import React, { useState } from 'react';
import PropsTypes from 'prop-types';

import Button from 'components/common/Button';
import DialogContentText from '@material-ui/core/DialogContentText';

import TextInput from 'components/common/TextInput';
import Dialog from 'components/common/Dialog';

import Toaster from 'components/ToasterQueue';

function ModalDialog({
  title,
  text,
  placeholder,
  actionLabel,
  handleAction,
  handleClose,
  className,
  defaultValue,
  validate,
  isOpen = false,
}) {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const onHandleAction = async () => {
    setLoading(true);

    if (typeof validate === 'function') {
      const state = validate(value);

      if (!state.valid) {
        Toaster.error(state.message);
        setLoading(false);
        return;
      }
    }

    const status = await handleAction(value);

    setLoading(false);

    if (status) {
      handleClose();
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const container = (
    <DialogContentText
      style={{ minWidth: '550px' }}
      id="alert-dialog-description"
    >
      {title === 'Delete' ? (
        <span> {text} </span>
      ) : (
        <TextInput
          label={text}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
      )}
    </DialogContentText>
  );

  const actions = (
    <>
      <Button
        onClick={onHandleAction}
        customLoadingStyle={{ marginRight: '5px' }}
        useMUButton
        color="primary"
        loading={loading}
      >
        {actionLabel}
      </Button>
      {title === 'Delete' && (
        <Button useMUButton onClick={handleClose} color="primary">
          Cancel
        </Button>
      )}
    </>
  );

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        className={className}
        container={container}
        title={title}
        actions={actions}
        handleClose={handleClose}
      />
    </div>
  );
}

ModalDialog.propTypes = {
  title: PropsTypes.string,
  text: PropsTypes.string,
  placeholder: PropsTypes.string,
  isOpen: PropsTypes.bool,
  className: PropsTypes.string,
  defaultValue: PropsTypes.string,
  actionLabel: PropsTypes.func,
  handleAction: PropsTypes.string,
  handleClose: PropsTypes.func,
  validate: PropsTypes.func,
};

ModalDialog.defaultProps = {
  title: '',
  text: '',
  placeholder: '',
  actionLabel: '',
  isOpen: false,
  className: '',
  defaultValue: '',
  handleAction: () => {},
  handleClose: () => {},
  validate: () => {
    return { valid: true };
  },
};

export default ModalDialog;
