/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { Container, Row } from 'reactstrap';

import MaterialTable from 'components/MaterialTable';
import { api, inlineFilters } from 'utils/fetch';
import Toaster from 'components/ToasterQueue';
import { getUsersHeaders, resetUserCache } from './utils';

import './style.scss';
import ReclaimModalView from './ReclaimModalView';
import WarningDialogue from './ReclaimModalView/WarningDialogue';

class Users extends React.Component {
  constructor(props) {
    super(props);
    const { headers, detailViewCols } = getUsersHeaders();
    this.headers = headers;
    this.detailViewCols = detailViewCols;
    this.state = {
      modalOpen: false,
      warningOpen: false,
      selectedUser: {},
      reclaimingUser: {},
    };
  }

  componentWillUnmount() {
    resetUserCache();
  }

  handleClose = () => this.setState({ modalOpen: false });

  handleOpenWarning = () => this.setState({ warningOpen: true });

  handleCloseWarning = () => this.setState({ warningOpen: false });

  handleReclaimUsers = async () => {
    return api
      .reclaimUser({
        data: {
          oldUserId: this.state.selectedUser.CognitoId,
          newUserId: this.state.reclaimingUser.id,
        },
      })
      .then(() => {
        this.setState({ warningOpen: false, modalOpen: false });
        Toaster.success(`Reclaiming successful`);
      })
      .catch((error) => {
        Toaster.error(error);
      });
  };

  handleSetReclaimingUser = (reclaimingUser) =>
    this.setState({ reclaimingUser });

  initTable = async () => {
    // TODO: Add functionality. Example: await api.getAllTenants()
  };

  render() {
    const { modalOpen, selectedUser, reclaimingUser, warningOpen } = this.state;

    return (
      <>
        <Container className="user-page">
          <Row>
            <MaterialTable
              tableProps={{
                title: 'All Users',
                detailViewTittle: 'User',
                canDelete: true,
                actionsExtraMenu: [
                  {
                    title: 'Reclaim',
                    data: true,
                    showIf: () => true,
                    onClick: (user) => {
                      this.setState({
                        modalOpen: true,
                        selectedUser: { ...user, id: user.UserId },
                      });
                    },
                  },
                ],
                name: 'AllUsers',
              }}
              columns={this.headers}
              remoteData
              fullScreenDetailView
              getData={async (param) => {
                const newParams = inlineFilters(param);
                const result = await api.getUsers(newParams);
                return {
                  ...result,
                  count: result.data.pagination.totalItems,
                  data: result.data,
                };
              }}
              detailsView={{
                cols: this.detailViewCols,
                getItem: api.getUser,
                onSave: api.updateUser,
                onDelete: api.deleteUser,
                idFieldName: 'CognitoId',
              }}
              init={this.initTable}
            />
          </Row>
        </Container>
        <ReclaimModalView
          open={modalOpen}
          selectedUser={selectedUser}
          handleClose={this.handleClose}
          onSetReclaimingUser={this.handleSetReclaimingUser}
          onOpenWarning={this.handleOpenWarning}
          onCloseWarning={this.handleCloseWarning}
          reclaimingUser={reclaimingUser}
        />
        <WarningDialogue
          open={warningOpen}
          handleClose={this.handleCloseWarning}
          onReclaim={this.handleReclaimUsers}
        />
      </>
    );
  }
}

export default Users;
