import React from 'react';
import PropTypes from 'prop-types';

function ShowIf({ condition, children }) {
  return <>{!!condition && children}</>;
}

ShowIf.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

ShowIf.defaultProps = {
  condition: true,
  children: [],
};

export default ShowIf;
