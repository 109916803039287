const validationSchemaHD = {
  PH: {
    name: 'PH',
    type: 'number',
  },
  Q: {
    name: 'Q',
    type: 'number',
  },
  holeDiameter: {
    name: 'Hole Diameter',
    type: 'number',
  },
  slotLengthOverall: {
    name: 'Slot Length Overall Secondary',
    type: 'number',
  },
  Q2: {
    name: 'Q Secondary',
    type: 'number',
  },
  holeDiameter2: {
    name: 'Hole Diameter Secondary',
    type: 'number',
  },
  slotLengthOverall2: {
    name: 'Slot Length Overall Secondary',
    type: 'number',
  },
};

export default validationSchemaHD;
