import React, { Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@material-ui/core/styles';
import GlobalSpinner from 'components/GlobalSpinner/GlobalSpinner';
import GlobalSpinnerContextProvider from 'components/GlobalSpinner/GlobalSpinnerContext';

import 'bootstrap/scss/bootstrap.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'scss/app.scss';

import Loader from 'components/Loader';
import store from 'redux/store';
import authActions from 'redux/actions/authActions';
import ScrollToTop from './ScrollToTop';
import Router from './Router';
import theme from './theme';

store.dispatch(authActions.init());
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ScrollToTop>
            <>
              <ToastContainer />
              <Loader />
              <GlobalSpinnerContextProvider>
                <div>
                  <GlobalSpinner />
                  <Router />
                </div>
              </GlobalSpinnerContextProvider>
            </>
          </ScrollToTop>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default hot(module)(App);
