/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { labelStyles } from './styles';

import './styles.scss';

const getCustomStyles = ({ error }) => ({
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#004D7A' : 'inherit',
    '&:hover': {
      backgroundColor:
        state.isFocused && !state.isSelected
          ? 'rgba(0, 77, 122, 0.1)'
          : state.isFocused && state.isSelected
          ? '#004D7A'
          : provided.backgroundColor,
    },
  }),
  control: (provided, state) => ({
    ...provided,
    height: 42,
    borderRadius: 0,
    borderColor: error ? '#f44336' : '#e0e0e0',
    boxShadow: state.isFocused ? '0 0 0 0 #004D7A' : provided.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#004D7A' : provided.borderColor,
    },
  }),
  menu: (provided) => ({ ...provided, zIndex: 10 }),
  indicatorsContainer: (provided) => ({ ...provided, height: 42 }),
  singleValue: (provided) => provided,
  valueContainer: (provided) => ({ ...provided, height: 42 }),
});

const getCustomStylesForTable = (top, error) => ({
  ...getCustomStyles({ error }),
  container: (provided) => ({
    ...provided,
    position: 'unset',
  }),
  menu: (provided) => ({
    ...provided,
    width: 'auto',
    top,
    zIndex: 1000,
  }),
  singleValue: (provided) => ({
    ...provided,
    width: 'auto',
    top: '50%',
  }),
});

class SelectInput extends PureComponent {
  handleChange = (selected) => {
    const { onChange, name } = this.props;
    onChange(name, selected ? selected.value : '', selected);
  };

  handleFocus = (e) => {
    const { onFocus } = this.props;
    onFocus(e);
  };

  render() {
    const {
      name,
      label,
      labelPosition,
      onChange,
      error = {},
      orientation,
      value,
      disabled,
      options,
      className,
      checkNearestValue,
      inTableCell,
      topOffset,
      ...rest
    } = this.props;

    const classList = {
      labelClass: '',
      selectClass: 'select',
      selectWrapDiv: '',
    };

    if (orientation === 'horizontal') {
      classList.labelClass = 'component-header control-label col-sm-4';
      classList.selectClass = '';
      classList.selectWrapDiv = 'col-sm-8';
    }

    if (orientation === 'vertical') {
      classList.labelClass = 'component-header';
    }

    let valueFromList = options.find((item) => item.value === value);

    if (!valueFromList && options.length && checkNearestValue) {
      valueFromList = options.reduce((prev, curr) =>
        Math.abs(curr.value - value) < Math.abs(prev.value - value)
          ? curr
          : prev
      );
      this.handleChange(valueFromList);
    }

    return (
      <div
        {...(className
          ? { className }
          : { className: inTableCell ? '' : 'form-group' })}
      >
        {label && labelPosition !== 'left' && (
          <label
            htmlFor={name}
            className={`Select-label ${classList.labelClass}`}
          >
            {label}
          </label>
        )}
        <div
          className={`input-group ${labelPosition === 'left' && 'horizontal'} ${
            classList.selectWrapDiv
          }`}
          data-testid={`Select-${name}`}
          style={{
            width: '100%',
            position: inTableCell ? 'unset' : 'relative',
          }}
        >
          {label && labelPosition === 'left' && (
            <label
              htmlFor={name}
              className={`Select-label ${classList.labelClass}`}
              style={labelStyles.medium}
            >
              {label}
            </label>
          )}
          <Select
            className={classList.selectClass}
            styles={
              inTableCell
                ? getCustomStylesForTable(topOffset, error)
                : getCustomStyles({ error })
            }
            name={name}
            id={name}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            value={valueFromList || []}
            isDisabled={disabled}
            options={options}
            {...rest}
          />
          {error && (
            <div className="pc-inline pull-right text-right">
              <span className="invalid-feedback" style={{ fontWeight: 300 }}>
                {error}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.string,
  orientation: PropTypes.string,
  disabled: PropTypes.bool,
  checkNearestValue: PropTypes.bool,
  inTableCell: PropTypes.bool,
  topOffset: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};

SelectInput.defaultProps = {
  onChange: () => {},
  onFocus: () => {},
  error: '',
  orientation: 'vertical',
  disabled: false,
  inTableCell: false,
  topOffset: 0,
  options: [],
  checkNearestValue: false,
  labelPosition: 'top',
  label: '',
  value: '',
  className: '',
};

export default SelectInput;
