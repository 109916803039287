export const inputStyles = {
  small: {
    height: '42px',
    borderRadius: '2px',
    boxShadow: 'none',
    zIndex: 0,
  },
  medium: {
    height: '44px',
    borderRadius: '2px',
    boxShadow: 'none',
    zIndex: 0,
  },
  big: {
    height: '50px',
    borderRadius: '2px',
    boxShadow: 'none',
    fontSize: '16px',
    zIndex: 0,
  },
};

export const btnStyles = {
  small: {
    height: '40px',
    borderRadius: '2px',
  },
  medium: {
    height: '44px',
    borderRadius: '2px',
    boxShadow: 'none',
  },
  big: {
    height: '50px',
    borderRadius: '2px',
    boxShadow: 'none',
  },
};

export const labelStyles = {
  small: {
    lineHeight: 1.5,
    fontWeight: 'normal',
  },
  medium: {
    lineHeight: 1.5,
    fontWeight: 'normal',
  },
  boldMedium: {
    lineHeight: 1.5,
    fontWeight: 'bold',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    letterSpacing: '0.00938em',
  },
  big: {
    lineHeight: 1.5,
    fontWeight: 'normal',
    fontSize: '16px',
  },
};
