import JSPDF from 'jspdf';
import moment from 'moment';
import 'jspdf-autotable';
import { getDefaultFileName } from '../Tools/exportTools';

const xPositions = [];
const marginAmendment = 3.5;
const headerIndent = 25;
const specSheetHeadTemplate = `${process.env.PUBLIC_URL}/img/specSheetHeadTemplate.jpg`;
const specSheetFigTemplate = `${process.env.PUBLIC_URL}/img/specSheetFigTemplate.jpg`;

const pdfAction = ({ doc, action, filename }) => {
  const blob = doc.output('datauristring');
  const actions = {
    save: () => doc.save(filename),
    show: () => doc.output('dataurlnewwindow'),
  };

    actions[action] && actions[action](); // eslint-disable-line
  return blob;
};

const addPageHeader = (doc, reportName, headers, pageSettings) => {
  const { width = 801.89, pageMargin = 40, padding = 0 } = pageSettings;

  doc.setFontSize(7);
  doc.text(
    `${reportName} ${moment(new Date()).format('YYYY-MM-DD HH:mm')}`,
    pageMargin,
    headerIndent
  );
  doc.text(
    `Page ${doc.internal.getCurrentPageInfo().pageNumber}`,
    width - pageMargin * 2,
    headerIndent
  );

  doc.setFontSize(7);
  headers.forEach((heading, index) => {
    // eslint-disable-next-line
      if (heading.hasOwnProperty('xPos')) {
        // eslint-disable-line
      doc.text(heading.display, heading.xPos, pageMargin);
      xPositions.push(heading.xPos);
    } else {
      const xPositionForCurrentHeader =
        pageMargin + index * ((width - pageMargin) / headers.length);
      doc.text(
        heading.display,
        index === 0
          ? xPositionForCurrentHeader
          : xPositionForCurrentHeader + padding,
        pageMargin
      );
      xPositions.push(
        index === 0
          ? xPositionForCurrentHeader
          : xPositionForCurrentHeader + padding
      );
    }
  });

  doc.line(
    pageMargin,
    pageMargin + marginAmendment,
    width - pageMargin,
    pageMargin + marginAmendment
  );
};

export const generatePDFTableReport = ({
  data,
  headers,
  reportName = 'Report',
  filename = getDefaultFileName(reportName, 'pdf'),
  pageSettings = {},
  action = 'show',
}) => {
  const {
    orientation = 'l',
    unit = 'pt',
    format = 'a4',
    height = 560.28,
      width = 801.89, // eslint-disable-line
    pageMargin = 50,
    padding = 15,
  } = pageSettings;

  let curPage = 1;

  const doc = new JSPDF({ orientation, unit, format });
  doc.setProperties({
    title: `${reportName} ${moment(new Date()).format('YYYY-MM-DD HH:mm')}`,
  });

  addPageHeader(doc, reportName, headers, pageSettings);

  const baseYPosForRows = pageMargin + padding;
  let nextYPos = baseYPosForRows;
  const totalRows = data.length;

  // ROWS
  data.forEach((row, i) => {
    const rowHeights = [];

    /*
     *
     * Row styles go here
     *
     * */

    // COLUMNS
    headers.forEach((column, cIndex) => {
      const longText = doc.splitTextToSize(
        String(row[column.key]),
        xPositions[cIndex] - xPositions[cIndex !== 0 && cIndex - 1]
      );
      const rowHeight = longText.length * doc.getLineHeight();
      rowHeights.push(rowHeight);

      /*
       *
       *  Column styles go here
       *
       * */

      doc.text(longText, xPositions[cIndex], nextYPos);
    });

    nextYPos = nextYPos + padding + Math.max(...rowHeights, 30);
    if (nextYPos > height - pageMargin) {
        doc.setPage(curPage++); // eslint-disable-line
      if (i < totalRows - 1) {
        doc.addPage();
        addPageHeader(doc, reportName, headers, pageSettings);
      }
      nextYPos = baseYPosForRows;
    }
  });

  return pdfAction({ doc, action: action.toLowerCase(), filename });
};

const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const SpecSheetGenerator = async (data) => {
  const {
    manufacturer,
    model,
    power,
    moduleDimensions: { pl, pw, pt, w, measurementUnit },
  } = data;
  const head = await toDataURL(specSheetHeadTemplate);
  const figure = await toDataURL(specSheetFigTemplate);
  const doc = new JSPDF('p', 'mm', 'letter');
  const width = doc.internal.pageSize.getWidth();
  const unit =
    {
      millimeter: 'mm',
      centimeter: 'cm',
      inches: '"',
    }[measurementUnit.toLowerCase()] || measurementUnit;

  //   measurementUnit.toLowerCase() === 'millimeter' ? 'mm' : measurementUnit;

  doc.addImage(head, 'JPEG', width * 0.05, 10, width * 0.9, 35);

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(12);
  doc.setFontType('bold');
  doc.text(
    `${manufacturer}/${model} - Module Data Sheet`,
    width / 2,
    55,
    null,
    null,
    'center'
  );
  doc.setTextColor(255, 0, 0);
  doc.setFontSize(10);
  doc.setFontType('normal');
  doc.text(
    '(Module Specification Sheet Not Available)',
    width / 2,
    60,
    null,
    null,
    'center'
  );

  doc.addImage(figure, 'JPEG', width * 0.05, 75, width * 0.9, 90);

  doc.autoTable({
    startY: 180,
    theme: 'striped',
    styles: {
      overflow: 'linebreak',
      fontSize: 10,
      valign: 'middle',
    },
    head: [['Module Specification Name', 'Specification', 'Unit of Measure']],
    body: [
      ['Manufacturer Name', manufacturer, 'N/A'],
      ['Model Name', model, 'N/A'],
      ['Wattage (Power)', power, 'Watt'],
      ['Length (PL)', pl, unit],
      ['Width (PW)', pw, unit],
      ['Thickness (PT)', pt, unit],
      ['Weight (W)', w, 'kg'],
    ],
  });

  // return doc.save(`${manufacturer}_${model}.pdf`);
  return new File(
    [doc.output('blob')],
    `${manufacturer}_${model}_${power}.pdf`
  );
};
