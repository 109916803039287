import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';
import CallReceived from '@material-ui/icons/CallReceived';
import { makeStyles } from '@material-ui/core/styles';
import Toaster from 'components/ToasterQueue';
import { uploadFileToS3 } from 'utils/uploadFileToS3';
import { api } from 'utils/fetch';
import { TOAST_TYPE } from 'components/ToasterQueue/ToasterQueue';
import ACL from 'redux/Acl';
import {
  ALLOW_SMM_IMPORT,
  ALLOW_SPECSHEET_IMPORT,
} from 'redux/constants/category';
import { IMPORT_FAILED, IMPORT_FAILED_WITH_REPORT } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuItem: { alignItems: 'center', justifyContent: 'center' },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

function SMMImportMenu({
  acl,
  handleImportDocument,
  fieldsMap,
  cols,
  refreshTable,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const inputFiles = useRef([]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback(({ target: eventTarget }) => {
    const anchorRefCur = anchorRef.current;
    if (anchorRefCur && anchorRefCur.contains(eventTarget)) return;
    // eslint-disable-next-line consistent-return
    return setOpen(false);
  }, []);

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handlePDFFileChange = useCallback(
    (e) => {
      const { isValidationOff } = true;
      const selectedFiles = inputFiles.current[e.target.name].files;
      Object.keys(selectedFiles).forEach((el) => {
        const tId = Toaster.msg(`Uploading '${selectedFiles[el].name}' ...`);
        uploadFileToS3({ file: selectedFiles[el], isValidationOff })
          .then((fileName) => {
            Toaster.updateById(
              tId,
              `'${fileName}' was successfully uploaded to S3`
            );
            // copyObject(fileName); //! should be implemented in future when all validations will be done
          })
          .catch((error) => {
            Toaster.dismissById(tId);
            Toaster.error(error);
          });
      });
      return handleClose(e);
    },
    [handleClose]
  );

  /* eslint-disable */
  // * --- FOR JSON import
  // const camelize = (str = '') => {
  //   if (str === str.toUpperCase()) return str.toLowerCase();
  //   return str.replace(/^([A-Z])|\s(\w)/g, (match, p1, p2) => {
  //     if (p2) return p2.toUpperCase();
  //     return p1.toLowerCase();
  //   });
  // };
  // const handleCSVFileChange = useCallback(
  //   (e) => {
  //     const selectedFile = inputFiles.current[e.target.name].files[0];
  //     const fileReader = new FileReader();
  //     fileReader.onerror = (err) => Toaster.error(`Import error: ${err.type}`);
  //     fileReader.onloadend = handleFileRead(fileReader, selectedFile.name);
  //     fileReader.readAsText(selectedFile);
  //     return handleClose(e);
  //   },
  //   [handleClose, handleFileRead]
  // );
  //   const handleFileRead = useCallback(
  //     (fileReader, selectedFile) => () => {
  //       try {
  //         console.log(fileReader.result)
  //         const rawContent = JSON.parse(fileReader.result);
  //         const camelize = (str = '') => {
  //           if (str === str.toUpperCase()) return str.toLowerCase();
  //           return str.replace(/^([A-Z])|\s(\w)/g, (match, p1, p2) => {
  //             if (p2) return p2.toUpperCase();
  //             return p1.toLowerCase();
  //           });
  //         };
  //       } catch (e) {
  //         Toaster.error(
  //           `'${selectedFile}' has an invalid CSV format. ${e.name}: ${e.message}`
  //         );
  //       }
  //     }
  //   },
  //   [handleImportDocument]
  // );
  // const correctedContent = Object.keys(rawContent).reduce((acc, cur) => {
  //   if (rawContent[cur] && typeof rawContent[cur] === 'object') {
  //     acc[camelize(cur)] = Object.keys(rawContent[cur]).reduce(
  //       (a, c) => {
  //         a[camelize(c)] = rawContent[cur][c];
  //         return a;
  //       }, {});
  //   } else {
  //     acc[camelize(cur)] = rawContent[cur];
  //   }
  //   return acc;
  // }, {});
  // * ---
  /* eslint-enable */

  const showImportError = useCallback(
    (selectedFile, errMessage) =>
      Toaster.error(
        `'${selectedFile}' has an invalid CSV format: ${errMessage}`
      ),
    []
  );

  const importFile = useCallback(
    // eslint-disable-next-line
    (selectedFile, type) => {
      try {
        const newFile = new File(
          [selectedFile],
          `smm-import-${Date.now().toString()}.pdf`,
          { type: 'application/octet-stream' }
        );
        Toaster.success(`Started import...`);
        uploadFileToS3({ file: newFile })
          .then((key) => {
            api
              .importSMs({
                data: {
                  importType: type,
                  fileKey: key,
                },
              })
              .then((response) => {
                if (response.data.isValid) {
                  // handle success
                  Toaster.success(
                    `Import was successful. You need to take further action to make them available in clawOS`
                  );
                  refreshTable();
                } else {
                  const { failedReportUrl } = response.data;
                  Toaster.showLink(
                    IMPORT_FAILED_WITH_REPORT,
                    failedReportUrl,
                    TOAST_TYPE.ERROR
                  );
                }
              })
              .catch(() => {
                Toaster.error(IMPORT_FAILED);
              });
          })
          .catch((error) => {
            Toaster.error(IMPORT_FAILED);
          });
      } catch (err) {
        showImportError(selectedFile, err.message);
      }
    },
    [fieldsMap, handleImportDocument, cols, showImportError]
  );

  const handleCSVFileChange = useCallback(
    (e) => {
      const selectedFile = inputFiles.current[e.target.name].files[0];
      if (selectedFile) importFile(selectedFile, 'csv');
      handleClose(e);
    },
    [handleClose, importFile]
  );

  const handleExcelFileChange = useCallback(
    (e) => {
      const selectedFile = inputFiles.current[e.target.name].files[0];
      if (selectedFile) importFile(selectedFile, 'excel');
      handleClose(e);
    },
    [handleClose, importFile]
  );

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) anchorRef.current.focus();
    prevOpen.current = open;
  }, [open]);

  const options = [
    {
      title: 'Import a CSV file into SMM',
      type: 'importcsv',
      accept: 'text/plain, .csv',
      multiple: false,
      disabled: !acl.getAccess(ALLOW_SMM_IMPORT).allowShow(),
      onChange: handleCSVFileChange,
    },
    {
      title: 'Import an Excel file into SMM',
      type: 'importexcel',
      accept: '.xlsx',
      multiple: false,
      disabled: !acl.getAccess(ALLOW_SMM_IMPORT).allowShow(),
      onChange: handleExcelFileChange,
    },
    {
      title: 'Import the module spec sheet PDFs',
      type: 'importpdf',
      accept: 'application/pdf',
      multiple: true,
      disabled: !acl.getAccess(ALLOW_SPECSHEET_IMPORT).allowShow(),
      onChange: handlePDFFileChange,
    },
  ];

  return (
    <div className={classes.root}>
      <div>
        <IconButton
          ref={anchorRef}
          aria-controls={open && 'menu-list-grow'}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <CallReceived />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {options.map((el) => (
                        <MenuItem
                          key={`extraMenu-${el.type}`}
                          name={el.type}
                          className={classes.menuItem}
                          onClick={() =>
                            inputFiles.current[`input-${el.type}`].click()
                          }
                          disabled={el.disabled}
                        >
                          {el.title}
                          <input
                            type="file"
                            {...(el.multiple ? { multiple: 'true' } : {})}
                            id={el.type}
                            name={`input-${el.type}`}
                            accept={el.accept}
                            // eslint-disable-next-line no-return-assign
                            ref={(elem) =>
                              (inputFiles.current[`input-${el.type}`] = elem)
                            }
                            style={{ display: 'none' }}
                            onChange={el.onChange}
                          />
                        </MenuItem>
                      ))}
                    </MenuList>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

export default SMMImportMenu;

SMMImportMenu.propTypes = {
  acl: PropTypes.object, // eslint-disable-line
  handleImportDocument: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldsMap: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  cols: PropTypes.array,
};

SMMImportMenu.defaultProps = {
  acl: new ACL(),
  handleImportDocument: () => {},
  fieldsMap: [],
  cols: [],
};
