import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './_modal.scss';

const modalRoot = document.getElementById('modal-root');

const themeSuffixes = [
  'pc-big-modal',
  'pc-medium-modal',
  'pc-feedback-modal',
  'pc-bom-modal',
];

class Modal extends Component {
  constructor(props) {
    super(props);
    this.container = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.container);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.container);
  }

  render() {
    const { children, isOpen, theme } = this.props;
    const suffix = themeSuffixes.includes(theme) ? theme : '';

    const modalStyles = {
      display: 'block',
      top: 0,
    };

    if (!isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      <div className="pc-modal-background modal" style={modalStyles}>
        <div className={`pc-modal modal-dialog ${suffix}`} role="document">
          <div className="modal-content">{children}</div>
        </div>
      </div>,
      this.container
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  theme: PropTypes.string,
};

Modal.defaultProps = {
  isOpen: false,
  theme: 'pc-medium-modal',
};

export default Modal;
