import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import PropTypes from 'prop-types';
import { SeparateRow } from './utils';

function subTotalSection({ value: subTotal, perWatCost, perModuleCost }) {
  return (
    <>
      <TableRow>
        <TableCell>Subtotal</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {subTotal}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Per Watt Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {perWatCost}
        </TableCell>
      </TableRow>
      <TableRow className="total-table-last-row">
        <TableCell>Per Module Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {perModuleCost}
        </TableCell>
      </TableRow>
    </>
  );
}

function totalCostEnergyShipping({
  estimateShipCost,
  salesTax,
  engServiceLabel,
  engServicesCost,
}) {
  const servicesCost = engServicesCost === '' ? 'N/A' : engServicesCost;
  const engServiceLbl = `Eng Services${
    engServiceLabel ? `:  ${engServiceLabel}` : ''
  }`;
  return (
    <>
      <TableRow>
        <TableCell>{engServiceLbl}</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {servicesCost}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Sales Tax</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {salesTax}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="total-table-cell-bottom-border">Estimated Shipping Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {estimateShipCost}
        </TableCell>
      </TableRow>
    </>
  );
}

function totalArrayCosts({ value: totalCost, perWatCost, perModuleCost }) {
  return (
    <>
      <TableRow>
        <TableCell>Total System Cost (FOB PanelClaw)</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {totalCost}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Per Watt Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {perWatCost}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Per Module Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {perModuleCost}
        </TableCell>
      </TableRow>
    </>
  );
}

function Totals({ subTotal = {}, totalCost = {} }) {
  return (
    <>
      <TableCell className="total-table-category" rowSpan={13}>
        <div className="total-table-category-text" style={{}}>
          TOTAL
        </div>
      </TableCell>
      {subTotalSection(subTotal)}
      <SeparateRow />
      {totalCostEnergyShipping(totalCost)}
      <SeparateRow />
      {totalArrayCosts(totalCost)}
    </>
  );
}

Totals.propTypes = {
  totalCost: PropTypes.shape({
    perModuleCost: PropTypes.string || PropTypes.number,
    perWatCost: PropTypes.string || PropTypes.number,
    value: PropTypes.string || PropTypes.number,
    estimateShipCost: PropTypes.string || PropTypes.number,
    servicesAndOther: PropTypes.string || PropTypes.number,
  }).isRequired,
  services: PropTypes.shape({
    ballastBlockQty: PropTypes.string || PropTypes.number,
    freight: PropTypes.string || PropTypes.number,
    value: PropTypes.string || PropTypes.number,
    engServiceLabel: PropTypes.string,
  }).isRequired,
  subTotal: PropTypes.shape({
    perModuleCost: PropTypes.string || PropTypes.number,
    perWatCost: PropTypes.string || PropTypes.number,
    value: PropTypes.string || PropTypes.number,
  }).isRequired,
};

export default Totals;
