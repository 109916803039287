/* eslint-disable consistent-return */
import { getObjProp } from 'utils';

export const texts = {
  fieldIsRequired: 'Field is required',
};
export function validateFields(cols = [], data = {}) {
  const errors = {};
  cols.forEach(({ validations, field, path }) => {
    if (!validations) return null;

    const { required, validate } = validations;

    const value = getObjProp(data, path || field);

    if (required && !value) {
      errors[field] = texts.fieldIsRequired;
      return null;
    }

    if (validate) {
      const error = validate(data);
      if (error) {
        errors[field] = error;
      }
    }
  });
  const hasErrors = !!Object.keys(errors).length;
  return hasErrors && errors;
}
