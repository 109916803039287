/* eslint-disable no-nested-ternary */
import { CheckBoxField as CheckBox } from 'components/common/Form/components/CheckBox';
import React from 'react';
import { SelectField as Select } from 'components/common/Form/components/Select';
import PropTypes from 'prop-types';
import EditViewDualList from 'components/MaterialTable/EditViewDualList';
import { CREATE, EDIT } from 'components/DetailView/constants';
import moment from 'moment';
import { isDate } from 'utils';

import Table from './Table';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

function prepareSelectValue(value, items) {
  if (Array.isArray(value)) {
    const addProp = value.length === 1 ? { isFixed: true } : {};
    return value.map((x) => ({ value: x.id, label: x.name, ...addProp }));
  }

  if (typeof value === 'string') {
    const foundItem = items.find(
      (x) => x.value === value || x.value === value.replace(/ /g, '') // Some value has extra spaces
    );

    if (foundItem) {
      return {
        value: foundItem.value,
        label: foundItem.label || foundItem.name,
      };
    }
  }

  return typeof value === 'string'
    ? { value, label: value }
    : { value: value.id, label: value.name };
}

function selectedOptionToValue(selectedOption, value, ignoreType = false) {
  if (Array.isArray(value) && !ignoreType) {
    return selectedOption.map((x) => ({
      id: x.value,
      name: x.label,
    }));
  }

  if (typeof value === 'string' && !ignoreType) {
    return selectedOption.value;
  }

  return {
    id: selectedOption.value,
    name: selectedOption.label,
  };
}

function CheckBoxField({ name, value, isReadOnly, onChangeField }) {
  return (
    <CheckBox
      name={name}
      checked={value}
      disabled={isReadOnly}
      onChange={() => {
        onChangeField(name, !value);
      }}
    />
  );
}

function SelectField({
  name,
  value,
  isReadOnly,
  items,
  onChangeField,
  ignoreType,
  isMulti = false,
}) {
  return (
    <Select
      name={name}
      value={prepareSelectValue(value, items)}
      placeholder={`Select ${name}`}
      isDisabled={isReadOnly}
      options={items}
      isMulti={isMulti}
      styles={{
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#004D7A' : 'inherit',
          '&:hover': {
            backgroundColor:
              state.isFocused && !state.isSelected
                ? 'rgba(0, 77, 122, 0.1)'
                : state.isFocused && state.isSelected
                ? '#004D7A'
                : provided.backgroundColor,
          },
        }),
        control: (provided, state) => ({
          ...provided,
          borderRadius: 0,
          borderColor: state.isFocused ? '#004D7A' : provided.borderColor,
          boxShadow: state.isFocused ? '0 0 0 0 #004D7A' : provided.boxShadow,
          '&:hover': {
            borderColor: state.isFocused ? '#004D7A' : provided.borderColor,
          },
        }),
        indicatorsContainer: (provided) => ({ ...provided }),
        singleValue: (provided) => provided,
        valueContainer: (provided) => ({ ...provided }),
        multiValueLabel: (base, state) => {
          return state.data.isFixed
            ? { ...base, borderRight: 'none!Important' }
            : base;
        },
        multiValueRemove: (base, state) => {
          return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
      }}
      onChange={(changedValue) => {
        const newValue = selectedOptionToValue(changedValue, value, ignoreType);
        onChangeField(name, newValue);
      }}
    />
  );
}

function NumericField({ name, value, isReadOnly, onChangeField }) {
  return (
    <input
      name={name}
      type="number"
      value={value}
      disabled={isReadOnly}
      onChange={(e) => {
        onChangeField(name, e.target.value);
      }}
    />
  );
}

function TextField({ name, value, isReadOnly, onChangeField }) {
  return (
    <input
      name={name}
      type="text"
      value={value}
      disabled={isReadOnly}
      onChange={(e) => {
        onChangeField(name, e.target.value);
      }}
    />
  );
}

function DualListField({ path, field, fieldsValues, onChangeField, ...opts }) {
  return (
    Object.keys(fieldsValues).length && (
      <EditViewDualList
        onChangeField={(e, newValue) => {
          onChangeField(path || field, newValue);
        }}
        fieldsValues={fieldsValues}
        path={path}
        field={field}
        {...opts}
      />
    )
  );
}

function getField({
  field,
  title,
  path,
  type,
  format,
  exType,
  value,
  isReadOnly,
  onChangeField,
  items,
  mode,
  defaultValue,
  fieldsValues,
  headers,
  listFields,
  options,
  cellEditable,
}) {
  const opts = {
    isReadOnly,
    isEditMode: mode === EDIT || mode === CREATE,
    mode,
    defaultValue,
    ...options,
  };

  switch (type) {
    case 'boolean':
      return (
        <CheckBoxField
          name={path || field}
          value={value}
          onChangeField={onChangeField}
          {...opts}
        />
      );
    case 'numeric':
      return (
        <NumericField
          name={path || field}
          value={value}
          onChangeField={onChangeField}
          {...opts}
        />
      );
    default: {
      if (exType === 'select') {
        const isMulti = !!options?.isMulti;
        return (
          <SelectField
            name={path || field}
            value={value}
            items={items}
            isMulti={isMulti}
            onChangeField={onChangeField}
            {...opts}
          />
        );
      }

      if (exType === 'dual-list') {
        return (
          <DualListField
            path={path}
            field={field}
            fieldsValues={fieldsValues}
            onChangeField={onChangeField}
            {...opts}
          />
        );
      }

      if (exType === 'table') {
        return (
          <Table
            headers={headers.fields}
            title={title}
            fields={listFields}
            data={fieldsValues[field]}
            disabled={isReadOnly}
            opts={opts}
            cellEditable={cellEditable}
          />
        );
      }

      if (type === 'date') {
        let formattedDate = moment(new Date(value)).format(
          format || DEFAULT_DATE_FORMAT
        );

        formattedDate = isDate(new Date(formattedDate)) ? formattedDate : '';

        return (
          <TextField
            name={path || field}
            value={formattedDate}
            onChangeField={onChangeField}
            {...opts}
          />
        );
      }

      return (
        <TextField
          name={path || field}
          value={value}
          onChangeField={onChangeField}
          {...opts}
        />
      );
    }
  }
}

CheckBoxField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onChangeField: PropTypes.func.isRequired,
};

NumericField.propTypes = {
  name: PropTypes.string.isRequired,
  value: (PropTypes.string || PropTypes.number).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onChangeField: PropTypes.func.isRequired,
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: (PropTypes.string || PropTypes.number).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onChangeField: PropTypes.func.isRequired,
};

DualListField.propTypes = {
  path: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  fieldsValues: PropTypes.objectOf(PropTypes.object),
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  isMulti: PropTypes.bool.isRequired,
  value: (PropTypes.string || PropTypes.object || PropTypes.number).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onChangeField: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
};

export { CheckBoxField, SelectField, NumericField, TextField, getField };
