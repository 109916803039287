export const ApiInspector = 'ApiInspector';
export const HelioscopeInspector = 'HelioscopeInspector';
export const Estimates = 'Estimates';
export const DWRequest = 'DW Request';

export const EstimatesProjectListTitle = 'Select Project';
export const EstimatesProjectListHeaders = [
  { accessor: 'name', Header: 'Name' },
  {
    accessor: 'revisionNumber',
    Header: 'Revision',
    width: 65,
    className: 'alignCenter',
  },
  { accessor: 'owner', Header: 'Owner' },
  { accessor: 'createdAt', Header: 'Created date', type: 'date' },
  { accessor: 'status', Header: 'Status' },
];

export const OpportunityListTitle = 'Create Request / Select Opportunity';

export const OpportunityListHeaders = [
  { accessor: 'OpportunityNumber', Header: 'OpportunityNumber' },
  { accessor: 'Project', Header: 'Project' },
  {
    accessor: 'Salesperson',
    Header: 'Salesperson',
  },
  { accessor: 'CreatedDate', Header: 'Created date', type: 'date' },
  { accessor: 'Stage', Header: 'Stage' },
];
