export const CREATE = 'Create';
export const CREATE_MODE = 'create';
export const INSERT_MODE = 'insert';
export const EDIT = 'Edit';
export const UPDATE = 'update';
export const VIEW = 'View';
export const INSERT = 'Insert';
export const EDIT_MODE = `Edit Mode`;
export const VIEW_MODE = `view mode`;

export const TENANT = 'Tenant';
export const ROLES = 'Roles';
export const NEVER = 'never';
