import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';
import TableBase from 'components/common/Table/TableBase';
import { api } from 'utils/fetch';
import Toaster from 'components/ToasterQueue';
import RefreshBomModal from 'components/SelectListModal';
import Loader from 'components/Loader';
import AddIcon from '@material-ui/icons/Add';
import { isObject } from 'utils';
import {
  calculateQuantity,
  calculateTotalPrice,
  isAccessory,
} from 'containers/Estimates/Estimate/utils';
import Total from './Total';

import config from './config';
import {
  additionalPartNames,
  EstimatesRefreshBomHeaders,
  RAIL,
} from '../../constants';
import {
  calculateTotal,
  clearObject,
  getColumns,
  getPartNumberPrice,
  updateAccessoriesTierPrice,
  updateLinesTierPrice,
} from './utils';
import {
  ACCESSORIES,
  ACCESSORIES_COLOR,
  ACCESSORY,
  ADDITIONAL,
  ADDITIONAL_COLOR,
  BASE,
  COMPATIBLE,
  EMPTY,
  EMPTY_MODULES_WARNING,
  ERROR,
  ERROR_GETTING_PARTS,
  GET_LATEST_PRICING,
  INCOMPATIBLE,
  MODEL,
  PART_NUMBER,
  PRICING_TIER,
  QUANTITY,
  REFRESH,
  REFRESH_BOM,
  REVISION_NUMBER,
  SELECT_REVISION,
  SUCCESS,
  UNSELECTED,
  WARNING,
  WIRES,
  WIRING,
  BASE_PAD,
  WIRING_KITS,
  WIRES_PROPERTIES,
  ACCESSORY_PROPERTIES,
  WIRE_ACCESSORIES_KIT_NAMES,
} from './constants';

import './styles.scss';

function LinesTable({
  values,
  lines,
  linesOther,
  partNumbers,
  onChangeLines,
  onUpdatePricingList,
  onRefreshBOM,
  onDeleteLine,
  onDeleteOtherLine,
  onChangeProject,
  onUpdateCache,
  isEdit,
  isProjectQuote,
  showStepsBadges,
  disableLinesTable,
  docType,
  needCheckExcludeDeflectors, // Deprecated: Deflector-less per CPD-2779
  wireAccessoriesKitStatus,
}) {
  const {
    module,
    modules = [],
    tier = 1,
    projectId,
    additionalComponents = [],
    numberModules = 0,
    wiringKit = false,
    accessories = false,
    subarraysCount = 0,
    mountingSystem: { rackingSystem } = {},
  } = values;

  const basePartNumber = lines?.find((line) =>
    line.description.split(', ')[0].includes(BASE.toUpperCase())
  )?.partNumber;

  const railPartNumber = lines?.find((line) => line.description.includes(RAIL))
    ?.partNumber;

  const columns = getColumns(partNumbers, modules, tier);

  const [prevProps, setPrevProps] = useState({
    basePartNumber,
    wiringKit,
    accessories,
    numberModules,
    tier,
    railPartNumber,
    wireAccessoriesKitStatus,
  });
  const [stateModules, setModules] = useState({});
  const [loadedSettings, setLoadedSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const [addedNewRowForBlank, setAddedNewRowForBlank] = useState([]);
  const [modulesParts, setModulesParts] = useState({});
  const [refreshBom, setRefreshBom] = useState({
    open: false,
    loading: false,
    list: [],
    headers: [],
  });
  const [loaded, setLoaded] = useState(!isProjectQuote && !isEdit);

  useEffect(() => {
    const updatedLines = updateLinesTierPrice(lines, tier, partNumbers);
    onChangeLines({ lines: updatedLines });
  }, [tier]);

  const updateLinesOther = async (updateType) => {
    const newLinesOther = [];
    if (basePartNumber) {
      if (wiringKit && updateType !== ACCESSORY) {
        const wiringResult = await api.getWiringParts(
          basePartNumber,
          numberModules
        );

        onUpdateCache(WIRING_KITS, wiringResult?.data);
        newLinesOther.push(
          ...updateAccessoriesTierPrice(wiringResult?.data, tier, partNumbers)
        );
        if (updateType === WIRING) {
          newLinesOther.push(...linesOther);
        }
      }
    }

    if (railPartNumber) {
      if (accessories && updateType !== WIRING) {
        const accessoriesResult = await api
          .getAccessoriesParts(
            railPartNumber,
            numberModules,
            subarraysCount,
            rackingSystem
          )
          .catch((err) => {
            Toaster.error(err?.message?.message || err?.message);
            return null;
          });

        onUpdateCache(ACCESSORIES, accessoriesResult?.data);

        if (updateType === ACCESSORY) {
          newLinesOther.push(...linesOther);
        }

        if (accessoriesResult?.data) {
          newLinesOther.push(
            ...updateAccessoriesTierPrice(
              accessoriesResult.data,
              tier,
              partNumbers
            )
          );
        }
      }
    }
    return newLinesOther;
  };

  useEffect(async () => {
    const isNumberModulesChanged = prevProps.numberModules !== numberModules;
    const isTierChanged = prevProps.tier !== tier;
    const isBasePartNumberChanged = prevProps.basePartNumber !== basePartNumber;
    const isRailPartNumberChanged = prevProps.railPartNumber !== railPartNumber;

    let isWiringKitClicked = false;
    let isAccessoriesClicked = false;

    if (prevProps.wiringKit !== wiringKit) {
      if (
        Object.keys(WIRES_PROPERTIES).every(
          (key) => wireAccessoriesKitStatus[key]
        ) ||
        (Object.keys(wireAccessoriesKitStatus).length &&
          Object.keys(WIRES_PROPERTIES).every(
            (key) => !wireAccessoriesKitStatus[key]
          ))
      ) {
        isWiringKitClicked = true;
      }
    }

    if (prevProps.accessories !== accessories) {
      if (
        Object.keys(ACCESSORY_PROPERTIES).every(
          (key) => wireAccessoriesKitStatus[key]
        ) ||
        (Object.keys(wireAccessoriesKitStatus).length &&
          Object.keys(ACCESSORY_PROPERTIES).every(
            (key) => !wireAccessoriesKitStatus[key]
          ))
      ) {
        isAccessoriesClicked = true;
      }
    }

    setPrevProps({
      numberModules,
      wiringKit,
      accessories,
      tier,
      basePartNumber,
      railPartNumber,
      wireAccessoriesKitStatus: { ...wireAccessoriesKitStatus },
    });

    if (
      isNumberModulesChanged ||
      isBasePartNumberChanged ||
      isRailPartNumberChanged
    ) {
      setLoading(true);
      const updatedLinesOther = await updateLinesOther();
      onChangeLines({ linesOther: updatedLinesOther });
      setLoading(false);
    }

    if (isWiringKitClicked) {
      if (wiringKit) {
        setLoading(true);
        const updatedLinesOther = await updateLinesOther(WIRING);
        onChangeLines({ linesOther: updatedLinesOther });
        setLoading(false);
      } else {
        onChangeLines({
          linesOther: linesOther.filter(
            (item) =>
              item.type !== WIRES || !WIRE_ACCESSORIES_KIT_NAMES[item.partNum]
          ),
        });
      }
    }

    if (isAccessoriesClicked) {
      if (accessories) {
        setLoading(true);
        const updatedLinesOther = await updateLinesOther(ACCESSORY);
        onChangeLines({ linesOther: updatedLinesOther });
        setLoading(false);
      } else {
        onChangeLines({
          linesOther: linesOther.filter(
            (item) =>
              item.type !== ACCESSORY || !WIRE_ACCESSORIES_KIT_NAMES[item.partNum]
          ),
        });
      }
    }

    if (isTierChanged) {
      onChangeLines({
        linesOther: updateAccessoriesTierPrice(linesOther, tier, partNumbers),
      });
    }
  }, [
    numberModules,
    wiringKit,
    accessories,
    tier,
    basePartNumber,
    railPartNumber,
    wireAccessoriesKitStatus,
  ]);

  const checkDeletedModules = (checkParts, loadedModules) => {
    let deletedLines = false;
    const newModulePart = { ...checkParts };
    for (const id in loadedModules) {
      if (!modules.find((item) => item.id === id)) {
        // if module was deleted
        deletedLines = true;
        Object.keys(newModulePart).forEach((partNumber) => {
          if (newModulePart[partNumber].moduleId === id) {
            // check if other module has same part number
            const otherModuleId = Object.keys(loadedModules).find(
              (moduleId) =>
                moduleId !== id && loadedModules[moduleId][partNumber]
            );

            if (otherModuleId) {
              // if other module has same part number
              newModulePart[partNumber].moduleId = otherModuleId;
              newModulePart[partNumber].moduleName =
                loadedModules[otherModuleId].model;
            } else {
              delete newModulePart[partNumber];
            }
          }
        });
        delete stateModules[id];
      }
    }

    if (deletedLines) {
      setModules({ ...stateModules });
      setModulesParts({ ...newModulePart });
    }

    return { newModulePart, deletedLines };
  };

  useEffect(() => {
    let updateLines = false;
    let mountingSystem;

    const requests = modules.reduce((promiseChain, item) => {
      const { id, metadata, isCustom } = item;

      const racking = metadata?.mountingSystem || null;
      const rowSpacing = metadata?.rowSpacing || null;

      if (
        racking !== loadedSettings.racking ||
        rowSpacing !== loadedSettings.rowSpacing
      ) {
        clearObject(stateModules);
        clearObject(modulesParts);
      }

      return promiseChain.then(
        () =>
          new Promise(async (resolve) => {
            if (!stateModules[id]) {
              if (id && racking && rowSpacing) {
                const modulePartNumbers = {};
                updateLines = true;
                setLoading(true);
                try {
                  let res = null;

                  if (isCustom) {
                    if (!isProjectQuote) {
                      const {
                        manufacturer,
                        model,
                        power,
                        dataSheetUrl,
                        moduleDimensions,
                      } = item;

                      const payload = {
                        data: {
                          linkedRackingProductFamily: 2, // cFR - constant
                          manufacturer,
                          model,
                          power,
                          moduleDimensions,
                          Ul1703Listed: true, // should be hardcoded
                          dataSheetUrl,
                          custom: isCustom,
                        },
                      };
                      res = await api.getCustomModuleParts(
                        payload,
                        racking,
                        rowSpacing
                      );
                    }
                  } else {
                    res = await api.getModuleParts(id, racking, rowSpacing);
                  }

                  if (!isProjectQuote && res.data.compatibleComponents) {
                    const { compatibleComponents } = res.data;

                    res.data.compatibleComponents = compatibleComponents.filter(
                      (component) => component.partName !== BASE_PAD
                    );
                  }

                  if (res?.data?.racking && !mountingSystem) {
                    mountingSystem = res.data.racking;
                  }

                  if (Array.isArray(res?.data?.compatibleComponents)) {
                    const { compatibleComponents } = res.data;

                    compatibleComponents.forEach((component) => {
                      modulePartNumbers[component.partNumber] = true;
                      if (!modulesParts[component.partNumber]) {
                        modulesParts[component.partNumber] = {
                          partNumber: component.partNumber,
                          id: component.id,
                          moduleId: id,
                          partName: component.partDisplayName,
                          description: component.partDescription,
                          bomRank: component.bomRank,
                        };
                      }
                    });
                  }
                } catch {
                  setLoading(false);
                  Toaster.error(ERROR_GETTING_PARTS);
                }

                stateModules[id] = {
                  ...modulePartNumbers,
                  model: item.model,
                };

                setModulesParts({ ...modulesParts });
                setModules({ ...stateModules });
                setLoadedSettings({
                  racking,
                  rowSpacing,
                });
              }
            }

            if (mountingSystem) {
              onChangeProject({ mountingSystem });
            }

            resolve({ modulesParts, updateLines, stateModules });
          })
      );
    }, Promise.resolve());

    requests.then(async (result) => {
      if (result) {
        const {
          modulesParts: checkParts,
          updateLines: addedLines,
          stateModules: loadedModules,
        } = result;
        const { newModulePart, deletedLines } = checkDeletedModules(
          checkParts,
          loadedModules
        );

        if (loaded && (addedLines || deletedLines)) {
          // if new lines were generated or deleted modules
          const newLines = [];
          isObject(newModulePart) &&
            Object.keys(newModulePart).forEach((partNumber) => {
              const part = newModulePart[partNumber];

              const modelName = loadedModules[part.moduleId]?.model || '';
              const currentLine = lines.find(
                (item) => item.partNumber === partNumber
              );

              const newLine = {
                partNumber: part.partNumber,
                description: part.description,
                quantity: 0,
                moduleId: part.moduleId,
                model: modelName,
                unitPrice: 0,
                total: 0,
                status: {
                  status: SUCCESS,
                  value: COMPATIBLE,
                },
                link: './',
              };

              newLine.quantity =
                currentLine?.quantity ||
                calculateQuantity(
                  newLine,
                  values,
                  docType,
                  needCheckExcludeDeflectors // Deprecated: Deflector-less per CPD-2779
                );

              newLines.push(newLine);
            });
          const updatedLines = [
            ...updateLinesTierPrice(newLines, tier, partNumbers),
            ...addedNewRowForBlank,
          ];
          setLoading(false);

          return onChangeLines({ lines: updatedLines, loaded: true });
        }

        let shouldUpdateRows = false;
        let i = -1;
        const n = lines.length;

        while (++i < n) {
          const row = lines[i];
          if (row.status.status === WARNING && newModulePart[row.partNumber]) {
            row.status = { status: SUCCESS, value: COMPATIBLE };
            shouldUpdateRows = true;
          }

          if (!row.unitPrice) {
            row.unitPrice = partNumbers[row.partNumber]?.price || 0;
            row.total = calculateTotal(row.quantity, row.unitPrice);
            shouldUpdateRows = true;
          }
        }

        setLoading(false);

        if (!loaded) {
          setLoaded(true);
        }

        if (shouldUpdateRows) {
          onChangeLines({ lines: [...lines], loaded: true });
        }
      }
    });
  }, [modules]);

  // Deprecated: Deflector-less per CPD-2779
  const calculateLinesQuantity = useCallback(
    (lines, values, docType, needCheckExcludeDeflectors) => {
      let i = -1;
      const n = lines.length;

      while (++i < n) {
        const row = lines[i];

        row.quantity = calculateQuantity(
          row,
          values,
          docType,
          needCheckExcludeDeflectors // Deprecated: Deflector-less per CPD-2779
        );
        row.total = calculateTotal(row.quantity, row.unitPrice);
      }

      return lines;
    },
    []
  );

  // Deprecated: Deflector-less per CPD-2779
  useEffect(() => {
    if (!loaded || loading || !Array.isArray(lines) || lines.length === 0)
      return;

    setLoading(true);

    const updatedLines = calculateLinesQuantity(
      lines,
      values,
      docType,
      needCheckExcludeDeflectors
    );

    onChangeLines({ lines: [...updatedLines], loaded: true });

    setLoading(false);
  }, [needCheckExcludeDeflectors, values.excludeDeflectors]);

  const updateEditableData = (
    rowIndex,
    columnId,
    value,
    lineOtherPart = false,
    prevSelected = null
  ) => {
    if (lineOtherPart) {
      let newLines = lines;
      let newLinesOther = linesOther;

      if (columnId === QUANTITY) {
        let selectedAdditionalComponentsLength = 0;
        additionalComponents.forEach((component) => {
          if (component.selected) {
            selectedAdditionalComponentsLength += 1;
          }
        });

        const currentIndex =
          prevSelected.index -
          lines.length -
          selectedAdditionalComponentsLength;

        newLinesOther[currentIndex] = {
          ...newLinesOther[currentIndex],
          partQty: value,
          partPriceTotal: calculateTotal(
            value,
            newLinesOther[currentIndex].partPriceUnit
          ),
        };
      } else if (columnId === PART_NUMBER) {
        if (
          prevSelected &&
          !isAccessory(partNumbers[prevSelected.partNumber]?.ProductType)
        ) {
          newLines = [...lines];
          newLines.splice(prevSelected.index, 1);
        } else {
          newLinesOther = [...linesOther];

          let selectedAdditionalComponentsLength = 0;
          additionalComponents.forEach((component) => {
            if (component.selected) {
              selectedAdditionalComponentsLength += 1;
            }
          });

          const currentIndex =
            prevSelected.index -
            lines.length -
            selectedAdditionalComponentsLength;
          newLinesOther.splice(currentIndex, 1);
        }

        const partPriceUnit = getPartNumberPrice(
          partNumbers,
          lineOtherPart.value,
          tier
        );

        const type =
          partNumbers[lineOtherPart.value]?.ProductType?.toLowerCase() === WIRES
            ? WIRES
            : ACCESSORY;

        newLinesOther = [
          ...newLinesOther,
          {
            partNum: lineOtherPart.value,
            partQty: 0,
            partDescription: lineOtherPart.description,
            partPriceUnit,
            partPriceTotal: calculateTotal(0, partPriceUnit),
            type,
            isComponentPart: false,
            editable: true,
            onDeleteLine: prevSelected.onDeleteLine
              ? (row) => {
                  onDeleteOtherLine(row);
                }
              : null,
          },
        ];
      }

      return onChangeLines({ lines: newLines, linesOther: newLinesOther });
    }

    let newRows = lines;

    if (
      columnId === PART_NUMBER &&
      prevSelected &&
      isAccessory(partNumbers[prevSelected.partNumber]?.ProductType)
    ) {
      newRows = [
        ...newRows,
        {
          partNumber: value.value,
          description: value.description,
          quantity: 0,
          unitPrice: getPartNumberPrice(partNumbers, value.value, tier),
          total: 0,
          status: modulesParts[value.value]
            ? {
                status: SUCCESS,
                value: COMPATIBLE,
              }
            : {
                status: WARNING,
                value: INCOMPATIBLE,
              },
          link: './',
          isComponentPart: true,
          onDeleteLine: prevSelected.onDeleteLine
            ? (row) => onDeleteLine(row)
            : null,
        },
      ];

      const newLinesOther = [...linesOther];

      let selectedAdditionalComponentsLength = 0;
      additionalComponents.forEach((component) => {
        if (component.selected) {
          selectedAdditionalComponentsLength += 1;
        }
      });

      const currentIndex =
        prevSelected.index - lines.length - selectedAdditionalComponentsLength;
      newLinesOther.splice(currentIndex, 1);

      onChangeLines({ lines: newRows, linesOther: newLinesOther });
    } else {
      newRows = lines.map((item, index) => {
        if (index === rowIndex) {
          switch (columnId) {
            case PART_NUMBER: {
              if (value !== UNSELECTED) {
                const partNumber = value;
                const unitPrice = getPartNumberPrice(
                  partNumbers,
                  partNumber.value,
                  tier
                );
                return {
                  ...item,
                  [columnId]: partNumber.value,
                  description: partNumber.description,
                  previousQuantity: item.quantity,
                  unitPrice,
                  total: calculateTotal(item.quantity, unitPrice),
                  status: modulesParts[partNumber.value]
                    ? {
                        status: SUCCESS,
                        value: COMPATIBLE,
                      }
                    : {
                        status: WARNING,
                        value: INCOMPATIBLE,
                      },
                  link: './',
                };
              }
            }
            case QUANTITY: {
              return {
                ...item,
                [columnId]: value,
                [`previous${
                  columnId.charAt(0).toUpperCase() + columnId.slice(1)
                }`]: value,
                total: calculateTotal(value, item.unitPrice),
              };
            }
            case MODEL: {
              return {
                ...item,
                [columnId]: value.model,
              };
            }
          }
          return {
            ...item,
            [columnId]: value,
          };
        }
        return item;
      });

      onChangeLines({ lines: newRows });
    }
  };

  const onRefreshPartNumbersBOM = () => {
    setLoading(true);
    api
      .getAllProjects({
        sortOrder: 1,
        sortBy: REVISION_NUMBER,
        withTenants: true,
        getAll: true,
        f_projectId: projectId,
      })
      .then(({ data }) => {
        if (data?.data?.length) {
          let list = [];

          list = data.data.map((item) => {
            return {
              _id: item._id,
              revisionNumber: item.revisionNumber,
              createdAt: item.createdAt,
            };
          });

          setRefreshBom({
            open: true,
            loading: false,
            title: SELECT_REVISION,
            headers: EstimatesRefreshBomHeaders,
            list,
            count: list.length,
          });
        }
        setLoading(false);
      });
  };

  const updatePricingList = () => {
    setLoading(true);
    onUpdatePricingList(lines, linesOther, tier).then(() => {
      setLoading(false);
    });
  };

  const onCloseRefreshBom = () => {
    setRefreshBom({
      open: false,
      loading: false,
      list: [],
      headers: [],
    });
  };

  const onClickRevision = ({ _id: revisionId }) => {
    onRefreshBOM([], [], {
      ...values,
      unselectedAdditionalComponents: true,
    });
    setLoading(true);
    onCloseRefreshBom();
    api
      .refreshBom({ data: { revisionId, isPanelClawTenant: true } })
      .then(({ data }) => {
        const { modules, otherLines } = data;

        if (modules?.length) {
          let i = -1;
          const n = modules.length;
          const newValues = { ...values, lines: [] };
          const selectedAddPartNumbers = {};
          let newLines = [];
          while (++i < n) {
            const module = modules[i];

            module.linesBom.forEach((item) => {
              const {
                partNum: partNumber,
                partQty: quantity,
                partPriceUnit: unitPrice,
                partPriceTotal: total,
                partDescription,
              } = item;
              const part = partNumbers[partNumber];
              const additionalPart = additionalPartNames[partNumber];

              if (additionalPart) {
                selectedAddPartNumbers[partNumber] = true;
              } else if (part) {
                newLines.push({
                  partNumber,
                  description: partDescription || part.description,
                  quantity,
                  previousQuantity: quantity,
                  unitPrice,
                  total,
                  moduleId: module.id,
                  moduleName: module.name,
                  status: modulesParts[partNumber]
                    ? { status: SUCCESS, value: COMPATIBLE }
                    : { status: WARNING, value: INCOMPATIBLE },
                  link: './',
                });
              }
            });

            newLines = updateLinesTierPrice(newLines, tier, partNumbers);
          }

          newValues.additionalComponents.forEach((item) => {
            if (selectedAddPartNumbers[item.partNumber]) {
              item.selected = true;
            }
          });

          onRefreshBOM(
            newLines,
            updateAccessoriesTierPrice(otherLines, tier, partNumbers),
            newValues
          );
        }

        setLoading(false);
      })
      .catch((err) => {
        Toaster.error(
          err?.message?.message ? err.message.message : err?.message
        );
        setLoading(false);
      });
  };

  const addNewRow = () => {
    if (modules.length) {
      const newLine = {
        item: null,
        quantity: 0,
        partNumber: UNSELECTED,
        description: '',
        unitPrice: 0,
        total: 0,
        moduleId: modules[0].id,
        model: modules[0].model,
        status: { status: ERROR, value: EMPTY },
        link: './',
        isComponentPart: true,
        onDeleteLine: (row) => onDeleteLine(row),
      };
      onChangeLines({ lines: [...lines, newLine] });
      if (!isEdit) {
        setAddedNewRowForBlank([...addedNewRowForBlank, newLine]);
      }
    } else {
      Toaster.warn(EMPTY_MODULES_WARNING);
    }
  };

  // TODO: REFACTOR!!! CALLING THIS FUNCTION TOO MANY TIMES
  const addAdditionalPartIfSelected = () => {
    const newData = Array.isArray(lines) ? [...lines] : [];

    additionalComponents.forEach((component) => {
      if (component.selected) {
        const part = partNumbers[component.partNumberTopLevel];
        const unitPrice = getPartNumberPrice(
          partNumbers,
          component.partNumberTopLevel,
          tier
        );

        const newLine = {
          id: component.id,
          name: component.name,
          kitQty: component.kitQty,
          quantity: 0,
          partNumber: component.partNumberTopLevel,
          description: part?.PartDescription,
          unitPrice,
          total: 0,
          status: { status: SUCCESS, value: ADDITIONAL },
          notEditable: true,
          additionalComponent: true,
          backgroundColor: ADDITIONAL_COLOR,
        };

        newLine.quantity = calculateQuantity(
          newLine,
          values,
          docType,
          needCheckExcludeDeflectors // Deprecated: Deflector-less per CPD-2779
        );
        newLine.total = calculateTotalPrice(newLine.quantity, unitPrice);

        newData.push(newLine);
      }
    });

    linesOther.forEach((item) => {
      newData.push({
        quantity: item.partQty,
        partNumber: item.partNum,
        description: item.partDescription,
        unitPrice: item.partPriceUnit,
        total:
          item.partPriceTotal ||
          calculateTotalPrice(item.partQty, item.partPriceUnit),
        status: {
          status: SUCCESS,
          value: item.type === WIRES ? WIRING : ACCESSORIES,
        },
        type: item.type,
        notEditable: !item.onDeleteLine && !item.editable,
        accessories: !item.onDeleteLine && !item.editable,
        backgroundColor: ACCESSORIES_COLOR,
        onDeleteLine: item.onDeleteLine,
      });
    });

    return newData;
  };

  const addNewRowIcon = (
    <IconButton
      disabled={disableLinesTable || modules.length === 0}
      className="icon-add"
      style={{ textAlign: 'center', overflow: 'visible' }}
      onClick={addNewRow}
    >
      <AddIcon />
    </IconButton>
  );

  const actions = [];

  if (isEdit && isProjectQuote) {
    actions.push({
      label: REFRESH_BOM,
      onClick: onRefreshPartNumbersBOM,
    });
  }

  actions.push({
    label: GET_LATEST_PRICING,
    onClick: updatePricingList,
  });
  // TODO: REFACTOR!!! CALLING THIS FUNCTION TOO MANY TIMES
  const data = addAdditionalPartIfSelected();

  return (
    <>
      <Card>
        <CardBody>
          <div className="card__title">
            {showStepsBadges && (
              <div style={{ margin: '0 0 20px 10px' }}>
                <Badge
                  color={disableLinesTable ? 'secondary' : 'primary'}
                  badgeContent="4"
                  variant=""
                />
              </div>
            )}
            <h5 className="bold-text">Estimate Lines</h5>
            {loading && <Loader loading={loading} className="fill-container" />}
            <TableBase
              actions={actions}
              columns={columns}
              data={data}
              tableConfig={config}
              dropDownList={partNumbers}
              toolbarIcon={addNewRowIcon}
              updateEditableData={updateEditableData}
            />
          </div>
          <Total
            values={values}
            partNumbers={partNumbers}
            allLines={{ lines, linesOther }}
          />
        </CardBody>
      </Card>
      {refreshBom.open && (
        <RefreshBomModal
          title={refreshBom.title}
          loading={refreshBom.loading}
          open={refreshBom.open}
          list={refreshBom.list}
          count={refreshBom.count}
          headers={refreshBom.headers}
          handleClose={onCloseRefreshBom}
          toolbarActions={{
            button: {
              disabled: false,
              label: REFRESH,
              onClick: onClickRevision,
            },
          }}
          withPagination={false}
        />
      )}
    </>
  );
}

LinesTable.propTypes = {
  values: PropTypes.object,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      partNumber: PropTypes.string,
      description: PropTypes.string,
      quantity: PropTypes.string || PropTypes.number,
      unitPrice: PropTypes.string || PropTypes.number,
      total: PropTypes.string || PropTypes.number,
      moduleId: PropTypes.string || PropTypes.number,
      moduleName: PropTypes.string,
      status: PropTypes.shape({
        status: PropTypes.string,
        value: PropTypes.string,
      }),
      link: PropTypes.string,
    })
  ).isRequired,
  linesOther: PropTypes.arrayOf(
    PropTypes.shape({
      partQty: PropTypes.string || PropTypes.number,
      partNum: PropTypes.string,
      partDescription: PropTypes.string,
      partPriceUnit: PropTypes.string || PropTypes.number,
      partPriceTotal: PropTypes.string || PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
  onRefreshBOM: PropTypes.func,
  isEdit: PropTypes.bool,
  isProjectQuote: PropTypes.bool,
  disableLinesTable: PropTypes.bool,
  showStepsBadges: PropTypes.bool,
  numberModules: PropTypes.number,
  modules: PropTypes.arrayOf(PropTypes.object),
  partNumbers: PropTypes.object,
  additionalComponents: PropTypes.array,
  onChangeLines: PropTypes.func,
  onUpdatePricingList: PropTypes.func,
  onDeleteLine: PropTypes.func,
  onChangeProject: PropTypes.func,
  docType: PropTypes.string,
  needCheckExcludeDeflectors: PropTypes.bool, // Deprecated: Deflector-less per CPD-2779
};

LinesTable.defaultProps = {
  values: {},
  onRefreshBOM: () => {},
  isEdit: false,
  isProjectQuote: false,
  disableLinesTable: false,
  showStepsBadges: false,
  numberModules: 0,
  modules: [],
  partNumbers: {},
  additionalComponents: [],
  additionalPartNames: {},
  modulesParts: {},
  onChangeLines: () => {},
  onUpdatePricingList: () => {},
  onDeleteLine: () => {},
  onChangeProject: () => {},
  docType: '',
  needCheckExcludeDeflectors: false, // Deprecated: Deflector-less per CPD-2779
};

export default connect(null, null)(LinesTable);
