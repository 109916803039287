import { Link } from '@material-ui/core';
import { api } from 'utils/fetch';
import Toaster from 'components/ToasterQueue';
import { CLICK_TO_OPEN } from 'components/common/utils/constants';
import React from 'react';

function getDocumentsData(documents, estimateId) {
  return documents.map((filename) => {
    return {
      document: {
        estimateId,
        filename,
      },
    };
  });
};

function documentLink({ value }) {
  const { estimateId, filename } = value || {};
  return (
    <Link
      component="button"
      onClick={() => {
        api
          .getEstimateDocument(estimateId, filename)
          .then(({ data }) => {
            Toaster.showLink(CLICK_TO_OPEN, data.url);
          })
          .catch((err) => {
            Toaster.error(err.message);
          });
      }}
    >
      {filename}
    </Link>
  );
}

function getColumns() {
  return [
    {
      Header: 'Documents',
      accessor: 'document',
      disableGlobalFilter: true,
      Cell: documentLink,
      className: 'document-item',
    },
  ];
}

export { getDocumentsData, getColumns };
