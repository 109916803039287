/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switcher from 'components/Switcher';
import { getObjProp } from 'utils';

function EditViewSwitch({
  path,
  field,
  fieldsValues,
  onChangeField,
  isReadOnly,
  isEditMode,
  label,
}) {
  const value = useMemo(() => getObjProp(fieldsValues, path || field, false), [
    fieldsValues,
    path,
    field,
  ]);

  return (
    <FormControlLabel
      display={{ xs: 'none', md: 'block' }}
      label={label}
      labelPlacement="start"
      control={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Switch
          checked={!!value}
          onChange={onChangeField}
          color="primary"
          disabled={!isEditMode || isReadOnly}
        />
      }
    />
  );
}

export default EditViewSwitch;
