import store from 'redux/store';

import { setError } from 'redux/errors/actions';

function parseJSON(response, options) {
  if (response.status !== 200) {
    const error = {
      code: response.status,
      message: response.statusText,
    };

    if (!options.ignoreGlobalNotification) {
      store.dispatch(setError('global.notification', 'GLOBAL', error));
    }
    throw error;
  }
  return response.json();
}

function checkStatus(response, options) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (options && options.popup && options.popup[response.status]) {
    const error = {
      code: response.status,
      message: response.statusText,
    };
    throw error;
  }

  return parseJSON(response, options).then((json) =>
    Promise.reject(
      new Error(
        json.message || response.statusText || 'Error occurred, try again'
      )
    )
  );
}

function getToken() {
  const state = store.getState();
  return state.auth.token;
}

function request(url, options = {}) {
  const completeUrl = `${process.env.REACT_APP_API_URL}/${url}`;
  const token = options.token || getToken() || sessionStorage.getItem('token');
  const headers = {
    ...options.headers,
    'content-type': 'application/json',
    authorization: `Bearer ${token}`,
    app: 'aui',
  };

  if (options.userIds) {
    headers.userIds = JSON.stringify(options.userIds);
  }

  return fetch(completeUrl, {
    mode: 'cors',
    ...options,
    headers,
  })
    .then((r) => checkStatus(r, options))
    .then((r) => parseJSON(r, options))
    .catch((error) => {
      if (!options.ignoreGlobalNotification) {
        store.dispatch(setError('global.notification', 'GLOBAL', error));
      }

      throw error;
    });
}

export const get = (url, options) =>
  request(url, { method: 'GET', ...(options || {}) });
export const post = (url, body) => request(url, { method: 'POST', body });
export const put = (url, body) => request(url, { method: 'PUT', body });
export const del = (url) => request(url, { method: 'DELETE' });

export default request;
