import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { Bar, Pie } from 'react-chartjs-2';
import moment from 'moment';
import { getFilterRangeComponent, loadingSpin, prepareData } from '../utils';

function getChartType(
  title,
  chartType,
  data,
  isLoading,
  selectedDates,
  isFilterable,
  handleDateChange,
  openDatePicker,
  setOpenDatePicker
) {
  let dateLabel = '';
  if (selectedDates?.from && selectedDates?.to) {
    dateLabel = ` ${moment(selectedDates.from).format('MM/DD/YYYY')} - ${moment(
      selectedDates.to
    ).format('MM/DD/YYYY')}`;
  } else if (selectedDates?.from) {
    dateLabel = `From: ${moment(selectedDates.from).format('MM/DD/YYYY')}`;
  } else if (selectedDates?.to) {
    dateLabel = `To: ${moment(selectedDates.to).format('MM/DD/YYYY')}`;
  }

  let chartComponent;

  switch (chartType) {
    case 'bar':
      chartComponent = <Bar data={data} />;
      break;
    case 'pie':
      chartComponent = <Pie data={data} className='pie-chart'/>;
      break;
    default:
      return null;
  }

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        {isFilterable
          ? getFilterRangeComponent({
              openDatePicker,
              selectedDates,
              dateLabel,
              setOpenDatePicker,
              handleDateChange,
            })
          : null}
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{title}</h5>
          </div>
          {chartComponent}
          {loadingSpin(isLoading)}
        </CardBody>
      </Card>
    </Col>
  );
}

const chartType = {
  // temporary
  'avg-tat-month': {
    type: 'bar',
  },
  'avg-new-projects-workday': {
    type: 'bar',
  },
  'avg-revision-projects-workday': {
    type: 'bar',
  },
  'avg-onhold-projects-workday': {
    type: 'bar',
  },
  'avg-ae-projects-workday': {
    type: 'bar',
  },
  'devops-onhold-workday': {
    type: 'bar',
  },
  'total-design-requests-workday': {
    type: 'bar',
  },
  'percent-stamp-requests-completed-se': {
    type: 'pie',
  },
};

function AnalyticChart({
  title,
  data,
  selectedDates,
  handleDateChange,
  isLoading,
}) {
  const type = chartType[title] ? chartType[title].type : 'string';
  const { Label, Data, YAxisName, IsFilterable } = data?.data || {};
  const chartData = prepareData(Data, YAxisName);
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  return getChartType(
    Label || title,
    type,
    chartData,
    isLoading,
    selectedDates,
    IsFilterable,
    handleDateChange,
    openDatePicker,
    setOpenDatePicker
  );
}

AnalyticChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  selectedDates: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
};

export default AnalyticChart;
