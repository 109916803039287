/* eslint-disable no-shadow */
/* eslint-disable react-hooks\exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TextField from '@material-ui/core/TextField';
import { api } from 'utils/fetch';

function AutoSuggestionFilter(props) {
  const { onFilter, propsValue, tableName } = props;
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);

  const getAllTenants = (params = {}) =>
    api.getAllTenants({
      ...params,
    });

  useEffect(() => {
    if (propsValue !== value) {
      setValue('');
      getAllTenants().then((res) => {
        setOptions(res.data);
      });
    }
  }, [propsValue, value]);

  // const onSubmit = useCallback(
  //   (e) => {
  //     onFilter({ name, value });
  //   },
  //   [value]
  // );

  const onChangeAutocomplete = useCallback((e) => {
    const value = e.target.innerHTML;
    setValue(value);
    onFilter({
      name: 'tenantName',
      value: value.toLowerCase() !== 'all' && value[0] !== '<' ? value : '',
      tableName,
    });
  }, []);

  const onChange = useCallback(({ target }) => {
    const { value } = target;
    setValue(value);
    onFilter({
      name: 'tenantName',
      value: value.toLowerCase() !== 'all' ? value : '',
      tableName,
    });
  }, []);

  return (
    <Autocomplete
      id="combo-box-demo"
      onChange={onChangeAutocomplete}
      options={options}
      getOptionLabel={(option) => option.name}
      style={{ width: 300 }}
      renderInput={(params) => <TextField onChange={onChange} {...params} />}
    />
  );
}

const mapStateToProps = ({ dataView }, { name, tableName }) => {
  const val =
    dataView &&
    dataView[tableName] &&
    dataView[tableName].filters &&
    dataView[tableName].filters[name]
      ? dataView[tableName].filters[name]
      : '';

  return {
    propsValue: val,
  };
};

export default connect(mapStateToProps)(AutoSuggestionFilter);

AutoSuggestionFilter.defaultProps = {
  propsValue: '',
};

AutoSuggestionFilter.propTypes = {
  name: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  propsValue: PropTypes.string,
  tableName: PropTypes.string.isRequired,
};
