import { Component } from 'react';
import PropTypes from 'prop-types';

import { get, post } from 'utils/request';
import { withIntl } from 'components/providers/Intl';

class Fetch extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = { data: null, loading: false, error: null };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.fetchData(this.props);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchData(props) {
    this.sendRequest(props)
      .then((data) => {
        this._isMounted && this.setState({ data, loading: false });
      })
      .catch((error) => {
        this._isMounted && this.setState({ data: null, loading: false, error });
      });
  }

  sendRequest(props) {
    const { _t } = props;
    return new Promise((resolve, reject) => {
      let response;
      if (props.url) {
        this.setState({ loading: true });
        if (props.type === 'GET') {
          response = get(props.url);
        } else if (props.type === 'POST') {
          response = post(props.url, JSON.stringify(props.body));
        } else {
          const error = {
            message: _t('errors.invalid_method'),
          };

          reject(error);
        }
      } else {
        const error = {
          message: _t('errors.invalid_url'),
        };
        reject(error);
      }
      return resolve(response);
    });
  }

  render() {
    const { render } = this.props;
    if (typeof render === 'function') {
      return render(this.state);
    }
    return null;
  }
}

Fetch.prototypes = {
  type: PropTypes.string,
  body: PropTypes.object,
  url: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};

Fetch.defaultProps = {
  type: 'GET',
  body: null,
};

export default withIntl(Fetch);
