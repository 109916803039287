import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';

function Filter(props) {
  const { name, onFilter, propsValue, tableName } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    if (propsValue !== value) {
      setValue(propsValue);
    }
  }, [propsValue]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onFilter({ name, value, tableName });
    },
    [value]
  );

  // eslint-disable-next-line no-shadow
  const onChange = useCallback(({ target: { value } }) => {
    // eslint-disable-next-line no-unused-expressions
    value.toString() === '' && onFilter({ name, value, tableName }); // if field is empty string -> filtering should be cleared
    setValue(value);
  }, []);

  const endAdornment = (
    <InputAdornment position="start">
      <IconButton onClick={onSubmit}>
        <FilterListIcon fontSize="small" />
      </IconButton>
    </InputAdornment>
  );
  return (
    <form onSubmit={onSubmit}>
      <Input
        value={value}
        id={name}
        name={name}
        onChange={onChange}
        endAdornment={endAdornment}
      />
    </form>
  );
}

const mapStateToProps = ({ dataView }, props) => {
  const { name, tableName, defaultValue } = props;

  const val =
    dataView &&
    dataView[tableName] &&
    dataView[tableName].filters &&
    dataView[tableName].filters[name]
      ? dataView[tableName].filters[name]
      : defaultValue;

  return {
    propsValue: val,
  };
};

export default connect(mapStateToProps)(Filter);

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line
  propsValue: PropTypes.string,
  tableName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

Filter.defaultProps = {
  defaultValue: '',
};
