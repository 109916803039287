export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const CHANGE_FILTER_OPTIONS = 'CHANGE_FILTER_OPTIONS';
export const CHANGE_QUEUED_IMPORT_DATA = 'CHANGE_QUEUED_IMPORT_DATA';

export const SET_SF_TABLES = 'SET_SF_TABLES';
export const SET_SF_SELECTED_TABLE_NAME = 'SET_SF_SELECTED_TABLE_NAME';
export const SET_SF_HEADER = 'SET_SF_HEADER';
export const SET_SF_HIDED_COLUMNS = 'SET_SF_HIDED_COLUMNS';

export const SET_HELLIOSCOPE_PROJECTS = 'SET_HELLIOSCOPE_PROJECTS';
export const SET_HELLIOSCOPE_DESIGNS = 'SET_HELLIOSCOPE_DESIGNS';
export const SET_HELLIOSCOPE_SEGMENTS = 'SET_HELLIOSCOPE_SEGMENTS';
export const SET_HELLIOSCOPE_TABLE = 'SET_HELLIOSCOPE_TABLE';
export const SET_HS_HIDED_COLUMNS = 'SET_HS_HIDED_COLUMNS';

export const SET_APIINSPECTOR_TABLES = 'SET_APIINSPECTOR_TABLES';
export const SET_APIINSPECTOR_SELECTED_TABLE_NAME =
  'SET_APIINSPECTOR_SELECTED_TABLE_NAME';
export const SET_APIINSPECTOR_HEADER = 'SET_APIINSPECTOR_HEADER';
export const SET_APIINSPECTOR_HIDED_COLUMNS = 'SET_APIINSPECTOR_HIDED_COLUMNS';

export const SET_SOURCE_TABLES = 'SET_SOURCE_TABLES';
export const SET_SOURCE_SELECTED_TABLE_NAME = 'SET_SOURCE_SELECTED_TABLE_NAME';
export const SET_SOURCE_HEADER = 'SET_SOURCE_HEADER';
export const SET_SOURCE_HIDED_COLUMNS = 'SET_SOURCE_HIDED_COLUMNS';

export function changeFilters(filter) {
  return {
    type: CHANGE_FILTERS,
    filter,
  };
}
export function changeFilterOptions(filterOptions) {
  return {
    type: CHANGE_FILTER_OPTIONS,
    filterOptions,
  };
}

export function changeQueuedImportData(queuedImportData) {
  return {
    type: CHANGE_QUEUED_IMPORT_DATA,
    queuedImportData,
  };
}

export function setSFHeader(tableName, sfHeader) {
  return {
    type: SET_SF_HEADER,
    tableName,
    sfHeader,
  };
}
export function setSFHidedHeader(sfHidedColumns) {
  return {
    type: SET_SF_HIDED_COLUMNS,
    sfHidedColumns,
  };
}

export function setSFTables(sfTables) {
  return {
    type: SET_SF_TABLES,
    sfTables,
  };
}

export function setSFSelectedTableName(sfSelectedTableName) {
  return {
    type: SET_SF_SELECTED_TABLE_NAME,
    sfSelectedTableName,
  };
}

export function setHellioscopeProjects(header, table) {
  return {
    type: SET_HELLIOSCOPE_PROJECTS,
    header,
    table,
  };
}

export function setHellioscopeDesigns(projectId, header, table) {
  return {
    type: SET_HELLIOSCOPE_DESIGNS,
    projectId,
    header,
    table,
  };
}
export function setHellioscopeSegments(designId, header, table) {
  return {
    type: SET_HELLIOSCOPE_SEGMENTS,
    designId,
    header,
    table,
  };
}

export function switchHSTable(tableName, selectedId) {
  return {
    type: SET_HELLIOSCOPE_TABLE,
    tableName,
    selectedId,
  };
}

export function setHsHidedHeader(hidedColumns) {
  return {
    type: SET_HS_HIDED_COLUMNS,
    hidedColumns,
  };
}

export function setAPIInspectorHeader(tableName, header) {
  return {
    type: SET_APIINSPECTOR_HEADER,
    tableName,
    header,
  };
}
export function setAPIInspectorHidedHeader(hidedColumns) {
  return {
    type: SET_APIINSPECTOR_HIDED_COLUMNS,
    hidedColumns,
  };
}

export function setAPIInspectorTables(tables) {
  return {
    type: SET_APIINSPECTOR_TABLES,
    tables,
  };
}

export function setAPIInspectorSelectedTableName(selectedTableName) {
  return {
    type: SET_APIINSPECTOR_SELECTED_TABLE_NAME,
    selectedTableName,
  };
}

export function setSourceTableHeader(source, tableName, header) {
  return {
    type: SET_SOURCE_HEADER,
    source,
    tableName,
    header,
  };
}
export function setSourceHidedHeader(source, hidedColumns) {
  return {
    type: SET_SOURCE_HIDED_COLUMNS,
    source,
    hidedColumns,
  };
}

export function setSourceTables(source, tables) {
  return {
    type: SET_SOURCE_TABLES,
    source,
    tables,
  };
}

export function setSourceSelectedTableName(source, selectedTableName) {
  return {
    type: SET_SOURCE_SELECTED_TABLE_NAME,
    source,
    selectedTableName,
  };
}
