export const getTitles = (
  metaData,
  { filteringDisabled = false, sortingDisabled = false, hidedColumns = {} } = {}
) => {
  const headers = metaData.map((item) => {
    const { name, label, type, referenceTo, options } = item;

    return {
      field: name,
      title: label,
      type: String(type).trim().toLowerCase(),
      showInTable: !hidedColumns[name],
      filtering: !options?.filteringDisabled,
      sortable: !options?.sortingDisabled,
      referenceTo: referenceTo || [],
    };
  });

  return { headers };
};

export const objectToString = (object) => {
  return JSON.stringify(object)
    .replace(/,"+/g, '\n')
    .replace(/":+/g, ': ')
    .replace(/["{}]+|(\\n)+/g, '');
};

export const makeUpperCaseKeysInObject = (object) => {
  for (const key in object) {
    if (Object.hasOwn(object, key)) {
      object[key.charAt(0).toUpperCase() + key.substring(1)] = object[key];
      delete object[key];
    }
  }
  return object;
};
