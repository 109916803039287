import { all } from 'redux-saga/effects';

import authSaga from './saga';
import cognitoSaga from './saga/cognitotoken';
import IoTSaga from './saga/IoT';
import cisUsers from './saga/cisUsers';
import dashboardSaga from './saga/dashboard';

export default function* rootSaga() {
  yield all([
    ...authSaga,
    ...cognitoSaga,
    ...IoTSaga,
    ...cisUsers,
    ...dashboardSaga,
  ]);
}
