import { uniqBy } from 'utils';

export function toMakerLookups(data, initial = []) {
  if (data) {
    const makers = data.map((m) => ({ value: m, label: m }));
    return uniqBy([...initial, ...makers], 'value');
  }

  return initial;
}

export function toModuleLookups(
  data,
  mapModules,
  initial = [],
  initialVersions = []
) {
  const returnData = {
    modules: initial,
    versions: initialVersions,
  };

  if (data) {
    // modules
    const modules = data.map((m) => ({
      value: m.viewData.id,
      label: m.viewData.model,
      metadata: {
        power: m.viewData.power,
      },
    }));

    returnData.modules = uniqBy([...initial, ...modules], 'value');

    returnData.modules.forEach((m) => {
      mapModules.set(m.value, m);
    });

    // spec-sheet versions
    const specVersions = [];
    data.forEach((m) => {
      const parentModuleId = m.viewData.id;
      const parentVersion = { parentModuleId, ...m.viewData };
      delete parentVersion.Versions;

      specVersions.push(parentVersion);

      const { versions } = m.viewData;
      versions.forEach((v) => {
        specVersions.push({
          parentModuleId,
          ...v.viewData,
        });
      });
    });

    returnData.versions = [...initialVersions, ...specVersions];
  }
  return returnData;
}

export function toMountingLookups(data, initial = [], mountingSystem = '') {
  let labelMountingSystem = '';
  let mountingSystems = initial;

  if (data) {
    const mountingSystem = data.map(({ viewData }) => ({
      value: viewData.id,
      label: viewData.name,
      data: {
        id: viewData.id,
        pvCadTolarance: viewData.pvCadTolarance,
        validations: viewData.validations,
        rackingSystem: viewData.rackingSystem,
        rowSpacingOptions: viewData.supportedRowSpacingOptions,
        name: viewData.name,
      },
    }));

    mountingSystems = uniqBy([...initial, ...mountingSystem], 'value');
  }

  let i = -1;
  const n = mountingSystems.length;

  while (++i < n) {
    if (mountingSystems[i].value === mountingSystem) {
      labelMountingSystem = mountingSystems[i].label;
      break;
    }
  }

  return [mountingSystems, labelMountingSystem];
}

export function toSpacingLookups(data, mountingSystem, initial = []) {
  if (data && mountingSystem && data.length > 0) {
    const rackingProduct = uniqBy([...data, ...initial], (o) => o.viewData.id);
    const rowSpacing = rackingProduct
      .filter((ms) => ms.viewData.id === mountingSystem)[0]
      .viewData.supportedRowSpacingOptions.map((rs) => ({
        value: rs.option.value,
        label: `${rs.option.value} ${rs.option.unit}`,
      }));

    return rowSpacing;
  }

  const parsedInit =
    initial.length > 0
      ? initial
          .map((rs) => rs.viewData)
          .map((vd) => vd.supportedRowSpacingOptions)[0]
          .map((rso) => ({
            value: rso.option.value,
            label: `${rso.option.value} ${rso.option.unit}`,
          }))
      : [];

  return parsedInit;
}
