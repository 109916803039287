import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { urls } from 'routes';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PointOfSaleOutlined from '@mui/icons-material/PointOfSaleOutlined';
import PermMediaOutlined from '@mui/icons-material//PermMediaOutlined';
import ArchitectureIcon from '@mui/icons-material//Architecture';
import SolarPowerOutlined from '@mui/icons-material/SolarPowerOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import PeopleAltOutlined from '@mui/icons-material/PeopleAltOutlined';
import ApartmentOutlined from '@mui/icons-material/ApartmentOutlined';
import DynamicFormOutlined from '@mui/icons-material/DynamicFormOutlined';
import FontDownloadOutlined from '@mui/icons-material/FontDownloadOutlined';
import {
  ALLOW_API_INSPECTOR,
  ALLOW_MANAGE_ACLS,
  ALLOW_MANAGE_ROLES,
  ALLOW_MANAGE_TENANTS,
  ALLOW_MANAGE_USERS,
  DW_DESIGNER_WORKFLOW,
  DW_ADMIN,
  DW_DESIGNER_MANAGER,
  DW_DESIGNER_VIEW,
  DW_SUBMISSIONS,
  DW_SUBMISSIONS_MANAGER,
} from 'redux/constants/category';
import { isLoaded } from 'redux/selectors/selectors';
import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';
import ShowIfHasPermission from '../ShowIfHasPermission';

class SidebarContent extends PureComponent {
  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { loaded } = this.props;

    if (!loaded) return null;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Dashboard"
            route={urls.dashboard}
            muiIcon={<DashboardOutlinedIcon />}
            exact
          />
          <SidebarLink
            title="Cost Estimator"
            route={urls.estimates}
            muiIcon={<PointOfSaleOutlined />}
          />

          <ShowIfHasPermission
            conditions={[
              `${DW_DESIGNER_WORKFLOW}.show`,
              `${DW_DESIGNER_WORKFLOW}.read`,
            ]}
            execOnlyOneRule
          >
            <SidebarCategory
              title="Designer Workflow"
              muiIcon={<ArchitectureIcon />}
            >
              <ShowIfHasPermission
                conditions={[
                  `${DW_DESIGNER_WORKFLOW}.show`,
                  `${DW_DESIGNER_WORKFLOW}.read`,
                  `${DW_DESIGNER_VIEW}.show`,
                  `${DW_DESIGNER_VIEW}.read`,
                  `${DW_DESIGNER_MANAGER}.show`,
                  `${DW_DESIGNER_MANAGER}.read`,
                  `${DW_ADMIN}.show`,
                  `${DW_ADMIN}.read`,
                ]}
                execOnlyOneRule
              >
                <SidebarLink title="Requests" route={urls.dwRequests} />
              </ShowIfHasPermission>

              <ShowIfHasPermission
                conditions={[
                  `${DW_DESIGNER_WORKFLOW}.show`,
                  `${DW_DESIGNER_WORKFLOW}.read`,
                  `${DW_SUBMISSIONS}.show`,
                  `${DW_SUBMISSIONS}.read`,
                  `${DW_SUBMISSIONS_MANAGER}.show`,
                  `${DW_SUBMISSIONS_MANAGER}.read`,
                  `${DW_DESIGNER_VIEW}.show`,
                  `${DW_DESIGNER_VIEW}.read`,
                  `${DW_DESIGNER_MANAGER}.show`,
                  `${DW_DESIGNER_MANAGER}.read`,
                  `${DW_ADMIN}.show`,
                  `${DW_ADMIN}.read`,
                ]}
                execOnlyOneRule
              >
                <SidebarLink title="Submissions" route={urls.dwSubmissions} />
              </ShowIfHasPermission>

              <ShowIfHasPermission
                conditions={[
                  `${DW_DESIGNER_WORKFLOW}.show`,
                  `${DW_DESIGNER_WORKFLOW}.read`,
                  `${DW_DESIGNER_WORKFLOW}.create`,
                  `${DW_DESIGNER_WORKFLOW}.update`,
                  `${DW_DESIGNER_WORKFLOW}.delete`,
                ]}
                execOnlyOneRule
              >
                <ShowIfHasPermission
                  conditions={[
                    `${DW_DESIGNER_MANAGER}.show`,
                    `${DW_DESIGNER_MANAGER}.read`,
                    `${DW_ADMIN}.show`,
                    `${DW_ADMIN}.read`,
                  ]}
                  execOnlyOneRule
                >
                  <SidebarLink title="Analytics" route={urls.dwAnalytics} />
                </ShowIfHasPermission>

                <ShowIfHasPermission
                  conditions={[`${DW_ADMIN}.show`, `${DW_ADMIN}.read`]}
                  execOnlyOneRule
                >
                  <SidebarLink title="Management" route={urls.dwManagement} />
                </ShowIfHasPermission>
              </ShowIfHasPermission>
            </SidebarCategory>
          </ShowIfHasPermission>

          <SidebarCategory title="Reports" muiIcon={<PermMediaOutlined />}>
            <SidebarLink title="Sales Dashboard" route={urls.sales} />
            <SidebarLink title="Custom" route={urls.reportsCustom} />
            {/* <SidebarLink title="Final Revisions" route={urls.finals} /> */}
          </SidebarCategory>
          <SidebarLink
            title="SMM"
            route={urls.smm}
            muiIcon={<SolarPowerOutlined />}
          />
          <div className="separator" />
          {/* <SidebarLink title="Custom Projects" route={urls.cprojects} /> TODO: Temporary hided */}
          <ShowIfHasPermission conditions={[`${ALLOW_API_INSPECTOR}.show`]}>
            <SidebarLink
              title="API Inspector"
              route={urls.apiInspector}
              muiIcon={<IntegrationInstructionsOutlinedIcon />}
            />
          </ShowIfHasPermission>
          <div className="separator" />
        </ul>
        <ul className="sidebar__block sidebar__footer">
          <ShowIfHasPermission conditions={[`${ALLOW_MANAGE_USERS}.show`]}>
            <SidebarLink
              title="Users & Groups"
              route={urls.users}
              muiIcon={<PeopleAltOutlined />}
            />
          </ShowIfHasPermission>
          {/* <SidebarLink title="CIS Users" route={urls.usersCIS} icon="user" />
          <SidebarLink
            title="Mongo Users"
            route={urls.usersMongo}
            icon="user"
          /> */}
          <ShowIfHasPermission conditions={[`${ALLOW_MANAGE_TENANTS}.show`]}>
            <SidebarLink
              title="Tenants"
              route={urls.tenants}
              muiIcon={<ApartmentOutlined />}
            />
          </ShowIfHasPermission>
          <ShowIfHasPermission conditions={[`${ALLOW_MANAGE_ROLES}.show`]}>
            <SidebarLink
              title="Roles"
              route={urls.roles}
              muiIcon={<FontDownloadOutlined />}
            />
          </ShowIfHasPermission>
          <ShowIfHasPermission conditions={[`${ALLOW_MANAGE_ACLS}.show`]}>
            <SidebarLink
              title="ACLs"
              route={urls.acls}
              muiIcon={<DynamicFormOutlined />}
            />
          </ShowIfHasPermission>
        </ul>
      </div>
    );
  }
}

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loaded: isLoaded(state),
  };
};

export default connect(mapStateToProps)(SidebarContent);
