import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Addon from './Addon';
import HelperText from './HelperText';

import { inputStyles, labelStyles } from './styles';
import './styles.scss';

// import { NUMBER } from 'modules/editor/services/constants';
export const NUMBER = 'number';

const allowedInputTypes = ['text', 'number', 'password', 'tel', 'url', 'email'];

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

function parseFloatToFixed(value, precision) {
  const float = value.toString().split('.');
  if (float.length === 2) {
    if (float[1].length > precision) {
      float[1] = float[1].substring(0, precision);
      return parseFloat(float.join('.'));
    }
    return parseFloat(value);
  }
  return parseFloat(value);
}

function getValue(value = null, precision) {
  if (!value) return '';
  if (precision === 0) {
    const t = Math.floor(value);
    return isNaN(t) ? 0 : t;
  }
  if (precision && isFloat(Number(value)))
    return parseFloatToFixed(value, precision);
  return value;
}

function TextInput({
  type,
  name,
  value = null,
  precision,
  label,
  labelAdditional,
  labelAddOnClick,
  size,
  error,
  helperText,
  helperTextColor,
  onClickHelperText,
  button,
  onButtonClick,
  addon,
  onChange,
  removeSymbols,
  hint,
  orientation,
  customValidate = false,
  max = 0,
  min = 0,
  disabled,
  staticContext, // not needed in the rest
  dispatch, // not needed in the rest
  ...rest
}) {
  const [validationError, setValidationError] = useState(null);
  const inputType = allowedInputTypes.includes(type) ? type : 'text';
  const getInputValue = (value, precision) =>
    removeSymbols
      ? getValue(value, precision).replace(/[^\w\s]/gi, '')
      : getValue(value, precision);

  const classList = {
    labelClass: '',
    inputClass: '',
  };

  if (orientation === 'horizontal') {
    classList.labelClass = 'col-sm-4 control-label';
    classList.inputClass = 'col-sm-8';
  }

  if (orientation === 'vertical') {
    classList.labelClass = 'component-header';
  }

  const validateRange = ({ min = null, max = null, val = null }) => {
    const errTextStart = 'Should be';
    if ((!min && !max) || !val) return null;
    if (min && max)
      return Number(val) >= Number(min) && Number(val) <= Number(max)
        ? null
        : `${errTextStart} between ${min} and ${max}!`;
    if (min && !max)
      return Number(val) >= Number(min)
        ? null
        : `${errTextStart} greater than ${min}!`;
    if (!min && max)
      return Number(val) <= Number(max)
        ? null
        : `${errTextStart} less than ${max}!`;
    return null;
  };

  const handleOnchange = (evt) => {
    const tmpVal = evt.target.value;
    let val = tmpVal;
    if (!(precision === 0 && +tmpVal === 0)) {
      val = getInputValue(tmpVal, precision);
    }

    if (inputType.toLowerCase() === NUMBER && customValidate)
      setValidationError(validateRange({ min, max, val }));
    onChange(evt);
  };

  const handleOnKeyDown = (e) =>
    e.target.value &&
    (e.keyCode === 69 || e.keyCode === 190) &&
    e.preventDefault();

  return (
    <div className="form-group">
      <label
        htmlFor={name}
        className={classList.labelClass}
        style={labelStyles[size]}
      >
        {label}
        {labelAdditional && (
          <sup onClick={labelAddOnClick}>({labelAdditional})</sup>
        )}
      </label>
      {helperText && (
        <HelperText
          text={helperText}
          color={helperTextColor}
          onClick={onClickHelperText}
        />
      )}

      <div className={classList.inputClass}>
        <div className="input-group" style={{ width: '100%' }}>
          <input
            type={inputType}
            style={inputStyles[size]}
            className={`form-control ${error ? 'is-invalid' : ''}`}
            name={name}
            value={getInputValue(value, precision)}
            id={name}
            {...(inputType.toLowerCase() === NUMBER && precision === 0
              ? { onKeyDown: handleOnKeyDown }
              : {})}
            onChange={handleOnchange}
            disabled={disabled}
            {...rest}
          />
          {button && (
            <Button
              onClick={onButtonClick}
              size={size}
              text={button}
              disabled={disabled}
            />
          )}
          {addon && <Addon text={addon} />}
          {hint && (
            <div className="pc-input-hint">
              Password should be 12 characters, at least one number, and one
              symbol
            </div>
          )}
        </div>
        {(error || validationError) && (
          <div className="pc-inline pull-right errorMessages">
            <span className="invalid-feedback">{error || validationError}</span>
          </div>
        )}
      </div>
    </div>
  );
}

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  precision: PropTypes.number,
  label: PropTypes.string,
  labelAdditional: PropTypes.string,
  labelAddOnClick: PropTypes.func,
  size: PropTypes.string,
  error: PropTypes.string,
  button: PropTypes.string,
  onButtonClick: PropTypes.func,
  helperText: PropTypes.string,
  onClickHelperText: PropTypes.func,
  helperTextColor: PropTypes.string,
  addon: PropTypes.string,
  orientation: PropTypes.string,
};

TextInput.defaultProps = {
  name: '',
  type: 'string',
  value: '',
  precision: null,
  label: null,
  size: 'medium',
  error: '',
  button: null,
  onButtonClick: () => {},
  helperText: null,
  helperTextColor: '#5e5ee2',
  onClickHelperText: () => {},
  addon: null,
  orientation: 'vertical',
  labelAdditional: '',
  labelAddOnClick: () => {},
};

export default TextInput;
