import { FAILED, SUCCEEDED } from 'redux/constants/category';
import {
  INITIALIZING,
  INITIALIZING_SUCCEEDED,
  INITIALIZING_FAILED,
  SIGN_UP_PROGRESSING,
  SIGN_UP_SUCCEEDED,
  SIGN_UP_FAILED,
  SIGN_UP_RESET_ERRORS,
  SIGN_IN_PROGRESSING,
  SIGN_IN_SUCCEEDED,
  SIGN_IN_FAILED,
  SIGN_IN_RESET_ERRORS,
  SIGN_OUT_SUCCEEDED,
  ID_TOKEN_SUCCEEDED,
  ID_TOKEN_FAILED,
  USER_SET_REQUESTED,
  SET_USER_IDS,
  HELIOSCOPE_LOGIN_REQUESTED,
  HELIOSCOPE_LOGIN_SUCCEEDED,
  HELIOSCOPE_LOGIN_FAILED,
  HELIOSCOPE_LOGOUT,
  ACL_SET_REQUESTED,
} from '../authTypes';

const initialState = {
  token: null,
  permissions: null,
  user: null,
  acl: null,
  credentials: null,
  refresh: false,
  tokenExpirationTime: 0,
  initializing: false,
  initStatus: null,
  signUp: {
    registering: false,
    error: null,
    success: false,
  },
  signIn: {
    authenticating: false,
    error: null,
  },
  // viewOptionsConfig: viewOptions,
  userIds: null,
  helioscope: {
    sessionId: null,
    initializing: false,
    error: null,
  },
};

export default function authReducer(state = initialState, action = {}) {
  const {
    type,
    token,
    user,
    error,
    refresh,
    tokenExpirationTime,
    refreshToken,
    userIds,
    sessionId,
    acl,
  } = action;

  switch (type) {
    case INITIALIZING:
      return {
        ...state,
        initializing: true,
      };

    case INITIALIZING_SUCCEEDED:
      return {
        ...state,
        token,
        user,
        tokenExpirationTime,
        refreshToken,
        initializing: false,
        initStatus: SUCCEEDED,
      };

    case INITIALIZING_FAILED:
      return {
        ...state,
        initializing: false,
        initStatus: FAILED,
      };

    case HELIOSCOPE_LOGIN_REQUESTED: {
      return {
        ...state,
        helioscope: { initializing: true, error: null },
      };
    }

    case HELIOSCOPE_LOGIN_SUCCEEDED: {
      return {
        ...state,
        helioscope: {
          initializing: false,
          sessionId,
        },
      };
    }

    case HELIOSCOPE_LOGIN_FAILED: {
      return {
        ...state,
        helioscope: {
          initializing: false,
          error,
        },
      };
    }

    case HELIOSCOPE_LOGOUT: {
      return {
        ...state,
        helioscope: {
          sessionId: null,
        },
      };
    }

    case SIGN_OUT_SUCCEEDED:
      return {
        ...state,
        token: null,
        user: null,
      };

    case SIGN_UP_PROGRESSING:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          registering: true,
        },
      };

    case SIGN_UP_SUCCEEDED:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          registering: false,
          success: true,
        },
      };

    case SIGN_UP_FAILED:
      return {
        ...state,
        signUp: {
          ...state.signUp,
          registering: false,
          error,
        },
      };

    case SIGN_UP_RESET_ERRORS:
      return {
        ...state,
        user,
        token,
        signUp: {
          registering: false,
          error: null,
          success: false,
        },
      };

    case SIGN_IN_PROGRESSING:
      return {
        ...state,
        signIn: {
          ...state.signIn,
          authenticating: true,
        },
      };

    case SIGN_IN_SUCCEEDED:
      return {
        ...state,
        user,
        token,
        tokenExpirationTime,
        refreshToken,
        signIn: {
          ...state.signIn,
          authenticating: false,
        },
      };

    case SIGN_IN_FAILED:
      return {
        ...state,
        signIn: {
          ...state.signIn,
          authenticating: false,
          error,
        },
      };

    case SIGN_IN_RESET_ERRORS:
      return {
        ...state,
        signIn: {
          ...state.signIn,
          authenticating: false,
          error: null,
        },
      };

    case ID_TOKEN_SUCCEEDED:
      return {
        ...state,
        user,
        token,
        tokenExpirationTime,
        refreshToken,
      };

    case ID_TOKEN_FAILED:
      return {
        ...state,
        refresh,
      };

    case SET_USER_IDS:
      return {
        ...state,
        userIds,
      };

    case USER_SET_REQUESTED: {
      const data = {
        ...state,
        token,
        user: {
          ...state.user,
          ...user,
        },
      };
      return data;
    }

    case ACL_SET_REQUESTED: {
      const data = {
        ...state,
        acl,
      };
      return data;
    }

    default:
      return state;
  }
}
