import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Card, Col, Row } from 'reactstrap';

import { FormControlLabel, FormGroup } from '@material-ui/core';

import Fetch from 'components/common/Fetch';
import SelectInput from 'components/common/SelectInput';

import TextInput from 'components/common/TextInput';

import Badge from '@material-ui/core/Badge';

import { isObject } from 'utils';

import { TableCheckbox } from 'components/common/MaterialTable/components/MaterialTableElements';

import { api } from 'utils/fetch';

import {
  DEFAULT_VALUES,
  DELIMITER,
} from 'containers/Estimates/Estimate/constants';

import configFields from './config';

import {
  toLookups,
  toLookupsSalespersons,
  toLookupsMaTypes,
  toLookupsStamps,
  toLookupsManufacturersToMembranes,
} from './utils';

import './assets/style.scss';

const stateComponents = {
  salesPersons: {},
  tiers: {},
  mechanicalAttachments: {},
  membranes: {},
  stamps: {},
};

function FieldsExtended({
  values,
  config,
  disabled,
  showStepsBadges,
  touched,
  errors,
  onChange,
  onHandleState,
  isPowerCap,
}) {
  const [fields, setFields] = useState({});

  const [membranesLoading, setMembranesLoading] = useState(false);
  const [membranesData, setMembranesData] = useState([]);

  useEffect(() => {
    loadMembranes();

    return () => {
      stateComponents.salesPersons = {};
      stateComponents.tiers = {};
      stateComponents.mechanicalAttachments = {};
      stateComponents.membranes = {};
      stateComponents.stamps = {};
    };
  }, []);

  useEffect(() => {
    const formattedFields = {
      discount: 0,
      salesTaxRate: 0,
      salesPerson: null,
      tier: null,
      mechanicalAttachment: null,
      manufacturer: null,
      membrane: null,
      stamp: null,
      includeShipping: true,
      includeSignature: true,
    };

    if (isPowerCap && values.manufacturer === DEFAULT_VALUES.POWER_CAP.MANUFACTURER && membranesData.length) {
      const manufacturMembrane = membranesData.find((item) => item.label?.includes(values.manufacturer) && item.value?.includes(values.membrane));

      if (manufacturMembrane) {
        const [manufacturer, membrane] = manufacturMembrane.value.split(
          DELIMITER
        );
        formattedFields.manufacturer = manufacturer;
        formattedFields.membrane = membrane;
        onChange({
          name: 'manufacturerMembrane',
          value: {
            manufacturer,
            membrane,
          },
        });
      }
    }

    if (isObject(values)) {
      formattedFields.discount = values.discount || 0;
      formattedFields.salesPerson = values.salesPerson || null;
      formattedFields.tier = values.tier;
      formattedFields.mechanicalAttachment =
        values.mechanicalAttachment || null;
      formattedFields.manufacturer = values.manufacturer || null;
      formattedFields.membrane = values.membrane || null;
      formattedFields.stamp = values.stamp || null;
      formattedFields.salesTaxRate = values.salesTaxRate || null;

      if (typeof values.includeShipping !== 'undefined') {
        formattedFields.includeShipping = !!values.includeShipping;
      }

      if (typeof values.includeSignature !== 'undefined') {
        formattedFields.includeSignature = !!values.includeSignature;
      }
    }

    setFields(formattedFields);

    return () => {};
  }, [values, membranesData]);

  const onChangeField = useCallback(
    (e) => {
      let {
        target: { name, value, checked },
      } = e;

      if (name === 'includeShipping' || name === 'includeSignature') {
        value = checked;
      }

      onChange({
        name,
        value,
      });
    },
    [onChange]
  );

  const onSelectField = useCallback(
    (name, value) => {
      if (name === 'membrane') {
        const [manufacturer, membrane] = value.split(DELIMITER);

        onChange({
          name: 'manufacturerMembrane',
          value: {
            manufacturer,
            membrane,
          },
        });

        return;
      }

      onChange({
        name,
        value,
      });
    },
    [onChange]
  );

  const checkIfLoaded = () => {
    if (stateComponents.loaded) return;

    const {
      salesPersons,
      tiers,
      mechanicalAttachments,
      membranes,
      stamps,
    } = stateComponents;

    stateComponents.loaded =
      salesPersons.loaded &&
      tiers.loaded &&
      mechanicalAttachments.loaded &&
      membranes.loaded &&
      stamps.loaded;

    if (stateComponents.loaded) {
      onHandleState({ name: 'loaded', value: true });
    }
  };

  const loadMembranes = useCallback(async () => {
    setMembranesLoading(true);

    const manufacturers = await api.getRoofManufacturers();

    const membranes = await api.getRoofMembranes();

    const data = toLookupsManufacturersToMembranes(
      manufacturers.data,
      membranes.data
    );

    setMembranesData(data);

    stateComponents.membranes.loaded = true;

    checkIfLoaded();

    setMembranesLoading(false);
  });

  return (
    <Card className="estimate-project-view">
      {showStepsBadges && (
        <div className="card__title card__title_margin-left">
          <Badge color={disabled ? 'secondary' : 'primary'} badgeContent="5" />
        </div>
      )}
      <Row>
        <Col md={12} lg={12} xl={6}>
          <div style={{ width: '100%' }}>
            <Fetch
              url="wes/estimates/salespersons"
              render={({ data, loading }) => {
                const options = toLookupsSalespersons(
                  data,
                  configFields.salesPersons
                );

                stateComponents.salesPersons.loaded = true;

                checkIfLoaded();

                return (
                  <SelectInput
                    label={config.fields.salesPerson.ui.label}
                    name="salesPerson"
                    isLoading={loading}
                    disabled={disabled}
                    options={options}
                    onChange={onSelectField}
                    value={fields.salesPerson}
                    error={errors.salesPerson}
                  />
                );
              }}
            />
            <Fetch
              url="wes/data/modulemakers"
              render={({ data, loading }) => {
                const options = toLookups(data, configFields.tiers);

                stateComponents.tiers.loaded = true;

                checkIfLoaded();

                return (
                  <SelectInput
                    label={config.fields.tier.ui.label}
                    name="tier"
                    isLoading={loading}
                    disabled={disabled}
                    options={options}
                    onChange={onSelectField}
                    value={fields.tier}
                    error={errors.tier}
                  />
                );
              }}
            />
            <TextInput
              label={config.fields.discount.ui.label}
              name="discount"
              size="small"
              disabled={disabled}
              onChange={onChangeField}
              onWheel={(event) => event.currentTarget.blur()}
              value={fields.discount}
              placeholder=""
              type="text"
              customValidate
              precision={2}
              min={0}
              max={100}
            />
            <TextInput
              label={config.fields.salesTaxRate.ui.label}
              name="salesTaxRate"
              size="small"
              disabled={disabled}
              onChange={onChangeField}
              onWheel={(event) => event.currentTarget.blur()}
              value={fields.salesTaxRate}
              placeholder=""
              type="text"
              customValidate
              precision={2}
              min={0}
              max={100}
            />
            <FormGroup
              aria-label="Include Signature"
              className="column-reverse"
            >
              <FormControlLabel
                control={
                  <TableCheckbox
                    checked={!!fields.includeSignature}
                    onChange={onChangeField}
                    name="includeSignature"
                    label="Include Signature"
                    disabled={disabled}
                    styleType="colored-click"
                    color="primary"
                  />
                }
                label="Include Signature"
                labelPlacement="start"
              />
            </FormGroup>
          </div>
        </Col>
        <Col md={12} lg={12} xl={6}>
          <Fetch
            url="wes/data/matypes"
            render={({ data, loading }) => {
              const options = toLookupsMaTypes(
                data,
                configFields.mechanicalAttachments
              );

              stateComponents.mechanicalAttachments.loaded = true;

              checkIfLoaded();

              return (
                !isPowerCap && (
                  <SelectInput
                    label={config.fields.mechanicalAttachment.ui.label}
                    name="mechanicalAttachment"
                    isLoading={loading}
                    disabled={disabled}
                    options={options}
                    onChange={onSelectField}
                    value={fields.mechanicalAttachment}
                    error={
                      touched.mechanicalAttachment &&
                      errors.mechanicalAttachment
                    }
                  />
                )
              );
            }}
          />

          {!isPowerCap && (
            <SelectInput
              label="Manufacturer and Membrane"
              name="membrane"
              isLoading={membranesLoading}
              disabled={disabled}
              options={membranesData}
              onChange={onSelectField}
              value={`${fields.manufacturer}${DELIMITER}${fields.membrane}`}
              error={touched.membrane && errors.membrane}
            />
          )}

          <Fetch
            url={
              fields.mechanicalAttachment
                ? `wes/data/masExtended/${fields.mechanicalAttachment}`
                : ''
            }
            render={({ data, loading }) => {
              const options = toLookupsStamps(null, configFields.stamps);

              stateComponents.stamps.loaded = true;

              checkIfLoaded();

              return (
                <SelectInput
                  label={config.fields.stamp.ui.label}
                  name="stamp"
                  isLoading={loading}
                  disabled={disabled}
                  options={options}
                  onChange={onSelectField}
                  value={fields.stamp}
                  error={touched.stamp && errors.stamp}
                />
              );
            }}
          />
          <div>
            <FormGroup aria-label="Include Shipping">
              <FormControlLabel
                control={
                  <TableCheckbox
                    checked={!!fields.includeShipping}
                    onChange={onChangeField}
                    name="includeShipping"
                    label="Include Shipping"
                    disabled={disabled}
                    styleType="colored-click"
                    color="primary"
                  />
                }
                label="Include Shipping"
                labelPlacement="start"
              />
            </FormGroup>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

FieldsExtended.propTypes = {
  values: PropTypes.shape({
    discount: PropTypes.string,
    salesPerson: PropTypes.string,
    tier: PropTypes.string,
    mechanicalAttachment: PropTypes.string,
    manufacturer: PropTypes.string,
    membrane: PropTypes.string,
    stamp: PropTypes.string,
    includeShipping: PropTypes.bool,
    includeSignature: PropTypes.bool,
  }),
  config: PropTypes.any,
  errors: PropTypes.shape({
    discount: PropTypes.string,
    salesPerson: PropTypes.string,
    tier: PropTypes.string,
    mechanicalAttachment: PropTypes.string,
    manufacturer: PropTypes.string,
    membrane: PropTypes.string,
    stamp: PropTypes.string,
    includeShipping: PropTypes.string,
    includeSignature: PropTypes.string,
  }),
  touched: PropTypes.shape({
    discount: PropTypes.bool,
    salesPerson: PropTypes.bool,
    tier: PropTypes.bool,
    mechanicalAttachment: PropTypes.bool,
    manufacturer: PropTypes.bool,
    membrane: PropTypes.bool,
    stamp: PropTypes.bool,
    includeShipping: PropTypes.bool,
    includeSignature: PropTypes.bool,
  }),
  restricted: PropTypes.shape({
    discount: PropTypes.bool,
    salesPerson: PropTypes.bool,
    tier: PropTypes.bool,
    mechanicalAttachment: PropTypes.bool,
    manufacturer: PropTypes.bool,
    membrane: PropTypes.bool,
    stamp: PropTypes.bool,
    includeShipping: PropTypes.bool,
    includeSignature: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  showStepsBadges: PropTypes.bool,
  onChange: PropTypes.func,
  onHandleState: PropTypes.func,
  isPowerCap: PropTypes.bool,
};

FieldsExtended.defaultProps = {
  values: {
    discount: 0,
    salesPerson: null,
    tier: null,
    mechanicalAttachment: null,
    manufacturer: null,
    membrane: null,
    stamp: null,
    includeShipping: true,
    includeSignature: true,
  },
  config: {},
  errors: PropTypes.shape({
    discount: '',
    salesPerson: '',
    tier: '',
    mechanicalAttachment: '',
    manufacturer: '',
    membrane: '',
    stamp: '',
    includeShipping: '',
    includeSignature: '',
  }),
  touched: PropTypes.shape({
    discount: false,
    salesPerson: false,
    tier: false,
    mechanicalAttachment: false,
    manufacturer: false,
    membrane: false,
    stamp: false,
    includeShipping: false,
    includeSignature: false,
  }),
  restricted: PropTypes.shape({
    discount: false,
    salesPerson: false,
    tier: false,
    mechanicalAttachment: false,
    manufacturer: false,
    membrane: false,
    stamp: false,
    includeShipping: false,
    includeSignature: false,
  }),
  disabled: false,
  showStepsBadges: false,
  onChange: () => {},
  onHandleState: () => {},
  isPowerCap: false,
};

export default connect()(FieldsExtended);
