/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Row, Col, CardBody } from 'reactstrap';

import { isObject } from 'utils';

import Loader from 'components/Loader/Loader';
import { Card } from 'react-bootstrap';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  setAPIInspectorSelectedTableName,
  setAPIInspectorHidedHeader,
  changeFilterOptions,
} from 'redux/actions/dataViewActions';

import loaderActions from 'redux/actions/loaderActions';

import { CallMade as FilterIcon } from 'components/MaterialTable/Tools/columnsFilterTool';

import { api } from 'utils/fetch';
import Cache from 'utils/Cache';

import Header from 'components/Header';
import ListTables from 'components/ListTables';
import ListFields from 'components/ListFields';

import Helioscope from './components/Helioscope';
import Table from './components/Table';

import { APIINSPECTOR_COLUMNS_FILTER_NAME, HELIOSCOPE } from './constants';

import './assets/style.scss';

class ApiInspector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef(null);

    this.state = {
      loaded: false,
      disabled: false,
      source: null,
      sources: [],
      loadingTables: true,
      loadingFields: true,
      expanded: false,
      headers: [],
      additionalFilter: null,
      viewedTableNames: [],
      notSupportedTables: false,
      selectedTableName: null,
      tables: [],
      fields: [],
      unselectedFields: [],
    };

    this.mapSources = {};

    this.tables = [];
    this.fields = [];

    this.needChangeFields = false;
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { source } = this.state;

    const {
      helioscopeTableName,
      helioscopeHeaders,
      helioscopeTables,
      helioscopeHidedColumns,
      search,
    } = this.props;

    let selectedTableName;
    let tables;
    let fields;
    let unselectedFields;

    let changedTableName = false;

    let needFilterTables = true;
    let needFilterFields = true;

    const forceUpdate = prevState.source !== source;

    if (source === HELIOSCOPE) {
      selectedTableName = helioscopeTableName;
      unselectedFields = helioscopeHidedColumns[selectedTableName]; // || {};

      if (
        prevProps.helioscopeTableName !== helioscopeTableName ||
        prevProps.helioscopeHidedColumns !== helioscopeHidedColumns ||
        prevProps.helioscopeHidedColumns?.[selectedTableName] !==
          helioscopeHidedColumns?.[selectedTableName] ||
        forceUpdate
      ) {
        changedTableName = true;
        this.setState({
          selectedTableName,
          unselectedFields,
        });
      }

      if (prevProps.helioscopeTables !== helioscopeTables || forceUpdate) {
        tables = helioscopeTables.map((h) => {
          return {
            Name: h.project_id || h.design_id || h.field_segment_id,
            Label: h.name,
            metadata: h,
          };
        });

        tables = Array.isArray(tables)
          ? filterTables(tables, search.listTables)
          : [];
        needFilterTables = false;

        this.tables = tables;

        this.setState({
          tables,
        });
      } else {
        tables = this.tables;
      }

      if (prevProps.helioscopeHeaders !== helioscopeHeaders || forceUpdate) {
        fields = helioscopeHeaders.map((h) => {
          return {
            field: h.field,
            title: h.title,
          };
        });

        fields = Array.isArray(fields)
          ? filterTables(fields, search.listFields)
          : [];
        needFilterFields = false;

        this.fields = fields;

        this.setState({
          fields,
        });
      } else {
        fields = this.fields;
      }
    } else {
      selectedTableName = this.props.selectedTableName;
      unselectedFields = this.props.hidedColumns[selectedTableName]; // || {};
      tables = this.props.tables;
      fields = this.props.headers[selectedTableName]; // || headers;

      if (
        prevProps.selectedTableName !== this.props.selectedTableName ||
        forceUpdate
      ) {
        changedTableName = true;
        this.setState({
          selectedTableName,
        });
      }

      if (prevProps.tables !== this.props.tables || forceUpdate) {
        tables = Array.isArray(tables)
          ? filterTables(tables, search.listTables)
          : [];
        needFilterTables = false;
        this.setState({
          tables,
        });
      }

      if (
        prevProps.headers?.[selectedTableName] !==
          this.props.headers?.[selectedTableName] ||
        changedTableName ||
        forceUpdate
      ) {
        fields = Array.isArray(fields)
          ? filterTables(fields, search.listFields)
          : [];
        needFilterFields = false;
        this.setState({
          fields,
        });
      }

      if (
        prevProps.hidedColumns !== this.props.hidedColumns ||
        prevProps.hidedColumns?.[selectedTableName] !==
          this.props.hidedColumns?.[selectedTableName] ||
        changedTableName ||
        forceUpdate
      ) {
        this.setState({
          unselectedFields,
        });
      }
    }

    if (prevProps.search.listTables !== search.listTables) {
      if (needFilterTables) {
        tables = Array.isArray(tables)
          ? filterTables(tables, search.listTables)
          : [];
        this.setState({
          tables,
        });
      }
    }

    if (prevProps.search.listFields !== search.listFields) {
      if (needFilterFields) {
        fields = Array.isArray(fields)
          ? filterTables(fields, search.listFields)
          : [];
        this.setState({
          fields,
        });
      }
    }
  }

  init = async () => {
    const result = await api.getApiInspectorSources();

    if (Array.isArray(result.data)) {
      const sources = result.data.map(
        ({ name, label, options: metadata, startTableName }) => {
          const options = {
            label,
            value: name,
            metadata,
            startTableName,
          };

          this.mapSources[name] = options;

          return {
            label,
            value: name,
          };
        }
      );

      const source = sources[0]?.value ?? null;

      this.setState({
        source,
        sources,
        notSupportedTables: !!this.mapSources[source]?.metadata
          ?.tableNavigationDisabled,
      });

      this.refresh(source);
    }

    this.setState({
      loaded: true,
    });
  };

  refresh = (source) => {
    const {
      helioscopeTableName,
      helioscopeHeaders,
      helioscopeTables,
      helioscopeHidedColumns,
      search,
    } = this.props;

    let selectedTableName;
    let tables;
    let fields;
    let unselectedFields;

    if (source === HELIOSCOPE) {
      // TODO! ONLY FOR TESTION. NEED OPTIMIZATION!
      selectedTableName = helioscopeTableName;
      tables = helioscopeTables.map((h) => {
        return {
          Name: h.project_id || h.design_id || h.field_segment_id,
          Label: h.name,
          metadata: h,
        };
      });
      fields = helioscopeHeaders.map((h) => {
        return {
          field: h.field,
          title: h.title,
        };
      });
      unselectedFields = helioscopeHidedColumns[selectedTableName] || {};
    } else {
      selectedTableName = this.props.selectedTableName;
      tables = this.props.tables;
      fields = this.props.headers[selectedTableName];
      unselectedFields = this.props.hidedColumns[selectedTableName] || {};
    }

    tables = filterTables(tables, search.listTables);
    fields = filterTables(fields, search.listFields);

    this.setState({
      selectedTableName,
      tables,
      fields,
      unselectedFields,
    });
  };

  onExpandPanel = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  onEventHeader = (data) => {
    if (isObject(data)) {
      this.setState({
        [data.data.name]: data.data.value,
      });

      if (data.data.name === 'source') {
        const notSupportedTables = !!this.mapSources[data.data.value]?.metadata
          ?.tableNavigationDisabled;

        this.setState({
          notSupportedTables,
        });

        this.setLoading(true);

        this.resetSearch();
      }
    }
  };

  onEventListTables = (data) => {
    if (isObject(data)) {
      this.resetSearchFields();

      if (this.state.source === HELIOSCOPE) {
        this.tableRef.current.changeTable(
          data.data.name,
          data.data.value,
          data.data.metadata
        );
        return;
      }

      this.props.setSelectedTableName(data.data.value);

      this.setState({
        [data.data.name]: data.data.value,
      });
    }
  };

  onEventListFields = (data) => {
    if (isObject(data)) {
      const { source } = this.state;

      if (source === HELIOSCOPE) {
        this.tableRef.current.selectColumn(data.data.name, data.data.value);
        return;
      }

      this.onChangedFields(data);
    }
  };

  onChangedFields = (data) => {
    const { hidedColumns, setHidedColumns, selectedTableName } = this.props;

    let needUpdate = false;

    if (data.data.value) {
      if (!hidedColumns[selectedTableName]) {
        hidedColumns[selectedTableName] = {};
      }

      hidedColumns[selectedTableName][data.data.name] = true;

      needUpdate = true;
    } else if (hidedColumns[selectedTableName]?.[data.data.name]) {
      delete hidedColumns[selectedTableName][data.data.name];
      needUpdate = true;
    }

    if (needUpdate) {
      hidedColumns[selectedTableName] = {
        ...hidedColumns[selectedTableName],
      };

      Cache.setValue(APIINSPECTOR_COLUMNS_FILTER_NAME, hidedColumns);

      setHidedColumns({ ...hidedColumns });
    }
  };

  onEventHS = (data) => {
    if (data?.action === 'loaded') {
      this.setLoading(false);
    }
  };

  onEventTable = (data) => {
    if (data?.action === 'loading') {
      this.setState({
        loadingFields: true,
      });
      return;
    }

    if (data?.action === 'loaded') {
      this.setLoading(false);
    }
  };

  setLoading = (loading) => {
    this.setState({
      loadingTables: loading,
      loadingFields: loading,
    });
  };

  getComponentTable = () => {
    const { source, additionalFilter, viewedTableNames } = this.state;
    const { setTableLoading, tables, headers } = this.props;

    if (source === HELIOSCOPE) {
      return (
        <Helioscope
          ref={this.tableRef}
          tableName={this.mapSources[source].startTableName}
          rootTableName={this.mapSources[source].startTableName}
          options={this.mapSources[source].metadata}
          onEvent={this.onEventHS}
        />
      );
    }

    return (
      <Table
        ref={this.tableRef}
        source={source}
        setTableLoading={setTableLoading}
        headers={headers}
        tables={tables}
        additionalFilter={additionalFilter}
        showRevertTool={viewedTableNames.length > 0}
        options={this.mapSources[source].metadata}
        onEvent={this.onEventTable}
      />
    );
  };

  getLoader = () => {
    return (
      <div className="loader">
        <Loader loading />
      </div>
    );
  };

  getContent = () => {
    const {
      disabled,
      source,
      sources,
      expanded,
      loadingTables,
      loadingFields,
      notSupportedTables,
      selectedTableName,
      unselectedFields,
      tables,
      fields,
    } = this.state;

    const { search } = this.props;

    return (
      <>
        <Header
          disabled={disabled}
          title="API Inspector"
          source={source}
          sources={sources}
          onEvent={this.onEventHeader}
        />

        <Row style={{ display: 'flex' }}>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Accordion
                  className="accordion"
                  expanded={expanded === 'panelSelectData'}
                  onChange={this.onExpandPanel('panelSelectData')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="filter-select-data">
                      <FilterIcon />
                      <div className="filter-select-data__title">
                        Select Data
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row style={{ width: '100%' }}>
                      <Col md={6} lg={6} xl={6}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <Typography
                            variant="h7"
                            component="span"
                            sx={{ flexGrow: 1 }}
                            className="api-inspector-title"
                          >
                            Table
                          </Typography>
                          <div style={{ marginLeft: '10px', width: '50%' }}>
                            {notSupportedTables ? (
                              <Typography
                                variant="h7"
                                component="span"
                                sx={{ flexGrow: 1 }}
                                className="api-inspector-title__not_supported"
                              >
                                list is not supported
                              </Typography>
                            ) : (
                              <ListTables
                                id="apiInspectorListTables"
                                item={selectedTableName}
                                items={tables}
                                loading={loadingTables}
                                onEvent={this.onEventListTables}
                                search={search.listTables}
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                          <Typography
                            variant="h7"
                            component="span"
                            sx={{ flexGrow: 1 }}
                            className="api-inspector-title"
                          >
                            Fields
                          </Typography>
                          <div style={{ marginLeft: '10px', width: '50%' }}>
                            <ListFields
                              id="apiInspectorListFields"
                              unselectedItems={unselectedFields}
                              items={fields}
                              loading={loadingFields}
                              onEvent={this.onEventListFields}
                              search={search.listFields}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="api-inspector-elem-table">
          <Col md={12} lg={12} xl={12}>
            {this.getComponentTable()}
          </Col>
        </Row>
      </>
    );
  };

  resetSearch = () => {
    this.props.changeFilterOptions({
      tableName: 'apiInspectorListTables',
      filterOptions: {
        search: '',
        changedBy: 'search',
      },
    });

    this.resetSearchFields();
  };

  resetSearchFields = () => {
    this.props.changeFilterOptions({
      tableName: 'apiInspectorListFields',
      filterOptions: {
        search: '',
        changedBy: 'search',
      },
    });
  };

  render() {
    return (
      <Container className="api-inspector-page">
        {this.state.loaded ? this.getContent() : this.getLoader()}
      </Container>
    );
  }
}

const mapStateToProps = ({
  dataView: {
    hellioscope,
    apiInspector,
    apiInspectorListTables,
    apiInspectorListFields,
  },
  auth,
  loader: { loading, tableLoading },
}) => {
  let helioscopeTableName = null;
  let helioscopeHeaders = {};
  let helioscopeTables = {};
  let helioscopeHidedColumns = {};

  if (hellioscope) {
    const {
      headers,
      tables,
      currentTable,
      hidedColumns,
      selectedId,
    } = hellioscope;

    helioscopeTableName = currentTable;
    helioscopeHeaders = headers[currentTable] || [];
    helioscopeTables = tables[currentTable] || [];

    if (isObject(helioscopeTables)) {
      if (helioscopeTables[selectedId]) {
        helioscopeTables = helioscopeTables[selectedId];
      } else {
        helioscopeTables = Object.values(helioscopeTables)[0] || [];
      }
    }

    helioscopeHidedColumns = hidedColumns || {};
  }

  return {
    token: auth?.token,
    helioscopeTableName,
    helioscopeHeaders,
    helioscopeTables,
    helioscopeHidedColumns,
    loading: loading || tableLoading,
    tables: apiInspector.tables,
    headers: apiInspector.headers,
    hidedColumns: apiInspector.hidedColumns,
    selectedTableName: apiInspector.selectedTableName,
    search: {
      listTables: apiInspectorListTables?.search || '',
      listFields: apiInspectorListFields?.search || '',
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTableLoading: (loading) => {
    dispatch(loaderActions.setTableLoading(loading));
  },
  setHidedColumns: (hidedColumns) => {
    dispatch(setAPIInspectorHidedHeader(hidedColumns));
  },
  setSelectedTableName: (name) => {
    dispatch(setAPIInspectorSelectedTableName(name));
  },
  changeFilterOptions: (filterOptions) => {
    dispatch(changeFilterOptions(filterOptions));
  },
});

ApiInspector.propTypes = {
  setTableLoading: PropTypes.func,
  helioscopeTableName: PropTypes.string,
  helioscopeHeaders: PropTypes.object,
  helioscopeTables: PropTypes.object,
  helioscopeHidedColumns: PropTypes.object,
  headers: PropTypes.object,
  tables: PropTypes.array,
  hidedColumns: PropTypes.object,
  selectedTableName: PropTypes.string,
  setSelectedTableName: PropTypes.func,
  setHidedColumns: PropTypes.func,
  changeFilterOptions: PropTypes.func,
};

ApiInspector.defaultProps = {
  setTableLoading: () => {},
  helioscopeTableName: null,
  helioscopeHeaders: {},
  helioscopeTables: {},
  helioscopeHidedColumns: {},
  headers: {},
  tables: [],
  hidedColumns: {},
  selectedTableName: '',
  setSelectedTableName: () => {},
  setHidedColumns: () => {},
  changeFilterOptions: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiInspector);

function filterTables(tables, search) {
  if (!search) {
    return tables;
  }

  const searchLower = search.toLowerCase();

  return tables.filter((t) => {
    const label = t.label || t.title || '';
    return label.toLowerCase().includes(searchLower);
  });
}
