import React from 'react';
import PropTypes from 'prop-types';

function Spinner({ size, highlight }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={!highlight ? 'white' : ''}
    >
      <path
        opacity={highlight ? '0.25' : '0.5'}
        d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
      />
      <path
        d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z"
        fill={highlight ? '#0a4f77' : ''}
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 16 16"
          to="360 16 16"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
}

Spinner.propTypes = {
  size: PropTypes.number,
  highlight: PropTypes.bool,
};

Spinner.defaultProps = {
  size: 32,
  highlight: true,
};

export default Spinner;
