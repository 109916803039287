/* eslint-disable no-console */
import { call, put, takeLatest } from 'redux-saga/effects';
import cisUsersActions, { CIS_USER_ACTIVATE } from '../actions/cisUsersActions';
import Toaster from 'components/ToasterQueue';

function* activateCisUser({ data }) {
  const { FirstName, LastName } = data;
  try {
    const res = yield call(cisUsersActions.activateCisUser, data);
    yield put(cisUsersActions.aActivateCisUserSuccess());
    yield call(cisUsersActions.notifyCISUser, res.data);
    yield put(cisUsersActions.aNotifyCISUser());
    Toaster.success(
      `User ${FirstName} ${LastName} has been successfully activated`
    );
  } catch (error) {
    yield put(cisUsersActions.aActivateCisUserFail());
    Toaster.error(`Error: ${error}`);
  }
}

export default [takeLatest(CIS_USER_ACTIVATE, activateCisUser)];
