/* eslint-disable no-param-reassign */

import { shallow } from 'enzyme';
import { Provider } from 'react-redux';
import GlobalSpinnerContextProvider from 'components/GlobalSpinner/GlobalSpinnerContext';

export function getObjProp(object, keys, defaultVal) {
  if (!object || !keys) return undefined;
  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getObjProp(object, keys.slice(1));
  }
  return object === undefined ? defaultVal : object;
}

export function setObjProp(object, keys, value) {
  if (!object || !keys) return object;
  keys = Array.isArray(keys) ? keys : keys.split('.');
  let objProp = object;
  let i = 0;
  while (i < keys.length - 1) {
    let elem = objProp[keys[i]];

    // add prop if not exist
    if (!elem) {
      // eslint-disable-next-line no-multi-assign
      elem = objProp[keys[i]] = {};
    }
    objProp = elem;
    i += 1;
  }
  objProp[keys[i]] = value;
  return object;
}

// accurate rounding the d to n decimals
export const roundToN = (d, n = 4) => +`${Math.round(`${d}e+${n}`)}e-${n}`;

const convertToType = (v, type) => {
  const types = {
    string: (val) => val,
    numeric: (val) => roundToN(val),
    boolean: (val) => !!JSON.parse(String(val).toLowerCase()),
  };
  return types[type] ? types[type](v) : v;
};

// (CSV rel.) to convert array of arrays(rows) to object using the rules
export const deserializeArrToObj = (arr, rules) => {
  const deserilizedObj = arr.reduce((acc, cur) => {
    const o = rules.find((k) => k.uiGridNameLowCase === cur[0].toLowerCase());
    if (o) {
      const value =
        cur[1].trim().toLowerCase() === 'null' || !cur[1]
          ? ''
          : convertToType(cur[1], o.type);
      setObjProp(acc, o.uiGridName, value);
    }
    return acc;
  }, {});

  return Object.keys(deserilizedObj).length === 0 ? null : deserilizedObj;
};

// (CSV rel.) to get the value at path of object
export const get = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

export function isBoolean(value) {
  return Object.prototype.toString.call(value) === '[object Boolean]';
}

export function isString(value) {
  return Object.prototype.toString.call(value) === '[object String]';
}

export function isFunction(value) {
  return Object.prototype.toString.call(value) === '[object Function]';
}

export function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function isTypedNumeric(value) {
  return (
    !isNaN(parseFloat(value)) && isFinite(value) && typeof value === 'number'
  );
}

export function isUndefined(value) {
  return Object.prototype.toString.call(value) === '[object Undefined]';
}

export function hasOwnProperty(context, name) {
  return Object.prototype.hasOwnProperty.call(context, name);
}

export const uniqBy = (arr, predicate, useEmpty = false) => {
  if (!Array.isArray(arr)) return [];

  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

  const pickedObjects = arr
    .filter((item) => item)
    .reduce((map, item) => {
      const key = cb(item);

      if (!key && !useEmpty) {
        return map;
      }

      return map.has(key) ? map : map.set(key, item);
    }, new Map())
    .values();

  return [...pickedObjects];
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function containsFileNameSpecialChars(str) {
  const specialChars = /[\\<>\0\u0001\u0002\u0003\u0004\u0005\u0006\b\t\n\v\f\r\u000e\u000f\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001a\u001b\u001c\u001d\u001e\u001f:*?,\\,\/]/;
  return specialChars.test(str);
}

export function mround(value, multiple) {
  return Math.round(value / multiple) * multiple;
}

export function mod(n, m) {
  return ((n % m) + m) % m;
}

export function roundup(num, precision = 0) {
  return Math.ceil(num * 10 ** precision) / 10 ** precision;
}

export function isBlank(value) {
  return value === '' || value === null || typeof value === 'undefined';
}

export function convertToNumber(value) {
  return isNumeric(value) ? +value : 0;
}

export function toLookups(data, initial = []) {
  if (!data) return initial;

  const items = data.map(({ id, name }) => ({ value: id, label: name }));

  return uniqBy([...initial, ...items], 'value');
}

export function scrollToView(el, offsetY = 0) {
  window.scrollTo({ top: el.offsetTop - offsetY, behavior: 'smooth' });
}

export function isDate(date) {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (!isNaN(date.getTime())) {
      return true;
    }
  }

  return false;
}

export function thousandSeparator(number) {
  return (typeof number === 'string'
    ? parseFloat(number)
    : number
  ).toLocaleString('en');
}

export const shallowWithProvider = (children) => (store) => {
  return shallow(
    <Provider store={store}>
      <GlobalSpinnerContextProvider>{children}</GlobalSpinnerContextProvider>
    </Provider>
  );
};
