import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { isNumeric } from 'utils';

import { calculateTotal, getPartNumberPrice } from '../utils';
import { ACCESSORY, WIRES } from '../constants';
import { PER_WAT_COST, SALES_TAX, STAMP_LABELS, MA } from './constant';

function SeparateRow() {
  return <TableRow className="separateRow" />;
}

function currencyFormat(num, zeroDashValue = false) {
  const value = zeroDashValue ? (num == 0 ? '—' : num) : num;
  return (
    <>
      <span className="currency">$</span>
      <span className="currency-value">{value}</span>
    </>
  );
}

function preparePriceTemplate(prices) {
  const template = {};

  Object.keys(prices).forEach((categoryKey) => {
    if (categoryKey === 'costWiring' && prices[categoryKey].value === 0) {
      template[categoryKey] = prices[categoryKey];
      return;
    }

    if (categoryKey === 'costMA' && !prices[categoryKey].value) {
      return;
    }

    if (categoryKey !== 'grandTotal') {
      template[categoryKey] = {};
    } else {
      template[categoryKey] = Number(prices[categoryKey]).toFixed(2);
      return;
    }

    const category = prices[categoryKey];

    Object.keys(category).forEach((key) => {
      const property = category[key];
      if (isNumeric(property)) {
        template[categoryKey][key] = currencyFormat(
          Number(property).toFixed(key === PER_WAT_COST ? 3 : 2),
          key === SALES_TAX
        );
      } else {
        template[categoryKey][key] = property;
      }
    });
  });

  return template;
}

function getPrices(values, partNumbers, { lines = [], linesOther = [] }) {
  if (!Array.isArray(lines)) return {};

  const {
    numberModules = 0,
    systemPower = 0,
    additionalComponents = [],
    tier = 1,
    stamp,
    wiringKit = false,
    accessories = false,
    engServicesCost = 0,
    estShippingCost = 0,
    salesTaxRate = 0,
  } = values;

  const maPredicate = (part) => {
    const partNumber = part?.partNum || part?.partNumber;

    const partData = partNumbers[partNumber];
    if (!partData) return false;

    return partData.ProductType === MA;
  };

  const majorComponents = lines.filter((l) => !maPredicate(l));

  const maComponents = [
    ...lines.filter((l) => maPredicate(l)),
    ...linesOther.filter((l) => maPredicate(l)),
  ];

  const accessoryComponents = linesOther.filter((l) => !maPredicate(l));

  let totalPrice = majorComponents.reduce((acc, line) => {
    const { total } = line;
    return acc + total;
  }, 0);

  let totalAccessoriesPrice = 0;
  let totalMAPrice = 0;
  let totalWiringPrice = 0;

  additionalComponents.forEach((component) => {
    if (component.selected) {
      const unitPrice = getPartNumberPrice(
        partNumbers,
        component.partNumberTopLevel,
        tier
      );
      const kitQty = component.kitQty || 0;
      const quantity = kitQty ? Math.ceil(numberModules / kitQty) : 0;
      const total = calculateTotal(quantity, unitPrice) || 0;
      totalPrice += total;
    }
  });

  if (wiringKit) {
    accessoryComponents.forEach((item) => {
      if (item.type === WIRES) {
        totalWiringPrice +=
          item.partPriceTotal || item.partQty * item.partPriceUnit || 0;
      }
    });
  }

  if (accessories) {
    accessoryComponents.forEach((item) => {
      if (item.type === ACCESSORY || !item.type) {
        totalAccessoriesPrice +=
          item.partPriceTotal || item.partQty * item.partPriceUnit || 0;
      }
    });

    maComponents.forEach((item) => {
      totalMAPrice +=
        item.total ||
        item.partPriceTotal ||
        item.quantity * item.unitPrice ||
        item.partQty * item.partPriceUnit ||
        0;
    });
  }

  const costMajor = {
    value: totalPrice,
    perWatCost: systemPower ? totalPrice / (systemPower * 1000) : 0,
    perModuleCost: numberModules ? totalPrice / numberModules : 0,
  };

  const costMA = {
    value: totalMAPrice,
    perModuleCost: numberModules ? totalMAPrice / numberModules : 0,
  };

  const costAccessories = {
    value: totalAccessoriesPrice,
    perModuleCost: numberModules ? totalAccessoriesPrice / numberModules : 0,
  };

  const costWiring = {
    value: totalWiringPrice,
    perModuleCost: numberModules ? totalWiringPrice / numberModules : 0,
  };

  const calcSubTotal =
    totalPrice + costAccessories.value + costWiring.value + costMA.value;

  const subTotal = {
    value: calcSubTotal,
    perWatCost: systemPower ? calcSubTotal / (systemPower * 1000) : 0,
    perModuleCost: numberModules ? calcSubTotal / numberModules : 0,
  };

  const engServiceLabel = STAMP_LABELS[stamp] || '';

  const estimateShipCost = estShippingCost * (numberModules || 0);
  const salesTax = (calcSubTotal / 100) * salesTaxRate;

  const calcTotalCost =
    +calcSubTotal + +engServicesCost + +estimateShipCost + salesTax;

  const totalCost = {
    engServicesCost,
    engServiceLabel,
    estimateShipCost,
    salesTax,
    value: calcTotalCost,
    perWatCost: systemPower ? calcTotalCost / (systemPower * 1000) : 0,
    perModuleCost: numberModules ? calcTotalCost / numberModules : 0,
  };

  return preparePriceTemplate({
    costMajor,
    costMA,
    costAccessories,
    costWiring,
    subTotal,
    totalCost,
    grandTotal: calcTotalCost,
  });
}

export { SeparateRow, getPrices };
