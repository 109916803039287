import React from 'react';
import { Icon } from '@material-ui/core';

import revertIcon from '../assets/revertIcon.svg';

export function CallMade() {
  return (
    <Icon style={{ textAlign: 'center', overflow: 'visible' }}>
      <img
        style={{ verticalAlign: 'unset' }}
        src={revertIcon}
        height={25}
        width={25}
        alt="addIcon"
      />
    </Icon>
  );
}

export const revertTableTool = ({ name, onRevertTable }) => ({
  name,
  icon: <CallMade />,
  onClick: () => onRevertTable(),
});
