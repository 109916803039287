export const initialState = {
  user: {
    updating: null,
    password: null,
  },
  mode: {
    debug: false,
  },
};

export default {
  initialState,
};
