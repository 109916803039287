import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

const accessLevels = {
  private: {
    authorize: (user) => !!user,
    callback: '/auth/signin',
  },
  public: {
    authorize: (user) => !user,
    callback: '/',
  },
};

class AuthRoute extends PureComponent {
  renderComponent(routeProps) {
    const { component: Component, render, user, access } = this.props;
    const accessLevel = accessLevels[access];

    if (!accessLevel || accessLevel.authorize(user)) {
      if (Component) {
        return <Component {...routeProps} />;
      }
      if (render) {
        return render(routeProps);
      }
      return null;
    }

    return <Redirect to={accessLevel.callback} />;
  }

  render() {
    const { component, render, user, access, ...rest } = this.props;
    return <Route {...rest} render={(props) => this.renderComponent(props)} />;
  }
}

AuthRoute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.elementType,
  render: PropTypes.func,
  location: PropTypes.shape({ search: PropTypes.string }),
  user: PropTypes.shape({ email: PropTypes.string }),
  access: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
};

AuthRoute.defaultProps = {
  component: null,
  render: null,
  location: null,
  user: null,
  access: '',
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default withRouter(connect(mapStateToProps)(AuthRoute));
