import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFlexLayout } from 'react-table';
import TableConstructor from './TableConstructor';
import TableCell from './TableCell';
import TableCellEditable from './TableEditableCell';
import './styles.scss';

function TableBase({
  tableConfig,
  columns,
  data,
  updateDraggableData,
  updateEditableData,
  toolbarIcon,
  ...rest
}) {
  const {
    isEditable,
    isResizable,
    isSortable,
    isSelectable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize,
    manualPagination,
    remoteData,
  } = tableConfig;
  const [filterValue, setFilterValue] = useState(null);
  const tableOptions = {
    columns,
    withPagination,
    data,
    updateDraggableData,
    updateEditableData,
    setFilterValue,
    defaultColumn: {},
    isEditable,
    withDragAndDrop: withDragAndDrop || false,
    dataLength: data.length,
    autoResetPage: false,
    disableSortBy: !isSortable,
    manualSortBy: !isSortable,
    manualGlobalFilter: !withSearchEngine,
    remoteData,
    manualPagination,
    initialState: {
      pageIndex: 0,
      pageSize: remoteData ? 40 : 10,
      globalFilter:
        withSearchEngine && !remoteData && filterValue
          ? filterValue
          : undefined,
    },
  };

  let tableOptionalHook = [];
  if (isResizable) tableOptionalHook = [useFlexLayout];
  if (withSearchEngine) {
    tableOptions.defaultColumn = {
      Cell: TableCell,
    };
  }
  if (isEditable) {
    tableOptions.defaultColumn = {
      Cell: TableCellEditable,
    };
  }

  return (
    <TableConstructor
      key={isResizable || isEditable ? 'modified' : 'common'}
      tableConfig={tableConfig}
      tableOptions={tableOptions}
      tableOptionalHook={tableOptionalHook}
      toolbarIcon={toolbarIcon}
      {...rest}
    />
  );
}

TableBase.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  data: PropTypes.arrayOf(PropTypes.shape()),
  updateDraggableData: PropTypes.func,
  updateEditableData: PropTypes.func,
};

TableBase.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [],
  },
  columns: [
    { Header: '#', accessor: 'id' },
    { Header: 'Header Example Title one', accessor: 'first' },
    { Header: 'Header Example Title two', accessor: 'last' },
  ],
  data: [
    { id: 1, first: 'Cell Example Data one', last: 'Cell Example Data two' },
    { id: 2, first: 'Cell Example Data three', last: 'Cell Example Data four' },
  ],
  updateDraggableData: () => {},
  updateEditableData: () => {},
};

export default TableBase;
