/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Container, Row } from 'reactstrap';
import moment from 'moment/moment';
import { api } from 'utils/fetch';
import Toaster from 'components/ToasterQueue';
import { getChartComponent, getKPIChartComponent } from './utils';
import './styles.scss';

class Analytics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      KPI: {},
      dateFilter: {},
      charts: [],
      chartContent: {},
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { charts } = this.state;
    if (charts.length && charts.length !== prevState.charts.length) {
      this.getChartsData(charts);
    }
  }

  init = async () => {
    const KPI = await api.getAnalyticsKPI().catch((error) => {
      Toaster.error(error?.message?.message || 'Failed to fetch KPI data');
    });
    const charts = await api.getAnalyticsCharts();
    this.setState({ charts: charts?.data, KPI: KPI?.data });
  };

  getChartData = async (chart, dateFrom, dateTo) => {
    const { chartContent, dateFilter } = this.state || {
      chartContent: {},
      dateFilter: {},
    };
    const from = dateFrom
      ? `?from=${moment(dateFrom).format('MM/DD/YYYY')}`
      : '';
    const to = dateTo
      ? `${dateFrom && dateTo ? '&' : '?'}to=${moment(dateTo).format(
          'MM/DD/YYYY'
        )}`
      : '';
    const results = await api.getAnalyticsChart(chart, `${from}${to}`);
    chartContent[chart].data = results?.data;

    dateFilter[chart] = { from: dateFrom, to: dateTo };
    this.setState({
      chartContent: { ...chartContent },
      dateFilter: { ...dateFilter },
    });
  };

  getKPIbyName = async (id, { category, name }, dateFrom, dateTo) => {
    const { KPI, dateFilter } = this.state || { KPI: {}, dateFilter: {} };
    const from = dateFrom
      ? `?from=${moment(dateFrom).format('MM/DD/YYYY')}`
      : '';
    const to = dateTo
      ? `${dateFrom && dateTo ? '&' : '?'}to=${moment(dateTo).format(
          'MM/DD/YYYY'
        )}`
      : '';
    const result = await api.getAnalyticsKPI(`/${id}${from}${to}`);
    const index = KPI[category].findIndex((item) => item.Id === id);
    KPI[category][index] = result?.data[category][0];

    dateFilter[id] = { from: dateFrom, to: dateTo };
    this.setState({ KPI: { ...KPI }, dateFilter: { ...dateFilter } });
  };

  getChartsData = async (charts) => {
    const { chartContent } = this.state || { chartContent: {} };
    for (const chart of charts) {
      chartContent[chart] = await api.getAnalyticsChart(chart);
      this.setState({ chartContent });
    }
  };

  getAnalyticsCharts = () => {
    const { charts, chartContent, dateFilter } = this.state;
    const chartComponents = [];
    for (const chart of charts) {
      chartComponents.push(
        getChartComponent(
          chart,
          chartContent?.[chart],
          !chartContent?.[chart],
          dateFilter?.[chart],
          (newDateFrom, newDateTo) =>
            this.handleDateChange(chart, newDateFrom, newDateTo, false)
        )
      );
    }
    return chartComponents;
  };

  getKPICharts = () => {
    const { KPI, dateFilter } = this.state;
    return Object.keys(KPI).map((categoryTitle) => {
      const category = KPI?.[categoryTitle];
      return (
        <Container>
          <Row className="analytic-KPI-group-title">
            <h3>{categoryTitle}</h3>
          </Row>
          <Row>
            {category?.map((subChart) => {
              if (subChart?.IsHidden) return null;
              return getKPIChartComponent(
                subChart?.Label,
                subChart?.Value,
                categoryTitle,
                (newDateFrom, newDateTo) =>
                  this.handleDateChange(subChart?.Id, newDateFrom, newDateTo, {
                    category: categoryTitle,
                    name: subChart?.Label,
                  }),
                dateFilter?.[subChart?.Id],
                subChart?.IsFilterable
              );
            })}
          </Row>
        </Container>
      );
    });
  };

  handleDateChange = (id, newDateFrom, newDateTo, KPITitle) => {
    if (KPITitle) {
      this.getKPIbyName(id, KPITitle, newDateFrom, newDateTo);
    } else {
      this.getChartData(id, newDateFrom, newDateTo);
    }
  };

  render() {
    return (
      <Container className="analytic-page">
        <Row className="analytic-KPI">{this.getKPICharts()}</Row>
        <Row className="analytic-chart">{this.getAnalyticsCharts()}</Row>
      </Container>
    );
  }
}

export default Analytics;
