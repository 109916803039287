import React from 'react';
import { Icon } from '@material-ui/core';

import addIcon from '../assets/addIcon.svg';

export function CallMade(props) {
  const { icon: CIcon } = props;

  return CIcon ? (
    <CIcon className={props.class ? props.class : ''} />
  ) : (
    <Icon
      style={{ textAlign: 'center', overflow: 'visible' }}
      className={props.class ? props.class : ''}
    >
      <img
        style={{ verticalAlign: 'unset' }}
        src={addIcon}
        height={25}
        width={25}
        alt="addIcon"
      />
    </Icon>
  );
}

export const addTool = ({
  name,
  onCreate,
  options,
  optionsHandleSelect,
  styles,
  icon,
  iconClass,
}) => {
  const params = {
    name,
    icon: <CallMade icon={icon} class={iconClass} />,
    styles,
    iconClass,
  };

  if (typeof onCreate === 'function') {
    params.onClick = () => onCreate();
  }

  if (options) {
    params.options = options;
  }

  if (typeof optionsHandleSelect === 'function') {
    params.optionsHandleSelect = optionsHandleSelect;
  }

  return params;
};
