import React, { useEffect } from 'react';
import Select from 'react-select';
import useState from 'react-usestateref';
import PropTypes from 'prop-types';

function SelectBar({
  propName,
  propLabel,
  list = [],
  onChange,
  selectedSFTableName,
}) {
  const [selectedTable, setSelectedTable] = useState({});

  useEffect(() => {
    if (selectedTable.Name !== selectedSFTableName) {
      const item = list.find((table) => table.Name === selectedSFTableName);
      setSelectedTable(item);
    }
  }, [selectedSFTableName]);

  return (
    <Select
      value={selectedTable.Label ? selectedTable : list[0]}
      options={list}
      getOptionLabel={(option) => option[propLabel]}
      getOptionValue={(option) => option[propName]}
      className="selectBar"
      onChange={(selected) => {
        setSelectedTable(selected);
        onChange(selected);
      }}
    />
  );
}

export default SelectBar;

SelectBar.propTypes = {
  propName: PropTypes.string,
  propLabel: PropTypes.string,
  list: PropTypes.shape({}),
  onChange: PropTypes.func,
  selectedSFTableName: PropTypes.string,
};

SelectBar.defaultProps = {
  propName: '',
  selectedSFTableName: '',
  propLabel: '',
  list: [],
  onChange: () => {},
};
