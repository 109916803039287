import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import './styles.scss';

import PropsTypes from 'prop-types';

function Switcher({
  isChecked,
  handleChange,
  label,
  labelPlacement,
  disabled,
  hide,
}) {
  return (
    !hide && (
      <FormControlLabel
        display={{ xs: 'none', md: 'block' }}
        label={label}
        labelPlacement={labelPlacement}
        className="smm-switcher"
        control={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Switch
            checked={isChecked}
            onChange={handleChange}
            name="swSwitcher"
            color="primary"
            disabled={disabled}
          />
        }
      />
    )
  );
}

Switcher.propTypes = {
  label: PropsTypes.string,
  labelPlacement: PropsTypes.string,
  isChecked: PropsTypes.bool,
  handleChange: PropsTypes.func,
  disabled: PropsTypes.bool,
  hide: PropsTypes.bool,
};

Switcher.defaultProps = {
  label: 'Allow',
  labelPlacement: 'top',
  isChecked: false,
  handleChange: () => ({}),
  disabled: false,
  hide: false,
};

export default Switcher;
