import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { colorAccent, colorIcon } from 'components/common/utils/palette';

export const TCheckbox = styled(Checkbox)`
  && {
    transition: 0.3s;
    color: ${colorIcon};

    span {
      ${(props) => props.checked && `color: ${colorAccent};`}
    }
  }
`;

export default TCheckbox;
