export const SHOW_FS_VALUES_PROFILE = 'SHOW_FS_VALUES_PROFILE';
export const SHOW_OPPORTUNITY_BY_DEFAULT = 'SHOW_OPPORTUNITY_BY_DEFAULT';

export const FAILED = 'FAILED';
export const SUCCEEDED = 'SUCCEEDED';
export const PRIVATE = 'PRIVATE';

export const ALLOW_SM_ACTIVATION = 'ALLOW_SM_ACTIVATION';

export const ALLOW_SMM_IMPORT = 'ALLOW_SMM_IMPORT';

export const ALLOW_SPECSHEET_IMPORT = 'ALLOW_SPECSHEET_IMPORT';

export const ALLOW_MANAGE_TENANTS = 'ALLOW_MANAGE_TENANTS';

export const ALLOW_MANAGE_ROLES = 'ALLOW_MANAGE_ROLES';

export const ALLOW_MANAGE_USERS = 'ALLOW_MANAGE_USERS';
export const ALLOW_MANAGE_ACLS = 'ALLOW_MANAGE_ACLS';
export const ALLOW_DEBUG_TOGGLE = 'ALLOW_DEBUG_TOGGLE';

export const ALLOW_API_INSPECTOR = 'ALLOW_API_INSPECTOR';

export const DW_DESIGNER_WORKFLOW = 'DW_DESIGNER_WORKFLOW';
export const DW_SUBMISSIONS = 'DW_SUBMISSIONS';
export const DW_SUBMISSIONS_MANAGER = 'DW_SUBMISSIONS_MANAGER';
export const DW_DESIGNER_VIEW = 'DW_DESIGNER_VIEW';
export const DW_DESIGNER_MANAGER = 'DW_DESIGNER_MANAGER';
export const DW_ADMIN = 'DW_ADMIN';
