/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropsTypes from 'prop-types';

import MaterialTable from 'components/MaterialTable';
import { inlineFilters } from 'utils/fetch';

import SelectInput from 'components/common/SelectInput';
import TextInput from 'components/common/ReactHookForm/TextInput';
import Toaster from 'components/ToasterQueue';
import { EDIT } from 'components/DetailView/constants';

import { isNumeric, isObject, isUndefined } from 'utils';
import { CREATE } from 'constants';
import Toolbar from 'components/Toolbar';

import { tabs } from './utils';

import './style.scss';

class Management extends PureComponent {
  title = 'Management';

  detailViewTittle = 'Management';

  idFieldName = 'id';

  constructor(props) {
    super(props);

    this.tabs = tabs;

    this.tableRef = React.createRef(null);

    this.state = {
      activeTab: this.tabs.requestType.name,
      refreshCount: 0,
      priorityId: null,
      priorities: [],
      errorPriority: '',
      capacity: {},
      changedData: false,
      disabledCancel: true,
      loadingSave: false,
      disabledSave: true,
    };

    this.previousPriorityId = null;
    this.changedPriorityId = null;

    this.previousCapacityValue = null;
    this.changedCapacityValue = null;

    this.cache = new Map();

    this.newId = 0;

    this.initPage();
  }

  componentDidUpdate(prevProps, prevState) {
    const { changedData, activeTab } = this.state;

    if (prevState.changedData !== changedData) {
      this.setState({
        disabledCancel: !changedData,
        disabledSave: !changedData,
      });
    }

    if (prevState.activeTab !== activeTab) {
      this.initPage();
      this.tableRef.current?.reloadTable();
    }
  }

  onChangeTab = (e, activeTab) => {
    const { activeTab: stateActiveTab } = this.state;
    if (stateActiveTab !== activeTab) {
      this.setState({ activeTab });
    }
  };

  middleToolbar = () => {
    const { activeTab, changedData } = this.state;

    return (
      <Paper square>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          // eslint-disable-next-line no-shadow
          onChange={(e, activeTab) => this.onChangeTab(e, activeTab)}
          aria-label="disabled tabs example"
        >
          <Tab
            value={this.tabs.requestType.name}
            label={this.tabs.requestType.title}
            disabled={changedData && this.tabs.requestType.name !== activeTab}
          />
          <Tab
            value={this.tabs.assignedTo.name}
            label={this.tabs.assignedTo.title}
            disabled={changedData && this.tabs.assignedTo.name !== activeTab}
          />
          <Tab
            value={this.tabs.reviewedBy.name}
            label={this.tabs.reviewedBy.title}
            disabled={changedData && this.tabs.reviewedBy.name !== activeTab}
          />
          <Tab
            value={this.tabs.requestStatus.name}
            label={this.tabs.requestStatus.title}
            disabled={changedData && this.tabs.requestStatus.name !== activeTab}
          />
        </Tabs>
      </Paper>
    );
  };

  getData = async (param = {}) => {
    const newParams = inlineFilters(param);

    const { activeTab } = this.state;
    const tab = this.tabs[activeTab];

    if (!this.cache.has(activeTab)) {
      const data = await tab.getData(newParams);
      this.createData(data.data);
    }

    const data = this.cache.get(activeTab) || {};
    const items = data.items || [];

    return {
      ...data,
      count: items.length,
      data: items,
      filters: {},
    };
  };

  createData = (data) => {
    const priorities = [];
    const existPriorities = {};

    let { capacity } = this.state;

    data.forEach((item) => {
      if (item.Type === 'Priority' && !existPriorities[item.Value]) {
        priorities.push({
          value: item.Id,
          label: item.Value,
          item,
        });
        existPriorities[item.Value] = true;
      }

      if (
        item.Type === 'Projects' &&
        item.Value === 'Standard' &&
        isNumeric(item.ValueNum) &&
        (isUndefined(capacity.ValueNum) || capacity.ValueNum < item.ValueNum)
      ) {
        capacity = item;
      }
    });

    this.setState({
      priorities,
      capacity,
    });

    Object.values(this.tabs).forEach((tab) => {
      const metadata = {
        items: [],
        changedData: new Map(),
        previousData: new Map(),
        removedData: new Map(),
      };

      this.cache.set(tab.name, metadata);

      data.forEach((item) => {
        if (tab.type === item.Type) {
          metadata.items.push(item);
        }
      });
    });
  };

  updateTableCell = async (newValue, oldValue, rowData, columnDef) => {
    if (newValue === oldValue) return;

    const { activeTab } = this.state;
    const tab = this.tabs[activeTab];
    const data = this.cache.get(tab.name);
    const { items, previousData, changedData } = data;

    let { id: index } = rowData.tableData;

    index = this.getCurrentPageIndex(index);

    const id = rowData.Id;

    if (!previousData.has(id)) {
      const { tableData, ...rest } = items[index];
      previousData.set(id, rest);
    }

    items[index][columnDef.field] = String(newValue).trim();

    if (id >= 0) {
      const previousRowData = previousData.get(id);
      const currentRowData = items[index];

      const hasChangedData = Object.keys(previousRowData).some((key) => {
        return previousRowData[key] !== currentRowData[key];
      });

      if (hasChangedData) {
        if (!changedData.has(id)) {
          changedData.set(id, items[index]);
        }
      } else {
        previousData.delete(id);
        changedData.delete(id);
      }
    }

    this.checkIfChangedData();
  };

  checkIfChangedData = () => {
    let changedData = Object.values(this.tabs).some((tab) => {
      if (this.cache.get(tab.name)?.removedData.size > 0) return true;

      const data = this.cache.get(tab.name)?.changedData?.values();

      if (!data) return false;

      const changed =
        [...data].some((value) => {
          const { tableData, _Action, ...rest } = value;
          const { Id, ...other } = rest;
          if (other.Value !== '' && other.Description !== '') {
            return true;
          }
        }) || false;

      return changed;
    });

    if (!changedData) {
      changedData =
        this.previousPriorityId !== this.changedPriorityId ||
        this.previousCapacityValue !== this.changedCapacityValue;
    }

    this.setState({ changedData });
  };

  onSelectPriority = (name, value) => {
    const { priorityId } = this.state;

    if (this.previousPriorityId === null) {
      this.previousPriorityId = priorityId;
    }

    this.changedPriorityId = value;

    this.setState({
      [name]: value,
    });

    this.checkIfChangedData();
  };

  onChangeCapacity = ({ target: { value } }) => {
    if (value !== '') {
      const number = value ? Number(value) : 0;
      if (Number.isNaN(number) || number < 0) {
        return false;
      }
    }

    const { capacity } = this.state;

    if (this.previousCapacityValue === null) {
      this.previousCapacityValue =
        typeof capacity.ValueNum !== 'undefined' ? capacity.ValueNum : '';
    }

    this.changedCapacityValue = value;

    this.setState({
      capacity: {
        ...capacity,
        ValueNum: value,
      },
    });

    this.checkIfChangedData();
  };

  onSave = async () => {
    try {
      this.tableRef.current.setLoading(true);

      const { activeTab } = this.state;
      const tab = this.tabs[activeTab];

      this.setState({
        disabledCancel: true,
        loadingSave: true,
      });

      const data = {
        data: this.getDataForSaving(),
      };

      await tab.save(data);

      this.clearChangedPreviosRemovedData();

      this.checkIfChangedData();

      this.setState({
        disabledSave: true,
      });

      Toaster.success('Data saved successfully');
    } catch (e) {
      console.error(e);

      this.setState({
        disabledCancel: false,
      });
      Toaster.error('Error saving data');
    } finally {
      this.tableRef.current.setLoading(false);

      this.setState({
        loadingSave: false,
      });

      this.scrollToTop();

      this.tableRef.current.reloadTable();
      this.tableRef.current.forceUpdateTableData();
    }
  };

  onCancel = () => {
    this.rollbackData();

    this.clearChangedPreviosRemovedDataWithoutItems();

    this.checkIfChangedData();

    this.setState({
      disabledCancel: true,
      loadingSave: false,
      disabledSave: true,
    });
  };

  scrollToTop = () => {
    globalThis.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  getDataForSaving = () => {
    const data = {
      AddedEntries: [],
      UpdatedEntries: [],
      RemovedEntries: [],
    };

    const { priorities, capacity } = this.state;

    const existIds = {};

    Object.values(this.tabs).forEach((tab) => {
      const changedData = this.cache.get(tab.name)?.changedData.values();

      if (changedData) {
        [...changedData].forEach((value) => {
          const { tableData, _Action, ...rest } = value;

          if (_Action === CREATE) {
            const { Id, ...other } = rest;
            if (other.Value !== '' && other.Description !== '')
              data.AddedEntries.push(other);
          } else {
            data.UpdatedEntries.push(rest);
          }

          existIds[rest.Id] = true;
        });
      }

      const removedData = this.cache.get(tab.name)?.removedData.values();

      if (removedData) {
        [...removedData].forEach((value) => {
          const { tableData, ...rest } = value;
          data.RemovedEntries.push(rest.Id);
          existIds[rest.Id] = true;
        });
      }
    });

    if (this.previousPriorityId !== this.changedPriorityId) {
      const item = priorities.find(
        ({ value }) => value === this.changedPriorityId
      );

      if (item && !existIds[item.item.Id]) {
        data.UpdatedEntries.push(item.item);
      }
    }

    if (
      this.previousCapacityValue !== this.changedCapacityValue &&
      !existIds[capacity.Id]
    ) {
      data.UpdatedEntries.push(capacity);
    }

    return data;
  };

  clearChangedPreviosRemovedDataWithoutItems = () => {
    Object.values(this.tabs).forEach((tab) => {
      const data = this.cache.get(tab.name);

      if (!data) return;

      data.changedData.clear();
      data.previousData.clear();
      data.removedData.clear();
    });

    this.previousPriorityId = null;
    this.changedPriorityId = null;

    this.previousCapacityValue = null;
    this.changedCapacityValue = null;
  };

  clearChangedPreviosRemovedData = () => {
    this.clearChangedPreviosRemovedDataWithoutItems();

    this.cache.clear();
  };

  rollbackData = () => {
    Object.values(this.tabs).forEach((tab) => {
      const data = this.cache.get(tab.name);

      if (!data) return;

      data.previousData.forEach((value, key) => {
        const index = data.items.findIndex((i) => i.Id === key);

        if (data.items[index]._Action === CREATE) {
          data.items.splice(index, 1);
        } else {
          const removedItem = data.removedData.get(key);

          Object.entries(value).forEach(([k, v]) => {
            data.items[index][k] = v;

            if (removedItem) {
              removedItem[k] = v;
            }
          });
        }
      });

      data.removedData.forEach((value, key) => {
        let index = 0;

        data.items.some((item, i) => {
          if (key > item.Id) {
            index = i + 1;
            return true;
          }

          return false;
        });

        data.items.splice(index, 0, value);
      });

      let needReloadTable = data.previousData.size > 0;

      if (data.removedData.size > 0) {
        data.items.sort((a, b) => a.Id - b.Id);
        needReloadTable = true;
      }

      if (needReloadTable) {
        if (data.items.length % this.pageSize === 0) {
          if (this.currentPage > 0) {
            this.currentPage -= 1;
          }

          this.tableRef.current.reloadTable(this.currentPage);
        }

        this.tableRef.current.forceUpdateTableData();
      }
    });

    if (this.previousPriorityId !== null) {
      this.setState({
        priorityId: this.previousPriorityId,
      });
    }

    if (this.previousCapacityValue !== null) {
      const { capacity } = this.state;

      this.setState({
        capacity: {
          ...capacity,
          ValueNum: this.previousCapacityValue,
        },
      });
    }
  };

  refreshTable = () => {
    const { refreshCount } = this.state;
    this.setState({ refreshCount: refreshCount + 1 });
  };

  onCreate = () => {
    const { activeTab } = this.state;
    const tab = this.tabs[activeTab];

    const data = this.cache.get(activeTab) || {};

    if (!data.items) return;

    this.newId -= 1;

    const newRecord = {
      Id: this.newId,
      Description: '',
      Status: '',
      Type: tab.type,
      Value: '',
      _Action: CREATE,
    };

    data.items.splice(this.getCurrentPageIndex(0), 0, newRecord);

    data.previousData.set(newRecord.Id, newRecord);
    data.changedData.set(newRecord.Id, newRecord);

    this.checkIfChangedData();

    this.tableRef.current.forceUpdateTableData();
  };

  onDeleteRowAction = (e, row) => {
    if (!isObject(row)) return;

    const { activeTab } = this.state;

    const data = this.cache.get(activeTab) || {};

    const index = this.getCurrentPageIndex(row.tableData.id);

    data.items.splice(index, 1);

    data.previousData.delete(row.Id);
    data.changedData.delete(row.Id);

    if (row._Action !== CREATE) {
      data.removedData.set(row.Id, row);
    }

    if (data.items.length % this.pageSize === 0) {
      if (this.currentPage > 0) {
        this.currentPage -= 1;
      }

      this.tableRef.current.reloadTable(this.currentPage);
    }

    this.tableRef.current.forceUpdateTableData();

    this.checkIfChangedData();
  };

  initPage = () => {
    this.currentPage = 0;
    this.pageSize = 5;
  };

  onChangePage = (currentPage, pageSize) => {
    this.currentPage = currentPage;
    this.pageSize = pageSize;
  };

  getCurrentPageIndex = (index) => {
    return this.pageSize * this.currentPage + index;
  };

  render() {
    const {
      activeTab,
      refreshCount,
      errorPriority,
      priorityId,
      priorities,
      capacity,
      loadingSave,
      disabledCancel,
      disabledSave,
    } = this.state;

    const tab = this.tabs[activeTab];

    return (
      <Container className="dw-managements-page">
        <Row>
          <MaterialTable
            ref={this.tableRef}
            tableProps={{
              title: this.title,
              detailViewTittle: this.detailViewTittle,
              detailViewMode: EDIT,
              hideExport: true,
              // hideDefaultMenu: true,
              name: tab.name,
              options: {
                filtering: false,
                sorting: false,
                page: this.currentPage,
                pageSize: this.pageSize,
              },
              onChangePage: this.onChangePage,
              cellEditable: {
                onCellEditApproved: async (
                  newValue,
                  oldValue,
                  rowData,
                  columnDef
                ) => {
                  await this.updateTableCell(
                    newValue,
                    oldValue,
                    rowData,
                    columnDef
                  );
                },
              },
              onCreate: this.onCreate,
            }}
            extandedDetailView
            middleToolbar={this.middleToolbar}
            columns={tab.headers}
            remoteData={false}
            hasAddButton
            report={false}
            deleteRowAction
            onDeleteRowAction={this.onDeleteRowAction}
            onRowClick={() => null} // disable row click, only edit button
            fullScreenDetailView={false}
            getData={this.getData}
            refreshCount={refreshCount}
            detailsView={{
              cols: this.detailViewCols,
              idFieldName: this.idFieldName,
            }}
          />
        </Row>
        <Row>
          <div>
            <div className="dw-managements-page__box">
              <Col
                md={12}
                lg={12}
                xl={4}
                className="dw-managements-page__box-fields"
              >
                <div className="dw-managements-page__box-title">
                  <h5>Workflow Settings</h5>
                </div>
                <div className="dw-managements-page__box-item">
                  <SelectInput
                    label="Priority"
                    labelPosition="left"
                    name="priorityId"
                    isLoading={false}
                    options={priorities}
                    onChange={this.onSelectPriority}
                    value={priorityId}
                    error={errorPriority}
                  />
                </div>
                <div className="dw-managements-page__box-item">
                  <TextInput
                    label="Capacity"
                    labelPosition="left"
                    labelWidth="12%"
                    name="capacity"
                    size="small"
                    onChange={this.onChangeCapacity}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={capacity?.ValueNum}
                    required
                  />
                </div>
              </Col>
            </div>
          </div>
        </Row>
        <Row className="dw-managements-page-footer">
          <div>
            <div className="dw-managements-page__box dw-managements-page_padding">
              <Toolbar
                disabledCancel={disabledCancel}
                onCancel={this.onCancel}
                disabledSave={disabledSave}
                loadingSave={loadingSave}
                onSave={this.onSave}
              />
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

export default Management;

Management.propTypes = {
  acl: PropsTypes.shape({}),
};

Management.defaultProps = {
  acl: {},
};
