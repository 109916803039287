import React from 'react';
import { Container, Row } from 'reactstrap';

import MaterialTable from 'components/MaterialTable';
import { api } from 'utils/fetch';
import { getCProjectsHeaders } from './utils';

import './style.scss';

class Projects extends React.Component {
  constructor(props) {
    super(props);
    const { headers, detailViewCols } = getCProjectsHeaders();
    this.headers = headers;
    this.detailViewCols = detailViewCols;
    this.getItem = api.getSingleProject;
  }

  // eslint-disable-next-line consistent-return
  highlightRowIf = (rowData) => {
    if (rowData.noOfRevisions !== 0 && rowData.noOfRevisions)
      // should be adjusted when noOfRevisions will be fixed
      return { backgroundColor: '#FBDAD3' };
  };

  render() {
    return (
      <Container className="cprojects-page">
        <Row>
          <MaterialTable
            tableProps={{
              title: 'Custom Projects',
              options: {
                rowStyle: this.highlightRowIf,
              },
              name: 'CustomProjects',
            }}
            //* frontend load until BE will be implemented
            columns={this.headers}
            remoteData
            report
            getData={async (params) => {
              const { filters, orderBy, desc, ...otherParams } = params;
              const newFilters = Object.keys(filters || {}).reduce(
                (acc, key) => {
                  acc[`f_${key}`] = filters[key];
                  return acc;
                },
                {}
              );
              const newParams = {
                sortBy: orderBy
                  ? orderBy.charAt(0).toLowerCase() + orderBy.slice(1)
                  : '',
                sortOrder: desc ? -1 : 1,
                skip: (otherParams.page - 1) * otherParams.pageSize,
                limit: otherParams.pageSize,
                ...otherParams,
                ...newFilters,
              };

              const res = await api.getAllProjects({
                ...newParams,
                getAll: true,
                withTenants: true,
                onlyCustom: true,
              });

              return {
                ...res,
                count: res.data.count,
                data: res.data.data,
              };
            }}
            detailsView={{
              getItem: this.getItem,
              cols: this.detailViewCols,
              idFieldName: '_id',
            }}
          />
        </Row>
      </Container>
    );
  }
}

export default Projects;
