import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { SearchInput, SearchWrap } from './styles';

function TableFilter({
  rows,
  setGlobalFilter,
  setFilterValue,
  placeholder,
  dataLength,
  remoteData,
  fetchSelectedPage,
}) {
  const onChange = useAsyncDebounce((item) => {
    const value = item.trim();
    setFilterValue(value);
    setGlobalFilter(value);
  }, 200);

  const [searchValue, setSearchValue] = useState('');

  return (
    <SearchWrap style={{ alignItems: 'stretch' }}>
      <SearchInput
        type="search"
        placeholder={placeholder}
        value={searchValue}
        onChange={(e) => {
          !remoteData && onChange(e.target.value);
          setSearchValue(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (remoteData) {
              fetchSelectedPage(0, searchValue);
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearchValue('');
                  if (remoteData) {
                    fetchSelectedPage(0, '');
                  } else {
                    setFilterValue('');
                    setGlobalFilter('');
                  }
                }}
              >
                <ClearIcon fontSize="small" aria-label="clear" />
              </IconButton>
              {remoteData && (
                <IconButton
                  type="submit"
                  aria-label="search"
                  onClick={() => {
                    fetchSelectedPage(0, searchValue);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {dataLength !== rows.length && !remoteData && (
        <span>Found {rows.length} matches</span>
      )}
    </SearchWrap>
  );
}

const filterGreaterThan = (rows, id, filterValue) =>
  rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });

filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

TableFilter.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func,
  placeholder: PropTypes.string,
  dataLength: PropTypes.number.isRequired,
};

TableFilter.defaultProps = {
  setFilterValue: () => {},
  placeholder: 'Search...',
};

export default TableFilter;
