import React from 'react';

import withIntl from './withIntl';

const IntlContext = React.createContext({
  lang: 'en-US',
});

export { withIntl };
export default IntlContext;
