import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { changeFilters } from 'redux/actions/dataViewActions';
import CheckboxFilter from './CheckboxFilter';
import Filter from './Filter';
import AutoSuggestionFilter from './AutoSuggestionFilter';
import SelectFilter from './SelectFilter';

class FilterRow extends Component {
  columns = [];

  shouldComponentUpdate() {
    return false;
  }

  getFilterComponent(col) {
    const {
      field,
      type,
      exType,
      filter,
      lookup,
      lookupHandler,
      defaultFilterValue,
    } = col;

    // eslint-disable-next-line
    const { changeFilters, tableName } = this.props;
    let FilterComponent;
    switch (type) {
      case 'boolean': {
        FilterComponent = CheckboxFilter;
        break;
      }
      case 'autosuggestion': {
        FilterComponent = AutoSuggestionFilter;
        break;
      }
      default: {
        FilterComponent = Filter;
      }
    }

    return exType === 'select' ? (
      <SelectFilter
        onFilter={changeFilters}
        name={field}
        tableName={tableName}
        lookup={filter || lookup}
        lookupHandler={lookupHandler}
        defaultValue={defaultFilterValue}
      />
    ) : (
      <FilterComponent
        onFilter={changeFilters}
        name={field}
        tableName={tableName}
        defaultValue={defaultFilterValue}
      />
    );
  }

  generateColumns = () => {
    const {
      columns,
      filterCellStyle,
      selection,
      hasActions,
      actionsColumnIndex,
    } = this.props;

    const columnsComponents = columns
      .filter((columnDef) => !columnDef.hidden)
      .sort((a, b) => a.tableData.columnOrder - b.tableData.columnOrder)
      .map((columnDef) => (
        <TableCell
          key={columnDef.tableData.id}
          style={{
            ...filterCellStyle,
            ...columnDef.filterCellStyle,
          }}
        >
          {columnDef.filtering !== false
            ? this.getFilterComponent(columnDef)
            : ''}
        </TableCell>
      ));
    if (selection) {
      columnsComponents.splice(
        0,
        0,
        <TableCell padding="none" key="key-selection-column" />
      );
    }

    if (hasActions) {
      if (actionsColumnIndex === -1) {
        columnsComponents.push(<TableCell key="key-action-column" />);
      } else {
        let endPos = 0;
        if (selection) {
          endPos = 1;
        }
        columns.splice(
          actionsColumnIndex + endPos,
          0,
          <TableCell key="key-action-column" />
        );
      }
    }

    return columnsComponents;
  };

  getColumns = () => {
    if (!this.columns.length) {
      this.columns = this.generateColumns();
    }

    return this.columns;
  };

  render() {
    const { filterRowStyle } = this.props;
    const columns = this.getColumns();

    return (
      <TableRow style={{ height: 10, ...filterRowStyle }}>{columns}</TableRow>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeFilters: (filter) => {
    dispatch(changeFilters(filter));
  },
});

/* eslint-disable */
FilterRow.propTypes = {
  actionsColumnIndex: PropTypes.number.isRequired,
  changeFilters: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  filterCellStyle: PropTypes.object,
  filterRowStyle: PropTypes.object,
  hasActions: PropTypes.bool,
  selection: PropTypes.bool,
};
/* eslint-enable */

FilterRow.defaultProps = {
  hasActions: false,
  selection: false,
};

export default connect(null, mapDispatchToProps)(FilterRow);
