import TextField from '@material-ui/core/TextField';

import { createFilterOptions } from '@material-ui/lab';

export const findValue = (initValue, options) => {
  return options.find((option) => option.id === initValue.id) ?? null;
};

export const isOptionEqualToValue = (option, value) => {
  return option?.id === value?.id && option?.name === value?.name;
};

export const getOptionLabel = (option) => {
  if (typeof option === 'string') {
    return option;
  }

  if (option.inputValue) {
    return option.inputValue;
  }

  return option.label;
};

export const renderOption = (option) => option.label;

export const renderInput = (params) => <TextField {...params} />;

export const filterOptions = createFilterOptions();
