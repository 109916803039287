const config = {
  isEditable: false,
  isSortable: false,
  isResizable: false,
  withPagination: false,
  withFooter: false,
  manualPagination: true,
  withSearchEngine: false,
  placeholder: 'Search...',
};

export default config;
