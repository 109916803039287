/* eslint-disable */
import React, { useRef, useState, useCallback } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import './MoreMenu.scss';

function MoreMenu(props) {
  const [isOpen, changeOpen] = useState(false);
  const iconEl = useRef(null);
  const onOpen = useCallback(() => changeOpen(true), []);
  const onClose = useCallback(() => changeOpen(false), []);

  const { actionsExtraMenu = null, tableProps = {}, deleteHandler } = props;
  const { data = null } = tableProps;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <MoreVertIcon ref={iconEl} onClick={onOpen} />
      <Menu
        className="solar-module-menu"
        anchorEl={iconEl.current}
        keepMounted
        open={isOpen}
        onClose={onClose}
      >
        <MenuItem onClick={(e) => tableProps.action.onClick(e, data)}>Details</MenuItem>
        {actionsExtraMenu &&
          actionsExtraMenu.map((el) => (
            <MenuItem key={el.title} {...(el.showIf(data) ? {} : {className: 'hidden'})} onClick={()=>{changeOpen(false);el.onClick(data)}}>
              {el.title}
            </MenuItem>
          ))}
          {
              deleteHandler && [
                  <li className="divider" />,
                  <MenuItem onClick={(e) => deleteHandler(e, data)}>Delete</MenuItem>
            ]
          }
      </Menu>
    </div>
  );
}

export default MoreMenu;
/* eslint-enable */
