import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Search from 'components/MaterialTable/Search';

import CircularProgress from 'components/CircularProgress';

import Checkbox from './components/Checkbox';

import './assets/style.scss';

function ListFields({
  id,
  unselectedItems,
  items,
  loading,
  disabled,
  onEvent,
  search,
}) {
  const onSelect = useCallback(
    (name, value) => {
      onEvent({
        action: 'selected',
        data: {
          name,
          value,
        },
      });
    },
    [onEvent]
  );

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 360,
        minHeight: 335,
        maxHeight: 300,
        bgcolor: 'background.paper',
      }}
      className={loading ? 'list-fields-list  loading' : 'list-fields-list '}
    >
      {loading ? (
        <div className="list-fields-progress">
          <CircularProgress content="" />
        </div>
      ) : (
        <>
          <Search tableName={id} value={search} />
          <Box
            sx={{
              width: '100%',
              maxWidth: 360,
              minHeight: 300,
              maxHeight: 300,
              overflow: 'auto',
            }}
          >
            <List component="nav">
              {items.map((it) => {
                return (
                  <ListItem
                    key={it.field}
                    disabled={disabled}
                    button
                    onClick={() =>
                      onSelect(it.field, !unselectedItems[it.field])
                    }
                  >
                    <Checkbox
                      disabled={disabled}
                      styleType="colored-click"
                      color="primary"
                      checked={!unselectedItems[it.field]}
                    />
                    <ListItemText primary={it.title} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}
    </Box>
  );
}

ListFields.propTypes = {
  id: PropTypes.string,
  unselectedItems: PropTypes.shape({
    field: PropTypes.bool,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onEvent: PropTypes.func,
  search: PropTypes.string,
};

ListFields.defaultProps = {
  id: null,
  unselectedItems: {},
  items: [],
  disabled: false,
  loading: false,
  onEvent: () => {},
  search: '',
};

export default connect()(ListFields);
