import PropTypes from 'prop-types';

import ACL from 'redux/Acl';

function ShowIfHasPermission({
  children,
  acl,
  conditions,
  execOnlyOneRule = false,
}) {
  const method = execOnlyOneRule ? 'some' : 'every';

  return conditions[method]((cond) => acl.isAccessAllow(cond))
    ? children
    : null;
}

ShowIfHasPermission.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  acl: PropTypes.object,
  execOnlyOneRule: PropTypes.bool,
};

ShowIfHasPermission.defaultProps = {
  conditions: [],
  acl: new ACL(),
  execOnlyOneRule: false,
};

export default ShowIfHasPermission;
