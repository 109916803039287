import './style.scss';

const getRolesHeaders = () => {
  const fields = [
    {
      field: 'id',
      title: 'Id',
      editable: 'never',
      filtering: false,
      sorting: false,
    },
    {
      field: 'name',
      title: 'Name',
      showInTable: true,
      searchable: true,
      validations: {
        required: true,
      },
    },
    {
      field: 'TenantName',
      title: 'Tenant Name',
      showInTable: true,
      searchable: false,
      editable: 'never',
      hideInCreate: true,
    },
    {
      field: 'TenantNameCreate',
      title: 'Tenant Name',
      showInTable: false,
      searchable: false,
      exType: 'select',
      lookup: [],
      hideInEdit: true,
    },
    {
      field: 'Acls',
      title: 'Acls',
      className: 'label_margin-0',
      type: 'object',
      exType: 'table',
      headers: {
        fields: [
          {
            field: 'IsIncluded',
            title: 'Included',
            type: 'boolean',
            editable: 'never',
            showInTable: true,
            // width: '1%',
            dependencies: [
              {
                name: 'Ops.Show',
              },
              {
                name: 'Ops.Enable',
              },
              {
                name: 'Ops.Read',
              },
              {
                name: 'Ops.Create',
              },
              {
                name: 'Ops.Update',
              },
              {
                name: 'Ops.Delete',
              },
            ],
          },
          {
            field: 'Id',
            title: 'Id',
            type: 'string',
            editable: 'never',
            showInTable: false,
            hidden: true,
            filtering: false,
            sorting: false,
            // width: '3%',
          },
          {
            field: 'AclKey',
            title: 'ACL',
            type: 'string',
            editable: 'never',
            filtering: true,
            sorting: true,
            // width: '30%',
            /* cellStyle: {
              minWidth: '120px',
              maxWidth: '120px',
            }, */
          },
          {
            field: 'Ops.Show',
            title: 'Show',
            type: 'boolean',
            filtering: true,
            sorting: false,
            // width: '3%',
            dependencies: [
              {
                name: 'IsIncluded',
                states: {
                  checked: true,
                  unchecked: 'dependencies:unchecked:all',
                },
              },
            ],
          },
          {
            field: 'Ops.Enable',
            title: 'Enable',
            type: 'boolean',
            filtering: true,
            sorting: false,
            // width: '3%',
            dependencies: [
              {
                name: 'IsIncluded',
                states: {
                  checked: true,
                  unchecked: 'dependencies:unchecked:all',
                },
              },
            ],
          },
          {
            field: 'Ops.Read',
            title: 'Read',
            type: 'boolean',
            filtering: true,
            sorting: false,
            // width: '3%',
            dependencies: [
              {
                name: 'IsIncluded',
                states: {
                  checked: true,
                  unchecked: 'dependencies:unchecked:all',
                },
              },
            ],
          },
          {
            field: 'Ops.Create',
            title: 'Create',
            type: 'boolean',
            filtering: true,
            sorting: false,
            // width: '3%',
            dependencies: [
              {
                name: 'IsIncluded',
                states: {
                  checked: true,
                  unchecked: 'dependencies:unchecked:all',
                },
              },
            ],
          },
          {
            field: 'Ops.Update',
            title: 'Update',
            type: 'boolean',
            filtering: true,
            sorting: false,
            // width: '3%',
            dependencies: [
              {
                name: 'IsIncluded',
                states: {
                  checked: true,
                  unchecked: 'dependencies:unchecked:all',
                },
              },
            ],
          },
          {
            field: 'Ops.Delete',
            title: 'Delete',
            type: 'boolean',
            filtering: true,
            sorting: false,
            // width: '3%',
            dependencies: [
              {
                name: 'IsIncluded',
                states: {
                  checked: true,
                  unchecked: 'dependencies:unchecked:all',
                },
              },
            ],
          },
          {
            field: 'UpdatedByEmail',
            title: 'Updated By',
            type: 'string',
            editable: 'never',
            filtering: true,
            sorting: true,
            cellStyle: {
              minWidth: '150px',
            },
          },
          {
            field: 'UpdatedAt',
            title: 'Updated At',
            type: 'date',
            editable: 'never',
            filtering: true,
            sorting: true,
            render: (row = {}) =>
              row.UpdatedAt
                ? new Date(row.UpdatedAt).toLocaleString('en-US')
                : '',
            cellStyle: {
              minWidth: '150px',
            },
          },
          {
            field: 'IsBackend',
            title: 'Is Backend',
            type: 'boolean',
            editable: 'never',
            filtering: true,
            sorting: true,
          },
        ],
      },
      listFields: [],
      showInTable: false,
      searchable: false,
    },
    {
      field: 'UpdatedByEmail',
      title: 'Updated By',
      showInTable: true,
      editable: 'never',
      filtering: true,
      sorting: true,
    },
    {
      field: 'UpdatedAt',
      title: 'Updated At',
      showInTable: true,
      editable: 'never',
      type: 'date',
      filtering: true,
      sorting: true,
      render: (row = {}) =>
        row.UpdatedAt
          ? new Date(row.UpdatedAt).toLocaleString('en-US')
          : '',
    },
  ];
  return {
    headers: fields.filter(({ showInTable }) => showInTable),
    detailViewCols: fields,
  };
};

export { getRolesHeaders };
