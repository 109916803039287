/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'reactstrap';

import { api } from 'utils/fetch';

import config from 'configs/routes';

import {
  isBoolean,
  isNumeric,
  isObject,
  hasOwnProperty,
  isTypedNumeric,
} from 'utils';
import cache from 'utils/Cache';

import Toaster from 'components/ToasterQueue';

import Loader from 'components/Loader/Loader';

import { DELETE_SUCCESS } from 'components/common/utils/constants';

import configEstimate from './configs/estimate';
import configProjectQuote from './configs/projectQuote';

import LinesTable from './components/LinesTable';
import Footer from './components/Footer';
import Header from './components/Header';

import {
  calculateSystemPower,
  getFormatLinesAndUpdateAdditionalComponents,
  replaceUrlToEditEstimatePage,
  updateLinesPerModule,
  updateLines,
  validateInputs,
  getFormattedLineAndUpdateAdditionalComponents,
  checkPartNumberPrices,
  convertToFixedNumber,
  calculateTotalPrice,
  isProductFamilyPowerCap,
} from './utils';

import {
  CLAW_FR_PLUS,
  ESTIMATE,
  OPERATION_SAVE,
  PROJECT_QUOTE,
  messages,
  DEFAULT_VALUES,
  POWER_CAP,
  RAIL,
  MA,
} from './constants';

import Details from './components/Project/Details';
import View from './components/Project/View';

import './assets/style.scss';
import {
  ACCESSORIES,
  ACCESSORIES_UpC,
  ACCESSORY,
  BASE,
  SUCCESS,
  UNSELECTED,
  WIRE_ACCESSORIES_KIT_NAMES,
  WIRES,
  WIRES_PROPERTIES,
  WIRING,
  WIRING_KIT,
  WIRING_KITS,
} from './components/LinesTable/constants';
import { STAMP } from './components/LinesTable/Total/constant';
import {
  calculateTotal,
  getPartNumberPrice,
  updateAccessoriesTierPrice,
} from './components/LinesTable/utils';

class Estimate extends React.PureComponent {
  constructor(props) {
    super(props);

    if (this.props?.match?.params) {
      this.params = this.props.match.params;
    }

    const blank = this.isBlank();

    this.state = {
      loaded: false,
      isPowerCap: false,
      documents: [],
      defaultProps: {},
      partNumbers: {
        loaded: false,
        items: {},
      },
      id: this.getId(),
      values: {
        customerId: '',
        customerName: '',
        customers: [],
        module: {
          labels: {},
          isCustom: false,
        },
        modules: [],
        additionalComponents: [],
        limitAdditionalComponents: 3,
        supports: this.createSupports(),
        discount: 0,
        tier: this.getDefaultTier(),
        includeShipping: true,
        includeSignature: true,
        wiringKit: false,
        accessories: false,
        loaded: blank,
        estShippingCost: 0,
        engServicesCost: 0,
        excludeDeflectors: false, // Deprecated: Deflector-less per CPD-2779
      },
      lines: [],
      linesOther: [],

      wireAccessoriesSettings: {
        isSpacerStickEnabled: false,
        isWireRouterEnabled: false,
      },

      wireAccessoriesKitStatus: {},

      notes: '',
      operations: {
        disabledCancel: false,
        disabledSaveAs: !this.isEdit(),
        disabledSave: false,
        loadingSave: false,
        loadingSaveAs: false,
      },
      disabledProject: false,
      disabledFields: false,
      disabledModuleFirstPart: blank,
      disabledModuleSecondPart: blank,
      disableLinesTable: blank,
      disabledModuleList: blank,
      disabledFieldsExtended: blank,
      disabledView: blank,
      disabledFooter: blank,
      showStepsBadges: false,
      canAddCustomModule: this.isBlank(),
      turnOnValidation: false,
      errors: {},
      showAdditionalComponents: true,
      onSaveTriggered: false,
      needCheckExcludeDeflectors: false, // Deprecated: Deflector-less per CPD-2779
    };

    this.checkDocType();

    this.cache = {
      tempEstimateLines: new Map(),
      checkedPartNumberPrices: new Map(),
      wiringKits: [],
      accessories: [],
    };

    this.stateComponents = {
      details: {},
      view: {},
    };

    this.init();
  }

  isBlank() {
    const { id: stateId } = this.state || {};
    const id = this.params?.id;

    return stateId ? stateId === 'blank' : !id || id === 'blank';
  }

  isEdit() {
    if (!this.params || this.state?.id === 'blank') return false;

    if (this.params.id === 'blank') {
      return true;
    }

    return this.params.id && this.params.action === 'edit';
  }

  isCreateProjectQuote() {
    if (!this.params) return false;

    const { id, action, item } = this.params;

    return id && action === 'create' && item === 'quote';
  }

  isProjectQuote = (values) => {
    const props = values || this.state?.values;
    return isObject(props)
      ? !props.parentEstimateId && props.projectId
      : this.isCreateProjectQuote();
  };

  isPowerCapProject = (values) => {
    const rackingName = values?.mountingSystem?.name || '';
    return isProductFamilyPowerCap(rackingName);
  };

  getId() {
    return this.params?.id || null;
  }

  async init() {
    cache.setSecret(this.props.token);
    const { id } = this.state;

    const { partNumbers } = await cache.getPartNumbersPrices();

    const additionalComponents = await this.getPartenForcements();

    const defaultPropsSolarModule = await api.getDefaultPropsSolarModule();

    let defaultProps = {};
    const addBlankState = {};

    if (defaultPropsSolarModule?.data) {
      defaultProps = defaultPropsSolarModule.data;
      if (isTypedNumeric(defaultProps?.estShippingCostMod)) {
        defaultProps.estShippingCostMod = convertToFixedNumber(
          defaultProps?.estShippingCostMod
        );
      }
    }

    if (!this.isBlank()) {
      await this.getEstimate(
        id,
        additionalComponents,
        defaultProps,
        partNumbers
      );
    } else {
      addBlankState.estShippingCost = defaultProps?.estShippingCostMod;
    }

    await this.getDocuments(id);

    this.setState({
      partNumbers: {
        ...this.state.partNumbers,
        items: partNumbers,
        loaded: true,
      },
      defaultProps,
      values: {
        ...this.state.values,
        additionalComponents,
        ...addBlankState,
      },
      loaded: true,
    });
  }

  async getEstimate(id, additionalComponents, defaultProps, partNumbers) {
    const result = await api.getEstimate(id).catch((err) => {
      Toaster.error(err.message);
    });

    if (!result?.data) return;

    const { includes, estShippingCost, ...other } = result.data;

    const values = {
      ...this.state.values,
      ...other,
      estShippingCost:
        (isTypedNumeric(estShippingCost)
          ? convertToFixedNumber(defaultProps?.estShippingCostMod)
          : estShippingCost) || defaultProps?.estShippingCostMod,
      loaded: true,
      supports: this.createSupports(result.data.supports),
    };

    const lines = [];

    if (isObject(includes)) {
      values.includeImage = !!includes.image;

      if (typeof includes.shipping !== 'undefined') {
        values.includeShipping = !!includes.shipping;
      }

      values.wiringKit = !!includes.wiring;
      values.accessories = !!includes.accessories;

      if (typeof includes.signature !== 'undefined') {
        values.includeSignature = !!includes.signature;
      }
    }

    if (Array.isArray(values.modules)) {
      const mountingSystem = values.mountingSystem?.id || null;
      const rowSpacing = values.rowSpacing || null;

      values.isSingleModuleEstimate = values.modules.length === 1;

      values.modules = values.modules.map((module, index) => {
        if (index === 0) {
          values.module.manufacturer = module.manufacturer;
          values.module.moduleParent = module.id;
          values.module.module = module.id;
          values.module.mountingSystem = mountingSystem;
          values.module.rowSpacing = rowSpacing;

          values.numberModules = isNumeric(values.numberModules)
            ? values.numberModules
            : module.numberModules || module.count || 0;
          values.modulePower = isNumeric(values.modulePower)
            ? values.modulePower
            : module.power || 0;

          values.systemPower = isNumeric(values.systemPower)
            ? values.systemPower
            : module.systemPower ||
              module.totalPower ||
              calculateSystemPower(values.numberModules, values.modulePower);

          values.module.name =
            module.name ?? `${module.manufacturer} ${module.model}`;

          values.module.labels = {
            manufacturer: module.manufacturer,
            moduleParent: module.model,
            mountingSystem: values.mountingSystem?.name || null,
          };
        }

        if (module.linesBom.length) {
          lines.push(
            ...getFormatLinesAndUpdateAdditionalComponents(
              module,
              null,
              additionalComponents,
              this.isProjectQuote(values) /* , this.onDeleteLine */
            )
          );
        }

        const numberModules = module.numberModules || module.count || 0;
        const modulePower = module.power || 0;
        const systemPower =
          module.systemPower ||
          module.totalPower ||
          calculateSystemPower(numberModules, modulePower);

        values.module.isCustom = module.isCustom;

        const formattedModule = {
          ...module,
          name: module.name ?? `${module.manufacturer} ${module.model}`,
          metadata: {
            manufacturer: module.manufacturer,
            moduleParent: module.id,
            module: module.module || null,
            mountingSystem,
            rowSpacing,
            numberModules,
            modulePower,
            systemPower,
          },
        };

        return formattedModule;
      });
    }

    if (values.stamp && !values.engServicesCost) {
      values.engServicesCost = STAMP[values.stamp];
    }

    const newState = {
      ...this.state,
      values,
      lines,
      linesOther: result.data.linesOther || [],
      notes: values.notes || '',
      canAddCustomModule: !!values.parentEstimateId,
    };

    if (newState.linesOther.length) {
      newState.linesOther.forEach((line) => {
        const wireAccessoriesKitName =
          WIRE_ACCESSORIES_KIT_NAMES[line.partNum]?.name;
        if (wireAccessoriesKitName) {
          newState.wireAccessoriesKitStatus[wireAccessoriesKitName] = true;
        }
      });
      newState.linesOther = updateAccessoriesTierPrice(
        newState.linesOther,
        values.tier,
        partNumbers
      );
    }

    if (this.isProjectQuote(values)) {
      if (!newState.values.customerId || !newState.values.customerName) {
        newState.values.customerId = values.owner;
        newState.values.customerName = values.owner;
      }
    }

    if (newState.values.customerId && newState.values.customerName) {
      newState.values.customers = [
        {
          id: values.customerId,
          name: values.customerName,
          label: values.customerName,
          value: `${values.customerId}_${values.customerName}`,
        },
        ...this.state.values.customers,
      ];
    }

    if (!hasOwnProperty(values, 'tier')) {
      values.tier = this.getDefaultTier(values);
    }

    this.checkDocType(values);

    newState.showAdditionalComponents =
      !values.mountingSystem?.name?.includes(CLAW_FR_PLUS) &&
      !values.mountingSystem?.name?.includes(POWER_CAP);

    if (this.isPowerCapProject(values)) {
      newState.isPowerCap = true;
      if (isObject(newState.values.supports)) {
        newState.values.supports.numberMAs.value =
          DEFAULT_VALUES.POWER_CAP.NUMBER_MAs;
      }
      newState.values.manufacturer = DEFAULT_VALUES.POWER_CAP.MANUFACTURER;
      newState.values.membrane = DEFAULT_VALUES.POWER_CAP.MEMBRANE;
      newState.values.mechanicalAttachment = DEFAULT_VALUES.POWER_CAP.MA;
    }

    await this.onUpdateWireAccessoriesSettings(lines, newState);

    this.setState(newState);
  }

  onUpdateCache(name, value) {
    this.cache[name] = value;
  }

  async getPartenForcements() {
    const result = await api.getPartenForcements();

    if (!Array.isArray(result?.data)) return;

    const formattedAdditionalComponents = [];

    let i = -1;
    const n = result.data.length;

    while (++i < n) {
      formattedAdditionalComponents.push({
        ...result.data[i],
        id: result.data[i].partName,
        name: result.data[i].partName,
        selected: false,
        custom: false,
      });
    }

    return formattedAdditionalComponents;
  }

  async getDocuments(id) {
    api
      .getEstimateDocumentsList(id)
      .then(({ data }) => {
        this.setState({ documents: data });
      })
      .catch((err) => {
        Toaster.error(err?.message);
      });
  }

  onUpdatePricingList = async (lines, linesOther, tier) => {
    return cache
      .getPartNumbersPrices(true)
      .then(({ partNumbers: newPartNumbers }) => {
        const updatedLines = lines.map((item) => {
          const unitPrice = getPartNumberPrice(
            newPartNumbers,
            item.partNumber,
            tier
          );
          return {
            ...item,
            unitPrice,
            total: calculateTotal(item.quantity, unitPrice),
          };
        });
        const updatedLinesOther = linesOther.map((item) => {
          const unitPrice = getPartNumberPrice(
            newPartNumbers,
            item.partNum,
            tier
          );
          return {
            ...item,
            partPriceUnit: unitPrice,
            partPriceTotal: calculateTotal(item.partQty, unitPrice),
          };
        });
        this.setState({
          lines: updatedLines,
          linesOther: updatedLinesOther,
          partNumbers: {
            ...this.state.partNumbers,
            items: newPartNumbers,
          },
        });
        return true;
      });
  };

  onDeleteLine = ({ partNumber, index }) => {
    const { lines } = this.state;

    // const index = lines.findIndex((line) => line.partNumber === partNumber);
    lines.splice(index, 1);

    this.setState({
      lines: [...lines],
    });
  };

  onDeleteOtherLine = ({ partNumber, index }) => {
    const {
      linesOther,
      lines,
      values: { additionalComponents },
      wireAccessoriesKitStatus,
    } = this.state;

    const newWireAccessoriesKitStatus = {
      ...wireAccessoriesKitStatus,
    };

    let selectedAdditionalComponentsLength = 0;
    additionalComponents.forEach((component) => {
      if (component.selected) {
        selectedAdditionalComponentsLength += 1;
      }
    });

    const currentIndex =
      index - lines.length - selectedAdditionalComponentsLength;
    const deletedPart = linesOther.splice(currentIndex, 1)[0];

    const partData = WIRE_ACCESSORIES_KIT_NAMES[deletedPart.partNum];

    const { type, name } = partData || {};

    if (name) {
      newWireAccessoriesKitStatus[name] = false;
    }

    const typeLines = this.cache[type === WIRES ? WIRING_KITS : ACCESSORIES];

    const isAllDeselected = !typeLines.some((line) => {
      return newWireAccessoriesKitStatus[
        WIRE_ACCESSORIES_KIT_NAMES[line.partNum].name
      ];
    });

    const newState = {
      wireAccessoriesKitStatus: newWireAccessoriesKitStatus,
      linesOther: [...linesOther],
    };

    if (isAllDeselected) {
      const newValues = { ...this.state.values };
      newValues[type === WIRES ? WIRING_KIT : ACCESSORIES] = false;

      newState.values = newValues;
    }

    this.setState(newState);
  };

  onRefreshBom = (lines, linesOther, values) => {
    this.setState({
      lines,
      linesOther,
      values,
    });

    this.checkLines(lines, linesOther);
  };

  onRefreshValues = (values) => {
    this.setState({
      values,
    });
  };

  onChangeProject = async (values) => {
    if (!isObject(values)) return;

    if (values.error) {
      Toaster.error(messages[values.error]);
      return;
    }

    const {
      module,
      modules,
      selectedAdditionalComponents,
      unselectedAdditionalComponents,
      supports,
      fields,
      stamp,
      needUpdateLinesBomQuantity,
      includeShipping,
      manufacturerMembrane,
      salesTaxRate,
      mountingSystem,
      wireAccessories,
      ...other
    } = values;

    let { showAdditionalComponents } = this.state;

    const newValues = {
      ...this.state.values,
      ...other,
      ...(isObject(fields) ? fields : null),
    };

    if (typeof mountingSystem !== 'undefined') {
      newValues.mountingSystem = mountingSystem;
    }

    if (typeof salesTaxRate !== 'undefined') {
      if (salesTaxRate > 100 || salesTaxRate < 0) {
        return;
      }
      const taxValue = salesTaxRate.match(/\d+(\.?)(\d?\d?)/g);
      newValues.salesTaxRate = taxValue ? taxValue[0] : '';
    }

    if (manufacturerMembrane) {
      newValues.manufacturer = manufacturerMembrane.manufacturer;
      newValues.membrane = manufacturerMembrane.membrane;
    }

    let newLines = [...this.state.lines];

    if (isObject(supports)) {
      Object.keys(supports).forEach((key) => {
        if (!isObject(supports[key])) return;

        newValues.supports[key] = {
          ...newValues.supports[key],
          ...supports[key],
        };
      });
    }

    if (includeShipping) {
      newValues.includeShipping = true;
      newValues.estShippingCost = this.state.defaultProps?.estShippingCostMod;
    } else if (typeof includeShipping === 'boolean' && !includeShipping) {
      newValues.estShippingCost = 0;
      newValues.includeShipping = false;
    }

    if (stamp) {
      newValues.stamp = stamp;
      newValues.engServicesCost = STAMP[stamp];
    }

    if (module) {
      // Deprecated: Deflector-less per CPD-2779
      if (module.excludeDeflectors) {
        newValues.excludeDeflectors = module.excludeDeflectors.value;
        delete module.excludeDeflectors;

        if (this.state.needCheckExcludeDeflectors) {
          let value = '';

          if (!newValues.excludeDeflectors) {
            value = newValues.numberModules;
          }

          newValues.supports.numberDeflectors = {
            ...newValues.supports.numberDeflectors,
            value,
          };
        }
      }
      // Deprecated: Deflector-less per CPD-2779
      if (module.needCheckExcludeDeflectors) {
        this.setState({
          needCheckExcludeDeflectors: module.needCheckExcludeDeflectors.value,
        });

        delete module.needCheckExcludeDeflectors;
      }

      const keys = Object.keys(module);

      if (keys.length > 0) {
        newValues.module = {
          ...newValues.module,
        };

        if (module.mountingSystem || module.rowSpacing) {
          if (module.mountingSystem) {
            const { isPowerCap } = this.state;

            newValues.mountingSystem = module.mountingSystem.data;

            if (this.isPowerCapProject(newValues)) {
              if (!isPowerCap) {
                if (isObject(newValues.supports)) {
                  newValues.supports.numberMAs.value =
                    DEFAULT_VALUES.POWER_CAP.NUMBER_MAs;
                }

                newValues.manufacturer = DEFAULT_VALUES.POWER_CAP.MANUFACTURER;
                newValues.membrane = DEFAULT_VALUES.POWER_CAP.MEMBRANE;
                newValues.mechanicalAttachment = DEFAULT_VALUES.POWER_CAP.MA;
              }

              if (showAdditionalComponents) {
                showAdditionalComponents = false;

                newValues.additionalComponents = newValues.additionalComponents.map(
                  (item) => {
                    item.selected = false;
                    return item;
                  }
                );
              }

              this.setState({ isPowerCap: true });
            }

            if (isPowerCap && !this.isPowerCapProject(newValues)) {
              newValues.manufacturer = '';
              newValues.membrane = '';
              newValues.mechanicalAttachment = '';
              if (!newValues.mountingSystem?.name?.includes(CLAW_FR_PLUS)) {
                showAdditionalComponents = true;
              }

              this.setState({ isPowerCap: false });
            }
          }

          newValues.modules = newValues.modules.map((item) => {
            if (module.mountingSystem) {
              item.metadata.mountingSystem = module.mountingSystem.value;
              if (
                module.mountingSystem.value !==
                  newValues.module.mountingSystem &&
                item.metadata.rowSpacing
              ) {
                item.metadata.rowSpacing = null;
              }
            } else {
              item.metadata.rowSpacing = module.rowSpacing.value;
            }
            return item;
          });

          if (module.mountingSystem?.label) {
            if (
              showAdditionalComponents &&
              (module.mountingSystem?.label?.includes(CLAW_FR_PLUS) ||
                module.mountingSystem?.label?.includes(POWER_CAP))
            ) {
              // With cfrplus additional parts should be hided CPD-2825
              let i = -1;
              const n = newValues.additionalComponents.length;
              while (++i < n) {
                newValues.additionalComponents[i].selected = false;
              }

              showAdditionalComponents = false;
            } else if (
              !showAdditionalComponents &&
              !module.mountingSystem?.label?.includes(CLAW_FR_PLUS) &&
              !module.mountingSystem?.label?.includes(POWER_CAP)
            ) {
              showAdditionalComponents = true;
            }
          }

          if (
            module.mountingSystem &&
            module.mountingSystem.value !== newValues.module.mountingSystem &&
            newValues.module.rowSpacing
          ) {
            newValues.module.rowSpacing = null;
            newValues.excludeDeflectors = false; // Deprecated: Deflector-less per CPD-2779
          }
        }

        Object.keys(module).forEach((name) => {
          newValues.module[name] = module[name].value;
          newValues.module.labels[name] = module[name].label;
        });

        newValues.module.name = `${newValues.module.labels.manufacturer} ${newValues.module.labels.moduleParent}`;
      }
    }

    if (modules) {
      newValues.modules = modules;

      if (modules[0]?.isCustom) {
        newValues.module = {
          labels: {},
          isCustom: true,
          mountingSystem: modules[0].metadata.mountingSystem,
          rowSpacing: modules[0].metadata.rowSpacing,
        };
      } else {
        newValues.module.isCustom = false;
      }

      newLines = updateLinesPerModule(this.state.lines, modules);
    }

    if (
      typeof newValues.numberModules !== 'undefined' ||
      typeof newValues.modulePower !== 'undefined'
    ) {
      newValues.systemPower =
        calculateSystemPower(newValues.numberModules, newValues.modulePower) ||
        0;
    }

    if (isBoolean(values.wiringKit)) {
      const wireAccessoriesKitStatus = {
        ...this.state.wireAccessoriesKitStatus,
        wireRouter: values.wiringKit,
        wireClip: values.wiringKit,
        homeRunCover: values.wiringKit,
        homeRunClip: values.wiringKit,
      };

      if (!this.state.wireAccessoriesSettings.isWireRouterEnabled) {
        delete wireAccessoriesKitStatus.wireRouter;
      }
      this.setState({ wireAccessoriesKitStatus });
    }

    if (isBoolean(values.accessories)) {
      const wireAccessoriesKitStatus = {
        ...this.state.wireAccessoriesKitStatus,
        spacerStick: values.accessories,
        shimPads: values.accessories,
        groundingLugs: values.accessories,
        basePads: values.accessories,
        moduleLevelPowerElectronics: values.accessories,
      };

      if (!this.state.wireAccessoriesSettings.isSpacerStickEnabled) {
        delete wireAccessoriesKitStatus.spacerStick;
      }

      this.setState({ wireAccessoriesKitStatus });
    }

    if (wireAccessories) {
      const wireAccessoriesKitStatus = {
        ...this.state.wireAccessoriesKitStatus,
        [wireAccessories.name]: wireAccessories.checked,
      };

      const allParts = [...this.cache.wiringKits, ...this.cache.accessories];

      const mapExistedItems = new Map();

      this.state.linesOther.forEach((line) => {
        if (
          WIRE_ACCESSORIES_KIT_NAMES[line.partNum]?.name !==
            wireAccessories.name ||
          (WIRE_ACCESSORIES_KIT_NAMES[line.partNum]?.name ===
            wireAccessories.name &&
            wireAccessories.checked)
        ) {
          mapExistedItems.set(line.partNum, line);
        }
      });

      const linesOther = [];

      allParts.forEach((item) => {
        const kitParam = WIRE_ACCESSORIES_KIT_NAMES[item.partNum];
        if (!kitParam) return;
        const status = wireAccessoriesKitStatus[kitParam.name];
        if (kitParam.name === wireAccessories.name) {
          if (!wireAccessories.checked) {
            const typeLines = this.cache[
              kitParam.type === WIRES ? WIRING_KITS : ACCESSORIES
            ];

            const isAllDeselected = !typeLines.some((line) => {
              return wireAccessoriesKitStatus[
                WIRE_ACCESSORIES_KIT_NAMES[line.partNum].name
              ];
            });

            if (isAllDeselected) {
              newValues[
                kitParam.type === WIRES ? WIRING_KIT : ACCESSORIES
              ] = false;

              this.setState({ values: newValues });
            }
          } else if (mapExistedItems.has(item.partNum)) {
            linesOther.push(mapExistedItems.get(item.partNum));
            mapExistedItems.delete(item.partNum);
          } else {
            linesOther.push(item);
          }
        } else if (status) {
          if (mapExistedItems.has(item.partNum)) {
            linesOther.push(mapExistedItems.get(item.partNum));
            mapExistedItems.delete(item.partNum);
          } else {
            linesOther.push(item);
          }
        }
      });

      for (const [key, value] of mapExistedItems.entries()) {
        linesOther.push(value);
      }

      const { tier } = newValues;
      const partNumbers = this.state.partNumbers.items;

      mapExistedItems.clear();

      this.setState({
        wireAccessoriesKitStatus,
        linesOther: updateAccessoriesTierPrice(linesOther, tier, partNumbers),
      });
    }

    if (selectedAdditionalComponents) {
      newValues.additionalComponents = newValues.additionalComponents.map(
        (item) => {
          item.selected = !!selectedAdditionalComponents.get(item.id);
          return item;
        }
      );
    }

    if (unselectedAdditionalComponents) {
      newValues.additionalComponents = newValues.additionalComponents.map(
        (item) => {
          item.selected = false;
          return item;
        }
      );
    }

    if (needUpdateLinesBomQuantity) {
      let options = // Deprecated: Deflector-less per CPD-2779
        this.state.needCheckExcludeDeflectors &&
        !newValues.excludeDeflectors &&
        !!newValues.supports.numberDeflectors.value
          ? {
              deflectors: {
                quantity: newValues.supports.numberDeflectors.value,
              },
              bolts: {
                quantity: 2 * newValues.supports.numberDeflectors.value,
                operation: 'increase',
              },
            }
          : null;

      if (newValues.excludeDeflectors) {
        options = {
          deflectors: {
            excludeDeflectors: true,
          },
        };
      }

      newLines = updateLines(newLines, newValues, this.docType, options);
    }

    if (
      hasOwnProperty(values, 'manufacturerMembrane') ||
      hasOwnProperty(values, 'membrane') ||
      hasOwnProperty(values, 'mechanicalAttachment')
    ) {
      this.updateEstimateLinesByMA(newValues, newLines);
    }

    this.validate(newValues, newLines);

    this.setState({
      showAdditionalComponents,
      values: newValues,
      lines: newLines,
    });
  };

  getEstimateLineBy = async ({
    mechanicalAttachment,
    manufacturer,
    membrane,
  }) => {
    if (!mechanicalAttachment || !manufacturer || !membrane) return;

    const result = await api.getMas(
      mechanicalAttachment,
      manufacturer,
      membrane
    );

    if (Array.isArray(result?.data) && result?.data.length > 0) {
      return result?.data[0];
    }

    return null;
  };

  updateEstimateLinesByMA = async (newValues, newLines) => {
    const estimateLine = await this.getEstimateLineBy({
      mechanicalAttachment: newValues.mechanicalAttachment,
      manufacturer: newValues.manufacturer,
      membrane: newValues.membrane,
    });

    if (estimateLine) {
      const formmatedLine = getFormattedLineAndUpdateAdditionalComponents(
        {
          line: estimateLine,
          id: newValues.modules?.[0]?.id,
          model: newValues.modules?.[0]?.model,
          partNumbers: this.state.partNumbers,
          tierId: newValues.tier,
          type: ACCESSORY,
        },
        this.onDeleteLine,
        newValues.additionalComponent,
        { status: SUCCESS, value: ACCESSORIES_UpC }
      );

      if (
        formmatedLine &&
        !this.cache.tempEstimateLines.has[formmatedLine.id]
      ) {
        newLines = newLines.filter(({ id }) => {
          return !this.cache.tempEstimateLines.has(id);
        });

        this.cache.tempEstimateLines.clear();

        newLines.push(formmatedLine);

        this.cache.tempEstimateLines.set(formmatedLine.id, true);
        // TODO: Move lines outside
        this.setState({
          lines: [...newLines],
        });
      }
    }
  };

  onUpdateWireAccessoriesSettings = async (lines, newState) => {
    const BasePartNumber = lines?.find((line) =>
      line.description.split(', ')[0].includes(BASE.toUpperCase())
    )?.partNumber;

    const RailPartNumber = lines?.find((line) =>
      line.description.includes(RAIL)
    )?.partNumber;

    await api
      .getWireAccessoriesSettings({
        BasePartNumber,
        RailPartNumber,
      })
      .then((wireAccessoriesSettings) => {
        if (wireAccessoriesSettings?.data) {
          if (newState) {
            newState.wireAccessoriesSettings = {
              ...newState.wireAccessoriesSettings,
              ...wireAccessoriesSettings.data,
            };
          } else {
            this.setState({
              wireAccessoriesSettings: {
                ...this.state.wireAccessoriesSettings,
                ...wireAccessoriesSettings.data,
              },
            });
          }
        }
      });

    if (newState) {
      const {
        numberModules = 0,
        subarraysCount = 0,
        mountingSystem: { rackingSystem } = {},
      } = newState.values;

      await api
        .getWiringParts(BasePartNumber, numberModules)
        .then((wiringResult) => {
          this.cache.wiringKits = wiringResult?.data || [];
        });

      await api
        .getAccessoriesParts(
          RailPartNumber,
          numberModules,
          subarraysCount,
          rackingSystem
        )
        .then((accessoriesResult) => {
          this.cache.accessories = accessoriesResult?.data || [];
        })
        .catch((err) => {
          Toaster.error(err?.message?.message || err?.message);
          return null;
        });
    }
  };

  onChangeEstimateLines = ({ lines, linesOther, loaded = false }) => {
    const updateLines = {};

    if (lines) {
      const { values } = this.state;
      this.validate(values, lines, loaded);
      updateLines.lines = lines;
    }

    if (linesOther) {
      const { wireAccessoriesKitStatus } = this.state;
      updateLines.linesOther = linesOther;
      updateLines.wireAccessoriesKitStatus = { ...wireAccessoriesKitStatus };
      if (linesOther.length) {
        const { partNumbers } = this.state;
        const newValues = {
          ...this.state.values,
          wiringKit: false,
          accessories: false,
        };

        linesOther.forEach((line) => {
          const currentPart = partNumbers?.items[line.partNum];
          const type =
            currentPart?.ProductType === WIRING ? WIRES : ACCESSORIES;

          if (type === WIRES && WIRE_ACCESSORIES_KIT_NAMES[line.partNum]) {
            newValues.wiringKit = true;
            updateLines.wireAccessoriesKitStatus[
              WIRE_ACCESSORIES_KIT_NAMES[line.partNum]?.name
            ] = true;
          }
          if (
            type === ACCESSORIES &&
            WIRE_ACCESSORIES_KIT_NAMES[line.partNum]
          ) {
            newValues.accessories = true;
            updateLines.wireAccessoriesKitStatus[
              WIRE_ACCESSORIES_KIT_NAMES[line.partNum]?.name
            ] = true;
          }
        });
        updateLines.values = newValues;
      }
    }

    this.setState(updateLines);

    if (loaded) {
      this.onUpdateWireAccessoriesSettings(lines);
    }

    this.checkLines(
      lines || this.state.lines,
      linesOther || this.state.linesOther
    );
  };

  onCancel = () => {
    this.goToEstimates();
  };

  onSave = async () => {
    const { values, lines } = this.state;

    if (this.validateOnSave(values, lines)) return;

    this.updateOperations({
      loadingSave: true,
    });

    const data = {
      data: this.getDataForSaving(),
    };

    try {
      let result;

      if (this.isBlank()) {
        result = await api.postEstimate(data);
      } else {
        result = await api.putEstimate(this.state.id, data);
      }

      if (!isObject(result?.data)) {
        throw new Error();
      }

      this.params = {
        ...(this.params ?? {}),
        action: 'edit',
        id: result.data.id,
        item: null,
      };

      this.checkDocType(result.data);

      this.setState({
        id: result.data.id,
        values: {
          ...this.state.values,
          id: result.data.id,
          variantNumber: result.data.variantNumber,
        },
      });

      replaceUrlToEditEstimatePage(result.data.id);

      Toaster.success(messages.save.success);
    } catch {
      Toaster.error(messages.save.error);
    }

    this.updateOperations({
      loadingSave: false,
    });

    this.scrollToTop();
  };

  onSaveAs = async () => {
    const { values, lines } = this.state;

    if (this.validateOnSave(values, lines)) return;

    this.updateOperations({
      loadingSaveAs: true,
    });

    const data = {
      data: this.getDataForSaving(),
    };

    try {
      const result = await api.postEstimate(data);

      if (isObject(result?.data)) {
        this.checkDocType(result.data);

        this.setState({
          id: result.data.id,
          values: {
            ...this.state.values,
            id: result.data.id,
            variantNumber: result.data.variantNumber,
          },
          documents: [],
        });

        replaceUrlToEditEstimatePage(result.data.id);
      }
    } catch {
      Toaster.error(messages.save.error);
    }

    Toaster.success(messages.save.success);

    this.updateOperations({
      loadingSaveAs: false,
    });

    this.scrollToTop();
  };

  onChangeFooter = (values) => {
    if (!isObject(values)) return;

    if (hasOwnProperty(values, 'notes')) {
      this.setState({
        notes: values.notes.substring(0, 250),
      });
    }
  };

  updateOperations = async ({
    loadingSaveAs = null,
    loadingSave = null,
    disabledCancel = null,
    disabledSave = null,
    disabledSaveAs = null,
  }) => {
    const updatedOperations = {
      ...this.state.operations,
    };

    let { disabledProject } = this.state;

    if (isBoolean(loadingSaveAs)) {
      updatedOperations.loadingSaveAs = loadingSaveAs;
      disabledProject = loadingSaveAs;
      disabledSave = loadingSaveAs;
    }

    if (isBoolean(loadingSave)) {
      updatedOperations.loadingSave = loadingSave;
      disabledProject = loadingSave;
      disabledSaveAs = loadingSave;
    }

    if (isBoolean(disabledCancel)) {
      updatedOperations.disabledCancel = disabledCancel;
    }

    if (isBoolean(disabledSave)) {
      updatedOperations.disabledSave = disabledSave;
    }

    if (isBoolean(disabledSaveAs)) {
      updatedOperations.disabledSaveAs = disabledSaveAs;
    }

    this.setState({
      operations: updatedOperations,
      disabledProject,
    });
  };

  getDataForSaving = () => {
    const {
      id,
      projectName,
      description,
      unit,
      salesPerson,
      opportunity,
      address,
      latitude,
      longitude,
      locationInfo,
      locationElevation,
      owner,
      discount,
      tier,
      mechanicalAttachment,
      manufacturer,
      membrane,
      stamp,
      numberModules,
      modulePower,
      systemPower,
      additionalComponents,
      supports,
      modules,
      estimateFileRef,
      status,
      archived,
      module: { mountingSystem, rowSpacing: formattedRowSpacing },
      includeImage,
      includeShipping,
      includeSignature,
      wiringKit,
      accessories,
      salesTaxRate,
      customerId,
      customerName,
      engServicesCost,
      estShippingCost,
      excludeDeflectors, // Deprecated: Deflector-less per CPD-2779
    } = this.state.values;

    let lineNeedsUpdate = false;

    const { lines, linesOther, partNumbers } = this.state;

    const formattedLinesOther = [];

    linesOther.forEach((item) => {
      return formattedLinesOther.push(item);
    });

    const formattedMountingSystem = {
      id: mountingSystem,
    };

    const mapFormattedModules = {};
    const formattedModules = [];

    if (Array.isArray(modules)) {
      modules.forEach((module) => {
        const fModule = {
          id:
            module.isCustom && !module.metadata.moduleParent
              ? module.id
              : module.metadata.moduleParent,
          manufacturer: module.metadata.manufacturer,
          power: module.metadata.modulePower,
          count: module.metadata.numberModules,
          isCustom: module.isCustom,
          moduleDimensions: module.moduleDimensions,
          model: module.model,
          dataSheetUrl: module.dataSheetUrl,
          linesBom: [],
        };

        mapFormattedModules[fModule.id] = fModule;

        formattedModules.push(fModule);
      });
    }

    if (Array.isArray(lines)) {
      lines.forEach((line, i) => {
        const {
          partNumber,
          description,
          quantity,
          unitPrice,
          total,
          partQtySpare,
          moduleId,
          onDeleteLine,
        } = line;

        if (line.type === ACCESSORY) {
          formattedLinesOther.push({
            partNum: partNumber,
            partDescription: description,
            partQty: parseFloat(quantity),
            partPriceUnit: parseFloat(unitPrice),
            partPriceTotal: parseFloat(total),
            type: ACCESSORY,
          });

          return;
        }

        if (!mapFormattedModules[moduleId]) return;

        if (onDeleteLine) {
          lineNeedsUpdate = true;
          delete lines[i].onDeleteLine;
          lines[i].disableSelectModule = this.isProjectQuote();
        }

        mapFormattedModules[moduleId].linesBom.push({
          partNum: partNumber,
          partDescription: description,
          partQty: parseFloat(quantity),
          partQtySpare, // TODO: ? - need to add from table
          partPriceUnit: parseFloat(unitPrice),
          partPriceTotal: parseFloat(total),
        });
      });
    }

    additionalComponents.forEach((component) => {
      if (component.selected) {
        const part = partNumbers.items[component.partNumberTopLevel];
        const unitPrice = getPartNumberPrice(
          partNumbers.items,
          component.partNumberTopLevel,
          tier
        );
        const kitQty = component.kitQty || 1;
        const quantity = Math.ceil(numberModules / kitQty);

        mapFormattedModules[modules[0].id].linesBom.push({
          partNum: component.partNumberTopLevel,
          partDescription: part.PartDescription,
          partQty: parseFloat(quantity),
          partQtySpare: undefined, // TODO: ? - need to add from table
          partPriceUnit: parseFloat(unitPrice),
          partPriceTotal: calculateTotalPrice(unitPrice, quantity),
        });
      }
    });

    /* TODO: After successful testing save operation we can remove it.
    const formattedAdditionalComponents = [];

    if (Array.isArray(additionalComponents)) {
      additionalComponents.forEach(
        ({
          id,
          kitQty,
          name,
          partName,
          partNumber,
          partNumberTopLevel,
          selected,
        }) => {
          formattedAdditionalComponents.push({
            id,
            kitQty,
            name,
            partName,
            partNumber,
            partNumberTopLevel,
            values: selected ? ['Include'] : ['Exclude'], // TODO?: ['Include', 'Exclude']
          });
        }
      );
    } */

    const formattedSupports = [];

    if (isObject(supports)) {
      Object.keys(supports).forEach((key) => {
        const { id, name, value } = supports[key];

        formattedSupports.push({
          id,
          name,
          value,
        });
      });
    }

    let formattedLocationInfo = null;

    if (locationInfo) {
      formattedLocationInfo = {
        country: locationInfo.country,
        administrativeAreaLevel1: locationInfo.administrative_area_level_1,
        postalCode: locationInfo.postal_code,
        formattedAddress: locationInfo.postal_code,
      };
    }

    const data = {
      id,
      projectName,
      description,
      unit,
      customerId,
      customerName,
      salesPerson,
      address,
      locationInfo: formattedLocationInfo,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      linesOther: formattedLinesOther,
      opportunity,
      notes: this.state.notes,
      locationElevation: parseFloat(locationElevation),
      owner,
      engServicesCost: parseFloat(engServicesCost),
      estShippingCost: parseFloat(estShippingCost),
      mountingSystem: formattedMountingSystem,
      rowSpacing: formattedRowSpacing,
      modules: formattedModules,
      estimateFileRef, // string ? - x
      status, // string ? - x
      archived, // bool ? - x
      discount: parseFloat(discount),
      includeImage,
      includeShipping,
      includeSignature,
      tier: `${tier}`,
      mechanicalAttachment,
      manufacturer,
      membrane,
      stamp,
      numberModules,
      modulePower,
      systemPower,
      salesTaxRate: parseFloat(salesTaxRate),
      supports: formattedSupports,
      includes: {
        image: includeImage,
        shipping: includeShipping,
        wiring: wiringKit,
        accessories,
        signature: includeSignature,
      },
      excludeDeflectors, // Deprecated: Deflector-less per CPD-2779
    };

    if (lineNeedsUpdate) {
      this.setState({ lines: [...lines] });
    }

    return data;
  };

  onHandleStateProject = ({ name, value }) => {};

  getLoader = () => {
    return (
      <div className="loader">
        <Loader loading />
      </div>
    );
  };

  checkIfLoaded = () => {
    const { details, view } = this.stateComponents;

    this.stateComponents.loaded = details.loaded && view.loaded;

    if (this.stateComponents.loaded) {
      this.onHandleStateProject({ name: 'loaded', value: true });
    }
  };

  onHandleStateDetails = ({ name, value }) => {
    this.stateComponents.details[name] = value;
    this.checkIfLoaded();
  };

  onHandleStateView = ({ name, value }) => {
    this.stateComponents.view[name] = value;
    this.checkIfLoaded();
  };

  checkLines = async (lines, linesOther) => {
    const zeroPrices = [];
    const newPartNumberPrices = new Map();

    const { tier } = this.state.values;
    let checkedPartNumberPrices = this.cache.checkedPartNumberPrices.get(tier);

    if (!checkedPartNumberPrices) {
      checkedPartNumberPrices = new Map();
    }

    this.cache.checkedPartNumberPrices.clear();

    checkPartNumberPrices({
      lines,
      checkedPartNumberPrices,
      newPartNumberPrices,
      zeroPrices,
    });
    checkPartNumberPrices({
      lines: linesOther,
      checkedPartNumberPrices,
      zeroPrices,
    });

    this.cache.checkedPartNumberPrices.set(tier, newPartNumberPrices);

    if (zeroPrices.length > 0) {
      Toaster.warn(
        `The following part(s) have $0 unit price: ${zeroPrices.join(', ')}`
      );
    }
  };

  getContent = () => {
    const {
      values,
      id,
      documents,
      partNumbers,
      operations,
      notes,
      customerId,
      customerName,
      customers,
      disabledProject,
      disabledFields,
      disabledModuleFirstPart,
      disabledModuleSecondPart,
      disableLinesTable,
      disabledModuleList,
      disabledFieldsExtended,
      disabledView,
      disabledFooter,
      showStepsBadges,
      canAddCustomModule,
      errors,
      linesOther,
      lines,
      defaultProps,
      needCheckExcludeDeflectors, // Deprecated: Deflector-less per CPD-2779
      showAdditionalComponents,
      isPowerCap,
      wireAccessoriesSettings,
      wireAccessoriesKitStatus,
    } = this.state;

    const config = this.getConfig();

    return (
      <>
        <Header
          isEdit={this.isEdit()}
          projectName={`${values.projectName} | v${values.variantNumber}`}
          notes={notes}
          id={id}
          onEvent={this.onEventHeader}
          onDocumentsUpdate={this.onDocumentsUpdate}
        />

        <Row style={{ display: 'flex' }}>
          <Col md={12} lg={12} xl={6}>
            <Details
              id={id}
              values={values}
              isPowerCap={isPowerCap}
              config={config}
              disabled={disabledProject}
              errors={errors}
              disabledFields={disabledFields}
              disabledModuleFirstPart={disabledModuleFirstPart}
              disabledModuleSecondPart={disabledModuleSecondPart}
              disabledFieldsExtended={disabledFieldsExtended}
              disabledModuleList={disabledModuleList}
              disabledExcludeDeflectors={lines.length === 0} // Deprecated: Deflector-less per CPD-2779
              showStepsBadges={showStepsBadges}
              canAddCustomModule={canAddCustomModule}
              onChange={this.onChangeProject}
              onHandleState={this.onHandleStateDetails}
              defaultProps={defaultProps}
              blank={this.isBlank()}
            />
          </Col>
          <Col md={12} lg={12} xl={6}>
            <View
              values={values}
              isPowerCap={isPowerCap}
              wireAccessoriesSettings={wireAccessoriesSettings}
              wireAccessoriesKitStatus={wireAccessoriesKitStatus}
              showAdditionalComponents={showAdditionalComponents}
              disabled={disabledView || disabledProject}
              errors={errors}
              config={config}
              showStepsBadges={showStepsBadges}
              onChange={this.onChangeProject}
              onHandleState={this.onHandleStateView}
            />
          </Col>
        </Row>

        <Row className="estimate-elem-table">
          <LinesTable
            values={values}
            partNumbers={partNumbers.items}
            lines={lines}
            linesOther={linesOther}
            onRefreshBOM={this.onRefreshBom}
            onChangeLines={this.onChangeEstimateLines}
            onUpdatePricingList={this.onUpdatePricingList}
            onDeleteLine={this.onDeleteLine}
            onDeleteOtherLine={this.onDeleteOtherLine}
            isEdit={this.isEdit() || this.isCreateProjectQuote()}
            isProjectQuote={this.isProjectQuote(values)}
            onChangeProject={this.onChangeProject}
            onUpdateCache={(name, value) => this.onUpdateCache(name, value)}
            docType={this.docType}
            disableLinesTable={disableLinesTable}
            showStepsBadges={showStepsBadges}
            needCheckExcludeDeflectors={needCheckExcludeDeflectors} // Deprecated: Deflector-less per CPD-2779
            wireAccessoriesKitStatus={wireAccessoriesKitStatus}
          />
        </Row>
        <Footer
          disabled={disabledProject || disabledFooter}
          disabledCancel={operations.disabledCancel}
          disabledSave={operations.disabledSave}
          disabledSaveAs={operations.disabledSaveAs}
          loadingSave={operations.loadingSave}
          loadingSaveAs={operations.loadingSaveAs}
          notes={notes}
          documents={documents}
          estimateId={id}
          customerId={customerId}
          customerName={customerName}
          customers={customers}
          showStepsBadges={showStepsBadges}
          onCancel={this.onCancel}
          onSave={this.onSave}
          onSaveAs={this.onSaveAs}
          onChange={this.onChangeFooter}
        />
      </>
    );
  };

  validate = (values, lines, loadedLines) => {
    if (!isObject(values)) return;

    if (this.isBlank()) {
      this.validateBlank(values, lines, loadedLines);
    } else {
      this.validateEdit(values);
    }
  };

  validateBlank = (values, lines, loadedLines) => {
    let {
      disabledFields,
      disabledModuleFirstPart,
      disabledModuleSecondPart,
      disabledFieldsExtended,
      disableLinesTable,
      disabledView,
      disabledFooter,
      turnOnValidation,
      errors,
    } = this.state;

    const {
      customerName,
      projectName,
      numberModules,
      modulePower,
      modules,
      module,
      address,
    } = values;

    if (disabledModuleFirstPart) {
      disabledModuleFirstPart = !(
        customerName &&
        projectName &&
        numberModules &&
        modulePower
      );
    }

    if (!disabledModuleFirstPart && disabledModuleSecondPart) {
      disabledModuleSecondPart = modules.length === 0;
    }

    if (disableLinesTable) {
      disableLinesTable = !(module.rowSpacing && module.mountingSystem);
      turnOnValidation = !disableLinesTable;
    }

    if (
      loadedLines &&
      disabledFieldsExtended &&
      disabledView &&
      disabledFooter
    ) {
      disabledFieldsExtended = false;
      disabledView = false;
      disabledFooter = false;
    }

    let newErrors = {};

    if (turnOnValidation) {
      newErrors = validateInputs(
        values,
        this.getConfig(),
        this.getActionForValidation()
      );

      Object.keys(newErrors).forEach((key) => {
        if (!errors[key]) {
          Toaster.error(
            `${newErrors[key]} to generate the correct Estimate Lines. Please make sure you enter a value.`
          );
        }
      });
    }

    this.updateOperations({ disabledSaveAs: true });

    this.setState({
      disabledFields,
      disabledModuleFirstPart,
      disabledModuleSecondPart,
      disabledFieldsExtended,
      disableLinesTable,
      disabledModuleList: disabledModuleFirstPart || modules.length === 0,
      disabledView,
      disabledFooter,
      turnOnValidation,
      errors: newErrors,
      onSaveTriggered: this.state.onSaveTriggered && newErrors.length > 0,
    });
  };

  createSupports = (supports) => {
    const formattedSupports = {};

    const defaultSupportFields = this.getDefaultSupportFields();

    let i = -1;
    const n = defaultSupportFields.length;

    while (++i < n) {
      const field = defaultSupportFields[i];
      const found = supports?.find(({ name }) => name === field.name);

      formattedSupports[field.name] = {
        id: field.id,
        name: field.name,
        label: field.label,
        value: typeof found?.value !== 'undefined' ? found.value : field.value,
      };
    }

    return formattedSupports;
  };

  validateEdit(values) {
    const newErrors = validateInputs(
      values,
      this.getConfig(),
      this.getActionForValidation()
    );

    this.setState({
      errors: newErrors,
      onSaveTriggered: this.state.onSaveTriggered && newErrors.length > 0,
    });
  }

  validateOnSave = (values, lines) => {
    if (!values.modules.length) {
      Toaster.warn('Modules must be added');
      return true;
    }

    const errors = validateInputs(values, this.getConfig(), OPERATION_SAVE);

    if (Object.keys(errors).length > 0) {
      this.setState({ errors, onSaveTriggered: true });
      Toaster.error(
        'There are required fields that need your attention. Please make sure all the required fields are entered with the correct values.'
      );
      return true;
    }

    if (lines.length === 0) {
      Toaster.warn('Estimate Lines must be added');
      return true;
    }

    if (lines.some((line) => line.partNumber === UNSELECTED)) {
      Toaster.warn('All lines must have a part number selected');
      return true;
    }

    return false;
  };

  getActionForValidation = () => {
    return this.state.onSaveTriggered ? OPERATION_SAVE : null;
  };

  scrollToTop = () => {
    globalThis.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  onEventHeader = (data) => {
    if (data?.tag === ESTIMATE && data?.action === DELETE_SUCCESS) {
      this.goToEstimates();
    }
  };

  onDocumentsUpdate = (newDocument) => {
    const { documents } = this.state;
    this.setState({ documents: [...documents, newDocument] });
  };

  goToEstimates = () => {
    this.props.history.push(config.urls.estimates);
  };

  checkDocType = (values) => {
    if (this.isProjectQuote(values)) {
      this.docType = PROJECT_QUOTE;
    } else {
      this.docType = ESTIMATE;
    }
  };

  getConfig = (values) => {
    if (this.isProjectQuote(values)) {
      return configProjectQuote;
    }

    return configEstimate;
  };

  getDefaultTier = (values) => {
    return this.getConfig(values)?.fields.tier.ui.defaultValue;
  };

  getDefaultSupportFields = (values) => {
    return this.getConfig(values)?.fields.supports.default.values;
  };

  render() {
    return (
      <Container className="blank-estimate-page">
        {this.state.loaded ? this.getContent() : this.getLoader()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth?.token,
  };
};

export default connect(mapStateToProps)(Estimate);
