const validationSchema = {
  mountingSystem: {
    name: 'Mounting System',
    type: 'string',
    required: true,
    length: { min: 1, max: 100 },
  },
  manufacturer: {
    name: 'Manufacturer',
    type: 'string',
    required: true,
    length: { min: 1, max: 100 },
  },
  model: {
    name: 'Model Name',
    type: 'string',
    required: true,
    length: { min: 1, max: 100 },
  },
  W: {
    name: 'Weight',
    type: 'number',
    required: true,
  },
  power: {
    name: 'Panel Wattage',
    type: 'string',
    required: true,
  },
  fireType: {
    name: 'Fire Type',
    type: 'string',
    length: { min: 1, max: 100 },
  },
  dataSheetUrl: {
    name: 'Datasheet',
    type: 'string',
    required: true,
  },
};

export default validationSchema;
