/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import PropsTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Switcher from 'components/Switcher';
import SpecSheetMenu from 'components/DetailModalView/SpecSheetMenu';
import ShowIfHasPermission from 'components/ShowIfHasPermission';

import MaterialTable from 'components/MaterialTable';
import { api, inlineFilters } from 'utils/fetch';
import { ALLOW_SM_ACTIVATION } from 'redux/constants/category';

import { CLICK_TO_OPEN } from 'components/common/utils/constants';

import Toaster from 'components/ToasterQueue';

import Button from 'components/common/Button';

import { getUser } from 'redux/selectors/selectors';
import { flagSelector } from 'redux/flags/selectors';

import { v4 as uuidV4 } from 'uuid';

import { getSMMHeaders, getSMMLogsHeaders } from './utils';

import SMMImportMenu from './SMMImportMenu';

import './style.scss';
import {
  S_SHADOW_STATUS_NA,
  S_SHADOW_STATUS_REQUESTED,
  S_SHADOW_STATUS_SUCCESS,
  executeSMPipeline,
} from './stateMachine';

class SMM extends PureComponent {
  title = 'Solar modules';

  SMM_TABS = {
    0: {
      title: 'SMM',
      name: 'Smm',
      headers: [],
      detailViewCols: [],
      fieldsMap: [],
      getItem: api.getSingleSM,
      getData: (params = {}) =>
        api.getSM({
          ...params,
        }),
      onSave: api.putSM,
    },
    1: {
      title: 'Custom SMM',
      name: 'CustomSmm',
      headers: [],
      detailViewCols: [],
      fieldsMap: [],
      getItem: api.getSingleCSM,
      getData: (params = {}) =>
        api.getCSM({
          ...params,
        }),
      onSave: api.putCSM,
    },
    2: {
      title: 'SMM Sync logs',
      name: 'SMMLogs',
      headers: [],
      detailViewCols: [],
      fieldsMap: [],
      getData: (params = {}) =>
        api.getSMsLogs({
          ...params,
        }),
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      refreshCount: 0,
      btnSyncLoading: false,
      btnSyncDisabled: false,
      btnSyncAllDisabled: false,
      btnSyncAllLoading: false,
    };
  }

  getExtraDetailViewToolbar = ({
    isAllowInDropdown,
    onSwitcherChange,
    isEditMode,
    isAllFieldsAreCorrect,
    specSheetUrl,
    data,
    setNewSpecSheetUrl,
    showExtraDialog,
  }) => (
    <div className="props-additional">
      <SpecSheetMenu
        url={specSheetUrl}
        isEditMode={isEditMode}
        isAllowInDropdown={isAllowInDropdown}
        data={data}
        setNewSpecSheetUrl={setNewSpecSheetUrl}
        showExtraDialog={!!showExtraDialog}
      />
      <ShowIfHasPermission conditions={[`${ALLOW_SM_ACTIVATION}.show`]}>
        <Switcher
          label="Allow in Dropdown"
          labelPlacement="start"
          isChecked={isAllowInDropdown}
          handleChange={onSwitcherChange}
          disabled={!isEditMode}
          isAllFieldsAreCorrect={isAllFieldsAreCorrect}
        />
      </ShowIfHasPermission>
    </div>
  );

  onChangeTab = (e, activeTab) => {
    const { activeTab: stateActiveTab } = this.state;
    if (stateActiveTab !== activeTab) {
      this.setState({ activeTab });
    }
  };

  refreshTable = () => {
    const { refreshCount } = this.state;
    this.setState({ refreshCount: refreshCount + 1 });
  };

  middleToolbar = () => {
    const {
      activeTab,
      btnSyncLoading,
      btnSyncAllLoading,
      btnSyncDisabled,
      btnSyncAllDisabled,
    } = this.state;

    const { debugMode } = this.props;

    return (
      <div className="solar-modules__middletoolbar">
        <Paper square>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            // eslint-disable-next-line no-shadow
            onChange={(e, activeTab) => this.onChangeTab(e, activeTab)}
            aria-label="disabled tabs example"
          >
            <Tab label="SMM" />
            <Tab label="Custom SMM" />
            <Tab label="SMM Sync logs" />
          </Tabs>
        </Paper>
        <div className="solar-modules__buttons-sync">
          <Button
            loading={btnSyncLoading}
            disabled={btnSyncLoading || btnSyncDisabled}
            onClick={() => this.sync()}
            className="btn btn-primary btn-md"
            customLoadingStyle={{
              position: 'absolute',
              marginLeft: '-21px',
              marginTop: '-3px',
            }}
          >
            Sync
          </Button>
          {debugMode && (
            <Button
              loading={btnSyncAllLoading}
              disabled={btnSyncAllLoading || btnSyncAllDisabled}
              onClick={this.syncAll}
              className="btn btn-primary btn-md"
              customLoadingStyle={{
                position: 'absolute',
                marginLeft: '-21px',
                marginTop: '-3px',
              }}
            >
              Sync All
            </Button>
          )}
        </div>
      </div>
    );
  };

  handleImportDocument = (queuedImportData) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.changeQueuedImportData(queuedImportData);
  };

  canRunSync = async () => {
    try {
      const statuses = [
        S_SHADOW_STATUS_REQUESTED,
        S_SHADOW_STATUS_NA,
        S_SHADOW_STATUS_SUCCESS,
      ];

      const results = await api.getWorkflowStatus(
        process.env.REACT_APP_SM_SYNC_ID,
        'sync-sm'
      );

      return statuses.includes(results.data?.status) && results.data?.success;
    } catch {
      return false;
    }
  };

  sync = async (isFullSync = false) => {
    try {
      let btnSyncLoading = true;
      let btnSyncAllLoading = false;

      if (isFullSync) {
        btnSyncLoading = false;
        btnSyncAllLoading = true;
      }

      const btnSyncDisabled = true;
      const btnSyncAllDisabled = true;

      this.setState({
        btnSyncLoading,
        btnSyncDisabled,
        btnSyncAllLoading,
        btnSyncAllDisabled,
      });

      if (await this.canRunSync()) {
        Toaster.success('Sync started');

        let response = {};

        const { user } = this.props;

        const params = {
          workflow: 'sync-sm',
          projectId: process.env.REACT_APP_SM_SYNC_ID,
          params: {
            userId: user._id,
            isFullSync,
            syncId: uuidV4(),
          },
          listener: (data) => {
            response = {
              ...response,
              ...data,
            };
          },
        };

        await executeSMPipeline(params);

        const smStatus = await api.getSMStatus(params.params.syncId);

        if (!smStatus?.data?.HasErrors) {
          Toaster.showLink(CLICK_TO_OPEN, smStatus.data.ReportUrl);
          this.refreshTable();
        } else {
          Toaster.showLink(
            CLICK_TO_OPEN,
            smStatus?.data?.ReportUrl,
            toast.TYPE.ERROR
          );
        }
      } else {
        Toaster.warn('Sync is already running');
      }
    } catch (err) {
      Toaster.error(err?.message?.message ? err.message.message : err?.message);
    } finally {
      this.setState(
        this.setState({
          btnSyncLoading: false,
          btnSyncDisabled: false,
          btnSyncAllLoading: false,
          btnSyncAllDisabled: false,
        })
      );
    }
  };

  syncAll = async () => {
    await this.sync(true);
  };

  render() {
    const { acl } = this.props;
    const { activeTab, refreshCount } = this.state;

    let headers = [];
    let detailViewCols = [];
    let fieldsMap = [];

    switch (activeTab) {
      case 0:
        ({ headers, detailViewCols, fieldsMap } = getSMMHeaders());
        break;
      case 1:
        ({ headers, detailViewCols, fieldsMap } = getSMMHeaders(true));
        break;
      case 2:
        ({ headers } = getSMMLogsHeaders());
        break;
      default:
        break;
    }

    Object.keys(this.SMM_TABS).forEach((key) => {
      const tab = this.SMM_TABS[key];
      tab.headers = headers;
      tab.fieldsMap = fieldsMap;
      tab.detailViewCols = detailViewCols;
    });

    const tab = this.SMM_TABS[activeTab];
    const logsTabActive = activeTab === 2;

    return (
      <Container className="smm-page">
        <Row>
          <MaterialTable
            tableProps={{
              title: 'Solar Modules',
              detailViewTittle: 'SMM',
              name: tab.name,
            }}
            middleToolbar={this.middleToolbar}
            columns={tab.headers}
            report={logsTabActive}
            remoteData
            fullScreenDetailView
            getData={async (param) => {
              const newParams = inlineFilters(param);
              const result = await tab.getData(newParams).catch(() => {
                return { data: { data: [], pagination: { totalItems: 0 } } };
              });
              return {
                ...result,
                count: result.data.pagination.totalItems,
                data: result.data,
              };
            }}
            refreshCount={refreshCount}
            detailsView={{
              getItem: tab.getItem,
              cols: tab.detailViewCols,
              onSave: tab.onSave,
              extraToolbar: this.getExtraDetailViewToolbar,
              idFieldName: 'id',
              // extraMenuDataSpecific: [extraMenuImportSMM({ acl })], // grid specific
              importMenu: (
                <SMMImportMenu
                  acl={acl}
                  handleImportDocument={this.handleImportDocument}
                  fieldsMap={tab.fieldsMap}
                  cols={tab.detailViewCols}
                  refreshTable={this.refreshTable}
                />
              ),
            }}
          />
        </Row>
      </Container>
    );
  }
}

SMM.propTypes = {
  acl: PropsTypes.shape({}),
  changeQueuedImportData: PropsTypes.func,
  user: PropsTypes.shape({}),
};

SMM.defaultProps = {
  acl: {},
  changeQueuedImportData: () => {},
  user: {},
};

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
    debugMode: flagSelector(state, 'mode.debug', false),
  };
};

export default connect(mapStateToProps)(SMM);
