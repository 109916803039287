import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import PropsTypes from 'prop-types';
import { getObjProp } from 'utils';

function EditViewSelect({
  path,
  field,
  fieldsValues,
  onChangeField,
  lookup,
  isReadOnly,
  isEditMode,
}) {
  const value = useMemo(() => getObjProp(fieldsValues, path || field, '-'), [
    fieldsValues,
    path,
    field,
  ]);

  const onChange = useCallback(
    // eslint-disable-next-line
    ({ target: { value } }) => {
      return onChangeField(field, value, path);
    },
    [onChangeField, path, field]
  );

  const [items, setItems] = useState([]);

  const createMenuItems = (lookup) => {
    let items = [];

    if (Array.isArray(lookup)) {
      if (Array.isArray(lookup[0])) {
        items = lookup.map((x) => (
          <MenuItem value={x[1]} key={x[0]}>
            {x[1]}
          </MenuItem>
        ));
      } else if (
        Object.prototype.toString.call(lookup[0]) === '[object Object]'
      ) {
        items = lookup.map((x) => (
          <MenuItem value={x.id} key={x.id}>
            {x.name}
          </MenuItem>
        ));
      }
    } else if (Array.isArray(lookup?.[0])) {
      items = Object.entries(lookup).map((x) => (
        <MenuItem value={x[1]} key={x[0]}>
          {x[1]}
        </MenuItem>
      ));
    }

    setItems(items);
  };

  useEffect(() => {
    if (typeof lookup === 'function') {
      (async () => {
        createMenuItems((await lookup(fieldsValues))?.data);
      })();
    } else {
      createMenuItems(lookup);
    }
  }, [lookup, fieldsValues]);

  let key = null;
  let label = null;

  if (Object.prototype.toString.call(value) === '[object Object]') {
    key = value.id;
    label = value.name;
  } else {
    key = value;
    label = value;
  }

  return isEditMode ? (
    <Select
      id={field}
      value={key}
      disabled={isReadOnly}
      onChange={onChange}
      label={label}
    >
      {items}
    </Select>
  ) : (
    label || null
  );
}

export default EditViewSelect;

EditViewSelect.propTypes = {
  path: PropsTypes.string,
  field: PropsTypes.string,
  fieldsValues: PropsTypes.object,
  isReadOnly: PropsTypes.bool,
  lookup: PropsTypes.array || PropsTypes.object || PropsTypes.func,
  isEditMode: PropsTypes.string,
  onChangeField: PropsTypes.func,
};

EditViewSelect.defaultProps = {
  path: '',
  field: '',
  fieldsValues: {},
  isReadOnly: false,
  lookup: {},
  onChangeField: () => ({}),
  isEditMode: false,
};
