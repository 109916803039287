import { connect } from 'react-redux';
import { getAcl } from 'redux/selectors/selectors';
import { changeQueuedImportData } from 'redux/actions/dataViewActions';
import SMM from './SMM';

const mapStateToProps = (state) => ({
  acl: getAcl(state), // only for demo/test purposes
});

const mapDispatchToProps = (dispatch) => ({
  changeQueuedImportData: (queuedImportData) => {
    dispatch(changeQueuedImportData(queuedImportData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SMM);
