import {
  BASE,
  BLT,
  BOLT,
  CAM,
  CAM_CLAW,
  CAM_SEAT_SPACER,
  DEFLECTOR,
  FRAME_ENHANCER,
  HWKITMASTRUT,
  LOCK_CLAW_CLIP,
  MA,
  MASTRUT,
  MODULE_CONNECTOR,
  RAIL,
} from 'containers/Estimates/Estimate/constants';

export default {
  [HWKITMASTRUT]: {
    overageMultiplier: 1,
    roundUp: 10,
    precision: 0,
  },
  [MASTRUT]: {
    overageMultiplier: 1,
    roundUp: 10,
    precision: 0,
  },
  [MA]: {
    overageMultiplier: 1,
    roundUp: 10,
    precision: 0,
  },
  [BOLT]: {
    overageMultiplier: 1.02,
    roundUp: 1000,
    precision: 0,
  },
  [BLT]: {
    overageMultiplier: 1.02,
    roundUp: 1000,
    precision: 0,
  },
  [RAIL]: {
    overageMultiplier: 1.01,
    roundUp: 10,
    precision: 0,
  },
  [MODULE_CONNECTOR]: {
    overageMultiplier: 1.005,
    roundUp: 10,
    precision: 0,
  },
  [CAM_CLAW]: {
    overageMultiplier: 1.015,
    roundUp: 20,
    precision: 0,
  },
  [CAM]: {
    overageMultiplier: 1.015,
    roundUp: 20,
    precision: 0,
  },
  [DEFLECTOR]: {
    overageMultiplier: 1.005,
    roundUp: 10,
    precision: 0,
  },
  [BASE]: {
    overageMultiplier: 1.01,
    roundUp: 10,
    precision: 0,
  },
  [CAM_SEAT_SPACER]: {
    overageMultiplier: 2,
    roundUp: 1,
    precision: 0,
    kitPartNumber: '5000533',
    componentPartNumber: '2000833*',
    qtyInKit: 300,
  },
  [FRAME_ENHANCER]: {
    overageMultiplier: 2,
    roundUp: 1,
    precision: 0,
    kitPartNumber: '5000532',
    componentPartNumber: '2000832*',
    qtyInKit: 300,
  },
  [LOCK_CLAW_CLIP]: {
    overageMultiplier: 2,
    roundUp: 1,
    precision: 0,
    kitPartNumber: '5000419',
    componentPartNumber: '2000819*',
    qtyInKit: 300,
  },
  default: {
    overageMultiplier: 1,
    roundUp: 1,
    precision: 0,
    qtyInKit: 1,
  },
};
