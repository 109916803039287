import React from 'react';
import moment from 'moment';
import { Icon } from '@material-ui/core';

import ExportIcon from '../assets/export.svg';

import { generateCSVTableReport } from '../export/csv';
import { generateExcelTableReport } from '../export/excel';
import { generatePDFTableReport } from '../export/pdf';

export function CallMade() {
  return (
    <Icon style={{ textAlign: 'center', overflow: 'visible' }}>
      <img
        style={{ verticalAlign: 'unset' }}
        src={ExportIcon}
        height={25}
        width={25}
        alt="exportIcon"
      />
    </Icon>
  );
}

export const getDefaultFileName = (reportName, extension = null) => {
  const fileName = `PanelClaw_${reportName}_${moment(new Date()).format(
    'YYYY-MM-DD_HH-mm'
  )}`;
  return !extension || extension === 'pdf'
    ? fileName
    : `${fileName}.${extension}`;
};

export const extraMenuExport = ({
  tableProps: { title, name },
  columns,
  remoteData,
  cachedData,
  getBackGeneratedLink,
  filterOptions,
}) => ({
  name: 'AllGridExportMenu',
  icon: <CallMade />,
  options: [
    { title: 'Export as PDF', type: 'pdf' },
    { title: 'Export as CSV', type: 'csv' },
    { title: 'Export as Excel', type: 'excel' },
  ],

  optionsHandleSelect: async (exportType) => {
    const headers = columns.map((el) => ({
      key: el.field,
      display: el.title,
    }));
    const exportOptions = {
      data: cachedData.filteredData,
      reportName: title,
      headers,
      action: 'save',
    };

    const exportTo = {
      pdf: () =>
        !remoteData
          ? generatePDFTableReport(exportOptions)
          : getBackGeneratedLink({
              exportTo: 'pdf',
              table: name,
              search: filterOptions && filterOptions.search,
            }),
      csv: () =>
        !remoteData
          ? generateCSVTableReport(exportOptions)
          : getBackGeneratedLink({
              exportTo: 'csv',
              table: name,
              search: filterOptions && filterOptions.search,
            }),
      excel: () =>
        !remoteData
          ? generateExcelTableReport(exportOptions)
          : getBackGeneratedLink({
              exportTo: 'excel',
              table: name,
              search: filterOptions && filterOptions.search,
            }),
    };

    exportTo[exportType]();
  },
});
