export function isSupportedLang(lang, supportedLanguages) {
  return Object.keys(supportedLanguages).indexOf(lang) > -1;
}

export function updateWithArgs(str, params) {
  if (!str) return null;

  // When params comes as key-value pairs
  if (params && params[0] && typeof params[0] === 'object') {
    const paramObj = params[0];

    Object.keys(paramObj).forEach((key) => {
      str = str.replace(key, paramObj[key]); // eslint-disable-line no-param-reassign
    });
  } else {
    params.forEach((param) => {
      str = str.replace('$?', param); // eslint-disable-line no-param-reassign
    });
  }

  return str;
}

export function createMarkup(html) {
  // TODO: Sanitize the html
  return { __html: html };
}
