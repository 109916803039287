import { api, inlineFilters } from 'utils/fetch';
import React from 'react';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import FilterBox from 'containers/DesignerWorkflow/Tools/Filter/FilterBox';
import Tooltip from '@material-ui/core/Tooltip';
import Toaster from 'components/ToasterQueue';
import LookupCache from '../utils/lookupCache';

const mapFieldNameToTitle = {
  Id: 'Request ID',
  Customer: 'Customer',
  Status: 'Current Status',
  Project: 'Project',
  RequestType: 'Request Type',
  RequestNotes: 'Request Notes',
  Priority: 'Priority',
  PriorityNotes: 'Priority Notes',
  Salesperson: 'Salesperson',
  Rac: 'Rac',
  Product: 'Product',
  SpecSheet: 'SpecSheet',
  Aif: 'Aif',
  PhotoLayout: 'Photo Layout',
  OpportunityId: 'Opportunity ID',
  CreatedBy: 'CreatedBy',
  CreatedAt: 'Created At',
};

const fields = [
  {
    field: 'Id',
    title: (
      <Tooltip enterNextDelay={300} title="Request ID">
        <div className="cell-with-tooltip">Request ID</div>
      </Tooltip>
    ),
    editable: 'never',
    showInTable: true,
    filtering: false,
    sortable: true,
    maxWidth: '60px',
    cellStyle: {
      whiteSpace: 'nowrap',
      maxWidth: '60px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  {
    field: 'Customer',
    title: (
      <Tooltip enterNextDelay={300} title="Customer">
        <div className="cell-with-tooltip">Customer</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Customer}>
        <div className="cell-with-tooltip">{row.Customer}</div>
      </Tooltip>
    ),
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'Status',
    title: (
      <Tooltip enterNextDelay={300} title="Current Status">
        <div className="cell-with-tooltip">Current Status</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    sortable: true,
    searchable: true,
    filtering: true,
    exType: 'select',
    filter: (() => [
      { id: 'Approved', name: 'Approved' },
      { id: 'Denied', name: 'Denied' },
    ])(),
    defaultFilterValue: 'Approved',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Status}>
        <div className="cell-with-tooltip">{row.Status}</div>
      </Tooltip>
    ),
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'Project',
    title: (
      <Tooltip enterNextDelay={300} title="Project">
        <div className="cell-with-tooltip">Project</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Project}>
        <div className="cell-with-tooltip">{row.Project}</div>
      </Tooltip>
    ),
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'RequestType',
    title: (
      <Tooltip enterNextDelay={300} title="Request Type">
        <div className="cell-with-tooltip">Request Type</div>
      </Tooltip>
    ),
    showInTable: true,
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestType}>
        <div className="cell-with-tooltip">{row.RequestType}</div>
      </Tooltip>
    ),
    exType: 'select',
    lookup: async () => LookupCache.getRequestType(),
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'RequestNotes',
    title: (
      <Tooltip enterNextDelay={300} title="Request Notes">
        <div className="cell-with-tooltip">Request Notes</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: true,
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestNotes}>
        <div className="cell-with-tooltip">{row.RequestNotes}</div>
      </Tooltip>
    ),
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'Priority',
    title: (
      <Tooltip enterNextDelay={300} title="Priority">
        <div className="cell-with-tooltip">Priority</div>
      </Tooltip>
    ),
    showInTable: true,
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Priority}>
        <div className="cell-with-tooltip">{row.Priority}</div>
      </Tooltip>
    ),
    exType: 'select',
    lookup: async () => LookupCache.getPriority(),
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'PriorityNotes',
    title: 'Priority Notes',
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'Salesperson',
    title: (
      <Tooltip enterNextDelay={300} title="Salesperson">
        <div className="cell-with-tooltip">Salesperson</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Salesperson}>
        <div className="cell-with-tooltip">{row.Salesperson}</div>
      </Tooltip>
    ),
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'Rac',
    title: (
      <Tooltip enterNextDelay={300} title="Rac">
        <div className="cell-with-tooltip">Rac</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Rac}>
        <div className="cell-with-tooltip">{row.Rac}</div>
      </Tooltip>
    ),
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'Product',
    title: (
      <Tooltip enterNextDelay={300} title="Product">
        <div className="cell-with-tooltip">Product</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Product}>
        <div className="cell-with-tooltip">{row.Product}</div>
      </Tooltip>
    ),
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'SpecSheet',
    title: 'SpecSheet',
    type: 'boolean',
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'Aif',
    title: 'Aif',
    type: 'boolean',
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'PhotoLayout',
    title: 'Photo Layout',
    type: 'boolean',
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'OpportunityId',
    title: (
      <Tooltip enterNextDelay={300} title="Opportunity ID">
        <div className="cell-with-tooltip">Opportunity ID</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '60px',
    cellStyle: {
      maxWidth: '60px',
    },
  },
  {
    field: 'CreatedBy',
    title: 'CreatedBy',
    type: 'string',
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'CreatedAt',
    title: (
      <Tooltip enterNextDelay={300} title="Created At">
        <div className="cell-with-tooltip">Created At</div>
      </Tooltip>
    ),
    type: 'date',
    format: 'YYYY-MM-DD',
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    defaultSort: 'desc',
    maxWidth: '60px',
    cellStyle: {
      whiteSpace: 'nowrap',
      maxWidth: '60px',
      overflow: 'hidden',
    },
  },
];

const metadata = {
  headers: fields.filter(({ showInTable }) => showInTable),
  detailViewCols: fields.map((field) => {
    return {
      ...field,
      title: mapFieldNameToTitle[field.field],
    };
  }),
};

const filtersFields = {
  Id: {
    label: 'Submission ID',
    type: 'string',
  },
  OpportunityID: {
    label: 'Opportunity ID',
    type: 'string',
  },
  SubmissionStatus: {
    label: 'Submission Status',
    type: 'select',
    lookup: async () => LookupCache.getSubmissionStatus(),
    options: {
      isMulti: true,
    },
  },
  RequestType: {
    label: 'Request Type',
    type: 'select',
    lookup: async () => LookupCache.getRequestType(),
    options: {
      isMulti: true,
    },
  },
};

const getInfoBox = (open, data, onClose) => {
  if (open) {
    return (
      <Box className="infoBox">
        <IconButton aria-label="close" onClick={onClose} className="closeIcon">
          <CloseIcon />
        </IconButton>
        {data.map(({ Label, Value }) => (
          <div>{`${Label}: ${Value}`}</div>
        ))}
      </Box>
    );
  }
};

const getFilterBox = (open, filters, onSubmit, onResetFilter, onClose) => {
  if (open) {
    return (
      <FilterBox
        fields={filtersFields}
        filters={filters}
        onSubmit={onSubmit}
        onResetFilter={onResetFilter}
        onClose={onClose}
      />
    );
  }
};

const getHeaderActions = () => [
  {
    label: 'Export Excel',
    getUrlLink: async (filters) => {
      return api.getExportSubmissions({ ...filters, exportTo: 'excel' });
    },
  },
  {
    label: 'Export PDF',
    getUrlLink: async (filters) => {
      return api.getExportSubmissions({ ...filters, exportTo: 'pdf' });
    },
  },
];

export default {
  submissions: {
    components: {
      header: {
        title: 'Designer Workflow - Submissions',
        getInfoBox,
        getHeaderActions,
      },
      search: {
        getFilterBox,
      },
    },
    title: 'Submissions',
    name: 'Submissions',
    type: 'submissions',
    tableTitle: 'Submissions',
    tableName: 'DW Submissions',
    detailViewTittle: 'Submission',
    getItem: api.getDWSubmission,
    getData: async (param) => {
      const newParams = inlineFilters(param);
      const res = await api.getDWSubmission(newParams).catch((err) => {
        Toaster.error(
          err?.message?.message ? err.message.message : err?.message
        );
        return {
          data: {
            data: [],
            pagination: {
              totalItems: 0,
            },
          },
        };
      });

      return {
        ...res,
        count: res.data.pagination.totalItems,
        data: res.data.data,
      };
    },
    putData: async (data) => {
      if (data.data.Status === 'Approved') {
        return api.approveDWSubmission(data);
      }

      return api.putDWSubmission(data);
    },
    headers: metadata.headers,
    detailViewCols: metadata.detailViewCols,
  },
  accountCoordinator: {
    allowedEditFields: {
      RequestNotes: true,
    },
  },
  designerManager: {
    allowedEditFields: {
      RequestNotes: true,
    },
  },
  designerView: {
    allowedEditFields: {
      RequestNotes: true,
    },
  },
};
