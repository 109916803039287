import { connect } from 'react-redux';

import { getAcl } from 'redux/selectors/selectors';

import Loader from './Loader';

const mapStateToProps = (state) => {
  const {
    loader: { loading, tableLoading },
  } = state;

  const acl = getAcl(state);

  return {
    loading,
    tableLoading,
    aclLoading: !acl.isInited(),
  };
};

export default connect(mapStateToProps)(Loader);
