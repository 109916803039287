import { createSelector } from 'reselect';
import { getNestedValueByStringKey } from 'utils/mixins';

// Filters
const flagFilter = (state, key, defaultStatus = true) => {
  const { flags } = state;
  const status = getNestedValueByStringKey(flags, key);
  if (status === null) return defaultStatus;
  return status;
};

export const flagSelector = createSelector(flagFilter, (flag) => flag);

export default {
  flagSelector,
};
