import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import useState from 'react-usestateref';

import { changeFilterOptions } from 'redux/actions/dataViewActions';

const localization = {
  addRemoveColumns: 'Add or remove columns',
  nRowsSelected: '{0} row(s) selected',
  showColumnsTitle: 'Show Columns',
  showColumnsAriaLabel: 'Show Columns',
  exportTitle: 'Export',
  exportAriaLabel: 'Export',
  exportCSVName: 'Export as CSV',
  exportPDFName: 'Export as PDF',
  searchTooltip: 'Search',
  searchPlaceholder: 'Search',
  searchAriaLabel: 'Search',
  clearSearchAriaLabel: 'Clear Search',
};

// eslint-disable-next-line no-shadow
function Search({ changeFilterOptions, tableName, value }) {
  const [searchText, setSearchText, searchTextRef] = useState(value);
  const submitSearch = useCallback(
    (e) => {
      e.preventDefault();
      changeFilterOptions({
        tableName,
        filterOptions: {
          search: searchTextRef.current,
          changedBy: 'search',
        },
      });
    },
    [searchText, changeFilterOptions]
  );

  const onClear = useCallback(
    (e) => {
      setSearchText('');
      submitSearch(e);
    },
    [submitSearch]
  );
  return (
    <form onSubmit={submitSearch} className="search-block">
      <TextField
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
        placeholder={localization.searchPlaceholder}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!searchText}
                onClick={onClear}
                aria-label={localization.clearSearchAriaLabel}
              >
                <ClearIcon fontSize="small" aria-label="clear" />
              </IconButton>
              <IconButton
                type="submit"
                aria-label="search"
                onClick={submitSearch}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          inputProps: {
            'aria-label': localization.searchAriaLabel,
          },
        }}
      />
    </form>
  );
}

const mapDispatchToProps = (dispatch) => ({
  changeFilterOptions: (table, filterOptions) => {
    dispatch(changeFilterOptions(table, filterOptions));
  },
});

export default connect(null, mapDispatchToProps)(Search);

Search.propTypes = {
  changeFilterOptions: PropTypes.func.isRequired,
  tableName: PropTypes.string,
  value: PropTypes.string,
};

Search.defaultProps = {
  tableName: '',
  value: '',
};
