import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import request, { post } from 'utils/request';
import cache from 'utils/Cache';
import {
  INITIALIZED,
  SIGN_UP_REQUESTED,
  SIGN_IN_REQUESTED,
  SIGN_OUT_REQUESTED,
  ID_TOKEN_REQUESTED,
  HELIOSCOPE_LOGIN_REQUESTED,
  USER_UPDATE_REQUESTED,
  PASSWORD_UPDATE_REQUESTED,
} from '../authTypes';
import authActions from '../actions/authActions';
import tokenAction from '../actions/cognitotoken';

import { getUser, registerUser, changeUserPassword } from './_utils';
import { clearError, setError } from '../errors/actions';
import { setFlag } from '../flags/actions';

export function* signUp({ name, email, password }) {
  yield put(authActions.signingUp(true));
  try {
    yield call(registerUser, name, email, password);
    yield put(authActions.signingUpSuccess());
  } catch (error) {
    yield put(authActions.signingUpFailure(error.message));
  }
}

export function* initialSignIn() {
  yield put(authActions.initializing(true));
  try {
    const authUser = yield call(getUser);
    const token = authUser.getIdToken().getJwtToken();
    const user = yield call(request, 'user', {
      token,
    });
    const refreshToken = authUser.getRefreshToken().token;
    const expirationTime = authUser.getIdToken().payload.exp;

    yield put(
      authActions.initializingSuccess(user, token, expirationTime, refreshToken)
    );
    yield put(tokenAction.watchToken());

    const acl = yield call(getACL, user._id, token);

    yield put(authActions.setACL(acl));
  } catch (error) {
    yield put(authActions.initFailure(error.message));
  }
}

export function* signIn({ cognitoUserSession }) {
  yield put(authActions.signingIn(true));
  try {
    const token = cognitoUserSession.getIdToken().getJwtToken();
    const refreshToken = cognitoUserSession.getRefreshToken().token;
    const expirationTime = cognitoUserSession.getIdToken().payload.exp;

    const user = yield call(request, 'user', {
      token,
    });

    yield put(
      authActions.signingInSuccess(user, token, expirationTime, refreshToken)
    );

    yield put(tokenAction.watchToken());

    const acl = yield call(getACL, user._id, token);
    yield put(authActions.setACL(acl));
  } catch (error) {
    yield put(authActions.signingInFailure(error.message));
  }
}

function awsSignOut() {
  return new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    });

    const user = userPool.getCurrentUser();

    if (user) {
      user.getSession((error, session) => {
        if (error) {
          reject(error);
        } else {
          user.setSignInUserSession(session);

          user.globalSignOut({
            onSuccess: (result) => {
              resolve(result);
            },
            // eslint-disable-next-line no-shadow
            onFailure: (error) => {
              reject(error);
            },
          });
        }
      });
    } else {
      resolve(null);
    }
  });
}

export function* signOut() {
  try {
    yield call(awsSignOut);

    sessionStorage.removeItem('token');

    yield call(cache.reset);

    yield put(authActions.signOutSuccess());
  } catch (error) {
    console.log('SignOut:ERROR:', error);
  }
}

export function* refreshSession() {
  try {
    const authUser = yield call(getUser);
    const token = authUser.getIdToken().getJwtToken();
    const expirationTime = jwtDecode(token).exp;
    const refreshToken = authUser.getRefreshToken().token;
    const user = yield call(request, 'user', {
      token,
    });
    yield put(
      authActions.fetchingIdTokenSuccess(
        token,
        expirationTime,
        refreshToken,
        user
      )
    );
  } catch (error) {
    yield put(authActions.fetchingIdTokenFailure(true));
  }
}

export function* helioscopeSignIn({
  helioscopeLogin,
  helioscopePass,
  cosUserId,
}) {
  try {
    const body = {
      cosUserId,
      email: helioscopeLogin,
      password: helioscopePass,
      remember_me: true,
    };
    const url = 'cis/import/helioscope/login';
    const user = yield call(post, url, JSON.stringify(body));
    yield put(authActions.loginHelioscopeSuccess(user.sessionId));
  } catch (error) {
    yield put(authActions.loginHelioscopeFailed(error.message));
  }
}

export function* updateUser({ user, options }) {
  const actionKey = 'user.updating';
  const errorKey = 'USER_UPDATING_FAILED';
  const authUser = yield call(getUser);
  const token = authUser.getIdToken().getJwtToken();

  yield put(clearError(actionKey));
  yield put(setFlag(actionKey, true, options));

  try {
    const requestUrl = `user`;
    const updateduser = yield call(request, requestUrl, {
      method: 'PUT',
      body: JSON.stringify(user),
    });

    if (updateduser.hasHelioscopeCredentials) {
      yield put(authActions.loginHelioscope({ cosUserId: updateduser._id }));
    }

    yield put(authActions.setUser(updateduser, token));
  } catch (error) {
    yield put(setError(actionKey, errorKey, error));
  }

  yield put(setFlag(actionKey, false, options));
}

export function* passwordChange({ oldPassword, newPassword, options }) {
  const actionKey = 'user.password';
  const errorKey = 'PASSWORD_UPDATING_FAILED';

  yield put(clearError(actionKey));
  yield put(setFlag(actionKey, true, options));

  try {
    yield call(changeUserPassword, oldPassword, newPassword);
  } catch (error) {
    yield put(setError(actionKey, errorKey, error));
  }

  yield put(setFlag(actionKey, false, options));
}

export function* getACL(cognitoId, token) {
  try {
    const acl = yield call(request, `cis/acl/${cognitoId}/frontend`, { token });
    return acl;
  } catch (error) {
    return null;
  }
}

export default [
  takeLatest(SIGN_UP_REQUESTED, signUp),
  takeLatest(INITIALIZED, initialSignIn),
  takeLatest(SIGN_IN_REQUESTED, signIn),
  takeLatest(ID_TOKEN_REQUESTED, refreshSession),
  takeLatest(SIGN_OUT_REQUESTED, signOut),
  takeLatest(HELIOSCOPE_LOGIN_REQUESTED, helioscopeSignIn),
  takeEvery(USER_UPDATE_REQUESTED, updateUser),
  takeLatest(PASSWORD_UPDATE_REQUESTED, passwordChange),
];
