// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import PropTypes from 'prop-types';

import config from 'configs/routes';

import authActions from 'redux/actions/authActions';

class SignIn extends Component {
  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) { // eslint-disable-line
    const { hasToken } = this.props;
    if (hasToken !== prevProps.hasToken && hasToken) {
      return this.redirectHome();
    }
  }

  init = () => {
    const {
      hasToken,
      location: { search },
    } = this.props;

    const searchParams = new URLSearchParams(search);
    const secret = searchParams.get('secret');
    const email = searchParams.get('email');

    if (hasToken) this.redirectHome();

    if (!hasToken && secret && email) {
      this.authorize(secret, email);
    } else {
      const token = sessionStorage.getItem('token');
      if (!token) {
        this.redirectPCD();
      }
    }
  };

  redirectPCD = () => this.props.history.push(config.urls.pcdesigner); // eslint-disable-line
  redirectHome = () => this.props.history.push(config.urls.dashboard); // eslint-disable-line

  authorize = (secret, email) => {
    const userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    });

    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
    });

    cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

    const { signIn } = this.props;
    const callback = {
      onSuccess: (result) => {
        signIn({ cognitoUserSession: result });
      },
      onFailure: (err) => {
        this.err = err;
        console.log(`%c-----> authorize.callback.onFailure`,'color:white; background:red;',err); // eslint-disable-line
        this.redirectPCD();
      },
      customChallenge: () => {
        cognitoUser.sendCustomChallengeAnswer(
          `${process.env.REACT_APP_ADMIN_UI_ORIGIN},${secret}`,
          callback
        );
      },
    };

    cognitoUser.initiateAuth(authenticationDetails, callback);
  };

  render() {
    return null;
  }
}

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
  hasToken: PropTypes.bool.isRequired, // eslint-disable-line
  error: PropTypes.string, // eslint-disable-line
  location: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    authenticating: state.auth.signIn.authenticating,
    error: state.auth.signIn.error,
    hasToken: !!state.auth && !!state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetErrors: () => {
    dispatch(authActions.resetSignInErrors());
  },
  signIn: ({ cognitoUserSession }) => {
    dispatch(authActions.signIn(cognitoUserSession));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
