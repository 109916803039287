import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';

import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Menu from 'components/common/Menu';

import HeaderDialog from 'containers/Estimates/Estimate/components/Dialog';

import Toaster from 'components/ToasterQueue';
import {
  CLICK_TO_OPEN,
  DELETE,
  DELETE_SUCCESS,
} from 'components/common/utils/constants';

import { ESTIMATE } from 'containers/Estimates/Estimate/constants';
import { getActions, formattingText } from './utils';

import './style.scss';

function Header({
  isEdit,
  projectName,
  limitLength,
  notes,
  id,
  onEvent,
  onDocumentsUpdate,
}) {
  const [showPopup, setShowPopup] = React.useState(null);

  const onHandleAction = useCallback(
    async (value) => {
      try {
        const result = await showPopup.action(value);

        if (showPopup.type === DELETE) {
          onEvent({ action: DELETE_SUCCESS, tag: ESTIMATE });
          return true;
        }

        if (result?.data?.url) {
          onDocumentsUpdate(`${value}${showPopup.fileFormat}`);
          Toaster.showLink(CLICK_TO_OPEN, result.data.url);
          return true;
        }
      } catch (err) {
        Toaster.error(
          err?.message?.message ? err.message.message : err?.message
        );
        return false;
      }
    },
    [showPopup]
  );

  return (
    <Box className="estimate-header" sx={{ flexGrow: 1 }}>
      <header>
        <Row className="estimate-title">
          <Col md={3} lg={3} xl={3} className="block">
            {isEdit && (
              <Menu
                items={getActions(projectName, notes, id)}
                onSelectItem={(item) => {
                  setShowPopup(item);
                }}
              />
            )}
            <Typography
              variant="h7"
              component="span"
              sx={{ flexGrow: 1 }}
              className="estimate-title"
              style={{ marginLeft: isEdit ? 0 : '30px' }}
            >
              {`Cost Estimator - ${isEdit ? 'Edit' : 'New Project'}`}
            </Typography>
          </Col>
          <Col md={6} lg={6} xl={6}>
            {notes && (
              <Typography
                variant="h7"
                component="span"
                sx={{ flexGrow: 1 }}
                className="note estimate-title-center"
              >
                <div className="note">{formattingText(notes, limitLength)}</div>
              </Typography>
            )}
          </Col>
          <Col md={3} lg={3} xl={3} className="estimate-title-end">
            <Typography variant="h7" component="span" sx={{ flexGrow: 1 }}>
              {isEdit ? projectName : 'Blank Estimate'}
            </Typography>
          </Col>
        </Row>
      </header>
      {showPopup && (
        <HeaderDialog
          {...showPopup}
          isOpen={!!showPopup}
          title={showPopup.modalTitle}
          handleAction={onHandleAction}
          handleClose={() => setShowPopup(false)}
        />
      )}
    </Box>
  );
}

Header.propTypes = {
  projectName: PropTypes.string,
  notes: PropTypes.string,
  limitLength: PropTypes.number,
  isEdit: PropTypes.func,
  onEvent: PropTypes.func,
  onDocumentsUpdate: PropTypes.func,
};

Header.defaultProps = {
  projectName: null,
  notes: null,
  limitLength: 200,
  isEdit: () => {},
  onEvent: () => {},
  onDocumentsUpdate: () => {},
};

export default connect()(Header);
