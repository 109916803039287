import { UPDATE_IOT } from '../actions/IoTActions';

const initialState = {
  dashboard: {
    smm: {
      availableInDropdown: 0,
      notAvailableInDropdown: 0,
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_IOT: {
      return { ...state, ...action.data };
    }

    default:
      return state;
  }
}
