import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';

import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import SelectInput from 'components/common/SelectInput';

import './assets/style.scss';

function Header({ title, source, sources, disabled, onEvent }) {
  const onSelectField = useCallback(
    (name, value) => {
      onEvent({
        action: 'change',
        data: {
          name,
          value,
        },
      });
    },
    [onEvent]
  );

  return (
    <Box className="custom-header" sx={{ flexGrow: 1 }}>
      <header>
        <Row className="custom-header-title">
          <Col md={3} lg={3} xl={3} className="block">
            <Typography
              variant="h7"
              component="span"
              sx={{ flexGrow: 1 }}
              className="custom-header-title"
            >
              {title}
            </Typography>
          </Col>
          <Col md={6} lg={6} xl={6} />
          <Col md={3} lg={3} xl={3} className="custom-header-title-end">
            <div style={{ width: '100%' }}>
              <SelectInput
                label="Source"
                labelPosition="left"
                name="source"
                disabled={disabled}
                options={sources}
                onChange={onSelectField}
                value={source}
              />
            </div>
          </Col>
        </Row>
      </header>
    </Box>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  source: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  onEvent: PropTypes.func,
};

Header.defaultProps = {
  title: null,
  source: null,
  sources: [],
  disabled: false,
  onEvent: () => {},
};

export default connect()(Header);
