export const inputStyles = {
  small: {
    height: '32px',
    borderRadius: '2px',
    boxShadow: 'none',
    zIndex: 0,
  },
  medium: {
    height: '34px',
    borderRadius: '2px',
    boxShadow: 'none',
    zIndex: 0,
  },
  big: {
    height: '40px',
    borderRadius: '2px',
    boxShadow: 'none',
    fontSize: '16px',
    zIndex: 0,
  },
};

export const btnStyles = {
  small: {
    height: '40px',
    borderRadius: '2px',
  },
  medium: {
    height: '44px',
    borderRadius: '2px',
    boxShadow: 'none',
  },
  big: {
    height: '50px',
    borderRadius: '2px',
    boxShadow: 'none',
  },
};

export const labelStyles = {
  small: {
    lineHeight: 1.5,
    fontWeight: 'normal',
  },
  medium: {
    lineHeight: 1.5,
    fontWeight: 'normal',
  },
  big: {
    lineHeight: 1.5,
    fontWeight: 'normal',
    fontSize: '16px',
  },
};
