import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContent from '@material-ui/core/DialogContent';

import Button from 'components/common/Button';

import { Col, Row } from 'react-bootstrap';

import Fetch from 'components/common/Fetch';
import SelectInput from 'components/common/SelectInput';
import TextInput from 'components/common/ReactHookForm/TextInput';

import {
  ERROR_CUSTOM_MODULE_DEFAULT_PROPS,
  ERROR_CUSTOM_MODULE_RACKING_PRODUCT,
  messages,
} from 'containers/Estimates/Estimate/constants';

import { api } from 'utils/fetch';
import Toaster from 'components/ToasterQueue';

import { isNumeric, isObject, isString } from 'utils';

import config from './config';

import {
  validate,
  toMountingSystemLookups,
  createFields,
  getModuleForCheck,
  createErrors,
  validateField,
} from './utils';

import './assets/style.scss';

function ModalDialog({
  onEvent,
  opened,
  values,
  disabled,
  information,
  defaultProps,
}) {
  const [mountingSystems, setMountingSystems] = useState([]);
  const [disabledFields, setDisabledFields] = useState(disabled);
  const [disabledButtons, setDisabledButtons] = useState(disabled);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({});

  const [errors, setErrors] = useState(createErrors());

  useEffect(() => {
    setDisabledFields(disabled);
    setDisabledButtons(disabled);
  }, [disabled]);

  useEffect(() => {
    if (opened) {
      setFields(createFields(values));
    } else {
      setFields({});
    }
  }, [values, opened]);

  const onHandleAdd = async () => {
    const { errors, hasErrors } = validate(fields);

    setErrors(errors);

    if (hasErrors) {
      return;
    }

    try {
      setLoading(true);
      setDisabledFields(true);
      setDisabledButtons(true);

      if (!isObject(defaultProps) || !Object.keys(defaultProps).length) {
        throw new Error(messages[ERROR_CUSTOM_MODULE_DEFAULT_PROPS]);
      }

      const data = getModuleForCheck(fields, mountingSystems, defaultProps);

      const result = await api.postCheckCustomSolarModule({ data });

      if (result?.data.length > 0) {
        onEvent({
          action: 'add',
          values: {
            ...fields,
            W: defaultProps.moduleDimensions?.w,
            PT: defaultProps.moduleDimensions?.pt,
            APT: defaultProps.moduleDimensions?.apt,
            dataSheetUrl: defaultProps.dataSheetUrl,
            rowSpacing: data.rowSpacing,
          },
        });
      } else {
        Toaster.error(messages[ERROR_CUSTOM_MODULE_RACKING_PRODUCT]);
      }
    } catch (error) {
      Toaster.error(error.message);
    } finally {
      setLoading(false);
      setDisabledFields(false);
      setDisabledButtons(false);
    }
  };

  const onHandleClose = () => {
    onEvent({ action: 'close' });
  };

  const checkField = (name, value) => {
    const valid = validateField(name, value);

    setErrors((previousState) => {
      return { ...previousState, [name]: valid };
    });
  };

  const updateMountingSystem = (name, value) => {
    checkField(name, value);

    setFields((previousState) => {
      return { ...previousState, [name]: value };
    });
  };

  const onChange = ({ target: { name, value } }) => {
    let formattedValue = value;

    if (config.fields[name]) {
      switch (config.fields[name].type) {
        case 'int':
        case 'number': {
          if (isString(value) && !value) break;

          if (!isNumeric(value)) return;

          if (config.fields[name].type === 'int') {
            formattedValue = parseInt(value, 10);
          } else if (`${value}`.split('.')[1]?.length > 1) {
            return;
          }
          break;
        }
        default: {
          break;
        }
      }
    }

    checkField(name, formattedValue);

    setFields((previousState) => {
      return { ...previousState, [name]: formattedValue };
    });
  };

  return (
    <Dialog
      open={opened}
      onClose={onHandleClose}
      className="new-module"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <div className="dialog-title title-bottom-border">
        <DialogTitle id="alert-dialog-title">Module Properties</DialogTitle>
        <div className="buttons" style={{ margin: '15px 15px 0 0' }}>
          <Button
            className="btn btn-outline-secondary btn-lg"
            onClick={onHandleClose}
            disabled={disabledButtons}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary btn-lg"
            onClick={onHandleAdd}
            disabled={disabledButtons}
            loading={loading}
          >
            Add
          </Button>
        </div>
      </div>

      <div className="info" style={{ marginTop: '15px' }}>
        {information}
      </div>

      <DialogContent>
        <div className="content">
          <Row>
            <Col md={12} lg={12} xl={7}>
              <div style={{ width: '100%' }}>
                <Fetch
                  url="wes/data/racking"
                  render={({ data, loading }) => {
                    const options = toMountingSystemLookups(data);

                    setMountingSystems(data);

                    return (
                      <SelectInput
                        label="Mounting System *"
                        labelPosition="left"
                        name="mountingSystem"
                        isLoading={loading}
                        disabled={disabledFields}
                        options={options}
                        onChange={updateMountingSystem}
                        value={fields.mountingSystem}
                        error={errors.mountingSystem}
                      />
                    );
                  }}
                />
                <TextInput
                  label="Manufacturer *"
                  labelPosition="left"
                  labelWidth="34%"
                  name="manufacturer"
                  size="small"
                  disabled={disabledFields}
                  onChange={onChange}
                  onWheel={(event) => event.currentTarget.blur()}
                  value={fields.manufacturer}
                  error={errors.manufacturer}
                  required
                />
                <TextInput
                  label="Model Name *"
                  labelPosition="left"
                  labelWidth="34%"
                  name="modelName"
                  size="small"
                  disabled={disabledFields}
                  onChange={onChange}
                  onWheel={(event) => event.currentTarget.blur()}
                  value={fields.modelName}
                  error={errors.modelName}
                  required
                />
              </div>
            </Col>
            <Col md={12} lg={12} xl={5}>
              <TextInput
                label="Panel Wattage (W) *"
                labelPosition="left"
                labelWidth="50%"
                name="power"
                size="small"
                disabled={disabledFields}
                onChange={onChange}
                onWheel={(event) => event.currentTarget.blur()}
                value={fields.power}
                type="text"
                error={errors.power}
                required
              />
              <TextInput
                label="PL (mm) *"
                labelPosition="left"
                labelWidth="50%"
                name="PL"
                size="small"
                disabled={disabledFields}
                onChange={onChange}
                onWheel={(event) => event.currentTarget.blur()}
                value={fields.PL}
                type="text"
                error={errors.PL}
                required
              />
              <TextInput
                label="PW (mm) *"
                labelPosition="left"
                labelWidth="50%"
                name="PW"
                size="small"
                disabled={disabledFields}
                onChange={onChange}
                onWheel={(event) => event.currentTarget.blur()}
                value={fields.PW}
                type="text"
                error={errors.PW}
                required
              />
            </Col>
          </Row>
        </div>
      </DialogContent>
    </Dialog>
  );
}

ModalDialog.propTypes = {
  opened: PropTypes.bool,
  onEvent: PropTypes.string,
  values: PropTypes.shape({
    mountingSystem: PropTypes.string,
    manufacturer: PropTypes.string,
    modelName: PropTypes.string,
    power: PropTypes.string,
    PL: PropTypes.string,
    PW: PropTypes.string,
  }),
  touched: PropTypes.shape({
    mountingSystem: PropTypes.bool,
    manufacturer: PropTypes.bool,
    modelName: PropTypes.bool,
    power: PropTypes.bool,
    PL: PropTypes.bool,
    PW: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  information: PropTypes.string,
  defaultProps: PropTypes.shape({
    dataSheetUrl: PropTypes.string,
    moduleDimensions: PropTypes.shape({
      pl: PropTypes.number,
      pt: PropTypes.number,
      pw: PropTypes.number,
      apt: PropTypes.number,
      w: PropTypes.number,
    }),
  }),
};

ModalDialog.defaultProps = {
  opened: false,
  onEvent: () => {},
  values: {
    mountingSystem: null,
    manufacturer: null,
    modelName: null,
    power: null,
    PL: null,
    PW: null,
  },
  touched: PropTypes.shape({
    mountingSystem: false,
    manufacturer: false,
    modelName: null,
    power: null,
    PL: null,
    PW: null,
  }),
  disabled: false,
  defaultProps: {},
  information:
    'If you add a Custom module, you can only use a single-module in your estimate',
};

export default ModalDialog;
