import React from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { routes, urls, nonAuthorizedUrls } from 'routes';
import { getAcl } from 'redux/selectors/selectors';
import dashboardActions from 'redux/actions/dashboardActions';
import cache from 'utils/Cache';
import AuthRoute from 'containers/App/AuthRoute';
import {
  FAILED,
  SUCCEEDED,
  ALLOW_API_INSPECTOR,
  ALLOW_MANAGE_ACLS,
  ALLOW_MANAGE_ROLES,
  ALLOW_MANAGE_TENANTS,
  ALLOW_MANAGE_USERS,
  DW_DESIGNER_WORKFLOW,
  DW_DESIGNER_VIEW,
  DW_DESIGNER_MANAGER,
  DW_ADMIN,
  DW_SUBMISSIONS,
  DW_SUBMISSIONS_MANAGER,
} from 'redux/constants/category';

import ACL from 'redux/Acl';
import Layout from '../Layout';
import MainWrapper from './MainWrapper';

import authActions from '../../redux/actions/authActions';
import loaderActions from '../../redux/actions/loaderActions';
import IoTActions from '../../redux/actions/IoTActions';
import tokenAction from '../../redux/actions/cognitotoken';

const wrappedRoutes = (onlyAuthorized) => {
  const routesList = onlyAuthorized
    ? routes
    : routes.filter((el) => nonAuthorizedUrls.includes(el.key));

  return (
    <>
      {routesList.map(({ key, ...props }) => (
        <AuthRoute key={key} {...props} />
      ))}
    </>
  );
};

const mapPathsToACLs = {
  [urls.apiInspector]: `${ALLOW_API_INSPECTOR}.show`,
  [urls.users]: `${ALLOW_MANAGE_USERS}.show`,
  [urls.tenants]: `${ALLOW_MANAGE_TENANTS}.show`,
  [urls.roles]: `${ALLOW_MANAGE_ROLES}.show`,
  [urls.acls]: `${ALLOW_MANAGE_ACLS}.show`,
  [urls.dwRequests]: [
    [`${DW_DESIGNER_WORKFLOW}.show`, `${DW_DESIGNER_WORKFLOW}.read`],
    [
      `${DW_DESIGNER_WORKFLOW}.show`,
      `${DW_DESIGNER_WORKFLOW}.read`,
      `${DW_DESIGNER_VIEW}.show`,
      `${DW_DESIGNER_VIEW}.read`,
      `${DW_DESIGNER_MANAGER}.show`,
      `${DW_DESIGNER_MANAGER}.read`,
      `${DW_ADMIN}.show`,
      `${DW_ADMIN}.read`,
    ],
  ],
  [urls.dwSubmissions]: [
    [`${DW_DESIGNER_WORKFLOW}.show`, `${DW_DESIGNER_WORKFLOW}.read`],
    [
      `${DW_DESIGNER_WORKFLOW}.show`,
      `${DW_DESIGNER_WORKFLOW}.read`,
      `${DW_SUBMISSIONS}.show`,
      `${DW_SUBMISSIONS}.read`,
      `${DW_SUBMISSIONS_MANAGER}.show`,
      `${DW_SUBMISSIONS_MANAGER}.read`,
      `${DW_DESIGNER_VIEW}.show`,
      `${DW_DESIGNER_VIEW}.read`,
      `${DW_DESIGNER_MANAGER}.show`,
      `${DW_DESIGNER_MANAGER}.read`,
      `${DW_ADMIN}.show`,
      `${DW_ADMIN}.read`,
    ],
  ],
  [urls.dwAnalytics]: [
    [`${DW_DESIGNER_WORKFLOW}.show`, `${DW_DESIGNER_WORKFLOW}.read`],
    [
      `${DW_DESIGNER_MANAGER}.show`,
      `${DW_DESIGNER_MANAGER}.read`,
      `${DW_ADMIN}.show`,
      `${DW_ADMIN}.read`,
    ],
  ],
  [urls.dwManagement]: [
    [`${DW_DESIGNER_WORKFLOW}.show`, `${DW_DESIGNER_WORKFLOW}.read`],
    [`${DW_ADMIN}.show`, `${DW_ADMIN}.read`],
  ],
};

class Router extends React.Component {
  componentDidMount() {
    const {
      setLoading,
      hasToken = false,
      error,
      location: { pathname },
      location: { search },
      subscribeIoT,
      watchToken,
      getTotalCount,
      initStatus,
    } = this.props;

    const searchParams = new URLSearchParams(search);
    const secret = searchParams.get('secret');
    const email = searchParams.get('email');

    error && console.warn(error); // eslint-disable-line

    if (!hasToken && secret && email && pathname !== urls.signin) {
      this.redirectSignin();
    }

    if (!hasToken && (!secret || !email) && initStatus === FAILED) {
      this.redirectPCD();
    }

    if (hasToken) {
      this.checkUserACLAccess();
      setLoading(false);
      this.addOnFocusListener();
      watchToken();
      subscribeIoT();
      getTotalCount();
    }
  }

  componentDidUpdate(prevProps) {
    const { hasToken, token, setLoading, getTotalCount } = this.props;

    if (prevProps.hasToken) {
      if (!hasToken) {
        // TODO understand under which scenario this code triggers
        this.removeOnFocusListener();
        this.redirectPCD();
      } else if (prevProps.token !== token) {
        cache.setSecret(token);
      }
    } else if (hasToken) {
      this.checkUserACLAccess();
      cache.setSecret(token);
      setLoading(false);
      getTotalCount();
      this.addOnFocusListener();
    }
  }

  componentWillUnmount() {
    this.removeOnFocusListener();
  }

  checkUserACLAccess = () => {
    const {
      location: { pathname },
      acl,
      initStatus,
    } = this.props;
    if (
      mapPathsToACLs[pathname] &&
      acl.isInited() &&
      !acl.isAccessAllow(mapPathsToACLs[pathname]) &&
      initStatus === SUCCEEDED
    ) {
      this.redirectHome();
    }
  };

  focusHandler = () => {
    const { checkToken } = this.props;
    setTimeout(() => {
      if (document.hidden) return;

      if (document.visibilityState === 'visible') {
        checkToken();
      }
    }, 4);
  };

  redirectHome = () => this.props.history.push(urls.dashboard); // eslint-disable-line
  redirectSignin = () => this.props.history.push(urls.signin); // eslint-disable-line
  redirectPCD = () => this.props.history.push(urls.pcdesigner); // eslint-disable-line

  removeOnFocusListener() {
    document.removeEventListener('visibilitychange', this.focusHandler);
  }

  addOnFocusListener() {
    this.removeOnFocusListener();
    document.addEventListener('visibilitychange', this.focusHandler);
  }

  render() {
    const { hasToken } = this.props;

    return (
      <MainWrapper>
        <main>
          <div>
            <Layout />
            <div className="container__wrap">
              <Switch>{wrappedRoutes(hasToken)}</Switch>
            </div>
          </div>
        </main>
      </MainWrapper>
    );
  }
}

Router.propTypes = {
  hasToken: PropTypes.bool, // eslint-disable-line
  token: PropTypes.string, // eslint-disable-line
  error: PropTypes.string, // eslint-disable-line
  location: PropTypes.object, // eslint-disable-line
  setLoading: PropTypes.func.isRequired,
  subscribeIoT: PropTypes.func,
  watchToken: PropTypes.func,
  checkToken: PropTypes.func,
  initStatus: PropTypes.string,
  acl: PropTypes.object, // eslint-disable-line
};

Router.defaultProps = {
  subscribeIoT: () => {},
  watchToken: () => {},
  checkToken: () => {},
  acl: new ACL(),
  initStatus: '',
};

const mapStateToProps = (state) => {
  const acl = getAcl(state);

  return {
    authenticating: state.auth.signIn.authenticating,
    error: state.auth.signIn.error,
    hasToken: !!state.auth && !!state.auth.token && acl.isInited(),
    token: state.auth?.token,
    initStatus: state.auth?.initStatus,
    acl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetErrors: () => {
    dispatch(authActions.resetSignInErrors());
  },
  signIn: ({ cognitoUserSession }) => {
    dispatch(authActions.signIn(cognitoUserSession));
  },
  setLoading: (loading) => {
    dispatch(loaderActions.setLoading(loading));
  },
  subscribeIoT: () => {
    dispatch(IoTActions.init());
  },
  getTotalCount: () => {
    dispatch(dashboardActions.getTotalCount());
  },
  watchToken: () => {
    dispatch(tokenAction.watchToken());
  },
  checkToken: () => {
    dispatch(tokenAction.checkToken());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Router));
