/* eslint-disable no-await-in-loop */
import { api } from 'utils/fetch';

export const S_SHADOW_STATUS_NA = 'Incomplete';
export const S_SHADOW_STATUS_IN_PROGRESS = 'Pending';
export const S_SHADOW_STATUS_PENDING = 'Pending';
export const S_SHADOW_STATUS_REQUESTED = 'Requested';
export const S_SHADOW_STATUS_FAILURE = 'Failed';
export const S_SHADOW_STATUS_SUCCESS = 'Successful';
export const S_SHADOW_STATUS_LOADING_FOR_UI = 'LoadingForUi';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

async function requestSMPipelineStatus({
  workflow,
  projectId,
  params,
  initial,
  listener = () => {},
}) {
  listener({
    workflow,
    projectId,
    shadowResults: {
      status: S_SHADOW_STATUS_PENDING,
      percentage: 2,
    },
  });

  try {
    let shadowResults = {};

    // eslint-disable-next-line no-constant-condition
    while (true) {
      let response = await api.getWorkflowStatus(projectId, workflow);
      shadowResults = response.data;

      listener({ workflow, projectId, shadowResults });

      if (shadowResults?.needSync) {
        await api.syncWorkflow({
          data: {
            workflow,
            projectId,
          },
        });

        response = await api.getWorkflowStatus(projectId, workflow);
        shadowResults = response.data;

        listener({ workflow, projectId, shadowResults });
      }

      if (
        shadowResults &&
        (shadowResults.status === S_SHADOW_STATUS_FAILURE ||
          shadowResults.status === S_SHADOW_STATUS_SUCCESS ||
          shadowResults.status === S_SHADOW_STATUS_NA)
      ) {
        if (!initial) {
          listener({
            workflow,
            projectId,
            shadowResults: {
              status: shadowResults.status,
            },
          });

          listener({ workflow, projectId, shadowResults });
        }

        break;
      }

      let w = 10;
      while (w > 0) {
        w -= 1;
        await delay(1000);
      }
    }
  } catch (error) {
    listener({
      workflow,
      projectId,
      shadowResults: {
        status: S_SHADOW_STATUS_NA,
      },
    });
  }
}

export async function executeSMPipeline({
  workflow,
  projectId,
  params,
  listener = () => {},
}) {
  listener({
    workflow,
    projectId,
    status: S_SHADOW_STATUS_PENDING,
    percentage: 2,
  });

  const response = await api.requestWorkflowExecution({
    data: {
      workflow,
      projectId,
    },
  });

  const requestWorkflowResponse = response.data;

  listener({ workflow, projectId, shadowResults: requestWorkflowResponse });

  if (
    requestWorkflowResponse.success &&
    requestWorkflowResponse.status === S_SHADOW_STATUS_REQUESTED
  ) {
    try {
      await api.executeWorkflow({
        data: {
          workflow,
          projectId,
          pcdVersion: `${process.env.REACT_APP_VERSION}`,
          params: {
            workflow,
            projectId,
            ...params,
          },
        },
      });

      await requestSMPipelineStatus({
        projectId,
        workflow,
        params,
        initial: false,
        listener,
      });
    } catch (error) {
      listener({
        workflow,
        projectId,
        shadowResults: {
          status: S_SHADOW_STATUS_FAILURE,
          message: error ? error.toString() : '',
        },
      });
    }
  } else {
    await requestSMPipelineStatus({
      projectId,
      workflow,
      params,
      initial: false,
      listener,
    });
  }
}
