import React from 'react';
import PropTypes from 'prop-types';

import Dialog from 'components/common/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';

import Button from 'components/common/Button';

import './assets/style.scss';

function ModalDialog({ onEvent, opened, title, info, cancel, save, metadata }) {
  const onHandleClose = () => {
    onEvent({ action: cancel.actionName, metadata });
  };

  const onHandleCancel = () => {
    onEvent({ action: cancel.actionName, metadata });
  };

  const onHandleSave = async () => {
    onEvent({ action: save.actionName, metadata });
  };

  const container = (
    <DialogContentText
      style={{ minWidth: '550px' }}
      id="alert-dialog-description"
    >
      <span> {info} </span>
    </DialogContentText>
  );

  const actions = (
    <>
      <Button
        className="btn btn-secondary btn-lg"
        onClick={onHandleCancel}
        disabled={false}
      >
        {cancel.label}
      </Button>
      <Button className="btn btn-primary btn-lg" onClick={onHandleSave}>
        {save.label}
      </Button>
    </>
  );

  return (
    <div>
      <Dialog
        isOpen={opened}
        className="estimate-modal-dialog"
        container={container}
        title={title}
        actions={actions}
        handleClose={onHandleClose}
      />
    </div>
  );
}

ModalDialog.propTypes = {
  opened: PropTypes.bool,
  onEvent: PropTypes.string,
  cancel: PropTypes.shape({
    label: PropTypes.string,
    actionName: PropTypes.string,
  }),
  save: PropTypes.shape({
    label: PropTypes.string,
    actionName: PropTypes.string,
  }),
  title: PropTypes.string,
  info: PropTypes.string,
  metadata: PropTypes.object,
};

ModalDialog.defaultProps = {
  opened: false,
  onEvent: () => {},
  cancel: {
    label: 'Cancel',
    actionName: 'cancel',
  },
  save: {
    label: 'Save',
    actionName: 'save',
  },
  title: '',
  info: '',
  metadata: null,
};

export default ModalDialog;
