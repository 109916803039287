import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LockIcon from '@material-ui/icons/Lock';

import FormikWrapper from 'components/common/FormikWrapper';
import Toaster from 'components/ToasterQueue';

import authActions from 'redux/actions/authActions';
import { flagSelector } from 'redux/selectors/selectors';
import { errorSelector } from 'redux/errors/selectors';

import Validator from 'utils/validator';
import Modal from 'components/common/Modal';
import PasswordForm from './PasswordForm';

import { passwordDefaults } from './_etc/defaultValues';
import { passwordSettingSchema } from './_etc/validationSchema';

class PasswordDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updateEvtTriggered: null,
      error: null,
      success: null,
    };
  }

  changePassword = (values, formikProps) => {
    const { updatePassword } = this.props;
    this.setState({
      updateEvtTriggered: false,
      error: null,
      success: null,
    });

    updatePassword(values.exPassword, values.password);
    this.setState({ updateEvtTriggered: true });
    this.runPasswordUpdateWatcher(formikProps);
  };

  runPasswordUpdateWatcher = ({ setSubmitting, resetForm }) => {
    const watcher = setInterval(() => {
      const { isPasswordUpdating, error } = this.props;
      const { updateEvtTriggered } = this.state;

      this.setState({ error: null });
      const done =
        !isPasswordUpdating &&
        isPasswordUpdating !== null &&
        updateEvtTriggered;
      if (done) {
        clearInterval(watcher);
        if (error) {
          this.setState({ error });
          Toaster.error('Password Change failed!');
        } else {
          this.setState({ success: true });
          Toaster.success('Password Change Successfull!');
        }
        resetForm();
        setSubmitting(false);
        this.setState({ updateEvtTriggered: null });
      }
    }, 500);
  };

  closeModal = () => {
    const { onClose } = this.props;
    this.setState({ error: null, success: null, updateEvtTriggered: null });
    onClose();
  };

  render() {
    const { isOpen, password, isPasswordUpdating } = this.props;

    return (
      <Modal isOpen={isOpen} theme="pc-feedback-modal">
        <div style={{ height: '500px' }}>
          <div className="modal-header text-center">
            <h3 className="modal-title">Change Password</h3>
            <div
              style={{
                float: 'right',
                paddingRight: '20px',
                marginTop: '-40px',
              }}
            >
              <button
                onClick={this.closeModal}
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div className="modal-body">
            <div>
              <div style={{ textAlign: 'center', marginTop: '-20px' }}>
                <span className="user-icon text-center">
                  <LockIcon className="lock-icon" />
                </span>
              </div>

              <div>
                <FormikWrapper
                  initialValues={password}
                  onSubmit={(values, formikProps) => {
                    this.changePassword(values, formikProps);
                  }}
                  validate={(values) =>
                    new Validator(passwordSettingSchema).run(values)
                  }
                  updating={isPasswordUpdating}
                  renderForm={(props) => <PasswordForm {...props} />}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

PasswordDialog.propTypes = {
  //  eslint-disable-next-line
  password: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string,
  updatePassword: PropTypes.func.isRequired,
  isPasswordUpdating: PropTypes.bool,
};

PasswordDialog.defaultProps = {
  password: passwordDefaults,
  error: null,
  isOpen: false,
  isPasswordUpdating: false,
};

const mapStateToProps = (state) => {
  const isPasswordUpdating = flagSelector(state, 'user.password', false);
  const error = errorSelector(state, 'user.password');

  return {
    password: {
      ...passwordDefaults,
    },
    isPasswordUpdating,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (oldPassword, newPassword, token) =>
    dispatch(authActions.updatePassword(oldPassword, newPassword, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordDialog);
