import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';
import React from 'react';
import {
  FormButtonToolbar,
  FormContainer,
} from 'components/common/Form/FormElements';
import { Form } from 'react-final-form';
import { Row } from 'reactstrap';
import Toolbar from 'components/Toolbar';
import FilterFieldComponent from './FilterFieldComponent';
import './style.scss';

const prepareState = (filters) => {
  const state = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key].options) {
      state[filters[key].options.field] = filters[key].options;
    }
    state[key] = filters[key];
  });
  return state;
};

function FilterBox({ fields, filters, onSubmit, onResetFilter, onClose }) {
  const [state, setState] = React.useState(prepareState(filters));

  return (
    <Box className="filterBox">
      <IconButton aria-label="close" onClick={onClose} className="closeIcon">
        <CloseIcon />
      </IconButton>
      <Row style={{ paddingBottom: '20px' }}>
        <h2>Filters</h2>
      </Row>
      <Row>
        <Form onSubmit={onSubmit} className="filter-form">
          {({ handleSubmit, form }) => {
            return (
              <FormContainer
                horizontal
                as="div"
                className="detail-view-form"
                onSubmit={handleSubmit}
              >
                {Object.keys(fields).map((fieldName) => {
                  const field = fields[fieldName];
                  const fieldValue = state[fieldName];
                  const additionalFieldValue =
                    state[field.options?.additional?.field];
                  return (
                    <FilterFieldComponent
                      key={fieldName}
                      fieldName={fieldName}
                      field={field}
                      fieldValue={fieldValue}
                      additionalFieldValue={additionalFieldValue}
                      onChange={(name, value) => {
                        setState({
                          ...state,
                          [name]: value,
                        });
                      }}
                    />
                  );
                })}
                <FormButtonToolbar className="filter-box-footer">
                  <Toolbar
                    className="filter-box-footer__toolbar"
                    disabledCancel={false}
                    cancelLabel="Reset Filter"
                    onCancel={() => {
                      form.reset();
                      onResetFilter();
                    }}
                    disabledSave={false}
                    saveLabel="Filter"
                    onSave={() => onSubmit(state)}
                  />
                </FormButtonToolbar>
              </FormContainer>
            );
          }}
        </Form>
      </Row>
    </Box>
  );
}

FilterBox.propTypes = {
  fields: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FilterBox;
