import React from 'react';
import PropTypes from 'prop-types';

import MUToolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Search from './Search';
import ExtraMenu from './ExtraMenu';
import SelectBar from './SelectBar';

const useStyles = makeStyles(() => ({
  typo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rightBlock: {
    display: 'inherit',
    alignItems: 'center',
  },
}));

const getSearchBar = (tableName, additionalSearchButton) => (
  <div className="searchTool">
    <Search tableName={tableName} />
    {additionalSearchButton && additionalSearchButton()}
  </div>
);

function Toolbar({
  title,
  extraMenuData,
  importMenu,
  middleToolbar,
  tableName,
  selectToolBar,
  showSearchBar,
  searchOnLeftSide,
  additionalSearchButton,
}) {
  const classes = useStyles();
  return (
    <MUToolbar className="toolbar">
      <Typography variant="h6" className={classes.typo}>
        {title}
      </Typography>
      {showSearchBar &&
        searchOnLeftSide &&
        getSearchBar(tableName, additionalSearchButton)}
      {middleToolbar ? middleToolbar() : <div className="spacer" />}
      <div className={classes.rightBlock}>
        {selectToolBar && (
          <SelectBar
            propName={selectToolBar.propName}
            propLabel={selectToolBar.propLabel}
            list={selectToolBar.list}
            selectedSFTableName={selectToolBar.selectedSFTableName}
            onChange={selectToolBar.onChange}
          />
        )}
        {showSearchBar &&
          !searchOnLeftSide &&
          getSearchBar(tableName, additionalSearchButton)}
        {extraMenuData.map(
          ({
            name,
            icon,
            iconClass,
            options,
            optionsHandleSelect,
            onClick,
            styles,
          }) => (
            <ExtraMenu
              key={name}
              options={options}
              optionsHandleSelect={optionsHandleSelect}
              styles={styles}
              iconComponent={icon}
              iconClass={iconClass}
              onClick={onClick}
            />
          )
        )}
        {importMenu}
      </div>
    </MUToolbar>
  );
}

export default Toolbar;

Toolbar.propTypes = {
  title: PropTypes.string,
  extraMenuData: PropTypes.array, // eslint-disable-line
  middleToolbar: PropTypes.func,
  importMenu: PropTypes.node,
  tableName: PropTypes.string,
  selectToolBar: PropTypes.object, // eslint-disable-line
  showSearchBar: PropTypes.bool, // eslint-disable-line
};

Toolbar.defaultProps = {
  title: 'All data',
  extraMenuData: [],
  middleToolbar: null,
  importMenu: null,
  tableName: '',
  selectToolBar: null,
  showSearchBar: true,
};
