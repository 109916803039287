import { Card as BootstrapCard } from 'react-bootstrap';
import styled from 'styled-components';
import { colorBackground } from 'components/common/utils/palette';
import { borderRadius, shadow } from 'components/common/utils/styles';
import { left } from 'components/common/utils/directions';

export const Card = styled(BootstrapCard)`
  width: 100%;
  padding-bottom: 30px;
  height: ${props => (props.height || '100%')};
  border: none;
  background-color: transparent;
`;

export const CardBody = styled(Card.Body)`
  height: 100%;
  background-color: ${colorBackground};
  border-radius: ${borderRadius};
  box-shadow: ${shadow};
  padding: 20px;
`;

export const CardTitleWrap = styled.div`
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;
  text-align: ${left};
  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 40px;
  }

  * {
    margin-bottom: 0;
  }
`;

export const CardTitle = styled.h6`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding-left: 4px;
  margin-top: -6.5px;
  text-transform: none;
  text-align: ${left};
  ${props => props.centered && `
    text-align: center;
  `}
`;

export const CardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
`;
