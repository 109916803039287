/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import './style.scss';

const WarningDialogue = ({ handleClose, onReclaim, open = false }) => {
  if (!open) return null;

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      maxWidth="md"
      className="sm-edit"
      style={{ zIndex: 9999 }}
    >
      <DialogTitle>Reclaim Window</DialogTitle>
      <DialogContent>
        <p>
          This will reassign all of the old user's projects to the selected
          owner. The new user will then be the owner of those projects.{' '}
          <b>This is permanent.</b> Are you sure you want to proceed?
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" autoFocus>
          Cancel
        </Button>
        <Button onClick={onReclaim} color="primary" autoFocus>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialogue;
