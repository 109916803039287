import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import TableBody from '@material-ui/core/TableBody';

import { Card, CardTitleWrap, CardTitle } from './Card';
import {
  Table,
  TableCheckbox,
  TableRow,
  TableWrap,
  TablePagination,
  TableCell,
} from './MaterialTableElements';

import MatTableHead from './MatTableHead';

const createData = (id, name) => {
  return {
    id,
    name,
  };
};

const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};

function MatTable({
  showTitle = true,
  showPagination = true,
  values = [],
  limitRows = 0,
  headers,
  disabled,
  disabledToolbar,
  onChange = () => {},
  onEventToolbar = () => {},
  toolbar,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(limitRows);
  const [data, setData] = useState([]);

  useEffect(() => {
    const formattedItems = [];

    let i = -1;
    const n = values.length;

    while (++i < n) {
      formattedItems.push(createData(values[i].id, values[i].name));
    }

    setData(formattedItems);

    return () => {};
  }, [values]);

  const handleRequestSort = (event, property) => {
    const orderByTemp = property;

    let orderTemp = 'desc';

    if (orderBy === property && order === 'desc') {
      orderTemp = 'asc';
    }

    setOrder(orderTemp);
    setOrderBy(orderByTemp);
  };

  const handleSelectAllClick = (event, checked) => {
    if (disabled) return;

    if (checked) {
      const newSelected = new Map();

      data.map((n) => newSelected.set(n.id, true));

      setSelected(newSelected);

      return;
    }

    setSelected(new Map([]));
  };

  const handleClick = (event, id) => {
    if (disabled) return;

    const newSelected = new Map(selected);
    const value = newSelected.get(id);

    let isActive = true;

    if (value) {
      isActive = false;
    }

    newSelected.set(id, isActive);

    setSelected(newSelected);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleDeleteSelected = () => {
    if (disabled) return;

    const filteredData = [];
    const newModuleList = [];

    let i = -1;
    const n = data.length;

    while (++i < n) {
      if (!selected.get(data[i].id)) {
        filteredData.push(data[i]);
        newModuleList.push(values[i]);
      }
    }

    setData(filteredData);
    setSelected(new Map([]));

    onChange({ modules: newModuleList });
  };

  const isSelected = (id) => !!selected.get(id);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <Col md={12} lg={12}>
      <Card>
        <div>
          {showTitle && (
            <CardTitleWrap>
              <CardTitle>Material table</CardTitle>
            </CardTitleWrap>
          )}
          <TableWrap>
            <Table>
              <MatTableHead
                numSelected={[...selected].filter((el) => el[1]).length}
                order={order}
                orderBy={orderBy}
                selected={selected}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                handleDeleteSelected={handleDeleteSelected}
                rowCount={data.length}
                rows={headers}
                toolbar={toolbar}
                onEventToolbar={onEventToolbar}
                disabled={disabled}
                disabledToolbar={disabledToolbar}
              />
              <TableBody>
                {data
                  .sort(getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((d) => {
                    const select = isSelected(d.id);
                    return (
                      <TableRow
                        role="checkbox"
                        onClick={(event) => handleClick(event, d.id)}
                        aria-checked={select}
                        tabIndex={-1}
                        key={d.id}
                        selected={select}
                        disabled
                      >
                        <TableCell padding="checkbox">
                          <TableCheckbox checked={select} disabled={disabled} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {d.name}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableWrap>
          {showPagination && (
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 15]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />
          )}
        </div>
      </Card>
    </Col>
  );
}

export default MatTable;
