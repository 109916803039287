import { api } from 'utils/fetch';

const SubmissionStatus = {
  data: [
    { value: 'Approved', name: 'Approved' },
    { value: 'Denied', name: 'Denied' },
    { value: 'Active', name: 'Active' },
  ],
};

export class DWLookupCache {
  constructor() {
    this.RequestType = null;
    this.RequestStatus = null;
    this.DesignStatus = null;
    this.Salesperson = null;
    this.Priority = null;
    this.AssignedTo = null;
    this.ReviewedBy = null;
    this.SubmissionStatus = SubmissionStatus;
    this.Rac = null;
    this.loaded = false;
  }

  async getAllDwLookup() {
    if (this.loaded) {
      return;
    }
    if (!this.waitForResponse) {
      this.waitForResponse = api.getDWLookup();
    }

    const allLookup = await this.waitForResponse;

    if (!allLookup) {
      return;
    }
    this.setLookupData(allLookup.data);

    this.waitForResponse = api.getDWLookupAdditional();
    const additionalLookup = await this.waitForResponse;
    if (!additionalLookup) {
      return;
    }

    if (this.Rac) {
      this.Rac = null; // temporary
    }

    if (this.Salesperson) {
      this.Salesperson = null; // temporary
    }

    this.setLookupData(additionalLookup.data);

    this.loaded = true;
  }

  setLookupData(data) {
    Object.keys(data).forEach((key) => {
      const record = data[key];
      if (!this[record.Type]) {
        this[record.Type] = { data: [] };
      }

      if (record.Value) {
        this[record.Type].data.push({
          id: record.Id,
          name: record.Description,
          value: record.Value,
        });
      }
    });
  }

  async getRequestType() {
    if (this.RequestType) {
      return this.RequestType;
    }

    await this.getAllDwLookup();

    return this.RequestType;
  }

  async getSubmissionStatus() {
    return this.SubmissionStatus;
  }

  async getAccountCoordinators() {
    if (this.Rac) {
      return this.Rac;
    }

    await this.getAllDwLookup();

    return this.Rac;
  }

  async getRequestStatus() {
    if (this.RequestStatus) {
      return this.RequestStatus;
    }

    await this.getAllDwLookup();

    return this.RequestStatus;
  }

  async getSalesperson() {
    if (this.Salesperson) {
      return this.Salesperson;
    }

    await this.getAllDwLookup();

    return this.Salesperson;
  }

  async getDesignStatus() {
    if (this.DesignStatus) {
      return this.DesignStatus;
    }

    await this.getAllDwLookup();

    return this.DesignStatus;
  }

  async getReviewer() {
    if (this.ReviewedBy) {
      return this.ReviewedBy;
    }

    await this.getAllDwLookup();

    return this.ReviewedBy;
  }

  async getAssignTo() {
    if (this.AssignedTo) {
      return this.AssignedTo;
    }

    await this.getAllDwLookup();

    return this.AssignedTo;
  }

  async getPriority() {
    if (this.Priority) {
      return this.Priority;
    }

    await this.getAllDwLookup();

    return this.Priority;
  }

  async getThirdPartyType() {
    if (this.ThirdParty) {
      return this.ThirdParty;
    }

    await this.getAllDwLookup();

    return this.ThirdParty;
  }

  getACLabel(value) {
    return this.getAccountCoordinators().then((acList) => {
      const ac = acList?.find((el) => el.value === value);
      return ac?.name;
    });
  }

  reset() {
    this.RequestType = null;
    this.RequestStatus = null;
    this.DesignStatus = null;
    this.Salesperson = null;
    this.AssignedTo = null;
    this.Priority = null;
    this.ReviewedBy = null;
    this.Rac = null;
    this.SubmissionStatus = SubmissionStatus;

    this.waitForResponse = null;
    this.loaded = null;
  }
}

const LookupCache = new DWLookupCache();

export default LookupCache;
