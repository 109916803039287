import IoTSDK from 'aws-iot-device-sdk';

// IoTClient
let instance = null;

export default class IoTClient {
  // Constructor
  constructor(options = {}, createNewClient = false) {
    if (createNewClient && instance) {
      instance.disconnect();
      instance = null;
    }

    if (instance) {
      return instance;
    }
    instance = this;
    this.init(options);
    return this;
  }

  init(options) {
    const clientId = `adminUIiot_${Math.floor(Math.random() * 1000000 + 1)}`;

    this.client = IoTSDK.device({
      clientId: options.clientId || clientId,
      region: options.region,
      host: options.host,
      protocol: options.protocol || 'wss',

      // Reconnect timeout
      baseReconnectTimeMs: options.baseReconnectTimeMs || 250,
      maximumReconnectTimeMs: options.maximumReconnectTimeMs || 5000,

      // Enable console debugging information
      debug: typeof options.debug === 'undefined' ? false : options.debug,

      // AWS access key ID, secret key and session token must be
      // initialized with empty strings
      accessKeyId: options.accessKeyId || options.AccessKeyId || '',
      secretKey: options.secretKey || options.SecretAccessKey || '',
      sessionToken: options.sessionToken || options.SessionToken || '',

      // Dissable auto subscription
      autoResubscribe:
        typeof options.debug === 'undefined' ? true : options.autoResubscribe,
    });
  }

  disconnect() {
    this.client.end();
  }

  onConnect(cb) {
    this.client.on('connect', (evt) => cb(evt));
  }

  onSubscribe(cb) {
    this.client.on('message', (topic, message) => cb(message, topic));
  }

  onReconnect(cb) {
    this.client.on('reconnect', () => cb());
  }

  onOffline(cb) {
    this.client.on('offline', () => cb());
  }

  onError(cb) {
    this.client.on('error', (error) => cb(error));
  }

  onClose(cb) {
    this.client.on('close', (error) => cb(error));
  }

  updateWebSocketCredentials(accessKeyId, secretAccessKey, sessionToken) {
    this.client.updateWebSocketCredentials(
      accessKeyId,
      secretAccessKey,
      sessionToken
    );
  }

  publish(topic, message) {
    this.client.publish(topic, message);
  }

  subscribe(topic, cb) {
    this.client.subscribe(topic, { qos: 0 }, cb);
  }

  unsubscribe(topic) {
    this.client.unsubscribe(topic);
  }
}
