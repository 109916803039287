import React from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

import config from './config';

import PlaceSearchBox from './PlaceSearchBox';

function PlaceSearchComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    version: '3.exp',
    googleMapsApiKey: config.google.MAPS_API_KEY,
    libraries: ['places', 'visualization', 'drawing', 'geometry'],
  });

  return isLoaded ? <PlaceSearchBox {...props} /> : <div />;
}
export default PlaceSearchComponent;
