import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { Col } from 'react-bootstrap';
import './styles.scss';
import { Container, Row } from 'reactstrap';
import { useGlobalSpinnerActionsContext } from 'components/GlobalSpinner/GlobalSpinnerContext';
import { getObjProp, setObjProp } from 'utils';
import { validateFields } from 'components/DetailModalView/_utils';
import { api } from 'utils/fetch';
import Toaster from 'components/ToasterQueue';
import PropTypes from 'prop-types';
import {
  CREATE,
  EDIT,
  INSERT,
  ROLES,
  TENANT,
  UPDATE,
  VIEW,
} from 'components/DetailView/constants';
import DetailForm from './DetailForm';
import { Card, CardBody } from '../common/Form/Card';
import DetailTitle from './DetailTitle';

const DetailView = forwardRef(function (props, ref) {
  const {
    editMode,
    data,
    cols,
    tableProps: { title: gridTitle, create, detailViewMode },
    extraToolbar,
    idFieldName,
    isLoading,
    onSave,
    onUpdate,
    colsAsTable,
    colsAsAccordion,
  } = props;

  const [mode, setMode] = useState('');
  const [fieldsValues, setFieldsValues] = useState({});
  const [isAllowInDropdown, setAllowInDropdown] = useState(false);
  const [specSheetUrl, setSpecSheetUrl] = useState(null);
  const [newSpecSheetUrl, setNewSpecSheetUrl] = useState(null);
  const [errors, setErrors] = useState({});
  const [showExtraDialog, doShowExtraDialog] = useState(0);
  const setGlobalSpinner = useGlobalSpinnerActionsContext();

  useEffect(() => {
    onUpdate();

    const state = {};

    const colsAsTableFields =
      (editMode === CREATE.toLowerCase() && create?.fieldsAsTable) ||
      colsAsTable;

    if (colsAsTableFields) {
      colsAsTableFields.forEach(({ fields }) => {
        fields.forEach(({ field, path }) => {
          setObjProp(state, path || field, getObjProp(data, path || field));
        });
      });
    }

    const colsAsAccordionFields =
      (editMode === CREATE.toLowerCase() && create?.fieldsAsAccordion) ||
      colsAsAccordion;

    if (colsAsAccordionFields) {
      colsAsAccordionFields.forEach(({ fields }) => {
        fields.forEach(({ field, path }) => {
          setObjProp(state, path || field, getObjProp(data, path || field));
        });
      });
    }

    ((editMode === CREATE.toLowerCase() && create?.fields) || cols).forEach(
      ({ field, path }) => {
        setObjProp(state, path || field, getObjProp(data, path || field));
      }
    );

    if (mode === CREATE && !create && !fieldsValues.TierId) {
      state.TierId = 1;
    }

    setAllowInDropdown(data?.availableInDropdown);
    setFieldsValues(state);
    setErrors({});
    setSpecSheetUrl(data?.dataSheetUrl);
  }, [data, mode, cols, colsAsTable, colsAsAccordion]);

  useEffect(() => {
    if (editMode === UPDATE && detailViewMode === EDIT) {
      setMode(EDIT);
      return;
    }
    setMode(
      // eslint-disable-next-line no-nested-ternary
      editMode === INSERT.toLowerCase()
        ? EDIT
        : editMode === CREATE.toLowerCase()
        ? CREATE
        : VIEW
    );
  }, [editMode]);

  useEffect(() => {}, [newSpecSheetUrl]);

  const isAllFieldsAreCorrect = useCallback(() => {
    let validationErrors;
    if (
      editMode === String(CREATE).toLowerCase() &&
      create?.validationOnCreate
    ) {
      validationErrors = create.validationOnCreate(fieldsValues);
    } else {
      validationErrors = validateFields(cols, fieldsValues);
    }
    if (validationErrors) {
      setErrors(validationErrors);
      return false;
    }
    setErrors({});
    return true;
  }, [cols, fieldsValues]);

  const onSwitcherChange = useCallback(() => {
    setGlobalSpinner(true);
    api
      .getSpecSheet(`${data.dataSheetUrl}.pdf`, !!data.custom)
      .then(({ data: { url: specSheetUrl } }) => {
        if (
          !isAllowInDropdown &&
          (data.custom || !data.approved || !specSheetUrl)
        ) {
          Toaster.error(
            'This module cannot be made available, because it needs to be approved and must include a valid PDF spec sheet.'
          );
          return;
        }

        if (isAllowInDropdown) isAllFieldsAreCorrect();
        fieldsValues.availableInDropdown = !isAllowInDropdown;
        setAllowInDropdown(fieldsValues.availableInDropdown);
      })
      .catch(() => {
        Toaster.error('The module data sheet does not exist');
      })
      .finally(() => setGlobalSpinner(false));
  }, [isAllowInDropdown, isAllFieldsAreCorrect]);

  const getExtraToolbar = useCallback(
    (gridtitle) => {
      const allExtraToolbars = {
        'solar modules': () =>
          extraToolbar({
            isAllowInDropdown,
            onSwitcherChange,
            isEditMode: mode === EDIT && !isLoading,
            isAllFieldsAreCorrect,
            specSheetUrl,
            data,
            setNewSpecSheetUrl,
            showExtraDialog,
          }),
      };

      const toCall = allExtraToolbars[gridtitle.toLowerCase()];
      return (toCall && toCall()) || null;
    },
    [
      data,
      extraToolbar,
      gridTitle,
      isAllowInDropdown,
      onSwitcherChange,
      mode,
      isAllFieldsAreCorrect,
      specSheetUrl,
      setNewSpecSheetUrl,
      showExtraDialog,
    ]
  );

  const onChange = async (name, value) => {
    const state = { ...fieldsValues };
    const updateRoles = name === TENANT && value.name !== state?.Tenant?.name;
    setObjProp(state, name, value);

    if (updateRoles) {
      const roles = cols.find((col) => col.field === ROLES);
      const newListOfRoles = await roles.lookup(state);
      const newValueRoles = [];
      state.Roles.forEach((role) => {
        const newRole = newListOfRoles.data.find((el) => el.name === role.name);
        if (newRole) newValueRoles.push(newRole);
      });
      if (newValueRoles.length === 0) {
        newValueRoles.push(newListOfRoles.data[0]);
      }
      state.Roles = newValueRoles;
    }

    setFieldsValues(state);
  };

  const saveChanges = (e) => {
    if (isLoading || mode === VIEW || (e && !Object.keys(e).length)) return;

    e?.preventDefault();
    e?.stopPropagation();

    const fieldsCorrect = isAllFieldsAreCorrect();
    // eslint-disable-next-line prettier/prettier
    if (fieldsCorrect && isAllowInDropdown && !data.dataSheetUrl) {
      // * to test CPD-247: change !data.dataSheetUrl to 1
      return doShowExtraDialog((prev) => prev + 1);
    }

    const dataForSave = {};

    Object.keys(fieldsValues).forEach((fieldName) => {
      if (fieldsValues[fieldName] && fieldsValues[fieldName].changed) {
        const { changed } = fieldsValues[fieldName];

        dataForSave[fieldName] = {};

        if (changed) {
          Object.keys(changed).forEach((changedFieldName) => {
            dataForSave[fieldName][changedFieldName] =
              changed[changedFieldName];
          });
        }

        delete fieldsValues[fieldName];
      } else {
        dataForSave[fieldName] = fieldsValues[fieldName];
      }
    });

    if (fieldsCorrect /* && data.dataSheetUrl */) {
      return onSave(
        (data || dataForSave)[idFieldName],
        dataForSave,
        mode === CREATE
      );
    }
  };

  return (
    <div ref={ref} className="detail-view">
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <DetailTitle
                  {...props}
                  mode={mode}
                  setMode={setMode}
                  getExtraToolbar={getExtraToolbar}
                  isAllowInDropdown={data?.availableInDropdown}
                />
                <DetailForm
                  {...props}
                  mode={mode}
                  errors={errors}
                  fieldsValues={fieldsValues}
                  loading={isLoading}
                  onChange={onChange}
                  onSave={saveChanges}
                  onSubmit={saveChanges}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

DetailView.displayName = 'DetailView';

DetailView.propTypes = {
  editMode: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableProps: PropTypes.objectOf(PropTypes.any).isRequired,
  extraToolbar: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isLoading: PropTypes.bool.isRequired,
  idFieldName: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DetailView;
