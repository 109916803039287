import { action } from '../_utils';
import { SET_ERROR, CLEAR_ALL_ERRORS, SET_ERROR_POPUP } from './types';

export const setError = (actionKey, errorKey = null, error = null) =>
  action(SET_ERROR, {
    actionKey,
    errorKey,
    error,
  });

export const setErrorPopup = (actionKey, popupMsg = null) =>
  action(SET_ERROR_POPUP, {
    actionKey,
    popupMsg,
  });

export const clearError = (actionKey) =>
  action(SET_ERROR, {
    actionKey,
    errorKey: null,
    error: null,
  });

export const clearAllErrors = () => action(CLEAR_ALL_ERRORS, {});
