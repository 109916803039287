/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { CardBody, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable from 'components/MaterialTable';
import { api } from 'utils/fetch';

import { Card } from 'react-bootstrap';

import {
  setHellioscopeProjects,
  setHellioscopeDesigns,
  setHellioscopeSegments,
  switchHSTable,
  setHsHidedHeader,
} from 'redux/actions/dataViewActions';
import loaderActions from 'redux/actions/loaderActions';

import CircularProgress from 'components/CircularProgress';
import {
  HELIOSCOPE,
  HS_COLUMNS_FILTER_NAME,
} from 'containers/ApiInspector/constants';

import Cache from 'utils/Cache';

import { getHSTitles, getHSTable } from './utils';

import './assets/style.scss';

class HelioscopeInspector extends React.Component {
  static prepareDataForTable(inData, options = {}) {
    const { metadata, data } = inData || {};
    const { header } = getHSTitles(metadata, {
      filteringDisabled: options.filteringDisabled,
      sortingDisabled: options.sortingDisabled,
    });
    const table = getHSTable(data);
    return { header, table };
  }

  constructor(props) {
    super(props);

    this.tableRef = React.createRef(null);

    this.detailViewCols = [];

    this.state = {
      viewedTableNames: [],
      cachedTables: {
        projects: false,
        designs: {},
        segments: {},
      },
      loadingData: false,
    };
  }

  componentDidMount() {
    const { sessionId, table, tableName } = this.props;

    if (!table[tableName] && sessionId) {
      this.init();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      sessionId,
      table,
      tableName,
      hasHelioscopeCredentials,
    } = this.props;

    if (sessionId && sessionId !== prevProps.sessionId) {
      if (!table[tableName]) {
        this.init();
      }
    }
  }

  async getHelioscopeData() {
    const {
      sessionId,
      setProjectsTable,
      onEvent,
      tableName,
      options,
    } = this.props;

    const result = await api.getApiInspectorSourceTableMetadata(
      HELIOSCOPE,
      tableName,
      {
        data: {
          miscOptions: {
            HelioscopeSessionId: sessionId,
          },
        },
      }
    );

    if (result) {
      this.updateTableState(tableName);
      setProjectsTable(
        HelioscopeInspector.prepareDataForTable(result.data, options)
      );
      onEvent({ action: 'loaded' });
    }
  }

  updateTableState = (tableName, selectedId) => {
    const { cachedTables } = this.state;
    if (tableName === 'projects') {
      cachedTables[tableName] = true;
    } else {
      cachedTables[tableName][selectedId] = true;
    }

    this.setState({
      cachedTables: { ...cachedTables },
    });
  };

  selectTable = async ({ tableName, fieldName, fieldValue, callback }) => {
    const { cachedTables } = this.state;
    const {
      sessionId,
      switchTable,
      setDesignsTable,
      setSegmentsTable,
      options,
    } = this.props;

    switch (tableName) {
      case 'projects': {
        switchTable(tableName, fieldValue);
        callback();
        break;
      }
      case 'designs': {
        if (!cachedTables.designs[fieldValue]) {
          const result = await api.getApiInspectorSourceTableMetadata(
            HELIOSCOPE,
            tableName,
            {
              data: {
                filters: [
                  {
                    name: fieldName,
                    value: fieldValue,
                  },
                ],
                miscOptions: {
                  HelioscopeSessionId: sessionId,
                },
              },
            }
          );

          if (result) {
            this.updateTableState('designs', fieldValue);
            setDesignsTable(
              fieldValue,
              HelioscopeInspector.prepareDataForTable(result.data, options)
            );
            callback();
          }
        } else {
          switchTable(tableName, fieldValue);
          callback();
        }

        break;
      }
      case 'field-segments': {
        if (!cachedTables.segments[fieldValue]) {
          const result = await api.getApiInspectorSourceTableMetadata(
            HELIOSCOPE,
            tableName,
            {
              data: {
                filters: [
                  {
                    name: fieldName,
                    value: fieldValue,
                  },
                ],
                miscOptions: {
                  HelioscopeSessionId: sessionId,
                },
              },
            }
          );

          if (result) {
            this.updateTableState('segments', fieldValue);
            setSegmentsTable(
              fieldValue,
              HelioscopeInspector.prepareDataForTable(result.data, options)
            );
            callback();
          }
        } else {
          switchTable('segments', fieldValue);
          callback();
        }
        break;
      }

      default:
        break;
    }
  };

  selectColumn = (name, value) => {
    const { tableName, hidedColumns, setHsHidedColumns } = this.props;

    let needUpdate = false;

    if (value) {
      if (!hidedColumns[tableName]) {
        hidedColumns[tableName] = {};
      }

      hidedColumns[tableName][name] = true;

      needUpdate = true;
    } else if (hidedColumns[tableName]?.[name]) {
      delete hidedColumns[tableName][name];
      needUpdate = true;
    }

    if (needUpdate) {
      hidedColumns[tableName] = {
        ...hidedColumns[tableName],
      };

      Cache.setValue(HS_COLUMNS_FILTER_NAME, hidedColumns);

      setHsHidedColumns({ ...hidedColumns });
    }
  };

  goTo = ({ tableName: targetTableName, referenceName, value, row, col }) => {
    this.tableRef.current.setLoadingTable(true);

    const { tableName, selectedId } = this.props;
    const { viewedTableNames } = this.state;

    this.setState({
      viewedTableNames: [
        ...viewedTableNames,
        { tableName, fieldName: col.field, fieldValue: selectedId },
      ],
    });

    this.tableRef.current.resetCachedData();

    this.selectTable({
      tableName: referenceName,
      fieldName: col.field,
      fieldValue: value,
      callback: () => {
        this.tableRef.current.clearFiltersAndUpdateTable();
        this.tableRef.current.setLoadingTable(false);
      },
    });
  };

  revert = () => {
    this.tableRef.current.setLoadingTable(true);

    const { viewedTableNames } = this.state;

    const { tableName, fieldName, fieldValue } = viewedTableNames.pop();

    this.tableRef.current.resetCachedData();

    this.selectTable({
      tableName,
      fieldName,
      fieldValue,
      callback: () => {
        this.setState(
          {
            viewedTableNames: [...viewedTableNames],
          },
          () => {
            this.tableRef.current.clearFiltersAndUpdateTable();
            this.tableRef.current.setLoadingTable(false);
          }
        );
      },
    });
  };

  init() {
    this.setState({
      loadingData: true,
    });

    const { sessionId, setHsHidedColumns } = this.props;

    const savedHidedColumns = Cache.getValue(HS_COLUMNS_FILTER_NAME) || {};

    setHsHidedColumns(savedHidedColumns);

    this.getHelioscopeData(sessionId);

    this.setState({
      loadingData: false,
    });
  }

  render() {
    const {
      header,
      table,
      selectedId,
      tableName,
      hasHelioscopeCredentials,
      sessionId,
      rootTableName,
      loading,
    } = this.props;

    const { loadingData } = this.state;

    if (loading || loadingData) {
      return (
        <Card>
          <CardBody>
            <div className="api-inspector-progress">
              <CircularProgress content="" />;
            </div>
          </CardBody>
        </Card>
      );
    }

    if (!hasHelioscopeCredentials && !sessionId) {
      return (
        <>
          Helioscope is not connected. Please configure it in clawOS user
          profile.
        </>
      );
    }

    const currentTable = selectedId ? table[selectedId] : table;

    const showRevertTool = tableName && tableName !== rootTableName;

    // eslint-disable-next-line no-nested-ternary
    return (
      <div className="hs-page">
        <Row>
          {header.length ? (
            <MaterialTable
              ref={this.tableRef}
              tableProps={{
                title: '',
                name: 'HelioscopeInspector',
                tableName,
                selectedId,
                hidedHSColumns: {},
                showRevertTool,
                showSearchBar: false,
                hideDefaultMenu: true,
                makeRefrenceLink: true,
                actions: {
                  goTo: this.goTo,
                  revert: this.revert,
                },
              }}
              columns={header}
              report
              getData={async () => {
                return {
                  count: currentTable.length,
                  data: currentTable,
                };
              }}
              detailsView={{
                cols: this.detailViewCols,
                idFieldName: '_id',
              }}
            />
          ) : null}
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const {
    auth: {
      helioscope: { initializing, sessionId, error },
      user,
    },
    dataView: {
      hellioscope: { headers, tables, currentTable, selectedId, hidedColumns },
    },
  } = state;

  const header = headers[currentTable] || {};
  const table = tables[currentTable] || {};
  const hasHelioscopeCredentials = user && user.hasHelioscopeCredentials;

  return {
    loading: initializing,
    hasHelioscopeCredentials,
    sessionId,
    HSerror: error,
    header,
    hidedColumns,
    table,
    selectedId,
    tableName: currentTable || props.tableName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProjectsTable: ({ header, table }) => {
    dispatch(setHellioscopeProjects(header, table));
  },
  setDesignsTable: (projectId, { header, table }) => {
    dispatch(setHellioscopeDesigns(projectId, header, table));
  },
  setSegmentsTable: (designId, { header, table }) => {
    dispatch(setHellioscopeSegments(designId, header, table));
  },
  switchTable: (tableName, selectedId) => {
    dispatch(switchHSTable(tableName, selectedId));
  },
  setHsHidedColumns: (hidedColumns) => {
    dispatch(setHsHidedHeader(hidedColumns));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(HelioscopeInspector);

HelioscopeInspector.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  setTables: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setHeader: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props,react/forbid-prop-types
  sessionId: PropTypes.string,
  // eslint-disable-next-line react/require-default-props,react/forbid-prop-types
  header: PropTypes.object,
  // eslint-disable-next-line react/require-default-props,react/forbid-prop-types
  table: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  selectedId: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  tableName: PropTypes.string,
  rootTableName: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  hasHelioscopeCredentials: PropTypes.bool,
  setHsHidedColumns: PropTypes.func.isRequired,
  setProjectsTable: PropTypes.func.isRequired,
  setDesignsTable: PropTypes.func.isRequired,
  setSegmentsTable: PropTypes.func.isRequired,
  switchTable: PropTypes.func.isRequired,
  hidedColumns: PropTypes.object,
  onEvent: PropTypes.func,
  options: PropTypes.object,
  loading: PropTypes.bool,
};

HelioscopeInspector.defaultProps = {
  hidedColumns: {},
  onEvent: () => {},
  tableName: null,
  rootTableName: null,
  options: {},
  loading: true,
};
