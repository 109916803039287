import { action } from '../_utils';

export const CHANGE_LOADER = 'CHANGE_LOADER';
export const CHANGE_TABLE_LOADER = 'CHANGE_TABLE_LOADER';

export default {
  setLoading: (loading) => action(CHANGE_LOADER, { loading }),
  setTableLoading: (tableLoading) =>
    action(CHANGE_TABLE_LOADER, { tableLoading }),
};
