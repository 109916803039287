import React from 'react';
import { Icon } from '@material-ui/core';

import addIcon from '../assets/filterIcon.svg';

export function CallMade() {
  return (
    <Icon style={{ textAlign: 'center', overflow: 'visible' }}>
      <img
        style={{ verticalAlign: 'unset' }}
        src={addIcon}
        height={25}
        width={25}
        alt="addIcon"
      />
    </Icon>
  );
}

export const columnsFilterTool = ({ name, onShowColumnsModal }) => ({
  name,
  icon: <CallMade />,
  onClick: () => onShowColumnsModal(),
});
