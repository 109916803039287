import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Highlighter from 'react-highlight-words';
import { right } from '@popperjs/core';
import TextField from '@material-ui/core/TextField';
import {
  colorScrollbar,
  colorText,
  colorBorder,
  colorBackground,
  colorTextAdditional,
  colorHover,
  colorBackgroundBody,
  colorAdditional,
  colorBlue,
  colorAccent,
  colorFieldsBorder,
  colorIcon,
  colorHeaderDataPicker,
} from '../utils/palette';
import { left, marginLeft, marginRight } from '../utils/directions';

export const scrollbarStyles = (props) => `
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    opacity: 0.3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colorScrollbar(props)};
  }
`;

export const TableWrap = styled.div`
  overflow-x: auto;

  ${scrollbarStyles};

  ${(props) =>
    props.pagination
      ? `
    margin-bottom: 1rem;
  `
      : `
    max-height: 458px;
    min-height: 250px;

    tbody {
      top: 30px;
    }
  `}
`;

// TODO:  //${colorBorder}${colorBackground} fix theme
export const tableStyle = `
  text-align: ${left};

  &:last-child {
    margin-bottom: 0;
  }

  table {
    width: inherit;
  }

  thead tr {
    position: sticky;
    top: 0;
    border-top: none;
  }

  thead th {
    min-height: 45px;
    min-width: 100px;
    padding: 10px 0 10px 0;
    top: 0;
    position: sticky;
    color: ${colorText};
    border-bottom: 2px solid #eff1f5;
    border-bottom-color: #eff1f5 !important;
    background: white !important;

    &:first-child {
      min-width: 45px;
    }
  }

  tbody td,
  tfoot td,
  th {
    border: none;
    padding: 10px 5px;
  }

  &.table tbody td,
  &.table tfoot td,
  th {
    color: ${colorTextAdditional};
  }

  th, td {
    vertical-align: middle;
    background: transparent !important;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  .badge {
    font-size: 100%;
  }

  tfoot {

    tr {
      border-top: 1px solid #eff1f5;
      border-bottom: none;
    }

    td {
      min-height: 45px;
      min-width: 100px;
      padding: 10px 5px 10px 5px;
      font-family: 'Roboto', sans-serif;
      color: ${colorText};
      font-weight: 700;

      &:first-child {
        min-width: 45px;
        white-space: nowrap
      }
    }
  }

  ${(props) =>
    props.bordered &&
    `
    text-align: ${left(props)};

    tr {
      border-top: none;
      border-bottom: 1px solid ${colorBorder(props)};
    }

    tbody tr:last-child {
      border-bottom: none;
    }
  `}

  ${(props) =>
    props.headAccent &&
    `

    thead {
      color: ${colorTextAdditional(props)};
      background-color: ${colorHover(props)};

      th {
        border-bottom: none;
      }
    }
  `}

  ${(props) =>
    props.striped &&
    `

    tbody tr:nth-of-type(odd) {
      --bs-table-accent-bg: ${colorHover(props)};
      background-color: ${colorHover(props)};
    }
  `}

  &.table-hover {

    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: ${colorBackgroundBody};
      }
    }
  }

  &.react-table {

    thead tr {
      position: sticky;
      top: 0;
      background: white;
    }
  }

  .react-table__column-header {
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    &:first-child{
      margin-right: 10px;
    }

    &.sortable {
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        ${right}: -20px;
      }
    }
  }

  &.resizable-table {

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;
      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 100px !important;

      &:first-child {
        max-width: 60px;
        min-width: 60px !important;
      }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action :none;
        background: ${colorBorder};

        &.isResizing {
          background: ${colorHeaderDataPicker};
        }
      }
    }
  }

  .react-dnd--off {
    pointer-events: none;
  }

  .react-dnd-draggable {
    z-index: 98 !important;

    td {
      width: inherit;

      &:first-child {
        width: 70px;
        min-width: 70px !important;
      }
    }
  }

  &.editable-table {
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -ms-user-select: none; /* IE10+ */

    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 100px;

      &:first-child {
        max-width: 60px;
        min-width: 60px;
      }
    }

    .table__editable-table--cell-active {
      max-width: inherit;
      border: none;
      outline: 2px solid ${colorBlue};
      margin: -7px 10px -6px 0;
    }

    .table__edit-form {
      max-width: fit-content;
      border: none;
      border-radius: 0;
      font-size: 13px;
      font-family: 'Roboto', sans-serif;
      padding-left: 0;
      background-color: ${colorBackground};
      color: ${colorTextAdditional};

      &:active {
        border: none;
        outline: 1px solid ${colorBlue};
      }
    }

    .form-control {

      &:focus {
        box-shadow: none;
      }
    }
  }
`;

export const SearchWrap = styled.div`
  display: flex;
  align-items: baseline;
  //margin-bottom: 30px;
  color: ${colorAdditional};
`;

export const SearchInput = styled(TextField)`
  max-width: 350px;
  min-width: 250px;
  font-size: 12px;
  margin-right: 20px;
  color: ${colorText};
  background: ${colorBackground};
`;

export const PaginationWrap = styled.div`
  display: flex;
  align-items: center;
  float: right;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;

    & > * {
      margin: 10px 0;
    }
  }

  .page-item {
    ${marginRight}: 2px;

    .page-link {
      background: transparent;
      border: none;
      color: ${colorAdditional};
      height: 28px;
      font-size: 10px;
      padding: 5px;
      text-align: center;
      min-width: 28px;
      transition: all 0.3s;

      &:hover {
        color: ${colorAccent};
        background: transparent;
      }

      &:focus,
      &:active {
        box-shadow: none;
      }

      svg {
        width: 13px;
        height: 13px;
        fill: ${colorAdditional};
        transition: 0.3s;
        position: absolute;
        top: 7px;
        left: 8px;
      }
    }

    &.pagination__item--arrow .page-link {
      border-radius: 50%;
      width: 28px;
      height: 28px;
      background: ${colorFieldsBorder};

      &:hover {
        background: ${colorAccent};

        svg {
          fill: white;
        }
      }
    }

    &.pagination__item--arrow.disabled .page-link {
      cursor: default;
      opacity: 0.4;
      background: ${colorFieldsBorder};

      svg {
        fill: ${colorAdditional};
      }
    }

    &.active .page-link {
      background-color: transparent;
      font-weight: bold;
      color: ${colorText};
    }

    &.disabled .page-link svg {
      fill: ${colorIcon};
    }
  }
`;

export const PaginationInfo = styled.div`
  color: ${colorAdditional};
  font-size: 12px;
  ${marginRight}: 0;
  ${marginLeft}: 10px;
`;

export const PaginationSelectWrap = styled(PaginationInfo)`
  ${marginLeft}: 20px;
  color: ${colorAdditional};
`;

export const PaginationFormSelect = styled(Form.Select)`
  color: ${colorAdditional};
  font-size: 12px;
  background-color: ${colorBackground};
  padding: 6px 12px;
  appearance: auto;
  background-image: none;
`;

export const PaginationFormOption = styled.option`
  color: ${colorAdditional};
  font-size: 14px;
`;

export const StyledHighlighter = styled(Highlighter)`
  mark {
    background-color: ${colorAccent};
    padding: 0;
  }
`;
