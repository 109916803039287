import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getDefaultFileName } from '../Tools/exportTools';

export const generateExcelTableReport = ({
  data: jsonData,
  headers,
  reportName = 'Report',
  filename = getDefaultFileName(reportName, 'xlsx'),
}) => {
  const compactHeaders = headers.reduce((acc, cur) => {
    return { ...acc, [cur.key]: cur.display };
  }, {});
  const headersKeys = Object.keys(compactHeaders);
  const preparedData = jsonData.reduce((acc, cur) => {
    const row = headersKeys.reduce(
      (a, c) => ({ ...a, [compactHeaders[c]]: cur[c] }),
      {}
    );
    return [...acc, row];
  }, []);
  const wb = {
    Sheets: { data: XLSX.utils.json_to_sheet(preparedData) },
    SheetNames: ['data'],
  };
  const bData = new Blob(
    [XLSX.write(wb, { bookType: 'xlsx', type: 'array' })],
    {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    }
  );
  FileSaver.saveAs(bData, filename);
};

export default generateExcelTableReport;
