// using for Links
export default {
  urls: {
    dashboard: '/',
    users: '/users',
    usersCIS: '/cis-users',
    usersMongo: '/mongo-users',
    smm: '/smm',
    signin: '/signin',
    pcdesigner: '/pcdesigner',
    cprojects: '/custom-projects',
    apiInspector: '/api-inspector',
    sales: '/sales',
    dwRequests: '/design-workflow/requests',
    dwSubmissions: '/design-workflow/submissions',
    dwAnalytics: '/design-workflow/analytics',
    dwManagement: '/design-workflow/management',
    finals: '/finals',
    tenants: '/tenants',
    acls: '/acls',
    roles: '/roles',
    estimateActionItem: '/estimate/:id/:action/:item',
    estimateAction: '/estimate/:id/:action',
    estimate: '/estimate/:id',
    estimates: '/estimates',
    reportsCustom: '/reports/custom',
  },
};
