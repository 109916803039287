export const userSchema = {
  firstName: {
    name: 'First Name',
    required: true,
  },
  lastName: {
    name: 'Last Name',
    required: true,
  },
  phone: {
    name: 'Phone Number',
    required: false,
  },
  email: {
    type: 'string',
    name: 'Email',
    required: true,
    custom: 'validateEmail',
  },
  helioscopeLogin: {
    type: 'string',
    name: 'helioscopeLogin',
    required: false,
  },
  helioscopePass: {
    type: 'string',
    name: 'helioscopePass',
    required: false,
  },
  companyName: {
    name: 'Company Name',
    required: true,
  },
  companyStreetAddress: {
    name: 'Company Street Address',
    required: true,
  },
  companyCity: {
    name: 'Company City',
    required: true,
  },
  companyState: {
    name: 'Company State/Province',
    required: true,
  },
  companyZip: {
    name: 'Company Zip Code',
    required: true,
  },
  companyCountry: {
    name: 'Company Country',
    required: true,
  },
  defaultModuleFS: {
    name: 'Default module FS',
    required: true,
  },
  defaultSubarrayFS: {
    name: 'Default subarray FS',
    required: true,
  },
};

export const passwordSettingSchema = {
  exPassword: {
    Name: 'Existing Password',
    required: true,
    custom: 'validatePassword',
  },
  password: {
    Name: 'New Password',
    required: true,
    custom: 'validatePassword',
  },
  confirmPassword: {
    Name: 'Confirm Password',
    required: true,
    custom: 'validateConfirmPassword',
  },
};
