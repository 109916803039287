import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Addon from './Addon';
import HelperText from './HelperText';

import { inputStyles, labelStyles } from './styles';

import './styles.scss';

const NUMBER = 'number';
const CURRENCY = 'currency';

const allowedInputTypes = ['text', 'number', 'password', 'tel', 'url', 'email'];

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

function parseTypeValue(value, precision, valueType){
  switch (valueType) {
    case CURRENCY:
      return Number(value).toFixed(precision);
    case NUMBER:
      return parseFloat(value);
  }
}

function parseFloatToFixed(value, precision, valueType) {
  const float = value.toString().split('.');
  if (float.length === 2) {
    if (float[1].length > precision) {
      float[1] = float[1].substring(0, precision);
      return parseTypeValue(float.join('.'), precision, valueType);
    }
    return parseTypeValue(value, float[1].length, valueType);
  }
  return parseTypeValue(value, 0, valueType);
}

function getValue(value = null, precision, valueType) {
  if (!value && value !== 0) return '';
  if (precision === 0) return Math.floor(value);
  if (precision && isFloat(Number(value)))
    return parseFloatToFixed(value, precision, valueType);
  return value;
}

function TextInput({
  type,
  valueType = NUMBER,
  name,
  value = null,
  precision,
  label,
  labelPosition,
  labelWidth,
  labelAdditional,
  labelAddOnClick,
  size,
  error,
  helperText,
  helperTextColor,
  onClickHelperText,
  button,
  onButtonClick,
  addon,
  onChange,
  removeSymbols,
  hint,
  orientation,
  customValidate = false,
  max = 0,
  min = 0,
  disabled,
  staticContext,
  dispatch,
  validationtip = null,
  match,
  location,
  history,
  onBlur = null,
  ...rest
}) {
  const refInput = useRef(null);
  const [validationError, setValidationError] = useState(null);

  const inputType = allowedInputTypes.includes(type) ? type : 'text';
  const getInputValue = React.useCallback(
    (value, precision, valueType) =>
      removeSymbols
        ? getValue(value, precision, valueType).replace(/[^\w\s]/gi, '')
        : getValue(value, precision, valueType),
    [value, precision, valueType]
  );

  const classList = {
    labelClass: '',
    inputClass: '',
  };

  if (orientation === 'horizontal') {
    classList.labelClass = 'col-sm-4 control-label';
    classList.inputClass = 'col-sm-8';
  }

  if (orientation === 'vertical') {
    classList.labelClass = 'component-header';
  }

  const validateRange = ({ min = null, max = null, val = null }) => {
    const errTextStart = 'Should be';
    if ((!min && !max) || !val) return null;
    if (min && max)
      return Number(val) >= Number(min) && Number(val) <= Number(max)
        ? null
        : `${errTextStart} between ${min} and ${max}!`;
    if (min && !max)
      return Number(val) >= Number(min)
        ? null
        : `${errTextStart} greater than ${min}!`;
    if (!min && max)
      return Number(val) <= Number(max)
        ? null
        : `${errTextStart} less than ${max}!`;
    return null;
  };

  const handleOnchange = React.useCallback(
    (evt) => {
      const {
        value: { tmpVal },
      } = evt.target;
      let val = 0;
      if (precision === 0 && +tmpVal === 0) {
        val = tmpVal;
      } else {
        val = getInputValue(tmpVal, precision);
      }

      if (inputType.toLowerCase() === NUMBER && customValidate)
        setValidationError(validateRange({ min, max, val }));
      onChange(evt);
    },
    [value, name]
  );

  const handleOnKeyDown = (e) => {
    if (inputType.toLowerCase() === NUMBER) {
      if (
        (precision === 0 &&
          e.target.value &&
          (e.keyCode === 69 || e.keyCode === 190)) ||
        e.code === 'Minus' ||
        e.code === 'NumpadSubtract' ||
        e.keyCode === '109'
      ) {
        e.preventDefault();
      }
    }
  };

  return (
    <div className="form-group">
      <div className="info-block">
        <div>
          {label && labelPosition !== 'left' && (
            <div style={{ marginBottom: '10px' }}>
              <label
                htmlFor={name}
                className={classList.labelClass}
                style={labelStyles[size]}
              >
                {label}
                {labelAdditional && (
                  <sup onClick={labelAddOnClick}>({labelAdditional})</sup>
                )}
              </label>
            </div>
          )}
          {helperText && (
            <HelperText
              text={helperText}
              color={helperTextColor}
              onClick={onClickHelperText}
            />
          )}
        </div>
        <div>
          {validationtip && (
            <div className="pc-inline pull-right text-right pc-input-tip">
              <span
                className={
                  error || validationError
                    ? 'measurement-input__tipAttention'
                    : 'measurement-input__tipDefault'
                }
              >
                {validationtip}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className={classList.inputClass}>
        <div className="input-group" style={{ width: '100%' }}>
          {label && labelPosition === 'left' && (
            <label
              htmlFor={name}
              className={`label_left ${classList.labelClass}`}
              style={{
                ...labelStyles[size],
                width: labelWidth,
              }}
            >
              {label}
              {labelAdditional && (
                <sup onClick={labelAddOnClick}>({labelAdditional})</sup>
              )}
            </label>
          )}
          <input
            type={inputType}
            style={inputStyles[size]}
            className={`form-control ${error ? 'is-invalid' : ''}`}
            name={name}
            value={getInputValue(value, precision, valueType)} // The reason of 1500's; TextInput should be moved in MeasurementInput; cannot be controlled and uncontrolled in the same time
            id={name}
            onChange={handleOnchange}
            onKeyDown={handleOnKeyDown}
            {...({ onBlur } || {})}
            disabled={disabled}
            ref={refInput}
            {...rest}
          />
          {button && (
            <Button
              onClick={onButtonClick}
              size={size}
              text={button}
              disabled={disabled}
            />
          )}
          {addon && <Addon text={addon} />}
          {hint && (
            <div className="pc-input-hint">
              Password should be 12 characters, at least one number, and one
              symbol
            </div>
          )}
        </div>
        {(error || validationError) && (
          <div className="pc-inline pull-right text-right">
            <span className="invalid-feedback">{error || validationError}</span>
          </div>
        )}
      </div>
    </div>
  );
}

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  precision: PropTypes.number,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  labelWidth: PropTypes.string,
  labelAdditional: PropTypes.string,
  labelAddOnClick: PropTypes.func,
  size: PropTypes.string,
  error: PropTypes.string,
  button: PropTypes.string,
  onButtonClick: PropTypes.func,
  helperText: PropTypes.string,
  onClickHelperText: PropTypes.func,
  helperTextColor: PropTypes.string,
  addon: PropTypes.string,
  orientation: PropTypes.string,
};

TextInput.defaultProps = {
  name: '',
  type: 'string',
  value: '',
  precision: null,
  label: null,
  labelPosition: 'top',
  labelWidth: '30%',
  size: 'medium',
  error: '',
  button: null,
  onButtonClick: () => {},
  helperText: null,
  helperTextColor: '#5e5ee2',
  onClickHelperText: () => {},
  addon: null,
  orientation: 'vertical',
  labelAdditional: '',
  labelAddOnClick: () => {},
};

export default TextInput;
