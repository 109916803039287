import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import TotalCount from './TotalCount';
import './style.scss';

function Dashboard(props) {
  const {
    // eslint-disable-next-line react/prop-types
    dashboardData: { smm },
  } = props;

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Charts</h3>
          <h3 className="page-subhead subhead">Subtitle</h3>
        </Col>
      </Row>
      <Row>
        <TotalCount />
      </Row>
    </Container>
  );
}

const mapSateToProps = ({ IoT: { dashboard } }) => ({
  dashboardData: dashboard,
});
export default connect(mapSateToProps)(Dashboard);
