const validationSchema = {
  PL: {
    name: 'PL',
    type: 'number',
    required: true,
  },
  PW: {
    name: 'PW',
    type: 'number',
    required: true,
  },
};

export default validationSchema;
