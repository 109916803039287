import React from 'react';
import PropsTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import './style.scss';

import 'components/DetailModalView/style.scss';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'components/common/Button';
import TableBase from 'components/common/Table/TableBase';
import { prepareData } from './utils';
import config from './config';

import { DWRequest } from '../MaterialTable/constants';

function ShowInSelectListModal({
  title,
  handleClose,
  open,
  list,
  count,
  headers,
  onClick,
  tableProps,
  toolbarActions,
  withPagination,
  remoteData,
  ...rest
}) {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [loading, setLoadingStatus] = React.useState(false);

  const { selectListFooter, selectListSubmitLabel, name } = tableProps;

  let idProperty = '';
  switch (name) {
    case DWRequest:
      idProperty = 'OpportunityNumber';
      break;
    default:
      idProperty = '_id';
      break;
  }

  function onRowClick(row) {
    setSelectedItem(
      row[idProperty] === (selectedItem && selectedItem[idProperty])
        ? null
        : row
    );
  }

  const rows = list.map((item, index) => prepareData(item, headers, index));

  const tableConfig = {
    ...config,
    withPagination,
    remoteData,
    // pageCount: (pageSize) => Math.ceil(count / pageSize),
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      maxWidth="md"
      className="sm-edit"
    >
      <DialogTitle>
        {title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            disabled={loading}
            onClick={handleClose}
            className="closeIcon"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <TableBase
          columns={headers}
          data={rows}
          tableConfig={tableConfig}
          onRowClick={onRowClick}
          selectedItem={selectedItem}
          count={count}
          idProperty={idProperty}
          {...rest}
        />
      </DialogContent>
      <div className="modal-list__toolbar">
        {toolbarActions && (
          <DialogActions>
            {toolbarActions.button && (
              <Button
                disabled={toolbarActions.button.disabled || !selectedItem}
                onClick={() => {
                  toolbarActions.button.onClick(selectedItem);
                }}
                className="btn btn-primary"
              >
                {toolbarActions.button.label}
              </Button>
            )}
          </DialogActions>
        )}
        {(selectListFooter || selectListSubmitLabel) && (
          <DialogActions>
            {selectListFooter}
            <Button
              loading={loading}
              disabled={loading || !selectedItem}
              onClick={() => {
                onClick(selectedItem, setLoadingStatus);
              }}
              className="btn btn-primary btn-lg"
            >
              {selectListSubmitLabel}
            </Button>
          </DialogActions>
        )}
      </div>
    </Dialog>
  );
}

ShowInSelectListModal.propTypes = {
  title: PropsTypes.string,
  open: PropsTypes.bool,
  handleClose: PropsTypes.func,
  onClick: PropsTypes.func,
  onRowClick: PropsTypes.func,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  list: PropsTypes.array,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  headers: PropsTypes.array,
  // eslint-disable-next-line react/no-unused-prop-types,react/forbid-prop-types
  tableProps: PropsTypes.object,
  toolbarActions: PropsTypes.object,
  loading: PropsTypes.bool,
};

ShowInSelectListModal.defaultProps = {
  tableProps: {},
  toolbarActions: null,
  title: '',
  open: false,
  loading: false,
  handleClose: () => ({}),
  onClick: () => ({}),
};

export default ShowInSelectListModal;
