import { createTheme } from '@material-ui/core/styles';

export const palette = {
  primary: {
    main: '#004D7A',
    contrastText: '#fff',
  },
  secondary: {
    main: '#d0c6c6',
  },
  error: {
    main: '#ff4861',
  },
  warning: {
    main: '#f6da6e',
  },
  info: {
    main: '#70bbfd',
  },
  success: {
    main: '#b8e986',
    dark: '#4ce1b6',
  },
};
const theme = createTheme({ palette, mode: 'light' });

export default theme;
