import { call, put, takeLatest } from 'redux-saga/effects';
import Toaster from 'components/ToasterQueue';
import IoTActions, { INITIALIZE_IOT } from '../actions/IoTActions';
import { SIGN_IN_SUCCEEDED } from '../authTypes';

export function* subscribeIoT() {
  try {
    const data = yield call(IoTActions.subscribe);
    yield put(IoTActions.updateIoT(data));
  } catch (error) {
    Toaster.error('Error while trying connect to IoT');
  }
}

export default [
  takeLatest(SIGN_IN_SUCCEEDED, subscribeIoT),
  takeLatest(INITIALIZE_IOT, subscribeIoT),
];
