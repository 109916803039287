export default class Access {
  #defaultState = false;

  constructor(config, acl = null) {
    this.id = config.ACLId;
    this.name = config.Name;

    this.permissions = {
      create: config.Ops?.Create || this.#defaultState,
      delete: config.Ops?.Delete || this.#defaultState,
      enable: config.Ops?.Enable || this.#defaultState,
      read: config.Ops?.Read || this.#defaultState,
      show: config.Ops?.Show || this.#defaultState,
      update: config.Ops?.Update || this.#defaultState,
    };

    this.acl = acl;
  }

  isInited() {
    return this.acl?.isInited();
  }

  allowCreate() {
    return this.permissions.create;
  }

  allowDelete() {
    return this.permissions.delete;
  }

  allowEnable() {
    return this.permissions.enable;
  }

  allowRead() {
    return this.permissions.read;
  }

  allowShow() {
    return this.permissions.show;
  }

  allowUpdate() {
    return this.permissions.update;
  }

  isAllow(operation) {
    return this.permissions[operation] || this.#defaultState;
  }
}
