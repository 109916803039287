/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import CommonButton from 'components/common/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@mui/material/Fade';
import MaterialTable from 'components/MaterialTable';
import { api } from 'utils/fetch';
import { Box } from '@material-ui/core';
import Menu from 'components/common/Menu';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  getAccessDWAdmin,
  getAccessDWDesignerManager,
  getAccessDWDesignerView,
  getAccessDesignWorkflow,
  getUser,
} from 'redux/selectors/selectors';
import { EDIT } from 'components/DetailView/constants';
import { DWRequest } from 'components/MaterialTable/constants';
import Toaster from 'components/ToasterQueue';
import ActionsDialog from 'containers/DesignerWorkflow/Requests/Dialog';
import ConfirmDialog from 'components/Dialogs/Confirm';
import { CLICK_TO_OPEN } from 'components/common/utils/constants';
import { prepareFilterForTable } from 'containers/DesignerWorkflow/utils/utils';
import { isBoolean, isFunction, isObject, scrollToView } from 'utils';
import moment from 'moment';
import ConfigTables from './utils';
import LookupCache from '../utils/lookupCache';

import './style.scss';

class Requests extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef(null);

    this.state = {
      selected: new Map(),
      showActionsPopup: false,
      showConfirmPopup: false,
      confirmPopupTitle: 'Confirm Action',
      confirmPopupMessage: '',
      confirmData: null,
      currentAction: null,
      currentSelectedItemId: null,
      currentSelectedItem: null,
      currentSelectedMap: null,
      filterOpened: false,
      filters: {},
      isFiltering: false,
      infoOpen: false,
      infoData: [],
    };

    this.openedRow = false;

    this.initActions();
    LookupCache.getAllDwLookup();
  }

  componentDidMount() {
    this.init();
  }

  getRequestInfo = async () => {
    const result = await api.getRequestInfo();
    this.setState({
      infoData: result?.data,
    });
  };

  getHeaderComponent() {
    const { table } = this.props;
    const { infoOpen, infoData } = this.state;
    const { title, getHeaderActions } = table?.components?.header || {
      title: '',
    };
    return (
      <Box className="estimate-header" sx={{ flexGrow: 1 }}>
        <header className="dw-request-header">
          <div className="dw-request-header-left">
            <Menu
              items={getHeaderActions()}
              onSelectItem={async (selected) => {
                const { filters } = this.state;
                this.tableRef.current.setLoading(true);
                const result = await selected
                  .getUrlLink(prepareFilterForTable(filters))
                  .catch((err) => {
                    Toaster.error(err?.message?.message || err?.message || err);
                  });
                this.tableRef.current.setLoading(false);
                if (result?.data?.url) {
                  Toaster.showLink(CLICK_TO_OPEN, result.data.url);
                }
              }}
            />
            <span>{title}</span>
          </div>

          <div>
            <Tooltip
              TransitionComponent={Fade}
              enterNextDelay={300}
              onClose={() => {
                this.setState({ infoOpen: false });
              }}
              title={infoData.map(({ Label, Value }) => (
                <div key={Label}>{`${Label}: ${Value}`}</div>
              ))}
            >
              <IconButton
                onMouseEnter={async () => {
                  if (infoOpen) return;
                  this.setState({ infoOpen: true });
                  await this.getRequestInfo();
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </header>
      </Box>
    );
  }

  init = async () => {
    await this.getRequestInfo();
  };

  initActions = () => {
    this.actionsWithEmptyList = [
      {
        label: 'Empty list',
        disabled: true,
      },
    ];

    this.actionWithoutSelectedItem = [
      {
        label: 'Please, edit table item',
        disabled: true,
      },
    ];

    this.actions = [
      {
        id: 'inProgress',
        label: 'In Progress',
        fieldName: 'RequestStatus',
        value: { Value: 'In Progress' },
        errors: [
          {
            values: ['In Progress'],
            message: 'This request is already In Progress',
          },
          {
            values: ['Complete'],
            message: 'This request is already Complete',
          },
          {
            values: ['Canceled'],
            message: 'This request is already Canceled',
          },
          /* {
            fieldName: 'RequestType',
            values: ['Project Stamp'],
            message:
              'This is a Stamp Request, so please provide the third party name',
          }, */
        ],
        isValid: this.isValidAction,
        onClick: this.handleSelectActions,
        needConfirm: true,
        confirmMessage: `You are about to change the Status to In Progress. Are you sure you want to do this?`,
      },
      {
        id: 'onHold',
        label: 'On Hold',
        fieldName: 'RequestStatus',
        value: { Value: 'On Hold' },
        fields: {
          OnHoldBy: {
            value: () => this.getUser().email,
          },
          OnHoldDate: {
            value: () =>
              moment().utc().subtract('+04:00').format('YYYY-MM-DDTHH:mm:ss'),
          },
        },
        errors: [
          {
            values: ['On Hold'],
            message: 'This request is already On Hold',
          },
          {
            values: ['Complete'],
            message: 'This request is already Complete',
          },
          {
            values: ['Canceled'],
            message: 'This request is already Canceled',
          },
        ],
        isValid: this.isValidAction,
        onClick: this.handleSelectActions,
        needConfirm: true,
        confirmMessage: `You are about to change the Status to On Hold. Are you sure you want to do this?`,
      },
      {
        id: 'complete',
        label: 'Complete',
        fieldName: 'RequestStatus',
        value: { Value: 'Complete' },
        fields: {
          DaysToComplete: {
            value: null,
          },
          CompletedDate: {
            value: () =>
              moment().utc().subtract('+04:00').format('YYYY-MM-DDTHH:mm:ss'),
          },
        },
        errors: [
          {
            values: ['In Progress'],
            operation: 'not',
            message: 'You can only complete requests that are In Progress',
          },
        ],
        isValid: this.isValidAction,
        onClick: this.handleSelectActions,
        needConfirm: true,
        confirmMessage: `You are about to change the Status to Complete. Are you sure you want to do this?`,
      },
      {
        id: 'useDivider',
        useDivider: true,
      },
      {
        id: 'assignTo',
        label: 'Assign To',
        type: 'assignedto',
        fieldName: 'AssignedTo',
        value: null,
        canOpenModalDialog: true,
        onClick: this.handleSelectActions,
        needConfirm: true,
        confirmMessage: `Are you sure you want to change the Assign To?`,
      },
      {
        id: 'designStatus',
        label: 'Design Status',
        type: 'designstatus',
        fieldName: 'DesignStatus',
        value: null,
        options: [{ Id: '', Value: '', Label: '' }],
        canOpenModalDialog: true,
        onClick: this.handleSelectActions,
        needConfirm: true,
        confirmMessage: `Are you sure you want to change the Design Status?`,
      },
      {
        id: 'reviewBy',
        label: 'Review By',
        type: 'reviewedby',
        fieldName: 'Reviewer',
        value: null,
        options: [{ Id: '', Value: '', Label: '' }],
        canOpenModalDialog: true,
        onClick: this.handleSelectActions,
        needConfirm: true,
        confirmMessage: `Are you sure you want to change the Review By?`,
      },
      {
        id: 'useDivider',
        useDivider: true,
      },
      {
        id: 'canceled',
        label: 'Canceled',
        fieldName: 'RequestStatus',
        value: { Value: 'Canceled' },
        errors: [
          {
            values: ['Complete'],
            message: 'You cannot cancel a request that is Completed',
          },
          {
            values: ['Canceled'],
            message: 'You cannot cancel a request that is Canceled',
          },
        ],
        isValid: this.isValidAction,
        onClick: this.handleSelectActions,
        needConfirm: true,
        confirmMessage: `You are about to change the Status to Canceled. Are you sure you want to do this?`,
      },
    ];
    this.createMapIdsToActions();
  };

  createMapIdsToActions = () => {
    this.mapIdsToActions = new Map();

    this.actions.forEach((action) => {
      this.mapIdsToActions.set(action.id, action);
    });
  };

  handleCheckboxClick = (event, rowData) => {
    const { applyActionsTo } = this.props;

    if (applyActionsTo === 'selected') {
      const { currentSelectedItemId } = this.state;

      if (currentSelectedItemId === rowData.Id) {
        this.setState({
          currentSelectedItemId: null,
          currentSelectedMap: null,
          currentSelectedItem: null,
        });
      } else {
        if (currentSelectedItemId) {
          Toaster.error(`Please select one request to action.`);
          return;
        }

        this.setState({
          currentSelectedItemId: rowData.Id,
          currentSelectedMap: { [rowData.Id]: rowData },
          currentSelectedItem: rowData,
        });
      }

      this.tableRef.current?.closeDetailView();
    }

    const { selected } = this.state;

    if (selected.has(rowData.Id)) {
      selected.delete(rowData.Id);
    } else {
      selected.set(rowData.Id, rowData);
    }

    this.setState({ selected });
  };

  handleSelectActions = (event) => {
    const { value } = event.nativeEvent.target;

    const action = this.mapIdsToActions.get(value);

    if (action.canOpenModalDialog) {
      this.setState({ showActionsPopup: true, currentAction: action });
    } else {
      const hasNotError = action?.isValid(action);

      if (!hasNotError) {
        return;
      }

      this.updateItemByAction(action);
    }
  };

  onHandleDialogSelectAction = async (value) => {
    try {
      const { currentAction } = this.state;

      const action = this.mapIdsToActions.get(currentAction.id);

      action.value = value;

      this.updateItemByAction(action);
      return true;
    } catch (err) {
      Toaster.error(err?.message?.message ? err.message.message : err?.message);
      return false;
    }
  };

  handlecloseActionsDialog = () => {
    this.setState({ showActionsPopup: false });
  };

  onEditRowAction = (id, data) => {
    this.openedRow = true;

    this.setState({
      currentSelectedItemId: id,
      currentSelectedMap: data,
      currentSelectedItem: data[id],
    });

    const { applyActionsTo } = this.props;

    if (applyActionsTo === 'selected') {
      this.clearSelected();
    }
  };

  onCreateRowAction = () => {
    this.openedRow = true;
  };

  getTableActions = () => {
    const { allowedActionsBtns } = this.props;
    const { currentSelectedItemId, selected } = this.state;

    if (allowedActionsBtns) {
      if (selected.has(currentSelectedItemId)) {
        return this.actions;
      }

      return this.actionWithoutSelectedItem;
    }

    return this.actionsWithEmptyList;
  };

  updateItemByAction = async (action) => {
    const { currentSelectedMap, currentSelectedItemId } = this.state;

    if (currentSelectedMap[currentSelectedItemId]) {
      if (action.needConfirm) {
        this.setState({
          showConfirmPopup: true,
          confirmPopupMessage: action.confirmMessage,
          confirmData: action,
        });
      } else {
        this.updateItemByActionPost(action);
      }
    }
  };

  updateItemByActionPost = async (action) => {
    const {
      currentSelectedMap,
      currentSelectedItemId,
      currentSelectedItem,
    } = this.state;

    if (currentSelectedMap[currentSelectedItemId]) {
      const { fieldName, value, fields: fieldsForUpdate } = action;
      currentSelectedItem[fieldName] = value.Value;
      if (isObject(fieldsForUpdate)) {
        Object.entries(fieldsForUpdate).forEach(([fieldKey, fieldValue]) => {
          currentSelectedItem[fieldKey] = isFunction(fieldValue.value)
            ? fieldValue.value()
            : fieldValue.value;
        });
      }

      const { tableData, ...fields } = currentSelectedItem;

      const data = {
        data: fields,
      };

      const { table } = this.props;

      const response = await table.putDWRequest(data);

      if (response.data) {
        Object.entries(response.data).forEach(([fieldKey, fieldValue]) => {
          currentSelectedItem[fieldKey] = fieldValue;
        });
      }

      this.tableRef.current.reloadTable();

      this.setState({ currentSelectedMap: { ...currentSelectedMap } });
    }
  };

  onHandleClickStampRequestMiddleToolbar = async () => {
    const { allowedUpdateStampRequest, table } = this.props;

    if (!allowedUpdateStampRequest) {
      Toaster.error('You do not have permission to perform this action');
      return;
    }

    const { selected } = this.state;

    if (selected.size !== 1) {
      Toaster.error(`Please select one request to stamp.`);
      return;
    }

    const { tableData, ...firstSelected } = selected.entries().next().value[1];

    if (!table.validateStampRequest(firstSelected)) {
      return;
    }

    const data = {
      data: {
        ...firstSelected,
      },
    };

    delete data.tableData;

    try {
      this.tableRef.current.setLoading(true);
      await table.postDWStampRequest(data);
      this.clearSelected();
      this.tableRef.current.forceUpdateTableData();
      Toaster.success('Stamp Request created');
    } catch (err) {
      Toaster.error(err?.message?.message ? err.message.message : err?.message);
    } finally {
      this.tableRef.current.setLoading(false);
    }
  };

  onHandleClickSwapDatesMiddleToolbar = async () => {
    const { allowedUpdateSwapDates, table } = this.props;

    if (!allowedUpdateSwapDates) {
      Toaster.error('You do not have permission to perform this action');
      return;
    }

    const { selected } = this.state;

    if (selected.size !== 2) {
      Toaster.error(`Please select two requests to swap.`);
      return;
    }
    const entries = selected.entries();

    const { tableData, ...firstSelected } = entries.next().value[1];
    const {
      tableData: tableDataSecond,
      ...secondSelected
    } = entries.next().value[1];

    if (!table.validateSwapDates(firstSelected, secondSelected)) {
      return;
    }

    const currentDate = moment().format('YYYY-MM-DD');

    const firstNote = `Swapped dates with ${secondSelected.Id} on ${currentDate}`;
    const secondNote = `Swapped dates with ${firstSelected.Id} on ${currentDate}`;

    const firstRequestNote = `${firstSelected.RequestNotes} ${firstNote}`;
    const secondRequestNote = `${secondSelected.RequestNotes} ${secondNote}`;

    if (firstRequestNote.length > 255 || secondRequestNote.length > 255) {
      Toaster.error(
        `This will exceed the Request Notes character limit. Please shorten the Request Notes and try again.`
      );
      return;
    }

    const firstData = {
      data: {
        ...firstSelected,
        RequestNotes: firstRequestNote,
        ExpectedCompletionDate: secondSelected.ExpectedCompletionDate,
        AssignedDate: secondSelected.AssignedDate,
        DaysToComplete: secondSelected.DaysToComplete,
      },
    };

    const secondData = {
      data: {
        ...secondSelected,
        RequestNotes: secondRequestNote,
        ExpectedCompletionDate: firstSelected.ExpectedCompletionDate,
        AssignedDate: firstSelected.AssignedDate,
        DaysToComplete: firstSelected.DaysToComplete,
      },
    };

    delete firstData.tableData;
    delete secondData.tableData;

    try {
      this.tableRef.current.setLoading(true);
      await table.putDWRequest(firstData);
      await table.putDWRequest(secondData);
      this.clearSelected();
      this.tableRef.current.forceUpdateTableData();
      Toaster.success('Dates swapped successfully');
    } catch (err) {
      Toaster.error(err?.message?.message ? err.message.message : err?.message);
    } finally {
      this.tableRef.current.setLoading(false);
    }
  };

  middleToolbar = () => {
    return (
      <div className="middleToolbar">
        <Button onClick={this.onHandleClickStampRequestMiddleToolbar}>
          Stamp Request
        </Button>
        <Button onClick={this.onHandleClickSwapDatesMiddleToolbar}>
          Swap Dates
        </Button>
      </div>
    );
  };

  isValidAction = (action) => {
    const { errors } = action;

    if (Array.isArray(errors) && errors.length > 0) {
      const { table } = this.props;
      const { currentSelectedItem } = this.state;

      return !errors.some(({ fieldName, values, message, operation }) => {
        let currentValue = null;
        let currentFieldName = null;

        if (fieldName) {
          currentValue = currentSelectedItem[fieldName];
          currentFieldName = fieldName;
        } else {
          currentValue = currentSelectedItem[action.fieldName];
          currentFieldName = action.fieldName;
        }

        if (table?.map.get(currentFieldName)?.has(currentValue)) {
          currentValue = table.map.get(currentFieldName).get(currentValue);
        }

        if (
          (operation === 'not' && !values.includes(currentValue)) ||
          (operation !== 'not' && values.includes(currentValue))
        ) {
          Toaster.error(message);
          return true;
        }

        return false;
      });
    }

    return true;
  };

  onDetailViewUpdate = () => {
    if (this.openedRow) {
      this.openedRow = false;
      setTimeout(() => {
        scrollToView(this.tableRef.current.detailViewRef.current, 60);
      });
    }
  };

  onCloseDetailView = () => {
    this.setState({
      currentSelectedItemId: null,
      currentSelectedMap: null,
      currentSelectedItem: null,
    });
  };

  onSaveDetailsView = async (e, data, create) => {
    const { table } = this.props;

    try {
      if (create) {
        await table.saveNewRequest(data);
        this.tableRef.current.forceUpdateTableData();
        return true;
      }

      await table.putDWRequest(data);
      this.tableRef.current.forceUpdateTableData();
      return true;
    } catch (err) {
      Toaster.error(err?.message?.message ? err.message.message : err?.message);
      return false;
    }
  };

  getSelectListFooter = () => {
    return (
      <CommonButton
        onClick={this.tableRef?.current?.onCloseSelectList}
        className="btn btn-secondary btn-lg"
      >
        Cancel
      </CommonButton>
    );
  };

  additionalSearchButton = () => {
    const { filterOpened, filters, isFiltering } = this.state;
    const { table } = this.props;
    const { getFilterBox } = table?.components?.search || {};
    return (
      <>
        <IconButton
          onClick={() => {
            this.setState({ filterOpened: !filterOpened });
          }}
        >
          <FilterListIcon style={{ color: isFiltering ? '#70bbfd' : '' }} />
        </IconButton>
        {getFilterBox &&
          getFilterBox(
            filterOpened,
            table?.type,
            filters,
            (newFilters) => {
              this.setState({ filterOpened: false, filters: newFilters });
              const tableFilters = prepareFilterForTable(newFilters);
              this.setState({
                isFiltering: Object.keys(tableFilters).length > 0,
              });
              this.tableRef.current.onChangeFilter({ filters: tableFilters });
            },
            () => {
              if (Object.keys(filters).length > 0) {
                this.tableRef.current.clearFiltersAndUpdateTable();
              }
              this.setState({
                filterOpened: false,
                filters: {},
                isFiltering: false,
              });
            },
            () => this.setState({ filterOpened: false })
          )}
      </>
    );
  };

  clearSelected = () => {
    this.setState({ selected: new Map() });
  };

  highlightRowIf = (rowData) => {
    const { currentSelectedItem } = this.state;

    if (currentSelectedItem?.Id === rowData.Id) {
      return { backgroundColor: '#E0FFFF' };
    }

    return null;
  };

  getData = async (params) => {
    this.applyDefaultParams(params);

    const { table } = this.props;

    return table.getData(params);
  };

  applyDefaultParams = (params) => {
    const { table } = this.props;

    if (
      !params.orderBy &&
      !isBoolean(params.desc) &&
      table.defaultSortDirection &&
      table.defaultOrderBy
    ) {
      params.orderBy = table.defaultOrderBy;
      params.desc = table.defaultSortDirection !== 'asc';
    }
  };

  onHandleDialogSave = () => {
    const { confirmData } = this.state;

    if (confirmData) {
      this.updateItemByActionPost(confirmData);
    }

    this.resetConfirmPopup();
  };

  onHandleDialogCancel = () => {
    this.resetConfirmPopup();
  };

  resetConfirmPopup = () => {
    this.setState({
      showConfirmPopup: false,
      confirmPopupMessage: '',
      confirmData: null,
    });
  };

  getUser = () => {
    const { user } = this.props;
    return user;
  };

  render() {
    const {
      table,
      allowedShowBtns,
      allowedCreate,
      allowedUpdate,
      allowedExport,
      applyActionsTo,
      countItemsWithAppliedActions,
      detailViewColsAsEditables,
    } = this.props;

    const {
      showActionsPopup,
      currentAction,
      showConfirmPopup,
      confirmPopupTitle,
      confirmPopupMessage,
    } = this.state;

    return (
      <Container className="request-page">
        {this.getHeaderComponent()}
        <Row>
          <MaterialTable
            ref={this.tableRef}
            tableProps={{
              title: 'Request',
              name: DWRequest,
              detailViewTittle: 'Request',
              detailViewMode: EDIT,
              searchOnLeftSide: true,
              additionalSearchButton: this.additionalSearchButton,
              selectListFooter: this.getSelectListFooter(),
              selectListSubmitLabel: 'Proceed',
              hideExport: !allowedExport,
              options: {
                pageSizeOptions: [5, 10, 20, 50, 100, 200],
                filtering: false,
                selection: true,
                showSelectAllCheckbox: false,
                selectionProps: (rowData) => {
                  const { selected } = this.state;
                  return {
                    disabled:
                      applyActionsTo === 'selected' &&
                      selected.size === countItemsWithAppliedActions &&
                      !selected.has(rowData.Id),
                    checked: selected.has(rowData.Id) || false,
                    onClick: (e) => this.handleCheckboxClick(e, rowData),
                  };
                },
                rowStyle: this.highlightRowIf,
              },
              create: {
                fields: table.createHeaders,
                saveLabel: 'Submit Request',
                title: 'Create Request',
                validationOnCreate: table.validateNewRecord,
              },
            }}
            extandedDetailView
            middleToolbar={allowedShowBtns && (() => this.middleToolbar())}
            columns={table.headers}
            remoteData
            hasAddButton={allowedCreate}
            fullScreenDetailView
            tableActions={this.getTableActions()}
            getData={this.getData}
            editRowAction
            onEditRowAction={this.onEditRowAction}
            onCreateRowAction={this.onCreateRowAction}
            report={!allowedUpdate}
            onRowClick={() => null} // disable row click, only edit button
            detailsView={{
              getDataFromView: true,
              cols: table.detailViewCols,
              colsAsTable: table.detailViewColsAsTable,
              colsAsAccordion: table.detailViewColsAsAccordion,
              detailViewColsAsEditables,
              idFieldName: '_id',
              onSave: this.onSaveDetailsView,
            }}
            onDetailViewUpdate={this.onDetailViewUpdate}
            onCloseDetailView={this.onCloseDetailView}
          />
          {showActionsPopup && (
            <ActionsDialog
              isOpen={showActionsPopup}
              title={currentAction.label}
              actionLabel="Save"
              requestType={currentAction.type}
              options={currentAction.options}
              handleAction={this.onHandleDialogSelectAction}
              handleClose={this.handlecloseActionsDialog}
            />
          )}
          {showConfirmPopup && (
            <ConfirmDialog
              isOpen={showConfirmPopup}
              title={confirmPopupTitle}
              message={confirmPopupMessage}
              actionLabel="Save"
              handleSave={this.onHandleDialogSave}
              handleClose={this.onHandleDialogCancel}
            />
          )}
        </Row>
      </Container>
    );
  }
}

Requests.propTypes = {
  allowedShowBtns: PropTypes.bool,
  allowedActionsBtns: PropTypes.bool,
  allowedCreate: PropTypes.bool,
  allowedUpdate: PropTypes.bool,
  allowedExport: PropTypes.bool,
  allowedUpdateStampRequest: PropTypes.bool,
  allowedUpdateSwapDates: PropTypes.bool,
  table: PropTypes.shape({
    components: PropTypes.shape({
      header: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      search: PropTypes.shape({
        getFilterBox: PropTypes.func,
      }),
    }).isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    getItem: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    saveNewRequest: PropTypes.func.isRequired,
    putDWRequest: PropTypes.func.isRequired,
    postDWStampRequest: PropTypes.func.isRequired,
    validateNewRecord: PropTypes.func.isRequired,
    validateStampRequest: PropTypes.func.isRequired,
    validateSwapDates: PropTypes.func.isRequired,
    headers: PropTypes.arrayOf(PropTypes.shape()),
    createHeaders: PropTypes.shape(),
    detailViewCols: PropTypes.shape(),
    map: PropTypes.shape(),
    detailViewColsAsTable: PropTypes.arrayOf(PropTypes.shape()),
    detailViewColsAsAccordion: PropTypes.arrayOf(PropTypes.shape()),
    defaultSortDirection: PropTypes.string,
    defaultOrderBy: PropTypes.string,
  }).isRequired,
  countItemsWithAppliedActions: PropTypes.number,
  applyActionsTo: PropTypes.string,
  detailViewColsAsEditables: PropTypes.shape(),
  user: PropTypes.shape(),
};

Requests.defaultProps = {
  allowedShowBtns: false,
  allowedActionsBtns: false,
  allowedCreate: false,
  allowedUpdate: false,
  allowedExport: false,
  allowedUpdateStampRequest: false,
  allowedUpdateSwapDates: false,
  countItemsWithAppliedActions: 0,
  applyActionsTo: null,
  detailViewColsAsEditables: null,
  user: {},
};

const mapStateToProps = (state) => {
  const accessDW = getAccessDesignWorkflow(state);
  const accessDWDesignerView = getAccessDWDesignerView(state);
  const accessDWDesignerManager = getAccessDWDesignerManager(state);
  const accessDWAdmin = getAccessDWAdmin(state);

  const isDesignerView = accessDWDesignerView.allowShow();

  const isDesignerManager =
    isDesignerView ||
    accessDWDesignerManager.allowShow() ||
    accessDWAdmin.allowShow();

  let allowedShowBtns = false;
  let allowedActionsBtns = false;
  let allowedCreate = false;

  if (accessDW.allowShow()) {
    allowedShowBtns = !isDesignerManager;
    allowedActionsBtns = isDesignerManager;
    allowedCreate = !isDesignerManager;
  }

  const allowedUpdateStampRequest = accessDW.allowUpdate();
  const allowedUpdateSwapDates = accessDW.allowUpdate();
  const allowedUpdate =
    accessDW.allowUpdate() ||
    accessDWDesignerView.allowUpdate() ||
    accessDWDesignerManager.allowUpdate() ||
    accessDWAdmin.allowUpdate();

  const table = isDesignerManager
    ? ConfigTables.designerManager
    : ConfigTables.accountCoordinator;

  let detailViewColsAsEditables = null;

  if (isDesignerView || isDesignerManager) {
    detailViewColsAsEditables = table.canEditFields;
  }

  return {
    table,
    allowedActionsBtns,
    allowedShowBtns,
    allowedCreate,
    allowedUpdate,
    allowedExport: allowedCreate,
    allowedUpdateStampRequest,
    allowedUpdateSwapDates,
    applyActionsTo: isDesignerManager ? 'selected' : null,
    countItemsWithAppliedActions: 1,
    detailViewColsAsEditables,
    user: getUser(state),
  };
};
export { Requests };
export default connect(mapStateToProps)(Requests);
