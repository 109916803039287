import { api } from './fetch';

const UPLOADE_ERROR_MESSAGE = 'Error occurred while uploading the file';

export const uploadFileToS3 = ({
  file,
  isValidationOff,
  progressCallback = null,
}) =>
  new Promise((resolve, reject) =>
    api
      .allowFileUpload({        
          contentType: file.type,
          fileName: file.name,
          extention: file.name.split('.').pop().toLowerCase(),
          isValidationOff,
          bucketType: undefined,
      })
      .then(({ data: { url, key } }) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url, true);
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(key);
          } else {
            reject(UPLOADE_ERROR_MESSAGE);
          }
        };
        xhr.onerror = () => reject(UPLOADE_ERROR_MESSAGE);
        if (progressCallback) {
          xhr.upload.onprogress = ({ lengthComputable, loaded, total }) => {
            progressCallback(lengthComputable ? loaded / total : -1);
          };
        }
        xhr.send(file);
      })
      .catch(() => {
        reject(UPLOADE_ERROR_MESSAGE);
      })
  );
