import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import './assets/style.scss';

function Toolbar({ onEvent, disabled }) {
  const onHandleClick = () => {
    onEvent({ action: 'custom' });
  };

  return (
    <div className="toolbar">
      <Button variant="text" onClick={onHandleClick} disabled={disabled}>
        custom...
      </Button>
    </div>
  );
}

Toolbar.propTypes = {
  disabled: PropTypes.bool,
  onEvent: PropTypes.func,
};

Toolbar.defaultProps = {
  disabled: false,
  onEvent: () => {},
};

export default connect()(Toolbar);
