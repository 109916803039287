import defaults from 'components/translations/defaults.json';

/**
 * Import all language files here and add them
 * to `supportedLanguages`
 */
const supportedLanguages = {
  defaults,
};

export { supportedLanguages };
export default {};
