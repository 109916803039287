import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

function Table(
  {
    tableProps,
    options,
    columns,
    data,
    components,
    actions,
    onRowClick,
    disable,
    isLoading,
  },
  ref
) {
  if (disable) return null;

  return (
    <div className="material-table">
      <MaterialTable
        tableRef={ref}
        style={{ width: '100%' }}
        data={data}
        {...tableProps}
        columns={columns}
        options={options}
        components={components}
        actions={actions}
        onRowClick={onRowClick}
        isLoading={isLoading}
      />
    </div>
  );
}

export default React.forwardRef(Table);

/* eslint-disable */
Table.propTypes = {
  actions: PropTypes.object,
  columns: PropTypes.array.isRequired,
  components: PropTypes.object,
  data: PropTypes.any.isRequired,
  disable: PropTypes.bool,
  onRowClick: PropTypes.func,
  options: PropTypes.object,
  tableProps: PropTypes.object,
  isLoading: PropTypes.bool,
};
/* eslint-enable */
