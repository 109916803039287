import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Form } from 'react-bootstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';

import {
  PaginationFormOption,
  PaginationFormSelect,
  PaginationInfo,
  PaginationSelectWrap,
  PaginationWrap,
} from './styles';

function TablePagination({
  dataLength,
  page,
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageSize,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  setPageSize,
  manualPageSize,
}) {
  const arrayPageIndex =
    pageIndex - 2 < 0
      ? pageOptions.slice(0, pageIndex + 3)
      : pageOptions.slice(pageIndex - 2, pageIndex + 3);

  return (
    <PaginationWrap>
      <Pagination>
        <Pagination.First
          disabled={!canPreviousPage}
          className="pagination__item--arrow"
          onClick={() => gotoPage(0)}
        >
          <ChevronDoubleLeftIcon />
        </Pagination.First>
        <Pagination.Prev
          disabled={!canPreviousPage}
          className="pagination__item--arrow"
          onClick={previousPage}
        >
          <ChevronLeftIcon />
        </Pagination.Prev>
        {arrayPageIndex.map((i) => (
          <Pagination.Item
            key={i}
            active={pageIndex === i}
            onClick={() => gotoPage(i)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={!canNextPage}
          className="pagination__item--arrow"
          onClick={nextPage}
        >
          <ChevronRightIcon />
        </Pagination.Next>
        <Pagination.Last
          disabled={!canNextPage}
          className="pagination__item--arrow"
          onClick={() => gotoPage(pageOptions.length - 1)}
        >
          <ChevronDoubleRightIcon />
        </Pagination.Last>
      </Pagination>
      <PaginationInfo className="pagination-info">
        Showing {pageSize * pageIndex + 1} to{' '}
        {pageSize * pageIndex + page.length} of {dataLength}
      </PaginationInfo>
      {manualPageSize.length > 1 && (
        <PaginationSelectWrap as={Form.Group}>
          <PaginationFormSelect
            className="pagination-select"
            name="select"
            id="exampleSelect"
            value={pageSize}
            onChange={(event) => {
              setPageSize(Number(event.target.value));
            }}
          >
            {manualPageSize.map((item) => (
              <PaginationFormOption key={item} value={item}>
                Show {item}
              </PaginationFormOption>
            ))}
          </PaginationFormSelect>
        </PaginationSelectWrap>
      )}
    </PaginationWrap>
  );
}

TablePagination.propTypes = {
  dataLength: PropTypes.number.isRequired,
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gotoPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  manualPageSize: PropTypes.arrayOf(PropTypes.number),
};

TablePagination.defaultProps = {
  manualPageSize: [10, 20, 30, 40],
};

export default TablePagination;
