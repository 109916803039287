/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';
import { CardBody } from 'reactstrap';

import { toast } from 'react-toastify';

import MaterialTable from 'components/MaterialTable';
import { api } from 'utils/fetch';

import {
  setAPIInspectorTables,
  setAPIInspectorHeader,
  setAPIInspectorHidedHeader,
  setAPIInspectorSelectedTableName,
} from 'redux/actions/dataViewActions';

import loaderActions from 'redux/actions/loaderActions';

import CircularProgress from 'components/CircularProgress';

import { isObject } from 'utils';

import {
  APIINSPECTOR_COLUMNS_FILTER_NAME,
  TABLE_LOAD_ERROR,
} from 'containers/ApiInspector/constants';

import Cache from 'utils/Cache';

import { getTitles, makeUpperCaseKeysInObject, objectToString } from './utils';

import './assets/style.scss';

class Table extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef(null);

    this.detailViewCols = [];

    this.state = {
      loading: true,
      headers: [],
      viewedTableNames: [],
      errorMessage: null,
    };

    this.cache = {
      key: null,
      data: null,
      count: null,
    };
  }

  componentDidMount() {
    this.getTables(true);
  }

  componentDidUpdate(prevProps) {
    const { source } = this.props;

    if (prevProps.source !== source) {
      this.getTables(true);
      return;
    }

    const { selectedTableName, tables } = this.props;

    if (
      prevProps.selectedTableName !== selectedTableName &&
      selectedTableName &&
      tables.length
    ) {
      this.setState({
        errorMessage: null,
        loading: true,
      });

      const payload = {
        Page: 1,
        PageSize: 1,
      };

      this.getItems(source, selectedTableName, payload);
    }
  }

  getData = async (params) => {
    const { additionalFilter, selectedTableName, source } = this.props;

    const { filters, orderBy, desc, ...otherParams } = params;

    const newFilters = Object.keys(filters || {}).reduce((acc, key) => {
      acc[`${key}`] = filters[key];
      return acc;
    }, {});

    const additionalParam = makeUpperCaseKeysInObject({
      ...otherParams,
    });

    const newParams = {
      sortBy: orderBy ? orderBy.charAt(0).toLowerCase() + orderBy.slice(1) : '',
      sortOrder: desc ? -1 : 1,
      ...additionalParam,
    };

    const formattedNewFilters = Object.keys(newFilters).map((item) => {
      return {
        name: item,
        value: newFilters[item],
      };
    });

    if (additionalFilter) {
      formattedNewFilters.push({
        name: additionalFilter.key,
        value: additionalFilter.value,
      });
    }

    const { additionalFilter: additionalFilterState } = this.state;

    if (additionalFilterState) {
      formattedNewFilters.push({
        name: additionalFilterState.key,
        value: additionalFilterState.value,
      });
    }

    const payload = {
      ...newParams,
      filters: formattedNewFilters,
      skipMetadata: true,
      /* IncludeColumns: ['columnName'], */
    };

    if (payload?.filters.length > 0) {
      payload.Page = 1;
    }

    const { items, count } = await this.getItems(
      source,
      selectedTableName,
      payload
    );

    return {
      count,
      data: items,
    };
  };

  getItems = async (source, selectedTableName, payload) => {
    if (this.loadItems) return null;

    this.loadItems = true;

    let items = [];
    let count = 0;
    let metadata = null;

    try {
      const cacheKey = `${source}_${selectedTableName}_${JSON.stringify(
        payload
      )}`;

      if (this.cache.key === cacheKey) {
        items = this.cache.data;
        count = this.cache.count;
        metadata = this.cache.metadata;
      } else {
        const result = await api.getApiInspectorSourceTableMetadata(
          source,
          selectedTableName,
          {
            data: payload,
          }
        );

        if (!payload.skipMetadata) {
          metadata = result.data.metadata;

          const { options, setHeader } = this.props;

          const { headers: formattedHeaders } = getTitles(metadata, {
            filteringDisabled: options.filteringDisabled,
            sortingDisabled: options.sortingDisabled,
          });
          setHeader(selectedTableName, formattedHeaders);
          this.setState({
            headers: formattedHeaders,
          });

          await this.getTableMetadata(selectedTableName, result);
        }

        for (let i = 0, n = result.data.data.length; i < n; i += 1) {
          const props = result.data.data[i];

          if (!Array.isArray(props)) continue;

          const formattedItem = {};

          let j = -1;
          const m = props.length;

          while (++j < m) {
            const prop = props[j];

            formattedItem[prop.name] = prop.value;

            if (isObject(prop.value)) {
              formattedItem[prop.name] = objectToString(prop.value);
            }

            if (
              prop.value === null ||
              (typeof prop.value !== 'boolean' && !prop.value)
            ) {
              formattedItem[prop.name] = '—';
            }
          }

          items.push(formattedItem);
        }

        count = result.data.totalCount ?? items.length;

        this.cache.key = cacheKey;
        this.cache.data = items;
        this.cache.count = count;

        if (!payload.skipMetadata) {
          this.cache.metadata = metadata;
        }
      }
    } catch (e) {
      this.checkError(e);
    }

    this.loadItems = false;

    return { items, count, metadata };
  };

  clearFiltersAndUpdateTable = () => {
    this.tableRef.current.clearFiltersAndUpdateTable();
  };

  setLoading = (loading = true) => {
    this.tableRef.current.setLoading(loading);
  };

  setLoadingTable = (loading = true) => {
    this.tableRef.current.setLoadingTable(loading);
  };

  getTables = async (forceUpdate = false) => {
    const {
      setTables,
      setHeader,
      tables,
      headers,
      setHidedHeader,
      setSelectedTableName,
      options,
      onEvent,
      source,
    } = this.props;

    this.setState({
      loading: true,
      errorMessage: null,
    });

    if (forceUpdate || !tables.length) {
      try {
        const result = await api.getApiInspectorSourceTables(source);

        const tableName = result.data[0].name;

        const payload = {
          Page: 1,
          PageSize: 1,
        };

        await this.getItems(source, tableName, payload);

        const savedHidedColumns =
          Cache.getValue(APIINSPECTOR_COLUMNS_FILTER_NAME) || {};

        setHidedHeader(savedHidedColumns);

        setSelectedTableName(tableName);
        setTables(result.data);
      } catch (e) {
        this.checkError(e);
        setSelectedTableName(null);
        setHeader(null);
        setHidedHeader({});
        setTables([]);
      } finally {
        // this.tableRef.current.setLoading(false);

        this.setState({
          loading: false,
        });

        onEvent({ action: 'loaded' });
      }

      return;
    }

    const tableName = tables[0].name;

    let formattedHeaders;

    if (headers[tableName]) {
      ({ headers: formattedHeaders } = getTitles(headers[tableName], {
        filteringDisabled: options.filteringDisabled,
        sortingDisabled: options.sortingDisabled,
      }));
      this.setState({
        headers: formattedHeaders,
      });
    } else {
      const payload = {
        Page: 1,
        PageSize: 1,
      };

      await this.getItems(source, tableName, payload);
    }

    setSelectedTableName(tableName);

    this.setState({
      loading: false,
    });

    onEvent({ action: 'loaded' });
  };

  getTableMetadata = async (tableName, tableMetaNames) => {
    const { headers, onEvent } = this.props;

    onEvent({ action: 'loading' });

    this.tableRef.current?.setLoadingTable(true);

    this.setSelectedTable(headers[tableName] ?? tableMetaNames, tableName);

    this.setState({ loading: false });

    this.tableRef.current?.setLoadingTable(false);

    onEvent({ action: 'loaded' });
  };

  setSelectedTable = (
    tableMetaNames,
    tableName,
    forceUpdateTableData,
    additionalFilter = null
  ) => {
    const callback =
      forceUpdateTableData ||
      (() => {
        setTimeout(() => {
          this.tableRef.current?.clearFiltersAndUpdateTable();
        }, 0);
      });

    const {
      hidedColumns,
      setHeader,
      setSelectedTableName,
      options,
    } = this.props;

    const { headers } = tableMetaNames.data
      ? getTitles(tableMetaNames.data.metadata, {
          filteringDisabled: options.filteringDisabled,
          sortingDisabled: options.sortingDisabled,
          hidedColumns: hidedColumns[tableName],
        })
      : { headers: tableMetaNames }; // eslint-disable-line

    this.setState({ headers, additionalFilter }, callback);

    setHeader(tableName, headers);

    setSelectedTableName(tableName);
  };

  goTo = async ({ referenceName, value, col, row }) => {
    this.tableRef.current.setLoadingTable(true);

    const { tables, headers, selectedTableName, onEvent, source } = this.props;

    onEvent({ action: 'loading' });

    const { viewedTableNames } = this.state;

    if (tables.some((table) => table.name === referenceName)) {
      this.setState({
        isLoadingTable: true,
        viewedTableNames: [...viewedTableNames, selectedTableName],
      });

      let tableMetaNames = headers[referenceName];

      if (!tableMetaNames) {
        const payload = {
          Page: 1,
          PageSize: 1,
        };

        const result = await this.getItems(source, referenceName, payload);

        tableMetaNames = result.metadata;
      }

      const idColumn = tableMetaNames.find(
        (currentCol) => String(currentCol.type).trim().toLowerCase() === 'id'
      );

      this.setSelectedTable(
        { data: { metadata: tableMetaNames } },
        referenceName,
        () => {
          this.setState({ isLoadingTable: false }, () => {
            this.tableRef.current.clearFiltersAndUpdateTable();
            this.tableRef.current.setLoadingTable(false);
          });
        },
        { key: idColumn?.name || col.field || 'Id', value }
      );
    }
  };

  revert = async () => {
    this.tableRef.current.setLoadingTable(true);

    const { headers, source } = this.props;
    const { viewedTableNames } = this.state;

    const tableName = viewedTableNames.pop();

    this.setState({ isLoadingTable: true });

    let tableMetaNames = headers[tableName];

    if (!tableMetaNames) {
      const payload = {
        Page: 1,
        PageSize: 1,
      };

      const result = await this.getItems(source, tableName, payload);

      tableMetaNames = result.metadata;
    }

    this.setSelectedTable(
      { data: { metadata: tableMetaNames } },
      tableName,
      () => {
        this.setState(
          {
            isLoadingTable: false,
            viewedTableNames: [...viewedTableNames],
          },
          () => {
            this.tableRef.current.clearFiltersAndUpdateTable();
            this.tableRef.current.setLoadingTable(false);
          }
        );
      }
    );
  };

  checkError = (e) => {
    toast.error(e.message?.message || TABLE_LOAD_ERROR);
    this.setState({ errorMessage: TABLE_LOAD_ERROR });
  };

  render() {
    const { headers: sHeaders, loading, errorMessage } = this.state;

    if (loading) {
      return (
        <Card>
          <CardBody>
            <div className="api-inspector-progress">
              <CircularProgress content="" />
            </div>
          </CardBody>
        </Card>
      );
    }

    if (errorMessage) {
      return (
        <Card>
          <CardBody>
            <div className="api-inspector-progress">{errorMessage}</div>
          </CardBody>
        </Card>
      );
    }

    const { tables, headers, selectedTableName, setSelectedTable } = this.props;

    const currentHeaders = headers[selectedTableName] || sHeaders;

    const { viewedTableNames } = this.state;

    return (
      <div className="api-inspector-page">
        {tables.length && sHeaders.length ? (
          <MaterialTable
            ref={this.tableRef}
            tableProps={{
              title: '',
              tables,
              setSelectedTable,
              name: 'ApiInspector',
              selectedTableName,
              actions: {
                goTo: this.goTo,
                revert: this.revert,
              },
              showRevertTool: viewedTableNames.length > 0,
              showSearchBar: false,
              hideDefaultMenu: true,
            }}
            columns={currentHeaders}
            remoteData
            report
            getData={this.getData}
            detailsView={{
              cols: this.detailViewCols,
              idFieldName: '_id',
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({
  dataView: {
    apiInspector: { tables, headers, hidedColumns, selectedTableName },
  },
}) => {
  return {
    tables,
    headers,
    hidedColumns,
    selectedTableName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTables: (sfTables) => {
    dispatch(setAPIInspectorTables(sfTables));
  },
  setHeader: (tableName, sfHeader) => {
    dispatch(setAPIInspectorHeader(tableName, sfHeader));
  },
  setHidedHeader: (sfHidedColumns) => {
    dispatch(setAPIInspectorHidedHeader(sfHidedColumns));
  },
  setTableLoading: (loading) => {
    dispatch(loaderActions.setTableLoading(loading));
  },
  setHidedColumns: (sfHidedColumns) => {
    dispatch(setAPIInspectorHidedHeader(sfHidedColumns));
  },
  setSelectedTableName: (name) => {
    dispatch(setAPIInspectorSelectedTableName(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Table);

Table.propTypes = {
  source: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.object,
  header: PropTypes.func.isRequired,
  hidedHeader: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tables: PropTypes.array,
  setTableLoading: PropTypes.func.isRequired,
  onEvent: PropTypes.func,
  showRevertTool: PropTypes.bool,
  selectedTableName: PropTypes.string,
  additionalFilter: PropTypes.object,
  setTables: PropTypes.func,
  setSelectedTableName: PropTypes.func,
  setSelectedTable: PropTypes.func,
  options: PropTypes.object,
};

Table.defaultProps = {
  source: '',
  headers: {},
  tables: [],
  onEvent: () => {},
  showRevertTool: false,
  selectedTableName: '',
  additionalFilter: null,
  setTables: () => {},
  setSelectedTableName: () => {},
  setSelectedTable: () => {},
  options: {},
};
