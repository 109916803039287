/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import { columns } from './_utils';

import './style.scss';
import { api } from 'utils/fetch';

function ReclaimModalView({
  handleClose,
  open = false,
  selectedUser,
  onOpenWarning,
  onSetReclaimingUser,
  reclaimingUser,
}) {
  if (!open) return null;

  //! Should be refactored because of multiple violation of rules-of-hooks(https://dmitripavlutin.com/react-hooks-mistakes-to-avoid/)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [users, setUsers] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState(true);

  // fill fields by exist info
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    api.getAllUsers().then((res) => {
      // eslint-disable-next-line no-shadow
      const users = res.data.data
        .filter((user) => !!user._id && user.userId !== selectedUser.userId)
        .map((user) => ({ ...user, id: user._id }));
      setUsers(users);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      maxWidth="md"
      className="sm-edit"
    >
      <DialogTitle>Reclaim Window</DialogTitle>
      <DialogContent>
        <div style={{ height: 150 }}>
          <h3>Selected User:</h3>
          <DataGrid
            autoPageSize
            hideFooter
            autoHeight
            rows={[selectedUser]}
            columns={columns}
            checkboxSelection={false}
            hideFooterSelectedRowCount
            hideFooterPagination
            disableMultipleSelection
            disableSelectionOnClick
          />
        </div>
        <h3>Other Users:</h3>
        <div>
          <DataGrid
            autoHeight
            loading={loading}
            rows={users}
            columns={columns}
            checkboxSelection={false}
            hideFooterSelectedRowCount
            disableMultipleSelection
            onRowSelected={(row) => onSetReclaimingUser(row.data)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            reclaimingUser &&
            Object.keys(reclaimingUser).length === 0 &&
            reclaimingUser.constructor === Object
          }
          onClick={onOpenWarning}
          color="primary"
          variant="contained"
        >
          Reclaim
        </Button>
        <Button onClick={handleClose} color="secondary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReclaimModalView;
