/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getAcl } from 'redux/selectors/selectors';

import { urls } from '../../routes';
import auth from '../../redux/actions/authActions';
import AboutDialog from './TopBarMenu/AboutDialog';
import FeedbackForm from './TopBarMenu/FeedbackForm';
import ProfileDetail from './TopBarMenu/ProfileDetails/index';
import PasswordDialog from './TopBarMenu/ProfileDetails/PasswordDialog';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

/* eslint-disable jsx-a11y/click-events-have-key-events */
class TopbarProfile extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
      isProfileModalOpened: false,
      isPwdModalOpened: false,
      isAboutModalOpened: false,
      isFeedbackModalOpened: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  triggerModal = (name) => {
    this.setState((prevState) => ({ [name]: !prevState[name] }));
  };

  render() {
    const {
      collapse,
      isProfileModalOpened,
      isPwdModalOpened,
      isAboutModalOpened,
      isFeedbackModalOpened,
    } = this.state;

    const { user, signOut, accountName, productName } = this.props;
    const firstName = user ? user.firstName : '';
    const lastName = user ? user.lastName : '';

    return (
      <div className="topbar__profile">
        <ProfileDetail
          isOpen={isProfileModalOpened}
          onClose={() => this.triggerModal('isProfileModalOpened')}
        />
        <PasswordDialog
          isOpen={isPwdModalOpened}
          onClose={() => this.triggerModal('isPwdModalOpened')}
        />
        <AboutDialog
          isOpen={isAboutModalOpened}
          onClose={() => this.triggerModal('isAboutModalOpened')}
          detailed
          accountName={accountName}
          productName={productName}
        />
        <FeedbackForm
          user={user}
          isOpen={isFeedbackModalOpened}
          onClose={() => {
            this.triggerModal('isFeedbackModalOpened');
          }}
        />
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">
            {firstName} {lastName}
          </p>
          <DownIcon className="topbar__icon" />
        </button>

        {collapse && (
          <ul className="dropdown-menu" style={{ display: 'block' }}>
            <li>
              <a
                href="#/"
                role="link"
                tabIndex={-1}
                onClick={() => this.triggerModal('isAboutModalOpened')}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#/"
                role="link"
                tabIndex={-1}
                onClick={() => this.triggerModal('isProfileModalOpened')}
              >
                Profile
              </a>
            </li>
            <li>
              <a
                href="#/"
                role="link"
                tabIndex={-1}
                onClick={() => this.triggerModal('isPwdModalOpened')}
              >
                Change Password
              </a>
            </li>
            <li>
              <a
                href="#/"
                role="link"
                tabIndex={0}
                onClick={() => this.triggerModal('isFeedbackModalOpened')}
              >
                Support and feedback
              </a>
            </li>
            <li role="separator" className="divider" />
            <li>
              <a href={urls.pcdesigner}>PCDesigner</a>
            </li>
            <li>
              <a
                href="/signout"
                onClick={(e) => {
                  e.preventDefault();
                  signOut();
                }}
              >
                Sign out
              </a>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    auth: { user },
  } = state;

  const accountName = getAcl(state).getAccess('ALLOW_SMART_UI').allowShow()
    ? 'clawOS'
    : 'clawOS Pro';

  return {
    user,
    accountName,
    productName: 'Admin Dashboard',
  };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(auth.signOut()),
});

TopbarProfile.propTypes = {
  user: PropTypes.shape({}),
  signOut: PropTypes.func,
  productName: PropTypes.string,
};

TopbarProfile.defaultProps = {
  user: {},
  signOut: () => {},
  productName: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(TopbarProfile);
