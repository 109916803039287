/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';
import { CardBody } from 'reactstrap';

import { toast } from 'react-toastify';

import MaterialTable from 'components/MaterialTable';
import { api } from 'utils/fetch';

import {
  setSourceTables,
  setSourceTableHeader,
  setSourceHidedHeader,
  setSourceSelectedTableName,
} from 'redux/actions/dataViewActions';

import loaderActions from 'redux/actions/loaderActions';

import CircularProgress from 'components/CircularProgress';

import { isBoolean, isNumeric, isString } from 'utils';

import {
  REPORTS_CUSTOM,
  REPORTS_CUSTOM_COLUMNS_FILTER_NAME,
  TABLE_LOAD_ERROR,
} from 'containers/Reports/Custom/constants';

import Cache from 'utils/Cache';

import Breadcrumbs from 'components/Breadcrumbs';

import {
  convertHierarchyValue,
  convertReferenceValue,
  getTitles,
  makeUpperCaseKeysInObject,
} from './utils';

import './assets/style.scss';

class Table extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef(null);

    this.detailViewCols = [];

    this.state = {
      loading: true,
      headers: [],
      errorMessage: null,
    };

    this.viewedTables = [];
    this.breadcrumbs = [];

    this.internalFilter = null;
  }

  componentDidMount() {
    this.getTables(true);
  }

  componentDidUpdate(prevProps) {
    const { source, options } = this.props;

    if (prevProps.source !== source) {
      this.viewedTables.length = 0;
      this.breadcrumbs.length = 0;
      this.internalFilter = null;
      this.getTables(true);
      return;
    }

    const { selectedTableName, tables, onEvent } = this.props;

    if (
      prevProps.selectedTableName !== selectedTableName &&
      selectedTableName &&
      tables.length
    ) {
      onEvent({ action: 'loading' });

      this.setState({
        errorMessage: null,
        loading: true,
      });

      if (options.isHierarchy) {
        this.viewedTables.length = 0;
        this.breadcrumbs.length = 0;
        this.internalFilter = null;
      }

      this.getTableMetadata(selectedTableName);
    }
  }

  getData = async (params) => {
    const { selectedTableName, source, options } = this.props;

    let path = options.isHierarchy ? this.getPath() : '';

    if (path) {
      path += '.';
    }

    const { filters, orderBy, desc, ...otherParams } = params;

    const newFilters = Object.keys(filters || {}).reduce((acc, key) => {
      acc[`${key}`] = this.getConvertedValue(key, filters[key]);
      return acc;
    }, {});

    const additionalParam = makeUpperCaseKeysInObject({
      ...otherParams,
    });

    const newParams = {
      sortBy: orderBy ? `${path}${orderBy}` : '',
      sortOrder: desc ? -1 : 1,
      ...additionalParam,
    };

    const formattedNewFilters = [];

    Object.keys(newFilters).forEach((item) => {
      if (!isString(newFilters[item]) || !!newFilters[item]) {
        formattedNewFilters.push({
          name: `${path}${item}`,
          value: this.getConvertedValue(item, newFilters[item]),
        });
      }
    });

    if (this.internalFilter) {
      formattedNewFilters.push({
        name: `${path}${this.internalFilter.key}`,
        value: this.getConvertedValue(
          this.internalFilter.key,
          this.internalFilter.value
        ),
      });
    }

    try {
      const data = {
        ...newParams,
        filters: formattedNewFilters,
        skipMetadata: true,
        path: this.getPath(),
      };

      if (formattedNewFilters.length > 0) {
        data.filters = formattedNewFilters;
      }

      const result = await api.getReportGeneratorSourceTableMetadata(
        source,
        selectedTableName,
        {
          data,
        }
      );

      this.updateHeadersIfNeed(result);

      return this.formattingData(result);
    } catch (e) {
      this.checkError(e);
    }
  };

  clearFiltersAndUpdateTable = () => {
    this.tableRef.current.clearFiltersAndUpdateTable();
  };

  setLoading = (loading = true) => {
    this.tableRef.current.setLoading(loading);
  };

  setLoadingTable = (loading = true) => {
    this.tableRef.current.setLoadingTable(loading);
  };

  getTables = async (forceUpdate = false) => {
    const {
      setTables,
      setHeader,
      tables,
      headers,
      setHidedHeader,
      setSelectedTableName,
      options,
      onEvent,
      source,
    } = this.props;

    this.setState({
      loading: true,
      errorMessage: null,
    });

    if (forceUpdate || !tables.length) {
      try {
        const result = await api.getReportGeneratorSourceTables(source);

        const tableName = result.data[0].name;

        const tableMetaData = await api.getReportGeneratorSourceTableMetadata(
          source,
          tableName,
          {
            data: {
              path: this.getPath(),
            },
          }
        );

        const { headers: formattedHeaders } = getTitles(
          tableMetaData.data.metadata,
          {
            filteringDisabled: options.filteringDisabled,
            sortingDisabled: options.sortingDisabled,
          }
        );

        const savedHidedColumns =
          Cache.getValue(REPORTS_CUSTOM_COLUMNS_FILTER_NAME) || {};

        setHeader(tableName, formattedHeaders);
        setHidedHeader(savedHidedColumns);

        this.setState({
          headers: formattedHeaders,
        });

        setSelectedTableName(tableName);
        setTables(result.data);
      } catch (e) {
        this.checkError(e);
        setSelectedTableName(null);
        setHeader(null);
        setHidedHeader({});
        setTables([]);
      } finally {
        // this.tableRef.current.setLoading(false);

        this.setState({
          loading: false,
        });

        onEvent({ action: 'loaded' });
      }

      return;
    }

    const tableName = tables[0].name;

    let formattedHeaders;

    if (headers[tableName]) {
      ({ headers: formattedHeaders } = getTitles(headers[tableName], {
        filteringDisabled: options.filteringDisabled,
        sortingDisabled: options.sortingDisabled,
      }));
    } else {
      const result = await api.getReportGeneratorSourceTableMetadata(
        source,
        tableName,
        {
          data: {
            path: this.getPath(),
          },
        }
      );

      ({ headers: formattedHeaders } = getTitles(result.data.metadata, {
        filteringDisabled: options.filteringDisabled,
        sortingDisabled: options.sortingDisabled,
      }));
      setHeader(tableName, formattedHeaders);
    }

    setSelectedTableName(tableName);

    this.setState({
      headers: formattedHeaders,
      loading: false,
    });

    onEvent({ action: 'loaded' });
  };

  getTableMetadata = async (tableName, forceUpdate = false) => {
    this.tableRef.current?.setLoadingTable(true);

    const { headers, source, setHeader } = this.props;

    let tableMetaNames;

    if (!forceUpdate && headers[tableName]) {
      tableMetaNames = headers[tableName];
    } else {
      try {
        tableMetaNames = await api.getReportGeneratorSourceTableMetadata(
          source,
          tableName,
          {
            data: {
              path: this.getPath(),
              page: 1,
              pageSize: 1,
            },
          }
        );
      } catch (e) {
        tableMetaNames = {
          data: {
            data: [],
            metadata: [],
            totalCount: 0,
          },
        };

        setHeader(tableName, []);

        this.checkError(e);
      }
    }

    this.setSelectedTable(tableMetaNames, tableName);

    this.endLoad();
  };

  setSelectedTable = (tableMetaNames, tableName, forceUpdateTableData) => {
    const callback =
      forceUpdateTableData ||
      (() => {
        setTimeout(() => {
          this.tableRef.current?.clearFiltersAndUpdateTable();
        }, 0);
      });

    const {
      hidedColumns,
      setHeader,
      setSelectedTableName,
      options,
    } = this.props;

    const { headers } = tableMetaNames.data
      ? getTitles(tableMetaNames.data.metadata, {
          filteringDisabled: options.filteringDisabled,
          sortingDisabled: options.sortingDisabled,
          hidedColumns: hidedColumns[tableName],
        })
      : { headers: tableMetaNames }; // eslint-disable-line

    this.setState({ headers }, callback);

    setHeader(tableName, headers);

    setSelectedTableName(tableName);
  };

  goTo = async ({ referenceName, value, col }) => {
    this.startLoad();

    const { options, selectedTableName, setSelectedTableName } = this.props;

    if (this.breadcrumbs.length === 0) {
      this.breadcrumbs.push({
        id: selectedTableName,
        label: selectedTableName,
      });
    }

    if (options.isHierarchy) {
      this.viewedTables.push(col.field);
      this.breadcrumbs.push({ id: col.field, label: col.field });
      await this.getTableMetadata(selectedTableName, true);
      return;
    }

    this.viewedTables.push(selectedTableName);
    this.breadcrumbs.push({ id: referenceName, label: referenceName });

    this.internalFilter = { key: col.field, value };

    setSelectedTableName(referenceName);
  };

  revert = async () => {
    this.startLoad();

    const selectedTableName = this.viewedTables.pop();
    this.breadcrumbs.pop();

    if (this.breadcrumbs.length === 1) {
      this.breadcrumbs.length = 0;
    }

    const { options, setSelectedTableName } = this.props;

    this.internalFilter = null;

    if (options.isHierarchy) {
      // eslint-disable-next-line react/destructuring-assignment
      await this.getTableMetadata(this.props.selectedTableName, true);
      return;
    }

    setSelectedTableName(selectedTableName);
  };

  checkError = (e) => {
    toast.error(e.message?.message || TABLE_LOAD_ERROR);
    this.setState({ errorMessage: TABLE_LOAD_ERROR });
  };

  updateHeadersIfNeed = (result) => {
    const {
      headers,
      selectedTableName,
      setHeader,
      options,
      hidedColumns,
    } = this.props;

    if (
      !headers[selectedTableName] ||
      result.data.metadata.length !== headers[selectedTableName].length
    ) {
      const { headers: formattedHeaders } = getTitles(result.data.metadata, {
        filteringDisabled: options.filteringDisabled,
        sortingDisabled: options.sortingDisabled,
        hidedColumns: hidedColumns[selectedTableName],
      });

      setHeader(selectedTableName, formattedHeaders);

      this.setState({
        headers: formattedHeaders,
      });
    }
  };

  formattingData = (result) => {
    const { options } = this.props;

    let convertValue = null;

    if (options.isHierarchy) {
      convertValue = convertHierarchyValue;
    } else {
      convertValue = convertReferenceValue;
    }

    const items = [];
    let count = 0;

    for (let i = 0, n = result.data.data.length; i < n; i += 1) {
      const props = result.data.data[i];

      if (!Array.isArray(props)) continue;

      const formattedItem = {};

      let j = -1;
      const m = props.length;

      while (++j < m) {
        const prop = props[j];

        formattedItem[prop.name] = convertValue(prop.value);
      }

      formattedItem.internalIndex = i;

      items.push(formattedItem);
      count++;
    }

    return {
      count: result.data.totalCount || count,
      data: items,
    };
  };

  getPath = () => {
    return this.viewedTables.join('.');
  };

  startLoad = () => {
    const { onEvent } = this.props;

    this.tableRef.current.setLoadingTable(true);

    onEvent({ action: 'loading' });

    this.setState({
      errorMessage: null,
      loading: true,
      isLoadingTable: true,
    });
  };

  endLoad = () => {
    const { onEvent } = this.props;

    this.setState({ loading: false });

    this.tableRef.current?.setLoadingTable(false);

    onEvent({ action: 'loaded' });
  };

  getConvertedValue = (name, value) => {
    const { headers, selectedTableName } = this.props;

    const { headers: sHeaders } = this.state;

    const currentHeaders = headers[selectedTableName] || sHeaders;

    const header = currentHeaders.find((item) => item.field === name);

    if (header) {
      switch (true) {
        case isNumeric(value):
          return +value;
        case isBoolean(value):
          return !!value;
        default:
          break;
      }
    }

    return value;
  };

  handleClickBreadcrumbs = async (item) => {
    this.startLoad();

    const filteredBreadcrumbs = [];

    let i = -1;
    let n = this.breadcrumbs.length;

    while (++i < n) {
      const table = this.breadcrumbs[i];

      filteredBreadcrumbs.push(table);

      if (table.id === item.id) {
        break;
      }
    }

    this.breadcrumbs = filteredBreadcrumbs;

    if (this.breadcrumbs.length === 1) {
      this.breadcrumbs.length = 0;
      this.viewedTables.length = 0;
    } else {
      const filteredViewedTables = [];

      i = -1;
      n = this.viewedTables.length;

      while (++i < n) {
        const table = this.viewedTables[i];

        filteredViewedTables.push(table);

        if (table === item.id) {
          break;
        }
      }

      this.viewedTables = filteredViewedTables;
    }

    const { selectedTableName, setSelectedTableName, options } = this.props;

    if (options.isHierarchy) {
      await this.getTableMetadata(selectedTableName, true);
      return;
    }

    this.internalFilter = null;

    setSelectedTableName(item.id);
  };

  middleToolbar = () => {
    if (this.breadcrumbs.length === 0) return null;

    return (
      <Breadcrumbs
        items={this.breadcrumbs}
        onClick={this.handleClickBreadcrumbs}
      />
    );
  };

  render() {
    const { headers: sHeaders, loading, errorMessage } = this.state;

    if (loading) {
      return (
        <Card>
          <CardBody>
            <div className="reports-custom-progress">
              <CircularProgress content="" />
            </div>
          </CardBody>
        </Card>
      );
    }

    if (errorMessage) {
      return (
        <Card>
          <CardBody>
            <div className="reports-custom-progress">{errorMessage}</div>
          </CardBody>
        </Card>
      );
    }

    const {
      tables,
      headers,
      selectedTableName,
      setSelectedTable,
      hidedColumns,
      options,
    } = this.props;

    const currentHeaders = headers[selectedTableName] || sHeaders;

    return (
      <div className="reports-custom-page">
        {tables.length && sHeaders.length ? (
          <MaterialTable
            ref={this.tableRef}
            tableProps={{
              title: '',
              tables,
              setSelectedTable,
              name: 'SourceTable',
              selectedTableName,
              hidedColumns: hidedColumns?.[selectedTableName] || {},
              actions: {
                goTo: this.goTo,
                revert: this.revert,
              },
              showRevertTool: this.viewedTables.length > 0,
              showSearchBar: false,
              hideDefaultMenu: true,
              useHierarchy: options.isHierarchy,
              useReference: !options.isHierarchy,
            }}
            middleToolbar={this.middleToolbar}
            columns={currentHeaders}
            remoteData
            report
            getData={this.getData}
            detailsView={{
              cols: this.detailViewCols,
              idFieldName: '_id',
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ dataView }) => {
  const reportsCustom = dataView?.[REPORTS_CUSTOM];

  return {
    tables: reportsCustom?.tables || [],
    headers: reportsCustom?.headers || {},
    hidedColumns: reportsCustom?.hidedColumns || {},
    selectedTableName: reportsCustom?.selectedTableName || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTables: (tables) => {
    dispatch(setSourceTables(REPORTS_CUSTOM, tables));
  },
  setHeader: (tableName, header) => {
    dispatch(setSourceTableHeader(REPORTS_CUSTOM, tableName, header));
  },
  setHidedHeader: (hidedColumns) => {
    dispatch(setSourceHidedHeader(REPORTS_CUSTOM, hidedColumns));
  },
  setTableLoading: (loading) => {
    dispatch(loaderActions.setTableLoading(loading));
  },
  setHidedColumns: (hidedColumns) => {
    dispatch(setSourceHidedHeader(REPORTS_CUSTOM, hidedColumns));
  },
  setSelectedTableName: (name) => {
    dispatch(setSourceSelectedTableName(REPORTS_CUSTOM, name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Table);

Table.propTypes = {
  source: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.object,
  header: PropTypes.func.isRequired,
  hidedHeader: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tables: PropTypes.array,
  setTableLoading: PropTypes.func.isRequired,
  onEvent: PropTypes.func,
  showRevertTool: PropTypes.bool,
  selectedTableName: PropTypes.string,
  setTables: PropTypes.func,
  setSelectedTableName: PropTypes.func,
  setSelectedTable: PropTypes.func,
  options: PropTypes.object,
};

Table.defaultProps = {
  source: '',
  headers: {},
  tables: [],
  onEvent: () => {},
  showRevertTool: false,
  selectedTableName: '',
  setTables: () => {},
  setSelectedTableName: () => {},
  setSelectedTable: () => {},
  options: {},
};
