import { api } from 'utils/fetch';
import { action } from '../_utils';

export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const GET_TOTAL_COUNT = 'GET_TOTAL_COUNT';
export const INCREASE_COUNT_TENANT = 'INCREASE_COUNT_TENANT';

const fetchUserTotalCount = () => {
  return api.getCISUsers({ excludeData: true, accountStage: 'Registered' });
};

const fetchTenantTotalCount = () => {
  return api.getAllTenants({ excludeData: true });
};

const getTotalCount = () => {
  return action(GET_TOTAL_COUNT);
};

function increaseCountTenant() {
  return {
    type: INCREASE_COUNT_TENANT,
  };
}

function setTotalCount(totalCount) {
  return {
    type: SET_TOTAL_COUNT,
    totalCount,
  };
}

const dashboardActions = {
  getTotalCount,
  fetchUserTotalCount,
  fetchTenantTotalCount,
  setTotalCount,
  increaseCountTenant,
};

export default dashboardActions;
