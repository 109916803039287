import { Card, CardBody, Col } from 'reactstrap';
import { isNumeric, thousandSeparator } from 'utils';
import moment from 'moment/moment';
import React from 'react';
import PropTypes from 'prop-types';
import { getFilterRangeComponent, loadingSpin } from '../utils';
import ExtendedTotalComponent from '../ExtendedTotalComponent';

function ChartKPI({
  title,
  total,
  data,
  isFilterable,
  isLoading,
  withDropDown,
  handleDateChange,
  selectedDates,
}) {
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  let dateLabel = '';
  if (selectedDates?.from && selectedDates?.to) {
    dateLabel = ` ${moment(selectedDates.from).format('MM/DD/YYYY')} - ${moment(
      selectedDates.to
    ).format('MM/DD/YYYY')}`;
  } else if (selectedDates?.from) {
    dateLabel = `From: ${moment(selectedDates.from).format('MM/DD/YYYY')}`;
  } else if (selectedDates?.to) {
    dateLabel = `To: ${moment(selectedDates.to).format('MM/DD/YYYY')}`;
  }
  return (
    <Col md={3} xl={2} lg={3} xs={6} style={{ height: '100%' }}>
      <Card>
        {isFilterable &&
          getFilterRangeComponent({
            openDatePicker,
            selectedDates,
            dateLabel,
            setOpenDatePicker,
            handleDateChange,
          })}
        <CardBody className="analytic-card">
          <div className="card__title">
            <h5 className="bold-text">{title}</h5>
          </div>
          <div className="analytic__total">
            <p className="analytic__total-stat analytic__total-stat-bold">
              {isNumeric(total)
                ? thousandSeparator(total)
                : total?.length
                ? total
                : 'No data found'}
            </p>
            {withDropDown ? <ExtendedTotalComponent data={data} /> : null}
          </div>
          {loadingSpin(isLoading)}
        </CardBody>
      </Card>
    </Col>
  );
}

ChartKPI.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number,
  data: PropTypes.array.isRequired,
  isFilterable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  withDropDown: PropTypes.bool.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  selectedDates: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
};

export default ChartKPI;
