import React, { useMemo } from 'react';

import { IconButton } from '@material-ui/core';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';

import Badge from 'components/common/Badge';

import { isObject } from 'utils';
import { PRICING_TIER } from './constants';

/* function LinkIcon() {
  return (
    <IconButton aria-label="Example">
      <OpenInNewIcon />
    </IconButton>
  );
} */

function StatusBadge(props) {
  const {
    row,
    value: { status, value },
  } = props;

  const {
    index,
    original: { partNumber, onDeleteLine },
  } = row;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Badge bg={status} style={{ minWidth: '90px' }}>
        {value}
      </Badge>
      {onDeleteLine ? (
        <IconButton
          aria-label="Example"
          onClick={() => {
            onDeleteLine({partNumber, index});
          }}
        >
          <DeleteIcon />
        </IconButton>
      ) : null}
    </div>
  );
}

/* function RemoveIcon(props) {
  if (props) {
    const { row, value: onDeleteLine } = props;
    return (
      <IconButton
        aria-label="Example"
        onClick={() => {
          onDeleteLine(row?.original?.partNumber);
        }}
      >
        <DeleteIcon />
      </IconButton>
    );
  }
} */

function prepareLineDropdownOptions(partNumbers, tierId) {
  return Object.keys(partNumbers).map((number) => {
    const part = partNumbers[number];
    return {
      value: part.PartNumber,
      label: `${part.PartDescription} - ${part.PartNumber}`,
      description: part.PartDescription,
      price: part[PRICING_TIER[tierId]] || 0, // Todo: There is empty price info
      originalData: part,
    };
  });
}

function prepareModuleDropdownOptions(modules) {
  return modules.map(({ id, name, model }) => ({
    value: id,
    label: name,
    model,
  }));
}

const clearObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    delete obj[key];
  });
  return obj;
};

const getPartNumberPrice = (partNumbers, partNumber, tierId) => {
  const part = partNumbers[partNumber];
  return (part && part[PRICING_TIER[tierId]]) || 0;
};

const calculateTotal = (quantity, unitPrice) => {
  return quantity * unitPrice;
};

const updateLinesTierPrice = (lines, tier, partNumbers = {}) => {
  if (!Array.isArray(lines)) return [];

  return lines.map((row) => {
    const { partNumber } = row;
    const unitPrice = getPartNumberPrice(partNumbers, partNumber, tier);
    return {
      ...row,
      unitPrice: unitPrice || 0,
      total: calculateTotal(row.quantity, unitPrice || 0),
    };
  });
};

const updateAccessoriesTierPrice = (linesOther, tier, partNumbers = {}) => {
  return linesOther.map((row) => {
    const { partNum, partQty } = row;
    const part = partNumbers[partNum];

    if (!isObject(part)) return { ...row };

    const unitPrice = getPartNumberPrice(partNumbers, partNum, tier);
    const quantity = partQty || 0;

    return {
      ...row,
      partQty: quantity,
      partPriceUnit: unitPrice || 0,
      partPriceTotal: calculateTotal(quantity, unitPrice),
    };
  });
};

const getColumns = (partNumbers, modules, tierId) => {

  const columns = [
    {
      type: 'int',
      Header: 'Quantity',
      accessor: 'quantity',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      minWidth: 170,
      Header: 'Part Number',
      accessor: 'partNumber',
      options: prepareLineDropdownOptions(partNumbers, tierId),
      dropdownLabel: 'Select Part Number',
      isDropdown: true,
    },
    {
      minWidth: 350,
      Header: 'Description',
      accessor: 'description',
      disableEditing: true,
    },
    {
      type: 'float',
      Header: 'Unit Price',
      accessor: 'unitPrice',
      disableGlobalFilter: true,
      disableEditing: true,
    },
    {
      type: 'float',
      Header: 'Total',
      accessor: 'total',
      disableGlobalFilter: true,
      disableEditing: true,
      Footer: (info) => {
        const { rows } = info;

        const totalPrice = useMemo(
          () => rows.reduce((sum, row) => Number(row.values.total) + sum, 0),
          [rows]
        );
        return <span>{Number(totalPrice).toFixed(2)}</span>;
      },
    },
    {
      Header: 'Module',
      width: 165,
      maxWidth: 165,
      accessor: 'model',
      options: prepareModuleDropdownOptions(modules),
      isDropdown: true,
      useBadge: true,
      dropdownLabel: 'Select Module',
      disableGlobalFilter: true,
    },
    {
      width: 165,
      maxWidth: 165,
      accessor: 'status',
      disableEditing: true,
      Cell: StatusBadge,
      disableGlobalFilter: true,
    } /* TODO:  Should be add later
    {
      accessor: 'link',
      disableEditing: true,
      Cell: LinkIcon,
      disableGlobalFilter: true,
    }, */,
    // { //TODO: Should be add later
    //   width: 65,
    //   maxWidth: 65,
    //   accessor: 'onDeleteLine',
    //   disableEditing: true,
    //   Cell: RemoveIcon,
    //   disableGlobalFilter: true,
    // },
  ];

  if (modules.length <= 1) {
    columns.splice(5, 1);
  }

  return columns;
};

const getRandomData = (partNumbers, tierId = 1) => {
  const data = [];
  const size = 0;
  const partNumbersSize = partNumbers.length;
  for (let i = 0; i < size; i += 1) {
    const randomPart = partNumbers[Math.floor(Math.random() * partNumbersSize)];
    const quantity = Math.floor(Math.random() * 100);
    const unitPrice = randomPart[PRICING_TIER[tierId]];
    data.push({
      quantity,
      partNumber: randomPart.PartNumber,
      description: randomPart.PartDescription,
      unitPrice,
      total: calculateTotal(quantity, unitPrice),
      status: [
        { status: 'success', value: 'Compatible' },
        { status: 'warning', value: 'Incompatible' },
      ][Math.floor(Math.random() * 2)],
      link: './',
    });
  }

  return data;
};

export {
  getColumns,
  getRandomData,
  getPartNumberPrice,
  updateLinesTierPrice,
  updateAccessoriesTierPrice,
  calculateTotal,
  clearObject,
};
