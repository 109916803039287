/* eslint-disable no-nested-ternary */
import React from 'react';
import { FormGroupLabel } from 'components/common/Form/FormElements';
import { SelectField as Select } from 'components/common/Form/components/Select';
import { CheckBoxField as CheckBox } from 'components/common/Form/components/CheckBox';

const getFiledLabel = (title) => {
  return <FormGroupLabel>{`${title}`}</FormGroupLabel>;
};

function CheckBoxField({ name, value, onChangeField }) {
  return (
    <CheckBox
      name={name}
      checked={value}
      onChange={() => {
        onChangeField(name, !value);
      }}
    />
  );
}

function NumericField({ name, value, onChangeField }) {
  return (
    <input
      name={name}
      type="number"
      value={value}
      onChange={(e) => {
        onChangeField(name, e.target.value);
      }}
    />
  );
}

function TextField({ name, value, onChangeField }) {
  return (
    <input
      name={name}
      type="text"
      value={value}
      onChange={(e) => {
        onChangeField(name, e.target.value);
      }}
    />
  );
}

function prepareSelectValue(value = '', isMulti = false) {
  if (Array.isArray(value)) {
    return value.map((x) => ({ value: x.id, label: x.name }));
  }

  if (!value) {
    if (isMulti) {
      return [];
    }
    return { value: '', label: '' };
  }
  return { value: value.id, label: value.name };
}

function selectedOptionToValue(selectedOption, isMulti) {
  if (Array.isArray(selectedOption) && isMulti) {
    return selectedOption.map((selected) => ({
      id: selected.value,
      name: selected.label,
      value: selected.value,
    }));
  }
  return {
    id: selectedOption.value,
    name: selectedOption.label,
    value: selectedOption.value,
  };
}

const additionalField = (
  additional,
  additionalFieldValue,
  options,
  onChangeField
) => {
  return (
    <div className="additional-checkbox">
      <span>{additional.label}</span>
      <CheckBoxField
        name={additional.field}
        value={additionalFieldValue}
        onChangeField={onChangeField}
      />
    </div>
  );
};

function SelectField({ name, value, items, onChangeField, label, isMulti }) {
  return (
    <Select
      name={name}
      value={prepareSelectValue(value, isMulti)}
      placeholder={`Select ${label}`}
      options={items}
      isMulti={isMulti}
      styles={{
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#004D7A' : 'inherit',
          '&:hover': {
            backgroundColor:
              state.isFocused && !state.isSelected
                ? 'rgba(0, 77, 122, 0.1)'
                : state.isFocused && state.isSelected
                ? '#004D7A'
                : provided.backgroundColor,
          },
        }),
        control: (provided, state) => ({
          ...provided,
          borderRadius: 0,
          borderColor: state.isFocused ? '#004D7A' : provided.borderColor,
          boxShadow: state.isFocused ? '0 0 0 0 #004D7A' : provided.boxShadow,
          '&:hover': {
            borderColor: state.isFocused ? '#004D7A' : provided.borderColor,
          },
        }),
        indicatorsContainer: (provided) => ({ ...provided }),
        singleValue: (provided) => provided,
        valueContainer: (provided) => ({ ...provided }),
        menu: (base) => ({ ...base, zIndex: 13 }),
        multiValueLabel: (base, state) => {
          return state.data.isFixed
            ? { ...base, borderRight: 'none!Important' }
            : base;
        },
        multiValueRemove: (base, state) => {
          return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
      }}
      onChange={(changedValue) => {
        const newValue = selectedOptionToValue(changedValue, isMulti);
        onChangeField(name, newValue);
      }}
    />
  );
}

function getField(
  fieldName,
  type,
  items,
  value,
  onChangeField,
  label = '',
  options = {},
  additionalFieldValue = null
) {
  let field;
  switch (type) {
    case 'numeric':
      field = (
        <NumericField
          name={fieldName}
          value={value}
          onChangeField={onChangeField}
          options={options}
        />
      );
      break;
    case 'select':
      field = (
        <SelectField
          name={fieldName}
          value={value}
          items={items}
          onChangeField={onChangeField}
          label={label}
          isMulti={!!options.isMulti}
        />
      );
      break;
    default: {
      field = (
        <TextField
          name={fieldName}
          value={value}
          onChangeField={onChangeField}
        />
      );
    }
  }

  const { additional } = options;

  return (
    <div>
      {field}
      {additional &&
        additionalField(
          additional,
          additionalFieldValue,
          options,
          onChangeField
        )}
    </div>
  );
}

export { getFiledLabel, getField };
