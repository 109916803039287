import { createSelector } from 'reselect';

import ACL from 'redux/Acl';
import {
  ALLOW_MANAGE_USERS,
  DW_DESIGNER_WORKFLOW,
  DW_ADMIN,
  DW_DESIGNER_MANAGER,
  DW_DESIGNER_VIEW,
  DW_SUBMISSIONS,
  DW_SUBMISSIONS_MANAGER,
  ALLOW_DEBUG_TOGGLE,
} from 'redux/constants/category';

export const userFilter = (state) => {
  const { user } = state.auth;
  return user;
};

const getNestedValueByStringKey = (obj, strKey) => {
  let str = strKey.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  str = str.replace(/^\./, ''); // strip a leading dot
  const keys = str.split('.');
  for (let i = 0, n = keys.length; i < n; i += 1) {
    const k = keys[i];
    if (k in obj) {
      obj = obj[k]; // eslint-disable-line no-param-reassign
    } else {
      return false;
    }
  }
};

const flagFilter = (state, key, defaultStatus = true) => {
  const { flags } = state;

  const status = getNestedValueByStringKey(flags, key);
  if (status === null) return defaultStatus;
  return status;
};

export const getUser = createSelector(userFilter, (user) => user);

export const flagSelector = createSelector(flagFilter, (flag) => flag);

const getAclProp = (state) => state.auth.acl;

export const getAcl = createSelector(getAclProp, (items) => {
  const acl = new ACL();

  if (Array.isArray(items)) {
    let i = -1;
    const n = items.length;

    while (++i < n) {
      acl.setAccess(items[i]);
    }

    acl.inited = true;
  }

  return acl;
});

export const getAccessManageUsers = createSelector(getAcl, (acl) =>
  acl.getAccess(ALLOW_MANAGE_USERS)
);

export const getAccessDesignWorkflow = createSelector(getAcl, (acl) =>
  acl.getAccess(DW_DESIGNER_WORKFLOW)
);

export const getAccessDWSubmissions = createSelector(getAcl, (acl) =>
  acl.getAccess(DW_SUBMISSIONS)
);

export const getAccessDWSubmissionsManager = createSelector(getAcl, (acl) =>
  acl.getAccess(DW_SUBMISSIONS_MANAGER)
);

export const getAccessDWDesignerView = createSelector(getAcl, (acl) =>
  acl.getAccess(DW_DESIGNER_VIEW)
);

export const getAccessDWDesignerManager = createSelector(getAcl, (acl) =>
  acl.getAccess(DW_DESIGNER_MANAGER)
);

export const getAccessDWAdmin = createSelector(getAcl, (acl) =>
  acl.getAccess(DW_ADMIN)
);

export const getAccessDebugToggle = createSelector(getAcl, (acl) =>
  acl.getAccess(ALLOW_DEBUG_TOGGLE)
);

const isLoadedProp = (state) => !state.loader.loading;

export const isLoaded = createSelector(
  isLoadedProp,
  getAcl,
  (loaded, acl) => loaded && acl.isInited()
);
