/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import MaterialTable from 'components/MaterialTable';
import { api } from 'utils/fetch';
import { Box } from '@material-ui/core';
import Menu from 'components/common/Menu';
import {
  getAccessDWAdmin,
  getAccessDWDesignerManager,
  getAccessDWDesignerView,
  getAccessDWSubmissionsManager,
  getAccessDesignWorkflow,
} from 'redux/selectors/selectors';
import { EDIT } from 'components/DetailView/constants';
import Toaster from 'components/ToasterQueue';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CLICK_TO_OPEN } from 'components/common/utils/constants';
import { prepareFilterForTable } from 'containers/DesignerWorkflow/utils/utils';
import { scrollToView } from 'utils';
import ConfigTables from './utils';
import LookupCache from '../utils/lookupCache';

import './style.scss';

class Submissions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef(null);

    this.state = {
      selected: new Map(),
      infoOpen: false,
      infoData: {},
      isFiltering: false,
      filterOpened: false,
      filters: {},
      currentSelectedItem: null,
      countItemsForApproveAndDeny: 1,
    };

    this.openedRow = false;
    this.firstLoadData = false;
    LookupCache.getAllDwLookup();
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const result = await api.getRequestInfo();
    this.setState({
      infoData: result?.data,
    });
  };

  getHeaderComponent() {
    const { config } = this.props;
    const { infoOpen, infoData } = this.state;
    const { title, getHeaderActions } = config?.components?.header || {
      title: '',
      getInfoBox: () => {},
    };
    return (
      <Box className="estimate-header" sx={{ flexGrow: 1 }}>
        <header className="dw-submissions-header">
          <div className="dw-submissions-header-left">
            <Menu
              items={getHeaderActions()}
              onSelectItem={async (selected) => {
                const { filters } = this.state;
                this.tableRef.current.setLoading(true);
                const result = await selected
                  .getUrlLink(prepareFilterForTable(filters))
                  .catch((err) => {
                    Toaster.error(err?.message?.message || err?.message || err);
                  });
                this.tableRef.current.setLoading(false);
                if (result?.data?.url) {
                  Toaster.showLink(CLICK_TO_OPEN, result.data.url);
                }
              }}
            />
            <span>{title}</span>
          </div>
          {false && (
            <div>
              <IconButton
                onClick={() => {
                  this.setState({ infoOpen: !infoOpen });
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
              {config.components.header.getInfoBox(infoOpen, infoData, () => {
                this.setState({ infoOpen: false });
              })}
            </div>
          )}
        </header>
      </Box>
    );
  }

  additionalSearchButton = () => {
    const { filterOpened, filters, isFiltering } = this.state;
    const { config } = this.props;
    const { getFilterBox } = config?.components.search || {};
    return (
      <>
        <IconButton
          onClick={() => {
            this.setState({ filterOpened: !filterOpened });
          }}
        >
          <FilterListIcon style={{ color: isFiltering ? '#70bbfd' : '' }} />
        </IconButton>
        {getFilterBox &&
          getFilterBox(
            filterOpened,
            filters,
            (newFilters) => {
              const tableFilters = prepareFilterForTable(newFilters);
              this.setState({
                filterOpened: false,
                filters: newFilters,
                isFiltering: Object.keys(tableFilters).length > 0,
              });
              this.tableRef.current.onChangeFilter({ filters: tableFilters });
            },
            () => {
              if (Object.keys(filters).length > 0) {
                this.tableRef.current.clearFiltersAndUpdateTable();
              }
              this.setState({
                filterOpened: false,
                filters: {},
                isFiltering: false,
              });
            },
            () => this.setState({ filterOpened: false })
          )}
      </>
    );
  };

  onEditRowAction = (id, data) => {
    this.openedRow = true;

    this.clearSelected();

    this.setState({
      currentSelectedItem: data[id],
    });
  };

  onDetailViewUpdate = () => {
    if (this.openedRow) {
      this.openedRow = false;
      setTimeout(() => {
        scrollToView(this.tableRef.current.detailViewRef.current, 60);
      });
    }
  };

  onSaveDetailsView = async (e, data) => {
    const { config } = this.props;

    try {
      return config.putData(data);
    } catch (err) {
      Toaster.error(err?.message?.message ? err.message.message : err?.message);
      return false;
    }
  };

  applyDefaultFilters = (params) => {
    const { config } = this.props;

    if (!params.filters || Array.isArray(params.filters)) {
      params.filters = {};
    }

    const formattedFilters = {};

    config.headers.forEach(({ field, filtering, defaultFilterValue }) => {
      if (filtering && defaultFilterValue) {
        formattedFilters[field] = defaultFilterValue;
      }
    });

    params.filters = {
      ...formattedFilters,
      ...params.filters,
    };
  };

  onHandleClickDenyMiddleToolbar = async () => {
    const { allowedDecline } = this.props;
    return this.updateData(allowedDecline, 'Status', 'Denied');
  };

  onHandleClickApproveMiddleToolbar = async () => {
    const { allowedApprove } = this.props;
    return this.updateData(allowedApprove, 'Status', 'Approved');
  };

  updateData = async (allowed, fieldName, fieldValue) => {
    if (!allowed) {
      Toaster.error('You do not have permission to perform this action');
      return;
    }

    const { selected, countItemsForApproveAndDeny } = this.state;

    if (selected.size !== countItemsForApproveAndDeny) {
      Toaster.error(`Please select only one submission to update.`);
      return;
    }

    const { config } = this.props;

    const items = [];

    selected.forEach((value) => {
      if (value[fieldName] === fieldValue) return;

      value[fieldName] = fieldValue;

      const { tableData, ...data } = value;

      items.push({ ...data });
    });

    if (items.length === 0) {
      Toaster.error('Selected submission is already updated');
      return;
    }

    try {
      this.tableRef.current.setLoading(true);
      await config.putData({ data: items[0] });
      this.tableRef.current.forceUpdateTableData();
      Toaster.success('Submissions updated successfully');

      this.setState({
        currentSelectedItem: null,
      });

      this.clearSelected();
    } catch (err) {
      Toaster.error(err?.message?.message ? err.message.message : err?.message);
    } finally {
      this.tableRef.current.setLoading(false);
    }
  };

  middleToolbar = () => {
    return (
      <div className="middleToolbar">
        <Button onClick={this.onHandleClickDenyMiddleToolbar}>Deny</Button>
        <Button onClick={this.onHandleClickApproveMiddleToolbar}>
          Approve
        </Button>
      </div>
    );
  };

  handleCheckboxClick = (event, rowData) => {
    const { selected } = this.state;
    let currentSelectedItem = null;

    if (selected.has(rowData.Id)) {
      selected.delete(rowData.Id);
    } else {
      selected.set(rowData.Id, rowData);
      currentSelectedItem = rowData;
    }

    this.setState({ selected, currentSelectedItem });
  };

  highlightRowIf = (rowData) => {
    const { currentSelectedItem, selected } = this.state;

    if (currentSelectedItem?.Id === rowData.Id || selected.has(rowData.Id)) {
      return { backgroundColor: '#E0FFFF' };
    }

    return null;
  };

  clearSelected = () => {
    this.setState({ selected: new Map() });
  };

  onCloseDetailView = () => {
    this.setState({
      currentSelectedItem: null,
    });
  };

  render() {
    const {
      config,
      allowedUpdate,
      allowedShowBtns,
      allowedSelect,
    } = this.props;

    return (
      <Container className="submissions-page">
        {this.getHeaderComponent()}
        <Row>
          <MaterialTable
            ref={this.tableRef}
            tableProps={{
              title: config.tableTitle,
              name: config.tableName,
              detailViewTittle: config.detailViewTittle,
              detailViewMode: EDIT,
              searchOnLeftSide: true,
              additionalSearchButton: this.additionalSearchButton,
              options: {
                filtering: false,
                selection: true,
                showSelectAllCheckbox: false,
                selectionProps: (rowData) => {
                  const { selected } = this.state;
                  return {
                    disabled: !allowedSelect,
                    checked: selected.has(rowData.Id) || false,
                    onClick: (e) => this.handleCheckboxClick(e, rowData),
                  };
                },
                rowStyle: this.highlightRowIf,
              },
            }}
            extandedDetailView
            middleToolbar={allowedShowBtns && (() => this.middleToolbar())}
            columns={config.headers}
            remoteData
            fullScreenDetailView
            getData={async (param) => {
              if (!this.firstLoadData) {
                this.applyDefaultFilters(param);
                this.firstLoadData = true;
              }

              return config.getData(param);
            }}
            editRowAction
            onEditRowAction={this.onEditRowAction}
            report={!allowedUpdate}
            onRowClick={() => null} // disable row click, only edit button
            detailsView={{
              getDataFromView: true,
              cols: config.detailViewCols,
              idFieldName: '_id',
              onSave: this.onSaveDetailsView,
            }}
            onDetailViewUpdate={this.onDetailViewUpdate}
            onCloseDetailView={this.onCloseDetailView}
          />
        </Row>
      </Container>
    );
  }
}

Submissions.propTypes = {
  allowedUpdate: PropTypes.bool,
  allowedShowBtns: PropTypes.bool,
  allowedDecline: PropTypes.bool,
  allowedApprove: PropTypes.bool,
  allowedSelect: PropTypes.bool,
  config: PropTypes.shape({
    components: PropTypes.shape({
      header: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tableTitle: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    detailViewTittle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    getItem: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    putData: PropTypes.func.isRequired,
    headers: PropTypes.array,
    detailViewCols: PropTypes.array,
  }).isRequired,
  applyApproveDenyTo: PropTypes.string,
};

Submissions.defaultProps = {
  allowedUpdate: false,
  allowedShowBtns: false,
  allowedDecline: false,
  allowedApprove: false,
  allowedSelect: false,
  applyApproveDenyTo: null,
};

const mapStateToProps = (state) => {
  const accessDW = getAccessDesignWorkflow(state);
  const accessDWSubmissionsManager = getAccessDWSubmissionsManager(state);
  const accessDWDesignerView = getAccessDWDesignerView(state);
  const accessDWDesignerManager = getAccessDWDesignerManager(state);
  const accessDWAdmin = getAccessDWAdmin(state);

  const allowedUpdate =
    accessDW.allowUpdate() ||
    accessDWSubmissionsManager?.allowUpdate() ||
    accessDWDesignerView?.allowUpdate() ||
    accessDWDesignerManager?.allowUpdate() ||
    accessDWAdmin?.allowUpdate();

  const allowedDecline = allowedUpdate;
  const allowedApprove = allowedUpdate;

  const allowedSelect =
    accessDWSubmissionsManager?.allowUpdate() ||
    accessDWDesignerView?.allowUpdate() ||
    accessDWDesignerManager?.allowUpdate() ||
    accessDWAdmin?.allowUpdate();

  const allowedShowBtns =
    accessDWSubmissionsManager?.allowUpdate() ||
    accessDWDesignerView?.allowUpdate() ||
    accessDWDesignerManager?.allowUpdate() ||
    accessDWAdmin?.allowUpdate();

  let tableName = null;

  if (
    accessDWSubmissionsManager?.allowUpdate() ||
    accessDWDesignerManager?.allowUpdate() ||
    accessDWAdmin?.allowUpdate()
  ) {
    tableName = 'designerManager';
  } else if (accessDWDesignerView?.allowUpdate()) {
    tableName = 'designerView';
  } else {
    tableName = 'accountCoordinator';
  }

  let config = null;

  if (tableName) {
    const detailViewCols = ConfigTables.submissions.detailViewCols.map(
      (col) => {
        return {
          ...col,
          editable: ConfigTables[tableName].allowedEditFields[col.field]
            ? true
            : 'never',
        };
      }
    );

    config = {
      ...ConfigTables.submissions,
      detailViewCols,
    };
  } else {
    config = ConfigTables.submissions;
  }

  return {
    config,
    allowedUpdate,
    allowedShowBtns,
    allowedDecline,
    allowedApprove,
    allowedSelect,
    applyApproveDenyTo: allowedShowBtns ? 'selected' : null,
  };
};

export { Submissions };

export default connect(mapStateToProps)(Submissions);
