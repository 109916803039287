/* eslint-disable no-console */
import { api, getToken } from 'utils/fetch';
import IoTClient from 'utils/IoTClient';
import { action } from '../_utils';
import store from '../store';

export const INITIALIZE_IOT = 'INITIALIZE_IOT';
export const UPDATE_IOT = 'UPDATE_IOT';

function subscribe() {
  const hasToken = getToken();

  if (!hasToken) return null;

  return api.getIoTCredentials().then(({ data }) => {
    const { NODE_ENV } = process.env;
    const client = new IoTClient({
      ...data,
      debug: ['local', 'development'].includes(NODE_ENV),
    });
    const topic = `${NODE_ENV}/dashboard`;
    client.onOffline(console.warn);
    client.onClose(console.warn);
    client.onError((error) => {
      console.error('IoT error', error);
    });
    client.onConnect(() => {
      client.subscribe(topic, (err) => {
        if (err) {
          console.error('Error while subscribe to IoT', err);
        }
        client.onSubscribe((resp) => {
          const payLoad = JSON.parse(resp.toString());
          // eslint-disable-next-line
          store.dispatch(IoTActions.updateIoT(payLoad));
        });
      });
    });
  });
}

const IoTActions = {
  init: () => action(INITIALIZE_IOT, {}),
  updateIoT: (data) => action(UPDATE_IOT, { data }),
  subscribe,
};

export default IoTActions;
