import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';

import { getPrices, SeparateRow } from './utils';
import Summary from './Summary';
import Totals from './Totals';

import './styles.scss';

function Total({ values, partNumbers, allLines }) {
  const [open, setOpen] = useState(false);

  const {
    costMajor,
    costMA,
    costAccessories,
    costWiring,
    subTotal,
    totalCost,
    grandTotal,
  } = getPrices(values, partNumbers, allLines);

  return (
    <div className="total-main">
      <div className="total-top">
        <div>Estimate Totals:</div>
        <div
          className="total-top-right"
          onClick={() => setOpen(!open)}
          role="button"
          tabIndex="0"
          aria-hidden="true"
          // onKeyPress={this.handleKeyPress}
        >
          {`${open ? 'less...' : 'more...'}`}
        </div>
      </div>
      <Collapse className="total-collapse" in={open} timeout="auto">
        <Table>
          <TableBody>
            <Summary
              costMajor={costMajor}
              costMA={costMA}
              costAccessories={costAccessories}
              costWiring={costWiring}
            />
            <SeparateRow />
            <Totals
              subTotal={subTotal}
              totalCost={totalCost}
            />
          </TableBody>
        </Table>
      </Collapse>
      <div className="total-grand">
        <div>Grand Total</div>
        <div>{grandTotal}</div>
      </div>
    </div>
  );
}

Total.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  partNumbers: PropTypes.object.isRequired,
  allLines: PropTypes.arrayOf(
    PropTypes.shape({
      linesOther: PropTypes.arrayOf(PropTypes.any),
      additionalComponents: PropTypes.arrayOf(PropTypes.any),
      lines: PropTypes.arrayOf(PropTypes.any),
    })
  ).isRequired,
};

export default Total;
