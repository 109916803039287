import { api } from 'utils/fetch';

export class UsersCache {
  constructor() {
    this.tenants = null;
    this.roles = null;

    this.waitForTenantsResponse = null;
    this.numberTenantsRequests = 0;
  }

  async getTenants() {
    if (this.tenants) {
      return this.tenants;
    }

    if (!this.waitForTenantsResponse) {
      this.waitForTenantsResponse = api.getTenantNames();
    }

    this.numberTenantsRequests++;

    let tenants = await this.waitForTenantsResponse;

    this.numberTenantsRequests--;

    if (!tenants) {
      tenants = { data: [] };
    }

    this.tenants = tenants;

    if (!this.numberTenantsRequests) {
      this.waitForTenantsResponse = null;
    }

    return this.tenants;
  }

  async getRoles({ tenantId = null, filter = null } = {}) {
    const tenants = await this.getTenants();

    if (filter) {
      this.roles = [];

      const existRoles = {};

      tenants.data.forEach((tenant) => {
        const { roles } = tenant;

        if (
          tenantId !== null &&
          String(tenant[filter.id]).trim().toLowerCase() !==
            String(tenantId).trim().toLowerCase()
        )
          return;

        roles.forEach((role) => {
          const key = String(role[filter.id]).toLowerCase();

          const exist = existRoles[key];

          if (!exist) {
            this.roles.push({
              id: role[filter.id],
              name: role[filter.name],
            });
            existRoles[key] = true;
          }
        });
      });
    } else {
      const tenant = tenants.data.find((x) => x.id === tenantId);
      this.roles = tenant ? tenant.roles : null;
    }

    return { data: this.roles };
  }

  async filterTenants({ roleId, filter } = {}) {
    const tenants = await this.getTenants();

    const filteredTennats = [];

    const existTenants = {};

    tenants.data.forEach((tenant) => {
      const { id, name, roles } = tenant;

      let exist = false;

      if (roleId) {
        exist = roles.some((role) => {
          return (
            String(role[filter.id]).trim().toLowerCase() ===
            String(roleId).trim().toLowerCase()
          );
        });
      } else {
        exist = true;
      }

      if (exist && !existTenants[id]) {
        filteredTennats.push({
          id: tenant[filter.id],
          name: tenant[filter.name],
        });
        existTenants[id] = true;
      }
    });

    return { data: filteredTennats };
  }

  reset() {
    this.tenants = null;
    this.roles = null;

    this.waitForTenantsResponse = null;
    this.numberTenantsRequests = 0;
  }
}
