import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import {
  TableCheckbox,
  TableSortLabel,
} from 'components/common/MaterialTable/components/MaterialTableElements';
import MatTableToolbar from 'components/common/MaterialTable/components/MatTableToolbar';

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

function MatTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  handleDeleteSelected,
  selected,
  rows,
  disabled,
}) {
  const rtl = useSelector((state) => state.rtl);

  return (
    <TableHead>
      <TableRow>
        {rows.map((row) => (
          <TableCell
            key={row.id}
            align={rtl.direction === 'rtl' ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id, onRequestSort)}
              className="material-table__sort-label"
              dir="ltr"
            >
              {row.label}
            </TableSortLabel>

            <div style={{ display: 'inline-flex' }}>
              <MatTableToolbar
                numSelected={[...selected].filter((el) => el[1]).length}
                handleDeleteSelected={handleDeleteSelected}
                onRequestSort={onRequestSort}
                showFilter={false}
                showDelete={false}
                disabled={disabled}
              />
            </div>
          </TableCell>
        ))}
        <TableCell padding="checkbox">
          <TableCheckbox
            disabled={disabled || rowCount === 0}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount && rowCount !== 0}
            onChange={onSelectAllClick}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

MatTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
};

export default MatTableHead;
