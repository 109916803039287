/* eslint-disable react/prop-types */
import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function ColumnsViewSwitch({ onChangeField, label, isShown = true }) {
  return (
    <FormControlLabel
      display={{ xs: 'none', md: 'block' }}
      label={label}
      labelPlacement="start"
      control={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Switch checked={isShown} onChange={onChangeField} color="primary" />
      }
    />
  );
}

export default ColumnsViewSwitch;
