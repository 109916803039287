import * as React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';

const ITEM_HEIGHT = 25;

export default function LongMenu({ items = [], onSelectItem, className }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectItem = (item) => {
    handleClose(null);
    onSelectItem(item);
  };

  return (
    <div className={className}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={!items || !items.length}
      >
        <MoreVertIcon className="icon__more-vert" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: '20ch',
          },
        }}
      >
        {items.map((item) => (
          <MenuItem
            style={{
              margin: '0 5px',
              fontWeight: '500',
              borderBottom: item.borderBottom
                ? '1px solid rgba(0, 0, 0, 0.87)'
                : '',
            }}
            key={item.id}
            disabled={item.disabled}
            onClick={() => {
              selectItem(item);
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

LongMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onSelectItem: PropTypes.func,
};

LongMenu.defaultProps = {
  className: '',
  items: [],
  onSelectItem: () => {},
};
