import jwtDecode from 'jwt-decode';
import { api } from 'utils/fetch';
import { isString } from 'utils';
import { encrypt, decrypt } from './crypto';

class Cache {
  constructor() {
    this.salesforcePrices = null;
    this.partNumbersTimeStamp = null;
    this.partNumbers = null;

    this.waitForSalesforcePricesResponse = null;
    this.numberSalesforcePricesRequests = 0;

    this.mapKeys = new Map([
      ['salesforcePrices', 'salesforce-prices'],
      ['partNumbers', 'part-numbers'],
      ['partNumbersTimeStamp', 'part-numbers-time-stamp'],
    ]);

    this.secretKey = null;
  }

  getSalesforcePrices = async () => {
    this.salesforcePrices = this.#get('salesforcePrices');

    if (this.salesforcePrices) return;

    if (!this.waitForSalesforcePricesResponse) {
      this.waitForSalesforcePricesResponse = api.getSalesforcePrices();
    }

    this.numberSalesforcePricesRequests++;

    let salesforcePrices = await this.waitForSalesforcePricesResponse;

    this.numberSalesforcePricesRequests--;

    if (!salesforcePrices) {
      salesforcePrices = { data: [] };
    }

    this.#save('salesforcePrices', salesforcePrices.data);

    if (!this.numberSalesforcePricesRequests) {
      this.waitForSalesforcePricesResponse = null;
    }

    return this.salesforcePrices;
  };

  getPartNumbersPrices = async (update = false) => {
    this.partNumbersTimeStamp = this.#get('partNumbersTimeStamp') || 0;
    // 86400000 - 1 day
    if (
      new Date().getTime() - this.partNumbersTimeStamp < 86400000 &&
      !update
    ) {
      const partNumbers = this.#get('partNumbers');

      if (partNumbers) {
        if (isString(partNumbers)) {
          this.partNumbers = JSON.parse(partNumbers);
        } else {
          this.partNumbers = partNumbers;
        }

        if (Array.isArray(this.partNumbers)) {
          // TODO: temp for clearing incorrect cache
          localStorage.removeItem('partNumbers');
          this.partNumbers = {};
        }

        return { partNumbers: this.partNumbers, needUpdatePrices: false };
      }
    }

    const result = await api.getSalesforcePrices();

    if (result.data) {
      const partNumbers = {};
      result.data.forEach((part) => {
        partNumbers[part.PartNumber] = part;
      });

      this.#save('partNumbers', JSON.stringify(partNumbers));
      this.#save('partNumbersTimeStamp', new Date().getTime());

      return { partNumbers, needUpdatePrices: true };
    }
  };

  reset = () => {
    this.salesforcePrices = null;
    this.waitForSalesforcePricesResponse = null;
    this.numberSalesforcePricesRequests = 0;

    localStorage.removeItem(this.mapKeys.get('salesforcePrices'));
  };

  setSecret = (key) => {
    this.secretKey = key ? jwtDecode(key).event_id : null;
  };

  #save(key, value) {
    this[key] = value;
    if (this.mapKeys.has(key)) {
      localStorage.setItem(
        this.mapKeys.get(key),
        encrypt(value, this.secretKey)
      );
    }

    return this[key];
  }

  #get(key) {
    if (this[key]) return this[key];

    const id = this.mapKeys.get(key);

    let value = localStorage.getItem(id);

    try {
      value = decrypt(value, this.secretKey);
    } catch {
      localStorage.removeItem(id);
      value = null;
    }

    return value;
  }

  getValue(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  setValue(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeValue(key) {
    localStorage.removeItem(key);
  }
}

const cache = new Cache();
export default cache;
