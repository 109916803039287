/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { isObject, debounce } from 'utils';

import './styles.scss';

const parseGeocodeAddress = (address) => {
  const location = { formatted_address: address.formatted_address };
  address.address_components.forEach((value) => {
    value.types.forEach((type) => {
      if (type === 'postal_code') location[type] = value.short_name;
      if (type === 'country') location[type] = value.short_name;
      if (type === 'administrative_area_level_1')
        location[type] = value.short_name;
    });
  });

  return location;
};

const getCustomStyles = ({ error }) => ({
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#004D7A' : 'inherit',
    '&:hover': {
      backgroundColor:
        state.isFocused && !state.isSelected
          ? 'rgba(0, 77, 122, 0.1)'
          : state.isFocused && state.isSelected
          ? '#004D7A'
          : provided.backgroundColor,
    },
  }),
  control: (provided, state) => {
    return {
      ...provided,
      height: 42,
      borderRadius: 0,
      borderColor: error ? '#f44336' : '#004D7A',
      boxShadow: state.isFocused ? '0 0 0 0 #004D7A' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? '#004D7A' : provided.borderColor,
      },
    };
  },
  indicatorsContainer: (provided) => {
    return { ...provided, height: 42 };
  },
  singleValue: (provided) => {
    return provided;
  },
  valueContainer: (provided) => {
    return { ...provided, height: 42 };
  },
});

const parseGeocodeResult = (searchResult, geocodeResult) => ({
  placeId: geocodeResult.place_id,
  locationInfo: parseGeocodeAddress(geocodeResult),
  description: searchResult.description,
  location: {
    elevation: geocodeResult.elevation * 39.3701,
    lat: geocodeResult.geometry.location.lat(),
    lng: geocodeResult.geometry.location.lng(),
  },
  viewport: {
    center: {
      lat: geocodeResult.geometry.viewport.getCenter().lat(),
      lng: geocodeResult.geometry.viewport.getCenter().lng(),
    },
    northEast: {
      lat: geocodeResult.geometry.viewport.getNorthEast().lat(),
      lng: geocodeResult.geometry.viewport.getNorthEast().lng(),
    },
    southWest: {
      lat: geocodeResult.geometry.viewport.getSouthWest().lat(),
      lng: geocodeResult.geometry.viewport.getSouthWest().lng(),
    },
  },
});

class PlaceSearchBox extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    value: PropTypes.string,
    onLocationSelected: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    map: PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.any,
    }),
  };

  static defaultProps = {
    onLocationSelected: () => {},
    onChange: () => {},
    disabled: false,
    value: null,
    map: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: null,
    };

    this.autocompleteService = null;
    this.geocoder = null;
    this.elevator = null;
    this.placesService = null;
  }

  componentDidMount() {
    if (window.google) {
      const { google } = window;
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.elevator = new window.google.maps.ElevationService();
      // We have to reduce the number of queries
      this.autocompleteService.getPlacePredictionsDebounced = debounce(
        this.autocompleteService.getPlacePredictions,
        250
      );
      this.geocoder = new google.maps.Geocoder();

      const { map } = this.props;

      if (isObject(map)) {
        this.initPlacesService(map);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { map } = this.props;

    if (prevProps.map !== map && isObject(map)) {
      this.initPlacesService(map);
    }
  }

  initPlacesService = ({ type, data }) => {
    if (type === 'google' && data) {
      this.placesService = new window.google.maps.places.PlacesService(data);
    }
  };

  onMapMarkerClicked = () => {
    const { value } = this.state;
    this.selectLocation(value);
  };

  onTextChange = (value) => {
    const { onChange, name } = this.props;
    this.setState({ value }, () => {
      this.selectLocation(value);
      onChange(name, value ? value.description : '');
    });
  };

  getElevation = (geocodeResult) => {
    const elevnReq = {
      locations: [
        {
          lat: geocodeResult.geometry.location.lat(),
          lng: geocodeResult.geometry.location.lng(),
        },
      ],
    };
    return new Promise((resolve, reject) => {
      this.elevator.getElevationForLocations(elevnReq, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            resolve({ ...geocodeResult, elevation: results[0].elevation });
          } else {
            resolve({ ...geocodeResult, elevation: null });
          }
        } else {
          reject(status);
        }
      });
    });
  };

  selectLocation(value) {
    if (this.placesService && isObject(value) && value.place_id) {
      this.placesService.getDetails(
        {
          placeId: value.place_id,
        },
        (result, status) => {
          if (status === 'OK' && result) {
            this.getElevation(result).then((resultWithEle) => {
              this.props.onLocationSelected(
                parseGeocodeResult(value, resultWithEle)
              );
            });
          }
        }
      );
    } else if (isObject(value) && value.place_id) {
      this.geocoder
        .geocode({ placeId: value.place_id })
        .then(({ results }) => {
          if (results[0]) {
            this.getElevation(results[0]).then((resultWithEle) => {
              this.props.onLocationSelected(
                parseGeocodeResult(value, resultWithEle)
              );
            });
          }
        })
        .catch((e) => window.alert(`Geocoder failed due to: ${e}`));
    }
  }

  searchPlaces = (query) =>
    new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictionsDebounced(
        { input: query },
        (results, status) => {
          if (status !== 'OK') {
            reject(status);
          } else {
            resolve(results);
          }
        }
      );
    });

  loadOptions = (query) => {
    if (!query || !this.autocompleteService) {
      return Promise.resolve({ options: [] });
    }
    return this.searchPlaces(query);
  };

  render() {
    const { value, error, disabled } = this.props;
    const address = value ? { description: value } : null;

    return (
      <div
        className="placesearchbox"
        style={{ paddingTop: 5 }}
        disabled={disabled}
      >
        <AsyncSelect
          name="suggestion-list"
          classname="select-container"
          placeholder="Enter location here"
          styles={getCustomStyles({ error })}
          getOptionLabel={(option) => `${option.description}`}
          getOptionValue={(option) => `${option.description}`}
          noResultsText="no_results_found"
          value={address}
          onChange={this.onTextChange}
          loadOptions={this.loadOptions}
        />
      </div>
    );
  }
}

export default PlaceSearchBox;
