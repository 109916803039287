import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import { Card, CardBody, Col, Row } from 'reactstrap';

import { isNumeric } from 'utils';

import Badge from '@material-ui/core/Badge';

import { FormControlLabel, FormGroup, Collapse } from '@material-ui/core';

import TextInput from 'components/common/ReactHookForm/TextInput';

import { TableCheckbox } from 'components/common/MaterialTable/components/MaterialTableElements';

import Button from 'components/common/Button';

import Table from './Table';

import './assets/style.scss';

const additionalComponentsHeaders = [
  {
    id: 'name',
    disablePadding: true,
    label: 'Additional Components',
  },
];

function createView(values = {}) {
  return {
    additionalComponents: values.additionalComponents || [],
    limitAdditionalComponents: values.limitAdditionalComponents || 3,
  };
}

function View({
  disabled,
  showStepsBadges,
  values,
  isPowerCap,
  wireAccessoriesSettings,
  wireAccessoriesKitStatus,
  onChange,
  onHandleState,
  config,
  showAdditionalComponents,
}) {
  const [fields, setFields] = useState(createView(values));

  useEffect(() => {
    onHandleState({ name: 'loaded', value: true });
  }, []);

  useEffect(() => {
    setFields(createView(values));
    return () => {};
  }, [values]);

  const onChangeAdditionalComponents = useCallback(
    ({ selected }) => {
      onChange({
        selectedAdditionalComponents: selected,
      });
    },
    [onChange]
  );

  const onChangeSupportsField = (e) => {
    let {
      target: { name, value },
    } = e;
    if (value !== '' && !isNumeric(value)) return;

    if (name === 'railModuleRatio' && `${value}`.split('.')[1]?.length > 2) {
      return;
    }

    if (value < 0) value = '0';

    onChange({
      supports: {
        [name]: {
          ...values.supports[name],
          value,
        },
      },
    });
  };

  const onChangeCostField = (e) => {
    let {
      target: { name, value },
    } = e;
    if (value !== '' && !isNumeric(value)) return;

    if (value < 0) value = 0;

    onChange({
      [name]: value,
    });
  };

  const onChangeField = (e) => {
    const {
      target: { name, checked },
    } = e;

    onChange({
      [name]: checked,
    });
  };

  const onChangeWireAccessoriesField = (e) => {
    const {
      target: { name, checked },
    } = e;

    onChange({
      wireAccessories: {
        name,
        checked,
      },
    });
  };

  const onUpdate = () => {
    onChange({
      needUpdateLinesBomQuantity: true,
    });
  };

  return (
    <Card className="estimate-project-view">
      <CardBody className="card-body-flex">
        {showStepsBadges && (
          <div className="card__title card__title_margin-left">
            <Badge
              color={disabled ? 'secondary' : 'primary'}
              badgeContent="5"
            />
          </div>
        )}
        {showAdditionalComponents && (
          <Row className="additional-components-center">
            <Col md={9} lg={9} xl={9}>
              <div>
                <Table
                  showTitle={false}
                  showPagination={false}
                  values={fields.additionalComponents}
                  headers={additionalComponentsHeaders}
                  limitRows={fields.limitAdditionalComponents}
                  disabled={disabled}
                  onChange={onChangeAdditionalComponents}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row className="supports">
          <Col md={12} lg={12} xl={7}>
            Number of Supports (Total)
          </Col>
          <Col md={12} lg={12} xl={5} />
        </Row>
        <div className="line" />
        <Row>
          <Col md={12} lg={12} xl={6} xxl={6}>
            <TextInput
              label={values.supports.numberSouthModules.label}
              labelPosition="left"
              labelWidth="50%"
              name={values.supports.numberSouthModules.name}
              size="small"
              disabled={disabled}
              onChange={onChangeSupportsField}
              onWheel={(event) => event.currentTarget.blur()}
              value={values.supports.numberSouthModules.value}
              type="number"
              min="0"
            />
          </Col>
          <Col md={12} lg={12} xl={6} xxl={6}>
            <TextInput
              label={values.supports.numberBases.label}
              labelPosition="left"
              labelWidth="50%"
              name={values.supports.numberBases.name}
              size="small"
              disabled={disabled}
              onChange={onChangeSupportsField}
              onWheel={(event) => event.currentTarget.blur()}
              value={values.supports.numberBases.value}
              type="number"
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={6} xxl={6}>
            <TextInput
              label={values.supports.railModuleRatio.label}
              labelPosition="left"
              labelWidth="50%"
              name={values.supports.railModuleRatio.name}
              size="small"
              disabled={disabled}
              onChange={onChangeSupportsField}
              onWheel={(event) => event.currentTarget.blur()}
              value={values.supports.railModuleRatio.value}
              min="0"
            />
          </Col>
          <Col md={12} lg={12} xl={6} xxl={6}>
            <TextInput
              label={values.supports.numberBolts.label}
              labelPosition="left"
              labelWidth="50%"
              name={values.supports.numberBolts.name}
              size="small"
              disabled={disabled}
              onChange={onChangeSupportsField}
              onWheel={(event) => event.currentTarget.blur()}
              value={values.supports.numberBolts.value}
              type="number"
              min="0"
            />
          </Col>
        </Row>
        <Row>
          {!isPowerCap && (
            <Col md={12} lg={12} xl={6} xxl={6}>
              <TextInput
                label={values.supports.numberMAs.label}
                labelPosition="left"
                labelWidth="50%"
                name={values.supports.numberMAs.name}
                size="small"
                disabled={disabled}
                onChange={onChangeSupportsField}
                onWheel={(event) => event.currentTarget.blur()}
                value={values.supports.numberMAs.value}
                type="number"
                min="0"
              />
            </Col>
          )}
          <Col md={12} lg={12} xl={6} xxl={6}>
            <TextInput
              label={values.supports.numberDeflectors.label}
              labelPosition="left"
              labelWidth="50%"
              name={values.supports.numberDeflectors.name}
              size="small"
              disabled={disabled}
              onChange={onChangeSupportsField}
              onWheel={(event) => event.currentTarget.blur()}
              value={values.supports.numberDeflectors.value}
              type="number"
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={6} xxl={6}>
            <div className="btn-update">
              <Button className="btn btn-primary btn-lg" onClick={onUpdate}>
                Update
              </Button>
            </div>
          </Col>
          <Col md={12} lg={12} xl={6} xxl={6}>
            <TextInput
              label={values.supports.numberRails.label}
              labelPosition="left"
              labelWidth="50%"
              name={values.supports.numberRails.name}
              size="small"
              disabled={disabled}
              onChange={onChangeSupportsField}
              onWheel={(event) => event.currentTarget.blur()}
              value={values.supports.numberRails.value}
              type="number"
              min="0"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={6} xxl={6} />
          <Col md={12} lg={12} xl={6} xxl={6}>
            <TextInput
              label={values.supports.numberCams.label}
              labelPosition="left"
              labelWidth="50%"
              name={values.supports.numberCams.name}
              size="small"
              disabled={disabled}
              onChange={onChangeSupportsField}
              onWheel={(event) => event.currentTarget.blur()}
              value={values.supports.numberCams.value}
              type="number"
              min="0"
            />
          </Col>
        </Row>
        <div className="line" />
        <Row>
          <Col md={2} lg={2} xl={2} xxl={2} />
          <Col md={8} lg={8} xl={8} xxl={8} className="center">
            <div>
              <FormGroup aria-label="Wiring Kit">
                <FormControlLabel
                  control={
                    <TableCheckbox
                      checked={values.wiringKit}
                      onChange={onChangeField}
                      name="wiringKit"
                      disabled={disabled}
                      styleType="colored-click"
                      color="primary"
                      label="Wiring Kit"
                    />
                  }
                  label="Wiring Kit"
                  labelPlacement="start"
                />
              </FormGroup>
              <Collapse
                className="total-collapse"
                in={values.wiringKit}
                timeout="auto"
              >
                <div className="accessories-add-components">
                  <div className="separator" />
                  {wireAccessoriesSettings.isWireRouterEnabled && (
                    <FormGroup aria-label="Wire Router">
                      <FormControlLabel
                        className="accessories-add-components-label"
                        control={
                          <TableCheckbox
                            checked={!!wireAccessoriesKitStatus.wireRouter}
                            onChange={onChangeWireAccessoriesField}
                            name="wireRouter"
                            disabled={disabled}
                            styleType="colored-click"
                            color="primary"
                            label="Wire Router"
                          />
                        }
                        label="Wire Router"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  )}
                  <FormGroup aria-label="Wire Clip">
                    <FormControlLabel
                      className="accessories-add-components-label"
                      control={
                        <TableCheckbox
                          checked={!!wireAccessoriesKitStatus.wireClip}
                          onChange={onChangeWireAccessoriesField}
                          name="wireClip"
                          disabled={disabled}
                          styleType="colored-click"
                          color="primary"
                          label="Wire Clip"
                        />
                      }
                      label="Wire Clip"
                      labelPlacement="start"
                    />
                  </FormGroup>
                  <FormGroup aria-label="Homerun Cover">
                    <FormControlLabel
                      className="accessories-add-components-label"
                      control={
                        <TableCheckbox
                          checked={!!wireAccessoriesKitStatus.homeRunCover}
                          onChange={onChangeWireAccessoriesField}
                          name="homeRunCover"
                          disabled={disabled}
                          styleType="colored-click"
                          color="primary"
                          label="Homerun Cover"
                        />
                      }
                      label="Homerun Cover"
                      labelPlacement="start"
                    />
                  </FormGroup>
                  <FormGroup aria-label="Homerun Clip">
                    <FormControlLabel
                      className="accessories-add-components-label"
                      control={
                        <TableCheckbox
                          checked={!!wireAccessoriesKitStatus.homeRunClip}
                          onChange={onChangeWireAccessoriesField}
                          name="homeRunClip"
                          disabled={disabled}
                          styleType="colored-click"
                          color="primary"
                          label="Homerun Clip"
                        />
                      }
                      label="Homerun Clip"
                      labelPlacement="start"
                    />
                  </FormGroup>
                  <div className="separator" />
                </div>
              </Collapse>
            </div>
          </Col>
          <Col md={2} lg={2} xl={2} xxl={2} />
        </Row>
        <Row>
          <Col md={2} lg={2} xl={2} xxl={2} />
          <Col md={8} lg={8} xl={8} xxl={8} className="center">
            <div>
              <FormGroup
                aria-label="Accessories"
                className="form-group-accessories"
              >
                <FormControlLabel
                  control={
                    <TableCheckbox
                      checked={values.accessories}
                      onChange={onChangeField}
                      name="accessories"
                      disabled={disabled}
                      styleType="colored-click"
                      color="primary"
                      label="Accessories"
                    />
                  }
                  label="Accessories"
                  labelPlacement="start"
                />
              </FormGroup>
              <Collapse
                className="total-collapse"
                in={values.accessories}
                timeout="auto"
              >
                <div className="accessories-add-components">
                  <div className="separator" />
                  {wireAccessoriesSettings.isSpacerStickEnabled && (
                    <FormGroup aria-label="Spacer Stick">
                      <FormControlLabel
                        className="accessories-add-components-label"
                        control={
                          <TableCheckbox
                            checked={!!wireAccessoriesKitStatus.spacerStick}
                            onChange={onChangeWireAccessoriesField}
                            name="spacerStick"
                            disabled={disabled}
                            styleType="colored-click"
                            color="primary"
                            label="Spacer Stick"
                          />
                        }
                        label="Spacer Stick"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  )}
                  <FormGroup aria-label="Shim Pads">
                    <FormControlLabel
                      className="accessories-add-components-label"
                      control={
                        <TableCheckbox
                          checked={!!wireAccessoriesKitStatus.shimPads}
                          onChange={onChangeWireAccessoriesField}
                          name="shimPads"
                          disabled={disabled}
                          styleType="colored-click"
                          color="primary"
                          label="Shim Pads"
                        />
                      }
                      label="Shim Pads"
                      labelPlacement="start"
                    />
                  </FormGroup>
                  <FormGroup aria-label="Grounding Lugs">
                    <FormControlLabel
                      className="accessories-add-components-label"
                      control={
                        <TableCheckbox
                          checked={!!wireAccessoriesKitStatus.groundingLugs}
                          onChange={onChangeWireAccessoriesField}
                          name="groundingLugs"
                          disabled={disabled}
                          styleType="colored-click"
                          color="primary"
                          label="Grounding Lugs"
                        />
                      }
                      label="Grounding Lugs"
                      labelPlacement="start"
                    />
                  </FormGroup>
                  <FormGroup aria-label="Base Pads">
                    <FormControlLabel
                      className="accessories-add-components-label"
                      control={
                        <TableCheckbox
                          checked={!!wireAccessoriesKitStatus.basePads}
                          onChange={onChangeWireAccessoriesField}
                          name="basePads"
                          disabled={disabled}
                          styleType="colored-click"
                          color="primary"
                          label="Base Pads"
                        />
                      }
                      label="Base Pads"
                      labelPlacement="start"
                    />
                  </FormGroup>
                  <FormGroup aria-label="Module Level Power Electronics">
                    <FormControlLabel
                      className="accessories-add-components-label"
                      control={
                        <TableCheckbox
                          checked={
                            !!wireAccessoriesKitStatus.moduleLevelPowerElectronics
                          }
                          onChange={onChangeWireAccessoriesField}
                          name="moduleLevelPowerElectronics"
                          disabled={disabled}
                          styleType="colored-click"
                          color="primary"
                          label="Module Level Power Electronics"
                        />
                      }
                      label="Module Level Power Electronics"
                      labelPlacement="start"
                    />
                  </FormGroup>
                  <div className="separator" style={{ marginBottom: '20px' }} />
                </div>
              </Collapse>
            </div>
          </Col>
          <Col md={2} lg={2} xl={2} xxl={2} />
        </Row>
        <Row>
          <Col md={2} lg={2} xl={2} xxl={2} />
          <Col md={8} lg={8} xl={8} xxl={8} className="center">
            <div>
              <TextInput
                label={config.fields.estShippingCost.ui.label}
                labelPosition="left"
                labelWidth="50%"
                precision={config.fields.estShippingCost.precision}
                name={config.fields.estShippingCost.name}
                size="boldMedium"
                disabled={disabled}
                onChange={onChangeCostField}
                onWheel={(event) => event.currentTarget.blur()}
                value={values.estShippingCost}
                valueType={config.fields.estShippingCost.valueType}
                type="number"
                min={config.fields.estShippingCost.inBetween.min}
              />
            </div>
          </Col>
          <Col md={2} lg={2} xl={2} xxl={2} />
        </Row>
        <Row>
          <Col md={2} lg={2} xl={2} xxl={2} />
          <Col md={8} lg={8} xl={8} xxl={8} className="center">
            <div>
              <TextInput
                label={config.fields.engServicesCost.ui.label}
                labelPosition="left"
                precision={config.fields.engServicesCost.precision}
                labelWidth="50%"
                name={config.fields.engServicesCost.name}
                size="boldMedium"
                disabled={disabled}
                onChange={onChangeCostField}
                onWheel={(event) => event.currentTarget.blur()}
                value={values.engServicesCost}
                valueType={config.fields.engServicesCost.valueType}
                type="number"
                min={config.fields.estShippingCost.inBetween.min}
              />
            </div>
          </Col>
          <Col md={2} lg={2} xl={2} xxl={2} />
        </Row>
        <div className="last-modified-row">
          <Row>
            <Col md={2} lg={2} xl={2} xxl={2} />
            <Col md={8} lg={8} xl={8} xxl={8} className="center">
              <div className="last-modified-text">
                {`Last modified: ${moment(new Date(values.lastModified)).format(
                  'YYYY-MM-DD HH:mm'
                )}`}
              </div>
            </Col>
            <Col md={2} lg={2} xl={2} xxl={2} />
          </Row>
        </div>
      </CardBody>
    </Card>
  );
}

View.propTypes = {
  disabled: PropTypes.bool,
  showStepsBadges: PropTypes.bool,
  values: PropTypes.shape({
    additionalComponents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
      })
    ),
    limitAdditionalComponents: PropTypes.number,
    supports: PropTypes.any,
    wiringKit: PropTypes.bool,
    accessories: PropTypes.bool,
    engServicesCost: PropTypes.string || PropTypes.number,
    estShippingCost: PropTypes.string || PropTypes.number,
  }),
  errors: PropTypes.shape({
    additionalComponents: PropTypes.string,
    limitAdditionalComponents: PropTypes.string,
    wiringKit: PropTypes.string,
    accessories: PropTypes.string,
  }),
  touched: PropTypes.shape({
    additionalComponents: PropTypes.any,
    limitAdditionalComponents: PropTypes.any,
    supports: PropTypes.any,
    wiringKit: PropTypes.bool,
    accessories: PropTypes.bool,
  }),
  restricted: PropTypes.shape({
    additionalComponents: PropTypes.bool,
    limitAdditionalComponents: PropTypes.bool,
    supports: PropTypes.bool,
    wiringKit: PropTypes.bool,
    accessories: PropTypes.bool,
    engServicesCost: PropTypes.string || PropTypes.number,
    estShippingCost: PropTypes.string || PropTypes.number,
  }),
  onChange: PropTypes.func,
  onHandleState: PropTypes.func,
  config: PropTypes.any.isRequired,
  showAdditionalComponents: PropTypes.bool,
  isPowerCap: PropTypes.bool,
  wireAccessoriesKitStatus: PropTypes.shape({
    wireRouter: PropTypes.bool,
    wireClip: PropTypes.bool,
    homeRunCover: PropTypes.bool,
    homeRunClip: PropTypes.bool,
    spacerStick: PropTypes.bool,
    shimPads: PropTypes.bool,
    groundingLugs: PropTypes.bool,
    basePads: PropTypes.bool,
    moduleLevelPowerElectronics: PropTypes.bool,
  }).isRequired,
  wireAccessoriesSettings: PropTypes.shape({
    isWireRouterEnabled: PropTypes.bool,
    isSpacerStickEnabled: PropTypes.bool,
  }).isRequired,
};

View.defaultProps = {
  disabled: false,
  showStepsBadges: false,
  values: {
    additionalComponents: [],
    limitAdditionalComponents: 3,
    supports: {},
    wiringKit: false,
    accessories: false,
    engServicesCost: 0,
    estShippingCost: 0,
  },
  errors: PropTypes.shape({
    additionalComponents: '',
    limitAdditionalComponents: '',
    wiringKit: '',
    accessories: '',
  }),
  touched: PropTypes.shape({
    additionalComponents: false,
    limitAdditionalComponents: false,
    wiringKit: false,
    accessories: false,
  }),
  restricted: PropTypes.shape({
    additionalComponents: false,
    limitAdditionalComponents: false,
    supports: false,
    wiringKit: false,
    accessories: false,
  }),
  showAdditionalComponents: true,
  onChange: () => {},
  onHandleState: () => {},
  isPowerCap: false,
};

export default connect()(View);
