import { v4 as uuidV4 } from 'uuid';

// Accessing nested JavaScript objects with string key
export const getNestedValueByStringKey = (obj, strKey) => {
  let str = strKey.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  str = str.replace(/^\./, ''); // strip a leading dot
  const keys = str.split('.');
  for (let i = 0, n = keys.length; i < n; i += 1) {
    const k = keys[i];
    if (k in obj) {
      obj = obj[k]; // eslint-disable-line no-param-reassign
    } else {
      return false;
    }
  }
  return obj;
};

export const substr = (str, start, length) => {
  if (!str) return null;
  const strLen = String(str).length;
  if (strLen < length) return str;

  return `${str.substr(start, length)} ..`;
};

export const getSlug = (str) => str.toLowerCase().replace(/[ ]/g, '-');

export const isNullOrUndefined = (val) => val === null || val === undefined;

export default {
  getNestedValueByStringKey,
};
