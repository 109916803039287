/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useEffect } from 'react';
import PropsTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import EditViewField from 'components/MaterialTable/EditViewField';
import EditViewSwitch from 'components/MaterialTable/EditViewSwitch';
import EditViewSelect from 'components/MaterialTable/EditViewSelect';
import EditViewDualList from 'components/MaterialTable/EditViewDualList';

import { getObjProp, setObjProp } from 'utils';

import ShowIf from 'components/ShowIf';
import Toaster from 'components/ToasterQueue';
import { api } from 'utils/fetch';
import { useGlobalSpinnerActionsContext } from 'components/GlobalSpinner/GlobalSpinnerContext';
import { validateFields } from './_utils';

import './style.scss';

function DetailModalView({
  tableProps: { title: gridTitle },
  title,
  handleClose,
  open,
  data,
  cols,
  onSave,
  onDelete,
  extraToolbar,
  editMode,
  idFieldName,
  isLoading,
}) {
  const [mode, setMode] = useState('');
  const [fieldsValues, setFieldsValues] = useState({});
  const [isAllowInDropdown, setAllowInDropdown] = useState(false);

  const [specSheetUrl, setSpecSheetUrl] = useState(null);
  const [newSpecSheetUrl, setNewSpecSheetUrl] = useState(null);
  const [errors, setErrors] = useState({});
  const [showExtraDialog, doShowExtraDialog] = useState(0);
  const setGlobalSpinner = useGlobalSpinnerActionsContext();

  useEffect(() => {
    setMode(
      // eslint-disable-next-line no-nested-ternary
      editMode === 'insert' ? 'Edit' : editMode === 'create' ? 'Create' : 'View'
    );
  }, [editMode]);

  // fill fields by exist info
  useEffect(() => {
    const state = {};

    cols.forEach(({ field, path }) => {
      setObjProp(state, path || field, getObjProp(data, path || field));
    });

    if (mode === 'Create' && !fieldsValues.TierId) {
      state.TierId = 1;
    }

    setAllowInDropdown(data.availableInDropdown);
    setFieldsValues(state);
    setErrors({});
    setSpecSheetUrl(data.dataSheetUrl);
  }, [data, mode, cols]);

  useEffect(() => {}, [newSpecSheetUrl]);

  const onChangeField = useCallback(
    (field, value, path, newFieldName = null) => {
      const name = path || field;

      let prop = getObjProp(fieldsValues, name);

      if (
        Object.prototype.toString.call(prop) === '[object Object]' &&
        newFieldName
      ) {
        prop = {
          ...prop,
          [newFieldName]: value,
          changed: {
            [newFieldName]: value,
          },
        };

        value = prop;
      }

      const state = setObjProp({ ...fieldsValues }, name, value);
      setFieldsValues(state);
    },
    [fieldsValues]
  );

  const isAllFieldsAreCorrect = useCallback(() => {
    const validationErrors = validateFields(cols, fieldsValues);
    if (validationErrors) {
      setErrors(validationErrors);
      return false;
    }
    setErrors({});
    return true;
  }, [cols, fieldsValues]);

  const onSwitcherChange = useCallback(() => {
    setGlobalSpinner(true);
    api
      .getSpecSheet(`${data.dataSheetUrl}.pdf`, !!data.custom)
      .then(({ data: { url: specSheetUrl } }) => {
        if (
          !isAllowInDropdown &&
          (data.custom || !data.approved || !specSheetUrl)
        ) {
          Toaster.error(
            'This module cannot be made available, because it needs to be approved and must include a valid PDF spec sheet.'
          );
          return;
        }

        if (isAllowInDropdown) isAllFieldsAreCorrect();
        fieldsValues.availableInDropdown = !isAllowInDropdown;
        setAllowInDropdown(fieldsValues.availableInDropdown);
      })
      .catch(() => {
        Toaster.error('The module data sheet does not exist');
      })
      .finally(() => setGlobalSpinner(false));
  }, [isAllowInDropdown, isAllFieldsAreCorrect]);

  // eslint-disable-next-line consistent-return
  const saveChanges = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const fieldsCorrect = isAllFieldsAreCorrect();
    // eslint-disable-next-line prettier/prettier
    if (fieldsCorrect && isAllowInDropdown && !data.dataSheetUrl) {
      // * to test CPD-247: change !data.dataSheetUrl to 1
      return doShowExtraDialog((prev) => prev + 1);
    }

    const dataForSave = {};

    Object.keys(fieldsValues).forEach((fieldName) => {
      if (fieldsValues[fieldName] && fieldsValues[fieldName].changed) {
        const { changed } = fieldsValues[fieldName];

        dataForSave[fieldName] = {};

        if (changed) {
          Object.keys(changed).forEach((changedFieldName) => {
            dataForSave[fieldName][changedFieldName] =
              changed[changedFieldName];
          });
        }

        delete fieldsValues[fieldName];
      } else {
        dataForSave[fieldName] = fieldsValues[fieldName];
      }
    });

    if (fieldsCorrect /* && data.dataSheetUrl */)
      return onSave(data[idFieldName], dataForSave, mode === 'Create');
  };

  const getExtraToolbar = useCallback(
    (gridtitle) => {
      const allExtraToolbars = {
        'solar modules': () =>
          extraToolbar({
            isAllowInDropdown,
            onSwitcherChange,
            isEditMode: mode.toLowerCase() === 'edit',
            isAllFieldsAreCorrect,
            specSheetUrl,
            data,
            setNewSpecSheetUrl,
            showExtraDialog,
          }),
      };

      const toCall = allExtraToolbars[gridtitle?.toLowerCase()];
      return (toCall && toCall()) || null;
    },
    [
      data,
      extraToolbar,
      gridTitle,
      isAllowInDropdown,
      onSwitcherChange,
      mode,
      isAllFieldsAreCorrect,
      specSheetUrl,
      setNewSpecSheetUrl,
      showExtraDialog,
    ]
  );

  const getComponent = (col) => {
    const {
      field,
      path,
      type,
      lookup,
      editable,
      exType,
      defaultValue,
      title: titleField,
    } = col;
    const isReadOnly = editable === 'never';

    const opts = {
      path,
      field,
      fieldsValues,
      isReadOnly: isReadOnly || field === idFieldName,
      isEditMode: mode === 'Edit' || mode === 'Create',
      mode,
      defaultValue,
    };

    if (type === 'boolean') {
      return (
        <EditViewSwitch
          onChangeField={(e, newValue) => {
            onChangeField(field, newValue, path);
          }}
          {...opts}
          {...{ label: titleField }}
        />
      );
    }

    if (exType === 'select') {
      return (
        <EditViewSelect
          onChangeField={(e, newValue) => {
            onChangeField(field, newValue, path, 'id');
          }}
          lookup={lookup}
          {...opts}
        />
      );
    }

    if (exType === 'dual-list') {
      return (
        <EditViewDualList
          onChangeField={(e, newValue) => {
            onChangeField(field, newValue, path);
          }}
          {...opts}
        />
      );
    }

    return (
      <EditViewField
        error={errors[field]}
        onChangeField={onChangeField}
        {...opts}
      />
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      maxWidth="md"
      className="sm-edit"
    >
      <DialogTitle>
        {title}
        <small> {mode ? `(${mode} mode)` : null}</small>
        {mode !== 'Create' && (
          <DialogActions>
            {getExtraToolbar(gridTitle)}
            <ShowIf condition={editMode !== 'insert'}>
              {/* <Button onClick={handleClose} color="primary">
                Export
              </Button> */}
              <span />
              <Button
                onClick={() => setMode(mode === 'View' ? 'Edit' : 'View')}
                color="primary"
                autoFocus
              >
                {mode === 'View' ? `Edit Mode` : `View Mode`}
              </Button>
            </ShowIf>
          </DialogActions>
        )}
      </DialogTitle>
      <DialogContent>
        <div className="props-table">
          {cols.map((col) => {
            const {
              field,
              title: colTitle,
              hideInEdit,
              hideInCreate,
              type,
            } = col;
            if (mode === 'Create' && field.toLowerCase() === 'id') {
              return null;
            }
            if (hideInEdit && mode === 'Edit') return null;
            if (hideInCreate && mode === 'Create') return null;

            const component = getComponent(col);
            const containerStyle = {
              width: col.exType === 'dual-list' ? '100%' : 'auto',
              height: col.exType === 'dual-list' ? '300px' : 'auto',
            };

            return (
              <div
                key={field}
                className="prop-container"
                style={containerStyle}
              >
                {type !== 'boolean' && (
                  <div className="prop-name">{colTitle}</div>
                )}
                <div className="prop-value" style={containerStyle}>
                  {component}
                </div>
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        {(mode === 'Create' || mode === 'Edit') && (
          <Button
            onClick={saveChanges}
            color="primary"
            variant="contained"
            loading={isLoading}
          >
            Save
          </Button>
        )}
        <Button
          onClick={() => {
            setMode(mode !== 'Create' ? 'View' : mode);
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DetailModalView.propTypes = {
  title: PropsTypes.string,
  open: PropsTypes.bool,
  handleClose: PropsTypes.func,
  onSave: PropsTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  cols: PropsTypes.array,
  tableProps: PropsTypes.shape({}),
  data: PropsTypes.shape({}),
  extraToolbar: PropsTypes.func,
  editMode: PropsTypes.string,
  idFieldName: PropsTypes.string,
  isLoading: PropsTypes.bool,
};

DetailModalView.defaultProps = {
  tableProps: {},
  title: '',
  open: false,
  handleClose: () => ({}),
  onSave: () => ({}),
  cols: [],
  data: {},
  extraToolbar: () => ({}),
  editMode: 'update',
  idFieldName: '',
  isLoading: false,
};

export default DetailModalView;
