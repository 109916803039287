import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from 'mdi-react/DeleteIcon';
import {
  Toolbar,
  TableButton,
  TableToolbarSelected,
  TableToolbarWrap,
} from './MaterialTableElements';
import MatTableFilterButton from './MatTableFilterButton';

function MatTableToolbar({
  numSelected,
  handleDeleteSelected,
  onRequestSort,
  showFilter = true,
  showDelete = true,
  disabled = false,
}) {
  return (
    <TableToolbarWrap>
      <Toolbar className="material-table__toolbar">
        <div>
          {false && numSelected > 0 && (
            <TableToolbarSelected>
              {numSelected} <span>selected</span>
            </TableToolbarSelected>
          )}
        </div>
        <div>
          {numSelected > 0
            ? showDelete && (
                <TableButton onClick={handleDeleteSelected} disabled={disabled}>
                  <DeleteIcon />
                </TableButton>
              )
            : showFilter && (
                <MatTableFilterButton
                  onRequestSort={onRequestSort}
                  disabled={disabled}
                />
              )}
        </div>
      </Toolbar>
    </TableToolbarWrap>
  );
}

MatTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDeleteSelected: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export default MatTableToolbar;
