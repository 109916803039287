import { action } from '../_utils';
import { SET_FLAG, CLEAR_ALL_FLAGS } from './types';

export const setFlag = (actionKey = null, status = null, options = {}) =>
  action(SET_FLAG, {
    actionKey,
    status,
    options,
  });

export const clearFlag = (actionKey = null, options = {}) =>
  action(SET_FLAG, {
    actionKey,
    status: null,
    options,
  });

export const clearAllFlag = () => action(CLEAR_ALL_FLAGS, {});
