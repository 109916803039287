import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

function SidebarLink({ title, icon, newLink, route, onClick, exact, muiIcon }) {
  return (
    <NavLink
      to={route}
      onClick={onClick}
      activeClassName="sidebar__link-active"
      exact={exact}
    >
      <li className="sidebar__link">
        {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
        {muiIcon}
        <p className="sidebar__link-title">
          {title}
          {newLink ? (
            <Badge className="sidebar__link-badge">
              <span>New</span>
            </Badge>
          ) : (
            ''
          )}
        </p>
      </li>
    </NavLink>
  );
}

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  exact: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  muiIcon: PropTypes.any,
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  exact: false,
  route: '/',
  onClick: () => {},
};

export default SidebarLink;
