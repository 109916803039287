export const userDefaults = {
  firstName: null,
  lastName: null,
  phone: null,
  companyName: null,
  companyStreetAddress: null,
  companyCity: null,
  companyState: null,
  companyZip: null,
  companyCountry: null,
  email: null,
  unit: 'Imperial',
  recieveNotification: false,
  minDefaultModuleFS: null,
  minDefaultSubarrayFS: null,
  defaultModuleFS: null,
  defaultSubarrayFS: null,
  helioscopeLogin: '',
  helioscopePass: '',
};

export const passwordDefaults = {
  exPassword: '',
  newPassword: '',
  confirmPassword: '',
};
