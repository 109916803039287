/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Container, Row } from 'reactstrap';

import MaterialTable from 'components/MaterialTable';
import { api } from 'utils/fetch';
import { getSalesHeaders } from './utils';


class Sales extends React.Component {
  constructor(props) {
    super(props);
    const { headers, detailViewCols } = getSalesHeaders();
    this.headers = headers;
    this.detailViewCols = detailViewCols;
  }

  getItem = api.getSingleProject;

  render() {
    return (
      <Container className="sales-page">
        <Row>
          <MaterialTable
            tableProps={{
              title: 'Sales',
              name: 'SalesDashboard',
            }}
            middleToolbar={null}
            columns={this.headers}
            actions={[]}
            remoteData
            report
            getData={async (params) => {
              const { filters, orderBy, desc, ...otherParams } = params;
              const newFilters = Object.keys(filters ? filters : {}).reduce((acc, key) =>
              {
                acc[`f_${key}`] = filters[key];
                return acc;
              }, {});
              const newParams = {
                sortBy: orderBy ? orderBy.charAt(0).toLowerCase() + orderBy.slice(1) : "",
                sortOrder: desc ? -1 : 1,
                skip: (otherParams.page - 1) * otherParams.pageSize,
                limit: otherParams.pageSize,
                ...otherParams,
                ...newFilters,
              };

              if (newParams.f_tenantName) {
                newParams.tenants = [newParams.f_tenantName];
              }

              const res = await api.getAllProjects({
                ...newParams,
                getAll: true,
                withTenants: true,
              });

              return {
                ...res,
                count: res.data.count,
                data: res.data.data,
              };
            }}
            detailsView={{
              getItem: this.getItem,
              cols: this.detailViewCols,
              idFieldName: '_id',
            }}
          />
        </Row>
      </Container>
    );
  }
}

export default Sales;
