import {
  COMPONENT_QUOTE,
  CLAW_FR,
  POLAR_BEAR_III_HD,
  POLAR_BEAR_III,
  ESTIMATE,
  OMG_POWERGRIP,
  OMG_POWERGRIP_PLUS,
  OMG_POWERGRIP_UNIVERSAL_7,
  MA_HW_KIT_ONLY,
} from 'containers/Estimates/Estimate/constants';

import { mround, mod, roundup, isBlank } from 'utils';

export default class Quantity {
  /**
   *
   * Pattern: MA STRUT
   * @param {Object} options -
   * @param {string} options.maType - value from MA dropdown
   * @param {string} options.maBracketQTY - Number of MA's | Brackets
   * @param {string} options.maQTY - Number of MA's | Brackets
   * @param {string} options.rackingProducts - clawFR
   * @returns
   */
  static calculateForMAStrut({
    maType,
    maBracketQTY,
    maQTY,
    rackingProducts = CLAW_FR,
  }) {
    if (rackingProducts === CLAW_FR || rackingProducts === POLAR_BEAR_III_HD) {
      if (maType) {
        return maBracketQTY !== '' ? maBracketQTY : maQTY;
      }
    }

    return '';
  }

  /**
   *
   * Pattern: MA
   * @param {Object} options -
   * @param {string} options.maType - value from MA dropdown
   * @param {string} options.maQTY - Number of MA's | Brackets
   * @returns
   */
  static calculateForMA({ maType, maQTY }) {
    if (maType === OMG_POWERGRIP) {
      // 'OMG PG'
      return (Math.floor(maQTY / 20) + (maQTY % 20 !== 0 ? 1 : 0)) * 20;
    }

    if (maType === OMG_POWERGRIP_PLUS) {
      // 'OMG PLUS'
      return (Math.floor(maQTY / 8) + (maQTY % 8 !== 0 ? 1 : 0)) * 8;
    }

    if (maType === OMG_POWERGRIP_UNIVERSAL_7) {
      // 'OMG UNIVERSAL 7'
      return (Math.floor(maQTY / 2) + (maQTY % 2 !== 0 ? 1 : 0)) * 2;
    }

    return maType !== MA_HW_KIT_ONLY ? maQTY : 0;
  }

  /**
   *
   * Pattern: HW KIT, MA STRUT
   * @param {Object} options -
   * @param {string} options.maType - value from MA dropdown
   * @param {string} options.qtyForMAStrut - Quantity.calculateForMAStrut({ maType, maBracketQTY, maQTY });
   * @returns
   */
  static calculateForHWKITMAStrut({ maType, qtyForMAStrut }) {
    if (!maType || maType.startsWith('EF')) return '';

    return Math.floor(qtyForMAStrut / 10) + (qtyForMAStrut % 10 !== 0 ? 1 : 0);
  }

  /**
   *
   * Pattern: BOLT
   * @param {Object} options -
   * @param {string} options.tilt - 5 | 10 | 1010
   * @param {string} options.hd10BoltKitQty - For POLAR_BEAR_III_HD
   * @param {string} options.hd5BoltKitQty - For POLAR_BEAR_III_HD
   * @param {string} options.cFRBoltCount - Quantity.getcFRBoltCount(...)
   * @param {string} options.includeExtras - Default = true
   * @param {string} options.rackingProducts - clawFR
   * @returns
   */
  static calculateForBolt({
    tilt,
    hd10BoltKitQty,
    hd5BoltKitQty,
    cFRBoltCount,
    includeExtras = true,
    rackingProducts = CLAW_FR,
  }) {
    if (rackingProducts === POLAR_BEAR_III_HD) {
      if (Number(tilt) === 10) {
        return hd10BoltKitQty;
      }

      if (Number(tilt) === 5) {
        return hd5BoltKitQty;
      }
    }

    if (rackingProducts === CLAW_FR) {
      if (includeExtras) {
        if (mround(cFRBoltCount, 100) < cFRBoltCount) {
          return mround(cFRBoltCount + (cFRBoltCount % 100) + 100 / 2, 100);
        }

        return mround(cFRBoltCount, 100);
      }

      return cFRBoltCount;
    }

    return '';
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.cFRBoltQty - Quantity.getcFRBoltQty(...)
   * @param {string} options.numberBolts - Number of Bolts
   * @returns
   */
  static getcFRBoltCount({ numberBolts, cFRBoltQty }) {
    if (numberBolts !== '') {
      return numberBolts;
    }

    return cFRBoltQty;
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.numModules - Number of Modules
   * @param {string} options.tilt - 5 | 10 | 1010
   * @param {string} options.cFRBallastRailQtyForBallast - Quantity.getcFRBallastRailQtyForBallast(...)
   * @param {string} options.numSouthModules - Quantity.getNumSouthModules(...)
   * @returns
   */
  static getcFRBoltQty({
    numModules,
    tilt,
    cFRBallastRailQtyForBallast,
    numSouthModules,
  }) {
    const result =
      1.11 *
      (numModules * (tilt !== 1010 ? 8 : 6) +
        cFRBallastRailQtyForBallast * 2 +
        numSouthModules * 2);

    return roundup(result);
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.numModules - Number of Modules
   * @param {string} options.numSouthModules - Quantity.getNumSouthModules(...)
   * @param {string} options.tilt - 5 | 10 | 1010
   * @param {string} options.cFRBallastRailQty - Quantity.getcFRBallastRailQty(...)
   * @returns
   */
  static getcFRBallastRailQtyForBallast({
    numModules,
    numSouthModules,
    tilt,
    cFRBallastRailQty,
  }) {
    const roundResult = Math.round(numModules / numSouthModules);
    const ifResult = Number(tilt) === 1010 ? 2 : 1;

    return (
      cFRBallastRailQty - (2 * numSouthModules - 1) * (roundResult + ifResult)
    );
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.numSouthModules - Quantity.getNumSouthModules(...)
   * @param {string} options.numModules - Number of Modules
   * @param {string} options.tilt - 5 | 10 | 1010
   * @param {string} options.shortTraysORBallastRails - Number of Rails
   * @param {string} options.trayORBallastRailToModuleRatio - Rail to Module Ratio
   * @param {string} options.ballastRail2ModuleDefault - Default = 1
   * @returns
   */
  static getcFRBallastRailQty({
    numSouthModules,
    numModules,
    tilt,
    shortTraysORBallastRails,
    trayORBallastRailToModuleRatio,
    ballastRail2ModuleDefault = 1,
  }) {
    if (isBlank(shortTraysORBallastRails)) {
      const temp =
        (2 * numSouthModules - 1) *
        (Math.round(numModules / numSouthModules) +
          (Number(tilt) === 1010 ? 2 : 1));
      const tempRatio = isBlank(trayORBallastRailToModuleRatio)
        ? ballastRail2ModuleDefault * numModules
        : trayORBallastRailToModuleRatio * numModules;
      return temp + Math.ceil(tempRatio);
    }

    return shortTraysORBallastRails;
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.cFRBallastRailCount -  Quantity.getcFRBallastRailCount(...)
   * @param {string} options.clawKitQty - For POLAR_BEAR_III
   * @param {string} options.rackingProducts - clawFR
   * @param {string} options.includeExtras - Default true
   * @returns
   */
  static calculateForRail({
    cFRBallastRailCount,
    clawKitQty, // POLAR_BEAR_III
    rackingProducts = CLAW_FR,
    includeExtras = true,
  }) {
    if (
      rackingProducts === POLAR_BEAR_III ||
      rackingProducts === POLAR_BEAR_III_HD
    ) {
      return clawKitQty;
    }

    if (rackingProducts === CLAW_FR) {
      if (includeExtras) {
        if (mround(cFRBallastRailCount, 10) < cFRBallastRailCount) {
          return mround(
            cFRBallastRailCount + mod(cFRBallastRailCount, 10) + 10 / 2,
            10
          );
        }

        return mround(cFRBallastRailCount, 10);
      }

      return cFRBallastRailCount;
    }

    return '';
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.shortTraysORBallastRails - Number of Rails
   * @param {string} options.cFRBallastRailQty
   * @returns
   */
  static getcFRBallastRailCount({
    shortTraysORBallastRails,
    cFRBallastRailQty,
  }) {
    if (!isBlank(shortTraysORBallastRails)) {
      return shortTraysORBallastRails;
    }

    return cFRBallastRailQty;
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.numSouthModules - Number of South Modules
   * @param {string} options.numModules - Number of Modules
   * @returns
   */
  static getNumSouthModules({ numSouthModules, numModules }) {
    if (!isBlank(numSouthModules)) {
      return numSouthModules;
    }

    return Math.ceil(Math.sqrt(numModules));
  }

  /**
   *
   * Patter: MODULE CONNECTOR
   * @param {Object} options -
   * @param {string} options.connectorFormula -  Quantity.getConnectorFormula(...)
   * @param {string} options.rackingProducts - clawFR
   * @param {string} options.includeExtras - Default true
   * @returns
   */
  static calculateForModuleConnector({
    connectorFormula,
    rackingProducts = CLAW_FR,
    includeExtras = true,
  }) {
    if (rackingProducts === CLAW_FR && includeExtras) {
      if (mround(connectorFormula, 10) < connectorFormula) {
        return mround(
          connectorFormula + mod(connectorFormula, 10) + 10 / 2,
          10
        );
      }

      return mround(connectorFormula, 10);
    }

    return connectorFormula;
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.numModules - Numer of Modules
   * @param {string} options.tilt - 5 | 10 | 1010
   * @param {string} options.numSouthModules - Quantity.getNumSouthModules(...)
   * @param {string} options.docType - Estimate | Project Quote
   * @param {string} options.rackingProducts - clawFR
   * @returns
   */
  static getConnectorFormula({
    numModules,
    tilt,
    numSouthModules,
    docType,
    rackingProducts = CLAW_FR,
  }) {
    if (rackingProducts === POLAR_BEAR_III && Number(tilt) === 5) {
      if (!isBlank(numSouthModules)) {
        if (docType === ESTIMATE) {
          return 2 * numSouthModules;
        }

        return numSouthModules;
      }

      return Math.ceil(Math.sqrt(numModules)) * 2;
    }

    if (rackingProducts === CLAW_FR) {
      return numModules * 2;
    }

    return 0;
  }

  /**
   *
   * Pattern: CAM CLAW
   * @param {Object} options -
   * @param {string} options.camClawCountBuffered - Quantity.getCamClawCountBuffered(...)
   * @param {string} options.camClawCount -
   * @param {string} options.rackingProducts - clawFR
   * @param {string} options.includeExtras - Default true
   * @returns
   */
  static calculateForCamClaw({
    camClawCountBuffered,
    camClawCount,
    rackingProducts = CLAW_FR,
    includeExtras = true,
  }) {
    if (rackingProducts === CLAW_FR) {
      if (includeExtras) {
        if (mround(camClawCountBuffered, 20) < camClawCountBuffered) {
          return mround(
            camClawCountBuffered + mod(camClawCountBuffered, 20) + 20 / 2,
            20
          );
        }

        return mround(camClawCountBuffered, 20);
      }

      return camClawCount;
    }

    return '';
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.camClawCount - Quantity.getCamClawCount(...)
   * @returns
   */
  static getCamClawCountBuffered({ camClawCount }) {
    return roundup(camClawCount * 1.01, 0);
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.docType - Estimate | Project Quote
   * @param {string} options.camClaws - Number of Cams | Cam Claws (2nd field!!!)
   * @param {string} options.numModules - Numer of Modules
   * @returns
   */
  static getCamClawCount({ docType, camClaws, numModules }) {
    if (docType === COMPONENT_QUOTE) {
      return camClaws;
    }

    return numModules * 2;
  }

  /**
   *
   * Pattern: CAM
   * @param {Object} options -
   * @param {string} options.clawCount - Quantity.getClawCount(...)
   * @param {string} options.rackingProducts - ClawFR
   * @param {string} options.includeExtras - Default true
   * @returns
   */
  static calculateForCam({
    clawCount,
    rackingProducts = CLAW_FR,
    includeExtras = true,
  }) {
    if (rackingProducts === CLAW_FR && includeExtras) {
      if (mround(clawCount, 20) < clawCount) {
        return mround(clawCount + mod(clawCount, 20) + 20 / 2, 20);
      }

      return mround(clawCount, 20);
    }

    return clawCount;
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.docType - Estimate | Project Quote
   * @param {string} options.claws - Number of Cams | Cam Claws
   * @param {string} options.numModules - Number of Modules
   * @returns
   */
  static getClawCount({ docType, claws, numModules }) {
    if (docType === COMPONENT_QUOTE) {
      return claws;
    }

    return numModules * 2;
  }

  /**
   *
   * Pattern: DEFLECTOR
   * @param {Object} options -
   * @param {string} options.deflectorFormula - Quantity.getDeflectorFormula(...)
   * @param {string} options.rackingProducts - clawFR
   * @param {string} options.includeExtras - Default true
   * @returns
   */
  static calculateForDeflector({
    deflectorFormula,
    rackingProducts = CLAW_FR,
    includeExtras = true,
  }) {
    if (rackingProducts === CLAW_FR && includeExtras) {
      if (mround(deflectorFormula, 10) < deflectorFormula) {
        return mround(
          deflectorFormula + mod(deflectorFormula, 10) + 10 / 2,
          10
        );
      }

      return mround(deflectorFormula, 10);
    }

    return deflectorFormula;
  }

  /**
   *
   * @param {Object} options -
   * @param {string} options.deflectors - Number of Deflectors
   * @param {string} options.tilt - 5 | 10 | 1010
   * @param {string} options.numModules - Number of Modules
   * @param {string} options.numSsupports - For  Polar Bear III HD
   * @param {string} options.trayORBallastRailToModuleRatio - Rail to Module Ratio
   * @param {string} options.pb35DShortTrayPercent - For Polar Bear III HD
   * @param {string} options.pb35Tray2ModuleDefault - For Polar Bear III HD
   * @param {string} options.rackingProducts - clawFR
   * @returns
   */
  static getDeflectorFormula({
    deflectors,
    tilt,
    numModules,
    numSsupports,
    trayORBallastRailToModuleRatio,
    pb35DShortTrayPercent,
    pb35Tray2ModuleDefault,
    rackingProducts = CLAW_FR,
  }) {
    if (deflectors !== '') {
      return deflectors;
    }

    if (rackingProducts === POLAR_BEAR_III_HD) {
      if (tilt === 10) {
        return numModules + roundup(numSsupports / 2, 0);
      }

      if (!isBlank(trayORBallastRailToModuleRatio)) {
        return roundup(
          numModules *
            trayORBallastRailToModuleRatio *
            (1 - pb35DShortTrayPercent),
          0
        );
      }

      return roundup(
        numModules * pb35Tray2ModuleDefault * (1 - pb35DShortTrayPercent),
        0
      );
    }

    if (tilt !== 1010) {
      return numModules;
    }

    return 0;
  }

  /**
   *
   * Pattern: BASE
   * @param {Object} options -
   * @param {string} options.numMsupportsFormula - Quantity.getNumMsupportsFormula(...)
   * @param {string} options.rackingProducts - clawFR
   * @param {string} options.includeExtras - Default true
   * @returns
   */
  static calculateForBase({
    numMsupportsFormula,
    rackingProducts = CLAW_FR,
    includeExtras = true,
  }) {
    if (rackingProducts === CLAW_FR && includeExtras) {
      if (mround(numMsupportsFormula, 10) < numMsupportsFormula) {
        return mround(numMsupportsFormula + (numMsupportsFormula % 10) + 5, 10);
      }

      return mround(numMsupportsFormula, 10);
    }

    return numMsupportsFormula;
  }

  /**
   *
   * Pattern: BASE
   * @param {Object} options -
   * @param {string} options.stdSupports - Number of Bases
   * @param {string} options.numSouthModules -
   * @param {string} options.totalSupports - Number of Supports (Total)
   * @param {string} options.tilt - 5 | 10 | 1010
   * @param {string} options.numModules - Number of Modules
   * @param {string} options.numSsupports - for Polar Bear III HD
   * @param {string} options.numSupportsPb3 - for Polar Bear III HD
   * @param {string} options.support2ModuleRatio - Base to Module Ratio
   * @param {string} options.numSupports - Number of South Modules
   * @param {string} options.support2moduleDefault - Default 2.22 for Polar Bear III HD
   * @param {string} options.rackingProducts - clawFR
   * @returns
   */
  static getNumMsupportsFormula({
    stdSupports,
    numSouthModules,
    totalSupports,
    tilt,
    numModules,
    numSsupports,
    numSupportsPb3,
    support2ModuleRatio,
    numSupports,
    support2moduleDefault = 2.22,
    rackingProducts = CLAW_FR,
  }) {
    if (
      rackingProducts !== POLAR_BEAR_III &&
      rackingProducts !== POLAR_BEAR_III_HD &&
      rackingProducts !== CLAW_FR
    ) {
      return 0;
    }

    if (isBlank(stdSupports)) {
      if (!isBlank(numSouthModules)) {
        if (isBlank(totalSupports)) {
          if (rackingProducts !== CLAW_FR && tilt === 10) {
            return 2 * (numModules - numSsupports / 2);
          }

          if (rackingProducts === POLAR_BEAR_III_HD && tilt === 5) {
            return 2 * numModules;
          }

          return 2 * numModules + numSupportsPb3;
        }

        return totalSupports - numSsupports - numSupports;
      }

      if (isBlank(totalSupports)) {
        if (rackingProducts !== CLAW_FR && tilt === 10) {
          if (isBlank(support2ModuleRatio)) {
            return (
              roundup(
                (support2moduleDefault * (numModules - numSsupports / 2)) / 2,
                0
              ) * 2
            );
          }

          return (
            roundup(
              (support2ModuleRatio * (numModules - numSsupports / 2)) / 2,
              0
            ) * 2
          );
        }

        if (
          (rackingProducts === POLAR_BEAR_III_HD && tilt === 5) ||
          rackingProducts === CLAW_FR
        ) {
          if (isBlank(support2ModuleRatio)) {
            return roundup((support2moduleDefault * numModules) / 2, 0) * 2;
          }

          return roundup((support2ModuleRatio * numModules) / 2, 0) * 2;
        }

        if (isBlank(support2ModuleRatio)) {
          return (
            roundup(
              (support2moduleDefault * numModules + numSupportsPb3) / 2,
              0
            ) * 2
          );
        }

        return (
          roundup((support2ModuleRatio * numModules + numSupportsPb3) / 2, 0) *
          2
        );
      }

      return totalSupports - numSsupports - numSupports;
    }

    return stdSupports;
  }

  /**
   *
   * @param {Object} options
   * @param {string} options.docType - Estimate | Project Quote
   * @param {string} options.numSouthModules - Number of South Modules
   * @param {string} options.support2ModuleRatio - Base to Module Ratio
   * @param {string} options.numModules - Number of Modules
   * @param {string} options.support2ModuleDefaultPB3
   * @returns
   */
  static getNumSupportsPB3({
    docType,
    numSouthModules,
    support2ModuleRatio,
    numModules,
    support2ModuleDefaultPB3 = 2.22,
  }) {
    if (docType === 'Estimate') {
      if (numSouthModules === '') {
        if (support2ModuleRatio !== '') {
          return (
            roundup((support2ModuleRatio * Math.sqrt(numModules)) / 2, 0) * 2
          );
        }

        return (
          roundup((support2ModuleDefaultPB3 * Math.sqrt(numModules)) / 2, 0) * 2
        );
      }

      return 2 * numSouthModules;
    }

    if (numSouthModules === '') {
      return (
        roundup((support2ModuleDefaultPB3 * Math.sqrt(numModules)) / 2, 0) * 2
      );
    }

    return numSouthModules;
  }
}
