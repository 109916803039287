import config from './config';
import Access from './Access';

class ACL {
  #inited;

  constructor() {
    this.levelToAccess = new Map();

    this.inited = false;

    this.setAccess(config.defaultAccess);
  }

  setAccess(config) {
    const access = new Access(config, this);

    this.levelToAccess.set(access.name, access);

    return access;
  }

  getAccess(name) {
    const access =
      this.levelToAccess.get(name) ||
      this.levelToAccess.get(config.defaultAccess.Name);
    return access;
  }

  isAccessAllow(chain) {
    if (Array.isArray(chain)) {
      let i = -1;
      const len = chain.length;

      if (len === 0) return false;

      let allowed = true;

      while (++i < len) {
        const subchain = chain[i];

        if (Array.isArray(subchain)) {
          allowed =
            allowed &&
            subchain.some((c) => {
              return this.isAccessToOnePermissionAllowed(c);
            });
        } else {
          allowed = allowed && this.isAccessToOnePermissionAllowed(subchain);
        }
      }

      return allowed;
    }

    return this.isAccessToOnePermissionAllowed(chain);
  }

  isAccessToOnePermissionAllowed(chain) {
    const props = chain.split('.');

    const access = this.getAccess(props[0]);

    return access.isAllow(props[1]);
  }

  clear() {
    this.levelToAccess.clear();
  }

  isInited() {
    return this.inited;
  }

  get inited() {
    return this.#inited;
  }

  set inited(value) {
    this.#inited = value;
  }
}

export default ACL;
