const EstimatesRefreshBomHeaders = [
  { accessor: 'revisionNumber', Header: 'Revision', width: 65 },
  { accessor: 'createdAt', Header: 'Created date', type: 'date' },
];

const additionalPartNames = {
  5000532: 'FrameEnchancer kit',
  5000419: 'Lock Claw Clip kit',
  5000533: 'Cam Seat Spacer  kit ',
};

const ADMIN_UI = 'AdminUI';

const ESTIMATE = 'Estimate';
const PROJECT_QUOTE = 'Project Quote';
const COMPONENT_QUOTE = 'Component Quote';

const ERROR_CUSTOM_MODULE_SINGLE = 'ERROR_CUSTOM_MODULE_SINGLE';
const ERROR_CUSTOM_MODULE_RACKING_PRODUCT =
  'ERROR_CUSTOM_MODULE_RACKING_PRODUCT';
const ERROR_CUSTOM_MODULE_DATASHEET = 'ERROR_CUSTOM_MODULE_DATASHEET';
const ERROR_CUSTOM_MODULE_DEFAULT_PROPS = 'ERROR_CUSTOM_MODULE_DEFAULT_PROPS';

const messages = {
  save: {
    success: 'Estimate saved successfully',
    error: 'Error saving estimate',
  },
  [ERROR_CUSTOM_MODULE_DEFAULT_PROPS]:
    'Can not receive valid default props for custom module',
  [ERROR_CUSTOM_MODULE_SINGLE]:
    'You can only use a single custom module. Please remove the custom module first, if you wish to add another module',
  [ERROR_CUSTOM_MODULE_RACKING_PRODUCT]:
    'Cannot find a compatible racking product.',
  [ERROR_CUSTOM_MODULE_DATASHEET]: 'Datasheet is required!',
};

const CLAW_FR = 'clawFR';
const CLAW_FR_PLUS = 'clawFRplus';
const POWER_CAP = 'PowerCap';

const POLAR_BEAR_III_HD = 'Polar Bear III HD';
const POLAR_BEAR_III = 'Polar Bear III';

const OMG_POWERGRIP = 'OMG_POWERGRIP';
const OMG_POWERGRIP_PLUS = 'OMG_POWERGRIP_PLUS';
const OMG_POWERGRIP_UNIVERSAL_7 = 'OMG_POWERGRIP_UNIVERSAL_7';
const MA_HW_KIT_ONLY = 'MA HW KIT ONLY';

const OPERATION_SAVE = 'operaation:save';
const UNSELECTED = 'Unselected';

const HWKITMASTRUT = 'HW KIT, MA STRUT';
const MASTRUT = 'MA STRUT';
const MA = 'MA';
const BOLT = 'BOLT';
const BLT = 'BLT';
const RAIL = 'RAIL';
const MODULE_CONNECTOR = 'MODULE CONNECTOR';
const CAM_CLAW = 'CAM CLAW';
const CAM = 'CAM';
const DEFLECTOR = 'DEFLECTOR';
const BASE = 'BASE';
const CAM_SEAT_SPACER = 'Cam Seat Spacer';
const FRAME_ENHANCER = 'Frame Enhancer';
const LOCK_CLAW_CLIP = 'Lock Claw Clip';

const DELIMITER = ':@:';
const RACKING_PRODUCTS_10D = 'clawFR 10 Degree';

const DEFAULT_VALUES = {
  POWER_CAP: {
    MA: 'TBD',
    MANUFACTURER: 'Watershed Geo',
    MEMBRANE: 'Turf_And_Sand',
    NUMBER_MAs: 0,
  },
};

export {
  ADMIN_UI,
  DEFAULT_VALUES,
  EstimatesRefreshBomHeaders,
  additionalPartNames,
  messages,
  ESTIMATE,
  PROJECT_QUOTE,
  COMPONENT_QUOTE,
  ERROR_CUSTOM_MODULE_SINGLE,
  ERROR_CUSTOM_MODULE_RACKING_PRODUCT,
  ERROR_CUSTOM_MODULE_DATASHEET,
  ERROR_CUSTOM_MODULE_DEFAULT_PROPS,
  CLAW_FR,
  CLAW_FR_PLUS,
  POLAR_BEAR_III,
  POLAR_BEAR_III_HD,
  OMG_POWERGRIP,
  OMG_POWERGRIP_PLUS,
  OMG_POWERGRIP_UNIVERSAL_7,
  MA_HW_KIT_ONLY,
  OPERATION_SAVE,
  UNSELECTED,
  HWKITMASTRUT,
  MASTRUT,
  MA,
  BOLT,
  BLT,
  RAIL,
  MODULE_CONNECTOR,
  CAM_CLAW,
  CAM,
  DEFLECTOR,
  BASE,
  CAM_SEAT_SPACER,
  FRAME_ENHANCER,
  LOCK_CLAW_CLIP,
  DELIMITER,
  RACKING_PRODUCTS_10D,
  POWER_CAP,
};
