import React from 'react';
import PropTypes from 'prop-types';

export function SimpleTextInput({
  errorsFieldName,
  fieldName,
  type,
  label,
  placeholder,
  readOnly = false,
  colStyle = 'col-sm-6',
  supLabel = null,
  supLabelOnClick = null,
  register,
}) {
  return (
    <div className={`${colStyle} text-input__block floatLeft`}>
      <label htmlFor={fieldName} className="form-input__label">
        {label}
        {supLabel && <sup onClick={supLabelOnClick}>({supLabel})</sup>}
      </label>
      <input
        className={`form-control ${errorsFieldName ? 'is-invalid' : ''}`}
        {...(type && { type })}
        name={fieldName}
        readOnly={readOnly}
        placeholder={placeholder}
        {...register(fieldName)}
      />
      {errorsFieldName && (
        <div className="pc-inline pull-right text-right">
          <span className="invalid-feedback">{errorsFieldName.message}</span>
        </div>
      )}
    </div>
  );
}

SimpleTextInput.propTypes = {
  errorsFieldName: PropTypes.any,
  fieldName: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  colStyle: PropTypes.string,
  supLabel: PropTypes.string,
  supLabelOnClick: PropTypes.func,
  register: PropTypes.func,
};

SimpleTextInput.defaultProps = {
  errorsFieldName: null,
  fieldName: '',
  type: '',
  label: '',
  placeholder: '',
  readOnly: false,
  colStyle: 'col-sm-6',
  supLabel: null,
  supLabelOnClick: null,
  register: () => {},
};

export default SimpleTextInput;
