import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FilterListIcon from 'mdi-react/FilterListIcon';
import { Menu, MenuItem, TableButton } from './MaterialTableElements';

function MatTableFilterButton({ onRequestSort, disabled }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (property) => (event) => {
    onRequestSort(event, property);
    handleClose();
  };

  return (
    <div>
      <TableButton
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        <FilterListIcon />
      </TableButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        disabled={disabled}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSort('name')} disabled={disabled}>
          Name
        </MenuItem>
      </Menu>
    </div>
  );
}

MatTableFilterButton.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MatTableFilterButton;
