import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete } from '@material-ui/lab';

import { v4 as uuidV4 } from 'uuid';

import {
  filterOptions,
  findValue,
  getOptionLabel,
  isOptionEqualToValue,
  renderInput,
  renderOption,
} from './utils';

import './assets/style.scss';

const getCustomStyles = ({ error }) => ({
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#004D7A' : 'inherit',
    '&:hover': {
      backgroundColor:
        state.isFocused && !state.isSelected
          ? 'rgba(0, 77, 122, 0.1)'
          : state.isFocused && state.isSelected
          ? '#004D7A'
          : provided.backgroundColor,
    },
  }),
  control: (provided, state) => {
    return {
      ...provided,
      height: 42,
      borderRadius: 0,
      borderColor: error ? '#f44336' : '#004D7A',
      boxShadow: state.isFocused ? '0 0 0 0 #004D7A' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? '#004D7A' : provided.borderColor,
      },
    };
  },
  indicatorsContainer: (provided) => {
    return { ...provided, height: 42 };
  },
  singleValue: (provided) => {
    return provided;
  },
  valueContainer: (provided) => {
    return { ...provided, height: 42 };
  },
});

function AutocompleteExtended({
  value: initValue,
  label,
  options,
  disabled,
  loading,
  onChange,
  error,
}) {
  const [value, setValue] = useState(findValue(initValue, options));

  useEffect(() => {
    if (initValue?.id === value?.id) return;

    const value = findValue(initValue, options);

    setValue(value);
  }, [options]);

  const onChangeValue = useCallback((value) => {
    setValue(value);
    onChange(value);
  });

  const onChangeAutoComplete = useCallback((event, newValue) => {
    if (typeof newValue === 'string') {
      onChangeValue({
        label: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      if (value && newValue.action === 'update') {
        value.name = newValue.inputValue;
        value.label = newValue.inputValue;
        onChangeValue(value);
      } else {
        onChangeValue({
          id: uuidV4(),
          name: newValue.inputValue,
          label: newValue.inputValue,
        });
      }
    } else {
      onChangeValue(newValue);
    }
  });

  const onFilterOptions = useCallback((options, params) => {
    const filtered = filterOptions(options, params);

    const { inputValue } = params;

    const isExisting = options.some((option) => inputValue === option.label);

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        action: 'update',
        label: `Save: "${inputValue}"`,
      });
    }

    return filtered;
  });

  return (
    <div className="autocomplete">
      {label && <div className="label">{label}</div>}
      <Autocomplete
        id="autocomplete"
        className={`autocomplete__select ${error ? 'error' : ''}`}
        loading={loading}
        loadingText="Loading..."
        noOptionsText="No options"
        value={value}
        options={options}
        onChange={onChangeAutoComplete}
        filterOptions={onFilterOptions}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionSelected={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={renderInput}
        styles={getCustomStyles({})}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
      />
    </div>
  );
}

AutocompleteExtended.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

AutocompleteExtended.defaultProps = {
  label: '',
  value: '',
  options: [],
  disabled: false,
  loading: false,
  onChange: () => {},
  error: '',
};

export default AutocompleteExtended;
