const validationSchema = {
  AFI: {
    name: 'A-FI',
    type: 'number',
    required: false,
  },
  AFT: {
    name: 'A-FT',
    type: 'number',
    required: false,
  },
  AFO: {
    name: 'A-FO',
    type: 'number',
    required: false,
  },
  AMINGAP: {
    name: 'A-MIN gap',
    type: 'number',
    required: false,
  },
  AMAXGAP: {
    name: 'A-MAX gap',
    type: 'number',
    required: false,
  },
  AMINGAPPROTRUSION: {
    name: 'A-MIN Gap Protrusion form wall',
    type: 'number',
    required: false,
  },
};

export default validationSchema;
