import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { getAccessDebugToggle, getAcl } from 'redux/selectors/selectors';
import { SHOW_FS_VALUES_PROFILE } from 'redux/constants/category';
import { flagSelector } from 'redux/flags/selectors';
import { setFlag } from 'redux/flags/actions';
import {
  SimpleTextInput as simpleTextInput,
  CheckBoxInput as checkboxInput,
} from '../../../common/ReactHookForm';
import SelectInput from '../../../common/SelectInput';
import Button from '../../../common/Button';

export const SHOW = 'show';
export const HIDE = 'hide';

const unitOptions = [
  { value: 'Imperial', label: 'Imperial' },
  { value: 'Metric', label: 'Metric' },
];

const getErrorMessage = (fieldName, errType) => {
  const errs = {
    required: `${fieldName} is required!`,
    maxLength: `Max length exceeded`,
  };

  return errs[errType] || 'Validation error!';
};

function ProfileForm(props) {
  const {
    values,
    onSubmit,
    updating,
    disabled,
    loginHelioscope,
    logoutHelioscope,
    helioscope,
    acl,
    debugMode,
    toggleDebugMode,
    accessDebugToggle,
  } = props;

  const [isDisable, setIsDisable] = useState(true);

  const ProfileSchema = yup.object().shape({
    firstName: yup.string().required(getErrorMessage('First Name', 'required')),
    lastName: yup.string().required(getErrorMessage('Last Name', 'required')),
    phone: yup
      .string()
      // .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/, "Invalid phone")
      // .required(getErrorMessage('Phone', 'required'))
      .nullable(),
    email: yup
      .string()
      .required(getErrorMessage('Email', 'required'))
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email'
      ),
    companyName: yup
      .string()
      .required(getErrorMessage('Company Name', 'required')),
    companyStreetAddress: yup
      .string()
      .required(getErrorMessage('Company Street Address', 'required')),
    companyCity: yup
      .string()
      .required(getErrorMessage('Company City', 'required')),
    companyState: yup
      .string()
      .required(getErrorMessage('Company State', 'required')),
    companyZip: yup
      .string()
      .required(getErrorMessage('Company ZIP', 'required'))
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits'),
    companyCountry: yup
      .string()
      .required(getErrorMessage('Company Country State', 'required')),
    recieveNotification: yup.boolean(),
    helioscopeLogin: yup.string().nullable(),
    helioscopePass: yup.string().nullable(),
    defaultSubarrayFS: yup
      .number()
      .required(getErrorMessage('defaultSubarrayFS', 'required'))
      .test(
        'maxDigitsAfterDecimal',
        'number field must have 2 digits after decimal or less',
        (number) => Number.isInteger(number * 10 ** 2)
      ),
    minDefaultSubarrayFS: yup
      .number()
      .required(getErrorMessage('minDefaultSubarrayFS', 'required'))
      .test(
        'maxDigitsAfterDecimal',
        'number field must have 2 digits after decimal or less',
        (number) => Number.isInteger(number * 10 ** 2)
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    resetField,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(ProfileSchema),
    defaultValues: { ...values },
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
  });

  const [heliscopePswFieldType, setHeliscopePswFieldType] = useState(
    'password'
  );

  const toggleHelioscopeAccount = () => {
    const helioscopeLogin = getValues('helioscopeLogin');
    const helioscopePass = getValues('helioscopePass');

    if (helioscope.sessionId) {
      logoutHelioscope();
      resetField('helioscopeLogin');
      resetField('helioscopePass');
    } else {
      loginHelioscope({ helioscopeLogin, helioscopePass });
    }
  };

  const renderHelioscopeInfo = () => (
    <div className="col-sm-12">
      <p>Helioscope account activated</p>
    </div>
  );

  const toggleHeliscopePswFieldType = useCallback(() =>
    setHeliscopePswFieldType(
      heliscopePswFieldType === 'text' ? 'password' : 'text'
    )
  );

  const renderHelioscopeForm = () => (
    <form autoComplete="on" className="form-group">
      {simpleTextInput({
        errorsFieldName: errors.helioscopeLogin,
        fieldName: 'helioscopeLogin',
        type: 'email',
        label: 'Helioscope Email Address',
        placeholder: 'eg. john@example.com',
        colStyle: 'col-sm-12',
        register,
      })}
      {simpleTextInput({
        errorsFieldName: errors.helioscopePass,
        fieldName: 'helioscopePass',
        type: heliscopePswFieldType,
        label: 'Helioscope password',
        placeholder: 'password',
        colStyle: 'col-sm-12',
        supLabel: heliscopePswFieldType === 'text' ? HIDE : SHOW,
        supLabelOnClick: toggleHeliscopePswFieldType,
        register,
      })}
    </form>
  );

  const textInputs = [
    {
      errorsFieldName: errors.firstName,
      fieldName: 'firstName',
      label: 'First Name *',
      placeholder: 'eg. John',
      readOnly: disabled,
      register,
    },
    {
      errorsFieldName: errors.lastName,
      fieldName: 'lastName',
      label: 'Last Name *',
      placeholder: 'eg. Doe',
      readOnly: disabled,
      register,
    },

    {
      errorsFieldName: errors.phone,
      fieldName: 'phone',
      label: 'Phone Number *',
      placeholder: 'eg. +1(555)555-55-55',
      readOnly: disabled,
      register,
    },
    {
      errorsFieldName: errors.email,
      fieldName: 'email',
      label: 'Email Address *',
      placeholder: 'eg. john@example.com',
      readOnly: isDisable,
      register,
    },

    {
      errorsFieldName: errors.companyName,
      fieldName: 'companyName',
      label: 'Company Name *',
      placeholder: 'eg. ABC Company',
      readOnly: disabled,
      register,
    },
    {
      errorsFieldName: errors.companyStreetAddress,
      fieldName: 'companyStreetAddress',
      label: 'Company Street Address *',
      placeholder: 'eg. 6 Pineknoll Court',
      readOnly: disabled,
      register,
    },

    {
      errorsFieldName: errors.companyCity,
      fieldName: 'companyCity',
      label: 'Company Street Address *',
      placeholder: 'eg. Helotes',
      readOnly: disabled,
      register,
    },
    {
      errorsFieldName: errors.companyState,
      fieldName: 'companyState',
      label: 'Company State/Province *',
      placeholder: 'eg. TX',
      readOnly: disabled,
      register,
    },

    {
      errorsFieldName: errors.companyZip,
      fieldName: 'companyZip',
      label: 'Company Zip Code *',
      placeholder: 'eg. 78023',
      readOnly: disabled,
      register,
    },
    {
      errorsFieldName: errors.companyCountry,
      fieldName: 'companyCountry',
      label: 'Company Country *',
      placeholder: 'eg. United State',
      readOnly: disabled,
      register,
    },
  ];

  const trapOnSubmit = (data) => onSubmit(data);

  return (
    <div className="profile-form">
      <form onSubmit={handleSubmit(trapOnSubmit)}>
        <div>
          <div className="row margin-row">
            <div className="col-sm-8 floatLeft">
              <div className="form-group">
                {textInputs.map((el) => simpleTextInput({ ...el }))}
              </div>
              <div className="form-group">
                <div className="col-sm-6 text-input__block floatLeft">
                  <Controller
                    render={({ field }) => (
                      <SelectInput
                        name="selUnit"
                        label="Unit of measure"
                        options={unitOptions}
                        disabled={disabled}
                        onChange={(name, selected) => {
                          field.onChange(selected);
                        }}
                        value={field.value}
                        error={errors.unit}
                        placeholder=""
                      />
                    )}
                    name="unit"
                    control={control}
                  />
                </div>
                <div className="col-sm-6 text-input__block floatLeft">
                  {checkboxInput({
                    fieldName: 'recieveNotification',
                    label: 'Receive Email notifications',
                    register,
                  })}
                </div>
              </div>
              {acl.getAccess(SHOW_FS_VALUES_PROFILE).allowShow() && (
                <div className="form-group">
                  {simpleTextInput({
                    errorsFieldName: errors.defaultSubarrayFS,
                    fieldName: 'defaultSubarrayFS',
                    label: 'Default Subarray FS',
                    placeholder: '',
                    readOnly: isDisable,
                    register,
                  })}
                  {simpleTextInput({
                    errorsFieldName: errors.defaultModuleFS,
                    fieldName: 'defaultModuleFS',
                    label: 'Default Module/Dome FS',
                    placeholder: '',
                    readOnly: isDisable,
                    register,
                  })}
                </div>
              )}
            </div>
            <div className="col-sm-4 floatLeft">
              {helioscope.sessionId
                ? renderHelioscopeInfo()
                : renderHelioscopeForm()}
              <div style={{ clear: 'both' }} />
              <div className="col-sm-12 helioscope-container">
                <Button
                  loading={updating}
                  disabled={disabled}
                  className="buttons_margin-right_0 pcbtn-basic"
                  type="button"
                  onClick={toggleHelioscopeAccount}
                  style={{ position: 'relative', marginRight: 0 }}
                >
                  {helioscope.sessionId ? 'Logout' : 'Test login'}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ clear: 'both', height: '50px' }} />
        <hr />
        <div className="profile-form__footer">
          {accessDebugToggle.allowShow() && accessDebugToggle.allowEnable() && (
            <Button
              onClick={() => toggleDebugMode(!debugMode)}
              type="button"
              theme="pcd-grey"
            >
              Turn Debug Mode {debugMode ? 'OFF' : 'ON'}
            </Button>
          )}
          <Button
            loading={updating}
            disabled={disabled}
            type="submit"
            theme="pcd-success"
            style={{ position: 'relative', marginRight: 0 }}
          >
            Update
          </Button>
        </div>
      </form>
    </div>
  );
}

ProfileForm.propTypes = {
  values: PropTypes.object.isRequired, // eslint-disable-line
  touched: PropTypes.object.isRequired, // eslint-disable-line
  errors: PropTypes.object.isRequired, // eslint-disable-line
  updating: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loginHelioscope: PropTypes.func.isRequired,
  logoutHelioscope: PropTypes.func.isRequired,
  helioscope: PropTypes.object.isRequired, // eslint-disable-line
  acl: PropTypes.object.isRequired, // eslint-disable-line
  accessDebugToggle: PropTypes.object.isRequired, // eslint-disable-line
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    debugMode: flagSelector(state, 'mode.debug', false),
    acl: getAcl(state),
    accessDebugToggle: getAccessDebugToggle(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleDebugMode: (status) => {
    dispatch(setFlag('mode.debug', status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
