import React, { useCallback, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import PropsTypes from 'prop-types';
import { getObjProp } from 'utils';

function EditViewField({
  type = 'text',
  path,
  field,
  fieldsValues,
  onChangeField,
  error,
  isReadOnly,
  mode,
  defaultValue,
}) {
  const value = useMemo(
    () =>
      getObjProp(
        fieldsValues,
        path || field,
        mode === 'Create' ? defaultValue || '' : '-'
      ),
    [fieldsValues, path, field]
  );
  const onChange = useCallback(
    // eslint-disable-next-line
    ({ target: { value } }) => onChangeField(field, value, path),
    [onChangeField, path, field]
  );
  const newType = type === 'numeric' ? 'number' : type;
  const extraOpts =
    field === 'AllowedDomains'
      ? {
          rows: 10,
          multiline: true,
        }
      : {};
  return mode === 'Edit' || mode === 'Create' ? (
    <TextField
      id={field}
      type={newType}
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={error || ''}
      disabled={mode !== 'Create' && isReadOnly}
      {...extraOpts}
    />
  ) : Array.isArray(value) ? (
    value.join(',')
  ) : (
    value || null
  );
}

EditViewField.propTypes = {
  type: PropsTypes.string,
  path: PropsTypes.string,
  field: PropsTypes.string,
  fieldsValues: PropsTypes.object,
  error: PropsTypes.string,
  onChangeField: PropsTypes.func,
  isReadOnly: PropsTypes.bool,
  mode: PropsTypes.string,
  defaultValue: PropsTypes.string,
};

EditViewField.defaultProps = {
  type: '',
  path: '',
  field: '',
  fieldsValues: {},
  error: '',
  onChangeField: () => ({}),
  isReadOnly: false,
  mode: '',
  defaultValue: '',
};

export default EditViewField;
