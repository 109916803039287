import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import cache from 'utils/Cache';

import { api, inlineFilters } from 'utils/fetch';

import Toaster from 'components/ToasterQueue';
import MaterialTable from 'components/MaterialTable';
import VariantsModal from 'components/SelectListModal';
import { getEstimatesHeaders, getDeleteAction } from './utils';

import Dialog from './Estimate/components/Dialog';

import './style.scss';

const EstimatesVariantsHeaders = [
  {
    accessor: 'variantNumber',
    Header: 'Variant Number',
    width: 65,
    value: null,
  },
  { accessor: 'projectName', Header: 'Project Name', width: 65, value: '' },
  { accessor: 'customerName', Header: 'Customer Name', value: '' },
  { accessor: 'description', Header: 'Description', value: '' },
  { accessor: 'created', Header: 'Created date', type: 'date' },
  { accessor: 'notes', Header: 'Note', value: '' },
];

class Estimates extends React.PureComponent {
  constructor(props) {
    super(props);

    const { headers, detailViewCols } = getEstimatesHeaders();

    this.headers = headers;
    this.detailViewCols = detailViewCols;

    this.onceCallRequestSalesforcePrices = false;

    this.tableRef = React.createRef(null);

    this.selectListFooter = (
      <Button className="btn btn-primary">
        <Link to="./estimate/blank">Create Estimate</Link>
      </Button>
    );

    this.variantsToolbarActions = {
      button: {
        disabled: false,
        label: 'Edit',
        onClick: this.onClickVariant,
      },
    };

    this.state = {
      variants: {
        open: false,
        loading: false,
        list: [],
        headers: [],
      },
      dialog: {
        open: false,
        data: null,
      },
    };
  }

  componentDidUpdate() {
    if (this.props.token && !this.onceCallRequestSalesforcePrices) {
      this.onceCallRequestSalesforcePrices = true;
      cache.setSecret(this.props.token);
      cache.getSalesforcePrices();
    }
  }

  goToEstimate = (id) => {
    if (id) {
      this.props.history.push(`/estimate/${id}/create/quote`);
    } else {
      this.props.history.push(`/estimate/blank`);
    }
  };

  openVariants = async (data) => {
    if (!data) return;

    const { children, ...others } = data;

    others._id = others.id;

    const list = [others];

    if (Array.isArray(children)) {
      children.forEach((item) => {
        list.push({
          ...item,
          _id: item.id,
        });
      });
    }

    this.setState({
      variants: {
        open: true,
        loading: false,
        title: 'Estimates',
        headers: EstimatesVariantsHeaders,
        list,
        count: list.length,
      },
    });
  };

  onCloseVariants = () => {
    const { variants } = this.state;
    this.setState({ variants: { ...variants, open: false } });
  };

  onClickVariant = (item) => {
    this.onCloseVariants();
    this.props.history.push(`/estimate/${item._id}/edit`);
  };

  onClickDeleteEstimate = async (id) => {
    this.setState({
      dialog: {
        open: true,
        data: getDeleteAction(id),
      },
    });
  };

  onHandleDialogClose = () => {
    this.setState({ dialog: { open: false, data: null } });
  };

  onHandleDialogAction = async () => {
    try {
      this.tableRef.current.setLoading(true);

      const { dialog } = this.state;

      await dialog.data.action();

      this.tableRef.current.deleteItem(dialog.data.metadata.id);

      Toaster.success('The estimate was deleted successfully');

      return true;
    } catch (err) {
      Toaster.error(err?.message?.message ? err.message.message : err?.message);
      return false;
    } finally {
      this.tableRef.current.setLoading(false);
    }
  };

  render() {
    const { variants, dialog } = this.state;

    return (
      <Container className="roles-page">
        <Row>
          <MaterialTable
            ref={this.tableRef}
            tableProps={{
              title: 'Cost Estimator',
              actions: {
                goToEstimate: this.goToEstimate,
              },
              canDelete: true,
              name: 'Estimates',
              temp: true,
              selectListFooter: this.selectListFooter,
              selectListSubmitLabel: 'Create Project Quote',
            }}
            remoteData
            middleToolbar={null}
            columns={this.headers}
            getData={async (param) => {
              const newParams = inlineFilters(param);
              let result;
              try {
                result = await api.getEstimates(newParams);
              } catch {
                result = {
                  data: {
                    data: [],
                    pagination: {
                      totalItems: 0,
                    },
                  },
                  count: 0,
                };
              }
              return {
                ...result,
                count: result.data.pagination.totalItems,
                data: result.data,
              };
            }}
            detailsView={{
              cols: this.detailViewCols,
              getItem: api.getEstimate,
              idFieldName: 'id',
              canBreakDelete: true,
              onDelete: this.onClickDeleteEstimate,
              onSave: async (id, payload, createEstimate) => {
                if (createEstimate) {
                  const response = await api.postEstimate(payload);
                  Toaster.success(`The new estimate was created successfully`);
                  return response;
                }
                return await api.putEstimate(id, payload);
              },
            }}
            onDetailViewClick={(e, data) => this.openVariants(data)}
            onRowClick={(e, data) => this.openVariants(data)}
          />
          {variants.open && (
            <VariantsModal
              title={variants.title}
              isLoading={variants.loading}
              open={variants.open}
              list={variants.list}
              count={variants.count}
              headers={variants.headers}
              handleClose={this.onCloseVariants}
              withPagination={false}
              toolbarActions={this.variantsToolbarActions}
            />
          )}
          {dialog.open && (
            <Dialog
              {...dialog.data}
              isOpen={!!dialog.open}
              title={dialog.data.modalTitle}
              handleAction={this.onHandleDialogAction}
              handleClose={this.onHandleDialogClose}
            />
          )}
        </Row>
      </Container>
    );
  }
}

Estimates.propTypes = {
  token: PropTypes.string,
};

Estimates.defaultProps = {
  token: null,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth?.token,
  };
};

export default connect(mapStateToProps)(Estimates);
