import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './assets/style.scss';

import Fetch from 'components/common/Fetch';
import SelectInput from 'components/common/SelectInput';

import { isObject } from 'utils';

import { IconButton, FormControlLabel, FormGroup } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';

import { TableCheckbox } from 'components/common/MaterialTable/components/MaterialTableElements';

import { RACKING_PRODUCTS_10D } from 'containers/Estimates/Estimate/constants';
import {
  toMakerLookups,
  toModuleLookups,
  toMountingLookups,
  toSpacingLookups,
} from './utils';

const mapModules = new Map();

const stateComponents = {
  manufacturer: {},
  moduleParent: {},
  mountingSystem: {},
};

function Module({
  values,
  config: configFields,
  errors,
  touched,
  restricted,
  disabled,
  disabledModule,
  disabledMountingSystem,
  disabledModuleFirstPart,
  disabledModuleSecondPart,
  showStepsBadges,
  onUpdateField,
  onAddModule,
  onHandleState,
  showExcludeDeflectors, // Deprecated: Deflector-less per CPD-2779
  disabledExcludeDeflectors, // Deprecated: Deflector-less per CPD-2779
}) {
  const [manufacturers, setManufacturers] = useState([]);
  const [modules, setModules] = useState([]);
  const [mountingSystems, setMountingSystems] = useState([]);
  const [rowSpacings, setRowSpacings] = useState([]);
  const [moduleVersions, setModuleVersions] = useState([]);
  const [disabledAddModule, setDisabledAddModule] = useState(disabled);

  // Deprecated: Deflector-less per CPD-2779
  const [needCheckExcludeDeflectors, setNeedCheckExcludeDeflectors] = useState(
    false
  );

  useEffect(() => {
    return () => {
      stateComponents.manufacturer = {};
      stateComponents.moduleParent = {};
      stateComponents.mountingSystem = {};
    };
  }, []);

  useEffect(() => {
    const { manufacturer, moduleParent, module } = values;

    const enabledAddModule = !!(manufacturer && moduleParent && module);

    setDisabledAddModule(disabled || !enabledAddModule);

    return () => {};
  }, [values, disabled]);

  // Deprecated: Deflector-less per CPD-2779
  useEffect(() => {
    onUpdateField({
      name: 'needCheckExcludeDeflectors',
      value: needCheckExcludeDeflectors,
    });

    return () => {};
  }, [needCheckExcludeDeflectors]);

  const updateManufacturer = useCallback(
    (name, value, options) => {
      onUpdateField({ name: 'moduleParent', value: '' });
      onUpdateField({ name: 'module', value: '' });
      // onUpdateField({ name: 'mountingSystem', value: '' });
      // onUpdateField({ name: 'rowSpacing', value: null });

      onUpdateField({ name, value, label: options?.label });
    },
    [onUpdateField]
  );

  const updateModuleParent = useCallback(
    (name, value, options) => {
      onUpdateField({ name: 'module', value: '' });
      // onUpdateField({ name: 'mountingSystem', value: '' });
      // onUpdateField({ name: 'rowSpacing', value: null });

      onUpdateField({ name, value, label: options?.label });
    },
    [onUpdateField]
  );

  const updateModule = useCallback(
    (name, value, options) => {
      // onUpdateField({ name: 'mountingSystem', value: '' });
      // onUpdateField({ name: 'rowSpacing', value: null });

      onUpdateField({ name, value, label: options?.label });
    },
    [onUpdateField]
  );

  const updateMountingSystem = useCallback(
    (name, value, options) => {
      onUpdateField({ name, value, label: options?.label, data: options?.data });
    },
    [onUpdateField]
  );

  const updateRowSpacing = useCallback(
    (name, value, options) => {
      onUpdateField({ name, value, label: options?.label });
    },
    [onUpdateField]
  );

  const onChangeExludeDeflectors = useCallback(
    (e) => {
      const {
        target: { name, checked },
      } = e;

      onUpdateField({ name, value: checked });
    },
    [onUpdateField]
  );

  const addModule = useCallback(() => {
    const metadata = {
      numberModules: 0,
      modulePower: 0,
      systemPower: 0,
    };

    const module = mapModules.get(values.moduleParent);

    if (!module) return;

    if (module.metadata) {
      metadata.numberModules = mapModules.size;
      metadata.modulePower = module.metadata.power;
      metadata.systemPower = module.metadata.systemPower;
    }

    // mapModules
    onAddModule(metadata);
  }, [onUpdateField]);

  const checkIfLoaded = () => {
    if (stateComponents.loaded) return;

    const { manufacturer, moduleParent, mountingSystem } = stateComponents;

    stateComponents.loaded =
      manufacturer.loaded && moduleParent.loaded && mountingSystem.loaded;

    if (stateComponents.loaded) {
      onHandleState({ name: 'loaded', value: true });
    }
  };

  const renderMountingSystemSelection = () => {
    const config = {
      url: 'wes/data/racking',
      type: 'GET',
      body: null,
    };

    return (
      <Fetch
        url={config.url}
        type={config.type}
        body={config.body}
        render={({ data, loading }) => {
          stateComponents.mountingSystem.loaded = true;

          const [options, labelMountingSystem] = toMountingLookups(
            data,
            mountingSystems,
            values.mountingSystem
          );

          // Deprecated: Deflector-less per CPD-2779
          const needShowExcludeDeflectors =
            showExcludeDeflectors &&
            RACKING_PRODUCTS_10D === labelMountingSystem;
          // Deprecated: Deflector-less per CPD-2779
          setNeedCheckExcludeDeflectors(needShowExcludeDeflectors);

          checkIfLoaded();

          return (
            <div style={{ position: 'relative' }}>
              <SelectInput
                label={configFields.fields.mountingSystem.ui.label}
                labelPosition="left"
                name="mountingSystem"
                noResultsText={"Select 'Module' first"}
                disabled={
                  disabled ||
                  disabledModuleSecondPart ||
                  disabledMountingSystem ||
                  restricted.mountingSystem
                }
                isLoading={loading}
                options={options}
                onChange={updateMountingSystem}
                value={values.mountingSystem}
                error={touched.mountingSystem && errors.mountingSystem}
              />

              <SelectInput
                label={configFields.fields.rowSpacing.ui.label}
                labelPosition="left"
                name="rowSpacing"
                noResultsText={"Select 'Mounting System' first"}
                disabled={
                  disabled ||
                  disabledModuleSecondPart ||
                  disabledMountingSystem ||
                  restricted.rowSpacing
                }
                options={toSpacingLookups(
                  data,
                  values.mountingSystem,
                  rowSpacings
                )}
                onChange={updateRowSpacing}
                value={values.rowSpacing}
                checkNearestValue={!!values.customModule}
                error={
                  (touched.rowSpacing && errors.rowSpacing) || errors.rowSpacing
                }
              />

              {needShowExcludeDeflectors && ( // Deprecated: Deflector-less per CPD-2779
                <div className="toggle-horizontal">
                  <FormGroup
                    aria-label="Exclude Deflectors"
                    className="column-reverse"
                  >
                    <FormControlLabel
                      control={
                        <TableCheckbox
                          checked={!!values.excludeDeflectors}
                          onChange={onChangeExludeDeflectors}
                          name="excludeDeflectors"
                          label="Exclude Deflectors"
                          disabled={
                            disabled ||
                            disabledModuleSecondPart ||
                            disabledMountingSystem ||
                            restricted.mountingSystem ||
                            disabledExcludeDeflectors
                          }
                          styleType="colored-click"
                          color="primary"
                        />
                      }
                      label="Exclude Deflectors"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  };

  return (
    <div
      className="estimate-module"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <div style={{ width: '100%' }}>
        <Fetch
          url="wes/data/modulemakers"
          render={({ data, loading }) => {
            const options = toMakerLookups(data, manufacturers);

            stateComponents.manufacturer.loaded = true;

            checkIfLoaded();

            return (
              <SelectInput
                label={configFields.fields.manufacturer.ui.label}
                labelPosition="left"
                name="manufacturer"
                disabled={disabled || disabledModule || disabledModuleFirstPart}
                isLoading={loading}
                options={options}
                onChange={updateManufacturer}
                value={values.manufacturer}
                error={touched.manufacturer && errors.manufacturer}
              />
            );
          }}
        />

        <Fetch
          url={
            values.manufacturer && !values.customModule
              ? `wes/data/modulemodels/${values.manufacturer}`
              : ''
          }
          render={({ data, loading }) => {
            // moduleOptions
            const moduleOptions = toModuleLookups(
              data,
              mapModules,
              modules,
              moduleVersions
            );

            // specVersionOptions
            const specVersions =
              isObject(moduleOptions) && Array.isArray(moduleOptions.versions)
                ? moduleOptions.versions.filter(
                    (v) => v.parentModuleId === values.moduleParent
                  )
                : [];

            let specVersionOptions = specVersions.map((v) => ({
              value: v.id,
              label: v.dataSheetVersion,
              fileKey: v.dataSheetUrl,
            }));

            const hasCustomModule = !!values.customModule;
            if (hasCustomModule) {
              specVersionOptions = [...moduleOptions.versions];
            }

            stateComponents.moduleParent.loaded = true;

            checkIfLoaded();

            return (
              <>
                <SelectInput
                  label={configFields.fields.moduleParent.ui.label}
                  labelPosition="left"
                  name="moduleParent"
                  noResultsText="Select Manufacturer first"
                  disabled={
                    disabled ||
                    disabledModule ||
                    disabledModuleFirstPart ||
                    restricted.module
                  }
                  isLoading={loading}
                  options={moduleOptions.modules}
                  onChange={updateModuleParent}
                  value={values.moduleParent}
                  error={touched.moduleParent && errors.moduleParent}
                />

                <div className="row">
                  <SelectInput
                    label="Module Spec Sheet *"
                    labelPosition="left"
                    name="module"
                    noResultsText="Select module first."
                    disabled={
                      disabled ||
                      disabledModuleFirstPart ||
                      disabledModule ||
                      restricted.module
                    }
                    isLoading={loading}
                    options={specVersionOptions}
                    onChange={updateModule}
                    value={values.module}
                    error={touched.module && errors.module}
                  />
                </div>
              </>
            );
          }}
        />
        {showStepsBadges && (
          <div className="card__title card__title_margin-left">
            <Badge
              color={
                disabled || disabledModuleSecondPart ? 'secondary' : 'primary'
              }
              badgeContent="3"
            />
          </div>
        )}
        <div
          className="line"
          style={{ marginBottom: '30px', marginRight: '8px' }}
        />
        {renderMountingSystemSelection()}
      </div>
      <div>
        <IconButton
          aria-haspopup="true"
          onClick={addModule}
          className="icon-add module-add"
          disabled={disabledAddModule}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}

Module.propTypes = {
  onUpdateField: PropTypes.func,
  onAddModule: PropTypes.func,
  onHandleState: PropTypes.func,
  values: PropTypes.shape({
    manufacturer: PropTypes.string.isRequired,
    moduleParent: PropTypes.any,
    module: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    rowSpacing: PropTypes.number,
    mountingSystem: PropTypes.string.isRequired,
    customModule: PropTypes.object,
    excludeDeflectors: PropTypes.bool, // Deprecated: Deflector-less per CPD-2779
  }),
  config: PropTypes.any,
  errors: PropTypes.shape({
    moduleParent: PropTypes.any,
    dataSheetUrl: PropTypes.any,
    manufacturer: PropTypes.string,
    module: PropTypes.string,
    rowSpacing: PropTypes.string,
    mountingSystem: PropTypes.string,
    excludeDeflectors: PropTypes.string, // Deprecated: Deflector-less per CPD-2779
  }),
  touched: PropTypes.shape({
    dataSheetUrl: PropTypes.any,
    moduleParent: PropTypes.any,
    manufacturer: PropTypes.bool,
    module: PropTypes.bool,
    rowSpacing: PropTypes.bool,
    mountingSystem: PropTypes.bool,
    excludeDeflectors: PropTypes.bool, // Deprecated: Deflector-less per CPD-2779
  }),
  restricted: PropTypes.shape({
    manufacturer: PropTypes.bool,
    module: PropTypes.bool,
    rowSpacing: PropTypes.bool,
    mountingSystem: PropTypes.bool,
    excludeDeflectors: PropTypes.bool, // Deprecated: Deflector-less per CPD-2779
  }),
  disabled: PropTypes.bool,
  disabledModule: PropTypes.bool,
  disabledMountingSystem: PropTypes.bool,
  disabledModuleFirstPart: PropTypes.bool,
  disabledModuleSecondPart: PropTypes.bool,
  showStepsBadges: PropTypes.bool,
  showExcludeDeflectors: PropTypes.bool, // Deprecated: Deflector-less per CPD-2779
  disabledExcludeDeflectors: PropTypes.bool, // Deprecated: Deflector-less per CPD-2779
};

Module.defaultProps = {
  onUpdateField: () => {},
  onAddModule: () => {},
  onHandleState: () => {},
  values: {
    manufacturer: '',
    moduleParent: '',
    module: '',
    rowSpacing: null,
    mountingSystem: '',
    name: '',
    excludeDeflectors: false, // Deprecated: Deflector-less per CPD-2779
  },
  config: {},
  errors: PropTypes.shape({
    moduleParent: '',
    dataSheetUrl: '',
    manufacturer: '',
    module: '',
    rowSpacing: '',
    mountingSystem: '',
    name: '',
    excludeDeflectors: '', // Deprecated: Deflector-less per CPD-2779
  }),
  touched: PropTypes.shape({
    dataSheetUrl: false,
    moduleParent: false,
    manufacturer: false,
    module: false,
    rowSpacing: false,
    mountingSystem: false,
    name: false,
    excludeDeflectors: false, // Deprecated: Deflector-less per CPD-2779
  }),
  restricted: PropTypes.shape({
    manufacturer: false,
    module: false,
    rowSpacing: false,
    mountingSystem: false,
    name: false,
    excludeDeflectors: false, // Deprecated: Deflector-less per CPD-2779
  }),
  disabled: true,
  disabledModule: true,
  disabledMountingSystem: true,
  disabledModuleFirstPart: false,
  disabledModuleSecondPart: true,
  showStepsBadges: false,
  showExcludeDeflectors: false, // Deprecated: Deflector-less per CPD-2779
  disabledExcludeDeflectors: false, // Deprecated: Deflector-less per CPD-2779
};

export default connect()(Module);
