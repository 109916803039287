import React from 'react';

import { getNestedValueByStringKey } from 'utils/mixins';
import IntlContext from './index';
import { supportedLanguages } from './config';
import { createMarkup, updateWithArgs, isSupportedLang } from './utils';

const getLangData = ({ lang }) => {
  if (!isSupportedLang(lang, supportedLanguages))
    return supportedLanguages.defaults;
  return supportedLanguages[lang];
};

const translate = (key, defaultMsg, args, config) => {
  const langData = getLangData(config);
  return (
    updateWithArgs(getNestedValueByStringKey(langData, key), args) ||
    defaultMsg ||
    key
  );
};

const translateHtml = (key, defaultMsg, args, config) => {
  const langData = getLangData(config);

  const html = updateWithArgs(getNestedValueByStringKey(langData, key), args);
  if (!html) return defaultMsg || key;

  return <span dangerouslySetInnerHTML={createMarkup(html)} />; // eslint-disable-line react/no-danger
};

export default function withIntl(Component) {
  return function compose(props) {
    return (
      <IntlContext.Consumer>
        {(config) => (
          <Component
            {...props}
            lang={config.lang}
            _t={(key, defaultMsg = null, ...args) =>
              translate(key, defaultMsg, args, config)
            }
            _thtml={(key, defaultMsg, ...args) =>
              translateHtml(key, defaultMsg, args, config)
            }
          />
        )}
      </IntlContext.Consumer>
    );
  };
}
