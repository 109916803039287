import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

class FormikWrapper extends React.Component {
  render() {
    const {
      renderForm,
      handleChangeKeyVal,
      handleChangeEvt,
      ...otherProps
    } = this.props;

    const { updating } = otherProps;

    return (
      <Formik {...otherProps}>
        {formikProps => {
          const { isSubmitting } = formikProps;
          // TODO: Can optimize below code line
          const disabled = isSubmitting || updating || otherProps.disabled;

          const props = {
            ...otherProps,
            ...formikProps,
            disabled,
            handleChangeKeyVal: (key, value) =>
              handleChangeKeyVal(key, value, formikProps),
            handleChangeEvt: evt => handleChangeEvt(evt, formikProps),
          };
          return renderForm(props);
        }}
      </Formik>
    );
  }
}

FormikWrapper.propTypes = {
  disabled: PropTypes.bool,
  updating: PropTypes.bool,
  renderForm: PropTypes.func.isRequired,
  handleChangeKeyVal: PropTypes.func,
  handleChangeEvt: PropTypes.func,
};

FormikWrapper.defaultProps = {
  updating: false,
  disabled: false,
  handleChangeKeyVal: () => {},
  handleChangeEvt: () => {},
};

export default FormikWrapper;
