/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Row, Col, CardBody } from 'reactstrap';

import { isObject } from 'utils';

import Loader from 'components/Loader/Loader';
import { Card } from 'react-bootstrap';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  setSourceSelectedTableName,
  setSourceHidedHeader,
  changeFilterOptions,
} from 'redux/actions/dataViewActions';

import loaderActions from 'redux/actions/loaderActions';

import { CallMade as FilterIcon } from 'components/MaterialTable/Tools/columnsFilterTool';

import { api } from 'utils/fetch';
import Cache from 'utils/Cache';

import Header from 'components/Header';
import ListTables from 'components/ListTables';
import ListFields from 'components/ListFields';

import Table from './components/Table';

import {
  REPORTS_CUSTOM,
  REPORTS_CUSTOM_COLUMNS_FILTER_NAME,
} from './constants';

import { filterTables } from './utils';

import './assets/style.scss';

class ApiInspector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef(null);

    this.state = {
      loaded: false,
      disabled: false,
      source: null,
      sources: [],
      loadingTables: true,
      loadingFields: true,
      expanded: false,
      headers: [],
      viewedTableNames: [],
      notSupportedTables: false,
      selectedTableName: null,
      tables: [],
      fields: [],
      unselectedFields: [],
    };

    this.mapSources = {};
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { source } = this.state;

    const { search } = this.props;

    let changedTableName = false;

    let needFilterTables = true;
    let needFilterFields = true;

    const forceUpdate = prevState.source !== source;

    const { selectedTableName } = this.props;
    const unselectedFields = this.props.hidedColumns[selectedTableName]; // || {};
    let { tables } = this.props;
    let fields = this.props.headers[selectedTableName]; // || headers;

    if (
      prevProps.selectedTableName !== this.props.selectedTableName ||
      forceUpdate
    ) {
      changedTableName = true;
      this.setState({
        selectedTableName,
      });
    }

    if (prevProps.tables !== this.props.tables || forceUpdate) {
      tables = Array.isArray(tables)
        ? filterTables(tables, search.listTables)
        : [];
      needFilterTables = false;
      this.setState({
        tables,
      });
    }

    if (
      prevProps.headers?.[selectedTableName] !==
        this.props.headers?.[selectedTableName] ||
      changedTableName ||
      forceUpdate
    ) {
      fields = Array.isArray(fields)
        ? filterTables(fields, search.listFields)
        : [];
      needFilterFields = false;
      this.setState({
        fields,
      });
    }

    if (
      prevProps.hidedColumns !== this.props.hidedColumns ||
      prevProps.hidedColumns?.[selectedTableName] !==
        this.props.hidedColumns?.[selectedTableName] ||
      changedTableName ||
      forceUpdate
    ) {
      this.setState({
        unselectedFields,
      });
    }

    if (prevProps.search.listTables !== search.listTables) {
      if (needFilterTables) {
        tables = Array.isArray(tables)
          ? filterTables(tables, search.listTables)
          : [];
        this.setState({
          tables,
        });
      }
    }

    if (prevProps.search.listFields !== search.listFields) {
      if (needFilterFields) {
        fields = Array.isArray(fields)
          ? filterTables(fields, search.listFields)
          : [];
        this.setState({
          fields,
        });
      }
    }
  }

  init = async () => {
    const result = await api.getReportGeneratorSources();

    if (Array.isArray(result.data)) {
      const sources = result.data.map(
        ({ name, label, options: metadata, startTableName }) => {
          const options = {
            label,
            value: name,
            metadata,
            startTableName,
          };

          this.mapSources[name] = options;

          return {
            label,
            value: name,
          };
        }
      );

      const source = sources[0]?.value ?? null;

      this.setState({
        source,
        sources,
        notSupportedTables: !!this.mapSources[source]?.metadata
          ?.tableNavigationDisabled,
      });

      this.refresh(source);
    }

    this.setState({
      loaded: true,
    });
  };

  refresh = (source) => {
    const { search } = this.props;

    const { selectedTableName } = this.props;
    const unselectedFields = this.props.hidedColumns[selectedTableName] || {};

    const tables = filterTables(this.props.tables, search.listTables);
    const fields = filterTables(
      this.props.headers[selectedTableName],
      search.listFields
    );

    this.setState({
      selectedTableName,
      tables,
      fields,
      unselectedFields,
    });
  };

  onExpandPanel = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  onEventHeader = (data) => {
    if (isObject(data)) {
      this.setState({
        [data.data.name]: data.data.value,
      });

      if (data.data.name === 'source') {
        const notSupportedTables = !!this.mapSources[data.data.value]?.metadata
          ?.tableNavigationDisabled;

        this.setState({
          notSupportedTables,
        });

        this.setLoading(true);

        this.resetSearch();
      }
    }
  };

  onEventListTables = (data) => {
    if (isObject(data)) {
      this.resetSearchFields();

      this.props.setSelectedTableName(data.data.value);

      this.setState({
        [data.data.name]: data.data.value,
      });
    }
  };

  onEventListFields = (data) => {
    if (isObject(data)) {
      this.onChangedFields(data);
    }
  };

  onChangedFields = (data) => {
    const { hidedColumns, setHidedColumns, selectedTableName } = this.props;

    let needUpdate = false;

    if (data.data.value) {
      if (!hidedColumns[selectedTableName]) {
        hidedColumns[selectedTableName] = {};
      }

      hidedColumns[selectedTableName][data.data.name] = true;

      needUpdate = true;
    } else if (hidedColumns[selectedTableName]?.[data.data.name]) {
      delete hidedColumns[selectedTableName][data.data.name];
      needUpdate = true;
    }

    if (needUpdate) {
      hidedColumns[selectedTableName] = {
        ...hidedColumns[selectedTableName],
      };

      Cache.setValue(REPORTS_CUSTOM_COLUMNS_FILTER_NAME, hidedColumns);

      setHidedColumns({ ...hidedColumns });
    }
  };

  onEventHS = (data) => {
    if (data?.action === 'loaded') {
      this.setLoading(false);
    }
  };

  onEventTable = (data) => {
    if (data?.action === 'loading') {
      this.setState({
        loadingFields: true,
      });
      return;
    }

    if (data?.action === 'loaded') {
      this.setLoading(false);
    }
  };

  setLoading = (loading) => {
    this.setState({
      loadingTables: loading,
      loadingFields: loading,
    });
  };

  getComponentTable = () => {
    const { source, viewedTableNames } = this.state;
    const { setTableLoading, tables, headers } = this.props;

    return (
      <Table
        ref={this.tableRef}
        source={source}
        setTableLoading={setTableLoading}
        headers={headers}
        tables={tables}
        showRevertTool={viewedTableNames.length > 0}
        options={this.mapSources[source].metadata}
        onEvent={this.onEventTable}
      />
    );
  };

  getLoader = () => {
    return (
      <div className="loader">
        <Loader loading />
      </div>
    );
  };

  getContent = () => {
    const {
      disabled,
      source,
      sources,
      expanded,
      loadingTables,
      loadingFields,
      notSupportedTables,
      selectedTableName,
      unselectedFields,
      tables,
      fields,
    } = this.state;

    const { search } = this.props;

    return (
      <>
        <Header
          disabled={disabled}
          title="Custom Reports"
          source={source}
          sources={sources}
          onEvent={this.onEventHeader}
        />

        <Row style={{ display: 'flex' }}>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Accordion
                  className="accordion"
                  expanded={expanded === 'panelSelectData'}
                  onChange={this.onExpandPanel('panelSelectData')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="filter-select-data">
                      <FilterIcon />
                      <div className="filter-select-data__title">
                        Select Data
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row style={{ width: '100%' }}>
                      <Col md={6} lg={6} xl={6}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <Typography
                            variant="h7"
                            component="span"
                            sx={{ flexGrow: 1 }}
                            className="reports-custom-title"
                          >
                            Table
                          </Typography>
                          <div style={{ marginLeft: '10px', width: '50%' }}>
                            {notSupportedTables ? (
                              <Typography
                                variant="h7"
                                component="span"
                                sx={{ flexGrow: 1 }}
                                className="reports-custom-title__not_supported"
                              >
                                list is not supported
                              </Typography>
                            ) : (
                              <ListTables
                                id="customReportsListTables"
                                item={selectedTableName}
                                items={tables}
                                loading={loadingTables}
                                onEvent={this.onEventListTables}
                                search={search.listTables}
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                          <Typography
                            variant="h7"
                            component="span"
                            sx={{ flexGrow: 1 }}
                            className="reports-custom-title"
                          >
                            Fields
                          </Typography>
                          <div style={{ marginLeft: '10px', width: '50%' }}>
                            <ListFields
                              id="customReportsListFields"
                              unselectedItems={unselectedFields}
                              items={fields}
                              loading={loadingFields}
                              onEvent={this.onEventListFields}
                              search={search.listFields}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="reports-custom-elem-table">
          <Col md={12} lg={12} xl={12}>
            {this.getComponentTable()}
          </Col>
        </Row>
      </>
    );
  };

  resetSearch = () => {
    this.props.changeFilterOptions({
      tableName: 'customReportsListTables',
      filterOptions: {
        search: '',
        changedBy: 'search',
      },
    });

    this.resetSearchFields();
  };

  resetSearchFields = () => {
    this.props.changeFilterOptions({
      tableName: 'customReportsListFields',
      filterOptions: {
        search: '',
        changedBy: 'search',
      },
    });
  };

  render() {
    return (
      <Container className="reports-custom-page">
        {this.state.loaded ? this.getContent() : this.getLoader()}
      </Container>
    );
  }
}

const mapStateToProps = ({
  dataView,
  auth,
  loader: { loading, tableLoading },
}) => {
  const { customReportsListTables, customReportsListFields } = dataView;
  const reportsCustom = dataView?.[REPORTS_CUSTOM];

  return {
    token: auth?.token,
    loading: loading || tableLoading,
    tables: reportsCustom?.tables || [],
    headers: reportsCustom?.headers || {},
    hidedColumns: reportsCustom?.hidedColumns || {},
    selectedTableName: reportsCustom?.selectedTableName || null,
    search: {
      listTables: customReportsListTables?.search || '',
      listFields: customReportsListFields?.search || '',
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTableLoading: (loading) => {
    dispatch(loaderActions.setTableLoading(loading));
  },
  setHidedColumns: (hidedColumns) => {
    dispatch(setSourceHidedHeader(REPORTS_CUSTOM, hidedColumns));
  },
  setSelectedTableName: (name) => {
    dispatch(setSourceSelectedTableName(REPORTS_CUSTOM, name));
  },
  changeFilterOptions: (filterOptions) => {
    dispatch(changeFilterOptions(filterOptions));
  },
});

ApiInspector.propTypes = {
  setTableLoading: PropTypes.func,
  headers: PropTypes.object,
  tables: PropTypes.array,
  hidedColumns: PropTypes.object,
  selectedTableName: PropTypes.string,
  setSelectedTableName: PropTypes.func,
  setHidedColumns: PropTypes.func,
  changeFilterOptions: PropTypes.func,
};

ApiInspector.defaultProps = {
  setTableLoading: () => {},
  headers: {},
  tables: [],
  hidedColumns: {},
  selectedTableName: '',
  setSelectedTableName: () => {},
  setHidedColumns: () => {},
  changeFilterOptions: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiInspector);
