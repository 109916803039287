import React from 'react';
import { Container, Row } from 'reactstrap';

import MaterialTable from 'components/MaterialTable';
import { api, inlineFilters } from 'utils/fetch';
import Toaster from 'components/ToasterQueue';
import { getRolesHeaders } from './utils';

class Roles extends React.Component {
  constructor(props) {
    super(props);
    const { headers, detailViewCols } = getRolesHeaders();
    this.headers = headers;
    this.detailViewCols = detailViewCols;
  }

  clearDetailViewCols(){
    this.detailViewCols.forEach((col) => {
      if (col.headers) {
        col.headers.fields.forEach((field) => {
          delete field.tableData;
        });
      }
    });
  }

  render() {
    return (
      <Container className="roles-page">
        <Row>
          <MaterialTable
            tableProps={{
              title: 'Roles',
              detailViewTittle: 'Role',
              actions: {},
              canDelete: true,
              name: 'Roles',
            }}
            remoteData
            hasAddButton
            fullScreenDetailView
            middleToolbar={null}
            columns={this.headers}
            getData={async (param) => {
              const newParams = inlineFilters(param);
              const result = await api.getRoles(newParams);
              return {
                ...result,
                count: result.data.pagination.totalItems,
                data: result.data,
              };
            }}
            detailsView={{
              cols: this.detailViewCols,
              getItem: api.getRole,
              onDelete: api.deleteRole,
              onClose: () => this.clearDetailViewCols(),
              onSave: async (id, payload, createRole) => {
                if (createRole) {
                  const { TenantNameCreate, ...otherPayload } = payload.data;
                  const newPayload = {
                    data: {
                      ...otherPayload,
                      TenantName: TenantNameCreate,
                    },
                  };
                  const response = await api.postRole(newPayload);
                  Toaster.success(
                    `The new role for the \"${TenantNameCreate}\" is now ready to be assigned to users`
                  );
                  return response;
                }
                return await api.putRole(id, payload);
              },
              idFieldName: 'id',
            }}
          />
        </Row>
      </Container>
    );
  }
}

export default Roles;
