import { isObject } from 'utils';

export const getHSTitles = (
  metaData,
  { filteringDisabled = false, sortingDisabled } = {}
) => {
  const header = metaData.map((item) => {
    const { name, type, label, referenceTo, options } = item;

    return {
      field: name,
      title: label,
      type: String(type).trim().toLowerCase(),
      showInTable: true,
      filtering: !options?.filteringDisabled,
      sortable: !options?.sortingDisabled,
      searchable: true,
      referenceTo: referenceTo || [],
    };
  });

  return { header };
};

export const objectToString = (object) => {
  return JSON.stringify(object)
    .replace(/,"+/g, '\n')
    .replace(/":+/g, ': ')
    .replace(/["{}]+|(\\n)+/g, '');
};

export const getHSTable = (data) => {
  const table = [];
  for (let i = 0, n = data.length; i < n; i += 1) {
    if (!table[i]) {
      table[i] = {};
    }
    const item = data[i];
    for (let j = 0, k = item.length; j < k; j += 1) {
      const { name, value } = item[j];

      if (isObject(value)) {
        table[i][name] = objectToString(value);
      } else if (value === null || (typeof value !== 'boolean' && !value)) {
        table[i][name] = '—';
      } else {
        table[i][name] = value;
      }
    }
  }

  return table;
};

export const makeUpperCaseKeysInObject = (object) => {
  for (const key in object) {
    if (Object.hasOwn(object, key)) {
      object[key.charAt(0).toUpperCase() + key.substring(1)] = object[key];
      delete object[key];
    }
  }
  return object;
};
