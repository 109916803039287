import React from 'react';
import PropTypes from 'prop-types';

import Fetch from 'components/common/Fetch';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';

function AboutDialog({ isOpen, onClose, detailed, accountName, productName }) {
  return (
    <Modal isOpen={isOpen} theme="pc-feedback-modal">
      <div>
        <Fetch
          url={detailed ? 'about?detailed=true' : 'about'}
          render={({ data, loading }) => (
            <div>
              <div className="modal-header text-center about">
                <h3 className="modal-title">
                  {`${accountName} - ${productName}`}
                </h3>
              </div>
              <div className="modal-body">
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {loading ? (
                    <span>Loading...</span>
                  ) : (
                    <div>
                      <p className="version">
                        v{process.env.REACT_APP_VERSION}
                      </p>
                      {detailed && (
                        <p
                          className="version"
                          style={{ fontWeight: 'normal', fontSize: '14px' }}
                        >
                          WDE v
                          {data && data.wdeVersion
                            ? data.wdeVersion.replace(/"/g, '')
                            : ':unknown'}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="modal-about-description">
                    {productName} is the companion management app for clawOS,
                    which is the design tool for PanelClaw commercial rooftop
                    racking. The companion app manages, tracks and reports on
                    clawOS projects and its data. It also allows the management
                    of the tenants, users, security and roles.
                  </div>
                </p>
              </div>
              <div className="modal-footer">
                <Button theme="primary" type="submit" onClick={onClose}>
                  OK
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </Modal>
  );
}

AboutDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  detailed: PropTypes.bool,
  accountName: PropTypes.string,
  productName: PropTypes.string,
};

AboutDialog.defaultProps = {
  isOpen: false,
  detailed: false,
  accountName: '',
  productName: '',
};

export default AboutDialog;
