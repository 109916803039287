import React from 'react';
import NumberCounter from 'react-smooth-number-counter';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { connect } from 'react-redux';

function TotalCount({ totalCount }) {
  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody className="dashboard-body">
          <div className="card__title">
            <h5 className="bold-text center">
              Total count of users and tenants
            </h5>
          </div>

          <div>
            <Row className="dashboard-body-col">
              <Col md={6} lg={6} xl={6} className="dashboard-body-col-flex">
                <div className="dashboard__total-value">
                  <NumberCounter
                    value={totalCount.users || 0}
                    transition={1000}
                  />
                </div>
                <div className="dashboard__total-label">Users</div>
              </Col>
              <Col md={6} lg={6} xl={6} className="dashboard-body-col-flex">
                <div className="dashboard__total-value">
                  <NumberCounter
                    value={totalCount.tenants || 0}
                    transition={1000}
                  />
                </div>
                <div className="dashboard__total-label">Tenants</div>
              </Col>
              {totalCount.isLoading && (
                <div className="spinner">
                  <Spinner animation="border" role="status" color="primary">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

const mapStateToProps = ({ dashboard: { totalCount } }) => ({
  totalCount,
});

export default connect(mapStateToProps)(TotalCount);
