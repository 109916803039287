import { CsvBuilder } from 'filefy';
import { getDefaultFileName } from '../Tools/exportTools';

export function generateCSVTableReport({
  data,
  headers,
  reportName = 'Report',
  filename = getDefaultFileName(reportName, 'csv'),
}) {
  const headersNames = headers.map((el) => el.display); // probably we have to add _id
  const headersKeys = headers.map((el) => el.key);

  const csvBuilder = new CsvBuilder(filename).setColumns(headersNames);
  csvBuilder.addRows(
    data.reduce((acc, cur) => [...acc, headersKeys.map((el) => cur[el])], [])
  );

  return csvBuilder.exportFile();
}

export default generateCSVTableReport;
