import { SET_FLAG, CLEAR_ALL_FLAGS } from './types';
import { initialState } from './_configs';

const flagUpdated = (exState, actionKey, status, { runningInBackground }) => {
  const state = {
    ...exState,
  };

  if (!actionKey) return state;

  const actionKeySplitted = actionKey.split('.');
  const actionGroup = actionKeySplitted[0];
  const action = actionKeySplitted[1];

  if (!action) return state;

  state[actionGroup][action] = !runningInBackground && status;

  return state;
};

export default function flagReducer(
  state = initialState,
  { type, actionKey, status, options }
) {
  switch (type) {
    case SET_FLAG:
      return {
        ...flagUpdated(state, actionKey, status, options)
      };

    case CLEAR_ALL_FLAGS:
      return initialState;

    default:
      return state;
  }
}
