import secASchema from './SecA/validationSchema';
import topViewSchema from './TopView/validationSchema';
import topFormSchema from './TopForm/validationSchema';

const ModuleParamterSchema = {
  ...secASchema,
  ...topViewSchema,
  ...topFormSchema,
};

export default ModuleParamterSchema;
