import React from 'react';
import PropTypes from 'prop-types';

function TextArea({ name, label, error, ...rest }) {
  return (
    <div className="form-group pc-small-input">
      {label && <label htmlFor={name}>{label}</label>}
      {error && (
        <div className="pc-inline pull-right text-right">
          <span className="error">{error}</span>
        </div>
      )}
      <textarea
        name={name}
        id={name}
        type="text"
        className="form-control"
        rows="4"
        {...rest}
      />
    </div>
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

TextArea.defaultProps = {
  error: '',
};

export default TextArea;
