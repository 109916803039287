import { isObject } from 'utils';

const prepareFilterForTable = (filters) => {
  const filterForTable = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      let filterValue;
      if (isObject(filters[key])) {
        filterValue = filters[key].value;
      } else if (Array.isArray(filters[key])) {
        filterValue = filters[key].reduce(
          (acc, item, counter) =>
            `${acc}${item?.value}${
              counter + 1 < filters[key].length ? ',' : ''
            }`,
          ''
        );
      } else {
        filterValue = filters[key];
      }

      if (filterValue) filterForTable[key] = filterValue;
    }
  });
  return filterForTable;
};

export { prepareFilterForTable };
