import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import TableCell from './TableCell';
import SelectInput from '../SelectInput';
import { convertType, findUpEll } from './utils';
import Badge from '../Badge';
import { isAccessory } from 'containers/Estimates/Estimate/utils';

const MODULE = 'model';

function EditableCell(prop) {
  const {
    value: initialValue,
    row: { index, original, values },
    column: {
      id,
      isDropdown,
      disableEditing,
      accessor,
      dropdownLabel,
      options,
      type,
      useBadge,
      editable,
    },
    column,
    state,
    updateEditableData,
  } = prop;

  const [value, setValue] = useState(convertType(initialValue, column.type));
  const [topOffset, setTopOffset] = useState(0);

  const [isActiveInput, setIsActiveInput] = useState(false);
  const [dropDownValue, setDopDownValue] = useState('Page specific');

  const handleClick = () => {
    if (
      disableEditing ||
      original.additionalComponent ||
      (original.accessories && isDropdown) ||
      (id === MODULE && original.disableSelectModule)
    ) {
      return;
    }

    setIsActiveInput(!isActiveInput);
  };
  const onChange = (event) => {
    setValue(convertType(event.target.value, type));
  };

  const updateData = (selected, data) => {
    if (selected) {
      const otherLinePart = data
        ? isAccessory(data?.originalData?.ProductType) && data
        : isAccessory(original.type) && values;
      const updateLinesOther =
        (original.accessories && original.partNumber) || otherLinePart;

      updateEditableData(
        index,
        id,
        selected.target ? value : data,
        updateLinesOther,
        { index, ...original }
      );
      setIsActiveInput(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      updateData(event);
    } else if (event.key === 'Escape') {
      setIsActiveInput(false);
    }
  };

  useEffect(() => {
    setValue(initialValue);
    if (isDropdown) {
      setDopDownValue(initialValue);
    }
    if (
      (!initialValue || initialValue == 'Unselected') &&
      isDropdown &&
      !original.additionalComponent &&
      !original.accessories
    ) {
      setIsActiveInput(true);
    }
  }, [initialValue]);

  const handleBlur = () => {
    isActiveInput && setIsActiveInput(false);
  };

  return (
    <div onDoubleClick={handleClick}>
      {isActiveInput ? (
        <div className="table__editable-table--cell-active">
          {isDropdown ? (
            <SelectInput
              defaultMenuIsOpen
              autoFocus
              onFocus={(e) => {
                const { scrollTop } = findUpEll(
                  e.target,
                  'role',
                  'table'
                ).parentNode;
                const inputEll = findUpEll(e.target, 'class', 'control');
                setTopOffset(
                  inputEll.offsetTop -
                    scrollTop +
                    inputEll.getBoundingClientRect().height
                );
              }}
              name={accessor}
              inTableCell
              options={options}
              onChange={(name, selected, data) => {
                updateData(selected, data);
              }}
              topOffset={topOffset}
              onKeyPress={handleKeyPress}
              onBlur={handleBlur}
              value={dropDownValue}
              placeholder=""
            />
          ) : (
            <Form.Control
              autoFocus
              type="text"
              className="table__edit-form"
              value={value}
              onChange={onChange}
              onBlur={updateData}
              onKeyPress={handleKeyPress}
            />
          )}
        </div>
      ) : useBadge ? (
        <Badge bg="primary">{value}</Badge>
      ) : (
        <TableCell value={value} column={column} state={state} />
      )}
    </div>
  );
}

EditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  row: PropTypes.shape({
    index: PropTypes.number,
    original: PropTypes.object,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
    accessor: PropTypes.string,
    dropdownLabel: PropTypes.string,
    type: PropTypes.string,
    isDropdown: PropTypes.bool,
    useBadge: PropTypes.bool,
    disableEditing: PropTypes.bool,
    options: PropTypes.shape([
      PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
    ]),
  }).isRequired,
  state: PropTypes.shape({
    globalFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  updateEditableData: PropTypes.func.isRequired,
};

export default EditableCell;
