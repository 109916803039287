import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { Col, Row } from 'reactstrap';

import Button from 'components/common/Button';
import TextInput from 'components/common/ReactHookForm/TextInput';
import Badge from '@material-ui/core/Badge';

import TableBase from 'components/common/Table/TableBase';

import ModalDialog from 'containers/Estimates/Estimate/components/Dialog/Modal';
import config from './config';
import { getDocumentsData, getColumns } from './utils';

import './style.scss';

function Footer({
  disabled,
  disabledCancel,
  disabledSave,
  disabledSaveAs,
  loadingSave,
  loadingSaveAs,
  onCancel,
  onSave,
  onSaveAs,
  notes,
  documents,
  estimateId,
  onChange,
  showStepsBadges,
}) {
  const [openedModalDialog, setOpenedModalDialog] = useState(false);
  const [modalDialogMetadata, setModalDialogMetadata] = useState(null);

  const data = getDocumentsData(documents, estimateId);
  const columns = getColumns();

  const onChangeNote = useCallback(
    (evt) => {
      onChange({
        notes: evt.target.value,
      });
    },
    [onChange]
  );

  const openModalDialog = (data) => {
    setOpenedModalDialog(true);
    setModalDialogMetadata(data);
  };

  const onHandleSaveAs = () => {
    openModalDialog({ action: 'saveAs' });
  };

  const closeModalDialog = () => {
    setOpenedModalDialog(false);
    setModalDialogMetadata(null);
  };

  const onHandleEventModalDialog = ({ action }) => {
    closeModalDialog();

    if (action === 'save') {
      onSaveAs();
    }
  };

  return (
    <Box className="estimate-footer" sx={{ flexGrow: 1 }}>
      <footer>
        {showStepsBadges && (
          <div className="card__title card__title_margin-left">
            <Badge
              color={disabled ? 'secondary' : 'primary'}
              badgeContent="6"
            />
          </div>
        )}
        <div className="documents">
          <div style={{ width: '50%' }}>
            <Row>
              <Col md={12} lg={12} xl={1} className="label note">
                Note
              </Col>
              <Col md={12} lg={12} xl={11}>
                <div style={{ marginLeft: '15px', width: '100%' }}>
                  <TextInput
                    name="notes"
                    size="small"
                    disabled={disabled || loadingSaveAs || loadingSave}
                    onChange={onChangeNote}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={notes}
                    type="text"
                    maxlength="250"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="documents-section">
            {/* <div className="title">Documents</div> */}
            <div
              className="document-list"
              style={{ marginLeft: '10px', width: '100%' }}
            >
              <TableBase columns={columns} data={data} tableConfig={config} />
            </div>
          </div>
        </div>
        <div className="toolbar-buttons">
          <div className="estimate__cancel">
            <Button
              disabled={disabledCancel}
              onClick={onCancel}
              className="btn btn-secondary btn-lg"
            >
              Cancel
            </Button>
          </div>
          <div className="estimate__toolbar_saves">
            <div>
              <Button
                className="btn btn-primary btn-lg"
                loading={loadingSaveAs}
                disabled={disabled || disabledSaveAs}
                onClick={onHandleSaveAs}
              >
                Save As
              </Button>
              <Button
                className="btn btn-primary btn-lg"
                loading={loadingSave}
                disabled={disabled || disabledSave}
                onClick={onSave}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <ModalDialog
          opened={openedModalDialog}
          onEvent={onHandleEventModalDialog}
          info="This will create a new variant. Do you want to proceed?"
          save={{ label: 'Proceed', actionName: 'save' }}
          metadata={modalDialogMetadata}
        />
      </footer>
    </Box>
  );
}

Footer.propTypes = {
  disabled: PropTypes.bool,
  disabledCancel: PropTypes.bool,
  disabledSave: PropTypes.bool,
  disabledSaveAs: PropTypes.bool,
  loadingSave: PropTypes.bool,
  loadingSaveAs: PropTypes.bool,
  documents: PropTypes.arrayOf(PropTypes.string),
  estimateId: PropTypes.string,
  showStepsBadges: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onSaveAs: PropTypes.func,
  notes: PropTypes.string,
  onChange: PropTypes.func,
};

Footer.defaultProps = {
  disabled: true,
  disabledCancel: true,
  disabledSave: true,
  disabledSaveAs: true,
  loadingSave: false,
  loadingSaveAs: false,
  estimateId: '',
  documents: [],
  notes: null,
  showStepsBadges: false,
  onCancel: () => {},
  onSave: () => {},
  onSaveAs: () => {},
  onChange: () => {},
};

export default connect()(Footer);
