import {
  WATCH_TOKEN,
  ID_TOKEN_SUCCEEDED,
  ID_TOKEN_FAILED,
  CHECK_TOKEN,
} from '../authTypes';

import { action } from '../_utils';

export default {
  watchToken: () => action(WATCH_TOKEN),
  checkToken: () => action(CHECK_TOKEN),
  fetchingIdTokenSuccess: (token, tokenExpirationTime) =>
    action(ID_TOKEN_SUCCEEDED, { token, tokenExpirationTime }),
  fetchIdTokenFailure: (refresh) => action(ID_TOKEN_FAILED, { refresh }),
};
