import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/Button';
import TextInput from 'components/common/TextInput';

function Password(props) {
  const {
    values,
    handleChange,
    touched,
    errors,
    disabled,
    updating,
    handleSubmit,
  } = props;

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        <div>
          <TextInput
            name="exPassword"
            size="small"
            type="password"
            label="Enter existing password *"
            disabled={disabled}
            value={values.exPassword}
            onChange={handleChange}
            error={touched.exPassword && errors.exPassword}
            placeholder=""
          />
          <TextInput
            name="password"
            size="small"
            type="password"
            label="Enter new password *"
            disabled={disabled}
            value={values.password}
            onChange={handleChange}
            error={touched.password && errors.password}
            placeholder=""
          />
          <TextInput
            name="confirmPassword"
            size="small"
            type="password"
            label="Confirm new password *"
            disabled={disabled}
            value={values.confirmPassword}
            onChange={handleChange}
            error={touched.confirmPassword && errors.confirmPassword}
            placeholder=""
          />
        </div>

        <div style={{ clear: 'both' }} />
        <div>
          <center>
            <Button
              className="btn btn-primary pcbtn pcbtn-primary padding-btn"
              loading={updating}
              disabled={disabled}
              theme="primary"
              type="submit"
            >
              Update Password
            </Button>
          </center>
        </div>
      </form>
    </div>
  );
}

Password.propTypes = {
  values: PropTypes.object.isRequired, // eslint-disable-line
  touched: PropTypes.object.isRequired, // eslint-disable-line
  errors: PropTypes.object.isRequired, // eslint-disable-line
  disabled: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Password;
