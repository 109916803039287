import { uniqBy } from 'utils';
import { DELIMITER } from 'containers/Estimates/Estimate/constants';

export function toLookups(data, initial = []) {
  if (!data) return initial;

  const items = data.map(({ id, name }) => ({ value: id, label: name }));

  return uniqBy([...initial, ...items], 'value');
}

export function toLookupsSalespersons(data, initial = []) {
  if (!data) return initial;

  const items = data.map(({ firstName, lastName, title }) => {
    const value = `${firstName} ${lastName}`;
    const label = `${value}${title ? `, ${title}` : ''}`;

    return { value, label };
  });

  return uniqBy([...initial, ...items], 'value');
}

export function toLookupsMaTypes(data, initial = []) {
  if (!data) return initial;

  const items = data.map(({ key, value }) => ({ value: key, label: value }));
  return uniqBy([...initial, ...items], 'value');
}

export function toLookupsManufacturers(data) {
  if (!data) return [];

  return data.map((obj) => ({
    value: obj.viewData.id,
    label: obj.viewData.name,
  }));
}

export function toLookupsMembranes(data, initial = []) {
  if (!data) return initial;

  const items = data.map(({ viewData }) => ({
    value: viewData?.name,
    label: viewData?.displayName,
  }));
  return uniqBy([...initial, ...items], 'value');
}

export function toLookupsStamps(data, initial = []) {
  if (!data) return initial;

  const items = data.map(({ id, partNumber }) => ({
    value: id,
    label: partNumber,
  }));
  return uniqBy([...initial, ...items], 'value');
}

export function toLookupsManufacturersToMembranes(manufacturers, membranes) {
  if (!manufacturers || !membranes) return [];

  const items = [];

  for (let i = 0, n = manufacturers.length; i < n; i++) {
    for (let j = 0, m = membranes.length; j < m; j++) {
      items.push({
        value: `${manufacturers[i].viewData.id}${DELIMITER}${membranes[j].viewData.name}`,
        label: `${manufacturers[i].viewData.name} ${membranes[j].viewData.displayName}`,
      });
    }
  }

  return items;
}
