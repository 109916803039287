import { isDate, uniqBy } from 'utils';
import { api, inlineFilters } from 'utils/fetch';
import React from 'react';
import FilterBox from 'containers/DesignerWorkflow/Tools/Filter/FilterBox';
import Toaster from 'components/ToasterQueue';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import LookupCache from '../utils/lookupCache';

function getRenderForExpectedCompletionDate(row) {
  let formattedDate = moment(
    new Date(row.ExpectedCompletionDate).toLocaleString('en-US')
  ).format('YYYY-MM-DD');

  if (!isDate(new Date(formattedDate))) {
    formattedDate = '';
  }

  return (
    <Tooltip enterNextDelay={700} title={formattedDate}>
      <div className="cell-with-tooltip">{formattedDate}</div>
    </Tooltip>
  );
}

const handleGetDataErrors = (error) => {
  Toaster.error(
    error?.message?.message ? error.message.message : error?.message
  );
  return { data: { data: [], pagination: { totalItems: 0 } } };
};

const acFields = [
  {
    field: 'Id',
    title: 'Request ID',
    editable: 'never',
    showInTable: false,
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    cellStyle: {
      whiteSpace: 'nowrap',
      maxWidth: '55px',
      overflow: 'hidden',
    },
  },
  {
    field: 'OpportunityId',
    title: (
      <Tooltip enterNextDelay={300} title="Opportunity">
        <div className="cell-with-tooltip">Opportunity</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    cellStyle: {
      whiteSpace: 'nowrap',
      maxWidth: '55px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  {
    field: 'Customer',
    title: (
      <Tooltip enterNextDelay={300} title="Customer">
        <div className="cell-with-tooltip">Customer</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Customer}>
        <div className="cell-with-tooltip">{row.Customer}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'Project',
    title: (
      <Tooltip enterNextDelay={300} title="Project">
        <div className="cell-with-tooltip">Project</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Project}>
        <div className="cell-with-tooltip">{row.Project}</div>
      </Tooltip>
    ),
    maxWidth: '55px',
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'Salesperson',
    title: (
      <Tooltip enterNextDelay={300} title="Salesperson">
        <div className="cell-with-tooltip">Salesperson</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Salesperson}>
        <div className="cell-with-tooltip">{row.Salesperson}</div>
      </Tooltip>
    ),
    maxWidth: '55px',
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'Ac',
    title: (
      <Tooltip enterNextDelay={300} title="AC">
        <div className="cell-with-tooltip">AC</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Ac}>
        <div>{row.Ac}</div>
      </Tooltip>
    ),
    maxWidth: '55px',
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'ProductType',
    title: (
      <Tooltip enterNextDelay={300} title="Product">
        <div className="cell-with-tooltip">Product</div>
      </Tooltip>
    ),
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.ProductType}>
        <div className="cell-with-tooltip">{row.ProductType}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'RequestType',
    title: (
      <Tooltip enterNextDelay={300} title="Req Type">
        <div className="cell-with-tooltip">Req Type</div>
      </Tooltip>
    ),
    exType: 'select',
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestType}>
        <div className="cell-with-tooltip">{row.RequestType}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'RequestNotes',
    title: (
      <Tooltip enterNextDelay={300} title="Req Note">
        <div className="cell-with-tooltip">Req Note</div>
      </Tooltip>
    ),
    editable: true,
    showInTable: true,
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestNotes}>
        <div className="cell-with-tooltip">{row.RequestNotes}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'AssignedDate',
    title: (
      <Tooltip enterNextDelay={300} title="Req Date">
        <div className="cell-with-tooltip">Req Date</div>
      </Tooltip>
    ),
    type: 'date',
    format: 'YYYY-MM-DD',
    editable: 'never',
    showInTable: true,
    maxWidth: '55px',
    cellStyle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '55px',
      overflow: 'hidden',
    },
    filtering: false,
    sortable: true,
  },
  {
    field: 'ExpectedCompletionDate',
    title: (
      <Tooltip enterNextDelay={300} title="Expected">
        <div className="cell-with-tooltip">Expected</div>
      </Tooltip>
    ),
    type: 'date',
    format: 'YYYY-MM-DD',
    editable: 'never',
    maxWidth: '55px',
    render: getRenderForExpectedCompletionDate,
    cellStyle: {
      maxWidth: '55px',
    },
    showInTable: true,
    filtering: false,
    sortable: false,
  },
  {
    field: 'DaysToComplete',
    title: (
      <Tooltip enterNextDelay={300} title="DTC">
        <div className="cell-with-tooltip">DTC</div>
      </Tooltip>
    ),
    showInTable: true,
    maxWidth: '55px',
    cellStyle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '55px',
      overflow: 'hidden',
    },
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'RequestStatus',
    title: (
      <Tooltip enterNextDelay={300} title="Status">
        <div className="cell-with-tooltip">Status</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestStatus}>
        <div className="cell-with-tooltip">{row.RequestStatus}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
    filtering: false,
    sortable: true,
  },
  {
    field: 'AssignedTo',
    title: (
      <Tooltip enterNextDelay={300} title="Assigned To">
        <div className="cell-with-tooltip">Assigned To</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.AssignedTo}>
        <div className="cell-with-tooltip">{row.AssignedTo}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
    filtering: false,
    sortable: true,
  },
  {
    field: 'DesignNotes',
    title: (
      <Tooltip enterNextDelay={300} title="Design Notes">
        <div className="cell-with-tooltip">Design Notes</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.DesignNotes}>
        <div className="cell-with-tooltip">{row.DesignNotes}</div>
      </Tooltip>
    ),
  },
  {
    field: 'RequestKind',
    title: 'Request Kind',
    editable: 'never',
    showInTable: false,
    cellStyle: {
      whiteSpace: 'nowrap',
      maxWidth: '55px',
      overflow: 'hidden',
    },
    hideInDetailView: true,
  },
];

const acFieldsAsTable = [
  {
    id: '1',
    name: 'column1',
    size: {
      lg: 4,
      md: 4,
      sm: 4,
    },
    fields: [
      {
        field: 'Id',
        title: 'Request ID',
        editable: 'never',
        showInTable: false,
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        cellStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '55px',
          overflow: 'hidden',
        },
      },
      {
        field: 'OpportunityId',
        title: 'Oppy',
        type: 'numeric',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        cellStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '55px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
      {
        field: 'Customer',
        title: 'Customer',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Customer}>
            <div className="cell-with-tooltip">{row.Customer}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'Project',
        title: 'Project',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Project}>
            <div className="cell-with-tooltip">{row.Project}</div>
          </Tooltip>
        ),
        maxWidth: '55px',
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'Salesperson',
        title: 'Salesperson',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Salesperson}>
            <div className="cell-with-tooltip">{row.Salesperson}</div>
          </Tooltip>
        ),
        maxWidth: '55px',
        cellStyle: {
          maxWidth: '55px',
        },
      },
    ],
  },
  {
    id: '2',
    name: 'column2',
    size: {
      lg: 4,
      md: 4,
      sm: 4,
    },
    fields: [
      {
        field: 'Ac',
        title: 'AC',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Ac}>
            <div>{row.Ac}</div>
          </Tooltip>
        ),
        maxWidth: '55px',
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'ProductType',
        title: 'Product',
        showInTable: false,
        editable: 'never',
        filtering: false,
        sortable: true,
      },
      {
        field: 'RequestType',
        title: 'Req Type',
        exType: 'select',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.RequestType}>
            <div className="cell-with-tooltip">{row.RequestType}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'AssignedDate',
        title: 'Req Date',
        type: 'date',
        format: 'YYYY-MM-DD',
        editable: 'never',
        showInTable: true,
        maxWidth: '55px',
        cellStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '55px',
          overflow: 'hidden',
        },
        filtering: false,
        sortable: true,
      },
      {
        field: 'ExpectedCompletionDate',
        title: 'Expected',
        type: 'date',
        format: 'YYYY-MM-DD',
        editable: 'never',
        maxWidth: '55px',
        render: getRenderForExpectedCompletionDate,
        cellStyle: {
          maxWidth: '55px',
        },
        showInTable: true,
        filtering: false,
        sortable: false,
      },
    ],
  },
  {
    id: '3',
    name: 'column3',
    size: {
      lg: 4,
      md: 4,
      sm: 4,
    },
    fields: [
      {
        field: 'DaysToComplete',
        title: 'DTC',
        showInTable: true,
        maxWidth: '55px',
        cellStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '55px',
          overflow: 'hidden',
        },
        editable: 'never',
        filtering: false,
        sortable: true,
      },
      {
        field: 'RequestStatus',
        title: 'Status',
        showInTable: true,
        editable: 'never',
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.RequestStatus}>
            <div className="cell-with-tooltip">{row.RequestStatus}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
        filtering: false,
        sortable: true,
      },
      {
        field: 'AssignedTo',
        title: 'Assigned To',
        showInTable: true,
        editable: 'never',
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.AssignedTo}>
            <div className="cell-with-tooltip">{row.AssignedTo}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
        filtering: false,
        sortable: true,
      },
      {
        field: 'RequestKind',
        title: 'Request Kind',
        editable: 'never',
        showInTable: false,
        hideInDetailView: true,
      },
      {
        field: 'OnHoldBy',
        title: 'On Hold By',
        editable: 'never',
        showInTable: false,
        hideInDetailView: true,
      },
      {
        field: 'OnHoldDate',
        title: 'On Hold Date',
        editable: 'never',
        showInTable: false,
        hideInDetailView: true,
      },
    ],
  },
];

const acFieldsAsCols = [
  {
    field: 'RequestNotes',
    title: 'Req Note',
    editable: true,
    showInTable: true,
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestNotes}>
        <div className="cell-with-tooltip">{row.RequestNotes}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
    validations: {
      validate: ({ RequestNotes }) => {
        if (RequestNotes.length > 255) {
          return 'This will exceed the Request Notes character limit. Please shorten the Request Notes and try again.';
        }
        return false;
      },
    },
  },
  {
    field: 'DesignNotes',
    title: 'Design Notes',
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.DesignNotes}>
        <div className="cell-with-tooltip">{row.DesignNotes}</div>
      </Tooltip>
    ),
  },
];

const acMetadata = {
  headers: acFields.filter(({ showInTable }) => showInTable),
  detailViewCols: acFieldsAsCols,
  detailViewColsAsTable: acFieldsAsTable,
};

const createACFields = [
  {
    field: 'OpportunityId',
    title: 'Opportunity',
    editable: 'never',
    createModeFromList: true,
  },
  {
    field: 'Customer',
    title: 'Customer',
    editable: 'never',
    createModeFromList: true,
  },
  {
    field: 'Project',
    title: 'Project',
    editable: 'never',
    createModeFromList: true,
  },

  {
    field: 'Salesperson',
    title: 'Salesperson',
    editable: 'never',
    createModeFromList: true,
  },
  {
    field: 'RequestType',
    title: 'Request Type',
    options: {
      ignoreType: true,
    },
    createModeFromList: true,
    exType: 'select',
    lookup: async () => LookupCache.getRequestType(),
  },
  {
    field: 'RequestNotes',
    title: 'Request Notes',
    createModeFromList: true,
  },
  {
    field: 'PriorityNotes',
    title: 'Priority Notes',
    createModeFromList: true,
  },
  {
    field: 'ProjectState',
    title: 'Project State',
    editable: 'never',
    createModeFromList: true,
  },
  {
    field: 'AccountCoordinator',
    title: 'Account Coordinator',
    options: {
      ignoreType: true,
    },
    exType: 'select',
    lookup: async () => LookupCache.getAccountCoordinators(),
    createModeFromList: true,
  },
  {
    field: 'Product',
    title: 'Product',
    editable: 'never',
    createModeFromList: true,
  },
  {
    field: 'ModuleSpec',
    title: 'Module Spec',
    type: 'boolean',
    createModeFromList: true,
  },

  {
    field: 'Aif',
    title: 'Aif',
    type: 'boolean',
    createModeFromList: true,
  },

  {
    field: 'PhotoLayout',
    title: 'Photo Layout',
    type: 'boolean',
    createModeFromList: true,
  },

  {
    field: 'PlanCheckDoc',
    title: 'Plan Check Doc',
    type: 'boolean',
    createModeFromList: true,
  },

  {
    field: 'AsBuiltDoc',
    title: 'As Built Doc',
    type: 'boolean',
    createModeFromList: true,
  },
];

const filtersACFields = {
  Id: {
    label: 'Request ID',
    type: 'numeric',
  },
  OpportunityID: {
    label: 'Opportunity ID',
    type: 'numeric',
  },
  RequestStatus: {
    label: 'Request Status',
    type: 'select',
    lookup: async () => LookupCache.getRequestStatus(),
    options: {
      isMulti: true,
    },
  },
  Salesperson: {
    label: 'Salesperson',
    type: 'select',
    lookup: async () => LookupCache.getSalesperson(),
    options: {
      isMulti: true,
    },
  },
  Rac: {
    label: 'RAC',
    type: 'select',
    lookup: async () => LookupCache.getAccountCoordinators(),
    options: {
      isMulti: true,
    },
  },
  RequestType: {
    label: 'Request Type',
    type: 'select',
    lookup: async () => LookupCache.getRequestType(),
    options: {
      isMulti: true,
    },
  },
  RequestDate: {
    label: 'Request Date',
    type: 'string',
  },
  ExpectedCompletionDate: {
    label: 'Expected Completion Date',
    type: 'string',
  },
  DaysToComplete: {
    label: 'Days to Complete',
    type: 'numeric',
    options: {
      additional: {
        additionalFor: 'DaysToComplete',
        field: 'ExcludeEmptyDtc',
        type: 'checkbox',
        label: 'Exclude empty',
      },
    },
  },
};

const validateACNewRecord = ({
  OpportunityId,
  RequestType,
  RequestNotes,
  ModuleSpec,
  Aif,
  PhotoLayout,
  AsBuiltDoc,
  PlanCheckDoc,
}) => {
  const requestType = RequestType?.id || RequestType?.name || RequestType;
  let errors = {};
  if (!OpportunityId || !requestType) {
    errors = {
      ...errors,
      OpportunityId: !OpportunityId
        ? 'Opportunity is required to submit a new request.'
        : '',
      RequestType: !requestType
        ? 'Request Type is required to submit a new request.'
        : '',
    };
  }

  switch (requestType) {
    case 'Other':
      if (!RequestNotes) {
        errors = {
          ...errors,
          RequestNotes:
            'If the Request Type is Other, you must fill-in the Request Notes, to submit a new request.',
        };
      }
      break;
    case 'Array Layout':
    case 'Ballast Map Layout':
      if (!ModuleSpec || !Aif || !PhotoLayout) {
        errors = {
          ...errors,
          ModuleSpec: !ModuleSpec
            ? 'Module Spec Sheet is required. Please check them all before submitting a new request.'
            : '',
          Aif: !Aif
            ? 'AIF is required. Please check them all before submitting a new request.'
            : '',
          PhotoLayout: !PhotoLayout
            ? 'Aerial documents is required. Please check them all before submitting a new request.'
            : '',
        };
      }
      break;
    case 'Module Compatibility':
      if (!ModuleSpec) {
        errors = {
          ...errors,
          ModuleSpec: !ModuleSpec
            ? 'The Module Spec Sheet is required. Please check them all before submitting a new request.'
            : '',
        };
      }
      break;
    case 'As Built':
      if (!AsBuiltDoc) {
        errors = {
          ...errors,
          AsBuiltDoc: !AsBuiltDoc
            ? 'An As Built Documentation is required. Please check it before submitting a new request.'
            : '',
        };
      }
      break;
    case 'Building Block':
      if (!ModuleSpec) {
        errors = {
          ...errors,
          ModuleSpec: !ModuleSpec
            ? 'A Module Spec Sheet is required. Please check it before submitting a new request.'
            : '',
        };
      }
      break;
    case 'Plan Check Comments':
      if (!PlanCheckDoc) {
        errors = {
          ...errors,
          PlanCheckDoc: !PlanCheckDoc
            ? 'A Plan Check Doc is required. Please check it before submitting a new request.'
            : '',
        };
      }
      break;
    default:
      break;
  }

  if (Object.values(errors).some((error) => error)) {
    return errors;
  }
  return false;
};

const dmFields = [
  {
    field: 'Id',
    title: 'Request ID',
    editable: 'never',
    showInTable: false,
    filtering: false,
    sortable: true,
  },
  {
    field: 'OpportunityId',
    title: (
      <Tooltip enterNextDelay={300} title="Opportunity">
        <div className="cell-with-tooltip">Opportunity</div>
      </Tooltip>
    ),
    type: 'numeric',
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    cellStyle: {
      whiteSpace: 'nowrap',
      maxWidth: '55px',
      overflow: 'hidden',
    },
  },
  {
    field: 'Customer',
    title: (
      <Tooltip enterNextDelay={300} title="Customer">
        <div className="cell-with-tooltip">Customer</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Customer}>
        <div className="cell-with-tooltip">{row.Customer}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'Project',
    title: (
      <Tooltip enterNextDelay={300} title="Project">
        <div className="cell-with-tooltip">Project</div>
      </Tooltip>
    ),

    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Project}>
        <div className="cell-with-tooltip">{row.Project}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'Salesperson',
    title: (
      <Tooltip enterNextDelay={300} title="Salesperson">
        <div className="cell-with-tooltip">Salesperson</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Salesperson}>
        <div className="cell-with-tooltip">{row.Salesperson}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'Rac',
    title: (
      <Tooltip enterNextDelay={300} title="AC">
        <div className="cell-with-tooltip">AC</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Rac}>
        <div className="cell-with-tooltip">{row.Rac}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'AssignedTo',
    title: (
      <Tooltip enterNextDelay={300} title="Assigned To">
        <div className="cell-with-tooltip">Assigned To</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.AssignedTo}>
        <div className="cell-with-tooltip">{row.AssignedTo}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'DesignStatus',
    title: (
      <Tooltip enterNextDelay={300} title="Design Status">
        <div className="cell-with-tooltip">Design Status</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.DesignStatus}>
        <div className="cell-with-tooltip">{row.DesignStatus}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'Reviewer',
    title: (
      <Tooltip enterNextDelay={300} title="Reviewer">
        <div className="cell-with-tooltip">Reviewer</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Reviewer}>
        <div className="cell-with-tooltip">{row.Reviewer}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'DesignNotes',
    title: (
      <Tooltip enterNextDelay={300} title="Design Notes">
        <div className="cell-with-tooltip">Design Notes</div>
      </Tooltip>
    ),
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'ThirdParty',
    title: '3rd party',
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '60px',
    cellStyle: {
      whiteSpace: 'nowrap',
      maxWidth: '60px',
      overflow: 'hidden',
    },
  },
  {
    field: 'Priority',
    title: (
      <Tooltip enterNextDelay={300} title="Priority">
        <div className="cell-with-tooltip">Priority</div>
      </Tooltip>
    ),
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Priority}>
        <div className="cell-with-tooltip">{row.Priority}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'PriorityNotes',
    title: (
      <Tooltip enterNextDelay={300} title="Priority Notes">
        <div className="cell-with-tooltip">Priority Notes</div>
      </Tooltip>
    ),
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'RequestStatus',
    title: (
      <Tooltip enterNextDelay={300} title="Request Status">
        <div className="cell-with-tooltip">Request Status</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestStatus}>
        <div className="cell-with-tooltip">{row.RequestStatus}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'RequestCategory',
    title: 'Request Category',
    exType: 'select',
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'RequestDate',
    title: (
      <Tooltip enterNextDelay={300} title="Req Date">
        <div className="cell-with-tooltip">Req Date</div>
      </Tooltip>
    ),
    type: 'date',
    format: 'YYYY-MM-DD',
    editable: 'never',
    showInTable: true,
    maxWidth: '60px',
    cellStyle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '60px',
      overflow: 'hidden',
    },
    filtering: false,
    sortable: true,
  },
  {
    field: 'Product',
    title: (
      <Tooltip enterNextDelay={300} title="Product">
        <div className="cell-with-tooltip">Product</div>
      </Tooltip>
    ),
    exType: 'select',
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.Product}>
        <div className="cell-with-tooltip">{row.Product}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'RequestType',
    title: (
      <Tooltip enterNextDelay={300} title="Request Type">
        <div className="cell-with-tooltip">Request Type</div>
      </Tooltip>
    ),
    exType: 'select',
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestType}>
        <div className="cell-with-tooltip">{row.RequestType}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'RequestNotes',
    title: (
      <Tooltip enterNextDelay={300} title="Request Notes">
        <div className="cell-with-tooltip">Request Notes</div>
      </Tooltip>
    ),
    editable: true,
    showInTable: true,
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestNotes}>
        <div className="cell-with-tooltip">{row.RequestNotes}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
  {
    field: 'ExpectedCompletionDate',
    title: (
      <Tooltip enterNextDelay={300} title="Expected">
        <div className="cell-with-tooltip">Expected</div>
      </Tooltip>
    ),
    type: 'date',
    format: 'YYYY-MM-DD',
    editable: 'never',
    maxWidth: '55px',
    render: getRenderForExpectedCompletionDate,
    cellStyle: {
      maxWidth: '55px',
    },
    showInTable: true,
    filtering: false,
    sortable: false,
  },
  {
    field: 'DaysToComplete',
    title: (
      <Tooltip enterNextDelay={300} title="DTC">
        <div className="cell-with-tooltip">DTC</div>
      </Tooltip>
    ),
    showInTable: true,
    maxWidth: '55px',
    cellStyle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '55px',
      overflow: 'hidden',
    },
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'DesignNotes',
    title: (
      <Tooltip enterNextDelay={300} title="Design Notes">
        <div className="cell-with-tooltip">Design Notes</div>
      </Tooltip>
    ),
    showInTable: true,
    editable: 'never',
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.DesignNotes}>
        <div className="cell-with-tooltip">{row.DesignNotes}</div>
      </Tooltip>
    ),
  },
  {
    field: 'CompletedDate',
    title: 'CompletedDate',
    showInTable: false,
    editable: 'never',
    filtering: false,
    sortable: true,
  },
  {
    field: 'OutsourceHrs',
    title: 'OutsourceHrs',
    type: 'numeric',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'PcReviewHrs',
    title: 'PcReviewHrs',
    type: 'numeric',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'PcDesignHrs',
    title: 'PcDesignHrs',
    type: 'numeric',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'ProductGen',
    title: 'ProductGen',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'ProductType',
    title: 'Product',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Claw',
    title: 'Claw',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Tilt',
    title: 'Tilt',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'ClawMaterial',
    title: 'ClawMaterial',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Lug',
    title: 'Lug',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Pad',
    title: 'Pad',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Spacing',
    title: 'Spacing',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Module',
    title: 'Module',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'ModuleDim',
    title: 'ModuleDim',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Asce7',
    title: 'Asce7',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'WindSpeed',
    title: 'WindSpeed',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'WindExposure',
    title: 'WindExposure',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Occupancy',
    title: 'Occupancy',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Sdc',
    title: 'Sdc',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'SeismicMethod',
    title: 'SeismicMethod',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'GroundSnow',
    title: 'GroundSnow',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'RoofHeight',
    title: 'RoofHeight',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Parapet',
    title: 'Parapet',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'RoofSlope',
    title: 'RoofSlope',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'SystemSize',
    title: 'SystemSize',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Revision',
    title: 'Revision',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'MaxPsf',
    title: 'MaxPsf',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'MinPsf',
    title: 'MinPsf',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'MaType',
    title: 'MaType',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'PsfRestriction',
    title: 'PsfRestriction',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'ModuleMfg',
    title: 'ModuleMfg',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Model',
    title: 'Model',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'PowerStc',
    title: 'PowerStc',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Pl',
    title: 'Pl',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Pw',
    title: 'Pw',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Pt',
    title: 'Pt',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Q',
    title: 'Q',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'W',
    title: 'W',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'HoleDiameter',
    title: 'HoleDiameter',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'Sds',
    title: 'Sds',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'MaxMpv',
    title: 'MaxMpv',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'FireRatingReq',
    title: 'FireRatingReq',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
];

const dmFieldsAsCols = [
  {
    field: 'DesignNotes',
    title: 'Design Notes',
    showInTable: false,
    editable: true,
    filtering: false,
    sortable: true,
  },
  {
    field: 'RequestNotes',
    title: 'Req Note',
    editable: true,
    showInTable: true,
    filtering: false,
    sortable: true,
    maxWidth: '55px',
    render: (row) => (
      <Tooltip enterNextDelay={700} title={row.RequestNotes}>
        <div className="cell-with-tooltip">{row.RequestNotes}</div>
      </Tooltip>
    ),
    cellStyle: {
      maxWidth: '55px',
    },
  },
];

const dmFieldsAsTable = [
  {
    id: '1',
    name: 'column1',
    size: {
      lg: 4,
      md: 4,
      sm: 4,
    },
    fields: [
      {
        field: 'Id',
        title: 'Request ID',
        editable: 'never',
        showInTable: false,
        filtering: false,
        sortable: true,
      },
      {
        field: 'OpportunityId',
        title: 'Oppy',
        type: 'numeric',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        cellStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '55px',
          overflow: 'hidden',
        },
      },
      {
        field: 'Customer',
        title: 'Customer',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Customer}>
            <div className="cell-with-tooltip">{row.Customer}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'Project',
        title: 'Project',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Project}>
            <div className="cell-with-tooltip">{row.Project}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'Salesperson',
        title: 'Salesperson',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Salesperson}>
            <div className="cell-with-tooltip">{row.Salesperson}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'Rac',
        title: 'AC',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Rac}>
            <div className="cell-with-tooltip">{row.Rac}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'AssignedTo',
        title: 'Assigned To',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.AssignedTo}>
            <div className="cell-with-tooltip">{row.AssignedTo}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'DesignStatus',
        title: 'Design Status',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.DesignStatus}>
            <div className="cell-with-tooltip">{row.DesignStatus}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'Reviewer',
        title: 'Reviewer',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Reviewer}>
            <div className="cell-with-tooltip">{row.Reviewer}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
    ],
  },
  {
    id: '2',
    name: 'column2',
    size: {
      lg: 4,
      md: 4,
      sm: 4,
    },
    fields: [
      {
        field: 'Priority',
        title: 'Priority',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Priority}>
            <div className="cell-with-tooltip">{row.Priority}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'PriorityNotes',
        title: 'PriorityNotes',
        showInTable: false,
        editable: 'never',
        filtering: false,
        sortable: true,
      },
      {
        field: 'RequestStatus',
        title: 'Request Status',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.RequestStatus}>
            <div className="cell-with-tooltip">{row.RequestStatus}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'RequestCategory',
        title: 'Request Category',
        exType: 'select',
        showInTable: false,
        editable: 'never',
        filtering: false,
        sortable: true,
      },
      {
        field: 'RequestDate',
        title: 'Req Date',
        type: 'date',
        format: 'YYYY-MM-DD',
        editable: 'never',
        showInTable: true,
        maxWidth: '60px',
        cellStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '60px',
          overflow: 'hidden',
        },
        filtering: false,
        sortable: true,
      },
      {
        field: 'Product',
        title: 'Product',
        exType: 'select',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.Product}>
            <div className="cell-with-tooltip">{row.Product}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'RequestType',
        title: 'Req Type',
        exType: 'select',
        showInTable: true,
        editable: 'never',
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.RequestType}>
            <div className="cell-with-tooltip">{row.RequestType}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'RequestNotes',
        title: 'Req Note',
        editable: true,
        showInTable: true,
        filtering: false,
        sortable: true,
        maxWidth: '55px',
        render: (row) => (
          <Tooltip enterNextDelay={700} title={row.RequestNotes}>
            <div className="cell-with-tooltip">{row.RequestNotes}</div>
          </Tooltip>
        ),
        cellStyle: {
          maxWidth: '55px',
        },
      },
      {
        field: 'ExpectedCompletionDate',
        title: 'Expected',
        type: 'date',
        format: 'YYYY-MM-DD',
        editable: 'never',
        maxWidth: '55px',
        render: getRenderForExpectedCompletionDate,
        cellStyle: {
          maxWidth: '55px',
        },
        showInTable: true,
        filtering: false,
        sortable: false,
      },
    ],
  },
  {
    id: '3',
    name: 'column3',
    size: {
      lg: 4,
      md: 4,
      sm: 4,
    },
    fields: [
      {
        field: 'DaysToComplete',
        title: 'DTC',
        showInTable: true,
        maxWidth: '55px',
        cellStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '55px',
          overflow: 'hidden',
        },
        editable: 'never',
        filtering: false,
        sortable: true,
      },
      {
        field: 'ThirdParty',
        title: '3rd party',
        showInTable: false,
        editable: 'never',
        filtering: false,
        sortable: true,
      },
      {
        field: 'CompletedDate',
        title: 'CompletedDate',
        showInTable: false,
        editable: 'never',
        filtering: false,
        sortable: true,
      },
      {
        field: 'OutsourceHrs',
        title: 'OutsourceHrs',
        type: 'numeric',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'PcReviewHrs',
        title: 'PcReviewHrs',
        type: 'numeric',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'PcDesignHrs',
        title: 'PcDesignHrs',
        type: 'numeric',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'ProductGen',
        title: 'ProductGen',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'ProductType',
        title: 'Product',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'OnHoldBy',
        title: 'On Hold By',
        editable: 'never',
        showInTable: false,
        hideInDetailView: true,
      },
      {
        field: 'OnHoldDate',
        title: 'On Hold Date',
        editable: 'never',
        showInTable: false,
        hideInDetailView: true,
      },
    ],
  },
];

const dmFieldsAsAccordion = [
  {
    id: '1',
    name: 'column1',
    size: {
      lg: 4,
      md: 4,
      sm: 4,
    },
    fields: [
      {
        field: 'Claw',
        title: 'Claw',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Tilt',
        title: 'Tilt',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'ClawMaterial',
        title: 'ClawMaterial',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Lug',
        title: 'Lug',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Pad',
        title: 'Pad',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Spacing',
        title: 'Spacing',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Module',
        title: 'Module',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'ModuleDim',
        title: 'ModuleDim',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Asce7',
        title: 'Asce7',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'WindSpeed',
        title: 'WindSpeed',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'WindExposure',
        title: 'WindExposure',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Occupancy',
        title: 'Occupancy',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
    ],
  },
  {
    id: '2',
    name: 'column2',
    size: {
      lg: 4,
      md: 4,
      sm: 4,
    },
    fields: [
      {
        field: 'Sdc',
        title: 'Sdc',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'SeismicMethod',
        title: 'SeismicMethod',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'GroundSnow',
        title: 'GroundSnow',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'RoofHeight',
        title: 'RoofHeight',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Parapet',
        title: 'Parapet',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'RoofSlope',
        title: 'RoofSlope',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'SystemSize',
        title: 'SystemSize',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Revision',
        title: 'Revision',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'MaxPsf',
        title: 'MaxPsf',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'MinPsf',
        title: 'MinPsf',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'MaType',
        title: 'MaType',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'PsfRestriction',
        title: 'PsfRestriction',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
    ],
  },
  {
    id: '3',
    name: 'column3',
    size: {
      lg: 4,
      md: 4,
      sm: 4,
    },
    fields: [
      {
        field: 'ModuleMfg',
        title: 'ModuleMfg',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Model',
        title: 'Model',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'PowerStc',
        title: 'PowerStc',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Pl',
        title: 'Pl',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Pw',
        title: 'Pw',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Pt',
        title: 'Pt',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Q',
        title: 'Q',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'W',
        title: 'W',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'HoleDiameter',
        title: 'HoleDiameter',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'Sds',
        title: 'Sds',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'MaxMpv',
        title: 'MaxMpv',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
      {
        field: 'FireRatingReq',
        title: 'FireRatingReq',
        showInTable: false,
        editable: true,
        filtering: false,
        sortable: true,
      },
    ],
  },
];

const dmMetadata = {
  headers: dmFields.filter(({ showInTable }) => showInTable),
  detailViewCols: dmFieldsAsCols,
  detailViewColsAsTable: dmFieldsAsTable,
  detailViewColsAsAccordion: dmFieldsAsAccordion,
};

const createDMFields = [
  {
    field: 'Customer',
    title: 'Customer',
    editable: 'never',
    createModeFromList: true,
  },
  {
    field: 'Project',
    title: 'Project',
    editable: 'never',
    createModeFromList: true,
  },

  {
    field: 'Salesperson',
    title: 'Salesperson',
    editable: 'never',
    createModeFromList: true,
  },
  {
    field: 'RequestType',
    title: 'Request Type',
    createModeFromList: true,
    exType: 'select',
    lookup: async () => LookupCache.getRequestType(),
  },
  {
    field: 'RequestNotes',
    title: 'Request Notes',
    createModeFromList: true,
  },
  {
    field: 'PriorityNotes',
    title: 'Priority Notes',
    createModeFromList: true,
  },
];

const filtersDMFields = {
  Id: {
    label: 'Request ID',
    type: 'numeric',
  },
  OpportunityID: {
    label: 'Opportunity ID',
    type: 'numeric',
  },
  RequestStatus: {
    label: 'Request Status',
    type: 'select',
    lookup: async () => LookupCache.getRequestStatus(),
    options: {
      isMulti: true,
    },
  },
  // Salesperson: {
  //   label: 'Salesperson',
  //   type: 'select',
  //   lookup: async () => LookupCache.getSalesperson(),
  // },
  // Rac: {
  //   label: 'RAC',
  //   type: 'select',
  //   lookup: async () => LookupCache.getAccountCoordinators(),
  // },
  RequestType: {
    label: 'Request Type',
    type: 'select',
    lookup: async () => LookupCache.getRequestType(),
    options: {
      isMulti: true,
    },
  },
  RequestDate: {
    label: 'Request Date',
    type: 'string',
  },
  ExpectedCompletionDate: {
    label: 'Expected Completion Date',
    type: 'string',
  },
  DaysToComplete: {
    label: 'Days to Complete',
    type: 'numeric',
    options: {
      additional: {
        additionalFor: 'DaysToComplete',
        field: 'ExcludeEmptyDtc',
        type: 'checkbox',
        label: 'Exclude empty',
      },
    },
  },
  AssignedTo: {
    label: 'Assigned To',
    type: 'select',
    lookup: async () => LookupCache.getAssignTo(),
    options: {
      isMulti: true,
    },
  },
  ReviewedByDate: {
    label: 'Reviewed By Date',
    type: 'date',
    format: 'YYYY-MM-DD',
  },
  Reviewer: {
    label: 'Reviewer',
    type: 'select',
    lookup: async () => LookupCache.getReviewer(),
    options: {
      isMulti: true,
    },
  },
  DesignStatus: {
    label: 'Design Status',
    type: 'select',
    lookup: async () => LookupCache.getDesignStatus(),
    options: {
      isMulti: true,
    },
  },
};

const validateDMNewRecord = ({ RequestType, RequestNotes }) => {
  const requestType = RequestType?.name || RequestType;
  let errors = {};
  if (!requestType) {
    errors = {
      ...errors,
      RequestType: !requestType
        ? 'Request Type is required to submit a new request.'
        : '',
    };
  }

  if (requestType === 'Other' && !RequestNotes) {
    errors = {
      ...errors,
      RequestNotes:
        'If the Request Type is Other, you must fill-in the Request Notes, to submit a new request.',
    };
  }

  if (Object.values(errors).some((error) => error)) {
    return errors;
  }
  return false;
};

export const toLookups = (data, initial = []) => {
  if (!data) return initial;

  const items = data.map(({ Id, Value }) => ({ value: Id, label: Value }));

  return uniqBy([...initial, ...items], 'value');
};

const getFilterBox = (
  open,
  type,
  filters,
  onSubmit,
  onResetFilter,
  onClose
) => {
  const fields =
    type === 'accountCoordinator' ? filtersACFields : filtersDMFields;
  if (open) {
    return (
      <FilterBox
        fields={fields}
        filters={filters}
        onSubmit={onSubmit}
        onResetFilter={onResetFilter}
        onClose={onClose}
      />
    );
  }
};

const getHeaderActions = (type) => [
  {
    label: 'Export Excel',
    getUrlLink: async (filter) => {
      return api.getExportRequests(type, { ...filter, exportTo: 'excel' });
    },
  },
  {
    label: 'Export PDF',
    getUrlLink: async (filter) => {
      return api.getExportRequests(type, { ...filter, exportTo: 'pdf' });
    },
  },
];

export default {
  accountCoordinator: {
    components: {
      header: {
        title: 'Designer Workflow - Account Coordinator View',
        getHeaderActions: () => getHeaderActions('ac'),
      },
      search: {
        getFilterBox,
      },
    },
    title: 'AC Requests',
    name: 'ACRequests',
    type: 'accountCoordinator',
    getItem: api.getDWACRequest,
    getData: async (param) => {
      const newParams = inlineFilters(param);
      const res = await api
        .getDWACRequest(newParams)
        .catch(handleGetDataErrors);

      return {
        ...res,
        count: res.data.pagination.totalItems,
        data: res.data.data,
      };
    },
    saveNewRequest: async (data) => {
      const payload = {
        data: {
          ...data.data,
          RequestType: data.data.RequestType.id,
          AccountCoordinator: data.data.AccountCoordinator?.id,
        },
      };

      return api.saveNewACRequest(payload);
    },
    putDWRequest: async (data) => {
      return api.putDWACRequest(data);
    },
    validateNewRecord: validateACNewRecord,
    validateStampRequest: (data) => {
      if (data.RequestType === 'Project Stamp') {
        Toaster.error(
          'This request is an existing Stamp Request. Please select a regular request'
        );
        return false;
      }

      if (data.RequestType === 'Array Layout') {
        Toaster.error('You cannot request a stamp on a Array Layout request');
        return false;
      }

      if (data.RequestStatus === 'Canceled') {
        Toaster.error('This request is already Canceled');
        return false;
      }

      if (data.OpportunityId === '' && data.RequestStatus !== 'Complete') {
        Toaster.error(
          'Opportunity number is missing. Please make sure that there is an Opportunity number for the request'
        );
        return false;
      }

      return true;
    },
    validateSwapDates: (requestFirst, requestSecond) => {
      if (
        requestFirst.RequestType === 'Project Stamp' &&
        requestSecond.RequestType === 'Project Stamp'
      ) {
        Toaster.error(
          'The requests you selected to swap, contain a Project Stamp request. Please select different requests'
        );
        return false;
      }

      if (
        requestFirst.RequestStatus === 'Complete' &&
        requestSecond.RequestStatus === 'Complete'
      ) {
        Toaster.error(
          'The requests you selected to swap, contain a Completed request. Please select different requests'
        );
        return false;
      }

      if (
        requestFirst.RequestStatus === 'Canceled' &&
        requestSecond.RequestStatus === 'Canceled'
      ) {
        Toaster.error(
          'The requests you selected to swap, contain a Canceled request. Please select different requests'
        );
        return false;
      }

      return true;
    },
    postDWStampRequest: async (data) => {
      const payload = {
        OpportunityId: data.data.OpportunityId,
        Customer: data.data.Customer,
        Project: data.data.Project,
        Salesperson: data.data.Salesperson,
        Rac: data.data.Ac,
        ProductType: data.data.ProductType,
        RequestType: data.data.RequestType,
        RequestStatus: data.data.RequestStatus,
        OriginalRequestId: data.data.Id,
      };
      return api.postDWACStampRequest({ data: payload });
    },
    headers: acMetadata.headers,
    defaultSortDirection: 'asc',
    createHeaders: createACFields,
    detailViewCols: acMetadata.detailViewCols,
    detailViewColsAsTable: acMetadata.detailViewColsAsTable,
  },
  designerManager: {
    components: {
      header: {
        title: 'Designer Workflow - Designer view',
        getHeaderActions: () => getHeaderActions('dm'),
      },
      search: {
        getFilterBox,
      },
    },
    title: 'Designer Requests',
    name: 'DesignerRequests',
    type: 'designerManager',
    getItem: api.getDWDMRequest,
    getData: async (param) => {
      const newParams = inlineFilters(param);
      const res = await api
        .getDWDMRequest(newParams)
        .catch(handleGetDataErrors);

      return {
        ...res,
        count: res.data.pagination.totalItems,
        data: res.data.data,
      };
    },
    saveNewRequest: async (data) => {
      const payload = {
        data: {
          ...data.data,
          RequestType: data.data.RequestType.id,
          AccountCoordinator: data.data.AccountCoordinator?.id,
        },
      };

      return api.saveNewDMRequest(payload);
    },
    putDWRequest: async (data) => {
      return api.putDWDMRequest(data);
    },
    validateNewRecord: validateDMNewRecord,
    headers: dmMetadata.headers,
    createHeaders: createDMFields,
    detailViewCols: dmMetadata.detailViewCols,
    detailViewColsAsTable: dmMetadata.detailViewColsAsTable,
    detailViewColsAsAccordion: dmMetadata.detailViewColsAsAccordion,
    map: new Map([
      [
        'RequestStatus',
        new Map([
          ['InProgress', 'In Progress'],
          ['OnHold', 'On Hold'],
          ['Complete', 'Complete'],
        ]),
      ],
    ]),
    canEditFields: {
      ThirdParty: {
        exType: 'select',
        lookup: async () => LookupCache.getThirdPartyType(),
        when: {
          RequestType: 'Project Stamp',
        },
      },
    },
  },
  designerView: {
    canEditFields: {
      ThirdParty: {
        exType: 'select',
        lookup: async () => LookupCache.getThirdPartyType(),
        when: {
          RequestType: 'Project Stamp',
        },
      },
    },
  },
};
