/* eslint-disable react/display-name */
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import LaunchIcon from '@material-ui/icons/Launch';

import { api } from 'utils/fetch';
import Toaster from 'components/ToasterQueue';

export const onClickDataSheetUrl = (key, isCustom = false) => {
  const tmpExt = key.split('.').pop().toLowerCase();
  const extension = tmpExt !== 'pdf' || tmpExt === key ? '.pdf' : '';

  return (
    api
      .getSpecSheet(key + extension, isCustom)
      .then(({ data: { url } }) => window.open(url))
      // eslint-disable-next-line no-console
      .catch((e) => {
        Toaster.error('Document does not exist');
        console.error(e);
      })
  );
};

const rackingFamily = {
  2: 'CFR',
  3: 'CFRPlus',
};

export const getSMMLogsHeaders = () => {
  const fields = [
    {
      field: 'id',
      title: 'id',
      searchable: true,
      showInTable: true,
    },
    {
      field: 'origin',
      title: 'Origin',
      searchable: true,
      showInTable: true,
    },
    {
      field: 'call',
      title: 'Call',
      searchable: true,
      showInTable: true,
    },
    {
      field: 'status',
      title: 'Status',
      searchable: true,
      showInTable: true,
    },
    {
      field: 'SyncStatus.ReportUrl',
      title: 'Report Url',
      searchable: true,
      showInTable: true,
    },
    {
      field: 'SyncStatus.HasErrors',
      title: 'Has Errors',
      type: 'boolean',
      showInTable: true,
    },
    {
      field: 'createdAt',
      title: 'Created At',
      type: 'date',
      searchable: true,
      showInTable: true,
    },
    {
      field: 'createdByEmail',
      title: 'Created By Email',
      searchable: true,
      showInTable: true,
    },
  ];

  return {
    headers: fields.filter(({ showInTable }) => showInTable),
    detailViewCols: fields,
    fieldsMap: fields,
  }
};

export const getSMMHeaders = (isCustom = false) => {
  const fields = [
    {
      field: 'manufacturer',
      title: 'Manufacturer',
      searchable: true,
      showInTable: true,
      options: [],
      validations: {
        required: true,
      },
    },
    {
      field: 'model',
      title: 'Model',
      searchable: true,
      showInTable: true,
      options: [],
      dependsOn: 'manufacturer',
      validations: {
        required: true,
      },
    },
    {
      field: 'power',
      title: 'Power',
      type: 'numeric',
      sorting: false,
      searchable: false,
      showInTable: true,
      validations: {
        required: true,
      },
    },
    {
      field: 'availableInDropdown',
      title: 'Available in dropdown',
      showInTable: true,
      sorting: false,
      type: 'boolean',
      searchable: false,
      hideInEdit: true,
      render: (row = {}) => (
        <Checkbox
          disabled
          size="small"
          color="primary"
          checked={row.availableInDropdown}
        />
      ),
    },
    {
      field: 'manufactureApprovalLetter',
      title: 'Manufacture Approval Letter',
      showInTable: false,
      sorting: false,
      type: 'boolean',
      searchable: false,
      hideInEdit: false,
      render: (row = {}) => (
        <Checkbox
          disabled
          size="small"
          color="primary"
          checked={row.manufactureApprovalLetter}
        />
      ),
    },
    {
      field: 'modMfgApproved',
      title: 'Mod MFG Approved',
      showInTable: false,
      sorting: false,
      type: 'boolean',
      searchable: false,
      hideInEdit: false,
      render: (row = {}) => (
        <Checkbox
          disabled
          size="small"
          color="primary"
          checked={row.modMfgApproved}
        />
      ),
    },
    {
      field: 'dataSheetUrl',
      title: 'Data Sheet Url',
      searchable: false,
      showInTable: true,
      sorting: false,
      filtering: false,
      hideInEdit: true,
      validations: {
        validate: (data) => {
          if (data.availableInDropdown === true && !data.dataSheetUrl) {
            return 'Data Sheet Url is required if Available in dropdown';
          }
          return false;
        },
      },
      render: (row = {}) => {
        /* eslint-disable */
        return (
          <>
            {row.dataSheetUrl ? (
              <Link
                component="button"
                className="data-sheet-url"
                onClick={() => onClickDataSheetUrl(row.dataSheetUrl, isCustom)}
              >
                Data Sheet
                <LaunchIcon fontSize="small" />
              </Link>
            ) : (
              '---'
            )}
          </>
        );
      },
      /* eslint-enable */
    },
    {
      field: 'measurementUnit',
      path: 'moduleDimensions.measurementUnit',
      title: 'Measurement Unit',
      type: 'numeric',
      validations: {
        required: false,
      },
    },
    {
      field: 'aebe',
      path: 'moduleDimensions.aebe',
      title: 'AEBE',
      type: 'numeric',
      validations: {
        required: false,
      },
    },
    {
      field: 'pl',
      path: 'moduleDimensions.pl',
      title: 'PL',
      type: 'numeric',
      validations: {
        required: true,
      },
    },
    {
      field: 'pw',
      path: 'moduleDimensions.pw',
      title: 'PW',
      type: 'numeric',
      validations: {
        required: true,
      },
    },
    {
      field: 'holeDiameter',
      path: 'moduleDimensions.holeDiameter',
      title: 'Hole Diameter',
      type: 'numeric',
    },
    {
      field: 'apt',
      path: 'moduleDimensions.apt',
      title: 'APT',
      type: 'numeric',
      validations: {
        required: false,
      },
    },
    {
      field: 'bpt',
      path: 'moduleDimensions.bpt',
      title: 'BPT',
      type: 'numeric',
      validations: {
        required: false,
      },
    },
    {
      field: 'ph',
      path: 'moduleDimensions.ph',
      title: 'PH',
      type: 'numeric',
      validations: {
        required: false,
      },
    },
    {
      field: 'pt',
      path: 'moduleDimensions.pt',
      title: 'PT',
      type: 'numeric',
      validations: {
        required: false,
      },
    },
    {
      field: 'q',
      path: 'moduleDimensions.q',
      title: 'Q',
      type: 'numeric',
      validations: {
        required: false,
      },
    },
    {
      field: 'w',
      path: 'moduleDimensions.w',
      title: 'W',
      type: 'numeric',
      validations: {
        required: false,
      },
    },
    {
      field: 'slotLengthOverall',
      path: 'moduleDimensions.slotLengthOverall',
      title: 'Slot Length Overall',
      type: 'numeric',
      validations: {
        required: false,
      },
    },
    {
      field: 'rackingAebe',
      path: 'rackingAebe',
      title: 'Racking AEBE',
      exType: 'table',
      headers: {
        fields: [
          {
            field: 'productFamily',
            title: 'Product Family',
            type: 'string',
            headerStyle: {
              textAlign: 'center',
            },
            render: (row = {}) => rackingFamily[row.productFamily],
            editable: 'never',
            showInTable: true,
            filtering: false,
            sorting: false,
          },
          {
            field: 'aebe',
            title: 'AEBE',
            type: 'string',
            headerStyle: {
              textAlign: 'center',
            },
            showInTable: true,
            filtering: false,
            sorting: false,
          },
        ],
      },
      options: {
        paging: false,
        cellEditable: true,
        filtering: false,
        sorting: false,
      },
      filtering: false,
      sorting: false,
      showInTable: true,
      hideIfNoData: true,
      render: (row = {}) => {
        if (
          row?.rackingAebe &&
          Array.isArray(row.rackingAebe) &&
          row.rackingAebe.length
        ) {
          return (
            <div>
              {row.rackingAebe.map(({ productFamily, aebe }) => (
                <p
                  key={productFamily}
                >{`${rackingFamily[productFamily]}: ${aebe}`}</p>
              ))}
            </div>
          );
        }

        if (row.moduleDimensions?.aebe) {
          return <p>{`${row.moduleDimensions.aebe}`}</p>;
        }
        return <span>---</span>;
      },
    },
    {
      field: 'smDbModuleId',
      path: 'smDbModuleId',
      title: 'SMDB Module Id',
      showInTable: !isCustom,
      hideInDetailView: isCustom,
      validations: {
        required: false,
      },
    },
  ];
  return {
    headers: fields.filter(({ showInTable }) => showInTable),
    detailViewCols: fields,
    fieldsMap: fields.reduce(
      (acc, cur) => [
        ...acc,
        {
          dbGridName: cur.title,
          uiGridName: cur.path || cur.field,
          uiGridNameLowCase: (cur.path || cur.field).toLowerCase(),
          type: cur.type || 'string',
        },
      ],
      []
    ),
  };
};

export const showSyncResult = (data, count = 0, skip = 0) => {
  const message = `Sync Records(count=${count};skip=${skip}):
    Updated: ${data.UpdatedCount}
    Inserted: ${data.InsertedCount}
    Deleted: ${data.DeletedCount}`;

  Toaster.success(message);
};
