const PRICING_TIER = {
  1: 'PricingTier1',
  2: 'PricingTier2',
  3: 'PricingTier3',
  4: 'PricingTier4',
  5: 'PricingTier5',
  6: 'PricingTier6',
};

const WIRING = 'Wiring';
const ACCESSORIES = 'accessories';
const ACCESSORIES_UpC = 'Accessories';
const ACCESSORY = 'accessory';
const WIRES = 'wiring';
const WIRING_KITS = 'wiringKits';
const WIRING_KIT = 'wiringKit';
const ADDITIONAL = 'Additional';
const SUCCESS = 'success';
const WARNING = 'warning';
const ERROR = 'error';
const EMPTY = 'Empty';
const UNSELECTED = 'Unselected';
const EMPTY_MODULES_WARNING = 'Modules must be added first';
const COMPATIBLE = 'Compatible';
const INCOMPATIBLE = 'Incompatible';
const SELECT_REVISION = 'Select revision to refresh BOM';
const ERROR_GETTING_PARTS = 'Error getting module parts';
const REFRESH_BOM = 'Refresh BOM';
const GET_LATEST_PRICING = 'Get latest pricing';
const REFRESH = 'Refresh';
const MODEL = 'model';
const QUANTITY = 'quantity';
const PART_NUMBER = 'partNumber';
const REVISION_NUMBER = 'revisionNumber';
const BASE = 'Base';
const BASE_PAD = 'BASE PAD';

const ACCESSORIES_COLOR = '#e7fffd';
const ADDITIONAL_COLOR = '#ffffb4';

const WIRE_ACCESSORIES_KIT_NAMES = {
  ['500022501']: { name: 'wireRouter', type: WIRES },
  ['500022502']: { name: 'wireRouter', type: WIRES },
  ['5000226']: { name: 'wireClip', type: WIRES },
  ['500062302']: { name: 'homeRunCover', type: WIRES },
  ['5000767']: { name: 'homeRunCover', type: WIRES },
  ['5000628']: { name: 'homeRunClip', type: WIRES },
  ['5000766']: { name: 'homeRunClip', type: WIRES },
  ['5000510']: { name: 'spacerStick', type: ACCESSORIES },
  ['5000610']: { name: 'spacerStick', type: ACCESSORIES },
  ['5000228']: { name: 'shimPads', type: ACCESSORIES },
  ['5000494']: { name: 'groundingLugs', type: ACCESSORIES },
  ['5000229']: { name: 'basePads', type: ACCESSORIES },
  ['5000519']: { name: 'moduleLevelPowerElectronics', type: ACCESSORIES },
  ['5000619']: { name: 'moduleLevelPowerElectronics', type: ACCESSORIES },
};

const WIRES_PROPERTIES = {
  wireRouter: 'Wire Router',
  wireClip: 'Wire Clip',
  homeRunCover: 'Home Run Cover',
  homeRunClip: 'Home Run Clip',
};

const ACCESSORY_PROPERTIES = {
  spacerStick: 'Spacer Stick',
  shimPads: 'Shim Pads',
  groundingLugs: 'Grounding Lugs',
  basePads: 'Base Pads',
  moduleLevelPowerElectronics: 'Module Level Power Electronics',
};

export {
  WIRES,
  WIRES_PROPERTIES,
  ACCESSORY_PROPERTIES,
  WIRE_ACCESSORIES_KIT_NAMES,
  BASE,
  BASE_PAD,
  REVISION_NUMBER,
  ACCESSORIES_COLOR,
  ADDITIONAL_COLOR,
  MODEL,
  QUANTITY,
  PART_NUMBER,
  REFRESH,
  REFRESH_BOM,
  GET_LATEST_PRICING,
  PRICING_TIER,
  WIRING,
  ACCESSORIES,
  ACCESSORY,
  ADDITIONAL,
  SUCCESS,
  WARNING,
  ERROR,
  EMPTY,
  UNSELECTED,
  EMPTY_MODULES_WARNING,
  COMPATIBLE,
  INCOMPATIBLE,
  SELECT_REVISION,
  ERROR_GETTING_PARTS,
  WIRING_KITS,
  WIRING_KIT,
  ACCESSORIES_UpC,
};
