import React from 'react';
import PropTypes from 'prop-types';

function HelperText({ text, color, onClick }) {
  return (
    <span
      role="button"
      tabIndex={0}
      onClick={onClick}
      style={{
        color: color || '#5e5ee2',
        fontSize: '11px',
        marginLeft: '5px',
        cursor: 'pointer',
      }}
    >
      {text}
    </span>
  );
}

HelperText.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

HelperText.defaultProps = {
  color: '#5e5ee2',
};

export default HelperText;
