import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { TableCheckbox, TableSortLabel } from './MaterialTableElements';

import MatTableToolbar from './MatTableToolbar';

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

function MatTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  handleDeleteSelected,
  selected,
  rows,
  disabled,
  disabledToolbar,
  toolbar,
  onEventToolbar,
}) {
  const rtl = useSelector((state) => state.rtl);
  const Toolbar = toolbar;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <TableCheckbox
            disabled={disabled || rowCount === 0}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount && rowCount !== 0}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {rows.map((row) => (
          <TableCell
            key={row.id}
            style={{ display: 'flex', justifyContent: 'space-between' }}
            align={rtl.direction === 'rtl' ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id, onRequestSort)}
                className="material-table__sort-label"
                dir="ltr"
              >
                {row.label}
              </TableSortLabel>
              <div style={{ display: 'inline-flex' }}>
                <MatTableToolbar
                  numSelected={[...selected].filter((el) => el[1]).length}
                  handleDeleteSelected={handleDeleteSelected}
                  onRequestSort={onRequestSort}
                  showFilter={false}
                />
              </div>
            </div>
            <div style={{ display: 'inline-flex', padding: '7px 0' }}>
              {Toolbar && (
                <Toolbar onEvent={onEventToolbar} disabled={disabledToolbar} />
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

MatTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  disabledToolbar: PropTypes.bool,
  toolbar: PropTypes.any,
  onEventToolbar: PropTypes.func,
};

MatTableHead.defaultProps = {
  toolbar: null,
  disabledToolbar: false,
  onEventToolbar: () => {},
};

export default MatTableHead;
