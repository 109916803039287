import { connect } from 'react-redux';

import { getAcl } from 'redux/selectors/selectors';
import ShowIfHasPermission from './ShowIfHasPermission';

const mapStateToProps = (state) => ({
  acl: getAcl(state),
});

export default connect(mapStateToProps)(ShowIfHasPermission);
