import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Select, MenuItem } from '@material-ui/core';
import { isObject } from 'utils';

function SelectFilter({
  name,
  onFilter,
  propsValue,
  tableName,
  lookup,
  lookupHandler,
  fieldsValues,
}) {
  const [items, setItems] = useState([]);

  const onChange = useCallback(
    ({ target: { value } }) => {
      return onFilter({ name, value, tableName });
    },
    [name, onFilter]
  );

  const createMenuItems = (lookup) => {
    const items = [
      <MenuItem value="N/A" key="N/A">
        N/A
      </MenuItem>,
    ];

    if (Array.isArray(lookup)) {
      if (Array.isArray(lookup[0])) {
        lookup.forEach((x) => {
          items.push(
            <MenuItem value={x[1]} key={x[0]}>
              {x[1]}
            </MenuItem>
          );
        });
      } else if (
        Object.prototype.toString.call(lookup[0]) === '[object Object]'
      ) {
        lookup.forEach((x) => {
          items.push(
            <MenuItem value={x.id} key={x.id}>
              {x.name}
            </MenuItem>
          );
        });
      }
    } else if (isObject(lookup)) {
      Object.entries(lookup).forEach((x) => {
        items.push(
          <MenuItem value={x[1]} key={x[0]}>
            {x[1]}
          </MenuItem>
        );
      });
    }

    setItems(items);
  };

  useEffect(() => {
    if (typeof lookup === 'function') {
      (async () => {
        const data = await lookup(fieldsValues);
        createMenuItems(data?.data || data);
      })();
    } else {
      createMenuItems(lookup);
    }
  }, [lookup, fieldsValues]);

  useEffect(() => {
    let isMounted = true;

    const getLookup = async () => {
      if (lookupHandler && isMounted) {
        const data = await lookupHandler();
        createMenuItems(data?.data || data);
      }
    };

    getLookup().catch(console.error);

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Select
      value={propsValue === null ? 'N/A' : propsValue}
      onChange={onChange}
    >
      {items}
    </Select>
  );
}

const mapStateToProps = ({ dataView }, { name, tableName, defaultValue }) => {
  let val = null;

  if (
    Array.isArray(dataView?.[tableName]?.filters) &&
    dataView[tableName].filters.length === 0
  ) {
    val = defaultValue;
  } else {
    val = dataView?.[tableName]?.filters?.[name]
      ? dataView[tableName].filters[name]
      : null;
  }

  return {
    propsValue: val,
    fieldsValues: dataView[tableName]?.filters || [],
  };
};

export default connect(mapStateToProps)(SelectFilter);

SelectFilter.propTypes = {
  name: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line
  propsValue: PropTypes.string,
  tableName: PropTypes.string.isRequired,
  lookup: PropTypes.object,
  lookupHandler: PropTypes.func,
  defaultValue: PropTypes.string,
};

SelectFilter.defaultProps = {
  defaultValue: '',
};
