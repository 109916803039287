import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItemButton from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from 'components/CircularProgress';
import Search from 'components/MaterialTable/Search';
// import Divider from '@material-ui/core/Divider';
// import InboxIcon from '@material-ui/icons/Inbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
import './assets/style.scss';

function ListTables({ id, item, items, loading, disabled, onEvent, search }) {
  const onSelect = useCallback(
    (name, value, metadata) => {
      onEvent({
        action: 'selected',
        data: {
          name,
          value,
          metadata,
        },
      });
    },
    [onEvent]
  );

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 360,
        minHeight: 335,
        maxHeight: 300,
        bgcolor: 'background.paper',
      }}
      className={loading ? 'list-tables-list  loading' : 'list-tables-list'}
    >
      {loading ? (
        <div className="list-tables-progress">
          <CircularProgress content="" />
        </div>
      ) : (
        <>
          <Search tableName={id} value={search} />
          <Box
            sx={{
              width: '100%',
              maxWidth: 360,
              minHeight: 300,
              maxHeight: 300,
              overflow: 'auto',
            }}
          >
            <List component="nav">
              {items.map((it) => {
                return (
                  <ListItemButton
                    key={it.name}
                    button
                    selected={item === it.name}
                    onClick={() => onSelect('tableName', it.name, it.metadata)}
                    disabled={disabled}
                  >
                    <ListItemText primary={it.label} />
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
        </>
      )}
    </Box>
  );
}

ListTables.propTypes = {
  id: PropTypes.string,
  item: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      metadata: PropTypes.object,
    })
  ),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onEvent: PropTypes.func,
  search: PropTypes.string,
};

ListTables.defaultProps = {
  id: null,
  item: null,
  items: [],
  disabled: false,
  loading: false,
  onEvent: () => {},
  search: '',
};

export default connect()(ListTables);
