import { uniqBy } from 'utils';

export function toLookupsCustomers(data, initial = []) {
  if (!data) return initial;

  const items = data.map(({ id, name, type }) => {
    return {
      id,
      name,
      label: `${name}${type ? `, ${type}` : ''}`,
      value: `${id}_${name}`,
    };
  });

  return uniqBy([...initial, ...items], 'value');
}

export function createCustomer(values) {
  return {
    id: values.customerId ? values.customerId : null,
    name: values.customerName,
  };
}
