import React from 'react';
import './globalSpinner.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalSpinnerContext } from './GlobalSpinnerContext';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: '999999',
    color: '#004D7A',
  },
}));

function GlobalSpinner() {
  const classes = useStyles();
  const isGlobalSpinnerOn = useGlobalSpinnerContext();
  return isGlobalSpinnerOn ? (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
}

export default GlobalSpinner;
