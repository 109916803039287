import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import { Row } from 'reactstrap';
import Toolbar from 'components/Toolbar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Toaster from 'components/ToasterQueue';

const minDBDate = new Date('2023-01-01 0:00:00'); // Temporary limitation

function SelectDateModal({
  dateFrom,
  dateTo,
  handleDateChange,
  setOpenDatePicker,
}) {
  const [stateDateFrom, setDateFrom] = useState(dateFrom || '');
  const [stateDateTo, setDateTo] = useState(dateTo || '');

  const validate = (value, setDate) => {
    if (value && value < minDBDate) {
      setDate(minDBDate);
      Toaster.error('Analytics is currently limited to data since 1/1/2023');
    }
  };

  return (
    <Box className="date-picker-box">
      <IconButton
        aria-label="close"
        onClick={() => setOpenDatePicker(false)}
        className="closeIcon"
      >
        <CloseIcon />
      </IconButton>
      <Row style={{ paddingBottom: '20px' }}>
        <h3>Select Date</h3>
      </Row>
      <div className="date-picker-row">
        <span>Date From</span>
        <div>
          <DatePicker
            dateFormat="MM/dd/yyyy"
            selected={stateDateFrom}
            placeholderText="Click to select a date"
            onChange={(value) => setDateFrom(value)}
            maxDate={
              stateDateTo && stateDateTo < new Date() ? stateDateTo : new Date()
            }
            onCalendarClose={() => validate(stateDateFrom, setDateFrom)}
          />
        </div>
      </div>
      <div className="date-picker-row">
        <span>Date To</span>
        <div>
          <DatePicker
            dateFormat="MM/dd/yyyy"
            selected={stateDateTo}
            placeholderText="Click to select a date"
            onChange={(value) => setDateTo(value)}
            minDate={stateDateFrom}
            maxDate={new Date()}
            onCalendarClose={() => validate(stateDateTo, setDateTo)}
          />
        </div>
      </div>
      <Toolbar
        className="date-picker-box-footer"
        disabledCancel={false}
        cancelLabel="Clear"
        onCancel={() => handleDateChange('', '')}
        disabledSave={false}
        saveLabel="Proceed"
        onSave={() => handleDateChange(stateDateFrom, stateDateTo)}
      />
    </Box>
  );
}

SelectDateModal.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
  setOpenDatePicker: PropTypes.func.isRequired,
};

SelectDateModal.defaultProps = {
  dateFrom: '',
  dateTo: '',
};

export default SelectDateModal;
