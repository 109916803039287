import { Badge as BootstrapBadge } from 'react-bootstrap';
import styled from 'styled-components';
import { colorGray, colorWhite, getColor } from './utils/palette';

const Badge = styled(BootstrapBadge)`
  color: ${(props) => (props.bg === 'secondary' ? colorGray : colorWhite)};
  background-color: ${(props) => getColor(props.bg)} !important;
  font-weight: 500;
`;

export default Badge;
