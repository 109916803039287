import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import Modal from 'components/common/Modal';
import ACL from 'redux/Acl';

import { flagSelector } from 'redux/flags/selectors';
import { errorSelector } from 'redux/errors/selectors';

import Validator from 'utils/validator';
import Toaster from 'components/ToasterQueue';
import { getAcl } from 'redux/selectors/selectors';
import authActions from 'redux/actions/authActions';
import { userDefaults } from './_etc/defaultValues';
import { userSchema } from './_etc/validationSchema';
import ProfileForm from './ProfileForm';

import './ProfileForm.scss';

class ProfileDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updateEvtTriggered: null,
      error: null,
      success: null,
    };
  }

  updateUser = (user) => {
    this.setState({
      updateEvtTriggered: false,
      error: null,
      success: null,
    });

    this.props.updateUser(user);

    this.setState({ updateEvtTriggered: true });

    this.runUserUpdateWatcher();
  };

  runUserUpdateWatcher = () => {
    const watcher = setInterval(() => {
      const { isUserUpdating, error } = this.props;
      const { updateEvtTriggered } = this.state;

      const done =
        !isUserUpdating && isUserUpdating !== null && updateEvtTriggered;
      if (done) {
        clearInterval(watcher);
        if (error) {
          this.setState({ error });
          Toaster.error('User Update failed!');
        } else {
          this.setState({ success: true });
          Toaster.success('User Update Successfull!');
        }
        this.setState({ updateEvtTriggered: null });
      }
    }, 500);
  };

  closeModal = () => {
    this.setState({ error: null, success: null, updateEvtTriggered: null });
    this.props.onClose();
  };

  render() {
    const {
      isOpen,
      user,
      isUserUpdating,
      loginHelioscope,
      logoutHelioscope,
      helioscope,
      acl,
    } = this.props;

    return (
      <Modal isOpen={isOpen} theme="pc-feedback-modal">
        <div className="profile-wrapper">
          <div className="modal-header text-center">
            <h3 className="modal-title">User Profile</h3>
            <div className="profile-wrapper__title">
              <button
                onClick={this.closeModal}
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div className="modal-body">
            <div className="row justify-content-center">
              <div className="profile-wrapper__avatar">
                <span className="user-icon text-center">
                  <AccountCircleIcon className="lock-icon" />
                </span>
              </div>

              <div className="row-div-padding">
                <ProfileForm
                  values={user}
                  onSubmit={(values) => {
                    this.updateUser(values);
                  }}
                  validate={(values) => new Validator(userSchema).run(values)}
                  updating={isUserUpdating}
                  loginHelioscope={loginHelioscope}
                  logoutHelioscope={logoutHelioscope}
                  helioscope={helioscope}
                  acl={acl}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ProfileDetails.propTypes = {
  //  eslint-disable-next-line
  user: PropTypes.object,
  //  eslint-disable-next-line
  helioscope: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  loginHelioscope: PropTypes.func.isRequired,
  error: PropTypes.string,
  isUserUpdating: PropTypes.bool,
  acl: PropTypes.object, // eslint-disable-line
};

ProfileDetails.defaultProps = {
  user: userDefaults,
  error: null,
  isOpen: false,
  isUserUpdating: false,
  acl: new ACL(),
};

const mapStateToProps = (state) => {
  const { user, helioscope } = state.auth;
  const isUserUpdating = flagSelector(state, 'user.updating', false);
  const error = errorSelector(state, 'user.updating');
  const acl = getAcl(state);

  return {
    user: {
      ...userDefaults,
      ...user,
    },
    helioscope,
    isUserUpdating,
    error,
    acl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(authActions.updateUser(user)),
  loginHelioscope: (data) =>
    dispatch(authActions.loginHelioscope({ ...data, cosUserId: data._id })),
  logoutHelioscope: () => dispatch(authActions.logoutHelioscope()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
