/* eslint valid-typeof: 0, class-methods-use-this: 0 */

// import { NUMBER } from 'modules/editor/services/constants';
export const NUMBER = 'number';
const mathRoundWithPrecision = (n, p) =>
  typeof n === NUMBER ? Number(n.toFixed(p)) : null;

const convertLims = (system, attr) => {
  const {
    inBetween: {
      min = null,
      max = null,
      mMin = null,
      mMax = null,
      iMin = null,
      iMax = null,
      munit,
      iunit,
    },
  } = attr;
  const tArr = [
    { min },
    { max },
    { mMin },
    { mMax },
    { iMin },
    { iMax },
  ].reduce((acc, cur) => {
    if (typeof Object.values(cur)[0] === NUMBER) {
      acc[Object.getOwnPropertyNames(cur)] = mathRoundWithPrecision(
        Object.values(cur)[0],
        attr.precision
      );
    } else {
      acc[Object.getOwnPropertyNames(cur)] = null;
    }
    return acc;
  }, {});

  switch (system) {
    case 'imperial':
      return typeof tArr.iMin === NUMBER && typeof tArr.iMax === NUMBER
        ? {
            min: tArr.iMin,
            originalMin: tArr.min,
            max: tArr.iMax,
            originalMax: tArr.max,
          }
        : {
            min: tArr.min,
            originalMin: tArr.min,
            max: tArr.max,
            originalMax: tArr.max,
          };
    case 'metric':
      return typeof tArr.mMin === NUMBER && typeof tArr.mMax === NUMBER
        ? {
            min: tArr.mMin,
            originalMin: tArr.min,
            max: tArr.mMax,
            originalMax: tArr.max,
          }
        : {
            min: tArr.min,
            originalMin: tArr.min,
            max: tArr.max,
            originalMax: tArr.max,
          };
  }
};

class Validator {
  schema = null;

  system = null;

  constructor(schema, system) {
    this.schema = schema;
    this.system = system;
  }

  run(values) {
    const { schema } = this;
    const { system } = this;
    const errors = {};

    const fields = Object.keys(schema);
    fields.forEach((field) => {
      let errorMessage = null;

      const attr = schema[field];

      let value = values[field];

      if (value !== 0 && !value) {
        value = null;
      }

      if (typeof value === 'string') {
        value = value.trim();
      }

      if (attr.length) {
        const { length } = String(value);
        if (length < attr.length.min) {
          errorMessage = 'Too short!';
        }

        if (length > attr.length.max) {
          errorMessage = 'Too long!';
        }
      }

      // Custom
      if (attr.custom) {
        errorMessage = this[attr.custom](field, values);
      }

      // Type
      if (attr.type && value) {
        if (attr.type === 'number') {
          if (isNaN(Number(value))) {
            errorMessage = 'Invalid data type!';
          }
        } else if (typeof value !== attr.type) {
          errorMessage = 'Invalid data type!'; // 1582
        }
      }

      // Only
      if (attr.only && Array.isArray(attr.only)) {
        if (!attr.only.includes(value)) {
          errorMessage = 'Invalid value!';
        }
      }

      // inBetween
      if (value != null && attr.inBetween) {
        const valueInBetween = value * 1;
        if (typeof valueInBetween !== 'number' || isNaN(valueInBetween)) {
          errorMessage = 'Invalid data type!';
        } else {
          let { min, max } = attr.inBetween;
          if (system && attr.quantity) {
            ({ min, max } = convertLims(system, attr));
          }

          if (
            attr.inBetween.min &&
            !attr.inBetween.max &&
            !(valueInBetween >= attr.inBetween.min)
          ) {
            errorMessage =
              field === 'setback'
                ? `Should be at least 4 Feet!`
                : `Should be greater than ${min}!`;
          }

          if (
            attr.inBetween.max &&
            !attr.inBetween.min &&
            !(valueInBetween <= attr.inBetween.max)
          ) {
            errorMessage = `Should be less than ${max}!`;
          }

          if (
            attr.inBetween.max &&
            attr.inBetween.min &&
            (!(valueInBetween <= attr.inBetween.max) ||
              !(valueInBetween >= attr.inBetween.min))
          ) {
            errorMessage = `Should be between ${min} and ${max}!`;
          }
        }
      }
      if (attr.required) {
        if (value !== 0 && value === null) {
          errorMessage =
            attr && attr.name ? `${attr.name} is required!` : 'is required!';
        }
      }

      if (errorMessage) {
        // Get rid of `@` sign if preventConversion == true
        if (attr.preventConversion) {
          const aToccurrences = errorMessage.match(/@/g) || [];
          aToccurrences.forEach(() => {
            errorMessage = errorMessage.replace('@', '');
          });
        }

        errors[field] = errorMessage;
      }
    });
    return errors;
  }

  validateLatitude(field, { latitude }) {
    if (!(isFinite(latitude) && Math.abs(latitude) <= 90))
      return 'Invalid latitude!';

    return false;
  }

  validateLongitude(field, { longitude }) {
    if (!(isFinite(longitude) && Math.abs(longitude) <= 180))
      return 'Invalid longitude!';

    return false;
  }

  validateEmail(field, { email }) {
    const patt = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!patt.test(email)) return 'Invalid email address';

    return false;
  }

  validateWindSpeed(field, allValues) {
    const speed = allValues[field] * 1;
    if (typeof speed !== 'number' || isNaN(speed)) {
      return 'Invalid data type!';
    }

    if (allValues.siteCode === '705' && (speed < 85 || speed > 200)) {
      return 'Should be between @85 and @200';
    }

    if (allValues.siteCode === '710' && (speed < 100 || speed > 300)) {
      return 'Should be between @100 and @300';
    }

    if (allValues.siteCode === '716' && (speed < 85 || speed > 300)) {
      return 'Should be between @85 and @300';
    }

    return false;
  }

  validateMAdistribution(field, allValues) {
    const maDistribution = allValues[field] * 1;
    if (typeof maDistribution !== 'number' || isNaN(maDistribution)) {
      return 'Invalid data type!';
    }

    if (maDistribution === 0 || maDistribution >= 1) return false;

    return 'Value should be 0 or >= 1';
  }

  validatePassword(field, { password }) {
    // NOTE: Password Policy: 12 characters min;lowercase+uppercase+number+symbols
    const lowercasePatt = new RegExp(/(?=.*[a-z])/);
    if (!lowercasePatt.test(password))
      return 'Include atleast one lowercase letter!';

    const uppercasePatt = new RegExp(/(?=.*[A-Z])/);
    if (!uppercasePatt.test(password))
      return 'Include atleast one uppercase letter!';

    const digitPatt = new RegExp(/(?=.*\d)/i);
    if (!digitPatt.test(password)) return 'Include at least one digit!';

    const symbolPatt = new RegExp(/(?=.*[$@$!%*?&])/i);
    if (!symbolPatt.test(password)) return 'Include at least one symbol!';

    const lengthPatt = new RegExp(/(?=^.{12,}$)/);
    if (!lengthPatt.test(password))
      return 'Character length is not enough! (min=12)';

    return false;
  }

  validateConfirmPassword(field, { password, confirmPassword }) {
    if (password !== confirmPassword) return 'Passwords do not match';

    return false;
  }
}

export default Validator;
