import { hasOwnProperty, isNumeric, isObject, isString, roundup } from 'utils';
import componentsParts from './configs/componentsParts';

import { toast } from 'react-toastify';

import {
  ADMIN_UI,
  BASE,
  BLT,
  BOLT,
  CAM,
  CAM_CLAW,
  CAM_SEAT_SPACER,
  DEFLECTOR,
  ESTIMATE,
  FRAME_ENHANCER,
  HWKITMASTRUT,
  LOCK_CLAW_CLIP,
  MA,
  MASTRUT,
  MODULE_CONNECTOR,
  RAIL,
  UNSELECTED,
} from './constants';

import Quantity from './components/LinesTable/Quantity';
import {
  PRICING_TIER,
} from './components/LinesTable/constants';

function getFormatLinesAndUpdateAdditionalComponents(
  { linesBom, id: moduleId, model },
  onDeleteLine,
  additionalComponents,
  isProjectQuote = false
) {
  if (!Array.isArray(additionalComponents)) additionalComponents = [];

  const partNumbers = [];

  linesBom.forEach((line) => {
    if (!line.partDescription) return;
    let addPartNumber = true;

    additionalComponents.forEach((ac) => {
      if (!ac.selected && line.partNum.includes(ac.partNumberTopLevel)) {
        ac.selected = true;
        addPartNumber = false;
      }
    });

    if (addPartNumber) {
      const quantity = line.partQty || 0;

      partNumbers.push({
        item: line.item || null,
        quantity,
        previousQuantity: quantity,
        partNumber: line.partNum,
        moduleId,
        model,
        disableSelectModule: isProjectQuote,
        description: line.partDescription,
        unitPrice: line.partPriceUnit || 0,
        total: line.partPriceTotal || 0,
        status: { status: 'warning', value: 'Incompatible' },
        link: './',
        // onDeleteLine: (row) => onDeleteLine(row),
      });
    }
  });

  return partNumbers;
}

function getFormattedLineAndUpdateAdditionalComponents(
  { line, id: moduleId, model, partNumbers, tierId, type },
  onDeleteLine,
  additionalComponents,
  status
) {
  if (!Array.isArray(additionalComponents)) additionalComponents = [];

  let partNumber = line.partNumber || line.partNum || '';
  const partDescription = (
    line.partNumberDescription ||
    line.partDescription ||
    ''
  ).trim();
  let description = partDescription.startsWith(line.partName.trim())
    ? partDescription
    : `${line.partName}, ${partDescription}`;

  let unitPrice = line.partPriceUnit || 0;
  const quantity = line.partQty || 0;

  if (partNumbers.items?.[partNumber]) {
    const part = partNumbers.items[partNumber];

    if (!unitPrice && typeof part?.[PRICING_TIER[tierId]] !== 'undefined') {
      unitPrice = part[PRICING_TIER[tierId]];
    }

    if (typeof part?.PartNumber !== 'undefined') {
      partNumber = part.PartNumber;
    }

    if (typeof part?.PartDescription !== 'undefined') {
      description = part.PartDescription;
    }
  } else {
    toast.warn('This part number is not available. Please enter a replacement part manually');
  }

  let i = -1;
  const n = additionalComponents.length;

  while (++i < n) {
    const ac = additionalComponents[i];

    if (!ac.selected && partNumber.includes(ac.partNumberTopLevel)) {
      ac.selected = true;
      return;
    }
  }

  return {
    id: line.id,
    item: line.item || null,
    quantity,
    partNumber,
    moduleId,
    model,
    description,
    label: `${description} - ${partNumber}`,
    unitPrice,
    total: line.partPriceTotal || calculateTotalPrice(unitPrice, quantity),
    status: isObject(status)
      ? status
      : { status: 'warning', value: 'Incompatible' },
    link: './',
    onDeleteLine: (row) => onDeleteLine(row),
    type,
    partName: line.partName,
    partDisplayName: line.partDisplayName,
  };
}

function calculateSystemPower(numberModules, modulePower) {
  return numberModules && modulePower
    ? Number((numberModules * modulePower) / 1000).toFixed(1)
    : 0;
}

function validateInputs(values, config, action = null) {
  const newErrors = {};

  Object.keys(config.fields).forEach((key) => {
    const field = config.fields[key];

    if (field.required) {
      if (field.validateOnAction && field.validateOnAction !== action) return;

      let model;

      if (field.modelName) {
        model = values[field.modelName];
      } else {
        model = values;
      }

      if (model[field.ignoreRuleIfExistField]) return;

      switch (field.type) {
        case 'string': {
          if (!model[field.name]) {
            newErrors[field.name] =
              field.ui.error.empty || field.ui.error.message;
          }
          break;
        }
        case 'number': {
          if (!isNumeric(model[field.name])) {
            newErrors[field.name] =
              field.ui.error.empty || field.ui.error.message;
          }
          break;
        }
        case 'numeric': {
          if (!Number.isInteger(+model[field.name])) {
            newErrors[field.name] =
              field.ui.error.empty || field.ui.error.message;
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  return newErrors;
}

function updateLinesPerModule(lines, modules) {
  const newLines = [];

  lines.forEach((line) => {
    if (modules.some((module) => module.id === line.moduleId)) {
      newLines.push(line);
    }
  });

  return newLines;
}
// TODO: NEED OPTIMIZATION & SWITCH FROM description to NAME | ID & Add Configs & Convert numeric
function updateLines(lines, fields, docType, options) {
  const formattedLines = [];

  const tilt = getTilt(fields?.module?.labels?.mountingSystem);

  const hasEstimate = docType === ESTIMATE;

  const useRound = true;

  let i = -1;
  const n = lines.length;

  while (++i < n) {
    const formattedLine = { ...lines[i] };
    let componentPartName;

    if (isString(formattedLine.description)) {
      const description = getLineDescriptionPattern(formattedLine);

      const numSouthModules = Quantity.getNumSouthModules({
        numSouthModules: fields.supports.numberSouthModules.value,
        numModules: fields.numberModules,
      });

      const isMaRelatedComponent = isHWKITMAStrut(description) || isMAStrut(description) || isMA(description);

      switch (true) {
        case isHWKITMAStrut(description): {
          if (hasEstimate) {
            formattedLine.quantity = Quantity.calculateForHWKITMAStrut({
              maType: fields.mechanicalAttachment, // value from MA dropdown
              qtyForMAStrut: Quantity.calculateForMAStrut({
                maType: fields.mechanicalAttachment, // maType - value from MA dropdown
                maBracketQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
                maQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
              }),
            });
          }

          componentPartName = HWKITMASTRUT;
          break;
        }
        case isMAStrut(description): {
          if (hasEstimate) {
            formattedLine.quantity = Quantity.calculateForMAStrut({
              maType: fields.mechanicalAttachment, // maType - value from MA dropdown
              maBracketQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
              maQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
            });
          }

          componentPartName = MASTRUT;
          break;
        }
        case isMA(description): {
          if (hasEstimate) {
            formattedLine.quantity = Quantity.calculateForMA({
              maType: fields.mechanicalAttachment, // value from MA dropdown
              maQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
            });
          }

          componentPartName = MA;
          break;
        }
        case isBolt(description): {
          if (hasEstimate) {
            formattedLine.quantity = Quantity.calculateForBolt({
              tilt,
              cFRBoltCount: Quantity.getcFRBoltCount({
                numberBolts: fields.supports.numberBolts.value, // Number of Bolts
                cFRBoltQty: Quantity.getcFRBoltQty({
                  numModules: fields.numberModules,
                  tilt,
                  cFRBallastRailQtyForBallast: Quantity.getcFRBallastRailQtyForBallast(
                    {
                      numModules: fields.numberModules,
                      numSouthModules,
                      tilt,
                      cFRBallastRailQty: Quantity.getcFRBallastRailQty({
                        numSouthModules,
                        numModules: fields.numberModules,
                        tilt,
                        shortTraysORBallastRails:
                          fields.supports.numberRails.value, // Number of Rails
                        trayORBallastRailToModuleRatio:
                          fields.supports.railModuleRatio.value,
                      }),
                    }
                  ),
                  numSouthModules,
                }),
              }),
            });

            if (
              options?.bolts?.quantity >= 0 &&
              options.bolts.operation === 'increase'
            ) {
              formattedLine.quantity += options.bolts.quantity;
            }
          }

          componentPartName = BOLT;
          break;
        }
        case isRail(description): {
          if (hasEstimate) {
            formattedLine.quantity = Quantity.calculateForRail({
              cFRBallastRailCount: Quantity.getcFRBallastRailCount({
                shortTraysORBallastRails: fields.supports.numberRails.value, // Number of Rails
                cFRBallastRailQty: Quantity.getcFRBallastRailQty({
                  numSouthModules,
                  numModules: fields.numberModules,
                  tilt,
                  shortTraysORBallastRails: fields.supports.numberRails.value, // Number of Rails
                  trayORBallastRailToModuleRatio:
                    fields.supports.railModuleRatio.value, // Rail to Module Ratio
                }),
              }),
            });
          }

          componentPartName = RAIL;
          break;
        }
        case isModuleConnector(description): {
          if (hasEstimate) {
            formattedLine.quantity = Quantity.calculateForModuleConnector({
              connectorFormula: Quantity.getConnectorFormula({
                tilt,
                numSouthModules,
                docType,
                numModules: fields.numberModules,
              }),
            });
          }

          componentPartName = MODULE_CONNECTOR;
          break;
        }
        case isCamClaw(description): {
          if (hasEstimate) {
            formattedLine.quantity = Quantity.calculateForCamClaw({
              camClawCountBuffered: Quantity.getCamClawCountBuffered({
                camClawCount: Quantity.getCamClawCount({
                  docType,
                  camClaws: fields.supports.numberCams.value, // Number of Cams | Cam Claws (2nd field!!!)
                  numModules: fields.numberModules,
                }),
              }),
              camClawCount: Quantity.getCamClawCount({
                docType,
                camClaws: fields.supports.numberCams.value, // Number of Cams | Cam Claws (2nd field!!!)
                numModules: fields.numberModules,
              }),
            });
          }

          componentPartName = CAM_CLAW;
          break;
        }
        case isCam(description): {
          if (hasEstimate) {
            formattedLine.quantity = Quantity.calculateForCam({
              clawCount: Quantity.getClawCount({
                docType,
                claws: fields.supports.numberCams.value, // Number of Cams | Cam Claws
                numModules: fields.numberModules,
              }),
            });
          }

          componentPartName = CAM;
          break;
        }
        case isDeflector(description): {
          if (hasEstimate) {
            if (options?.deflectors?.quantity >= 0) {
              formattedLine.quantity = options.deflectors.quantity;
            } else if (options?.deflectors?.excludeDeflectors) {
              break;
            } else {
              formattedLine.quantity = Quantity.calculateForDeflector({
                deflectorFormula: Quantity.getDeflectorFormula({
                  deflectors: fields.supports.numberDeflectors.value, // Number of Deflectors
                  tilt,
                  numModules: fields.numberModules,
                  trayORBallastRailToModuleRatio:
                    fields.supports.railModuleRatio.value, // Rail to Module Ratio
                }),
              });
            }
          }

          componentPartName = DEFLECTOR;
          break;
        }
        case isBase(description): {
          if (hasEstimate) {
            formattedLine.quantity = Quantity.calculateForBase({
              numMsupportsFormula: Quantity.getNumMsupportsFormula({
                stdSupports: fields.supports.numberBases.value, // Number of Bases
                numSouthModules: fields.supports.numberSouthModules.value,
                tilt,
                numModules: fields.numberModules,
                support2ModuleRatio: fields.supports.railModuleRatio.value, // fields.supports.baseModuleRatio.value, // Base to Module Ratio // UI has not baseModuleRatio
                numSupports: fields.supports.numberSouthModules.value, // number of south modules -> numSouthModules
                numSupportsPb3: Quantity.getNumSupportsPB3({
                  docType,
                  numSouthModules: fields.supports.numberSouthModules.value,
                  support2ModuleRatio: fields.supports.railModuleRatio.value,
                  numModules: fields.numberModules,
                }),
              }),
            });
          }

          componentPartName = BASE;
          break;
        }
        default: {
          componentPartName = 'default';
        }
      }

      if (!hasOwnProperty(formattedLine, 'previousQuantity')) {
        formattedLine.previousQuantity = formattedLine.quantity;
      }

      if (useRound && !isMaRelatedComponent) {
        formattedLine.quantity = roundQuantity(
          formattedLine,
          componentPartName,
          !hasEstimate
        );
      }

      formattedLine.total = calculateTotalPrice(
        formattedLine.unitPrice,
        formattedLine.quantity
      );
    }

    formattedLines.push(formattedLine);
  }

  return formattedLines;
}

function roundQuantity(
  line,
  componentPartName,
  usePreviousQuantity = false,
  isRoundUp = true
) {
  let { quantity } = line;

  if (usePreviousQuantity) {
    quantity = line.previousQuantity;
  }

  if (!isNumeric(quantity)) {
    quantity = 0;
  }

  if (!isRoundUp) return quantity;

  let componentPart = componentsParts[componentPartName];

  if (!componentPart) {
    componentPart = componentsParts.default;
  }

  const divider =
    line.kitQty ||
    componentPart.qtyInKit ||
    componentsParts.default.qtyInKit ||
    1;

  const precision =
    componentPart.precision || componentsParts.default.precision || 0;

  const roundUp = componentPart.roundUp || componentsParts.default.roundUp || 1;

  quantity = roundup(
    (quantity * componentPart.overageMultiplier) / divider,
    precision
  );

  quantity = Math.ceil(quantity / roundUp) * roundUp;

  return quantity;
}

function checkPartNumberPrices({
  lines,
  checkedPartNumberPrices,
  newPartNumberPrices,
  zeroPrices,
}) {
  if (!Array.isArray(lines)) return;

  let i = -1;
  const n = lines.length;

  while (++i < n) {
    const { partNumber, unitPrice } = lines[i];

    if (partNumber && partNumber !== UNSELECTED) {
      if (!checkedPartNumberPrices.has(partNumber)) {
        if (+unitPrice === 0) zeroPrices.push(partNumber);

        checkedPartNumberPrices.set(partNumber, true);
      }

      newPartNumberPrices.set(partNumber, true);
    }
  }
}

function getTilt(name) {
  switch (name) {
    case 'clawFR 5 Degree':
      return 5;
    case 'clawFR 10 Degree':
      return 10;
    case 'clawFR 10 Degree Dual Tilt':
      return 1010;
    default:
      return '';
  }
}

function replaceUrlToEditEstimatePage(id) {
  window.history.replaceState(null, ADMIN_UI, `/estimate/${id}/edit`);
}

function isHWKITMAStrut(id) {
  return String(id).includes(HWKITMASTRUT);
}

function isMAStrut(id) {
  return String(id).includes(MASTRUT);
}

function isMA(id) {
  return String(id).includes(MA);
}

function isBolt(id) {
  return String(id).includes(BOLT) || String(id).includes(BLT);
}

function isRail(id) {
  return String(id).includes(RAIL);
}

function isModuleConnector(id) {
  return String(id).includes(MODULE_CONNECTOR);
}

function isCamClaw(id) {
  return String(id).includes(CAM_CLAW);
}

function isCam(id) {
  return String(id).includes(CAM);
}

function isDeflector(id) {
  return String(id).includes(DEFLECTOR);
}

function isBase(id) {
  return String(id).includes(BASE);
}

function isCamSeatSpacer(id) {
  return String(id).includes(CAM_SEAT_SPACER);
}

function isFrameEnhancer(id) {
  return String(id).includes(FRAME_ENHANCER);
}

function isLockClawClip(id) {
  return String(id).includes(LOCK_CLAW_CLIP);
}

const calculateTotalPrice = (unitPrice, quantity) => {
  return quantity * unitPrice;
};

function calculateQuantity(
  line,
  fields,
  docType,
  needCheckExcludeDeflectors = false // Deprecated: Deflector-less per CPD-2779
) {
  const tilt = getTilt(fields?.module?.labels?.mountingSystem);

  if (!isString(line.description)) return line.quantity;

  const hasEstimate = docType === ESTIMATE;

  let { quantity } = line;
  let componentPartName;

  const description = getLineDescriptionPattern(line);

  const numSouthModules = Quantity.getNumSouthModules({
    numSouthModules: fields.supports.numberSouthModules.value,
    numModules: fields.numberModules,
  });

  let usePreviousQuantity = !hasEstimate;
  const useRound = true;

  switch (true) {
    case isCamSeatSpacer(line.id): {
      quantity = fields.numberModules || 0;
      componentPartName = CAM_SEAT_SPACER;
      usePreviousQuantity = false;
      break;
    }
    case isFrameEnhancer(line.id): {
      quantity = fields.numberModules || 0;
      componentPartName = FRAME_ENHANCER;
      usePreviousQuantity = false;
      break;
    }
    case isLockClawClip(line.id): {
      quantity = fields.numberModules || 0;
      componentPartName = LOCK_CLAW_CLIP;
      usePreviousQuantity = false;
      break;
    }
    case isHWKITMAStrut(description): {
      if (hasEstimate) {
        quantity = Quantity.calculateForHWKITMAStrut({
          maType: fields.mechanicalAttachment, // value from MA dropdown
          qtyForMAStrut: Quantity.calculateForMAStrut({
            maType: fields.mechanicalAttachment, // maType - value from MA dropdown
            maBracketQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
            maQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
          }),
        });
      }

      componentPartName = HWKITMASTRUT;
      break;
    }
    case isMAStrut(description): {
      if (hasEstimate) {
        quantity = Quantity.calculateForMAStrut({
          maType: fields.mechanicalAttachment, // maType - value from MA dropdown
          maBracketQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
          maQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
        });
      }

      componentPartName = MASTRUT;
      break;
    }
    case isMA(description): {
      if (hasEstimate) {
        quantity = Quantity.calculateForMA({
          maType: fields.mechanicalAttachment, // value from MA dropdown
          maQTY: fields.supports.numberMAs.value, // Number of MA's | Brackets
        });
      }

      componentPartName = MA;
      break;
    }
    case isBolt(description): {
      if (hasEstimate) {
        quantity = Quantity.calculateForBolt({
          tilt,
          cFRBoltCount: Quantity.getcFRBoltCount({
            numberBolts: fields.supports.numberBolts.value, // Number of Bolts
            cFRBoltQty: Quantity.getcFRBoltQty({
              numModules: fields.numberModules,
              tilt,
              cFRBallastRailQtyForBallast: Quantity.getcFRBallastRailQtyForBallast(
                {
                  numModules: fields.numberModules,
                  numSouthModules,
                  tilt,
                  cFRBallastRailQty: Quantity.getcFRBallastRailQty({
                    numSouthModules,
                    numModules: fields.numberModules,
                    tilt,
                    shortTraysORBallastRails: fields.supports.numberRails.value, // Number of Rails
                    trayORBallastRailToModuleRatio:
                      fields.supports.railModuleRatio.value,
                  }),
                }
              ),
              numSouthModules,
            }),
          }),
        });
        // Deprecated: Deflector-less per CPD-2779
        if (needCheckExcludeDeflectors && fields.excludeDeflectors) {
          quantity -=
            2 *
            Quantity.calculateForDeflector({
              deflectorFormula: Quantity.getDeflectorFormula({
                deflectors: fields.supports.numberDeflectors.value, // Number of Deflectors
                tilt,
                numModules: fields.numberModules,
                trayORBallastRailToModuleRatio:
                  fields.supports.railModuleRatio.value, // Rail to Module Ratio
              }),
            });
        }
      }

      componentPartName = BOLT;
      break;
    }
    case isRail(description): {
      if (hasEstimate) {
        quantity = Quantity.calculateForRail({
          cFRBallastRailCount: Quantity.getcFRBallastRailCount({
            shortTraysORBallastRails: fields.supports.numberRails.value, // Number of Rails
            cFRBallastRailQty: Quantity.getcFRBallastRailQty({
              numSouthModules,
              numModules: fields.numberModules,
              tilt,
              shortTraysORBallastRails: fields.supports.numberRails.value, // Number of Rails
              trayORBallastRailToModuleRatio:
                fields.supports.railModuleRatio.value, // Rail to Module Ratio
            }),
          }),
        });
      }

      componentPartName = RAIL;
      break;
    }
    case isModuleConnector(description): {
      if (hasEstimate) {
        quantity = Quantity.calculateForModuleConnector({
          connectorFormula: Quantity.getConnectorFormula({
            tilt,
            numSouthModules,
            docType,
            numModules: fields.numberModules,
          }),
        });
      }

      componentPartName = MODULE_CONNECTOR;
      break;
    }
    case isCamClaw(description): {
      if (hasEstimate) {
        quantity = Quantity.calculateForCamClaw({
          camClawCountBuffered: Quantity.getCamClawCountBuffered({
            camClawCount: Quantity.getCamClawCount({
              docType,
              camClaws: fields.supports.numberCams.value, // Number of Cams | Cam Claws (2nd field!!!)
              numModules: fields.numberModules,
            }),
          }),
          camClawCount: Quantity.getCamClawCount({
            docType,
            camClaws: fields.supports.numberCams.value, // Number of Cams | Cam Claws (2nd field!!!)
            numModules: fields.numberModules,
          }),
        });
      }

      componentPartName = CAM_CLAW;
      break;
    }
    case isCam(description): {
      if (hasEstimate) {
        quantity = Quantity.calculateForCam({
          clawCount: Quantity.getClawCount({
            docType,
            claws: fields.supports.numberCams.value, // Number of Cams | Cam Claws
            numModules: fields.numberModules,
          }),
        });
      }

      componentPartName = CAM;
      break;
    }
    case isDeflector(description): {
      if (hasEstimate) {
        // Deprecated: Deflector-less per CPD-2779
        if (needCheckExcludeDeflectors && fields.excludeDeflectors) {
          quantity = 0;
        } else {
          quantity = Quantity.calculateForDeflector({
            deflectorFormula: Quantity.getDeflectorFormula({
              deflectors: fields.supports.numberDeflectors.value, // Number of Deflectors
              tilt,
              numModules: fields.numberModules,
              trayORBallastRailToModuleRatio:
                fields.supports.railModuleRatio.value, // Rail to Module Ratio
            }),
          });
        }
      }

      componentPartName = DEFLECTOR;
      break;
    }
    case isBase(description): {
      if (hasEstimate) {
        quantity = Quantity.calculateForBase({
          numMsupportsFormula: Quantity.getNumMsupportsFormula({
            stdSupports: fields.supports.numberBases.value, // Number of Bases
            numSouthModules: fields.supports.numberSouthModules.value,
            tilt,
            numModules: fields.numberModules,
            support2ModuleRatio: fields.supports.railModuleRatio.value, // fields.supports.baseModuleRatio.value, // Base to Module Ratio // UI has not baseModuleRatio
            numSupports: fields.supports.numberSouthModules.value, // number of south modules -> numSouthModules
            numSupportsPb3: Quantity.getNumSupportsPB3({
              docType,
              numSouthModules: fields.supports.numberSouthModules.value,
              support2ModuleRatio: fields.supports.railModuleRatio.value,
              numModules: fields.numberModules,
            }),
          }),
        });
      }

      componentPartName = BASE;
      break;
    }
    default: {
      componentPartName = 'default';
    }
  }

  if (!hasOwnProperty(line, 'previousQuantity')) {
    line.previousQuantity = line.quantity;
  }

  return useRound
    ? roundQuantity(
        {
          kitQty: line.kitQty,
          quantity,
          previousQuantity: line.previousQuantity,
        },
        componentPartName,
        usePreviousQuantity
      )
    : quantity;
}

const convertToFixedNumber = (value, precision = 2) => {
  return Number(value).toFixed(precision);
};

function getLineDescriptionPattern(line) {
  let description = line.description.trim();

  if (line.partName) {
    description += `, ${line.partName.trim()}`;
  }

  if (line.partDisplayName) {
    description += `, ${line.partDisplayName.trim()}`;
  }

  return description;
}

const isProductFamilyPowerCap = (rackingName) =>
  rackingName?.includes('PowerCap');

const isAccessory = (productType) => /Ma|Accessory|Wiring/i.test( productType || '');

export {
  updateLinesPerModule,
  updateLines,
  replaceUrlToEditEstimatePage,
  getFormatLinesAndUpdateAdditionalComponents,
  getFormattedLineAndUpdateAdditionalComponents,
  calculateSystemPower,
  validateInputs,
  calculateTotalPrice,
  checkPartNumberPrices,
  calculateQuantity,
  convertToFixedNumber,
  isProductFamilyPowerCap,
  isAccessory,
};
