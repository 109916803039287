export const EST_SHIPPING_COST = 4.9;
export const PER_WAT_COST = 'perWatCost';
export const SALES_TAX = 'salesTax';
export const STAMP = {
  none: 0,
  projectStamp: 1300,
  projectStampRev: 650,
  caNonLACntyProjectStamp: 1800,
  caLaCntyOnlyProjectStamp: 2300,
};

export const MA = 'Ma';

export const STAMP_LABELS = {
  none: 'None',
  projectStamp: 'Project Stamp',
  projectStampRev: 'Project Stamp Rev',
  caNonLACntyProjectStamp: 'CA (non-LA Cnty) Project Stamp',
  caLaCntyOnlyProjectStamp: 'CA (LA Cnty only) Project Stamp',
};
