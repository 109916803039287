export const INITIALIZED = 'pcd/auth/INITIALIZED';
export const INITIALIZING = 'pcd/auth/INITIALIZING';
export const INITIALIZING_SUCCEEDED = 'pcd/auth/INITIALIZING_SUCCEEDED';
export const INITIALIZING_FAILED = 'pcd/auth/INITIALIZING_FAILED';

export const SIGN_UP_REQUESTED = 'pcd/auth/SIGN_UP_REQUESTED';
export const SIGN_UP_PROGRESSING = 'pcd/auth/SIGN_UP_PROGRESSING';
export const SIGN_UP_SUCCEEDED = 'pcd/auth/SIGN_UP_SUCCEEDED';
export const SIGN_UP_FAILED = 'pcd/auth/SIGN_UP_FAILED';
export const SIGN_UP_RESET_ERRORS = 'pcd/auth/SIGN_UP_RESET_ERRORS';

export const SIGN_IN_REQUESTED = 'pcd/auth/SIGN_IN_REQUESTED';
export const SIGN_IN_PROGRESSING = 'pcd/auth/SIGN_IN_PROGRESSING';
export const SIGN_IN_SUCCEEDED = 'pcd/auth/SIGN_IN_SUCCEEDED';
export const SIGN_IN_FAILED = 'pcd/auth/SIGN_IN_FAILED';
export const SIGN_IN_RESET_ERRORS = 'pcd/auth/SIGN_IN_RESET_ERRORS';

export const SIGN_OUT_REQUESTED = 'pcd/auth/SIGN_OUT_REQUESTED';
export const SIGN_OUT_SUCCEEDED = 'pcd/auth/SIGN_OUT_SUCCEEDED';

export const HELIOSCOPE_LOGIN_REQUESTED = 'pcd/auth/HELIOSCOPE_LOGIN_REQUESTED';
export const HELIOSCOPE_LOGIN_FAILED = 'pcd/auth/HELIOSCOPE_LOGIN_FAILED';
export const HELIOSCOPE_LOGOUT = 'pcd/auth/HELIOSCOPE_LOGOUT';
export const HELIOSCOPE_LOGIN_SUCCEEDED = 'pcd/auth/HELIOSCOPE_LOGIN_SUCCEEDED';

export const ID_TOKEN_REQUESTED = 'pcd/auth/ID_TOKEN_REQUESTED';
export const ID_TOKEN_SUCCEEDED = 'pcd/auth/ID_TOKEN_SUCCEEDED';
export const ID_TOKEN_FAILED = 'pcd/auth/ID_TOKEN_FAILED';

export const TEMP_CREDENTIALS_REQUESTED = 'pcd/auth/TEMP_CREDENTIALS_REQUESTED';
export const TEMP_CREDENTIALS_REQUEST_PROGRESSING =
  'pcd/auth/TEMP_CREDENTIALS_REQUEST_PROGRESSING';
export const TEMP_CREDENTIALS_REQUEST_SUCCEEDED =
  'pcd/auth/TEMP_CREDENTIALS_REQUEST_SUCCEEDED';
export const TEMP_CREDENTIALS_REQUEST_FAILED =
  'pcd/auth/TEMP_CREDENTIALS_REQUEST_FAILED';

// cognito
export const CHECK_TOKEN = 'pcd/projects/CHECK_TOKEN';
export const WATCH_TOKEN = 'pcd/projects/WATCHING_TOKEN';
// export const ID_TOKEN_SUCCEEDED = "pcd/auth/ID_TOKEN_SUCCEEDED";
// export const ID_TOKEN_FAILED = "pcd/auth/ID_TOKEN_FAILED";

export const USER_UPDATE_REQUESTED = 'pcd/auth/USER_UPDATE_REQUESTED';
export const USER_SET_REQUESTED = 'pcd/auth/USER_SET_REQUESTED';

export const PASSWORD_UPDATE_REQUESTED = 'pcd/auth/PASSWORD_UPDATE_REQUESTED';

export const PASSWORD_CHANGE_FAILED = 'pcd/auth/PASSWORD_CHANGE_FAILED';
export const SET_USER_IDS = 'pcd/auth/SET_USER_IDS';

export const ACL_SET_REQUESTED = 'pcd/auth/ACL_SET_REQUESTED';
