export function prepareData(item, headers, index) {
  const row = {};

  headers.forEach(({ accessor, value }) => {
    row[accessor] =
      typeof item[accessor] !== 'undefined'
        ? item[accessor]
        : typeof value !== 'undefined'
        ? value
        : '';
  });

  row.index = index;
  row._id = item._id;

  return row;
}
export function filterByString(list, headers, filterString) {
  const rows = [];
  let i = -1;
  while (++i < list.length) {
    const item = list[i];
    if (filterString.length === 0) {
      rows.push(prepareData(item, headers, i));
    } else {
      let result = false;
      headers.forEach(({ access }) => {
        if (
          item[access]
            .toString()
            .toLowerCase()
            .includes(filterString.toLowerCase())
        ) {
          result = true;
        }
      });
      if (result) {
        rows.push(prepareData(item, headers, i));
      }
    }
  }
  return rows;
}
