import { createSelector } from 'reselect';
import { getNestedValueByStringKey } from 'utils/mixins';

// Filters
const errorFilter = (state, key) => {
  const { errors } = state;
  return getNestedValueByStringKey(errors, key) || null;
};

export const errorSelector = createSelector(errorFilter, error => error);

export default {
  errorSelector,
};
