import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import { SeparateRow } from './utils';

function arrayCostsMajor({ value: arrayCost, perWatCost, perModuleCost }) {
  return (
    <>
      <TableRow>
        <TableCell>Cost (Major Comps)</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {arrayCost}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Per Watt Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {perWatCost}
        </TableCell>
      </TableRow>
      <TableRow className="total-table-last-row">
        <TableCell>Per Module Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {' '}
          {perModuleCost}{' '}
        </TableCell>
      </TableRow>
    </>
  );
}

function arrayCostMA({ value: arrayCost, perModuleCost }) {
  return (
    <>
      <TableRow>
        <TableCell>Cost (Mech Attachments)</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {arrayCost}
        </TableCell>
      </TableRow>
      <TableRow className="total-table-last-row">
        <TableCell>Per Module Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {perModuleCost}
        </TableCell>
      </TableRow>
    </>
  );
}

function arrayCostsAccess({ value: arrayCost, perModuleCost }) {
  return (
    <>
      <TableRow>
        <TableCell>Cost (Construction Aids)</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {arrayCost}
        </TableCell>
      </TableRow>
      <TableRow className="total-table-last-row">
        <TableCell>Per Module Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {perModuleCost}
        </TableCell>
      </TableRow>
    </>
  );
}

function arrayCostWiring({ value: arrayCost, perModuleCost }) {
  return (
    <>
      <TableRow>
        <TableCell>Cost (Wire Management)</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {arrayCost}
        </TableCell>
      </TableRow>
      <TableRow className="total-table-last-row">
        <TableCell>Per Module Cost</TableCell>
        <TableCell align="right" className="total-table-cell-currency">
          {perModuleCost}
        </TableCell>
      </TableRow>
    </>
  );
}

function Summary({
  costMajor = {},
  costAccessories = {},
  costWiring = {},
  costMA = {},
}) {
  let rowSpanVertLanel = 7;

  if (costWiring?.value) {
    rowSpanVertLanel += 3;
  }

  if (costMA?.value) {
    rowSpanVertLanel += 3;
  }

  return (
    <>
      <TableCell className="total-table-category" rowSpan={rowSpanVertLanel}>
        <div className="total-table-category-text" style={{}}>
          SUMMARY
        </div>
      </TableCell>
      {arrayCostsMajor(costMajor)}
      {costMA?.value && (
        <>
          <SeparateRow />
          {arrayCostMA(costMA)}
        </>
      )}
      <SeparateRow />
      {arrayCostsAccess(costAccessories)}
      {costWiring?.value !== 0 && ( // Wiring is present
        <>
          <SeparateRow />
          {arrayCostWiring(costWiring)}
        </>
      )}
    </>
  );
}

Summary.propTypes = {
  costAccessories: PropTypes.shape({
    perModuleCost: PropTypes.string || PropTypes.number,
    value: PropTypes.string || PropTypes.number,
  }).isRequired,
  costMajor: PropTypes.shape({
    perModuleCost: PropTypes.string || PropTypes.number,
    perWatCost: PropTypes.string || PropTypes.number,
    value: PropTypes.string || PropTypes.number,
  }).isRequired,
};

export default Summary;
