import { isObject, uniqBy } from 'utils';

import config from './config';

import schemaCommon from './helpers/validationSchema';
import schemaHD from './helpers/validationSchemaHD';
import schemaClawFR from './helpers/validationSchemaClawFR';

const productValidators = {
  common: schemaCommon,
  HD: { ...schemaCommon, ...schemaHD },
  clawFR: { ...schemaCommon, ...schemaClawFR },
};

export function toMountingSystemLookups(data, initial = []) {
  if (data) {
    const mountingSystem = data.map(({ viewData }) => ({
      value: viewData.id,
      label: viewData.name,
    }));

    return uniqBy([...initial, ...mountingSystem], 'value');
  }

  return initial;
}

export function isCharRequired(name, mountingSystemId, mountingSystems = []) {
  let validator;
  let mountingSystem = '';

  mountingSystems.forEach((system) => {
    if (system.viewData.id === mountingSystemId) {
      mountingSystem = system.viewData.name;
    }
  });

  if (mountingSystem.includes('HD')) {
    validator = productValidators.HD;
  } else if (mountingSystem.includes('clawFR')) {
    validator = productValidators.clawFR;
  } else {
    validator = productValidators.common;
  }

  if (validator[name] && validator[name].required) {
    return true;
  }

  return false;
}

export function setRequiredChar(
  label,
  name,
  mountingSystem,
  mountingSystems = []
) {
  return `${label} ${
    isCharRequired(
      name,
      mountingSystem,
      Array.isArray(mountingSystems) ? mountingSystems : []
    )
      ? '*'
      : ''
  }`;
}

export function createFields(values = {}) {
  const {
    mountingSystem,
    manufacturer,
    modelName,
    W,
    power,
    dataSheetUrl,
    PL,
    PW,
    APT,
  } = values;

  const fields = {
    mountingSystem,
    manufacturer,
    modelName,
    W,
    power,
    dataSheetUrl,
    PL,
    PW,
    APT,
  };

  return fields;
}

export function getModuleForCheck(values, mountingSystems, defaultProps) {
  const selectedRacking = mountingSystems.find(
    (r) => r.viewData.id === values.mountingSystem
  );

  const productFamily = selectedRacking.viewData.name.includes('HD') ? 1 : 2;

  let rowSpacing = null;

  const option =
    selectedRacking?.viewData?.supportedRowSpacingOptions?.[0]?.option;

  if (isObject(option)) {
    rowSpacing = parseInt(option.value, 10);
  }

  const module = {
    id: values.id,
    linkedRackingProductFamily: productFamily,
    linkedRackingProduct: values.mountingSystem,
    rowSpacing,
    manufacturer: values.manufacturer,
    model: values.modelName,
    power: values.power,
    fireType: values.fireType,
    UL1703listed: values.UL1703listed,
    moduleDimensions: {
      PH: values.PH,
      PL: values.PL,
      PW: values.PW,
      W:
        typeof values.W !== 'undefined'
          ? values.W
          : defaultProps?.moduleDimensions?.w,
      Q: values.Q,
      PT:
        typeof values.PT !== 'undefined'
          ? values.PT
          : defaultProps?.moduleDimensions?.pt,
      holeDiameter: values.holeDiameter,
      slotLengthOverall: values.slotLengthOverall,
      Q2: values.Q2,
      holeDiameter2: values.holeDiameter2,
      slotLengthOverall2: values.slotLengthOverall2,
      APT:
        typeof values.APT !== 'undefined'
          ? values.APT
          : defaultProps?.moduleDimensions?.apt,
      AFI: values.AFI,
      AFT: values.AFT,
      AFO: values.AFO,
      AMINGAP: values.AMINGAP,
      AMAXGAP: values.AMAXGAP,
      AMINGAPPROTRUSION: values.AMINGAPPROTRUSION,
    },
    dataSheetUrl:
      typeof values.dataSheetUrl !== 'undefined'
        ? values.dataSheetUrl
        : defaultProps?.dataSheetUrl,
  };

  return module;
}

export function createErrors() {
  return Object.keys(config.fields).map((key) => {
    return { [key]: false };
  });
}

export function validate(fields) {
  const errors = {};
  let hasErrors = false;

  Object.keys(config.fields).forEach((key) => {
    errors[key] = config.fields[key].required && !fields[key];

    if (errors[key]) {
      hasErrors = true;
    }
  });

  return { errors, hasErrors };
}

export function validateField(name, value) {
  return config.fields[name]?.required && !value;
}
