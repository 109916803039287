import { isObject } from 'utils';

export const getParams = (props) => {
  const {
    filters = {},
    orderBy,
    orderDirection,
    page = 0,
    pageSize = 10,
    search,
  } = props;
  const params = {
    page: page + 1, // in MaterialUI pages starting from 0
    pageSize,
    filters,
  };
  if (search) {
    params.search = search;
  }
  if (orderBy) {
    params.orderBy = orderBy.field[0].toUpperCase() + orderBy.field.slice(1);
    params.desc = orderDirection === 'desc';
  }

  return params;
};

const getPage = (pagination, params, totalCount) => {
  if (pagination) {
    return pagination.page - 1;
  }

  if (Math.ceil(totalCount / params.pageSize) < params.page) {
    return 0;
  }

  return params.page - 1;
};

export const getRemoteData = async (props, getData) => {
  const params = getParams(props.filterOptions);

  const { data, count } = await getData(params);

  const allItems = (data && data.data) || data;

  let totalCount = data.pagination
    ? data.pagination.totalItems
    : allItems.length;

  if (count) {
    totalCount = count;
  }

  return {
    data: allItems,
    page: getPage(data.pagination, params, totalCount),
    totalCount,
  };
};

export const getAllItemsFirstTime = async (
  filterOptions,
  getData,
  idFieldName
) => {
  const options = getParams(filterOptions);
  const { data } = await getData();
  const allItems = (data && data.data) || data;
  const MUPage = options.page - 1;

  return {
    data: allItems,
    groupedData: allItems.reduce((acc, cur) => {
      acc[cur[idFieldName]] = cur;
      return acc;
    }, {}),
    totalCount: allItems.length,
    page: MUPage,
    pageSize: options.pageSize,
    limit: options.pageSize,
  };
};
/* eslint-disable */
const sort = (allItems, { orderBy, orderDirection }, filterTypes) => {
  if (!orderBy) return allItems;
  const desc = orderDirection === 'desc';
  const { field } = orderBy;
  return allItems.sort((a, b) => {
    const type = filterTypes[field];
    switch (type) {
      case 'numeric':
        a = Number(a[field]);
        b = Number(b[field]);
        break;
      case 'boolean':
        a = Boolean(a[field]);
        b = Boolean(b[field]);
        break;
      case 'date':
        a = a[field] ? new Date(a[field]) : new Date();
        b = b[field] ? new Date(b[field]) : new Date();
        break;
      case 'string':
      case 'autosuggestion':
      default:
        a = a[field] ? a[field] : '';
        b = b[field] ? b[field] : '';
        typeof a === 'string' && a.length && (a = a.toLowerCase());
        typeof b === 'string' && b.length && (b = b.toLowerCase());
        break;
    }
    return (desc ? a < b : a > b) ? 1 : -1;
  });
};
/* eslint-enable */
const filter = (allItems, options, filterTypes) => {
  const filters = Object.entries(options.filters).filter(
    ([, value]) => value !== null && value !== undefined && value !== ''
  );

  return !filters.length
    ? allItems
    : allItems.filter((item) => {
        return filters.every(([filterKey, filterValue]) => {
          const itemValue = getValue(item, filterKey);
          const type = filterTypes[filterKey];

          if (itemValue === null || itemValue === undefined) return false;
          switch (type) {
            case 'boolean':
              return Boolean(itemValue) === Boolean(filterValue);
            case 'numeric':
              if (filterValue !== 0 && !filterValue) return true;
              return Number(itemValue) === Number(filterValue);
            case 'enum':
              return itemValue
                .toLowerCase()
                .includes(filterValue.toLowerCase());
            default:
              return itemValue
                .toLowerCase()
                .includes(filterValue.toLowerCase());
          }
        });
      });
};

// eslint-disable-next-line no-shadow
const search = (allItems, { search }, searchableFields) => {
  if (!search) {
    return allItems;
  }

  const normalizedSearch = search.toLowerCase();
  return allItems.filter((item) =>
    searchableFields.some(
      ({ field }) =>
        item[field] &&
        item[field].toString().toLowerCase().includes(normalizedSearch)
    )
  );
};

const paginate = (allItems, { page, pageSize }) =>
  allItems.slice(page * pageSize, (page + 1) * pageSize);

export function getFilteredData(options, cachedData) {
  // eslint-disable-next-line no-unused-vars
  const { allItems, prevFilters, searchableFields, filterTypes } = cachedData;
  const { page, pageSize } = getParams(options);
  let filteredData = allItems.slice();

  const MUPage = page - 1;

  filteredData = search(filteredData, options, searchableFields);

  filteredData = filter(filteredData, options, filterTypes);

  filteredData = sort(filteredData, options, filterTypes);

  const paginatedData = paginate(filteredData, options);

  return {
    data: paginatedData,
    totalCount: filteredData.length,
    page: MUPage,
    pageSize,
    filteredData,
  };
}

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

export const getVisibleColumns = (columns, hidedColumns) => {
  columns.forEach((col) => {
    if (!hidedColumns[col.field] && col.tableData && !col.tableData.width) {
      col.tableData.initialWidth = 'calc((100% - (0px)) / 11)';
      col.tableData.width = 'calc((100% - (0px)) / 11)';
    }
    col.hidden = !!hidedColumns[col.field];
  });
  return columns;
};

function getValue(item, name) {
  const names = name.split('.');
  let field = item;
  let value = null;

  names.forEach((n) => {
    if (!field) return;

    value = field[n];
    field = value;
  });

  return value;
}
