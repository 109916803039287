import React from 'react';
import PropTypes from 'prop-types';

import { Box, CircularProgress, Typography } from '@material-ui/core';

export function CircularProgressWithContent(props) {
  const { content } = props;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {content}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithContent.propTypes = {
  content: PropTypes.string,
};

CircularProgressWithContent.defaultProps = {
  content: 'Loading...',
};

export default CircularProgressWithContent;
