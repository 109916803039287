import { OPERATION_SAVE } from 'containers/Estimates/Estimate/constants';

const config = {
  fields: {
    customerName: {
      name: 'customerName',
      type: 'string',
      precision: 0,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Customer *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Customer is required',
        },
      },
    },
    projectName: {
      name: 'projectName',
      type: 'string',
      precision: 0,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Project Name *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Project Name is required',
        },
      },
    },
    address: {
      name: 'address',
      type: 'string',
      precision: 0,
      required: false,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Site Address',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: '',
        },
      },
    },
    estShippingCost: {
      name: 'estShippingCost',
      type: 'number',
      valueType: 'currency',
      precision: 2,
      required: false,
      validateOnAction: OPERATION_SAVE,
      inBetween: {
        min: 0,
        max: Infinity,
        mMin: 0,
        mMax: Infinity,
      },
      ui: {
        label: 'Est. Shipping Cost',
        type: 'Input',
        placeholder: '',
        size: 'boldMedium',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: '',
        },
      },
    },
    engServicesCost: {
      name: 'engServicesCost',
      type: 'number',
      valueType: 'currency',
      precision: 2,
      required: false,
      validateOnAction: OPERATION_SAVE,
      inBetween: {
        min: 0,
        max: Infinity,
        mMin: 0,
        mMax: Infinity,
      },
      ui: {
        label: 'Eng. Services Cost',
        type: 'Input',
        placeholder: '',
        size: 'boldMedium',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: '',
        },
      },
    },
    opportunity: {
      name: 'opportunity',
      type: 'numeric',
      precision: 0,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Opportunity *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          empty: 'Opportunity is required',
          type: 'Opportunity must be a integer',
          message: 'Opportunity is required',
        },
      },
    },
    numberModules: {
      name: 'numberModules',
      type: 'numeric',
      precision: 0,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Number of Modules *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Number of Modules is required',
        },
      },
    },
    modulePower: {
      name: 'modulePower',
      type: 'number',
      precision: 1,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Module Power (W) *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Module Power is required',
        },
      },
    },
    systemPower: {
      name: 'systemPower',
      type: 'number',
      precision: 1,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'System Power (kW)',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'System Power is required',
        },
      },
    },
    tier: {
      name: 'tier',
      type: 'string',
      precision: 0,
      required: true,
      validateOnAction: OPERATION_SAVE,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Tier *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '1',
        error: {
          message: 'Tier is required',
        },
      },
    },
    salesPerson: {
      name: 'salesPerson',
      type: 'string',
      precision: 0,
      required: true,
      validateOnAction: OPERATION_SAVE,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Sales Person *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Sales Person is required',
        },
      },
    },
    discount: {
      name: 'discount',
      type: 'string',
      precision: 0,
      required: false,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Discount',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Discount is required',
        },
      },
    },
    salesTaxRate: {
      name: 'salesTaxRate',
      type: 'number',
      precision: 2,
      required: false,
      inBetween: {
        min: 0,
        max: 100,
        mMin: 0,
        mMax: 100,
      },
      ui: {
        label: 'Sales Tax Rate (%)',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
      },
    },
    mechanicalAttachment: {
      name: 'mechanicalAttachment',
      type: 'string',
      precision: 0,
      required: false,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Mechanical Attachment',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Mechanical Attachment is required',
        },
      },
    },
    membrane: {
      name: 'membrane',
      type: 'string',
      precision: 0,
      required: false,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Membrane',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Membrane is required',
        },
      },
    },
    stamp: {
      name: 'stamp',
      type: 'string',
      precision: 0,
      required: false,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Stamps',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Stamps is required',
        },
      },
    },
    manufacturer: {
      name: 'manufacturer',
      modelName: 'module',
      ignoreRuleIfExistField: 'isCustom',
      type: 'string',
      precision: 0,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Module Mfg *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Module Mfg is required',
        },
      },
    },
    moduleParent: {
      name: 'moduleParent',
      modelName: 'module',
      ignoreRuleIfExistField: 'isCustom',
      type: 'string',
      precision: 0,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Module *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Module is required',
        },
      },
    },
    mountingSystem: {
      name: 'mountingSystem',
      modelName: 'module',
      type: 'string',
      precision: 0,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Racking *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Racking is required',
        },
      },
    },
    rowSpacing: {
      name: 'rowSpacing',
      modelName: 'module',
      type: 'string',
      precision: 0,
      required: true,
      inBetween: {
        min: -Infinity,
        max: Infinity,
        mMin: -Infinity,
        mMax: Infinity,
      },
      ui: {
        label: 'Row spacing *',
        type: 'Input',
        placeholder: '',
        size: 'small',
        autoComplete: 'off',
        defaultValue: '',
        error: {
          message: 'Row spacing is required',
        },
      },
    },
    supports: {
      default: {
        values: [
          {
            id: 1,
            name: 'numberSouthModules',
            label: '# south modules *',
            value: '',
          },
          {
            id: 2,
            name: 'numberBases',
            label: '# of bases',
            value: '',
          },
          {
            id: 3,
            name: 'railModuleRatio',
            label: 'rail/module ratio *',
            value: '',
          },
          {
            id: 4,
            name: 'baseModuleRatio',
            label: 'base/module ratio',
            value: '',
          },
          {
            id: 5,
            name: 'numberDeflectors',
            label: '# of deflectors',
            value: '',
          },
          {
            id: 6,
            name: 'numberBolts',
            label: '# of bolts',
            value: '',
          },
          {
            id: 7,
            name: 'numberRails',
            label: '# of rails',
            value: '',
          },
          {
            id: 8,
            name: 'numberMAs',
            label: '# MAs/brackets *',
            value: '',
          },
          {
            id: 9,
            name: 'numberCams',
            label: '# of cams/claws',
            value: '',
          },
        ],
      },
    },
  },
};

export default config;
