import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Col } from 'react-bootstrap';

import { CREATE, EDIT } from 'components/DetailView/constants';
import ShowIf from 'components/ShowIf';
import { FormContainer } from 'components/common/Form/FormElements';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Toolbar from '../Toolbar';

import DetailFieldComponent from './DetailFieldComponent';

import './styles.scss';

function checkCondition(col, fieldsValues) {
  if (col.hideInDetailView) {
    return false;
  }
  if (
    col.hideIfNoData &&
    (Array.isArray(fieldsValues[col?.field])
      ? !fieldsValues[col?.field].length
      : !fieldsValues[col?.field])
  ) {
    return false;
  }
  return true;
}

function DetailForm({
  cols,
  mode,
  onSubmit,
  handleClose,
  fieldsValues,
  loading,
  errors,
  onChange,
  onSave,
  tableProps,
  colsAsTable,
  colsAsAccordion,
  colsAsEditables,
}) {
  const [fields, setFields] = useState([]);
  const [fieldsAsTable, setFieldsAsTable] = useState([]);
  const [fieldsAsAccordion, setFieldsAsAccordion] = useState([]);
  const [saveLabel, setSaveLabel] = useState('Save');
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const { create } = tableProps;

    if (mode === CREATE && create?.fields) {
      setFields(create.fields || []);
    } else {
      setFields(cols || []);
    }

    if (mode === CREATE && create?.fieldsAsTable) {
      setFieldsAsTable(create.fieldsAsTable || []);
    } else {
      setFieldsAsTable(colsAsTable || []);
    }

    if (mode === CREATE && create?.fieldsAsAccordion) {
      setFieldsAsAccordion(create.fieldsAsAccordion || []);
    } else {
      setFieldsAsAccordion(colsAsAccordion || []);
    }

    setSaveLabel(mode === CREATE ? create?.saveLabel || 'Create' : 'Save');
  }, [mode, tableProps.create, cols, colsAsTable, colsAsAccordion]);

  const onExpandPanel = useCallback((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  });

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form }) => {
        return (
          <FormContainer
            horizontal
            as="div"
            className="detail-view-form"
            onSubmit={handleSubmit}
          >
            {fieldsAsTable.map((colWithFields, j) => (
              <Col
                className={`detail-view-form__col detail-view-form__col-extended ${
                  j === fieldsAsTable.length - 1
                    ? 'detail-view-form__col-extended-last'
                    : ''
                } ${j === 0 ? 'detail-view-form__col-extended-first' : ''}`}
                key={colWithFields.id || j}
                lg={colWithFields.size.lg || 12 / fieldsAsTable.length}
                md={colWithFields.size.md || 12 / fieldsAsTable.length}
                sm={colWithFields.size.md || 1}
              >
                {colWithFields.fields.map((col, i) => (
                  <ShowIf
                    key={col?.field || i}
                    condition={checkCondition(col, fieldsValues)}
                  >
                    <DetailFieldComponent
                      key={col?.field || i}
                      col={col}
                      fieldsValues={fieldsValues}
                      conditionForEdit={colsAsEditables?.[col?.field]?.when}
                      extraFields={colsAsEditables?.[col?.field]}
                      errors={errors}
                      mode={mode}
                      loading={loading}
                      onChange={onChange}
                    />
                  </ShowIf>
                ))}
              </Col>
            ))}

            <Col
              className="detail-view-form__col detail-view-form__col-fields"
              lg={12}
              md={12}
              sm={12}
            >
              {fields.map((col, i) => (
                <ShowIf
                  key={col?.field || i}
                  condition={checkCondition(col, fieldsValues)}
                >
                  <DetailFieldComponent
                    key={col?.field || i}
                    col={col}
                    fieldsValues={fieldsValues}
                    conditionForEdit={colsAsEditables?.[col?.field]?.when}
                    extraFields={colsAsEditables?.[col?.field]}
                    errors={errors}
                    mode={mode}
                    loading={loading}
                    onChange={onChange}
                  />
                </ShowIf>
              ))}
            </Col>

            {fieldsAsAccordion?.length > 0 && (
              <Accordion
                className="detail-view-form__accordion"
                expanded={expanded === 'panelSelectData'}
                onChange={onExpandPanel('panelSelectData')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="filter-select-data">
                    <div className="filter-select-data__title">
                      Other Request Data
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {fieldsAsAccordion.map((colWithFields, j) => (
                    <Col
                      className={`detail-view-form__col detail-view-form__col-extended ${
                        j === fieldsAsAccordion.length - 1
                          ? 'detail-view-form__col-extended-last'
                          : ''
                      } ${
                        j === 0 ? 'detail-view-form__col-extended-first' : ''
                      }`}
                      key={colWithFields.id || j}
                      lg={colWithFields.size.lg || 12 / fieldsAsTable.length}
                      md={colWithFields.size.md || 12 / fieldsAsTable.length}
                      sm={colWithFields.size.md || 1}
                    >
                      {colWithFields.fields.map((col, i) => (
                        <ShowIf
                          key={col?.field || i}
                          condition={checkCondition(col, fieldsValues)}
                        >
                          <DetailFieldComponent
                            key={col?.field || i}
                            col={col}
                            fieldsValues={fieldsValues}
                            conditionForEdit={
                              colsAsEditables?.[col?.field]?.when
                            }
                            extraFields={colsAsEditables?.[col?.field]}
                            errors={errors}
                            mode={mode}
                            loading={loading}
                            onChange={onChange}
                          />
                        </ShowIf>
                      ))}
                    </Col>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}

            {(mode === EDIT || mode === CREATE) && (
              <Toolbar
                className="detail-view-form__toolbar"
                disabledCancel={loading}
                onCancel={() => {
                  handleClose();
                  form.reset();
                }}
                disabledSave={loading}
                loadingSave={loading}
                saveLabel={saveLabel}
                onSave={onSave}
              />
            )}
          </FormContainer>
        );
      }}
    </Form>
  );
}

DetailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldsValues: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  tableProps: PropTypes.object,
  colsAsTable: PropTypes.arrayOf(PropTypes.object),
  colsAsAccordion: PropTypes.arrayOf(PropTypes.object),
};

export default DetailForm;
