import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Breadcrumbs, Stack } from '@mui/material';
import { Link, Typography } from '@material-ui/core';

import './assets/style.scss';

function Index({ items, onClick }) {
  const handleClick = useCallback((value) => {
    onClick(value);
  }, []);

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {items.map((item, index) => {
          return index !== items.length - 1 ? (
            <Link
              underline="hover"
              key={item.id}
              color="inherit"
              href="#"
              onClick={(e) => {
                handleClick(item);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {item.label}
            </Link>
          ) : (
            <Typography key={item.id} color="text.primary">
              {item.label}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
}

Index.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
};

Index.defaultProps = {
  items: [],
  onClick: () => {},
};

export default connect()(Index);
