import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getAcl } from 'redux/selectors/selectors';

import { ThemeProps, RTLProps } from 'types/ReducerProps';

class MainWrapper extends PureComponent {
  render() {
    const {
      theme,
      children,
      rtl: { direction },
    } = this.props;

    return (
      <div
        className={classNames(theme.className, `${direction}-support`)}
        dir={direction}
      >
        <div className="wrapper">{children}</div>
      </div>
    );
  }
}

MainWrapper.propTypes = {
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    rtl: state.rtl,
    acl: getAcl(state),
  };
};

export default withRouter(connect(mapStateToProps)(MainWrapper));
