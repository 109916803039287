import {
  INITIALIZED,
  INITIALIZING,
  INITIALIZING_SUCCEEDED,
  INITIALIZING_FAILED,
  SIGN_UP_REQUESTED,
  SIGN_UP_PROGRESSING,
  SIGN_UP_SUCCEEDED,
  SIGN_UP_FAILED,
  SIGN_UP_RESET_ERRORS,
  SIGN_IN_REQUESTED,
  SIGN_IN_PROGRESSING,
  SIGN_IN_SUCCEEDED,
  SIGN_IN_FAILED,
  SIGN_IN_RESET_ERRORS,
  SIGN_OUT_REQUESTED,
  SIGN_OUT_SUCCEEDED,
  ID_TOKEN_REQUESTED,
  ID_TOKEN_SUCCEEDED,
  ID_TOKEN_FAILED,
  TEMP_CREDENTIALS_REQUESTED,
  TEMP_CREDENTIALS_REQUEST_PROGRESSING,
  TEMP_CREDENTIALS_REQUEST_SUCCEEDED,
  TEMP_CREDENTIALS_REQUEST_FAILED,
  USER_UPDATE_REQUESTED,
  HELIOSCOPE_LOGIN_REQUESTED,
  HELIOSCOPE_LOGIN_SUCCEEDED,
  HELIOSCOPE_LOGIN_FAILED,
  PASSWORD_UPDATE_REQUESTED,
  USER_SET_REQUESTED,
  HELIOSCOPE_LOGOUT,
  ACL_SET_REQUESTED,
} from '../authTypes';

import { action } from '../_utils';

export default {
  init: () => action(INITIALIZED, {}),
  initializing: (status = true) => action(INITIALIZING, { status }),
  initializingSuccess: (user, token, tokenExpirationTime, refreshToken) => {
    return action(INITIALIZING_SUCCEEDED, {
      user,
      token,
      tokenExpirationTime,
      refreshToken,
    });
  },
  initFailure: (error) => action(INITIALIZING_FAILED, { error }),

  signUp: (name, email, password) =>
    action(SIGN_UP_REQUESTED, { name, email, password }),
  signingUp: (status = true) => action(SIGN_UP_PROGRESSING, { status }),
  signingUpSuccess: (user, token, tokenExpirationTime, refreshToken) => {
    return action(SIGN_UP_SUCCEEDED, {
      user,
      token,
      tokenExpirationTime,
      refreshToken,
    });
  },
  signingUpFailure: (error) => action(SIGN_UP_FAILED, { error }),
  resetSignUpErrors: () => action(SIGN_UP_RESET_ERRORS),
  setUser: (user, token) => action(USER_SET_REQUESTED, { user, token }),
  signIn: (cognitoUserSession) =>
    action(SIGN_IN_REQUESTED, { cognitoUserSession }),
  signingIn: (status = true) => action(SIGN_IN_PROGRESSING, { status }),
  signingInSuccess: (user, token) => {
    sessionStorage.setItem('token', token);
    return action(SIGN_IN_SUCCEEDED, { user, token });
  },
  signingInFailure: (error) => action(SIGN_IN_FAILED, { error }),
  resetSignInErrors: () => action(SIGN_IN_RESET_ERRORS),

  signOut: () => action(SIGN_OUT_REQUESTED),
  signOutSuccess: () => action(SIGN_OUT_SUCCEEDED),
  loginHelioscope: ({ helioscopeLogin, helioscopePass, cosUserId }) =>
    action(HELIOSCOPE_LOGIN_REQUESTED, {
      helioscopeLogin,
      helioscopePass,
      cosUserId,
    }),
  loginHelioscopeSuccess: (sessionId) =>
    action(HELIOSCOPE_LOGIN_SUCCEEDED, { sessionId }),
  loginHelioscopeFailed: (error) => action(HELIOSCOPE_LOGIN_FAILED, { error }),
  logoutHelioscope: () => action(HELIOSCOPE_LOGOUT),
  fetchIdToken: () => action(ID_TOKEN_REQUESTED, {}),
  fetchingIdTokenSuccess: (token, tokenExpirationTime, refreshToken, user) => {
    sessionStorage.setItem('token', token);
    return action(ID_TOKEN_SUCCEEDED, {
      token,
      tokenExpirationTime,
      refreshToken,
      user,
    });
  },
  fetchingIdTokenFailure: (refresh) => action(ID_TOKEN_FAILED, { refresh }),

  fetchTempCredentials: () => action(TEMP_CREDENTIALS_REQUESTED, {}),
  fetchingTempCredentials: () =>
    action(TEMP_CREDENTIALS_REQUEST_PROGRESSING, { status: true }),
  fetchingTempCredentialsSuccess: (credentials) =>
    action(TEMP_CREDENTIALS_REQUEST_SUCCEEDED, { credentials }),
  fetchingTempCredentialsFailure: (error) =>
    action(TEMP_CREDENTIALS_REQUEST_FAILED, { error }),

  updateUser: (user) => action(USER_UPDATE_REQUESTED, { user }),
  updatePassword: (oldPassword, newPassword) =>
    action(PASSWORD_UPDATE_REQUESTED, { oldPassword, newPassword }),

  setACL: (acl) => action(ACL_SET_REQUESTED, { acl }),
};
