import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useResizeColumns,
  useRowSelect,
} from 'react-table';
import styled from 'styled-components';
import { Table as BootstrapTable } from 'reactstrap';
import TableHeader from './TableHeader';
import BodyReactTable from './TableBody';
import TableFooter from './TableFooter';
import TableFilter from './TableFilter';
import TableActions from './TableActions';
import TablePagination from './TablePagination';
import { tableStyle, TableWrap } from './styles';
import Loader from '../../Loader/Loader';

const Table = styled(BootstrapTable).withConfig({
  shouldForwardProp: (prop) => !['headAccent'].includes(prop),
})`
  ${tableStyle}
`;

function TableConstructor({
  tableConfig,
  tableOptions,
  tableOptionalHook,
  toolbarIcon,
  actions,
  ...rest
}) {
  const {
    isEditable,
    isResizable,
    isSortable,
    isSelectable,
    withPagination,
    withFooter,
    withSearchEngine,
    manualPageSize,
    placeholder,
    remoteData,
  } = tableConfig;

  const [remotePageIndex, setRemotePageIndex] = useState(0);
  const [remotePageSize, setRemotePageSize] = useState(
    tableOptions?.initialState?.pagination?.pageSize || 10
  );
  const [remoteFilterValue, setRemoteFilterValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateDraggableData,
    updateEditableData,
    dataLength,
    state: { pageIndex, pageSize },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    ...tableOptionalHook
  );

  if (remoteData) {
    pageOptions = [];
    pageCount = Math.ceil(rest.count / remotePageSize);
    let i = -1;
    while (++i < pageCount) {
      pageOptions.push(i);
    }
    dataLength = rest.count;
    canPreviousPage = pageCount > 1 && remotePageIndex > 0;
    canNextPage = pageCount > 1 && remotePageIndex < pageCount - 1;
  }

  const fetchSelectedPage = (pageNumber, filter) => {
    setIsLoading(true);
    setRemoteFilterValue(filter);
    setRemotePageIndex(pageNumber);
    rest.fetchSelectedPage(pageNumber + 1, remotePageSize, filter).then(() => {
      setIsLoading(false);
    });
  };

  const fetchPreviousPage = () => {
    setIsLoading(true);
    setRemotePageIndex(remotePageIndex - 1);
    rest
      .fetchSelectedPage(remotePageIndex, remotePageSize, remoteFilterValue)
      .then(() => {
        setIsLoading(false);
      });
  };

  const fetchNextPage = () => {
    setIsLoading(true);
    setRemotePageIndex(remotePageIndex + 1);
    rest
      .fetchSelectedPage(remotePageIndex + 2, remotePageSize, remoteFilterValue)
      .then(() => {
        setIsLoading(false);
      });
  };

  const fetchPageSize = (size) => {
    setIsLoading(true);
    setRemotePageSize(size);
    setRemotePageIndex(0);
    rest.fetchSelectedPage(1, size, remoteFilterValue).then(() => {
      setIsLoading(false);
    });
  };

  const withActions = !!(actions && actions.length);

  return (
    <div>
      {isLoading && <Loader loading={isLoading} className="fill-container" />}
      {(withSearchEngine || toolbarIcon || withActions) && (
        <div className="table-tools">
          {withSearchEngine && (
            <TableFilter
              rows={rows}
              setGlobalFilter={setGlobalFilter}
              setFilterValue={tableOptions.setFilterValue}
              globalFilter={state.globalFilter}
              placeholder={placeholder}
              dataLength={dataLength}
              remoteData={remoteData}
              fetchSelectedPage={fetchSelectedPage}
            />
          )}
          <div
            className={`right-table-tool ${
              withActions ? 'with-action-tool' : ''
            }`}
          >
            {toolbarIcon}
            {withActions ? <TableActions actions={actions} /> : null}
          </div>
        </div>
      )}
      <TableWrap pagination={withPagination}>
        <Table {...getTableProps()} bordered>
          <TableHeader
            headerGroups={headerGroups}
            isSortable={isSortable}
            isResizable={isResizable}
          />
          <BodyReactTable
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            updateDraggableData={updateDraggableData}
            updateEditableData={updateEditableData}
            isEditable={isEditable}
            isSelectable={isSelectable}
            {...rest}
          />
          {withFooter &&
            (pageCount === pageIndex + 1 ||
              (!withPagination && rows.length !== 0)) && (
              <TableFooter footerGroups={footerGroups} />
            )}
        </Table>
      </TableWrap>
      {withPagination && rows.length > 0 && (
        <TablePagination
          page={page}
          gotoPage={remoteData ? fetchSelectedPage : gotoPage}
          previousPage={remoteData ? fetchPreviousPage : previousPage}
          nextPage={remoteData ? fetchNextPage : nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={remoteData ? remotePageSize : pageSize}
          pageIndex={remoteData ? remotePageIndex : pageIndex}
          pageCount={pageCount}
          setPageSize={remoteData ? fetchPageSize : setPageSize}
          manualPageSize={manualPageSize}
          dataLength={dataLength}
        />
      )}
    </div>
  );
}

TableConstructor.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
    remoteData: PropTypes.bool,
    withFooter: PropTypes.bool,
    isSelectable: PropTypes.bool,
  }),
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    data: PropTypes.arrayOf(PropTypes.shape()),
    setFilterValue: PropTypes.func,
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
    defaultColumn: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({
        Cell: PropTypes.func,
      }).isRequired,
    ]),
    isEditable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    dataLength: PropTypes.number,
  }),
  tableOptionalHook: PropTypes.arrayOf(PropTypes.func).isRequired,
};

TableConstructor.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    remoteData: false,
    withFooter: false,
    isSelectable: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  },
  tableOptions: [
    {
      columns: [],
      data: [],
      setFilterValue: () => {},
      updateDraggableData: () => {},
      updateEditableData: () => {},
      defaultColumn: [],
      withDragAndDrop: false,
      dataLength: null,
      disableSortBy: false,
      manualSortBy: false,
      manualGlobalFilter: false,
    },
  ],
};

export default TableConstructor;
