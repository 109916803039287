import React, { useState, useCallback, useMemo } from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { getObjProp } from 'utils';

import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';

function EditViewDualList({
  path,
  field,
  fieldsValues,
  onChangeField,
  lookup,
  isReadOnly,
  mode,
}) {
  let value = useMemo(() => getObjProp(fieldsValues, path || field, '-'), [
    fieldsValues,
    path,
    field,
  ]);

  if (Object.prototype.toString.call(value) === '[object Object]') {
    if (!Array.isArray(value.AvailableAcls)) {
      value.AvailableAcls = [];
    }

    if (!Array.isArray(value.LinkedAcls)) {
      value.LinkedAcls = [];
    }
  } else {
    value = {
      AvailableAcls: [],
      LinkedAcls: [],
    };
  }

  const initedLinkedACLIds = value.LinkedAcls.map(({ Id }) => Id);

  const [selected, setSelected] = useState(initedLinkedACLIds);

  const mergedAcls = [
    ...value.AvailableAcls,
    ...value.LinkedAcls,
  ].sort((a, b) => a.Name.localeCompare(b.Name));

  const uniqueAcls = [
    ...mergedAcls
      .reduce((map, obj) => map.set(obj.Id, obj), new Map())
      .values(),
  ];

  const options = uniqueAcls.map((x) => ({
    value: x.Id,
    label: x.Name,
  }));

  const onChange = useCallback(
    // eslint-disable-next-line
    (selected) => {
      const selectedSet = new Set(selected);

      const newValue = {
        ...value,
        AvailableAcls: [],
        LinkedAcls: [],
      };

      let i = -1;
      const n = mergedAcls.length;
      while (++i < n) {
        const acl = mergedAcls[i];
        const array = selectedSet.has(acl.Id)
          ? newValue.LinkedAcls
          : newValue.AvailableAcls;

        array.push(acl);
      }

      onChangeField(field, newValue, path);
      setSelected(selected);
    },
    [onChangeField, path, field]
  );

  return (
    <DualListBox
      options={options}
      selected={selected}
      onChange={onChange}
      disabled={mode === 'View' || isReadOnly}
      showHeaderLabels
      lang={{
        availableHeader: 'Available ACLs',
        selectedHeader: 'Used ACLs',
      }}
      icons={{
        moveLeft: <KeyboardArrowLeftOutlinedIcon />,
        moveAllLeft: [
          <KeyboardArrowLeftOutlinedIcon key="1" />,
          <KeyboardArrowLeftOutlinedIcon key="2" />,
        ],
        moveRight: <KeyboardArrowRightOutlinedIcon />,
        moveAllRight: [
          <KeyboardArrowRightOutlinedIcon key="3" />,
          <KeyboardArrowRightOutlinedIcon key="4" />,
        ],
      }}
      canFilter
    />
  );
}

export default EditViewDualList;
