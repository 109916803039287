import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

function Loader({
  loading,
  tableLoading,
  aclLoading,
  pageLoaded: pageLoadedProps,
  className,
}) {
  const [pageLoaded, setPageLoaded] = useState(pageLoadedProps);

  useEffect(() => {
    window.addEventListener('load', () => {
      setTimeout(() => setPageLoaded(true), 500);
    });
  });

  if (!loading && !tableLoading && !aclLoading && pageLoaded) return null;

  return (
    <div className={`load ${className}`}>
      <div className="load__icon-wrap">
        <svg className="load__icon">
          <path fill="#004D7A" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
  );
}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  tableLoading: PropTypes.bool,
  aclLoading: PropTypes.bool,
  pageLoaded: PropTypes.bool, // only for test purpose
  className: PropTypes.string,
};

Loader.defaultProps = {
  pageLoaded: false,
  aclLoading: false,
  tableLoading: false,
  className: '',
};

export default Loader;
