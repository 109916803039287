import { uniqBy } from 'utils';
import { api } from 'utils/fetch';

const requestTypeFields = [
  {
    field: 'Id',
    title: 'Id',
    searchable: true,
    showInTable: false,
    editable: 'never',
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
  {
    field: 'Description',
    title: 'Description',
    searchable: true,
    showInTable: true,
    editable: true,
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
  {
    field: 'Value',
    title: 'Value',
    searchable: true,
    showInTable: true,
    editable: true,
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
];

export const requestTypeHeaders = {
  headers: requestTypeFields.filter(({ showInTable }) => showInTable),
  detailViewCols: [], // requestTypeFields,
  fieldsMap: requestTypeFields.reduce(
    (acc, cur) => [
      ...acc,
      {
        dbGridName: cur.title,
        uiGridName: cur.path || cur.field,
        uiGridNameLowCase: (cur.path || cur.field).toLowerCase(),
        type: cur.type || 'string',
      },
    ],
    []
  ),
};

const assignedToFields = [
  {
    field: 'Id',
    title: 'Id',
    searchable: true,
    showInTable: false,
    editable: 'never',
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
  {
    field: 'Description',
    title: 'Description',
    searchable: true,
    showInTable: true,
    editable: true,
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
  {
    field: 'Value',
    title: 'Value',
    searchable: true,
    showInTable: true,
    editable: true,
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
];

export const assignedToHeaders = {
  headers: assignedToFields.filter(({ showInTable }) => showInTable),
  detailViewCols: [], // assignedToFields,
  fieldsMap: assignedToFields.reduce(
    (acc, cur) => [
      ...acc,
      {
        dbGridName: cur.title,
        uiGridName: cur.path || cur.field,
        uiGridNameLowCase: (cur.path || cur.field).toLowerCase(),
        type: cur.type || 'string',
      },
    ],
    []
  ),
};

const reviewedByFields = [
  {
    field: 'Id',
    title: 'Id',
    searchable: true,
    showInTable: false,
    editable: 'never',
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
  {
    field: 'Description',
    title: 'Description',
    searchable: true,
    showInTable: true,
    editable: true,
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
  {
    field: 'Value',
    title: 'Value',
    searchable: true,
    showInTable: true,
    editable: true,
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
];

export const reviewedByHeaders = {
  headers: reviewedByFields.filter(({ showInTable }) => showInTable),
  detailViewCols: [], // reviewedByFields,
  fieldsMap: reviewedByFields.reduce(
    (acc, cur) => [
      ...acc,
      {
        dbGridName: cur.title,
        uiGridName: cur.path || cur.field,
        uiGridNameLowCase: (cur.path || cur.field).toLowerCase(),
        type: cur.type || 'string',
      },
    ],
    []
  ),
};

const requestStatusFields = [
  {
    field: 'Id',
    title: 'Id',
    searchable: true,
    showInTable: false,
    editable: 'never',
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
  {
    field: 'Description',
    title: 'Description',
    searchable: true,
    showInTable: true,
    editable: true,
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
  {
    field: 'Value',
    title: 'Value',
    searchable: true,
    showInTable: true,
    editable: true,
    filtering: true,
    sortable: true,
    options: [],
    validations: {
      required: true,
    },
  },
];

export const requestStatusHeaders = {
  headers: requestStatusFields.filter(({ showInTable }) => showInTable),
  detailViewCols: [], // requestStatusFields,
  fieldsMap: requestStatusFields.reduce(
    (acc, cur) => [
      ...acc,
      {
        dbGridName: cur.title,
        uiGridName: cur.path || cur.field,
        uiGridNameLowCase: (cur.path || cur.field).toLowerCase(),
        type: cur.type || 'string',
      },
    ],
    []
  ),
};

export function toLookups(data, initial = [], useEmpty = false) {
  if (!data) return initial;

  let items = [...initial, ...data];

  items = items.map(({ Id, Value }) => ({ value: Id, label: Value }));

  return uniqBy(items, 'value', useEmpty);
}

export const tabs = {
  requestType: {
    title: 'Request Type',
    name: 'requestType',
    type: 'RequestType',
    headers: requestTypeHeaders.headers,
    detailViewCols: requestTypeHeaders.detailViewCols,
    fieldsMap: requestTypeHeaders.fieldsMap,
    getItem: api.getDWLookup,
    getData: (params = {}) =>
      api.getDWLookup({
        ...params,
      }),
    save: api.postDWLookup,
  },
  assignedTo: {
    title: 'Assigned To',
    name: 'assignedTo',
    type: 'AssignedTo',
    headers: assignedToHeaders.headers,
    detailViewCols: assignedToHeaders.detailViewCols,
    fieldsMap: [],
    getItem: api.getDWLookup,
    getData: (params = {}) =>
      api.getDWLookup({
        ...params,
      }),
    save: api.postDWLookup,
  },
  reviewedBy: {
    title: 'Reviewed By',
    name: 'reviewedBy',
    type: 'ReviewedBy',
    headers: reviewedByHeaders.headers,
    detailViewCols: reviewedByHeaders.detailViewCols,
    fieldsMap: reviewedByHeaders.fieldsMap,
    getItem: api.getDWLookup,
    getData: (params = {}) =>
      api.getDWLookup({
        ...params,
      }),
    save: api.postDWLookup,
  },
  requestStatus: {
    title: 'Status',
    name: 'requestStatus',
    type: 'RequestStatus',
    headers: requestStatusHeaders.headers,
    detailViewCols: requestStatusHeaders.detailViewCols,
    fieldsMap: requestStatusHeaders.fieldsMap,
    getItem: api.getDWLookup,
    getData: (params = {}) =>
      api.getDWLookup({
        ...params,
      }),
    save: api.postDWLookup,
  },
};
