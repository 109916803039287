import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';

import { api } from 'utils/fetch';

import TextInput from 'components/common/ReactHookForm/TextInput';

import Autocomplete from 'components/Autocomplete';

import { toLookupsCustomers, createCustomer } from './utils';

import LocationPartial from './LocationPartial';

import './assets/style.scss';

let defaultCustomers = [];

function Fields({
  id,
  values,
  config,
  disabled,
  onChangeField,
  onHandleState,
  errors,
}) {
  const [customer, setCustomer] = useState(createCustomer(values));
  const [customerLoading, setCustomerLoading] = useState(true);
  const [customerOptions, serCustomerOptions] = useState([]);

  useEffect(() => {
    if (values.customerId === customer.id) return;

    setCustomer(createCustomer(values));
  }, [values.customerId]);

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      if (value === '') return onChangeField({ name, value: '' });

      if (name === 'numberModules' || name === 'modulePower') {
        const number = value ? Number(value) : 0;
        if (Number.isNaN(number) || number < 0) {
          return false;
        }
        if (name === 'numberModules') {
          value = parseInt(value, 10);
        } else if (`${number}`.split('.')[1]?.length > 1) {
          return false;
        }
      }

      return onChangeField({ name, value });
    },
    [onChangeField]
  );

  const onChangeAddress = useCallback(
    (name, value) => {
      return onChangeField({ name, value });
    },
    [onChangeField]
  );

  useEffect(async () => {
    onHandleState({ name: 'loaded', value: true });

    defaultCustomers = values.customers;

    let customers;

    try {
      customers = toLookupsCustomers(
        (await api.getEstimatesCustomers(id))?.data,
        defaultCustomers
      );
    } catch {
      customers = [];
    }

    serCustomerOptions(customers);
    setCustomerLoading(false);

    return () => {
      defaultCustomers.length = 0;
    };
  }, []);

  useEffect(() => {
    defaultCustomers = values.customers;
  }, [values.customers]);

  const onSelectCustomer = useCallback(
    (value) => {
      onChangeField({
        name: 'fields',
        value: {
          customerId: value?.id || null,
          customerName: value?.name || null,
        },
      });
    },
    [onChangeField]
  );

  return (
    <Row className="estimate-fields">
      <Col md={12} xxl={7}>
        <div className="form-group" style={{ width: '100%' }}>
          <Autocomplete
            className="customer-select"
            name="customerName"
            label={config.fields.customerName.ui.label}
            labelPosition="left"
            value={customer}
            options={customerOptions}
            disabled={disabled}
            loading={customerLoading}
            error={errors.customerName}
            onChange={onSelectCustomer}
          />
        </div>
        <TextInput
          label={config.fields.projectName.ui.label}
          labelPosition="left"
          labelWidth="30%"
          name="projectName"
          size="small"
          disabled={disabled}
          error={errors.projectName}
          onChange={onChange}
          onWheel={(event) => event.currentTarget.blur()}
          value={values.projectName}
          required
        />
        <LocationPartial
          touched={{}}
          showErrorLabel={false}
          errors={{}}
          label={config.fields.address.ui.label}
          labelWidth="30"
          values={{
            address: values.address,
            latitude: values.latitude,
            longitude: values.longitude,
            locationElevation: values.locationElevation,
            center: values.center,
            locationInfo: values.locationInfo,
          }}
          disabled={disabled}
          setFieldValue={onChangeAddress}
        />
        <TextInput
          label={config.fields.opportunity.ui.label}
          labelPosition="left"
          labelWidth="30%"
          name="opportunity"
          size="small"
          error={errors.opportunity}
          disabled={disabled}
          onChange={onChange}
          onWheel={(event) => event.currentTarget.blur()}
          value={values.opportunity}
        />
      </Col>
      <Col md={12} xxl={5}>
        <TextInput
          label={config.fields.numberModules.ui.label}
          labelPosition="left"
          labelWidth="52%"
          name="numberModules"
          size="small"
          disabled={disabled}
          onChange={onChange}
          error={errors.numberModules}
          onWheel={(event) => event.currentTarget.blur()}
          value={values.numberModules}
          type="text"
          min={0}
          required
        />
        <TextInput
          label={config.fields.modulePower.ui.label}
          labelPosition="left"
          labelWidth="52%"
          name="modulePower"
          size="small"
          disabled={disabled}
          onChange={onChange}
          onWheel={(event) => event.currentTarget.blur()}
          error={errors.modulePower}
          value={values.modulePower}
          type="text"
          required
        />
        <TextInput
          label={config.fields.systemPower.ui.label}
          labelPosition="left"
          labelWidth="52%"
          name="systemPower"
          size="small"
          disabled
          onChange={onChange}
          onWheel={(event) => event.currentTarget.blur()}
          value={values.systemPower}
          type="text"
          customValidate
          precision={8}
          min={0}
          max={100}
        />
      </Col>
    </Row>
  );
}

Fields.propTypes = {
  id: PropTypes.string,
  values: PropTypes.shape({
    projectName: PropTypes.string,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    locationElevation: PropTypes.string,
    center: PropTypes.string,
    locationInfo: PropTypes.string,
    opportunity: PropTypes.string,
    numberModules: PropTypes.string,
    modulePower: PropTypes.string,
    systemPower: PropTypes.string,
    customerId: PropTypes.string,
    customerName: PropTypes.string,
    customers: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  config: PropTypes.any,
  disabled: PropTypes.bool,
  onChangeField: PropTypes.func,
  onHandleState: PropTypes.func,
  errors: PropTypes.shape({
    customerName: PropTypes.string,
    projectName: PropTypes.string,
    opportunity: PropTypes.string,
    numberModules: PropTypes.string,
    modulePower: PropTypes.string,
  }),
};

Fields.defaultProps = {
  id: null,
  values: {
    projectName: null,
    address: null,
    latitude: null,
    longitude: null,
    locationElevation: null,
    center: null,
    locationInfo: null,
    opportunity: null,
    numberModules: '',
    modulePower: '',
    systemPower: '',
    customerId: null,
    customerName: null,
    customers: [],
  },
  config: {},
  disabled: false,
  errors: {},
  onChangeField: () => {},
  onHandleState: () => {},
};

export default connect()(Fields);
