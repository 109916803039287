function convertType(initialValue, type) {
  let value;
  switch (type) {
    case 'int':
      value = Number.parseInt(initialValue || 0, 10);
      break;
    case 'float':
      value = Number.parseFloat(initialValue || 0).toFixed(2);
      break;
    case 'date':
      value = new Date(initialValue).toLocaleDateString();
      break;
    default:
      value = initialValue;
  }
  return value;
}

function findUpEll(el, tagNme, keyWord) {
  while (el.parentNode) {
    el = el.parentNode;
    const attr = el.getAttribute(tagNme);
    if (attr && attr.includes(keyWord)) {
      return el;
    }
  }
  return null;
}

export { convertType, findUpEll };
