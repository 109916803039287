import { useTheme } from '@material-ui/core/styles';

function getThemeValue(name, props, values) {
  if (!Object.keys(props.theme).length) {
    props.theme = useTheme();
  }

  const value = props.theme && props.theme[name];

  let themeValue;

  if (typeof value === 'function') {
    themeValue = value(values);
  } else {
    themeValue = values[value];
  }

  if (typeof themeValue === 'function') {
    return themeValue(props);
  }
  return themeValue;
}

function theme(name, values) {
  return function (props) {
    return getThemeValue(name, props, values);
  };
}

export default theme;
