import React from 'react';
import PropTypes from 'prop-types';

export function CheckBoxInput({ fieldName, label, register }) {
  return (
    <div
      className="pc-checkbox"
      style={{ display: 'flex', height: '100%', alignItems: 'flex-end' }}
    >
      <div className="control-group">
        <div className="controls">
          <input
            className="custom-checkbox"
            type="checkbox"
            name={fieldName}
            {...register(fieldName)}
          />
          <label htmlFor={fieldName} className="control-label">
            {label}
          </label>
        </div>
      </div>
    </div>
  );
}

CheckBoxInput.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.func,
  register: PropTypes.func,
  error: PropTypes.string,
  min: PropTypes.number,
};

CheckBoxInput.defaultProps = {
  error: '',
  min: 0,
  fieldName: '',
  label: '',
  register: () => {},
};

export default CheckBoxInput;
