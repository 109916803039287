import {
  CHANGE_FILTERS,
  CHANGE_FILTER_OPTIONS,
  CHANGE_QUEUED_IMPORT_DATA,
  SET_SF_TABLES,
  SET_SF_HEADER,
  SET_SF_HIDED_COLUMNS,
  SET_HELLIOSCOPE_PROJECTS,
  SET_HELLIOSCOPE_DESIGNS,
  SET_HELLIOSCOPE_SEGMENTS,
  SET_HELLIOSCOPE_TABLE,
  SET_HS_HIDED_COLUMNS,
  SET_SF_SELECTED_TABLE_NAME,
  SET_APIINSPECTOR_HEADER,
  SET_APIINSPECTOR_HIDED_COLUMNS,
  SET_APIINSPECTOR_TABLES,
  SET_APIINSPECTOR_SELECTED_TABLE_NAME,
  SET_SOURCE_HEADER,
  SET_SOURCE_HIDED_COLUMNS,
  SET_SOURCE_TABLES,
  SET_SOURCE_SELECTED_TABLE_NAME,
} from '../actions/dataViewActions';

const initialState = {
  search: '',
  filters: {},
  sfHeaders: {},
  sfHidedColumns: {},
  sfTables: [],
  sfSelectedTableName: '',
  hellioscope: {
    headers: {
      projects: [],
      designs: [],
      segments: [],
    },
    tables: {
      projects: {},
      designs: {},
      segments: {},
    },
    currentTable: '',
    selectedId: null,
    hidedColumns: {
      projects: {},
      designs: {},
      segments: {},
    },
  },
  apiInspector: {
    headers: {},
    hidedColumns: {},
    tables: [],
    selectedTableName: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_FILTERS: {
      const { filter } = action;

      const newState = { ...state };
      const { [filter.name]: currentFilter, ...otherFilters } = newState[
        filter.tableName
      ].filters;
      const finalFilters =
        filter.value !== null && filter.value !== 'N/A'
          ? {
              [filter.name]: filter.value,
              ...otherFilters,
            }
          : { ...otherFilters };

      newState[filter.tableName] = {
        ...newState[filter.tableName],
        filters: { ...finalFilters },
        changedBy: 'filter',
      };

      return newState;
    }
    case CHANGE_FILTER_OPTIONS: {
      const { filterOptions } = action;

      const newState = { ...state };
      newState[filterOptions.tableName] = filterOptions.filterOptions;
      return newState;
    }
    case CHANGE_QUEUED_IMPORT_DATA: {
      const { queuedImportData } = action;
      return { ...state, queuedImportData };
    }

    case SET_SF_HEADER: {
      const { sfHeader, tableName } = action;
      return { ...state, sfHeaders: { [tableName]: sfHeader } };
    }

    case SET_SF_HIDED_COLUMNS: {
      const { sfHidedColumns } = action;
      return { ...state, sfHidedColumns };
    }

    case SET_SF_TABLES: {
      const { sfTables } = action;
      return { ...state, sfTables };
    }

    case SET_SF_SELECTED_TABLE_NAME: {
      const { sfSelectedTableName } = action;
      return { ...state, sfSelectedTableName };
    }

    case SET_HELLIOSCOPE_PROJECTS: {
      const { header, table } = action;
      const { hellioscope } = state;
      hellioscope.headers.projects = header;
      hellioscope.tables.projects = table;
      hellioscope.currentTable = 'projects';
      return { ...state, hellioscope };
    }

    case SET_HELLIOSCOPE_DESIGNS: {
      const { header, table, projectId } = action;
      const { hellioscope } = state;
      hellioscope.headers.designs = header;
      hellioscope.tables.designs[projectId] = table;
      hellioscope.currentTable = 'designs';
      hellioscope.selectedId = projectId;
      return { ...state, hellioscope };
    }

    case SET_HELLIOSCOPE_SEGMENTS: {
      const { header, table, designId } = action;
      const { hellioscope } = state;
      hellioscope.headers.segments = header;
      hellioscope.tables.segments[designId] = table;
      hellioscope.currentTable = 'segments';
      hellioscope.selectedId = designId;
      return { ...state, hellioscope };
    }

    case SET_HELLIOSCOPE_TABLE: {
      const { tableName, selectedId } = action;
      const { hellioscope } = state;
      hellioscope.currentTable = tableName;
      hellioscope.selectedId = selectedId;
      return { ...state, hellioscope };
    }

    case SET_HS_HIDED_COLUMNS: {
      const { hidedColumns } = action;
      const { hellioscope } = state;
      hellioscope.hidedColumns = hidedColumns;
      return { ...state, hellioscope };
    }

    case SET_APIINSPECTOR_HEADER: {
      const { header, tableName } = action;

      const { apiInspector } = state;

      apiInspector.headers = { [tableName]: header };

      return { ...state, apiInspector };
    }

    case SET_APIINSPECTOR_HIDED_COLUMNS: {
      const { hidedColumns } = action;
      const { apiInspector } = state;

      apiInspector.hidedColumns = hidedColumns;

      return { ...state, apiInspector };
    }

    case SET_APIINSPECTOR_TABLES: {
      const { tables } = action;
      const { apiInspector } = state;

      apiInspector.tables = tables;

      return { ...state, tables };
    }

    case SET_APIINSPECTOR_SELECTED_TABLE_NAME: {
      const { selectedTableName } = action;
      const { apiInspector } = state;

      apiInspector.selectedTableName = selectedTableName;

      return { ...state, selectedTableName };
    }

    case SET_SOURCE_HEADER: {
      const { source, header, tableName } = action;

      const target = state[source] || {};

      target.headers = { [tableName]: header };

      return { ...state, [source]: target };
    }

    case SET_SOURCE_HIDED_COLUMNS: {
      const { source, hidedColumns } = action;
      const target = state[source] || {};

      target.hidedColumns = hidedColumns;

      return { ...state, [source]: target };
    }

    case SET_SOURCE_TABLES: {
      const { source, tables } = action;
      const target = state[source] || {};

      target.tables = tables;

      return { ...state, [source]: target };
    }

    case SET_SOURCE_SELECTED_TABLE_NAME: {
      const { source, selectedTableName } = action;
      const target = state[source] || {};

      target.selectedTableName = selectedTableName;

      return { ...state, [source]: target };
    }

    default:
      return state;
  }
}
