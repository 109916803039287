import React from 'react';
import ShowIf from 'components/ShowIf';
import Button from '@material-ui/core/Button';
import PropsTypes from 'prop-types';
import {
  CREATE,
  CREATE_MODE,
  EDIT,
  EDIT_MODE,
  INSERT_MODE,
  VIEW,
  VIEW_MODE,
} from 'components/DetailView/constants';
import { CardTitleWrap, CardTitle } from '../common/Form/Card';

function DetailTitle({
  mode,
  title,
  handleClose,
  getExtraToolbar,
  tableProps: { title: gridTitle, detailViewMode },
  editMode,
  setMode,
  isAllowInDropdown,
}) {
  const toggleMode = () => {
    setMode(mode === VIEW ? EDIT : VIEW);
  };

  const isEditable = (currentMode) => {
    const formattedCurrentMode = currentMode.toLowerCase().trim();

    if (formattedCurrentMode === VIEW_MODE) return false;

    return !(
      [INSERT_MODE, CREATE_MODE].includes(formattedCurrentMode) ||
      isAllowInDropdown === false
    );
  };

  const titleString = mode === CREATE ? `${CREATE} ${title}` : title;

  return (
    <>
      <CardTitleWrap className="detail-view-title">
        <CardTitle>
          {titleString}
          <ShowIf condition={isEditable(editMode) && !detailViewMode}>
            <Button
              onClick={toggleMode}
              color="primary"
              style={{ marginLeft: '10px' }}
            >
              {mode === VIEW ? EDIT_MODE : VIEW_MODE}
            </Button>
          </ShowIf>
        </CardTitle>

        <div className="profile-wrapper__title">
          <button
            onClick={handleClose}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </CardTitleWrap>
      <div className="detail-view-tools">{getExtraToolbar(gridTitle)}</div>
    </>
  );
}

DetailTitle.propTypes = {
  mode: PropsTypes.string.isRequired,
  title: PropsTypes.string.isRequired,
  handleClose: PropsTypes.func.isRequired,
  getExtraToolbar: PropsTypes.func.isRequired,
  editMode: PropsTypes.string.isRequired,
  setMode: PropsTypes.func.isRequired,
  tableProps: PropsTypes.objectOf(PropsTypes.any).isRequired,
  isAllowInDropdown: PropsTypes.bool,
};

DetailTitle.defaultProps = {
  isAllowInDropdown: true,
};

export default DetailTitle;
