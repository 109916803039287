/* eslint-disable no-console */
import { api } from 'utils/fetch';
import { action } from '../_utils';

export const CIS_USER_ACTIVATE = 'CIS_USER_ACTIVATE';
export const CIS_USER_ACTIVATE_SUCCESS = 'CIS_USER_ACTIVATE_SUCCESS';
export const CIS_USER_ACTIVATE_FAIL = 'CIS_USER_ACTIVATE_FAIL';
export const NOTIFY_ACTIVATED_USER = 'NOTIFY_ACTIVATED_USER';

const activateCisUser = (data) => {
  const { CognitoId: id, ...rest } = data;
  return api.putCISUser(id, { data: { ...rest } });
};

const notifyCISUser = (data) => {
  const { FirstName, Email, LastName, AccountStage } = data;
  return api.postCISUserNotify({
    data: { FirstName, Email, LastName, AccountStage },
  });
};

const cisUsersActions = {
  aActivateCisUser: (data) => action(CIS_USER_ACTIVATE, { data }),
  aActivateCisUserSuccess: () => action(CIS_USER_ACTIVATE_SUCCESS, {}),
  aActivateCisUserFail: () => action(CIS_USER_ACTIVATE_FAIL, {}),
  aNotifyCISUser: () => action(NOTIFY_ACTIVATED_USER, {}),
  activateCisUser,
  notifyCISUser,
};

export default cisUsersActions;
