import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CardBody } from 'reactstrap';
import { Card, Col, Row } from 'react-bootstrap';

import Badge from '@material-ui/core/Badge';

import './assets/style.scss';

import { isObject } from 'utils';

import { ERROR_CUSTOM_MODULE_SINGLE } from 'containers/Estimates/Estimate/constants';

import Fields from '../Fields';
import Module from '../Module';
import ModuleList from '../ModuleList';
import FieldsExtended from '../FieldsExtended';

const stateComponents = {
  fields: {},
  module: {},
  moduleList: {},
  fieldsExtended: {},
};

function Details({
  id,
  values,
  isPowerCap,
  config,
  limitModules,
  disabled,
  disabledFields,
  disabledModuleFirstPart,
  disabledModuleSecondPart,
  disabledModuleList,
  disabledFieldsExtended,
  disabledExcludeDeflectors, // Deprecated: Deflector-less per CPD-2779
  onChange,
  onHandleState,
  showStepsBadges,
  canAddCustomModule,
  errors,
  defaultProps,
  blank,
}) {
  const [fields, setFields] = useState({});
  const [module, setModule] = useState({});
  const [modules, setModules] = useState([]);
  const [disabledModule, setModuleDisabled] = useState(true);
  const [disabledMountingSystem, setMountingSystemDisabled] = useState(true);
  const [fieldsExtended, setFieldsExtended] = useState({});

  useEffect(() => {
    return () => {
      stateComponents.fields = {};
      stateComponents.module = {};
      stateComponents.moduleList = {};
      stateComponents.fieldsExtended = {};
    };
  }, []);

  useEffect(() => {
    const formattedFields = {
      customerId: null,
      customerName: null,
      customers: [],
      projectName: null,
      address: null,
      latitude: null,
      longitude: null,
      locationElevation: null,
      center: null,
      locationInfo: null,
      opportunity: null,
      numberModules: '',
      modulePower: '',
      systemPower: '',
    };

    const formattedFieldsExtended = {
      discount: null,
      salesPerson: null,
      salesTaxRate: null,
      tier: null,
      mechanicalAttachment: null,
      manufacturer: null,
      membrane: null,
      stamp: null,
      includeShipping: true,
      includeSignature: true,
    };

    const formattedModule = {
      manufacturer: null,
      moduleParent: null,
      module: null,
      rowSpacing: null,
      mountingSystem: null,
      name: null,
      isCustom: null,
      excludeDeflectors: null, // Deprecated: Deflector-less per CPD-2779
    };

    let formattedModules = [];

    if (isObject(values)) {
      formattedFields.customerId = values.customerId;
      formattedFields.customerName = values.customerName;
      formattedFields.customers = values.customers;

      formattedFields.projectName = values.projectName;

      formattedFields.address = values.address;
      formattedFields.latitude = values.latitude;
      formattedFields.longitude = values.longitude;
      formattedFields.locationElevation = values.locationElevation;
      formattedFields.center = values.center;
      formattedFields.locationInfo = values.locationInfo;

      formattedFields.opportunity = values.opportunity;
      formattedFields.numberModules = values.numberModules;
      formattedFields.modulePower = values.modulePower;
      formattedFields.systemPower = values.systemPower;

      formattedModule.manufacturer = values.module.manufacturer;
      formattedModule.moduleParent = values.module.moduleParent;
      formattedModule.module = values.module.module;
      formattedModule.model = values.module.labels?.moduleParent;
      formattedModule.rowSpacing = values.module.rowSpacing;
      formattedModule.mountingSystem = values.module.mountingSystem;
      formattedModule.name = values.module.name;
      formattedModule.isCustom = values.module.isCustom;
      formattedModule.excludeDeflectors = values.excludeDeflectors; // Deprecated: Deflector-less per CPD-2779

      formattedModules = values.modules;

      formattedFieldsExtended.discount = values.discount || null;
      formattedFieldsExtended.salesTaxRate = values.salesTaxRate || null;
      formattedFieldsExtended.salesPerson = values.salesPerson || null;
      formattedFieldsExtended.tier = values.tier || null;
      formattedFieldsExtended.mechanicalAttachment =
        values.mechanicalAttachment || null;

      formattedFieldsExtended.manufacturer = values.manufacturer || null;
      formattedFieldsExtended.membrane = values.membrane || null;
      formattedFieldsExtended.stamp = values.stamp || null;
      formattedFieldsExtended.includeShipping = values.includeShipping;
      formattedFieldsExtended.includeSignature = values.includeSignature;
    }

    setFields(formattedFields);

    setModule(formattedModule);

    setModuleDisabled(
      formattedModules.length >= limitModules ||
        (values.modules.length === 1 && !!values.isSingleModuleEstimate) ||
        values.module.isCustom
    );

    setMountingSystemDisabled(formattedModules.length === 0);

    setModules(formattedModules);

    setFieldsExtended(formattedFieldsExtended);
    return () => {};
  }, [values]);

  const validateModuleList = () => {
    let i = -1;
    const n = modules.length;

    if (n >= limitModules) return true;

    while (++i < n) {
      if (modules[i].custom) {
        onChange({ error: ERROR_CUSTOM_MODULE_SINGLE });
        return true;
      }

      if (modules[i].id === module.moduleParent) {
        return true;
      }
    }

    return false;
  };

  const onUpdateField = ({ name, value }) => {
    onChange({ [name]: value });
  };

  const onUpdateModule = ({ name, value, label, data = {} }) => {
    onChange({
      module: {
        [name]: {
          value,
          label,
          data,
        },
      },
    });
  };

  const onAddModule = ({
    numberModules = 0,
    modulePower = 0,
    systemPower = 0,
    name,
  }) => {
    if (validateModuleList()) return;

    const formattedModules = [...modules];

    formattedModules.push({
      id: module.moduleParent,
      name: name ?? module.name,
      manufacturer: module.manufacturer,
      model: module.model,
      power: modulePower,
      metadata: {
        manufacturer: module.manufacturer,
        moduleParent: module.moduleParent,
        module: module.module,
        mountingSystem: module.mountingSystem,
        rowSpacing: module.rowSpacing,
        numberModules,
        modulePower,
        systemPower,
      },
    });

    onChange({
      modules: formattedModules,
    });
  };

  const onUpdateModules = ({ modules }) => {
    onChange({
      modules,
    });
  };

  const checkIfLoaded = () => {
    if (stateComponents.loaded) return;

    const { fields, module, moduleList, fieldsExtended } = stateComponents;

    stateComponents.loaded =
      fields.loaded &&
      module.loaded &&
      moduleList.loaded &&
      fieldsExtended.loaded;

    if (stateComponents.loaded) {
      onHandleState({ name: 'loaded', value: true });
    }
  };

  const onHandleStateFields = ({ name, value }) => {
    stateComponents.fields[name] = value;
    checkIfLoaded();
  };

  const onHandleStateModule = ({ name, value }) => {
    stateComponents.module[name] = value;
    checkIfLoaded();
  };

  const onHandleStateModuleList = ({ name, value }) => {
    stateComponents.moduleList[name] = value;
    checkIfLoaded();
  };

  const onHandleStateFieldsExtended = ({ name, value }) => {
    stateComponents.fieldsExtended[name] = value;
    checkIfLoaded();
  };

  return (
    <Card>
      <CardBody>
        {showStepsBadges && (
          <div className="card__title card__title_margin-left">
            <Badge
              color={disabled || disabledFields ? 'secondary' : 'primary'}
              badgeContent="1"
            />
          </div>
        )}
        <Row>
          <Fields
            id={id}
            values={fields}
            config={config}
            disabled={disabledFields || disabled}
            onChangeField={onUpdateField}
            onHandleState={onHandleStateFields}
            errors={errors}
          />
        </Row>
        <div className="line margin-top_40" />
        {showStepsBadges && (
          <div className="card__title card__title_margin-left">
            <Badge
              color={
                disabled || disabledModuleFirstPart ? 'secondary' : 'primary'
              }
              badgeContent="2"
            />
          </div>
        )}
        <Row className="margin-top_50">
          <Col md={12} xxl={6}>
            <Module
              values={module}
              config={config}
              onUpdateField={onUpdateModule}
              disabled={disabled}
              disabledModule={disabledModule}
              disabledMountingSystem={disabledMountingSystem}
              disabledModuleFirstPart={disabledModuleFirstPart}
              disabledModuleSecondPart={disabledModuleSecondPart}
              disabledExcludeDeflectors={disabledExcludeDeflectors} // Deprecated: Deflector-less per CPD-2779
              showStepsBadges={showStepsBadges}
              errors={errors}
              onAddModule={onAddModule}
              onHandleState={onHandleStateModule}
              showExcludeDeflectors={blank} // Deprecated: Deflector-less per CPD-2779
            />
          </Col>
          <Col className="module-list" md={12} xxl={6}>
            <ModuleList
              values={modules}
              limitModules={limitModules}
              disabled={disabledModuleList || disabled}
              disabledToolbar={disabledModuleFirstPart}
              onChange={onUpdateModules}
              onHandleState={onHandleStateModuleList}
              canAddCustomModule={canAddCustomModule}
              modulePower={fields.modulePower}
              defaultProps={defaultProps}
            />
          </Col>
        </Row>
        <div className="line margin-top_40" />
        <Row className="margin-top_50">
          <FieldsExtended
            values={fieldsExtended}
            config={config}
            isPowerCap={isPowerCap}
            disabled={disabledFieldsExtended || disabled}
            showStepsBadges={showStepsBadges}
            onChange={onUpdateField}
            onHandleState={onHandleStateFieldsExtended}
            errors={errors}
          />
        </Row>
      </CardBody>
    </Card>
  );
}

Details.propTypes = {
  errors: PropTypes.shape({
    customerName: PropTypes.string,
    projectName: PropTypes.string,
    opportunity: PropTypes.string,
    numberModules: PropTypes.string,
    modulePower: PropTypes.string,
    rowSpacing: PropTypes.string,
  }),
  id: PropTypes.string,
  values: PropTypes.shape({
    projectName: PropTypes.string,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    locationElevation: PropTypes.string,
    center: PropTypes.string,
    locationInfo: PropTypes.string,
    opportunity: PropTypes.string,
    numberModules: PropTypes.string,
    modulePower: PropTypes.string,
    systemPower: PropTypes.string,
    module: PropTypes.shape({
      manufacturer: PropTypes.string.isRequired,
      moduleParent: PropTypes.any,
      module: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      rowSpacing: PropTypes.number,
      mountingSystem: PropTypes.string.isRequired,
      name: PropTypes.string,
      model: PropTypes.string,
      isCustom: PropTypes.bool,
    }),
    modules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    customerId: PropTypes.string,
    customerName: PropTypes.string,
    customers: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  config: PropTypes.any,
  limitModules: PropTypes.number,
  disabled: PropTypes.bool,
  disabledFields: PropTypes.bool,
  disabledModuleFirstPart: PropTypes.bool,
  disabledModuleSecondPart: PropTypes.bool,
  disabledExcludeDeflectors: PropTypes.bool, // Deprecated: Deflector-less per CPD-2779
  disabledModuleList: PropTypes.bool,
  disabledFieldsExtended: PropTypes.bool,
  showStepsBadges: PropTypes.bool,
  canAddCustomModule: PropTypes.bool,
  onChange: PropTypes.func,
  onHandleState: PropTypes.func,
  defaultProps: PropTypes.shape({
    estShippingCostMod: PropTypes.number,
    customModule: PropTypes.shape({
      dataSheetUrl: PropTypes.string,
      moduleDimensions: PropTypes.shape({
        pl: PropTypes.number,
        pt: PropTypes.number,
        pw: PropTypes.number,
        apt: PropTypes.number,
        w: PropTypes.number,
      }),
    }),
  }),
  blank: PropTypes.bool,
  isPowerCap: PropTypes.bool,
};

Details.defaultProps = {
  id: null,
  values: {
    projectName: null,
    address: null,
    latitude: null,
    longitude: null,
    locationElevation: null,
    center: null,
    locationInfo: null,
    opportunity: null,
    numberModules: '',
    modulePower: '',
    systemPower: '',
    module: {
      manufacturer: '',
      moduleParent: '',
      module: '',
      model: '',
      rowSpacing: null,
      mountingSystem: '',
      name: '',
      isCustom: false,
    },
    modules: [],
    customerId: null,
    customerName: null,
    customers: [],
  },
  config: {},
  limitModules: 4,
  disabled: false,
  disabledFields: false,
  disabledModuleFirstPart: false,
  disabledModuleSecondPart: false,
  disabledExcludeDeflectors: true, // Deprecated: Deflector-less per CPD-2779
  disabledModuleList: false,
  disabledFieldsExtended: false,
  showStepsBadges: false,
  canAddCustomModule: false,
  errors: {},
  onChange: () => {},
  onHandleState: () => {},
  defaultProps: {},
  blank: false,
  isPowerCap: false,
};

export default connect()(Details);
