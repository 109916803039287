import React from 'react';
import { Container, Row } from 'reactstrap';

import MaterialTable from 'components/MaterialTable';
import { api } from 'utils/fetch';
import { getAclsHeaders } from './utils';

class Acls extends React.Component {
  constructor(props) {
    super(props);
    const { headers, detailViewCols } = getAclsHeaders();
    this.headers = headers;
    this.detailViewCols = detailViewCols;
  }

  render() {
    return (
      <Container className="acls-page">
        <Row>
          <MaterialTable
            tableProps={{
              title: 'ACLs',
              detailViewTittle: 'ACL',
              actions: {},
              canDelete: true,
              name: 'Acls',
            }}
            middleToolbar={null}
            columns={this.headers}
            fullScreenDetailView
            // remoteData - TODO on backend side
            getData={async (param) => {
              // const newParams = inlineFilters(param);
              const result = await api.getAcls(param);
              return {
                ...result,
                count: result.data.pagination.totalItems,
                data: result.data,
              };
            }}
            detailsView={{
              cols: this.detailViewCols,
              onDelete: api.deleteAcl,
              onSave: api.putAcl,
              idFieldName: 'Id',
            }}
          />
        </Row>
      </Container>
    );
  }
}

export default Acls;
