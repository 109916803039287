import React from 'react';
import Button from 'components/common/Button';
import PropTypes from 'prop-types';

import './assets/styles.scss';

function Toolbar({
  disabledCancel,
  cancelLabel,
  onCancel,
  disabledSave,
  saveLabel,
  loadingSave,
  onSave,
  className,
}) {
  const rootClassName = className ? `toolbar ${className}` : 'toolbar';

  return (
    <div className={rootClassName}>
      <Button
        disabled={disabledCancel}
        onClick={onCancel}
        className="btn btn-secondary btn-lg"
      >
        {cancelLabel}
      </Button>
      <Button
        className="btn btn-primary btn-lg"
        loading={loadingSave}
        disabled={disabledSave}
        onClick={onSave}
      >
        {saveLabel}
      </Button>
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
  disabledCancel: PropTypes.bool,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  disabledSave: PropTypes.bool,
  saveLabel: PropTypes.string,
  onSave: PropTypes.func,
  loadingSave: PropTypes.bool,
};

Toolbar.defaultProps = {
  className: '',
  disabledCancel: true,
  cancelLabel: 'Cancel',
  onCancel: () => {},
  disabledSave: true,
  saveLabel: 'Save',
  loadingSave: false,
  onSave: () => {},
};

export default Toolbar;
