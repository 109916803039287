import React from 'react';
import PropsTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from 'mdi-react/CloseIcon';

function DialogForm({
  isOpen,
  title,
  container,
  className = '',
  actions,
  handleClose,
}) {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className={className}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="closeIcon"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{container}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </div>
  );
}

DialogForm.propTypes = {
  title: PropsTypes.string,
  isOpen: PropsTypes.bool,
  handleClose: PropsTypes.func.isRequired,
  className: PropsTypes.string,
  container: PropsTypes.element,
  actions: PropsTypes.element,
};

DialogForm.defaultProps = {
  title: '',
  isOpen: false,
  className: '',
  container: null,
  actions: null,
};

export default DialogForm;
