import React, { useEffect, useMemo, useState } from 'react';
import { getObjProp, isObject } from 'utils';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { CREATE, EDIT, NEVER, VIEW } from 'components/DetailView/constants';
import {
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '../common/Form/FormElements';
import { getField } from './utils';

const getError = (errors, field) => {
  if (!errors || !errors[field]) return null;
  return <p className="detail-view-field-error">{errors[field]}</p>;
};

const getCharCount = (field, value) => {
  if (field === 'RequestNotes') return value.length;
  return false;
};

const getFiledLabel = (col) => {
  const { title, validations } = col;

  if (isObject(title)) {
    return title;
  }

  const isRequired = validations?.required;

  return (
    <FormGroupLabel className={col.className ? col.className : ''}>{`${title}${
      isRequired ? ' *' : ''
    }`}</FormGroupLabel>
  );
};

const isEditableField = (fields, condition) => {
  if (!condition) return false;

  return !Object.entries(condition).some(([key, value]) => {
    return fields[key] !== value;
  });
};

function DetailFieldComponent({
  col,
  fieldsValues,
  errors,
  mode,
  loading,
  onChange: onChangeField,
  conditionForEdit,
  extraFields,
}) {
  const {
    field,
    title,
    path,
    type,
    format,
    lookup,
    editable,
    exType,
    defaultValue,
    headers,
    listFields,
    options,
    cellEditable,
    createModeFromList,
  } = { ...col, ...extraFields };

  if (
    (mode === CREATE &&
      (col.hideInCreate || editable === 'never') &&
      !createModeFromList) ||
    ((mode === EDIT || mode === VIEW) && col.hideInEdit)
  ) {
    return null;
  }

  const [items, setItems] = useState([]);

  const createMenuItems = (lookup = []) => {
    const options = [];

    if (Array.isArray(lookup)) {
      if (Array.isArray(lookup[0])) {
        lookup.forEach((x) => {
          options.push({ value: x[1], label: x[1] });
        });
      } else if (
        Object.prototype.toString.call(lookup[0]) === '[object Object]'
      ) {
        lookup.forEach((x) => {
          const item = { value: x.id, label: x.name };
          if (x.value) {
            item.value = x.value;
          }
          options.push(item);
        });
      }
    } else if (isObject(lookup)) {
      Object.entries(lookup).forEach((x) => {
        options.push({ value: x[1], label: x[1] });
      });
    }

    setItems(options);
  };

  useEffect(() => {
    if (typeof lookup === 'function') {
      (async () => {
        createMenuItems((await lookup(fieldsValues))?.data);
      })();
    } else {
      createMenuItems(lookup);
    }
  }, [lookup, fieldsValues]);

  const isReadOnly =
    (editable === NEVER && !isEditableField(fieldsValues, conditionForEdit)) ||
    mode === VIEW ||
    loading;

  const value = useMemo(
    () =>
      getObjProp(
        fieldsValues,
        path || field,
        mode === CREATE ? defaultValue || '' : '-'
      ),
    [fieldsValues, path, field]
  );

  const error = getError(errors, path || field);
  const charCount = getCharCount(path || field, value);

  const inputField = getField({
    path,
    field,
    title,
    type,
    format,
    exType,
    value,
    isReadOnly,
    onChangeField,
    items,
    mode,
    defaultValue,
    fieldsValues,
    headers,
    listFields,
    options,
    cellEditable,
  });

  return (
    <FormGroup className="detail-view-form-group">
      {getFiledLabel(col)}
      <FormGroupField>
        <Field name={path || field}>
          {() => (
            <div
              className={`detail-view-field ${
                error ? 'detail-view-field-highlight-error' : ''
              }`}
            >
              {inputField}
            </div>
          )}
        </Field>
      </FormGroupField>
      {error}
      {charCount && (
        <span
          style={
            charCount > 255
              ? { color: 'red', margin: '10px 0px -10px 8px' }
              : {}
          }
        >{`${charCount}/255`}</span>
      )}
    </FormGroup>
  );
}

DetailFieldComponent.propTypes = {
  col: PropTypes.object.isRequired,
  fieldsValues: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  extraFields: PropTypes.object,
};

export default DetailFieldComponent;
