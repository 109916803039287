import './style.scss';

const getAclsHeaders = () => {
  const fields = [
    {
      field: 'Id',
      title: 'Id',
      editable: 'never',
      filtering: false,
      sorting: false,
    },
    {
      field: 'Name',
      title: 'Name',
      editable: 'never',
      showInTable: true,
      searchable: true,
      validations: {
        required: true,
      },
    },
    {
      field: 'Route',
      title: 'Route',
      showInTable: true,
      searchable: true,
      validations: {
        required: false,
      },
    },
    {
      field: 'UpdatedByEmail',
      title: 'Updated By',
      showInTable: true,
      filtering: true,
      sorting: true,
    },
    {
      field: 'UpdatedAt',
      title: 'Updated At',
      showInTable: true,
      filtering: true,
      sorting: true,
      render: (row = {}) =>
        row.UpdatedAt ? new Date(row.UpdatedAt).toLocaleString('en-US') : '',
    },
    {
      field: 'IsBackend',
      title: 'Is Backend',
      showInTable: true,
      type: 'boolean',
    },
  ];
  return {
    headers: fields.filter(({ showInTable }) => showInTable),
    detailViewCols: fields,
  };
};

export { getAclsHeaders };
