import {
  DELETE,
  FILE_NAME_ERROR_INVALID_CHARACTERS,
} from 'components/common/utils/constants';
import { api } from 'utils/fetch';

import { containsFileNameSpecialChars } from 'utils';

const validateFileName = (value) => {
  const valid = !containsFileNameSpecialChars(value);
  return { valid, message: FILE_NAME_ERROR_INVALID_CHARACTERS };
};

const getActions = (projectName, note, id) => [
  {
    label: 'Export Excel',
    modalTitle: 'Export Excel',
    text: 'Select name',
    actionLabel: 'Export',
    defaultValue: `${projectName} - ${note} ${new Date().toLocaleDateString(
      'fr-CA'
    )}`, // "fr-CA" for format yyyy-mm-dd
    placeholder: 'Export file name',
    fileFormat: '.xlsx',
    validate: validateFileName,
    action: (value) => {
      //
      const state = validateFileName(value);

      if (!state.valid) {
        throw new Error(state.message);
      }

      return api.exportEstimate({
        data: {
          filename: `${value}.xlsx`,
          estimateId: id,
        },
      });
    },
  },
  {
    label: 'Export PDF',
    modalTitle: 'Export PDF',
    actionLabel: 'Export',
    defaultValue: `${projectName} - ${note} ${new Date().toLocaleDateString(
      'fr-CA'
    )}`, // "fr-CA" for format yyyy-mm-dd
    text: 'Select name',
    placeholder: 'Export file name',
    fileFormat: '.pdf',
    borderBottom: true,
    validate: validateFileName,
    action: (value) => {
      const state = validateFileName(value);

      if (!state.valid) {
        throw new Error(state.message);
      }

      return api.exportEstimate({
        data: {
          filename: `${value}.pdf`,
          estimateId: id,
        },
      });
    },
  },
  {
    label: 'Delete',
    type: DELETE,
    actionLabel: 'Delete',
    modalTitle: 'Delete',
    text: 'Are you sure you want to delete this estimate?',
    className: 'deleteDialog',
    action: () => api.deleteEstimateDetails(id),
  },
];

const formattingText = (notes, limitLength) => {
  return notes.length > limitLength
    ? `${notes.substring(0, limitLength)}...`
    : notes;
};

export { getActions, formattingText };
