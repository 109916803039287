import { DELETE } from 'components/common/utils/constants';
import { api } from 'utils/fetch';

export const getEstimatesHeaders = () => {
  const fields = [
    {
      field: 'projectName',
      title: 'Project Name',
      showInTable: true,
    },
    {
      field: 'customerName',
      title: 'Customer',
      showInTable: true,
      searchable: true,
    },
    {
      field: 'preparedBy',
      title: 'Prepared By',
      searchable: true,
      showInTable: true,
    },
    {
      field: 'salesPerson',
      title: 'Salesperson',
      searchable: true,
      showInTable: true,
    },
    {
      field: 'variantsCount',
      title: '# of estimates',
      searchable: false,
      showInTable: true,
      sorting: false,
      filtering: false,
    },
  ];
  return {
    headers: fields.filter(({ showInTable }) => showInTable),
    detailViewCols: fields,
  };
};

export const getDeleteAction = (id) => {
  return {
    label: 'Delete',
    type: DELETE,
    actionLabel: 'Delete',
    modalTitle: 'Delete',
    text:
      'Are you sure you want to delete this estimate? (NOTE: Estimate variants will be also deleted!)',
    className: 'deleteDialog',
    metadata: { id },
    action: () => api.deleteEstimateList(id),
  };
};
