const config = {
  isEditable: false,
  isSelectable: true,
  isSortable: false,
  isResizable: false,
  withPagination: true,
  withFooter: false,
  withSearchEngine: true,
  withActions: false,
  manualPageSize: [10, 20, 30, 40],
  placeholder: 'Search...',
};

export default config;
