import React from 'react';
import PropTypes from 'prop-types';
import TableActions from 'components/common/Table/TableActions';
import SelectBar from '../SelectBar';

export function CallMade({ propName, value, list, onChange }) {
  return (
    <SelectBar
      value={value}
      propName={propName}
      list={list}
      onChange={onChange}
    />
  );
}

export const selectTool = ({
  name,
  onFilter,
  tableName,
  value,
  list,
  onChange,
}) => ({
  name,
  icon: (
    <CallMade
      onFilter={onFilter}
      tableName={tableName}
      value={value}
      list={list}
    />
  ),
  onChange: () => onChange(),
});

export const actionSelect = ({ tableActions, styles }) => {
  const params = {
    name: 'Actions',
    icon: <TableActions actions={tableActions} />,
    styles,
    className: 'actionSelectBar',
  };

  if (typeof onCreate === 'function') {
    params.onClick = () => {};
  }

  params.onClick = () => {};

  return params;
};

CallMade.propTypes = {
  propName: PropTypes.string,
  value: PropTypes.string,
  list: PropTypes.shape({}),
  onChange: PropTypes.func,
};

CallMade.defaultProps = {
  propName: '',
  value: '',
  list: [],
  onChange: () => {},
};
