import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';

function CheckboxFilter({ name, onFilter, propsValue, tableName }) {
  const [checked, setChecked] = useState(false);
  const [isDefault, setDefault] = useState(true);
  const onChange = useCallback(
    (value) => onFilter({ name, value, tableName }),
    [name, onFilter]
  );

  useEffect(() => {
    // TODO check why isDefault resets to default "true" value during checkbox transition true->false
    if (propsValue !== null) {
      setDefault(false);
    }

    if (propsValue !== checked) {
      if (propsValue === null) {
        setDefault(true);
        setChecked(false);
      } else {
        setDefault(false);
        setChecked(propsValue);
      }
    }
  }, [propsValue]);

  const handleChange = useCallback(() => {
    if (isDefault) {
      setDefault(false);
      setChecked(true);
      return onChange(true);
    }
    if (checked) {
      setChecked(false);
      return onChange(false);
    }
    setDefault(true);
    setChecked(null);
    return onChange(null);
  }, [isDefault, checked]);

  return (
    <Checkbox
      size="small"
      checked={checked}
      indeterminate={isDefault}
      onChange={handleChange}
    />
  );
}

const mapStateToProps = ({ dataView }, { name, tableName }) => {
  const val =
    dataView &&
    dataView[tableName] &&
    dataView[tableName].filters &&
    dataView[tableName].filters[name] !== undefined
      ? dataView[tableName].filters[name]
      : null;

  return {
    propsValue: val,
  };
};

export default connect(mapStateToProps)(CheckboxFilter);

CheckboxFilter.propTypes = {
  name: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line
  propsValue: PropTypes.bool,
  tableName: PropTypes.string.isRequired,
};
