import React from 'react';
import PropTypes from 'prop-types';
import MUButton from '@material-ui/core/Button';

import Spinner from './Spinner';

const defaultStyle = {
  position: 'absolute',
  marginLeft: '-31px',
  marginTop: '-2px',
};

const spinnerStyles = {
  default: defaultStyle,
  basic: {
    ...defaultStyle,
    marginLeft: '-36px',
    marginTop: '5px',
  },
  primary: defaultStyle,
  success: defaultStyle,
  error: defaultStyle,
  'pcd-success': defaultStyle,
  'pcd-grey': defaultStyle,
};

function Button(props) {
  const {
    children,
    type,
    theme,
    loading,
    onClick,
    disabled,
    className,
    customLoadingStyle,
    useMUButton,
    color,
    ...rest
  } = props;

  return useMUButton ? (
    <MUButton color={color} onClick={onClick}>
      {loading && (
        <span style={customLoadingStyle || spinnerStyles[theme]}>
          <Spinner size={24} />
        </span>
      )}
      {children}
    </MUButton>
  ) : (
    <button
      type={type}
      className={className || `btn btn-${theme} pcbtn pcbtn-${theme}`}
      onClick={onClick}
      disabled={disabled || loading}
      style={{ position: 'relative', marginRight: 10 }}
      {...rest}
    >
      {loading && (
        <span style={customLoadingStyle || spinnerStyles[theme]}>
          <Spinner size={24} />
        </span>
      )}
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  customLoadingStyle: PropTypes.object,
  useMUButton: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  theme: 'default',
  color: '',
  useMUButton: false,
  disabled: false,
  loading: false,
  onClick: () => {},
  className: '',
};

export default Button;
