import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import { thousandSeparator } from 'utils';

function ExtendedTotalComponent({ data }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Collapse in={open}>
        <div className="analytic__total">
          {data.map((item) => {
            const [value, label] = item.split(',');
            return (
              <p key={label} className="analytic__total-stat">
                {`${label}: ${thousandSeparator(value)}`}
              </p>
            );
          })}
        </div>
      </Collapse>
      <div className="analytic__total-extand-button">
        <IconButton
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
    </>
  );
}

ExtendedTotalComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
};

ExtendedTotalComponent.defaultProps = {
  data: [],
};

export default ExtendedTotalComponent;
