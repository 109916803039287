import Link from '@material-ui/core/Link';
import React from 'react';
import { Icon } from '@material-ui/core';
import './style.scss';

import { api } from 'utils/fetch';
import DownloadIcon from './assets/download.png';

export const Download = () => (
  <Icon
    className="sales-icon"
    style={{ overflow: 'visible', textAlign: 'center' }}
  >
    <img src={DownloadIcon} alt="DownloadIcon" />
  </Icon>
);

export const onClickDataSheetUrl = async (revisionId) => {
  try {
    const {
      data: { url },
    } = await api.getDeliverables(revisionId);
    window.open(url);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

function getStatuses() {
  return {
    successful: 'Successful',
    failed: 'Failed',
    pending: 'Pending',
    requested: 'Requested',
    incomplete: 'Incomplete',
  };
}

function getMountingSystems() {
  return {
    5: 'clawFR 5 Degree',
    10: 'clawFR 10 Degree',
    dt10: 'clawFR 10 Degree Dual Tilt',
  };
}

let tenants;

const getSalesHeaders = () => {
  const fields = [
    {
      field: 'name',
      title: 'Project Name',
      showInTable: true,
      filtering: true,
      sortable: true,
    },
    {
      field: 'tenantName',
      title: 'Tenant',
      showInTable: true,
      exType: 'select',
      filter: async () => {
        if (!tenants) {
          tenants = (await api.getAllTenants()).data.data;
        }
        const formattedTenants = [];

        if (Array.isArray(tenants)) {
          tenants.forEach(({ name }) => {
            formattedTenants.push({
              id: name,
              name,
            });
          });
        }

        return { data: formattedTenants };
      },
      render: (row = {}) => <span>{row.tenantName}</span>,
    },
    {
      field: 'address',
      title: 'Address',
      showInTable: true,
      sortable: true,
    },
    {
      field: 'owner',
      title: 'Project Owner',
      filtering: true,
      showInTable: false,
      sortable: true,
    },
    {
      field: 'revisionNumber',
      title: 'Revision #',
      type: 'numeric',
      showInTable: false,
      sortable: true,
    },
    {
      field: 'noOfRevisions',
      title: 'Total Revisions',
      type: 'numeric',
      showInTable: true,
      sorting: false,
      filtering: false,
    },
    {
      field: 'owner',
      showInTable: true,
      title: 'Project Owner',
      sortable: true,
    },
    {
      field: 'tag',
      showInTable: true,
      title: 'Oppy/Tag',
      filtering: true,
      sortable: true,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      field: 'createdAt',
      showInTable: true,
      title: 'Created Date',
      type: 'date',
      filtering: false,
      sortable: true,
      cellStyle: {
        width: '50px',
        maxWidth: '50px',
      },
    },
    {
      field: 'preparedBy',
      showInTable: true,
      title: 'Created By',
      sortable: true,
    },
    {
      field: 'status',
      showInTable: true,
      title: 'Status',
      sortable: true,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      exType: 'select',
      lookup: (() => getStatuses())(),
      render: (row = {}) => <span>{row.status}</span>,
    },
    {
      field: 'mountingSystem.Name',
      showInTable: true,
      title: 'Mounting System',
      sortable: true,
      exType: 'select',
      lookup: (() => getMountingSystems())(),
      render: (row = {}) => <span>{row.mountingSystem.Name}</span>,
    },
    {
      field: 'module.manufacturer',
      showInTable: true,
      title: 'Module',
      sortable: true,
    },
    {
      field: 'system.systemPower',
      showInTable: true,
      title: 'System Power',
      sortable: true,
    },
    {
      field: 'dataSheetUrl',
      title: 'Reports',
      searchable: false,
      showInTable: true,
      sorting: false,
      filtering: false,
      hideInEdit: true,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      validations: {
        validate: (data) => {
          if (data.availableInDropdown === true && !data.dataSheetUrl) {
            return 'Data Sheet Url is required if Available in dropdown';
          }
          return false;
        },
      },

      //! Should be refactored. Component definition is missing display name.
      /* eslint-disable */
      render: (row = {}) => {
          return (
              <>
                  {row.status.toLowerCase() === "successful" ? <Link
                      component="button"
                      className="data-sheet-url"
                      onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onClickDataSheetUrl(row['_id'])
                      }}
                  >
                      <Download/>
                  </Link> : '---'}
              </>
          );
      },
    /* eslint-enable */
    },
  ];
  return {
    headers: fields.filter(({ showInTable }) => showInTable),
    detailViewCols: fields,
  };
};

export { getSalesHeaders };
