import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';

import { post } from 'utils/request';

function registerUser(name, email, password) {
  const url = `signup`;

  const data = {
    name,
    email,
    password,
  };

  return post(url, JSON.stringify(data));
}

function changeUserPassword(oldPassword, newPassword) {
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  });

  const currentUser = userPool.getCurrentUser();

  if (currentUser != null) {
    return new Promise((resolve, reject) => {
      currentUser.getSession((err, session) => {
        if (err) {
          return err;
        }
        return session;
      });

      currentUser.changePassword(oldPassword, newPassword, (error, session) => {
        if (error) {
          reject(error);
        } else {
          resolve(session);
        }
      });
    });
  }

  return null;
}

function authenticateUser(email, password) {
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  });

  const user = new CognitoUser({ Username: email, Pool: userPool });
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
}

function getUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  });

  const currentUser = userPool.getCurrentUser();

  if (currentUser != null) {
    return new Promise((resolve, reject) => {
      currentUser.getSession((error, session) => {
        if (error) {
          reject(error);
        } else {
          resolve(session);
        }
      });
    });
  }

  return null;
}

function refreshSession(refreshToken) {
  const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  });

  const currentUser = userPool.getCurrentUser();

  if (currentUser != null) {
    return new Promise((resolve, reject) => {
      currentUser.refreshSession(RefreshToken, (error, session) => {
        if (error) {
          reject(error);
        } else {
          resolve(session);
        }
      });
    });
  }

  return null;
}

export {
  registerUser,
  authenticateUser,
  getUser,
  refreshSession,
  changeUserPassword,
};
