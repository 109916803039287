export default {
  fields: {
    mountingSystem: {
      type: 'string',
      required: true,
    },
    manufacturer: {
      type: 'string',
      required: true,
    },
    modelName: {
      type: 'string',
      required: true,
    },
    W: {
      type: 'number',
      required: false,
    },
    power: {
      type: 'int',
      required: true,
    },
    dataSheetUrl: {
      type: 'string',
      required: false,
    },
    PL: {
      type: 'int',
      required: true,
    },
    PW: {
      type: 'int',
      required: true,
    },
    APT: {
      type: 'int',
      required: false,
    },
  },
};
