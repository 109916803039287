import React from 'react';
import { Spinner } from 'reactstrap';
import 'chart.js/auto';
import DatePickerComponent from 'containers/DesignerWorkflow/Tools/DatePicker/DatePicker';
import IconButton from '@material-ui/core/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AnalyticChart from './components/AnalyticChart';
import ChartKPI from './components/ChartKPI';

export const prepareData = (data = [], YAxisName = '') => {
  const labels = [];
  const values = [];
  let i = -1;
  const n = data.length;
  while (++i < n) {
    const barData = data[i];
    labels.push(barData.X);
    values.push(barData.Y);
  }
  return {
    labels,
    datasets: [
      {
        label: YAxisName,
        data: values,
      },
    ],
  };
};

export const loadingSpin = (isLoading) => {
  if (isLoading) {
    return (
      <div className="spinner">
        <Spinner
          animation="border"
          role="status"
          color="primary"
          type="border"
          size="sm"
        >
          {''}
        </Spinner>
      </div>
    );
  }
  return null;
};

export function getKPIChartComponent(
  title,
  data = [],
  categoryTitle,
  handleDateChange,
  selectedDates,
  isFilterable,
  isLoading = false
) {
  const withDropDown = Array.isArray(data);
  const total = withDropDown
    ? data.reduce((acc, item) => acc + +item.split(',')[0], 0)
    : data;
  return (
    <ChartKPI
      title={title}
      total={total}
      data={data}
      isFilterable={isFilterable}
      isLoading={isLoading}
      withDropDown={withDropDown}
      categoryTitle={categoryTitle}
      selectedDates={selectedDates}
      handleDateChange={handleDateChange}
    />
  );
}

export const getChartComponent = (
  title,
  data,
  isLoading,
  selectedDates,
  handleDateChange
) => {
  return (
    <AnalyticChart
      title={title}
      data={data}
      handleDateChange={handleDateChange}
      selectedDates={selectedDates}
      isLoading={isLoading}
    />
  );
};

export const getFilterRangeComponent = ({
  openDatePicker,
  selectedDates,
  dateLabel,
  setOpenDatePicker,
  handleDateChange,
}) => (
  <div>
    {openDatePicker && (
      <DatePickerComponent
        dateFrom={selectedDates?.from}
        dateTo={selectedDates?.to}
        handleDateChange={(newDateFrom, NewDateTo) => {
          handleDateChange(newDateFrom, NewDateTo);
          setOpenDatePicker(false);
        }}
        setOpenDatePicker={setOpenDatePicker}
      />
    )}
    <div className="date-picker">
      <IconButton onClick={() => setOpenDatePicker(!openDatePicker)}>
        <CalendarMonthIcon />
      </IconButton>
      <span className="date-picker-range">{dateLabel}</span>
    </div>
  </div>
);
