import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { FormGroup, FormGroupField } from 'components/common/Form/FormElements';
import { getField, getFiledLabel } from './utils';

function DetailFieldComponent({
  fieldName,
  field,
  fieldValue,
  additionalFieldValue,
  onChange,
}) {
  const { type, lookup, label, options } = field;

  const [items, setItems] = useState([]);

  const createMenuItems = (lookup = []) => {
    const lookupItems = [];

    if (Array.isArray(lookup)) {
      if (Object.prototype.toString.call(lookup[0]) === '[object Object]') {
        lookup.forEach((x) => {
          const item = { value: x.value, label: x.name };
          lookupItems.push(item);
        });
      }
    }

    setItems(lookupItems.sort((a, b) => a.label.localeCompare(b.label)));
  };

  useEffect(() => {
    if (typeof lookup === 'function') {
      (async () => {
        createMenuItems((await lookup())?.data);
      })();
    } else {
      createMenuItems(lookup);
    }
  }, [lookup]);

  const inputField = getField(
    fieldName,
    type,
    items,
    fieldValue,
    onChange,
    label,
    options,
    additionalFieldValue
  );

  return (
    <FormGroup className="filter-form-group">
      {getFiledLabel(label)}
      <FormGroupField>
        <Field name={fieldName}>
          {() => <div className="filter-field">{inputField}</div>}
        </Field>
      </FormGroupField>
    </FormGroup>
  );
}

DetailFieldComponent.propTypes = {
  fieldName: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  fieldValue: PropTypes.string.isRequired,
  additionalFieldValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default DetailFieldComponent;
