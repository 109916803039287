import React from 'react';
import PropTypes from 'prop-types';

import { btnStyles } from './styles';

function Button({ size, text, onClick, disabled }) {
  return (
    <span className="input-group-btn">
      <button
        onClick={onClick}
        style={btnStyles[size]}
        className="btn btn-default"
        type="button"
        disabled={disabled}
      >
        {text}
      </button>
    </span>
  );
}

Button.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
};

export default Button;
