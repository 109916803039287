import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { post } from 'utils/request';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import SelectInput from 'components/common/SelectInput';
import TextArea from 'components/common/TextArea';
import Alert from 'components/common/Alert';

class FeedbackForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { type: 'FEEDBACK', category: null, message: '', error: null };
  }

  onClose = () => {
    const { onClose } = this.props;
    this.setState({
      type: 'FEEDBACK',
      category: null,
      message: '',
      error: null,
    });
    onClose();
  };

  setFieldValue = (key, val) => {
    const { state } = this;
    state[key] = val;
    this.setState({ ...state });
  };

  check = (type) => {
    this.setState({
      type,
    });
  };

  handleChnage = (evt) => {
    const message = evt.target.value;
    this.setState({ message });
  };

  submitFeedback = (data) => {
    const { user } = this.props;
    const userId = user && user._id;
    const { type, category, message } = data;

    return new Promise((resolve, reject) => {
      post(
        `user/${userId}/feedback`,
        JSON.stringify({ type, category, message })
      )
        .then(() => {
          this.onClose();
          resolve();
        })
        .catch((error) => {
          const errorMsg = (error && error.message) || 'Request has failed!';
          this.setState({ error: errorMsg });
          reject();
        });
    });
  };

  render() {
    const { isOpen } = this.props;
    const { message } = this.state;

    return (
      <Modal isOpen={isOpen} theme="pc-feedback-modal">
        <Formik
          enableReinitialize
          initialValues={this.state}
          validate={(values) => {
            const errors = {};

            // Input Validation
            if (!values.message) errors.message = 'Message is required!';
            if (!values.category) errors.category = 'Category is required!';

            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            this.submitFeedback(values).then(() => {
              setSubmitting(false);
              resetForm();
            });
          }}
          git
        >
          {({ values, touched, errors, isSubmitting, handleSubmit }) => (
            <>
              <div>
                <div className="modal-header text-center">
                  <h3 className="modal-title">Support</h3>
                </div>
                <div className="modal-footer">
                  <Button
                    className="btn btn-primary pcbtn pcbtn-primary padding-btn"
                    theme="primary"
                    type="submit"
                    onClick={() => {
                      const tab = window.open(
                        'http://support.clawos.com',
                        '_blank'
                      );
                      tab.focus();
                    }}
                  >
                    Create a Support Ticket
                  </Button>
                </div>
              </div>
              <hr />
              <form onSubmit={handleSubmit} noValidate>
                <div className="modal-header text-center">
                  <h3 className="modal-title">Feedback</h3>
                </div>

                <div className="modal-body">
                  {values.error && (
                    <Alert type="error">Request has failed!</Alert>
                  )}

                  <div className="form-group">
                    <div className="text-group">
                      <SelectInput
                        label="Category *"
                        name="category"
                        options={[
                          { value: 'Page specific', label: 'Page specific' },
                          {
                            value: 'Overall experience',
                            label: 'Overall experience',
                          },
                          { value: 'Notifications', label: 'Notifications' },
                          { value: 'Errors', label: 'Errors' },
                          { value: 'Performance', label: 'Performance' },
                          { value: 'Features', label: 'Features' },
                          { value: 'Other', label: 'Other' },
                        ]}
                        value={values.category}
                        onChange={this.setFieldValue}
                        error={touched.category && errors.category}
                        disabled={isSubmitting}
                      />

                      <TextArea
                        name="message"
                        label="Enter your feedback/request *"
                        defaultValue={message}
                        onChange={this.handleChnage}
                        error={touched.message && errors.message}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    type="reset"
                    onClick={this.onClose}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button theme="primary" type="submit" loading={isSubmitting}>
                    Submit
                  </Button>
                </div>
              </form>
            </>
          )}
        </Formik>
      </Modal>
    );
  }
}

FeedbackForm.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

FeedbackForm.defaultProps = {
  isOpen: false,
  user: { _id: '' },
};

export default FeedbackForm;
