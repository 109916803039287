import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import { UsersCache } from './UsersCache';

const cache = new UsersCache();
//
function getAccountStages() {
  return [
    { id: 'New Registration', name: 'New Registration' },
    { id: 'Activation Pending', name: 'Activation Pending' },
    { id: 'Registered', name: 'Registered' },
    { id: 'Declined', name: 'Declined' },
  ];
}

function getUnits() {
  return [
    { id: 'Imperial', name: 'Imperial' },
    { id: 'Metric', name: 'Metric' },
  ];
}

export const getUsersHeaders = () => {
  const fields = [
    {
      field: 'UserId',
      title: 'Id',
      showInTable: true,
      sortable: true,
      searchable: true,
      editable: 'never',
      cellStyle: {
        width: '10px',
        maxWidth: '50px',
      },
      type: 'numeric',
    },
    {
      field: 'FirstName',
      title: 'First Name',
      sortable: true,
      searchable: true,
      showInTable: true,
    },
    {
      field: 'LastName',
      title: 'Last Name',
      sortable: true,
      searchable: true,
      showInTable: true,
    },
    {
      field: 'Email',
      title: 'Email',
      sortable: true,
      searchable: true,
      showInTable: true,
    },
    {
      field: 'CognitoId',
      title: 'Cognito Id',
      showInTable: true,
      sortable: true,
      searchable: true,
      cellStyle: {
        width: '900px',
        maxWidth: '900px',
      },
    },
    {
      field: 'Tenant',
      title: 'Tenant',
      showInTable: true,
      sortable: true,
      exType: 'select',
      filter: async (row = {}) => {
        const params = {
          filter: {
            id: 'name',
            name: 'name',
          },
        };

        if (Object.prototype.toString.call(row.Role) === '[object Object]') {
          if (typeof row.Role.id !== 'undefined') {
            params.roleId = row.Role.id;
          }
        } else if (typeof row.Role !== 'undefined') {
          params.roleId = row.Role;
        }

        return cache.filterTenants(params);
      },
      lookup: async () => cache.getTenants(),
      // eslint-disable-next-line react/prop-types,react/display-name
      render: (row = {}) => <span>{row.Tenant && row.Tenant.name}</span>,
    },
    {
      field: 'Roles',
      title: 'Roles',
      showInTable: true,
      sortable: true,
      exType: 'select',
      options: {
        isMulti: true,
      },
      filter: async (row = {}) => {
        const params = {
          filter: {
            id: 'name',
            name: 'name',
          },
        };

        if (Object.prototype.toString.call(row.Tenant) === '[object Object]') {
          if (typeof row.Tenant.id !== 'undefined') {
            params.tenantId = row.Tenant.id;
          }
        } else if (typeof row.Tenant !== 'undefined') {
          params.tenantId = row.Tenant;
        }

        return cache.getRoles(params);
      },
      lookup: async (row = {}) => {
        let id = null;

        if (Object.prototype.toString.call(row.Tenant) === '[object Object]') {
          if (typeof row.Tenant.id !== 'undefined') {
            id = row.Tenant.id;
          }
        } else if (typeof row.Tenant !== 'undefined') {
          id = row.Tenant;
        }
        return cache.getRoles({ tenantId: id });
      },
      // eslint-disable-next-line react/prop-types,react/display-name
      render: (row = {}) => {
        if (row.Roles && Array.isArray(row.Roles)) {
          return (
            <div>
              {row.Roles.map((role) => (
                <p key={role.id}>{role.name}</p>
              ))}
            </div>
          );
        }
        return <span>{row.Role && row.Role.name}</span>;
      },
    },
    {
      field: 'AccountStage',
      title: 'Account Stage',
      showInTable: true,
      sortable: true,
      exType: 'select',
      lookup: (() => getAccountStages())(),
      // TODO when "lookup" prop is set it doesn't render value in grid unless I use custom renderer
      // eslint-disable-next-line react/prop-types,react/display-name
      render: (row = {}) => <span>{row.AccountStage}</span>,
    },
    {
      field: 'Unit',
      title: 'Unit',
      exType: 'select',
      lookup: (() => getUnits())(),
      sortable: true,
    },
    {
      field: 'Status',
      title: 'Status',
      type: 'boolean',
      sortable: true,
      lookup: { true: 'true', false: 'false' },
      // eslint-disable-next-line react/prop-types,react/display-name
      render: (row = {}) => <Checkbox checked={row.status} size="small" />,
    },
    {
      field: 'Notification',
      title: 'Notification',
      type: 'boolean',
      sortable: true,
      lookup: { true: 'true', false: 'false' },
      // eslint-disable-next-line react/prop-types,react/display-name
      render: (row = {}) => <Checkbox checked={row.status} size="small" />,
    },
    {
      field: 'CreatedAt',
      title: 'Created At',
      editable: 'never',
      type: 'date',
      filtering: false,
      sortable: true,
      cellStyle: {
        width: '50px',
        maxWidth: '50px',
      },
    },
    {
      field: 'CreatedBy',
      title: 'Created By',
      editable: 'never',
      filtering: false,
      sortable: true,
      cellStyle: {
        width: '50px',
        maxWidth: '50px',
      },
    },
    {
      field: 'UpdatedAt',
      title: 'Updated At',
      editable: 'never',
      type: 'date',
      filtering: false,
      sortable: true,
      cellStyle: {
        width: '50px',
        maxWidth: '50px',
      },
    },
    {
      field: 'UpdatedBy',
      title: 'Updated By',
      editable: 'never',
      filtering: false,
      sortable: true,
      cellStyle: {
        width: '50px',
        maxWidth: '50px',
      },
    },
  ];
  return {
    headers: fields.filter(({ showInTable }) => showInTable),
    detailViewCols: fields,
  };
};

export function resetUserCache() {
  cache.reset();
}
