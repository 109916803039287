import { isObject } from 'utils';

export const getTitles = (
  metaData,
  { filteringDisabled = false, sortingDisabled = false, hidedColumns = {} } = {}
) => {
  const headers = metaData.map((item) => {
    const { name, label, type, referenceTo, options } = item;

    const formattedType = String(type).trim().toLowerCase();

    return {
      field: name,
      title: label || name,
      type: formattedType,
      showInTable: !hidedColumns[name],
      filtering: !(
        options?.filteringDisabled ||
        formattedType === 'array' ||
        formattedType === 'object'
      ),
      sortable: !options?.sortingDisabled,
      referenceTo: referenceTo || [],
    };
  });

  return { headers };
};

export const objectToString = (object) => {
  return JSON.stringify(object)
    .replace(/,"+/g, '\n')
    .replace(/":+/g, ': ')
    .replace(/["{}]+|(\\n)+/g, '');
};

export const makeUpperCaseKeysInObject = (object) => {
  for (const key in object) {
    if (Object.hasOwn(object, key)) {
      object[key.charAt(0).toUpperCase() + key.substring(1)] = object[key];
      delete object[key];
    }
  }
  return object;
};

export const convertHierarchyValue = (value) => {
  return value;
};

export const convertReferenceValue = (value) => {
  if (isObject(value)) {
    return objectToString(value);
  }

  if (value === null || (typeof value !== 'boolean' && !value)) {
    return '—';
  }

  return value;
};
