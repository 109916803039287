/* eslint-disable no-console */
import { call, put, takeLatest } from 'redux-saga/effects';
import Toaster from 'components/ToasterQueue';
import dashboardActions, { GET_TOTAL_COUNT } from '../actions/dashboardActions';

function* getTotalCount() {
  try {
    const totalCount = {};
    const usersData = yield call(dashboardActions.fetchUserTotalCount);
    if (usersData.data) {
      totalCount.users = usersData.data.pagination.totalItems;
    }
    const tenantData = yield call(dashboardActions.fetchTenantTotalCount);

    if (tenantData.data) {
      totalCount.tenants = tenantData.data.pagination.totalItems;
    }
    yield put(dashboardActions.setTotalCount(totalCount));
  } catch (error) {
    Toaster.error(`Error: ${error}`);
  }
}

export default [takeLatest(GET_TOTAL_COUNT, getTotalCount)];
