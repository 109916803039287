import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import errorReducer from './errors/reducer';

import rootSaga from './rootSaga';

import {
  sidebarReducer,
  themeReducer,
  rtlReducer,
  authReducer,
  dataViewReducer,
  dashboardReducer,
  loaderReducer,
  IoTReducer,
  cisUsersReducer,
  flagsReducer,
} from './reducers';

const reducer = combineReducers({
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  auth: authReducer,
  dataView: dataViewReducer,
  dashboard: dashboardReducer,
  loader: loaderReducer,
  IoT: IoTReducer,
  errors: errorReducer,
  flags: flagsReducer,
  cisUsers: cisUsersReducer,
});

const sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
