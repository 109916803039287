import React from 'react';
import PropTypes from 'prop-types';

const alertSuffixes = ['danger', 'warning', 'info', 'error', 'success'];

function Alert({ type, onClose, children, textAlign }) {
  const suffix = alertSuffixes.includes(type) ? type : 'info';

  return (
    <div className="pc-alert">
      <div className={`alert alert-${suffix}`} style={{ textAlign }}>
        {onClose && (
          <button
            onClick={onClose}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
        {children}
      </div>
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  textAlign: PropTypes.string,
};

Alert.defaultProps = {
  type: 'info',
  onClose: null,
  textAlign: 'left',
};

export default Alert;
