import React from 'react';
import PropTypes from 'prop-types';

function TableDefaultBody({
  page,
  getTableBodyProps,
  prepareRow,
  isSelectable,
  onRowClick,
  selectedItem,
  idProperty,
}) {
  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row, i) => {
        prepareRow(row);

        const style = {
          backgroundColor: row?.original?.backgroundColor || '',
          cursor: isSelectable ? 'pointer' : '',
        };

        if (selectedItem && selectedItem[idProperty] === (row?.original && row.original[idProperty])) {
          style.backgroundColor = '#d6d6d6';
        }

        return (
          <tr
            key={i}
            {...row.getRowProps()}
            style={style}
            onClick={() => isSelectable && onRowClick(row.original)}
          >
            {row.cells.map((cell, index) => {
              return (
                <td
                  key={index}
                  {...cell.getCellProps({
                    className: cell.column.className,
                  })}
                >
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

TableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func,
  selectedItem: PropTypes.object,
};

TableDefaultBody.defaultProps = {
  selectedItem: {},
  onRowClick: () => {},
};

function TableBody({ page, getTableBodyProps, prepareRow, ...rest }) {
  return (
    <TableDefaultBody
      page={page}
      getTableBodyProps={getTableBodyProps}
      prepareRow={prepareRow}
      {...rest}
    />
  );
}

TableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

export default TableBody;
