export default {
  salesPersons: [],
  tiers: [
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '3',
      label: '3',
    },
    {
      value: '4',
      label: '4',
    },
    {
      value: '5',
      label: '5',
    },
    {
      value: '6',
      label: '6',
    },
  ],
  mechanicalAttachments: [],
  stamps: [
    {
      value: 'none',
      label: 'None',
    },
    {
      value: 'projectStamp',
      label: 'Project Stamp',
    },
    {
      value: 'projectStampRev',
      label: 'Project Stamp Rev',
    },
    {
      value: 'caNonLACntyProjectStamp',
      label: 'CA (non-LA Cnty) Project Stamp',
    },
    {
      value: 'caLaCntyOnlyProjectStamp',
      label: 'CA (LA Cnty only) Project Stamp',
    },
  ],
};
