import {
  INCREASE_COUNT_TENANT,
  SET_TOTAL_COUNT,
} from '../actions/dashboardActions';

const initialState = {
  totalCount: {
    isLoading: true,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: { isLoading: false, ...action.totalCount },
      };
    case INCREASE_COUNT_TENANT:
      const { totalCount } = state;
      totalCount.tenants += 1;
      return { ...state, totalCount };
    default:
      return state;
  }
}
