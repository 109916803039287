import fetch from 'node-fetch';
import store from 'redux/store';

// import dummyDataSMM from './dummyDataSMM.json';

export function getToken() {
  const state = store.getState();
  if (state.auth.token) return state.auth.token;

  return sessionStorage.getItem('token');
}

export const inlineFilters = (params) => {
  const { filters, ...paramsWithoutFilters } = params;
  return {
    ...paramsWithoutFilters,
    ...params.filters,
  };
};

const makeRequest = async (method, url, params = {}) => {
  const completeUrl = new URL(`${process.env.REACT_APP_API_URL}${url}`);
  if (params) {
    // eslint-disable-next-line no-unused-vars
    const { headers = null, ...urlParams } = params;
    Object.keys(params).forEach(
      (key) =>
        key !== 'data' &&
        key !== 'tenants' &&
        key !== 'sessionId' &&
        completeUrl.searchParams.append(key, urlParams[key])
    );
  }

  if (params.tenants) {
    params.tenants.forEach((x) =>
      completeUrl.searchParams.append('tenants', x)
    );
  }

  const token = getToken();
  if (token) {
    // eslint-disable-next-line no-param-reassign
    params.headers = {
      ...params.headers,
      authorization: `Bearer ${token}`,
      app: 'aui',
    };
  }

  const body =
    String(method).toLowerCase() === 'get' ||
    String(method).toLowerCase() === 'delete'
      ? undefined
      : JSON.stringify(params.data);

  const response = await fetch(completeUrl, {
    method,
    mode: 'cors',
    ...params,
    body,
  });

  // eslint-disable-next-line no-use-before-define
  await checkStatus(response);

  return { data: await response.json() };
};

const apiRequest = (method, url) => (params) =>
  makeRequest(method, url, params);

const parseJSON = async (response) => {
  if (response.status !== 200) {
    // eslint-disable-next-line no-throw-literal
    throw {
      code: response.status,
      message: await response.json(),
    };
  }

  const result = await response.json();

  return result;
};

const checkStatus = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const json = await parseJSON(response);
  return Promise.reject(
    new Error(
      json.message || response.statusText || 'Error occurred, try again'
    )
  );
};

export const api = {
  getModuleMakers: apiRequest('get', '/wes/data/modulemakers'),
  getModules: apiRequest('get', '/wes/data/modulemodels'),
  getAllUsers: apiRequest('get', '/user/all'),
  getUsers: (params) => apiRequest('get', '/user/cis')(params),
  getUser: (id) => apiRequest('get', `/cis/users/${id}`)(),
  getCISUsers: (params) => apiRequest('get', '/cis/users')(params),
  getAllTenants: (params) => apiRequest('get', '/cis/tenants')(params),
  getTenantNames: apiRequest('get', '/cis/tenants/names'),
  getTenant: (id) => apiRequest('get', `/cis/tenants/${id}`)(),
  getTenantRoles: (id) => apiRequest('get', `/cis/tenants/${id}/roles`)(),
  postTenant: (params) => apiRequest('post', '/cis/tenants')(params),
  putTenant: (id, params) =>
    apiRequest('put', `/cis/tenants/validate/${id}`)(params),
  deleteTenant: (id) => apiRequest('delete', `/cis/tenants/${id}`)(),
  reclaimUser: (params) => apiRequest('put', `/reclaim/user`)(params),
  getMongoUsers: apiRequest('get', '/user/mongo'),
  getIoTCredentials: apiRequest('get', '/credentials/iot'),
  updateUser: (id, params) => apiRequest('put', `/user/sync/${id}`)(params),
  deleteUser: (id) => apiRequest('delete', `/user/sync/${id}`)(),
  getSpecSheet: (key, isCustom) => {
    return isCustom
      ? apiRequest('get', `/file/customspecsheet/download/${key}`)()
      : apiRequest('get', `/file/specsheet/download/${key}`)();
  },
  getDeliverables: (revisionId) =>
    apiRequest('get', `/file/deliverables/download/${revisionId}`)(),
  putSM: (id, params) => apiRequest('put', `/wes/modules/${id}`)(params),
  putCSM: (id, params) => apiRequest('put', `/wes/custommodules/${id}`)(params),
  putCISUser: (id, params) => apiRequest('put', `/cis/users/${id}`)(params),
  postCISUserNotify: (params) => apiRequest('post', '/user/notify')(params),
  getServerGeneratedReport: (model = 'modules', params) =>
    apiRequest('get', `/wes/${model}/export`)(params),
  getSingleSM: (key) => apiRequest('get', `/wes/modules/${key}`)(),
  getSingleCSM: (key) => apiRequest('get', `/wes/custommodules/${key}`)(),
  getSM: apiRequest('get', '/wes/modules/base'),
  importSMs: (params) => apiRequest('post', '/wes/modules/import')(params),
  getCSM: apiRequest('get', '/wes/custommodules'),
  getSMsLogs: apiRequest('get', '/wes/sm'),
  getSFTables: apiRequest('get', '/wes/salesforce/sobjects'),
  getSFMetaData: (sobjectName) =>
    apiRequest('get', `/wes/salesforce/sobjects/metadata/${sobjectName}`)(),
  getSFData: (params) => apiRequest('post', `/wes/salesforce/data`)(params),
  allowFileUpload: apiRequest('get', `/file/upload`),
  getAllProjects: (params) => apiRequest('get', '/projects')(params),
  exportProjects: (params) => apiRequest('get', '/projects/export/to')(params),
  getSingleProject: (id) => apiRequest('get', `/projects/${id}`)(),
  getUsersByTenant: (id) => apiRequest('get', `/cis/tenants/${id}/users`)(),
  getAcls: apiRequest('get', '/cis/acl'),
  postAcl: (params) => apiRequest('post', '/cis/acl')(params),
  putAcl: (id, params) => apiRequest('put', `/cis/acl/${id}`)(params),
  deleteAcl: (id) => apiRequest('delete', `/cis/acl/${id}`)(),
  getRoles: apiRequest('get', '/cis/role'),
  getRole: (key) => apiRequest('get', `/cis/role/${key}`)(),
  postRole: (params) => apiRequest('post', '/cis/role')(params),
  putRole: (id, params) => apiRequest('put', `/cis/role/${id}`)(params),
  deleteRole: (id) => apiRequest('delete', `/cis/role/${id}`)(),
  exportRoles: (params) => apiRequest('get', `/cis/role/export/to`)(params),
  exportUsers: (params) => apiRequest('get', `/cis/users/export/to`)(params),
  exportTenants: (params) =>
    apiRequest('get', `/cis/tenants/export/to`)(params),
  getHelioscopeProjects: (params) =>
    apiRequest('post', '/cis/helioscope/data/projects')(params),
  getHelioscopeDesigns: (projectId, params) =>
    apiRequest('post', `/cis/helioscope/data/designs/${projectId}`)(params),
  getHelioscopeFieldSegments: (designId, params) =>
    apiRequest(
      'post',
      `/cis/helioscope/data/fieldSegments/${designId}`
    )(params),
  getEstimates: apiRequest('get', '/wes/estimates'),
  getEstimate: (key) => apiRequest('get', `/wes/estimates/${key}`)(),
  postEstimate: (params) => apiRequest('post', '/wes/estimates')(params),
  putEstimate: (id, params) =>
    apiRequest('put', `/wes/estimates/${id}`)(params),
  deleteEstimateList: (id) =>
    apiRequest(
      'delete',
      `/wes/estimates/${id}`
    )({
      source: 'list',
    }),
  deleteEstimateDetails: (id) =>
    apiRequest(
      'delete',
      `/wes/estimates/${id}`
    )({
      source: 'details',
    }),
  exportEstimates: (params) =>
    apiRequest('get', `/wes/estimates/export`)(params),
  generateEstimates: (params) =>
    apiRequest('post', `/wes/estimates/generate`)(params),
  getSalesforcePrices: () => apiRequest('get', `/wes/salesforce/prices`)(),
  getSalesforcePricesById: (tierId) =>
    apiRequest('get', `/wes/salesforce/prices/${tierId}`)(),
  getPartenForcements: (withKit = true) =>
    apiRequest('get', `/wes/data/partenforcements?withKit=${withKit}`)(),
  getModuleParts: (moduleId, rackingId, rowSpace) =>
    apiRequest(
      'get',
      `/wes/estimates/check/module/${moduleId}/racking/${rackingId}/rowspace/${rowSpace}`
    )(),
  getCustomModuleParts: (payload, rackingId, rowSpace) =>
    apiRequest(
      'post',
      `/wes/estimates/check/customsolarmodule/racking/${rackingId}/rowspace/${rowSpace}`
    )(payload),
  refreshBom: (params) => apiRequest('post', `/wes/estimates/refresh`)(params),
  exportEstimate: (param) =>
    apiRequest('post', `/wes/estimates/template`)(param),
  getEstimateDocumentsList: (estimateId) =>
    apiRequest(
      'get',
      `/wes/files/adminui/estimatetemplates/list/${estimateId}`
    )(),
  getEstimateDocument: (estimateId, fileName) =>
    apiRequest(
      'get',
      `/wes/files/adminui/estimatetemplates/${estimateId}/${encodeURIComponent(
        fileName
      )}`
    )(),
  getWiringParts: (partNumber, numberOfModules) =>
    apiRequest(
      'get',
      `/wes/estimates/wiring?basePartNumber=${partNumber}&NumberOfModules=${numberOfModules}`
    )(),
  getAccessoriesParts: (
    partNumber,
    numberOfModules,
    subarraysCount,
    rackingSystem
  ) =>
    apiRequest(
      'get',
      `/wes/estimates/additional-accessories?railPartNumber=${partNumber}&NumberOfModules=${numberOfModules}${
        subarraysCount ? `&SubarraysCount=${subarraysCount}` : ''
      }${rackingSystem ? `&rackingSystem=${rackingSystem}` : ''}`
    )(),
  postCheckCustomSolarModule: (params) =>
    apiRequest('post', '/wes/check/customsolarmodule')(params),
  getDefaultPropsSolarModule: () =>
    apiRequest('get', '/wes/estimates/defaults')(),
  getEstimatesCustomers: (id) =>
    apiRequest('get', `/wes/estimates/${id ? `${id}/` : ''}customers`)(),
  getMas: (ma, manufacturer, membrane) =>
    // apiRequest('get', `/wes/data/masExtended/${maType}/${membrane}`)(),
    apiRequest(
      'get',
      `/wes/data/masextended/${ma}/${manufacturer}/${membrane}`
    )(),
  getApiInspectorSources: () =>
    apiRequest('get', '/wes/api-inspector/source')(),
  getApiInspectorSourceTables: (source) =>
    apiRequest(
      'get',
      `/wes/api-inspector/source/${encodeURIComponent(source)}`
    )(),
  getApiInspectorSourceTableMetadata: (source, table, params) =>
    apiRequest(
      'post',
      `/wes/api-inspector/source/${encodeURIComponent(
        source
      )}/table/${encodeURIComponent(table)}`
    )(params),
  getReportGeneratorSources: () =>
    apiRequest('get', '/wes/report-generator/source')(),
  getReportGeneratorSourceTables: (source) =>
    apiRequest(
      'get',
      `/wes/report-generator/source/${encodeURIComponent(source)}`
    )(),
  getReportGeneratorSourceTableMetadata: (source, table, params) =>
    apiRequest(
      'post',
      `/wes/report-generator/source/${encodeURIComponent(
        source
      )}/table/${encodeURIComponent(table)}`
    )(params),
  getRoofManufacturers: () =>
    apiRequest('get', `/wes/data/roofmanufacturers`)(),
  getRoofMembranes: () => apiRequest('get', `/wes/data/roofmembranes`)(),
  getDWACRequest: (param) => apiRequest('get', `/dw/ac`)(param),
  putDWACRequest: (params) => apiRequest('put', `/dw/ac`)(params),
  saveNewACRequest: (params) => apiRequest('post', `/dw/ac`)(params),
  postDWACStampRequest: (params) =>
    apiRequest('post', `/dw/ac/stamp-request`)(params),
  getDWDMRequest: (param) => apiRequest('get', `/dw/dm`)(param),
  putDWDMRequest: (params) => apiRequest('put', `/dw/dm`)(params),
  saveNewDMRequest: (params) => apiRequest('post', `/dw/dm`)(params),
  getDWSubmission: (param) => apiRequest('get', `/dw/submission`)(param),
  putDWSubmission: (params) => apiRequest('put', `/dw/submission`)(params),
  approveDWSubmission: (params) =>
    apiRequest('put', `/dw/submission/approve`)(params),
  getAllOpportunity: (params) =>
    apiRequest('get', `/dw/sf/opportunity`)(params),
  getOpportunityByNumber: (opportunityNumber) =>
    apiRequest('get', `/dw/sf/opportunity/${opportunityNumber}`)(),
  getDWLookup: (param) => apiRequest('get', `/dw/lookup`)(param),
  getDWLookupAdditional: () => apiRequest('get', `/dw/lookup/additional`)(),
  postDWLookup: (params) => apiRequest('post', `/dw/lookup`)(params),
  getAnalyticsKPI: (param = '') =>
    apiRequest('get', `/dw/analytics/kpi${param}`)(),
  getAnalyticsCharts: () => apiRequest('get', `/dw/analytics/chart`)(),
  getAnalyticsChart: (name, params = '') =>
    apiRequest('get', `/dw/analytics/chart/${name}${params}`)(),
  getRequestInfo: () => apiRequest('get', `/dw/ac/stats`)(),
  getExportRequests: (type, params) =>
    apiRequest('get', `/dw/${type}/export/to`)(params),
  getExportSubmissions: (params) =>
    apiRequest('get', `/dw/submission/export/to`)(params),
  getSolarModulesSync: (
    params,
    part = 'part' // all
  ) => apiRequest('get', `/cis/sm/sync/${part}`)(params),
  requestWorkflowExecution: (params) =>
    apiRequest('post', `/workflow/request-workflow-execution`)(params),
  executeWorkflow: (params) =>
    apiRequest('post', `/workflow/execute-workflow`)(params),
  getWorkflowStatus: (projectId, workflow, params) =>
    apiRequest(
      'get',
      `/workflow/get-workflow-status/${projectId}/${workflow}`
    )(params),
  syncWorkflow: (params) =>
    apiRequest('post', `/workflow/sync-workflow`)(params),
  getSMStatus: (syncId) => apiRequest('get', `/wes/sm/sync-status/${syncId}`)(),
  getWireAccessoriesSettings: (params) =>
    apiRequest('get', `/wes/estimates/wiring-accessories/settings`)(params),
};
