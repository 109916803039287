export const initialState = {
  projects: {
    fetching: null,
    updating: null,
  },
  project: {
    creating: null,
    fetching: null,
    updating: null,
  },
  roofs: {
    fetching: null,
    updating: null,
  },
  roof: {
    fetching: null,
    updating: null,
  },
  site: {
    updating: null,
  },
  wde: {
    fetching: null,
  },
  discl: {
    updating: null,
  },
  archive: {
    updating: null,
  },
  customInputs: {
    updating: null,
  },
  s3: {
    updating: null,
  },
  user: {
    updating: null,
    password: null,
  },
  global: {
    notification: null,
  },
};

export const errorConfigs = {
  PROJECTS_FETCHING_FAILED: 'Projects fetching failed!',
  PROJECT_FETCHING_FAILDED: 'Project fetching failed!',
  PROJECT_UPDATING_FAILED: 'Project updating failed!',
  ROOFS_FETCHING_FAILED: 'Roofs fetching failed!',
  ROOF_FETCHING_FAILED: 'Roof fetching failed!',
  ROOF_UPDATE_FAILED: 'Roof updating failed!',
  S3_UPDATE_FAILED: 'Updating failed!',
  503: 'Service Not Available!',
  500: 'Internal Server Error!',
  DEFAULT_CONN_ERROR: 'Connection Error!',
};
