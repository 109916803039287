import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { isObject } from 'utils';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import SaveAlt from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme, options = {}) => {
  const styles = {
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    ...options,
  };

  return styles;
});

function ExtraMenu({
  options,
  optionsHandleSelect,
  iconComponent = null,
  iconClass,
  onClick,
  styles,
}) {
  const classes = useStyles(isObject(styles) ? styles : {});
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    if (onClick) {
      onClick();
    } else {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = useCallback(
    ({ target: eventTarget }) => {
      const anchorRefCur = anchorRef.current;
      if (anchorRefCur && anchorRefCur.contains(eventTarget)) return;
      setOpen(false);
      const clickedItem = eventTarget.getAttribute('name');
      return clickedItem && optionsHandleSelect(clickedItem.toLowerCase()); // eslint-disable-line
    },
    [optionsHandleSelect]
  );

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) anchorRef.current.focus();
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <IconButton
          ref={anchorRef}
          aria-controls={open && 'menu-list-grow'}
          aria-haspopup="true"
          onClick={handleToggle}
          className={iconClass || ''}
        >
          {iconComponent || <SaveAlt />}
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {options.map((el) => (
                        <MenuItem
                          key={`extraMenu-${el.type}`}
                          name={el.type}
                          className={classes.menuItem}
                          onClick={handleClose}
                          disabled={el.disabled}
                        >
                          {el.title}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

export default ExtraMenu;

ExtraMenu.propTypes = {
  optionsHandleSelect: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  options: PropTypes.array, // eslint-disable-line
  iconComponent: PropTypes.object, // eslint-disable-line
  iconClass: PropTypes.string,
  styles: PropTypes.object,
};

ExtraMenu.defaultProps = {
  optionsHandleSelect: (p) => console.log(p), // eslint-disable-line
  options: ['PDF', 'CSV', 'Excel'],
  iconComponent: null,
  iconClass: '',
  styles: {
    menuItem: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
};
