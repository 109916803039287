import React from 'react';
import { toast, Flip } from 'react-toastify';
import PropTypes from 'prop-types';

const generalOptions = {
  hideProgressBar: false,
  pauseOnHover: true,
  autoClose: 5000,
};

const longwaitOptions = {
  hideProgressBar: false,
  autoClose: 15000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

function ToasterWithLink({ msg, link }) {
  return (
    <span>
      <small>
        <strong>{`${msg} : `}</strong>
        <a target="_blank" rel="noopener noreferrer" href={link}>
          {link}
        </a>
      </small>
    </span>
  );
}

export default {
  msg: (msg) => toast.info(msg, generalOptions),
  success: (msg) => toast.success(msg, generalOptions),
  longWait: (msg) => toast.warn(msg, longwaitOptions),
  error: (msg) => toast.error(msg, generalOptions),
  warn: (msg) => toast.warn(msg, generalOptions),
  clearAll: () => toast.dismiss(),
  dismissById: (id) => toast.dismiss(id),
  updateById: (id, text) =>
    toast.update(id, {
      render: text,
      type: toast.TYPE.SUCCESS,
      transition: Flip,
    }),
  showLink: (msg, link, type) => {
    toast.dismiss();
    return toast(<ToasterWithLink msg={msg} link={link} />, {
      ...generalOptions,
      type,
    });
  },
  reportGenFailed: (msg) => {
    toast.dismiss();
    return toast.error(msg, generalOptions);
  },
};

export const TOAST_TYPE = {
  ERROR: 'error',
};

ToasterWithLink.propTypes = {
  msg: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
};

ToasterWithLink.defaultProps = {
  msg: 'Click here to open the report',
  link: '',
  type: 'success',
};
