export default Object.freeze({
  defaultAccess: {
    Ops: {
      Read: false,
      Create: false,
      Update: false,
      Delete: false,
      Show: false,
      Enable: false,
    },
    ACLId: -1,
    Name: 'default',
    Backend: false,
  },
});
