/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'react-bootstrap';
import { CardBody } from 'reactstrap';

import { toast } from 'react-toastify';

import Checkbox from 'components/common/Checkbox';

import MaterialTable from 'components/MaterialTable';

import CircularProgress from 'components/CircularProgress';

import { TABLE_LOAD_ERROR } from 'containers/Reports/Custom/constants';

import { isObject } from 'utils';

import './assets/style.scss';
import { NEVER } from 'components/DetailView/constants';

class Table extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef(null);

    this.detailViewCols = [];

    this.cachedData = {};

    this.state = {
      loading: false,
      errorMessage: null,
      headers: [],
      rows: [],
    };
  }

  componentDidMount() {
    const { headers, data } = this.props;

    this.setState({
      headers: this.createColumnsRender(headers),
    });

    this.createRows(data);
  }

  componentDidUpdate(prevProps) {
    const { headers, data, disabled } = this.props;

    if (headers !== prevProps.headers || disabled !== prevProps.disabled) {
      this.setState({
        headers: this.createColumnsRender(headers),
      });
    }

    if (data !== prevProps.data) {
      this.createRows(data);
    }
  }

  createRows = (data) => {
    this.cachedData = {};

    let rows = null;

    if (isObject(data)) {
      rows = [];

      const { fields } = this.props;

      if (Array.isArray(fields)) {
        fields.forEach((name) => {
          if (Array.isArray(data[name])) {
            rows.push(...data[name]);
          }
        });
      } else if (Array.isArray(data)) {
        rows = [...data];
      }
    } else {
      rows = data;

      let i = -1;
      const n = rows.length;

      while (++i < n) {
        this.cachedData[rows[i].Id] = rows[i];
      }
    }

    this.setState({
      rows,
    });
  };

  getData = async (filters) => {
    try {
      const { rows } = this.state;
      return this.formattingData(rows);
    } catch (e) {
      this.checkError(e);
    }
  };

  clearFiltersAndUpdateTable = () => {
    this.tableRef.current.clearFiltersAndUpdateTable();
  };

  setLoading = (loading = true) => {
    this.tableRef.current.setLoading(loading);
  };

  setLoadingTable = (loading = true) => {
    this.tableRef.current.setLoadingTable(loading);
  };

  checkError = (e) => {
    toast.error(e.message?.message || TABLE_LOAD_ERROR);
    this.setState({ errorMessage: TABLE_LOAD_ERROR });
  };

  formattingData = (data) => {
    const items = [];
    let count = 0;

    for (let i = 0, n = data.length; i < n; i += 1) {
      const formattedItem = { ...data[i] };

      items.push(formattedItem);
      count++;
    }

    return {
      count,
      data: items,
    };
  };

  startLoad = () => {
    const { onEvent } = this.props;

    this.tableRef.current.setLoadingTable(true);

    onEvent({ action: 'loading' });

    this.setState({
      errorMessage: null,
      loading: true,
      isLoadingTable: true,
    });
  };

  endLoad = () => {
    const { onEvent } = this.props;

    this.setState({ loading: false });

    this.tableRef.current?.setLoadingTable(false);

    onEvent({ action: 'loaded' });
  };

  createColumnsRender = (columns) => {
    return columns.map((col) => {
      if (col.type !== 'boolean') return col;

      const disabled = this.props.disabled || col.editable === NEVER;
      // eslint-disable-next-line no-param-reassign
      col.render = (row = {}) => (
        <Checkbox
          id={row.Id}
          key={row.Id}
          styleType="colored-click"
          color="primary"
          checked={this.getValue(row, col.field)}
          disabled={disabled}
          onChange={(e) => {
            this.updateValueIfNeed(row, col.field, e.target.checked, true);
          }}
        />
      );

      return col;
    });
  };

  getValue = (row, name) => {
    const names = name.split('.');
    let field = row;
    let value = null;

    names.forEach((n) => {
      if (!field) return;

      value = field[n];
      field = value;
    });

    return value;
  };

  updateValueIfNeed = (row, name, value, needCheckDependencies = false) => {
    this.updateValue(row, name, value, needCheckDependencies);
    this.updateValue(
      this.cachedData[row.Id],
      name,
      value,
      needCheckDependencies
    );

    const { rows } = this.state;

    this.setState({
      rows: [...rows],
    });
  };

  updateValue = (row, name, value, needCheckDependencies = false) => {
    const names = name.split('.');
    let field = row;
    let newField = null;

    if (names[0] === 'Ops') {
      if (!field.Ops) {
        field.Ops = {};
      }
    }

    let changed = false;

    names.forEach((n, i) => {
      if (i === names.length - 1) {
        field[n] = value;
        changed = true;
      } else {
        newField = field[n];
        field = newField;
      }
    });

    if (!changed || !needCheckDependencies) return;

    const col = this.getColByName(name);

    if (!col) return;

    if (Array.isArray(col.dependencies)) {
      col.dependencies.forEach((dep) => {
        if (!isObject(dep)) {
          this.updateValue(row, dep, value, false);
          return;
        }

        if (!dep.states) {
          this.updateValue(row, dep.name, value, false);
          return;
        }

        if (dep.states.checked && value) {
          this.updateValue(row, dep.name, value, false);
          return;
        }

        if (!value && !Object.keys(row.Ops).some((key) => row.Ops[key])) {
          this.updateValue(row, dep.name, value, false);
        }
        // TODO: add check states
      });
    }
  };

  getColByName = (name) => {
    const { headers } = this.state;

    return headers.find((col) => col.field === name);
  };

  rowStyle = () => {
    const { disabled } = this.props;

    return {
      backgroundColor: disabled ? '#f2f4f7' : '',
    };
  };

  getCellEditable = () => {
    return {
      cellStyle: {
        textAlign: 'center',
        height: '33px',
        padding: '0px',
      },
      onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
        return new Promise((resolve) => {
          this.updateValueIfNeed(rowData, columnDef.field, newValue);
          resolve();
        });
      },
    };
  };

  render() {
    const { loading, errorMessage } = this.state;

    if (loading) {
      return (
        <Card>
          <CardBody>
            <div className="reports-custom-progress">
              <CircularProgress content="" />
            </div>
          </CardBody>
        </Card>
      );
    }

    if (errorMessage) {
      return (
        <Card>
          <CardBody>
            <div className="reports-custom-progress">{errorMessage}</div>
          </CardBody>
        </Card>
      );
    }

    const { title, opts, disabled } = this.props;
    const { headers } = this.state;

    let cellEditable = false;

    if (opts.cellEditable && !disabled) {
      cellEditable = this.getCellEditable();
    }

    return (
      <div className="detail-view-table">
        {headers.length ? (
          <MaterialTable
            ref={this.tableRef}
            tableProps={{
              title,
              name: 'SourceTable',
              showSearchBar: false,
              hideDefaultMenu: true,
              options: {
                paging: true,
                pageSize: 50,
                enableColumnActions: false,
                rowStyle: this.rowStyle,
                ...opts,
              },
              enableColumnActions: false,
              cellEditable,
            }}
            columns={headers}
            remoteData={false}
            report
            getData={this.getData}
            detailsView={{
              cols: this.detailViewCols,
              idFieldName: '_id',
            }}
          />
        ) : null}
      </div>
    );
  }
}

Table.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.object,
  data: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.string),
  onEvent: PropTypes.func,
  disabled: PropTypes.bool,
};

Table.defaultProps = {
  title: '',
  headers: {},
  fields: [],
  data: {},
  onEvent: () => {},
  disabled: true,
};

export default Table;
