/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useEffect } from 'react';
import PropsTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import ColumnsViewSwitch from './ColumnsViewSwitch';
import '../DetailModalView/style.scss';

function ShowInTableColumnsModal({
  title,
  handleClose,
  open,
  cols,
  onSaveColumns,
  tableHidedColumns,
  isLoading,
}) {
  const [hidedColumns, setHidedColumns] = useState({});

  useEffect(() => {
    setHidedColumns({ ...tableHidedColumns });
  }, [tableHidedColumns]);

  const onChangeField = useCallback(
    (field, value) => {
      if (value && hidedColumns[field]) {
        delete hidedColumns[field];
        setHidedColumns({ ...hidedColumns });
      } else {
        hidedColumns[field] = true;
        setHidedColumns({ ...hidedColumns });
      }
    },
    [hidedColumns]
  );

  const saveChanges = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return onSaveColumns(hidedColumns);
  };

  const getComponent = (col) => {
    const { field, path, showInTable, title: titleField } = col;
    const isShown = !hidedColumns[field];

    const opts = {
      field,
      showInTable,
    };

    return (
      <ColumnsViewSwitch
        isShown={isShown}
        onChangeField={(e, newValue) => {
          onChangeField(field, newValue, path);
        }}
        {...opts}
        {...{ label: titleField }}
      />
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      maxWidth="md"
      className="sm-edit"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className="props-table">
          {cols.map((col) => {
            const { field } = col;

            const component = getComponent(col);
            const containerStyle = {
              width: 'auto',
              height: 'auto',
            };

            return (
              <div
                key={field}
                className="prop-container"
                style={containerStyle}
              >
                <div className="prop-value" style={containerStyle}>
                  {component}
                </div>
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={saveChanges}
          color="primary"
          variant="contained"
          loading={isLoading}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ShowInTableColumnsModal.propTypes = {
  title: PropsTypes.string,
  open: PropsTypes.bool,
  handleClose: PropsTypes.func,
  onSaveColumns: PropsTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  cols: PropsTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  tableHidedColumns: PropsTypes.object,
  tableProps: PropsTypes.shape({}),
  isLoading: PropsTypes.bool,
};

ShowInTableColumnsModal.defaultProps = {
  tableProps: {},
  title: '',
  open: false,
  isLoading: false,
  handleClose: () => ({}),
  onSaveColumns: () => ({}),
  cols: [],
  tableHidedColumns: {},
};

export default ShowInTableColumnsModal;
