import React from 'react';
import PropsTypes from 'prop-types';
import Toolbar from 'components/Toolbar';
import Dialog from 'components/common/Dialog';
import { DialogContent } from '@mui/material';

import './assets/styles.scss';

function ConfirmDialog({
  title,
  message,
  actionLabel,
  handleSave,
  handleClose,
  className,
  isOpen = false,
}) {
  const container = (
    <DialogContent
      id="alert-dialog-list"
      className="confirm-dialog-actions-dialog__container"
    >
      {message}
    </DialogContent>
  );

  const actions = (
    <Toolbar
      onCancel={handleClose}
      saveLabel={actionLabel}
      onSave={handleSave}
      disabledCancel={false}
      disabledSave={false}
    />
  );

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        className={className}
        container={container}
        title={title}
        actions={actions}
        handleClose={handleClose}
      />
    </div>
  );
}

ConfirmDialog.propTypes = {
  title: PropsTypes.string,
  message: PropsTypes.string,
  isOpen: PropsTypes.bool,
  className: PropsTypes.string,
  actionLabel: PropsTypes.func,
  handleSave: PropsTypes.string,
  handleClose: PropsTypes.func,
};

ConfirmDialog.defaultProps = {
  title: '',
  message: '',
  actionLabel: '',
  isOpen: false,
  className: 'confirm-dialog',
  handleSave: () => {},
  handleClose: () => {},
};

export default ConfirmDialog;
