import { call, put, takeLatest, delay } from 'redux-saga/effects';

import request from 'utils/request';
import cache from 'utils/Cache';
import { refreshSession, getUser } from './_utils';
import authActions from '../actions/authActions';
import { CHECK_TOKEN, WATCH_TOKEN } from '../authTypes';

function* checkToken() {
  const authUser = yield call(getUser);
  try {
    if (!authUser) {
      yield call(cache.reset);
      yield put(authActions.signOutSuccess());
    }

    const refToken = authUser.getRefreshToken().token;
    const session = yield call(refreshSession, refToken);
    if (!session) {
      yield call(cache.reset);
      yield put(authActions.signOutSuccess());
    }
  } catch (error) {
    if (
      Object.prototype.toString.call(error) === '[object Object]' &&
      error.code === 'NotAuthorizedException'
    ) {
      yield put(authActions.signOut());
    } else {
      yield put(authActions.fetchingIdTokenFailure(true));
    }
  }
}

function* watchToken() {
  try {
    const timeout = 300000; // 5 min

    while (true) {
      const authUser = yield call(getUser);

      if (!authUser) {
        yield call(cache.reset);
        yield put(authActions.signOutSuccess());
        break;
      }

      const refToken = authUser.getRefreshToken().token;

      const session = yield call(refreshSession, refToken);

      if (!session) {
        yield call(cache.reset);
        yield put(authActions.signOutSuccess());
        break;
      }

      const token = session.getIdToken().getJwtToken();
      const expTime = session.getIdToken().payload.exp;
      const refreshToken = session.getRefreshToken().token;

      const user = yield call(request, 'user', {
        token,
      });

      if (user.hasHelioscopeCredentials) {
        yield put(authActions.loginHelioscope({ cosUserId: user._id }));
      }

      yield put(
        authActions.fetchingIdTokenSuccess(token, expTime, refreshToken, user)
      );

      yield delay(timeout);
    }
  } catch (error) {
    if (
      Object.prototype.toString.call(error) === '[object Object]' &&
      error.code === 'NotAuthorizedException'
    ) {
      yield put(authActions.signOut());
    } else {
      yield put(authActions.fetchingIdTokenFailure(true));
    }
  }
}

export default [
  takeLatest(CHECK_TOKEN, checkToken),
  takeLatest(WATCH_TOKEN, watchToken),
];
