import React from 'react';
import PropTypes from 'prop-types';
import { StyledHighlighter } from './styles';
import { convertType } from './utils';

function TableCell({ value: initialValue, state, column }) {
  const value = convertType(initialValue, column.type);

  return column.disableGlobalFilter ? (
    value
  ) : (
    <StyledHighlighter
      searchWords={[state.globalFilter]}
      autoEscape
      textToHighlight={`${value}`}
    />
  );
}

TableCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  state: PropTypes.shape({
    globalFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  column: PropTypes.shape().isRequired,
};

export default TableCell;
