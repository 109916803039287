import React, { useState } from 'react';
import PropsTypes from 'prop-types';
import Toolbar from 'components/Toolbar';
import Fetch from 'components/common/Fetch';
import SelectInput from 'components/common/SelectInput';
import Dialog from 'components/common/Dialog';
import { toLookups } from 'containers/DesignerWorkflow/Management/utils';
import { DialogContent } from '@mui/material';

import './assets/styles.scss';

function ModalDialog({
  title,
  requestType,
  actionLabel,
  handleAction,
  handleClose,
  className,
  defaultValue,
  isOpen = false,
  options = [],
}) {
  let items = [];

  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const onHandleAction = async () => {
    setLoading(true);

    const foundItem = items.find((item) => item.Id === value);

    const status = await handleAction(foundItem);

    setLoading(false);

    if (status) {
      handleClose();
    }
  };

  const onSelectValue = (name, value) => {
    setValue(value);
  };

  const container = (
    <DialogContent
      style={{ minWidth: '550px' }}
      id="alert-dialog-list"
      className="dw-request-actions-dialog__container"
    >
      <Fetch
        url={`dw/lookup/type/${requestType}`}
        render={({ data, loading }) => {
          items = data ? [...options, ...data] : options;

          const lookups = toLookups(data, options, true);

          return (
            <SelectInput
              label="Value"
              labelPosition="left"
              name="value"
              isLoading={loading}
              options={lookups}
              onChange={onSelectValue}
              value={value}
            />
          );
        }}
      />
    </DialogContent>
  );

  const actions = (
    <Toolbar
      disabledCancel={loading}
      onCancel={handleClose}
      disabledSave={loading}
      saveLoading={loading}
      saveLabel={actionLabel}
      onSave={onHandleAction}
    />
  );

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        className={className}
        container={container}
        title={title}
        actions={actions}
        handleClose={handleClose}
      />
    </div>
  );
}

ModalDialog.propTypes = {
  title: PropsTypes.string,
  requestType: PropsTypes.string,
  isOpen: PropsTypes.bool,
  className: PropsTypes.string,
  defaultValue: PropsTypes.string,
  actionLabel: PropsTypes.func,
  handleAction: PropsTypes.string,
  handleClose: PropsTypes.func,
  options: PropsTypes.arrayOf(
    PropsTypes.shape({
      Id: PropsTypes.string,
      Label: PropsTypes.string,
      Value: PropsTypes.string,
    })
  ),
};

ModalDialog.defaultProps = {
  title: '',
  requestType: '',
  actionLabel: '',
  isOpen: false,
  className: 'dw-request-actions-dialog',
  defaultValue: '',
  handleAction: () => {},
  handleClose: () => {},
  options: [],
};

export default ModalDialog;
