import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PlaceSearchBox from './PlaceSearchBox';

import './assets/style.scss';

const roundNumberToN = (value, limitMantissa = 4) =>
  +`${Math.round(`${value}e+${limitMantissa}`)}e-${limitMantissa}`;

class LocationPartial extends PureComponent {
  handleLocationSelected = (place) => {
    const { setFieldValue } = this.props;
    if (place && place.location) {
      setFieldValue('latitude', roundNumberToN(place.location.lat, 6));
      setFieldValue('longitude', roundNumberToN(place.location.lng, 6));
      setFieldValue(
        'locationElevation',
        roundNumberToN(place.location.elevation, 6)
      );
      setFieldValue('center', place.location);
      setFieldValue('locationInfo', place.locationInfo);
    }
  };

  // Lat Lng has to convert to number type
  handleLatLngInput = (event) => {
    const { setFieldValue } = this.props;
    const { name, value } = event.target;
    if (value.trim() === '') {
      setFieldValue(name, value);
    } else {
      const numericValue = Number(value);
      setFieldValue(name, isNaN(numericValue) ? value : numericValue);
    }
  };

  updateAddress = (key, val) => {
    const { setFieldValue } = this.props;
    setFieldValue('latitude', '');
    setFieldValue('longitude', '');
    setFieldValue(key, val);
  };

  handleCheckbox = () => {
    const { setFieldValue, values } = this.props;
    setFieldValue('includeImage', !values.includeImage);
  };

  render() {
    const {
      values,
      touched,
      errors,
      restricted,
      isSubmitting,
      processing,
      readOnly,
      config,
      label,
      labelWidth,
      disabled,
      showErrorLabel,
    } = this.props;

    return (
      <div
        className="container-address form-group input-group"
        style={{ display: 'flex' }}
      >
        <label
          htmlFor="site-address"
          className="component-header"
          style={{ width: `${labelWidth}%` }}
        >
          {label}
        </label>
        {showErrorLabel && errors.address && (
          <div className="pc-inline pull-right text-right">
            <span className="error">{errors.address}</span>
          </div>
        )}
        {/* if a Google map is not used, put attribution image */}
        <div style={{ width: `${100 - labelWidth - 4}%` }}>
          <PlaceSearchBox
            name="address"
            error={errors.address}
            disabled={
              disabled ||
              isSubmitting ||
              restricted.address ||
              processing ||
              readOnly
            }
            onLocationSelected={this.handleLocationSelected}
            value={values.address}
            map={config?.map}
            onChange={this.updateAddress}
          />
        </div>
      </div>
    );
  }
}

LocationPartial.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.shape({
    address: PropTypes.any,
    latitude: PropTypes.any,
    longitude: PropTypes.any,
    includeImage: PropTypes.bool,
    location: PropTypes.shape({
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
    locationInfo: PropTypes.object,
  }).isRequired,
  label: PropTypes.string,
  labelWidth: PropTypes.string,
  errors: PropTypes.shape({
    address: PropTypes.any,
    latitude: PropTypes.any,
    longitude: PropTypes.any,
    location: PropTypes.shape({
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
  }).isRequired,
  touched: PropTypes.shape({
    latitude: PropTypes.any,
    address: PropTypes.any,
    longitude: PropTypes.any,
    location: PropTypes.shape({
      lat: PropTypes.bool,
      lng: PropTypes.bool,
    }),
  }).isRequired,
  restricted: PropTypes.shape({
    address: PropTypes.any,
    latitude: PropTypes.any,
    longitude: PropTypes.any,
    includeImage: PropTypes.bool,
    location: PropTypes.shape({
      lat: PropTypes.bool,
      lng: PropTypes.bool,
    }),
  }),
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  processing: PropTypes.bool,
  showErrorLabel: PropTypes.bool,
  config: PropTypes.any,
};

LocationPartial.defaultProps = {
  disabled: false,
  restricted: {},
  processing: false,
  showErrorLabel: true,
  config: null,
  label: 'Site Address',
  labelWidth: '20%',
};

export default LocationPartial;
export { LocationPartial as _LocationPartial };
