import React, { useState } from 'react';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';

function TableAction({ actions }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret size="lg" outline>
        Action
      </DropdownToggle>
      <DropdownMenu>
        {actions.map((action) => (
          <DropdownItem
            divider={action.useDivider}
            key={action.label}
            value={action.id}
            onClick={action.onClick}
            disabled={action.disabled}
          >
            {action.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

TableAction.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func })
  ),
};

TableAction.defaultProps = {
  actions: [],
};

export default TableAction;
