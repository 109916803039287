/* eslint no-case-declarations: 0 */
import { SET_ERROR, CLEAR_ALL_ERRORS, SET_ERROR_POPUP } from './types';
import { initialState, errorConfigs } from './_configs';

const resolveError = (error) => {
  if (!error) return null;
  if (errorConfigs[error.code] === undefined) return null;
  return errorConfigs[error.code];
};

const errorUpdated = (exState, actionKey, errorKey, error) => {
  const state = {
    ...exState,
  };
  if (!actionKey) return state;

  const actionKeySplitted = actionKey.split('.');
  const actionGroup = actionKeySplitted[0];
  const action = actionKeySplitted[1];
  if (!action) return state;

  if (errorKey === 'PROJECT_UPDATING_FAILED') {
    state[actionGroup][action] = error.message
      ? error.message
      : errorConfigs[errorKey] || errorKey;
  }

  if (errorKey === 'PASSWORD_UPDATING_FAILED') {
    state[actionGroup][action] = error.message;
  }

  if (errorKey === 'GLOBAL') {
    state.global.notification = resolveError(error);
  }

  return state;
};

const errorPopup = (exState, actionKey, popupMsg) => {
  const state = {
    ...exState,
  };
  state.global.popup = null;
  if (!actionKey) return state;

  state.global.popup = {
    actionKey,
    popupMsg,
  };

  return state;
};

export default function projectReducer(
  state = initialState,
  { type, actionKey, errorKey, error, popupMsg }
) {
  switch (type) {
    case SET_ERROR:
      return {
        ...errorUpdated(state, actionKey, errorKey, error),
      };

    case SET_ERROR_POPUP:
      return {
        ...errorPopup(state, actionKey, popupMsg),
      };

    case CLEAR_ALL_ERRORS:
      return initialState;

    default:
      return state;
  }
}
