import React from 'react';
import { Container, Row } from 'reactstrap';

import MaterialTable from 'components/MaterialTable';
import { api, inlineFilters } from 'utils/fetch';

import Toaster from 'components/ToasterQueue';
import store from 'redux/store';
import dashboardActions from 'redux/actions/dashboardActions';
import { getTenantsHeaders } from './utils';

class Tenants extends React.Component {
  constructor(props) {
    super(props);
    const { headers, detailViewCols } = getTenantsHeaders();
    this.headers = headers;
    this.detailViewCols = detailViewCols;
  }

  render() {
    return (
      <Container className="tenants-page">
        <Row>
          <MaterialTable
            tableProps={{
              title: 'Tenants',
              detailViewTittle: 'Tenant',
              actions: {},
              canDelete: true,
              name: 'Tenants',
            }}
            middleToolbar={null}
            columns={this.headers}
            remoteData
            hasAddButton
            fullScreenDetailView
            getData={async (param) => {
              const newParams = inlineFilters(param);
              const result = await api.getAllTenants(newParams);
              return {
                ...result,
                count: result.data.pagination.totalItems,
                data: result.data,
              };
            }}
            detailsView={{
              getItem: api.getTenant,
              cols: this.detailViewCols,
              onSave: async (id, payload, createTenant) => {
                if (!Array.isArray(payload.data.AllowedDomains)) {
                  payload.data.AllowedDomains = payload.data.AllowedDomains
                    ? payload.data.AllowedDomains.split(',').filter(
                        (element) => element
                      )
                    : [];
                }
                try {
                  const result = createTenant
                    ? await api.postTenant(payload)
                    : await api.putTenant(id, payload);
                  if (createTenant) {
                    Toaster.success(`New Tenant created`);
                    store.dispatch(dashboardActions.increaseCountTenant());
                  }
                  return result;
                } catch (error) {
                  const { message } = error.message;
                  const inUseDomains =
                    message.match(/\[(.*)\]/) && message.match(/\[(.*)\]/)[1];
                  if (inUseDomains) {
                    throw {
                      message: {
                        message: `That domains ${inUseDomains} are in use by one of the Tenants' users. Please edit/remove those users first`,
                      },
                    };
                  }

                  throw error;
                }
              },
              onDelete: api.deleteTenant,
              idFieldName: 'id',
            }}
          />
        </Row>
      </Container>
    );
  }
}

export default Tenants;
