import { CHANGE_LOADER, CHANGE_TABLE_LOADER } from '../actions/loaderActions';

const initialState = {
  loading: false,
  tableLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOADER: {
      const { loading } = action;
      return { ...state, loading };
    }
    case CHANGE_TABLE_LOADER: {
      const { tableLoading } = action;
      return { ...state, tableLoading };
    }
    default:
      return state;
  }
}
