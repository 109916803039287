import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from '@material-ui/core';
import Toaster from 'components/ToasterQueue';
import {
  changeFilterOptions,
  setHsHidedHeader,
} from 'redux/actions/dataViewActions';
import DetailModalView from 'components/DetailModalView';
import ShowInTableColumnsModal from 'components/ShowInTableColumnsModal';
import SelectListModal from 'components/SelectListModal';
import { api } from 'utils/fetch';

import { getAcl } from 'redux/selectors/selectors';
import { isBoolean, isDate, isNumeric, isObject } from 'utils';
import { CLICK_TO_OPEN } from 'components/common/utils/constants';

import AddIcon from '@material-ui/icons/Add';

import DetailView from 'components/DetailView';

import { SHOW_OPPORTUNITY_BY_DEFAULT } from 'redux/constants/category';

import {
  getAllItemsFirstTime,
  getFilteredData,
  getRemoteData,
  deepEqual,
  getVisibleColumns,
} from './_utils';
import { extraMenuExport } from './Tools/exportTools';
import { addTool } from './Tools/addTool';
import FilterRow from './Filters/FilterRow';
import MoreMenu from './MoreMenu';
import Toolbar from './Toolbar';
import Table from './Table';
import './style.scss';
import { revertTableTool } from './Tools/revertTableTool';
import {
  Estimates,
  EstimatesProjectListHeaders,
  EstimatesProjectListTitle,
  ApiInspector,
  OpportunityListHeaders,
  OpportunityListTitle,
  DWRequest,
} from './constants';
import { actionSelect } from 'components/MaterialTable/Tools/selectTool';
import { UPDATE } from 'components/DetailView/constants';
import moment from 'moment';

const DEFAULT_OPTIONS = {
  actionsColumnIndex: -1,
  selection: false,
  exportButton: true,
  filtering: true,
  pageSize: 20,
  pageSizeOptions: [5, 10, 20, 50, 100],
};

const initCachedData = {
  data: [],
  byFilter: [],
  bySearch: [],
  filteredData: [],
};
const initDetail = {
  open: false,
  id: null,
  exportedFileLink: null,
  exportError: null,
  editMode: '',
};

const initSelectList = {
  open: false,
  list: [],
  headers: [],
};

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

class TableWrap extends Component {
  tableOptions = null;

  tableRef = React.createRef(null);

  detailViewRef = React.createRef(null);

  // TODO remove
  cachedDetailViewData = {};

  cachedData = {
    ...initCachedData,
  };

  constructor(props) {
    super(props);

    this.state = {
      detail: {
        ...initDetail,
      },
      detailViewPanel: {
        ...initDetail,
      },
      selectList: {
        ...initSelectList,
      },
      isLoading: false,
      isLoadingTable: false,
      isShowInTableColumnsModalOpen: false,
      prevTableNameArray: [],
    };
  }

  componentDidMount() {
    this.props.init();
  }

  // eslint-disable-next-line consistent-return
  componentDidUpdate(prevProps, prevState) {
    const {
      filterOptions,
      getData,
      queuedImportData,
      refreshCount,
      tableProps,
    } = this.props;

    const { exportedFileLink: newLink, exportError } = this.state;
    const isChangedByTable = filterOptions.changedBy === 'table';
    if (
      prevProps.tableProps.name !== tableProps.name ||
      prevProps.refreshCount !== refreshCount
    ) {
      return this.forceUpdateTableData();
    }

    if (prevProps.queuedImportData !== queuedImportData) {
      if (queuedImportData && !prevState.open) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ detail: { open: true, editMode: 'insert' } }); //
      }
    }
    if (prevState.exportError !== exportError && exportError !== null) {
      Toaster.error(`Report's generation failed! ${exportError}`); // react-toastify bug
    }
    if (prevState.exportedFileLink !== newLink && newLink !== null) {
      Toaster.showLink(CLICK_TO_OPEN, newLink);
    }

    if (deepEqual(prevProps.filterOptions, filterOptions) || isChangedByTable)
      return null;

    if (this.tableRef) {
      this.tableRef.current.onQueryChange(filterOptions);
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.changeFilterOptions({});

    const table = this.tableRef.current;
    if (table) {
      table.setState({ data: [], columns: [] });
      table.dataManager?.setData([]);
      table.dataManager?.setColumns([]);
    }
  }

  forceUpdateTableData = () => {
    const { filterOptions } = this.props;
    // this.cachedData = {
    //   ...initCachedData,
    // };
    this.tableRef.current.onQueryChange(filterOptions);
  };

  onChangeFilter = (filterOptions) => {
    this.tableRef.current.onQueryChange(filterOptions);
  };

  getOptions = () => {
    if (!this.tableOptions) {
      const { tableProps } = this.props;
      this.tableOptions = { ...DEFAULT_OPTIONS, ...tableProps.options };
    }
    return this.tableOptions;
  };

  getTableData = async (tableFilterOptions) => {
    const {
      filterOptions,
      changeFilterOptions: onChangeFilterOptions,
      remoteData,
      getData,
      columns,
      detailsView,
      tableProps,
    } = this.props;

    const newFilterOptions = {
      tableName: tableProps.name,
      filterOptions: {
        ...tableFilterOptions,
        changedBy: 'table',
      },
    };

    if (filterOptions.filters) {
      newFilterOptions.filters = filterOptions.filters;
    }

    if (filterOptions.search) {
      newFilterOptions.search = filterOptions.search;
    }

    onChangeFilterOptions(newFilterOptions);

    if (remoteData) {
      return getRemoteData(newFilterOptions, getData);
    }

    if (!this.cachedData.allItems) {
      const allItems = await getAllItemsFirstTime(
        newFilterOptions,
        getData,
        detailsView.idFieldName
      );

      this.cachedData = {
        allItems: allItems.data,
        groupedItems: allItems.groupedData,
        prevFilters: newFilterOptions,
        searchableFields: columns.filter((i) => i.searchable && !i.hidden),
        filteredData: allItems.data,
        filterTypes: columns
          .map(({ type, field }) => ({ type, field }))
          .reduce(
            (r, { type, field }) => Object.assign(r, { [field]: type }),
            {}
          ),
      };

      if (isNumeric(this.page)) {
        allItems.page = this.page;
        this.page = null;
      }

      return allItems;
    }

    const { filteredData, ...resp } = await getFilteredData(
      {
        ...newFilterOptions.filterOptions,
      },
      this.cachedData
    );

    this.cachedData.prevFilters = newFilterOptions;
    this.cachedData.filteredData = filteredData;

    return resp;
  };

  onCloseDetailView = () => {
    this.closeDetailView();
    this.props.onCloseDetailView?.();
    this.props.detailsView.onClose?.();
  };

  closeDetailView = () => {
    const { detail, detailViewPanel } = this.state;
    this.setState(
      detail?.id || detailViewPanel?.id === null
        ? {
            detail: { open: false, id: null, editMode: UPDATE },
            isLoading: false,
          }
        : {
            detailViewPanel: { open: false, id: null, editMode: UPDATE },
            isLoading: false,
          }
    );
  };

  onDetailView = async (e, details) => {
    const {
      detailsView,
      remoteData,
      fullScreenDetailView,
      onEditRowAction,
    } = this.props;
    const id = details[detailsView.idFieldName];

    let item = null;
    if (
      !remoteData &&
      this.cachedData &&
      this.cachedData.groupedItems &&
      this.cachedData.groupedItems[id]
    ) {
      item = this.cachedData.groupedItems[id];
    }

    if (!item) {
      if (detailsView.getDataFromView) {
        item = details;
      } else {
        this.setState({ isLoading: true });
        const { data } = await detailsView.getItem(id);
        item = data;

        this.setState({ isLoading: false });
      }
    }

    this.cachedDetailViewData = { ...this.cachedDetailViewData, [id]: item };

    if (onEditRowAction) {
      onEditRowAction(id, this.cachedDetailViewData);
    }

    const editMode = detailsView.mode || UPDATE;

    if (fullScreenDetailView) {
      this.setState({
        detailViewPanel: { open: true, id, editMode },
      });
    } else {
      this.setState({ detail: { open: true, id, editMode } });
    }
  };

  onCreate = async (tableName, cols) => {
    if (tableName === 'Roles') {
      const allTenants = await api.getAllTenants();
      const tenantsList = allTenants.data.data.map((x) => [x.id, x.name]);

      const allAcls = await api.getAcls();
      const aclsList = allAcls.data.data.map((x) => ({
        Id: x.Id,
        AclKey: x.AclKey,
      }));

      this.cachedDetailViewData = {
        ...this.cachedDetailViewData,
        [-1]: {
          TenantNameCreate: tenantsList[0][1],
          Acls: aclsList,
        },
      };

      const tenantCol = cols.find((x) => x.field === 'TenantNameCreate');
      if (tenantCol) {
        tenantCol.lookup = tenantsList;
      }
    } else if (tableName === Estimates) {
      const { selectList } = this.state;
      if (
        !selectList.list.length &&
        selectList.title !== EstimatesProjectListTitle
      ) {
        this.setState({ isLoadingTable: true });
        const allProjects = await api.getAllProjects({
          sortBy: 'createdAt',
          sortOrder: -1,
          skip: 0,
          page: 0,
          limit: 10,
          pageSize: 10,
          getAll: true,
          withTenants: true,
        });
        if (allProjects?.data) {
          this.setState({
            selectList: {
              open: true,
              list: allProjects.data.data,
              title: EstimatesProjectListTitle,
              headers: EstimatesProjectListHeaders,
              count: allProjects.data.count,
              remoteData: true,
            },
            isLoadingTable: false,
          });
        }
      } else {
        this.setState({
          selectList: {
            ...selectList,
            open: true,
          },
        });
      }
      return;
    }

    if (tableName === DWRequest) {
      this.setState({ isLoadingTable: true });
      const allOpportunity = await api.getAllOpportunity({
        sortBy: 'createdAt',
        sortOrder: -1,
        page: 1,
        pageSize: 10,
        getAll: true,
      });
      if (allOpportunity?.data) {
        this.setState({
          selectList: {
            open: true,
            list: allOpportunity.data.Data,
            title: OpportunityListTitle,
            headers: OpportunityListHeaders,
            count: allOpportunity.data.TotalCount,
            remoteData: true,
          },
          isLoadingTable: false,
        });
      }

      return;
    }

    const { tableProps } = this.props;

    if (tableProps.onCreate) {
      tableProps.onCreate(tableName, cols);
      return;
    }

    this.setState({ detailViewPanel: { open: true, editMode: 'create' } });
  };

  onOpenCloseColumnsModal = () => {
    const { isShowInTableColumnsModalOpen } = this.state;
    this.setState({
      isShowInTableColumnsModalOpen: !isShowInTableColumnsModalOpen,
    });
  };

  onCloseSelectList = () => {
    const { selectList } = this.state;
    this.setState({ selectList: { ...selectList, open: false } });
  };

  onClickSelectList = (item, setLoadingStatus) => {
    const {
      tableProps: { name, actions },
      acl,
      onCreateRowAction,
    } = this.props;

    switch (name) {
      case Estimates:
        setLoadingStatus(true);

        const isPanelClawTenant = acl.isAccessAllow(
          `${SHOW_OPPORTUNITY_BY_DEFAULT}.show`
        );

        api
          .generateEstimates({
            data: {
              revisionId: item._id,
              isPanelClawTenant,
            },
          })
          .then((data) => {
            setLoadingStatus(false);
            if (data.data.opportunityNumberWarning) {
              Toaster.warn(data.data.opportunityNumberWarning);
            }
            this.onCloseSelectList();
            actions.goToEstimate(data.data.estimateId);
            // this.forceUpdateTableData();
          })
          .catch((error) => {
            setLoadingStatus(false);
            Toaster.error(
              error && error.message && error.message.message
                ? `Failed to create project quote: ${error.message.message}`
                : 'Error occurred during project quote creation'
            );
          });
        break;
      case DWRequest:
        setLoadingStatus(true);
        api.getOpportunityByNumber(item.OpportunityNumber).then((data) => {
          setLoadingStatus(false);
          const { OpportunityNumber, ...otherData } = data.data;
          this.cachedDetailViewData = {
            ...this.cachedDetailViewData,
            [-1]: {
              OpportunityId: OpportunityNumber,
              ...otherData,
            },
          };
          this.onCloseSelectList();
          this.setState({
            detailViewPanel: { open: true, editMode: 'create' },
          });
        });

        const { selectList } = this.state;
        this.setState({ selectList: { ...selectList, open: false } });

        onCreateRowAction();
      default:
        break;
    }
  };

  fetchSelectedPage = async (page, pageSize, searchFilter = '') => {
    const {
      tableProps: { name },
    } = this.props;

    switch (name) {
      case Estimates: {
        const param = {
          sortBy: 'createdAt',
          sortOrder: -1,
          page,
          skip: page * pageSize - pageSize,
          limit: pageSize,
          pageSize,
          getAll: true,
          withTenants: true,
        };

        if (searchFilter.length) {
          param.search = searchFilter;
        }

        const allProjects = await api.getAllProjects(param);
        if (allProjects?.data) {
          this.setState({
            selectList: {
              ...this.state.selectList,
              list: allProjects.data.data,
            },
          });
          return true;
        }
        return false;
      }
      case DWRequest: {
        const param = {
          sortBy: 'createdAt',
          sortOrder: -1,
          page,
          pageSize,
          getAll: true,
        };

        if (searchFilter.length) {
          param.search = searchFilter;
        }

        const allOpportunity = await api.getAllOpportunity(param);
        if (allOpportunity?.data) {
          this.setState({
            selectList: {
              ...this.state.selectList,
              list: allOpportunity.data.Data,
              count: allOpportunity.data.TotalCount,
            },
          });
          return true;
        }
        return false;
      }
      default:
        break;
    }
  };

  onRevertTable = () => {
    const {
      tableProps: { actions },
    } = this.props;

    actions?.revert?.();
  };

  onSave = async (id, item, create) => {
    this.setState({ isLoading: true });

    const {
      detailsView: { onSave, idFieldName },
      remoteData,
    } = this.props;

    try {
      await onSave(id, { data: item }, create);
    } catch (error) {
      Toaster.error(
        error && error.message && error.message.message
          ? error.message.message
          : 'Error ocurred during save please try again'
      );
      await this.onCloseDetailView();
      return;
    }

    if (!remoteData) {
      this.cachedData.groupedItems[id] = item;
      const foundIndex = this.cachedData.allItems.findIndex(
        (x) => x[idFieldName] === id
      );
      if (foundIndex !== -1) {
        this.cachedData.allItems[foundIndex] = item;
      }
    }

    await this.forceUpdateTableData();
    await this.onCloseDetailView();
  };

  onSaveColumns = () => {
    this.onOpenCloseColumnsModal();
  };

  onDelete = async (e, details) => {
    const {
      detailsView: { onDelete, idFieldName, canBreakDelete },
      remoteData,
    } = this.props;

    const id = details[idFieldName];
    try {
      await onDelete(id);
    } catch (error) {
      Toaster.error(
        error && error.message && error.message.message
          ? error.message.message
          : 'Error ocurred during delete please try again'
      );

      await this.onCloseDetailView();

      return;
    }

    if (canBreakDelete) return;

    if (!remoteData) {
      delete this.cachedData.groupedItems[id];
      const foundIndex = this.cachedData.allItems.findIndex(
        (x) => x[idFieldName] === id
      );
      if (foundIndex !== -1) {
        this.cachedData.allItems.splice(foundIndex, 1);
      }
    }

    this.forceUpdateTableData();
    this.onCloseDetailView();
  };

  deleteItem = (id) => {
    if (this.cachedData.groupedItems) {
      delete this.cachedData.groupedItems[id];
    }

    if (Array.isArray(this.cachedData.allItems)) {
      const foundIndex = this.cachedData.allItems.findIndex(
        (x) => x[idFieldName] === id
      );

      if (foundIndex !== -1) {
        this.cachedData.allItems.splice(foundIndex, 1);
      }
    }

    this.forceUpdateTableData();
    this.onCloseDetailView();
  };

  setLoading = (isLoading = true) => {
    this.setState({ isLoading });
  };

  setLoadingTable = (isLoadingTable = true) => {
    this.setState({ isLoadingTable });
  };

  moveToReference = (tableName, referenceName, row, col) => {
    const {
      tableProps: { actions },
    } = this.props;

    const { field } = col;
    const value = row[field];

    actions?.goTo?.({
      tableName,
      referenceName,
      value,
      row,
      col,
    });
  };

  getBackGeneratedLink = async ({ exportTo = 'pdf', table, search = '' }) => {
    Toaster.longWait(
      `Export to ${exportTo.toUpperCase()} submitted. Please wait…`
    );

    const apiCalls = {
      Smm: () =>
        api.getServerGeneratedReport('modules', {
          exportTo,
          ...(search ? { search } : {}),
        }),
      CustomSmm: () =>
        api.getServerGeneratedReport('customModules', {
          exportTo,
          ...(search ? { search } : {}),
        }),
      SalesDashboard: async () => {
        return api.exportProjects({
          exportTo,
          name: 'SalesDashboard',
          withTenants: true,
          getAll: true,
          ...(search ? { search } : {}),
        });
      },
      CustomProjects: async () => {
        return api.exportProjects({
          exportTo,
          name: 'CustomProjects',
          withTenants: true,
          getAll: true,
          ...(search ? { search } : {}),
        });
      },
      Roles: () =>
        api.exportRoles({
          exportTo,
          ...(search ? { search } : {}),
        }),
      AllUsers: () =>
        api.exportUsers({
          exportTo,
          ...(search ? { search } : {}),
        }),
      Tenants: () =>
        api.exportTenants({
          exportTo,
          ...(search ? { search } : {}),
        }),
      Estimates: () =>
        api.exportEstimates({
          exportTo,
          ...(search ? { search } : {}),
        }),
    };

    const { data, error } = await apiCalls[table]();

    if (data) {
      this.setState({ exportedFileLink: data.url });
    } else {
      this.setState({ exportError: error });
    }
  };

  openGeneratedReportInNewTab = (url) => {
    const win = window.open(url, '_blank');
    Toaster.clearAll();
    win.focus();
    this.setState({ exportedFileLink: null });
  };

  Toolbar = (props) => {
    const {
      detailsView: { importMenu, cols },
      middleToolbar,
      tableProps: {
        name,
        showRevertTool,
        showSearchBar = true,
        hideExport,
        hideDefaultMenu,
        searchOnLeftSide,
        additionalSearchButton,
      },
      tableActions,
      hasAddButton,
    } = this.props;

    let extraMenuData = [];
    let selectToolBar;

    if (showRevertTool) {
      extraMenuData.push(
        revertTableTool({
          ...this.props,
          getBackGeneratedLink: this.getBackGeneratedLink.bind(this),
          onRevertTable: () => this.onRevertTable(),
          name: `revert ${name}`,
        })
      );
    }

    if (!hideDefaultMenu) {
      if (!hideExport) {
        extraMenuData = [
          extraMenuExport({
            ...this.props,
            cachedData: this.cachedData,
            getBackGeneratedLink: this.getBackGeneratedLink.bind(this),
          }),
          // ...extraMenuDataSpecific, // grid specific
        ];
      }

      // eslint-disable-next-line no-unused-expressions
      if (hasAddButton) {
        extraMenuData.unshift(
          addTool({
            ...this.props,
            styles: {},
            icon: AddIcon,
            iconClass: 'icon-add',
            getBackGeneratedLink: this.getBackGeneratedLink.bind(this),
            onCreate: () => this.onCreate(name, cols),
            name: `add ${name}`,
          })
        );
      }

      if (name === DWRequest || name === 'DW Submissions') {
        extraMenuData.pop();
        name === DWRequest &&
          tableActions &&
          extraMenuData.push(
            actionSelect({ tableActions, styles: { hover: '' } })
          );
      }

      if (Estimates === name) {
        extraMenuData.unshift(
          addTool({
            ...this.props,
            getBackGeneratedLink: this.getBackGeneratedLink.bind(this),
            name: `add ${name}`,
            options: [
              { title: 'Create Estimate', type: 'createEstimate' },
              { title: 'Create Project Quote', type: 'createProjectQuote' },
            ],
            styles: {},
            icon: AddIcon,
            iconClass: 'icon-add',
            optionsHandleSelect: (action) => {
              if (action === 'createestimate') {
                this.props.tableProps?.actions?.goToEstimate?.();
              } else if (action === 'createprojectquote') {
                this.onCreate(name, cols);
              }
            },
          })
        );
      }
    }

    return (
      <Toolbar
        {...props}
        middleToolbar={middleToolbar}
        extraMenuData={extraMenuData}
        importMenu={importMenu}
        tableName={name}
        selectToolBar={selectToolBar}
        showSearchBar={showSearchBar}
        searchOnLeftSide={searchOnLeftSide}
        additionalSearchButton={additionalSearchButton}
      />
    );
  };

  getDetailsData = (detail, queuedImportData, cachedDetailViewData) => {
    if (detail.editMode === 'create') {
      return cachedDetailViewData[-1];
    }

    const cachedDetailData = this.cachedDetailViewData.Id
      ? this.cachedDetailViewData
      : cachedDetailViewData[detail.id];

    return detail.editMode === UPDATE ? cachedDetailData : queuedImportData;
  };

  clearFiltersAndUpdateTable() {
    const { filterOptions } = this.props;
    filterOptions.filters = {};
    this.tableRef.current.onQueryChange(filterOptions);
  }

  referenceColumnsMakeLinks(filteredColumns, tableName) {
    filteredColumns.forEach((col) => {
      if (col.type === 'reference' && col.referenceTo.length) {
        // eslint-disable-next-line no-param-reassign
        col.render = (row = {}) => (
          <>
            {col.referenceTo.map((referenceName) =>
              row[col.field] !== '—' || !row[col.field] ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  className="referenceLink"
                  onClick={(e) => {
                    e.preventDefault();
                    this.moveToReference(tableName, referenceName, row, col);
                  }}
                >
                  {`${row[col.field]} ${
                    col.referenceTo.length > 1 ? `(${referenceName})` : ''
                  }`}
                </Link>
              ) : (
                <span>{row[col.field] || '—'}</span>
              )
            )}
          </>
        );
      } else if (col.type === 'unknown') {
        col.render = (row = {}) => (
          <span>{JSON.stringify(row[col.field])}</span>
        );
      }
    });
  }

  processColumns(columns) {
    columns.forEach((col) => {
      if (col.render) return;

      if (String(col.type).toLocaleLowerCase() === 'date') {
        // eslint-disable-next-line no-param-reassign
        col.render = (row = {}) => {
          const formattedDate = moment(new Date(row[col.field])).format(
            col.format || DEFAULT_DATE_FORMAT
          );

          return isDate(new Date(formattedDate)) ? (
            <span>{formattedDate}</span>
          ) : (
            ''
          );
        };
      }
    });
  }

  createColsRenders(filteredColumns, tableName) {
    filteredColumns.forEach((col) => {
      col.render = (row = {}) => (
        <>{this.createColRender(tableName, row, col)}</>
      );
    });
  }

  createColRender = (tableName, row, col) => {
    const value = row[col.field];

    switch (true) {
      case isObject(value): {
        const n = isNumeric(value.size)
          ? value.size
          : Object.keys(value).length;
        const label = `{ ${n} field${n !== 1 ? 's' : ''} }`;

        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            className="referenceLink"
            onClick={(e) => {
              e.preventDefault();
              this.moveToReference(tableName, 'view', row, col);
            }}
          >
            {label}
          </Link>
        );
      }
      case Array.isArray(value): {
        const n = isNumeric(value.size) ? value.size : value.length;
        const label = `[ ${n} element${n !== 1 ? 's' : ''} ]`;

        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            className="referenceLink"
            onClick={(e) => {
              e.preventDefault();
              this.moveToReference(tableName, 'view', row, col);
            }}
          >
            {label}
          </Link>
        );
      }
      case isBoolean(value):
        return <span>{value ? 'True' : 'False'}</span>;
      default: {
        return <span>{value || '—'}</span>;
      }
    }
  };

  resetCachedData() {
    this.cachedData = { ...initCachedData };
  }

  updateCell = async (newValue, oldValue, rowData, columnDef) => {
    const idFieldName = 'Id';

    this.cachedData.groupedItems[rowData[idFieldName]] = rowData;

    this.cachedData.allItems[rowData.tableData.id][columnDef.field] = newValue;

    await this.forceUpdateTableData();
  };

  updateCachedData = (id, item) => {
    this.cachedDetailViewData = { ...this.cachedDetailViewData, [id]: item };
  };

  reloadTable = (page = null) => {
    this.cachedData.allItems = null;

    if (isNumeric(page)) {
      this.page = page;
    }
  };

  render() {
    const options = this.getOptions();
    const {
      tableProps,
      columns,
      detailsView,
      remoteData,
      queuedImportData,
      // actions = defaultActions,
      // eslint-disable-next-line react/prop-types
      report,
      onDetailViewClick,
      extandedDetailView,
      editRowAction,
      deleteRowAction,
      onDeleteRowAction,
    } = this.props;

    // eslint-disable-next-line react/prop-types
    let { onRowClick } = this.props;

    if (!report && !onRowClick) {
      onRowClick = this.onDetailView; // TODO! NEED REFACTOR!!!
    }

    const {
      detail,
      detailViewPanel,
      isLoading,
      isLoadingTable,
      isShowInTableColumnsModalOpen,
      selectList,
    } = this.state;
    const {
      actionsExtraMenu = null,
      canDelete = null,
      name,
      makeRefrenceLink,
      cellEditable,
    } = tableProps;

    const detailsData = this.getDetailsData(
      detail.id ? detail : detailViewPanel,
      queuedImportData,
      this.cachedDetailViewData
    );

    const deleteHandler = canDelete ? (e, row) => this.onDelete(e, row) : null;
    let actions = !report
      ? [
          {
            icon: 'save',
            tooltip: 'details',
            onClick:
              onDetailViewClick || ((e, row) => this.onDetailView(e, row)),
          },
        ]
      : [];

    let componentActions = {};

    if (!cellEditable) {
      if (editRowAction && !report) {
        actions = [
          {
            icon: 'edit',
            tooltip: 'edit',
            onClick:
              onDetailViewClick || ((e, row) => this.onDetailView(e, row)),
            position: 'row',
          },
        ];
      } else {
        componentActions = {
          Action: (tableProps, rowProps) =>
            MoreMenu({
              actionsExtraMenu,
              tableProps,
              rowProps,
              deleteHandler,
            }),
        };
      }
    }

    if (deleteRowAction) {
      actions = [
        {
          icon: 'delete',
          tooltip: 'delete',
          onClick: (e, row) => onDeleteRowAction(e, row),
          position: 'row',
        },
      ];
    }

    let tableColumns;
    let hidedColumns;

    if (makeRefrenceLink) {
      const {
        tableProps: { tableName },
        hsHidedColumns,
      } = this.props;
      hidedColumns = hsHidedColumns[tableName] || {};
      tableColumns = getVisibleColumns(columns, hidedColumns);
      this.referenceColumnsMakeLinks(tableColumns, tableProps.name);
    } else if (tableProps.name === ApiInspector) {
      const {
        tableProps: { selectedTableName },
        aiHidedColumns,
      } = this.props;
      hidedColumns = aiHidedColumns[selectedTableName] || {};
      tableColumns = getVisibleColumns(columns, hidedColumns);
      this.referenceColumnsMakeLinks(tableColumns, tableProps.name);
    } else if (tableProps.useHierarchy) {
      hidedColumns = tableProps.hidedColumns;
      tableColumns = getVisibleColumns(columns, hidedColumns);
      this.createColsRenders(tableColumns, tableProps.name);
    } else if (tableProps.useReference) {
      hidedColumns = tableProps.hidedColumns;
      tableColumns = getVisibleColumns(columns, hidedColumns);
      this.referenceColumnsMakeLinks(tableColumns, tableProps.name);
    } else {
      tableColumns = columns;
      this.processColumns(tableColumns, tableProps.name);
    }

    return (
      <>
        <div
          className={`material-table ${
            detailViewPanel.open && !extandedDetailView ? 'hide' : ''
          }`}
        >
          <Backdrop style={{ zIndex: 9999, color: '#004D7A' }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Table
            ref={this.tableRef}
            data={this.getTableData}
            tableProps={tableProps}
            options={options}
            columns={tableColumns}
            onRowClick={onRowClick}
            components={{
              Toolbar: this.Toolbar,
              FilterRow: (rowProps) => {
                const data = {
                  tableName: name,
                  ...rowProps,
                };
                return <FilterRow {...data} />;
              },
              // eslint-disable-next-line no-shadow
              ...componentActions,
            }}
            isLoading={isLoadingTable}
            actions={actions}
          />
          <DetailModalView
            tableProps={tableProps}
            remoteData={remoteData}
            handleClose={this.onCloseDetailView}
            open={detail.open}
            editMode={detail.editMode}
            data={detailsData}
            extraToolbar={detailsView.extraToolbar}
            cols={detailsView.cols}
            onSave={this.onSave}
            title="Item"
            isLoading={isLoading}
            idFieldName={detailsView.idFieldName}
          />

          {selectList.open && (
            <SelectListModal
              title={selectList.title}
              isLoading={isLoading}
              open={selectList.open}
              list={selectList.list}
              count={selectList.count}
              headers={selectList.headers}
              tableProps={tableProps}
              withPagination
              remoteData
              handleClose={this.onCloseSelectList}
              onClick={this.onClickSelectList}
              fetchSelectedPage={this.fetchSelectedPage}
            />
          )}
          {isShowInTableColumnsModalOpen && (
            <ShowInTableColumnsModal
              tableProps={tableProps}
              handleClose={this.onOpenCloseColumnsModal}
              open={isShowInTableColumnsModalOpen}
              cols={columns}
              tableHidedColumns={hidedColumns}
              onSaveColumns={this.onSaveColumns}
              title="Item"
              isLoading={isLoading}
            />
          )}
        </div>
        {detailViewPanel.open && (
          <DetailView
            ref={this.detailViewRef}
            tableProps={tableProps}
            remoteData={remoteData}
            handleClose={this.onCloseDetailView}
            open={detailViewPanel.open}
            editMode={detailViewPanel.editMode}
            data={detailsData}
            extraToolbar={detailsView.extraToolbar}
            cols={detailsView.cols}
            colsAsTable={detailsView.colsAsTable}
            colsAsAccordion={detailsView.colsAsAccordion}
            colsAsEditables={detailsView.detailViewColsAsEditables}
            onSave={this.onSave}
            onUpdate={this.props.onDetailViewUpdate}
            title={tableProps.detailViewTittle || 'Item'}
            isLoading={isLoading}
            idFieldName={detailsView.idFieldName}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const {
    dataView,
    dataView: {
      queuedImportData,
      hellioscope,
      apiInspector: { headers: aiHeaders, hidedColumns: aiHidedColumns } = {},
    },
  } = state;

  const filterOptions = dataView[ownProps.tableProps.name]
    ? dataView[ownProps.tableProps.name]
    : {};
  const { hidedColumns: hsHidedColumns } = hellioscope;
  const acl = getAcl(state);

  return {
    filterOptions,
    queuedImportData,
    acl,
    hsHidedColumns,
    aiHeaders,
    aiHidedColumns,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setHsHidedColumns: (hidedColumns) => {
    dispatch(setHsHidedHeader(hidedColumns));
  },
  changeFilterOptions: (filterOptions) => {
    dispatch(changeFilterOptions(filterOptions));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TableWrap);

TableWrap.propTypes = {
  changeFilterOptions: PropTypes.func.isRequired,
  /* eslint-disable */
  columns: PropTypes.array.isRequired,
  detailsView: PropTypes.any,
  detailViewPanel: PropTypes.any,
  filterOptions: PropTypes.object.isRequired,
  tableProps: PropTypes.object,
  getData: PropTypes.func.isRequired,
  remoteData: PropTypes.bool,
  middleToolbar: PropTypes.func,
  queuedImportData: PropTypes.shape({}),
  refreshCount: PropTypes.number,
  report: PropTypes.bool,
  setHeader: PropTypes.func,
  hsHidedColumns: PropTypes.object.isRequired,
  /* eslint-enable */
  setHsHidedColumns: PropTypes.func.isRequired,
  init: PropTypes.func,
  acl: PropTypes.object,
  // TODO: TEM SOLUTION! NEED REFACTOR: Next STEP -> EXTRACT OUTSIDE
  aiHeaders: PropTypes.object.isRequired,
  aiHidedColumns: PropTypes.object.isRequired,
  onEditRowAction: PropTypes.func,
  onCreateRowAction: PropTypes.func,
  onDeleteRowAction: PropTypes.func,
  deleteRowAction: PropTypes.bool,
  onDetailViewUpdate: PropTypes.func,
  onCloseDetailView: PropTypes.func,
};

TableWrap.defaultProps = {
  remoteData: false,
  middleToolbar: null,
  refreshCount: 0,
  queuedImportData: {},
  report: false,
  init: () => {},
  onEditRowAction: () => {},
  onCreateRowAction: () => {},
  onDeleteRowAction: () => {},
  deleteRowAction: false,
  onDetailViewUpdate: () => {},
  onCloseDetailView: () => {},
  setHeader: () => {},
};
