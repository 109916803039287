import React from 'react';

function getStatuses() {
  return {
    successful: 'Successful', 
    failed: 'Failed', 
    pending:'Pending', 
    requested: 'Requested', 
    incomplete: 'Incomplete',
  };
}

export const getCProjectsHeaders = () => {
  const fields = [
    {
      field: 'name',
      title: 'Name',
      showInTable: true,
      filtering: true,
      sortable: true,
    },
    {
      field: 'address',
      title: 'Address',
      showInTable: true,
      sortable: true,
    },
    {
      field: 'owner',
      title: 'Owner',
      filtering: true,
      showInTable: true,
      sortable: true,
    },
    {
      field: 'revisionNumber',
      title: 'Revision #',
      type: 'numeric',
      showInTable: true,
      sortable: true,
    },
    {
      field: 'noOfRevisions',
      title: 'Total Revisions',
      type: 'numeric',
      showInTable: true,
      sorting: false,
      filtering: false,
    },
    {
      field: 'createdAt',
      showInTable: true,
      title: 'Created Date',
      type: 'date',
      filtering: false,
      sortable: true,
      cellStyle: {
        width: '50px',
        maxWidth: '50px',
      },
    },
    {
      field: 'preparedBy',
      showInTable: true,
      title: 'Created By',
      sortable: true,
    },
    {
      field: 'updatedAt',
      showInTable: true,
      title: 'Modified Date',
      type: 'date',
      filtering: false,
      sortable: true,
      cellStyle: {
        width: '50px',
        maxWidth: '50px',
      },
    },
    {
      field: 'status',
      showInTable: true,
      title: 'Status',
      sortable: true,
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      exType: 'select',
      lookup:(() => getStatuses()) (),
      render: (row = {}) => <span>{row.status}</span>,
    },
  ];
  return {
    headers: fields.filter(({ showInTable }) => showInTable),
    detailViewCols: fields,
  };
};
