import Dashboard from 'containers/Dashboard';
import Users from 'containers/Users';
// import UsersCIS from 'containers/UsersCIS';
// import UsersMongo from 'containers/UsersMongo';
import SMM from 'containers/SMM';
import SignIn from 'containers/SignIn';
import CProjects from 'containers/CProjects';
import ApiInspector from 'containers/ApiInspector';
import Sales from 'containers/Sales';
import Requests from 'containers/DesignerWorkflow/Requests/Requests';
// import Finals from 'containers/Finals';
import Tenants from 'containers/Tenants';
import Acls from 'containers/Acls';
import Roles from 'containers/Roles';
import Estimates from 'containers/Estimates';
import Estimate from 'containers/Estimates/Estimate';
import ReportsCustom from 'containers/Reports/Custom';

import { PRIVATE } from 'redux/constants/category';
import Submissions from 'containers/DesignerWorkflow/Submissions/Submissions';
import Analytics from 'containers/DesignerWorkflow/Analytics/Analytics';
import Management from 'containers/DesignerWorkflow/Management/Management';
import config from './configs/routes';

export const { urls } = config;

export const nonAuthorizedUrls = ['signin', 'pcdesigner'];

export const routes = [
  {
    key: 'dashboard',
    exact: true,
    path: urls.dashboard,
    component: Dashboard,
    access: PRIVATE,
  },
  {
    key: 'users',
    path: urls.users,
    component: Users,
    access: PRIVATE,
  },
  // {
  //   key: 'cis-users',
  //   path: urls.usersCIS,
  //   component: UsersCIS,
  // },
  // {
  //   key: 'mongo-users',
  //   path: urls.usersMongo,
  //   component: UsersMongo,
  // },
  {
    key: 'smm',
    path: urls.smm,
    component: SMM,
    access: PRIVATE,
  },
  {
    key: 'signin',
    path: urls.signin,
    component: SignIn,
  },
  {
    key: 'requests',
    path: urls.dwRequests,
    component: Requests,
    access: PRIVATE,
  },
  {
    key: 'submissions',
    path: urls.dwSubmissions,
    component: Submissions,
    access: PRIVATE,
  },
  {
    key: 'analytics',
    path: urls.dwAnalytics,
    component: Analytics,
    access: PRIVATE,
  },
  {
    key: 'management',
    path: urls.dwManagement,
    component: Management,
    access: PRIVATE,
  },
  {
    key: 'sales',
    path: urls.sales,
    component: Sales,
    access: PRIVATE,
  },
  {
    key: 'acls',
    path: urls.acls,
    component: Acls,
    access: PRIVATE,
  },
  {
    key: 'roles',
    path: urls.roles,
    component: Roles,
    access: PRIVATE,
  },
  {
    key: 'estimateActionItem',
    path: urls.estimateActionItem,
    component: Estimate,
    exact: true,
    access: PRIVATE,
  },
  {
    key: 'estimateAction',
    path: urls.estimateAction,
    component: Estimate,
    exact: true,
    access: PRIVATE,
  },
  {
    key: 'estimate',
    path: urls.estimate,
    component: Estimate,
    exact: true,
    access: PRIVATE,
  },
  {
    key: 'estimates',
    path: urls.estimates,
    component: Estimates,
    access: PRIVATE,
  },
  // {
  //   key: 'finals',
  //   path: urls.finals,
  //   component: Finals,
  // },
  {
    key: 'pcdesigner',
    path: urls.pcdesigner,
    component: () => {
      // eslint-disable-next-line no-unused-expressions
      global.window &&
        global.window.location.replace(process.env.REACT_APP_PCDESIGNER);
      return null;
    },
  },
  {
    key: 'cprojects',
    path: urls.cprojects,
    component: CProjects,
    access: PRIVATE,
  },
  {
    key: 'apiinspector',
    path: urls.apiInspector,
    component: ApiInspector,
    access: PRIVATE,
  },
  {
    key: 'tenants',
    path: urls.tenants,
    component: Tenants,
    access: PRIVATE,
  },
  {
    key: 'reports-custom',
    path: urls.reportsCustom,
    component: ReportsCustom,
  },
];
