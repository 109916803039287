import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PropsTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toaster from 'components/ToasterQueue';
import { onClickDataSheetUrl } from 'containers/SMM/utils';
import OkOrCancel from 'components/Dialogs/OkOrCancel';
import { palette } from 'containers/App/theme';

import { SpecSheetGenerator } from 'components/MaterialTable/export/pdf';
import { uploadFileToS3 } from 'utils/uploadFileToS3';

const useStyles = makeStyles(() => ({
  noSpec: {
    backgroundColor: palette.error.main,
    color: 'white',
    '&:hover': {
      background: '#f00',
    },
  },
  hasSpec: {
    backgroundColor: '#e0e0e0',
    color: 'black',
  },
}));

function SpecSheetMenu({
  url,
  isEditMode,
  isAllowInDropdown,
  data,
  setNewSpecSheetUrl,
  showExtraDialog,
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(2); // Show
  const [showGenerateDialog, setGenerateDialogOpen] = useState(false);

  const anchorRef = useRef(null);
  const inputFile = useRef(null);

  useEffect(() => {
    setGenerateDialogOpen(showExtraDialog);
  }, [showExtraDialog]);

  const uploadFileToS3Wrapper = useCallback(
    (selectedFile, isValidationOff, tId) =>
      uploadFileToS3({ file: selectedFile, isValidationOff })
        .then((fileName) => {
          Toaster.dismissById(tId);
          setNewSpecSheetUrl(fileName);
          Toaster.success(
            `'${selectedFile.name}' was successfully uploaded to S3`
          );

          // **
          // ** On Save we have to copy this file to module-spec-sheets using copyObject from api/helpers/s3.js
          // **
        })
        .catch((error) => {
          Toaster.dismissById(tId);
          Toaster.error(error);
        }),
    [setNewSpecSheetUrl]
  );

  const menuOptions = useMemo(
    () => ({
      Upload: {
        isDisabled: ({ isEditModeAndAllowInDropdown }) =>
          !isEditModeAndAllowInDropdown,
        handleClick: () => {
          inputFile.current.click();
        },
      },
      Generate: {
        isDisabled: ({ isEditModeAndAllowInDropdown }) =>
          !isEditModeAndAllowInDropdown,
        handleClick: () => {
          if (showGenerateDialog) setGenerateDialogOpen(false);
          const tId = Toaster.msg(`Generating Module Data Sheet ...`);
          SpecSheetGenerator(data).then((file) => {
            // eslint-disable-next-line no-use-before-define
            uploadFileToS3Wrapper(file, true, tId);
          });
        },
      },
      Show: {
        isDisabled: () => !url,
        handleClick: () => onClickDataSheetUrl(url),
      },
    }),
    [data, url, showGenerateDialog, uploadFileToS3Wrapper]
  );

  const handleFileChange = useCallback(() => {
    const { isValidationOff } = true;
    const selectedFile = inputFile.current.files[0];
    const tId = Toaster.msg(`Uploading '${selectedFile.name}' ...`);
    uploadFileToS3Wrapper(selectedFile, isValidationOff, tId);
  }, [uploadFileToS3Wrapper]);

  const handleBtnViewClick = useCallback(
    () => url && onClickDataSheetUrl(url),
    [url]
  );

  const handleMenuItemClick = useCallback(
    (event, index, option) => {
      setSelectedIndex(index);
      setOpen(false);
      menuOptions[option].handleClick({ url, data });
    },
    [url, data, menuOptions]
  );

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  }, []);

  const btnClassName = !url ? classes.noSpec : classes.hasSpec;

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <input
          type="file"
          accept="application/pdf"
          id="specSheetFile"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            className="smm-button-group"
          >
            <Button className={btnClassName} onClick={handleBtnViewClick}>
              Module Spec Sheet
            </Button>
            <Button
              color="default"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {Object.keys(menuOptions).map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={menuOptions[option].isDisabled({
                            url,
                            isEditModeAndAllowInDropdown:
                              isEditMode && isAllowInDropdown,
                          })}
                          selected={index === selectedIndex}
                          onClick={(event) =>
                            handleMenuItemClick(event, index, option)
                          }
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
      <OkOrCancel
        title="Module needs to have a spec sheet"
        text="Upload it or click 'OK' to generate it."
        isOpen={showGenerateDialog}
        handleOkClick={menuOptions.Generate.handleClick}
      />
    </>
  );
}

SpecSheetMenu.propTypes = {
  url: PropsTypes.string,
  isEditMode: PropsTypes.bool,
  isAllowInDropdown: PropsTypes.bool,
  data: PropsTypes.shape({}),
  setNewSpecSheetUrl: PropsTypes.func,
  showExtraDialog: PropsTypes.bool,
};

SpecSheetMenu.defaultProps = {
  url: null,
  isEditMode: false,
  isAllowInDropdown: false,
  data: {},
  setNewSpecSheetUrl: () => {},
  showExtraDialog: false,
};

export default SpecSheetMenu;
