import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';

import { isObject } from 'utils';

import Table from 'components/common/MaterialTable';
import Toolbar from './components/Toolbar';

import NewModule from './components/NewModule';

import './assets/style.scss';

function ModuleList({
  values,
  onChange,
  limitModules,
  disabled,
  disabledToolbar,
  onHandleState,
  canAddCustomModule,
  modulePower,
  defaultProps,
}) {
  const [openedNewModule, setOpenedNewModule] = useState(false);
  const [showToolbar, setShowToolbar] = useState(canAddCustomModule);
  const [newModuleValues, setNewModuleValues] = useState({
    power: modulePower,
  });

  const headers = [
    {
      id: 'name',
      disablePadding: true,
      label: `Module List (up to ${limitModules})`,
    },
  ];

  useEffect(() => {
    onHandleState({ name: 'loaded', value: true });
    return () => {};
  }, []);

  useEffect(() => {
    setNewModuleValues({ power: modulePower });
  }, [modulePower]);

  useEffect(() => {
    setShowToolbar(canAddCustomModule);
  }, [canAddCustomModule]);

  const onHandleToolbar = (data) => {
    if (data?.action === 'custom') {
      setOpenedNewModule(true);
    }
  };

  const onHandleEventNewModule = async (data) => {
    setOpenedNewModule(false);

    if (data?.action === 'add' && isObject(data.values)) {
      const {
        mountingSystem,
        rowSpacing,
        manufacturer,
        modelName,
        W,
        power,
        dataSheetUrl,
        PL,
        PW,
        APT,
      } = data.values;

      const module = {
        id: uuidV4(),
        isCustom: true,
        manufacturer,
        model: modelName,
        power,
        dataSheetUrl,
        moduleDimensions: {
          PL,
          PW,
          APT,
          W,
        },
        name: `${manufacturer} ${modelName}`,
        metadata: {
          manufacturer,
          mountingSystem,
          rowSpacing,
          modulePower: power,
        },
      };

      onChange({ modules: [module] });
    }
  };

  return (
    <div>
      <Table
        className="module-list"
        showTitle={false}
        showPagination={false}
        values={values}
        disabled={disabled}
        headers={headers}
        toolbar={showToolbar && Toolbar}
        disabledToolbar={disabledToolbar}
        onEventToolbar={onHandleToolbar}
        limitRows={limitModules}
        onChange={onChange}
      />
      <NewModule
        opened={openedNewModule}
        onEvent={onHandleEventNewModule}
        values={newModuleValues}
        defaultProps={defaultProps.customModule}
      />
    </div>
  );
}

ModuleList.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  limitModules: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledToolbar: PropTypes.bool,
  canAddCustomModule: PropTypes.bool,
  onChange: PropTypes.func,
  onHandleState: PropTypes.func,
  modulePower: PropTypes.string,
  defaultProps: PropTypes.shape({
    estShippingCostMod: PropTypes.number,
    customModule: PropTypes.shape({
      dataSheetUrl: PropTypes.string,
      moduleDimensions: PropTypes.shape({
        pl: PropTypes.number,
        pt: PropTypes.number,
        pw: PropTypes.number,
        apt: PropTypes.number,
        w: PropTypes.number,
      }),
    }),
  }),
};

ModuleList.defaultProps = {
  values: [],
  limitModules: 0,
  disabled: false,
  disabledToolbar: false,
  canAddCustomModule: false,
  onChange: () => {},
  onHandleState: () => {},
  modulePower: '',
  defaultProps: {},
};

export default connect()(ModuleList);
