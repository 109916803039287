import React from 'react';
import './style.scss';
import Chip from '@material-ui/core/Chip';

const getTenantsHeaders = () => {
  const fields = [
    {
      field: 'id',
      title: 'Id',
      editable: 'never',
      filtering: false,
      sorting: false,
      type: 'numeric',
    },
    {
      field: 'name',
      title: 'Name',
      showInTable: true,
      searchable: true,
      validations: {
        required: true,
      },
    },
    {
      field: 'TierId',
      title: 'Tier ID',
      validations: {
        required: true,
      },
      defaultValue: '1',
    },
    {
      field: 'Country',
      title: 'Country',
      validations: {
        required: true,
      },
    },
    {
      field: 'City',
      title: 'City',
      showInTable: true,
      validations: {
        required: true,
      },
    },
    {
      field: 'State',
      title: 'State',
      showInTable: true,
      validations: {
        required: true,
      },
    },
    {
      field: 'Zipcode',
      title: 'Zip Code',
      showInTable: true,
      validations: {
        required: true,
      },
    },
    {
      field: 'MinDefaultSubarrayFS',
      title: 'Min Default Subarray FS',
    },
    {
      field: 'MinDefaultModuleFS',
      title: 'Min Default Module FS',
    },
    {
      field: 'DefaultSubarrayFS',
      title: 'Default Subarray FS',
    },
    {
      field: 'DefaultModuleFS',
      title: 'Default Module FS',
    },
    {
      field: 'DefaultEdgeModuleUpliftFS',
      title: 'Default Edge Module Uplift FS',
    },
    {
      field: 'UpdatedByEmail',
      title: 'Updated By',
      showInTable: true,
      filtering: true,
      sorting: true,
    },
    {
      field: 'UpdatedAt',
      title: 'Updated At',
      showInTable: true,
      filtering: true,
      sorting: true,
      render: (row = {}) =>
        row.UpdatedAt
          ? new Date(row.UpdatedAt).toLocaleString('en-US')
          : '',
    },
    {
      field: 'AllowedDomains',
      title: 'Allowed Domains',
      exType: 'tenantDomains',
      sorting: false,
      filtering: false,
      showInTable: true,
      validations: {
        required: true,
      },
      render: (row) =>
        row.AllowedDomains.map((x, i) => (
          <Chip label={x} color="primary" key={i} style={{ margin: 2 }} />
        )),
    },
  ];
  return {
    headers: fields.filter(({ showInTable }) => showInTable),
    detailViewCols: fields,
  };
};

export { getTenantsHeaders };
