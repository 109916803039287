export function filterTables(tables, search) {
  if (!search) {
    return tables;
  }

  const searchLower = search.toLowerCase();

  return tables.filter((t) => {
    const label = t.label || t.title || '';
    return label.toLowerCase().includes(searchLower);
  });
}

export default filterTables;
