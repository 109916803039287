import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PropsTypes from 'prop-types';

function OkOrCancel({ title, text, handleOkClick, isOpen = false }) {
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    handleClose();
    handleOkClick();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

OkOrCancel.propTypes = {
  title: PropsTypes.string,
  text: PropsTypes.string,
  isOpen: PropsTypes.bool,
  handleOkClick: PropsTypes.func,
};

OkOrCancel.defaultProps = {
  title: 'Do you agree?',
  text: '',
  isOpen: false,
  handleOkClick: () => {},
};

export default OkOrCancel;
